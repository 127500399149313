import React, { useState, useEffect } from 'react';
import { AppTable, AppButton, AppModal } from '@components';
import EncList from '@components/EncList';
import { GROUPS_API, VALUES_DEFINITIONS_API } from '@services/CONSTANTS';
import styled from 'styled-components';
import ic_eye from '@assets/icons/ic_eye.svg';
import ic_groups from '@assets/icons/ic_groups.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { AddGroup } from './AddGroup';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { AddJobTitle } from 'pages/JobTitle';
import moment from 'moment';
import { CondOperator } from '@nestjsx/crud-request';
import { VIEW_JOB_TITLES, VIEW_GROUP, GROPUS } from '@navigation/CONSTANTS';
import { useHistory, useParams, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatFullDate } from '@utils/dateFormatHelper';

import { useTranslation } from 'react-i18next';

const Edit = styled.img`
  width: 24px;
  height: 24px;
`;
const StyledLabel = styled.div`
  margin-left: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

//Mandatory Filters for consulting
const filters = [
  { field: 'definitionType.definition_type', operator: CondOperator.EQUALS, value: 'JOBS_TYPES' },
];

export const GroupList = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const [openForm, setOpenForm] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });
  //Get type from url
  let { type } = useParams();
  //Control if it is group or job_type table
  const [teamType, setTeamType] = useState(type ? type : 'group');
  //Redux
  const { userPermissions, userData } = useSelector((state) => state.app);
  //Store custom filters
  const [groupFilters, setGroupFilters] = React.useState(null);
  //Group Joins
  const groupJoins = React.useMemo(() => {
    return [{ field: 'groupsSite' }, { field: 'siteGroups' }];
  }, []);

  const groupColumns = [
    {
      dataField: 'id',
      text: `${t('table_columns.ID')}`,
      sort: true,
      disableColumnToggle: true,
      search: true,
      headerStyle: { width: '4%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.id}</StyledLabel>;
      },
    },
    {
      dataField: 'name',
      text: `${t('table_columns.group_name')}`,
      sort: true,
      search: true,
      headerStyle: { width: '24%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.name}</StyledLabel>;
      },
    },
    {
      dataField: 'groupsSite.length',
      text: `${t('table_columns.employees_assigned')}`,
      sort: false,
      search: false,
      headerStyle: { width: '9%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.groupsSite.length}</StyledLabel>;
      },
    },
    {
      dataField: 'siteGroups.length',
      text: `${t('table_columns.sites_assigned')}`,
      sort: false,
      search: false,
      headerStyle: { width: '8%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.siteGroups.length}</StyledLabel>;
      },
    },
    {
      dataField: 'last_modified',
      text: `${t('table_columns.last_modified')}`,
      sort: false,
      search: false,
      headerStyle: { width: '9%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
      },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {row.updated_at !== null ? `${formatFullDate(row.updated_at)}` : ''}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: `${t('table_columns.view')}`,
      search: false,
      headerStyle: { width: '3%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        return (
          <Route
            render={({ history }) => (
              <StyledLabel
                style={{
                  marginLeft: '0px',
                  justifyContent: 'center',
                  alignitems: 'center',
                }}
                onClick={() => {
                  history.push(VIEW_GROUP.replace(':groupId', row.id) + '?b=true');
                }}
              >
                <Edit src={ic_eye} alt="logo"></Edit>
              </StyledLabel>
            )}
          />
        );
      },
    },
  ];
  
  //Job titles
  const jobTitleColumns = [
    {
      dataField: 'value_definition_id',
      text: `${t('table_columns.ID')}`,
      sort: true,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '5%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.value_definition_id}</StyledLabel>;
      },
    },
    {
      dataField: 'description',
      text: `${t('table_columns.job_title')}`,
      sort: true,
      search: true,
      headerStyle: { width: '40%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.description}</StyledLabel>;
      },
    },
    {
      dataField: 'usersAssigned',
      text: `${t('table_columns.employees_assigned')}`,
      sort: false,
      search: false,
      headerStyle: { width: '12%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.usersAssigned}</StyledLabel>;
      },
    },
    {
      dataField: 'updated_at',
      text: `${t('table_columns.last_modified')}`,
      sort: false,
      search: false,
      headerStyle: { width: '12%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
      },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {row.updated_at !== null ? `${formatFullDate(row.updated_at)}` : ''}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: `${t('table_columns.view')}`,
      headerStyle: { width: '4%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        return (
          <Route
            render={({ history }) => (
              <StyledLabel
                style={{
                  marginLeft: '0px',
                  justifyContent: 'center',
                  alignitems: 'center',
                }}
                onClick={() => {
                  history.push(
                    VIEW_JOB_TITLES.replace(':jobTitleId', row.value_definition_id) + '?b=true',
                  );
                }}
              >
                <Edit src={ic_eye} alt="logo"></Edit>
              </StyledLabel>
            )}
          />
        );
      },
    },
  ];
  
  /***
   * Shows modal for creat group
   */
  const handleClickOpenFormModal = () => {
    setOpenForm(true);
  };

  /***
   * Hide modal for creat group
   */
  const handleCloseModal = () => {
    setOpenForm(false);
  };

  /***
   * Add to history the current url
   */
  const handleChangeTypeURL = () => {
    if (teamType === 'group') {
      history.push(GROPUS.replace(':type', 'job_title'));
    } else if (teamType === 'job_title') {
      history.push(GROPUS.replace(':type', 'group'));
    } else {
      history.push(GROPUS.replace(':type', 'group'));
    }
  };

  /***
   * To know which type is and what button activate
   */
  const getActiveTabIndex = (type) => {
    if (type === 'group') {
      return 0;
    } else if (type === 'job_title') {
      return 1;
    } else {
      return 0;
    }
  };

  /***
   * Set filter. Only get groups which belong to user groups
   */
  useEffect(() => {
    //Redirection if doesn't have enough privileges
    userData.isSiteManager && history.push('/Dashboard');

    if (userPermissions) {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions?.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroupFilters([
            {
              field: 'id',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
        } else {
          //System user without groups
          setGroupFilters([{ field: 'id', operator: CondOperator.IN, value: -1 }]);
        }
      } else {
        setGroupFilters([]);
      }
    }
  }, [userPermissions, history, userData.isSiteManager]);

  return (
    <div>
      <EncList
        title={t('Groups.title')}
        icon={ic_groups}
        src={ic_groups}
        indexActive={getActiveTabIndex(teamType)}
        options={[
          {
            label: t('Groups.groups'),
            onClick: () => {
              setTeamType('group');
              handleChangeTypeURL();
            },
          },
          {
            label: t('Groups.job_titles'),
            onClick: () => {
              setTeamType('job_title');
              handleChangeTypeURL();
            },
          },
        ]}
      ></EncList>
      {teamType === 'group' && groupFilters !== null && (
        <AppTable
          endpointPath={GROUPS_API}
          columns={groupColumns}
          csvName={t('csv_names.export_groups_csv_name')}
          joins={groupJoins}
          filters={groupFilters}
          reload={showSucessModal.show}
          withColumnToggler={true}
          withExport={true}
          trailingBtn={
            userPermissions?.type === 'system' ? (
              <></>
            ) : (
              <AppButton
                tooltiptitle={t('tooltips.add_group')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenFormModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      )}
      {teamType === 'job_title' && (
        <AppTable
          endpointPath={VALUES_DEFINITIONS_API}
          columns={jobTitleColumns}
          idColumnName={'value_definition_id'} //Db table Value definition has id column name different from other bd tables, it is necessary send it
          filters={filters}
          csvName={t('csv_names.export_job_titles_csv_name')}
          reload={showSucessModal.show}
          withColumnToggler={true}
          withExport={true}
          trailingBtn={
            userPermissions?.type === 'system' ? (
              <></>
            ) : (
              <AppButton
                tooltiptitle={t('tooltips.add_job_title')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenFormModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      )}
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      <AppModal
        open={openForm}
        handleclose={handleCloseModal}
        title={teamType === 'group' ? `${t('tooltips.add_group')}` : `${t('tooltips.add_job_title')}`}
        inside={1}
      >
        {teamType === 'group' ? (
          <AddGroup handleclose={handleCloseModal} setShowSucessModal={setShowSucessModal} />
        ) : (
          <AddJobTitle handleclose={handleCloseModal} setShowSucessModal={setShowSucessModal} />
        )}
      </AppModal>
    </div>
  );
};
