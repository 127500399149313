import React, { useState } from 'react';
import EncList from '@components/EncList';
import { useHistory, useParams } from 'react-router-dom';
import { MESSAGE_CENTER } from '@navigation/CONSTANTS';
import ic_message_center_white from '@assets/icons/ic_message_center_white.svg';
import { Chat } from '@pages/MessageCenter/Chat/Chat';
import { Updates } from '@pages/MessageCenter/Updates/Updates';

import { useTranslation } from 'react-i18next';

export const MessageCenter = () => {
  const { t } = useTranslation();
  let history = useHistory();
  //Get type from url
  let { type } = useParams();
  //Control what kind of page is
  const [teamType, setTeamType] = useState(type ? type : 'chat');

  /***
   * Add to history the current url
   */
  const handleChangeTypeURL = (type) => {
    if (type === 'chat') {
      history.push(MESSAGE_CENTER.replace(':type', 'chat'));
    } else if (type === 'updates') {
      history.push(MESSAGE_CENTER.replace(':type', 'updates'));
    } else {
      history.push(MESSAGE_CENTER.replace(':type', 'messages'));
    }
  };

  /***
   * To know which type is and what button activate
   */
  const getActiveTabIndex = (type) => {
    if (type === 'chat') {
      return 0;
    } else if (type === 'updates') {
      return 2;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <EncList
        title={t('MessageCenter.title')}
        icon={ic_message_center_white}
        indexActive={getActiveTabIndex(teamType)}
        options={[
          {
            label: `${t('MessageCenter.header_buttons.chat')}`,
            onClick: () => {
              setTeamType('chat');
              handleChangeTypeURL('chat');
            },
          },
        ]}
      ></EncList>
      {teamType === 'chat' && <Chat />}
      {teamType === 'updates' && <Updates />}
    </div>
  );
};
