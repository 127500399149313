import React from 'react';
// Handle console logs
import 'utils/dropConsole';
// Styles
import 'fontsource-roboto';
// ROUTER
import { BrowserRouter } from 'react-router-dom';
import { RouterConfig } from 'navigation/RouterConfig';
// MUI Theme
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import { ThemeSwitch } from "components/ThemeSwitch";
//import {dark, light } from "styles/muiTheme";
import './App.css';
import { ProvideAuth } from 'navigation/Auth/ProvideAuth';
// Redux
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import moment from 'moment';
import 'moment/locale/es';
import i18next from 'i18n';

moment.locale(i18next.resolvedLanguage);

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1179BF',
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage}>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <ThemeProvider theme={/*darkState ? dark() :*/ /*light()*/ theme}>
            {/*<ThemeSwitch
              darkState={darkState}
              handleThemeChange={handleThemeChange}
            />*/}
            <ProvideAuth>
              <BrowserRouter>
                <RouterConfig />
              </BrowserRouter>
            </ProvideAuth>
          </ThemeProvider>
        </Provider>
      </DndProvider>
    </LocalizationProvider>
  );
}

export default App;
