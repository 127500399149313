import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomizedSnackbar = ({ type, title, message }) => {
  const [open, setOpen] = React.useState(true);

  const handleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleclose}
        style={{ marginTop: '50px' }}
      >
        <Alert
          style={{ backgroundColor: type === 'success' ? PALETTE_COLOR.ACCENT_GREEN : '#d32f2f' }}
          onClose={handleclose}
          severity={type}
          sx={{ width: '100%' }}
        >
          <p style={{ fontWeight: 600, fontSize: '16px' }} className="mb-0">
            {title}
          </p>
          <p style={{ fontWeight: 400, fontSize: '14px' }} className="mb-0">
            {message}
          </p>
        </Alert>
      </Snackbar>
    </Stack>
  );
};
