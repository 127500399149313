import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppButton,
  InputForm,
  CustomizedSnackbar,
  AppDropDownSelect,
  AppSwitch,
  AppDropDownSingleSelect,
  AppCheckBox,
  AppModal,
  AppModalStatus,
  AppDropDownMultipleSelect,
} from '@components';
import {
  saveEntityData,
  getEntityData,
  getCurrentSession,
  editEntityData,
  deleteEntityData,
} from '@services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import {
  PALETTE_COLOR,
  SHIFT_EDIT,
  SHIFT_CREATION,
  SHIFT_REMOVE,
  SHIFT_UPDATE_PUBLISH,
} from '@config/CONSTANTS';
import {
  VALUES_DEFINITIONS_API,
  SITES_GROUPS_API,
  SHIFTS_API,
  ORGANIZATIONS_USERS_API,
  USER_SETTINGS_API,
} from 'services/CONSTANTS';
import { useSelector } from 'react-redux';
import { calcDot, SingleOption } from 'utils/coloredDot';
import moment from 'moment';
import { isEqual } from 'lodash';
import { ShortTimeZone, ShiftFullTimeDifferences, getTimeZone, ShiftTimes } from 'utils/appUtils';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import Lottie from 'lottie-react';

import EllipseIcon from '@assets/icons/ic_grey_ellipse.svg';
import trash from '@assets/icons/trash.svg';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ico_update_publish from '@assets/icons/ico_update_publish.svg';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';

import { useTranslation } from 'react-i18next';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const TzHeaderLabel = styled.div`
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;

const TzHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

const TzHeaderValue = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

const StyleButtons = styled.div`
  padding-bottom: 20px;
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
`;

const HoursDiff = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const Zone = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${MAIN_BLUE};
  top: -8px;
  position: relative;
  cursor: pointer;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const TimeRangeInput = styled(TextField)`
  border: 1px solid #E7ECF4;
  border-radius: 16px;
  & label.Mui-focused {
    display: none;
  }
  & label {
    display: none;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #E7ECF4;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #E7ECF4;
    }
    &.Mui-focused fieldset {
      border: 1px solid blue;
    }
  }
  &.MuiInputBase-root{
    padding: 0px;
    background-color: green;
    & .MuiButtonBase-root{
      padding: 0,
          padding-left: 10;
          background-color: gold;
    }
    & .MuiInputBase-input{
      padding: 8;
      padding-left: 0;
      background-color: cyan;
    }
  }
`;

const DivDraft = styled.div`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#F1F5FD')};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  .hours {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
  }
  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }
  .location {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #878e97;
  }
  .jobtitles {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
  }
  p {
    margin-bottom: 0px;
  }
`;

const emptyForm = {
  shift_date: null,
  start_time: null,
  end_time: null,
  title: '',
  enable_claim: false,
  open_spots: null,
  site: '',
  job_title: '',
  location: '',
  note: '',
  user_shifts: [],
  repeat_shift: false,
};

const requestCreateShiftC = {
  shift_date: '',
  start_time: '',
  end_time: '',
  enable_claim: '',
  open_spots: '',
  location: '',
  note: '',
  repeat_shift: '',
  status: {},
  organization: {},
  site: {},
  group: {},
  job_title: [],
  user_shifts: [],
};

export const AddTemplates = ({
  handleclose,
  setShowSucessModal,
  action,
  shiftID = null,
  draft = false,
  setshiftCreationInfo = null,
}) => {
  const { t } = useTranslation();
  const shiftSchema = Yup.object().shape({
    shift_date: Yup.date().nullable().required(`${t('validation.date_required')}`),
    start_time: Yup.string().nullable().required(`${t('validation.value_required')}`),
    end_time: Yup.string().nullable().required(`${t('validation.value_required')}`),
    title: Yup.string()
      .max(200, `${t('JobScheduler.validation.shift_form.must_be_title')}`)
      .required(`${t('validation.title_required')}`),
    site: Yup.object().nullable().required(`${t('validation.site_required')}`),
    job_title: Yup.array().nullable().required(`${t('validation.job_titles_required')}`),
    employees: Yup.array().nullable(),
    enable_claim: Yup.boolean().nullable(),
    open_spots: Yup.string()
      .nullable()
      .matches(/^[1-9][0-9]*$/, `${t('JobScheduler.validation.shift_form.must_be_number')}`)
      .when('enable_claim', {
        is: true,
        then: Yup.string().required(`${t('validation.spots_required')}`),
      }),
    location: Yup.string()
      .max(400, `${t('JobScheduler.validation.shift_form.must_be_location')}`)
      .required(`${t('validation.location_required')}`),
    note: Yup.string(),
    //repeat_shift: Yup.boolean(),
    repeat_shift_option: Yup.object(),
    end_repeat_shift_option: Yup.object(),
    workingDays: Yup.array()
      .nullable()
      .when('repeat_shift_option.value.value_definition', {
        is: '040',
        then: Yup.array().test('one-selected', `${t('validation.day_at_least_one')}`, function (value) {
          const found = value.find((v) => v.isActive === true);
          return found ? true : false;
        }),
      }),
    repeat_number_shifts: Yup.string()
      .nullable()
      .when('end_repeat_shift_option.value.id', {
        is: 2,
        then: Yup.string()
          .required(`${t('validation.value_required')}`)
          .matches(/^[1-9][0-9]*$/, `${t('JobScheduler.validation.shift_form.must_be_number')}`),
      }),
    end_repeat_date: Yup.date()
      .nullable()
      .when('end_repeat_shift_option.value.id', {
        is: 1,
        then: Yup.date().nullable().required(`${t('validation.value_required')}`),
      }),
    action: Yup.string(),
  });
  
  const initialValuesC = useMemo(() => {
    return {
      shift_date: moment(),
      start_time: '00:00',
      end_time: '12:00',
      title: '',
      enable_claim: false,
      open_spots: null,
      site: '',
      job_title: '',
      location: '',
      note: '',
      user_shifts: [],
      repeat_shift: false,
      timeZone: {
        label: 'America/Bogota',
        value: {
          value_definition: '060',
          description: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
          active: true,
          validation_type: 'America/Bogota',
        },
      },
      repeat_shift_option: {
        label: `${t('JobScheduler.options.Daily')}`,
        value: {
          value_definition: '010',
          description: 'Daily',
        },
      },
      end_repeat_shift_option: {
        label: `${t('JobScheduler.options.on')}`,
        value: {
          id: 1,
          description: 'on',
        },
      },
      workingDays: [
        {
          id: 1,
          name: 'Sun',
          isActive: false,
        },
        {
          id: 2,
          name: 'Mon',
          isActive: false,
        },
        {
          id: 3,
          name: 'Tue',
          isActive: false,
        },
        {
          id: 4,
          name: 'Wed',
          isActive: false,
        },
        {
          id: 5,
          name: 'Thu',
          isActive: false,
        },
        {
          id: 6,
          name: 'Fri',
          isActive: false,
        },
        {
          id: 7,
          name: 'Sat',
          isActive: false,
        },
      ],
      repeat_number_shifts: null,
      end_repeat_date: moment(new Date()),
    };
  }, [t]);

  const { jobSchedulerGroup, valuesDefinitions, userData } = useSelector((state) => state.app);

  const timeZones = React.useMemo(
    () =>
      valuesDefinitions
        ? valuesDefinitions
            .filter((v) => v.definitionType.definition_type === 'TIME_ZONES')
            .sort((a, b) => a.value_definition - b.value_definition)
        : [],
    [valuesDefinitions],
  );
  const localTz = timeZones.find((tz) => tz.description.includes(getTimeZone()));
  const [item, setItem] = useState({});
  const [initialValues, setInitialValues] = useState(initialValuesC);
  //Repeat options
  let repeatOptions =
    valuesDefinitions &&
    valuesDefinitions.filter((v) => v.definitionType.definition_type === 'SHIFT_REPETITION');
  const endRepeatOptions = useMemo(
    () => [
      { id: 1, description: 'on' },
      { id: 2, description: 'after' },
    ],
    [],
  );
  //To sort
  function compare(a, b) {
    if (a.value_definition > b.value_definition) return 1;
    if (a.value_definition < b.value_definition) return -1;
  }
  //Sort
  repeatOptions = repeatOptions.sort(compare);
  //To handle message
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  const [isloadingShift, setIsLoadingShift] = useState(false);
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [loadingSites, setLoadingSites] = useState(false);
  const [loading, setLoading] = useState(false);

  const [frmMessage, setFrmMessage] = useState('');
  const [startTimeF, setStartTimeF] = useState(moment(new Date().setHours(0, 0, 0, 0)));
  const [endTimeF, setEndTimeF] = useState(moment(new Date().setHours(12, 0, 0, 0)));
  const [diffHours, setDiffHours] = useState('-');
  const [selectedTz, setSelectedTz] = useState(
    timeZones.filter((tz) => tz.value_definition === '060')[0],
  );
  const [canAddNote, setCanAddNote] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openConfirmUpdateModal, setOpenConfirmUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const SYSTEM_ROLE = 'system';
  const ORG_ROLE = 'organizations';
  const CASALIMPIA_ADMIN_ROLE = 'admin';
  const cognitoRole = useRef('');
  const confirmEdition = useRef(false);
  const editingValues = useRef({});
  //Manage if shift is going to be all day
  const [allDay, setAllDay] = useState(false);
  //Variables to handle shift creation from template
  const templateData = useRef('');

  const deleteShiftTemplateModalContent = {
    buttonText: `${t('buttons.btn_delete')}`,
    actionIcon: ico_Archived,
    title: `${t('JobScheduler.modals.delete_shift_template_title')}`,
    subtitle: `${t('JobScheduler.modals.delete_shift_template_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  const confirmUpdatePubishModalContent = {
    buttonText: `${t('buttons.btn_confirm')}`,
    actionIcon: ico_update_publish,
    title: `${t('JobScheduler.modals.update_shift_approval_title')}`,
    bgcolor: PALETTE_COLOR.MAIN_BLUE,
    bordercolor: PALETTE_COLOR.MAIN_BLUE,
    borderhovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    bghovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  };

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  const handleRemoveModal = () => {
    setOpenRemoveModal((prev) => {
      return !prev;
    });
  };

  const handleClickOpenConfirmUpdateModal = () => {
    setOpenConfirmUpdateModal(true);
    confirmEdition.current = false;
  };

  const handleCloseConfirmUpdateModal = () => {
    setOpenConfirmUpdateModal(false);
  };

  /***
   * Get shift info when editing
   */
  useEffect(() => {
    if (shiftID) {
      setIsLoadingShift(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'organization' })
        .setJoin({ field: 'status' })
        .setJoin({ field: 'site' })
        .setJoin({ field: 'user_shifts' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.jobtitle' })
        .setJoin({ field: 'timeZone' })
        .setJoin({ field: 'repeat_shift_option' });
      getEntityData(`${SHIFTS_API}${shiftID}`, qb.query())
        .then((res) => {
          setItem(res);
          setInitialValues({
            ...res,
            shift_date: res.shift_date ? moment.utc(res.shift_date) : initialValuesC.shift_date,
            start_time: res.start_time
              ? moment.utc(res.start_time).format('HH:mm')
              : initialValuesC.start_time,
            end_time: res.end_time
              ? moment.utc(res.end_time).format('HH:mm')
              : initialValuesC.end_time,
            site: res.site
              ? { label: res.site?.name, value: res.site, color: res.organization?.color }
              : initialValuesC.site,
            job_title:
              res.jobs_titles.length > 0
                ? res.jobs_titles?.map((reg) => ({
                    label: reg.jobtitle.description,
                    value: reg.jobtitle,
                  }))
                : res.job_title
                ? [{ label: res.job_title?.description, value: res.job_title }]
                : initialValuesC.job_title,
            user_shifts: res.user_shifts
              ? res.user_shifts?.map((reg) => ({
                  label: reg.user.name,
                  value: reg.user.id,
                  user: reg.user,
                }))
              : initialValuesC.user_shifts,
            timeZone: res.timeZone
              ? { label: res.timeZone?.description, value: res.timeZone }
              : initialValuesC.timeZone,
            repeat_shift_option: res.repeat_shift_option
              ? { label: res.repeat_shift_option?.description, value: res.repeat_shift_option }
              : initialValuesC.repeat_shift_option,
            end_repeat_shift_option: res.end_repeat_date
              ? { label: endRepeatOptions[0].description, value: endRepeatOptions[0] }
              : res.repeat_number_shifts
              ? { label: endRepeatOptions[1].description, value: endRepeatOptions[1] }
              : initialValuesC.end_repeat_shift_option,
            workingDays: res.workingDays ? JSON.parse(res.workingDays) : initialValuesC.workingDays,
            end_repeat_date: res.end_repeat_date
              ? moment.utc(res.end_repeat_date)
              : initialValuesC.end_repeat_date,
          });
          setStartTimeF(moment.utc(res.start_time));
          setEndTimeF(moment.utc(res.end_time));

          setIsLoadingShift(false);
        })
        .catch((err) => {
          console.log('GetShiftData err=', err);
          setIsLoadingShift(false);
        });
    }
  }, [shiftID, endRepeatOptions, initialValuesC]);

  /***
   * Calc hours difference between two time fields
   */
  useEffect(() => {
    if (startTimeF && endTimeF) {
      try {
        const diff = ShiftFullTimeDifferences({ start_time: startTimeF, end_time: endTimeF });

        //var duration = moment.duration(ale);
        //var hours = Math.round(duration.asHours() * 100) / 100;
        isNaN(diff) ? setDiffHours('-') : setDiffHours(`${diff}h`);
        if (action === SHIFT_EDIT) if (diff === 24) setAllDay(true);
      } catch (e) {
        console.log('error: ', e);
        setDiffHours('-');
      }
    } else {
      setDiffHours('-');
    }
  }, [startTimeF, endTimeF, action]);

  /***
   * Load user permissions
   * Load all Job titles
   * Load sites for a group
   */
  useEffect(() => {
    async function fetchUserData() {
      const info = await getCurrentSession();
      cognitoRole.current = info.idToken?.payload['cognito:groups'][0];

      if (cognitoRole.current === SYSTEM_ROLE || cognitoRole.current === CASALIMPIA_ADMIN_ROLE)
        setCanAddNote(true);
      else if (cognitoRole.current === ORG_ROLE) {
        const { role } = userData;
        //Site Manager
        setCanAddNote(role.value_definition === '020');
      }
    }

    const getJobTitles = () => {
      setLoadingJobTitles(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'description', order: 'ASC' })
        .select(['definition_type_id', 'description'])
        .setFilter({
          field: 'definitionType.definition_type',
          operator: CondOperator.EQUALS,
          value: 'JOBS_TYPES',
        });
      getEntityData(VALUES_DEFINITIONS_API, qb.query())
        .then((res) => {
          setJobTitlesList(res);
          setLoadingJobTitles(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingJobTitles(false);
        });
    };

    const getSitesbyGroup = () => {
      if (jobSchedulerGroup) {
        setLoadingSites(true);

        switch (userData?.role?.value_definition) {
          case '020': //SITE MANAGER
            let filtersSm = [
              {
                field: 'user_id.id',
                operator: CondOperator.EQUALS,
                value: userData.id,
              },
              {
                field: 'organizationUserSitesSiteGroupsSiteGrupo.id',
                operator: CondOperator.EQUALS,
                value: jobSchedulerGroup.id,
              },
              {
                field: 'organizationUserSitesSiteStatus.value_definition',
                operator: CondOperator.EQUALS,
                value: '010',
              },
            ];
            const qbsm = RequestQueryBuilder.create()
              .setJoin({ field: 'user_id' })
              .setJoin({ field: 'organization_user_sites' })
              .setJoin({ field: 'organization_user_sites.site' })
              .setJoin({ field: 'organization_user_sites.site.groupsSite' })
              .setJoin({ field: 'organization_user_sites.site.groupsSite.group' })
              .setJoin({ field: 'organization_user_sites.site.status' })
              .setJoin({ field: 'organization_user_sites.site.organization_id' })
              .setFilter(filtersSm);
            //.sortBy({ field: 'organization_user_sites.site.name', order: 'ASC' });
            getEntityData(ORGANIZATIONS_USERS_API, qbsm.query())
              .then((res) => {
                const listSites = [];
                res.forEach((element) => {
                  element.organization_user_sites.forEach((ous) => {
                    listSites.push(ous.site);
                  });
                });
                setSiteList(listSites);
                setLoadingSites(false);
              })
              .catch((err) => {
                console.log('axios err=', err);
                setLoadingSites(false);
              });

            break;

          default:
            let filters = [
              {
                field: 'groups.id',
                operator: CondOperator.EQUALS,
                value: jobSchedulerGroup.id,
              },
              {
                field: 'sites.status.value_definition',
                operator: CondOperator.EQUALS,
                value: '010',
              },
            ];
            const qb = RequestQueryBuilder.create()
              .setJoin({ field: 'group' })
              .setJoin({ field: 'site' })
              .setJoin({ field: 'site.organization_id' })
              .setJoin({ field: 'site.status' })
              .setFilter(filters);
            //.sortBy({ field: 'site.name', order: 'ASC' });
            getEntityData(SITES_GROUPS_API, qb.query())
              .then((res) => {
                setSiteList(res.map((sg) => sg.site));
                setLoadingSites(false);
              })
              .catch((err) => {
                console.log('axios err=', err);
                setLoadingSites(false);
              });
            break;
        }
      }
    };

    fetchUserData();
    getJobTitles();
    getSitesbyGroup();

    return () => {};
  }, [jobSchedulerGroup, userData]);

  /***
   * Look for user settings
   */
  React.useEffect(() => {
    //setIsLoading(true);
    const qb = RequestQueryBuilder.create()
      .setJoin({
        field: 'user',
      })
      .setFilter({
        field: 'user.id',
        operator: CondOperator.EQUALS,
        value: userData.id,
      })
      .setFilter({
        field: 'code',
        operator: CondOperator.EQUALS,
        value: '010',
      });
    //Call API
    getEntityData(USER_SETTINGS_API, qb.query())
      .then((res) => {
        //User with timezone setting already saved
        if (res && res.length > 0) {
          const userTimeZone = timeZones.filter((tz) => tz.value_definition === res[0]?.detail)[0];
          setInitialValues((prev) => {
            return {
              ...prev,
              timeZone: userTimeZone
                ? {
                    label: userTimeZone?.description,
                    value: userTimeZone,
                    active:
                      userTimeZone?.value_definition ===
                      initialValuesC.timeZone.value.value_definition,
                  }
                : initialValuesC.timeZone,
            };
          });
          setSelectedTz(userTimeZone);
        } else {
          setInitialValues((prev) => {
            return {
              ...prev,
              timeZone: initialValuesC.timeZone,
            };
          });
        }
        //setIsLoading(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
    return () => {
      //setIsLoading(false);
      console.log('axios cleanup.');
    };
  }, [userData, timeZones, initialValuesC]);

  /***
   * Change date selector color
   */

  const BuildHeader = (props) => {
    return (
      <>
        <TzHeader
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
          }}
        >
          <AppCheckBox
            checked={props?.value?.value_definition === selectedTz?.value_definition}
            onChange={() => {}}
          />
          <div style={{ display: 'flex', flexDirection: 'column', color: '#000000', textAlign: 'left' }}>
            <TzHeaderLabel>{t('my_local_time')}</TzHeaderLabel>
            <TzHeaderValue>{`${localTz.description}`}</TzHeaderValue>
          </div>
        </TzHeader>
      </>
    );
  };

  const getMainButtonText = () => {
    return `${t('buttons.btn_save_template')}`
  };

  const saveShift = (values, status) => {
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setFrmMessage('');
    const timeZone = timeZones.filter(
      (tz) => tz.value_definition === values.timeZone.value.value_definition,
    )[0];
    values.shift_date = moment(values.shift_date).isValid() ? values.shift_date : null;
    values.start_time = moment(values.start_time, 'HH:mm', true).isValid()
      ? values.start_time
      : null;
    values.end_time = moment(values.end_time, 'HH:mm', true).isValid() ? values.end_time : null;

    if (isEqual(values, initialValuesC) || isEqual(values, emptyForm)) {
      setFrmMessage(`${t('JobScheduler.at_least_one_field')}`);
    } else {
      let formattedShiftDate = null;
      let formattedFStartTime = null;
      let formattedFEndTime = null;
      //New logic for new timepicker component
      const timeRangeStart = values.start_time.split(':');
      const timeRangeEnd = values.end_time.split(':');

      if (new moment(values.shift_date).isValid()) {
        //UTC 0
        const shiftDate = moment(values.shift_date)
          .utcOffset(0)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            dayOfYear: values.shift_date.get('dayOfYear'),
            month: values.shift_date.get('month'),
            year: values.shift_date.get('year'),
          });
        formattedShiftDate = shiftDate.format();
        formattedFStartTime = shiftDate
          .set({
            hour: timeRangeStart[0],
            minute: timeRangeStart[1],
            second: 0,
          })
          .format();
        formattedFEndTime = shiftDate.set({
          hour: timeRangeEnd[0],
          minute: timeRangeEnd[1],
          second: 0,
        });
        // All DAY VALIDATION
        formattedFEndTime = allDay
          ? formattedFEndTime.add(1, 'days').format()
          : formattedFEndTime.format();
      } else {
        formattedFStartTime = moment()
          .utcOffset(0)
          .set({
            hour: timeRangeStart[0],
            minute: timeRangeStart[1],
            second: 0,
          })
          .format();
        formattedFEndTime = moment().utcOffset(0).set({
          hour: timeRangeEnd[0],
          minute: timeRangeEnd[1],
          second: 0,
        });
      }
      const repeat_shift_option = repeatOptions.filter(
        (options) => options.value_definition === values.repeat_shift_option.value.value_definition,
      )[0];
      const filteredJobTitles = values.job_title?.map((item) => {
        return { jobtitle: item.value };
      });
      const requestCreateShift = {
        ...requestCreateShiftC,
        ...values,
        status,
        shift_date: formattedShiftDate,
        start_time: formattedFStartTime,
        end_time: formattedFEndTime,
        group: jobSchedulerGroup,
        organization: item.organization
          ? item.organization
          : values.site?.value
          ? values.site.value.organization_id
          : null,
        site: values.site?.value ? values.site?.value : null,
        jobtitle: filteredJobTitles,
        jobs_titles: filteredJobTitles,
        user_shifts: [],
        location: values.location,
        timeZone,
        repeat_shift_option: values.repeat_shift ? repeat_shift_option : null,
        repeat_number_shifts: values.repeat_shift
          ? values.end_repeat_shift_option.value.id === 1
            ? null
            : values.repeat_number_shifts
          : null,
        end_repeat_date: values.repeat_shift
          ? values.end_repeat_shift_option.value.id === 2
            ? null
            : values.end_repeat_date
          : null,
        workingDays: values.repeat_shift
          ? values.repeat_shift_option.value.value_definition === '040'
            ? JSON.stringify(values.workingDays)
            : null
          : null,
      };

      switch (action) {
        case SHIFT_CREATION:
          createShift(requestCreateShift, status);
          break;
        case SHIFT_EDIT:
          editShift(requestCreateShift, status);
          break;
        default:
          break;
      }
    }
  };

  const createShift = (requestCreateShift, status) => {
    setLoading(true);
    saveEntityData(SHIFTS_API, requestCreateShift)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title:
            status.value_definition === '040'
              ? `${t('success_messages.shift_template_created')}`
              : status.value_definition === '010'
              ? `${t('success_messages.shift_published')}`
              : status.value_definition === '060'
              ? `${t('success_messages.shift_saved')}`
              : `${t('success_messages.shift_created')}`,
          message: status.value_definition === '060' ? `${t('success_messages.shift_approval_pending')}` : '',
        });
        handleclose();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.status === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : status.value_definition === '040'
              ? `${t('error_messages.error_shift_template_creating')}`
              : status.value_definition === '060'
              ? `${t('error_messages.error_shift_publishing')}`
              : `${t('error_messages.error_shift_processing')}`,
          message: '',
        });
        console.log('shifts error = ', err);
      });
  };

  const editShift = (requestCreateShift, status) => {
    //delete (requestCreateShift.user_shifts);
    setLoading(true);
    editEntityData(`${SHIFTS_API}${item.id}`, requestCreateShift)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title:
            status.value_definition === '040'
              ? `${t('success_messages.shift_template_edited')}`
              : status.value_definition === '010'
              ? `${t('success_messages.shift_edited')}`
              : status.value_definition === '060'
              ? `${t('success_messages.shift_approval_sent')}`
              : `${t('success_messages.shift_edited')}`,
        });
        handleclose();
        cognitoRole.current === ORG_ROLE &&
          item.status.value_definition === '010' &&
          status.value_definition === '060' &&
          handleCloseConfirmUpdateModal();

        confirmEdition.current = false;
        editingValues.current = {};
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.status === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : status.value_definition === '020'
              ? `${t('error_messages.error_shift_draft_editing')}`
              : status.value_definition === '060'
              ? `${t('error_messages.error_shift_editing')}`
              : `${t('error_messages.error_shift_processing')}`,
          message: '',
        });
        confirmEdition.current = false;
        editingValues.current = {};
        cognitoRole.current === ORG_ROLE &&
          item.status.value_definition === '010' &&
          status.value_definition === '060' &&
          handleCloseConfirmUpdateModal();
        console.log('shifts error = ', err);
        setLoading(false);
      });
  };

  const deleteShift = () => {
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setLoading(true);
    deleteEntityData(`${SHIFTS_API}${item.id}`)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.shift_template_deleted')}`,
        });
        setLoading(false);
        handleclose();
        reloadSchedulerCalendar(true);
      })
      .catch((err) => {
        setLoading(false);
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.status === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : `${t('error_messages.error_shift_deleting')}`,
          message: '',
        });
        console.log('shifts deleting error = ', err);
      });
  };

  const saveAsDraft = (values) => {
    //Draft
    const status = valuesDefinitions.filter(
      (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '040',
    )[0]; //Draft
    saveShift(values, status);
  };

  const saveAsPublished = (values) => {
    let status = {};

    if (cognitoRole.current === SYSTEM_ROLE || cognitoRole.current === CASALIMPIA_ADMIN_ROLE) {
      //Published
      status = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '010',
      )[0];
      saveShift(values, status);
    } else {
      //Pending for approval
      status = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '060',
      )[0];

      if (action === SHIFT_CREATION || confirmEdition.current)
        confirmEdition.current
          ? saveShift(editingValues.current, status)
          : saveShift(values, status);
      else if (action === SHIFT_EDIT) {
        editingValues.current = values;
        handleClickOpenConfirmUpdateModal(SHIFT_UPDATE_PUBLISH);
      }
    }
  };

  /***
   * Open creation shift modal
   */
  const handleOpenCreateSnglShiftModal = (values) => {
    //Disable repetition
    values.repeat_shift = false;
    //Delete unnecessary data
    delete values.id;
    //Store template data
    templateData.current = values;
    //Close Shift template modal
    handleclose();
    //Open the modal to creat a shift. It return the info to parent <ViewTemplates/> and he takes decisions
    setshiftCreationInfo({ show: true, data: templateData.current });
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={shiftSchema}
        onSubmit={saveAsDraft}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
          <div style={{ width: '590px' }}>
            {isloadingShift ? (
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{ padding: '23px 30px 7px', fontSize: '14px' }}
                novalidate="novalidate"
              >
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.time_range')}></LabelForm>
                  </Col>
                  <Col xs={3}>
                    <TimeRangeInput
                      id="time"
                      label="."
                      type="time"
                      disabled={allDay}
                      value={values.start_time}
                      onChange={(event, newValue) => {
                        //New way: Add Shift date
                        const hours = event.target.value.split(':');
                        const dateTime = moment(values.shift_date).set({
                          hour: hours[0],
                          minute: hours[1],
                          second: 0,
                          millisecond: 0,
                        });
                        setFieldValue('start_time', event.target.value);
                        setStartTimeF(dateTime);
                      }}
                      InputLabelProps={{
                        shrink: true,
                        fontSize: 1,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          fontSize: '14px',
                          color: '#000000',
                          padding: 0,
                        },
                      }}
                      sx={{
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                          filter: allDay
                            ? 'invert(61%) sepia(0%) saturate(0%) hue-rotate(140deg) brightness(98%) contrast(98%);'
                            : 'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                        },
                      }}
                    />
                    {touched.start_time && errors.start_time && (
                      <ErrorText className="px-2 mb-0">{errors.start_time}</ErrorText>
                    )}
                  </Col>

                  <Col
                    xs={1}
                    style={{ textAlign: 'center', padding: '0', width: '6%', marginLeft: 5 }}
                  >
                    <LabelForm tittle="-"></LabelForm>
                  </Col>

                  <Col xs={3}>
                    <TimeRangeInput
                      id="time"
                      label="."
                      type="time"
                      disabled={allDay}
                      value={values.end_time}
                      //minTime={values.start_time ? moment(values.start_time) : moment()}
                      onChange={(event, newValue) => {
                        //New way: Add Shift date
                        const hours = event.target.value.split(':');
                        const dateTime = moment(values.shift_date).set({
                          hour: hours[0],
                          minute: hours[1],
                          second: 0,
                          millisecond: 0,
                        });
                        setFieldValue('end_time', event.target.value);
                        setEndTimeF(dateTime);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          fontSize: '14px',
                          color: '#000000',
                          padding: 0,
                        },
                      }}
                      sx={{
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                          filter: allDay
                            ? 'invert(61%) sepia(0%) saturate(0%) hue-rotate(140deg) brightness(98%) contrast(98%);'
                            : 'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                        },
                      }}
                    />
                    {touched.end_time && errors.end_time && (
                      <ErrorText className="px-2 mb-0">{errors.end_time}</ErrorText>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="px-0"
                    style={{ textAlign: 'left', padding: '0', width: '18%' }}
                  >
                    <HoursDiff>{diffHours} {t('form_labels.total').toLowerCase()}</HoursDiff>
                    <AppDropDownSingleSelect
                      isSearchable={true}
                      allowSelectAll={false}
                      width={400}
                      menuStyle={{ position: 'fixed' }}
                      name="group"
                      BuildHeader={BuildHeader}
                      headerOptions={[
                        {
                          label: ShortTimeZone(localTz),
                          value: localTz,
                        },
                      ]}
                      onChange={(selected) => {
                        setSelectedTz(selected.value);
                        setFieldValue('timeZone', selected);
                      }}
                      value={values.timeZone}
                      options={timeZones.map((option) => ({
                        label: option.description,
                        value: option,
                      }))}
                      targetComponent={<Zone>{ShortTimeZone(values.timeZone?.value)}</Zone>}
                      defaultValue={values.timeZone}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={{ textAlign: 'center', padding: '0' }}></Col>
                  <Col
                    xs={2}
                    style={{ display: 'flex', width: 'max-content' }}
                    className="mx-0 px-0"
                  >
                    <AppSwitch
                      name="all_day"
                      value={allDay}
                      onChange={(selected) => {
                        setAllDay(selected.target.checked);
                        if (selected.target.checked) {
                          //Set start at 00:00:00 and end one day more at 00:00:00
                          setStartTimeF(moment(values.shift_date).startOf('day'));
                          setEndTimeF(moment(values.shift_date).add(1, 'days').startOf('day'));
                          setFieldValue('start_time', '00:00');
                          setFieldValue('end_time', '00:00');
                        } else {
                          //Set start at 00:00:00 and end same day at 12:00:00
                          setStartTimeF(moment(values.shift_date).startOf('day'));
                          setEndTimeF(
                            moment(values.shift_date)
                              .startOf('day')
                              .startOf('day')
                              .hour(12)
                              .minute(0),
                          );
                          setFieldValue('start_time', '00:00');
                          setFieldValue('end_time', '12:00');
                        }
                      }}
                    />
                    <LabelForm tittle={t('form_labels.all_day')} style={{ backgroundColor: 'pink' }}></LabelForm>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.shift_title')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="title"
                      value={values.title}
                      invalid={touched.title && errors.title}
                      onChange={handleChange}
                    ></InputForm>
                    {touched.title && errors.title && (
                      <ErrorText className="px-2 mb-0">{errors.title}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.site')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownSelect
                      options={siteList.map((site) => ({
                        label: site.name,
                        value: site,
                        color: site.organization_id.color,
                        address: site.address,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      name="site"
                      placeholder={t('required')}
                      isLoading={loadingSites}
                      customOption={SingleOption}
                      avatar={values.site && values.site.value ? calcDot(values.site.color) : null}
                      icon={EllipseIcon}
                      value={values.site}
                      onChange={(selected) => {
                        setFieldValue('site', selected);
                        setFieldValue('user_shifts', null);
                        selected
                          ? setFieldValue('location', selected?.address)
                          : setFieldValue('location', '');
                      }}
                      invalid={touched.site && errors.site}
                      defaultValue={values.site}
                    />
                    {touched.site && errors.site && (
                      <ErrorText className="px-2 mb-0">{errors.site}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.job_titles')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownMultipleSelect
                      options={jobTitlesList.map((option) => ({
                        label: option.description,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      name="job_title"
                      placeholder={t('required')}
                      isLoading={loadingJobTitles}
                      value={values.job_title}
                      icon={null}
                      height={230}
                      onChange={(selected) => {
                        setFieldValue('job_title', selected);
                      }}
                      invalid={touched.job_title && errors.job_title}
                      defaultValue={[]}
                    />
                    {touched.job_title && errors.job_title && (
                      <ErrorText className="px-2 mb-0">{errors.job_title}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.location')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="location"
                      value={values.location}
                      disabled
                      invalid={touched.location && errors.location}
                      onChange={handleChange}
                    ></InputForm>
                    {touched.location && errors.location && (
                      <ErrorText className="px-2 mb-0">{errors.location}</ErrorText>
                    )}
                  </Col>
                </Row>
                {canAddNote && (
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.notes')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        placeholder={t('optional')}
                        style={{ width: '100%', resize: 'none' }}
                        inpfontsize={'14px'}
                        name="note"
                        ctype="textarea"
                        rows={3}
                        value={values.note && values.note.toLowerCase().replace(/\s/g, '_') === 'not_shift_note' ? `${t('Timesheet.not_shift_note')}` : values.note}
                        invalid={touched.note && errors.note}
                        onChange={handleChange}
                      ></InputForm>
                      {touched.note && errors.note && (
                        <ErrorText className="px-2 mb-0">{errors.note}</ErrorText>
                      )}
                    </Col>
                  </Row>
                )}
                <hr />
                {frmMessage && (
                  <ErrorText className="px-2 mb-0 text-center">{frmMessage}</ErrorText>
                )}
                <StyleButtons>
                  <span>
                    <AppButton
                      loading={loading}
                      style={{
                        width: 'auto',
                        marginRight: '20px',
                        display: 'unset',
                        flexDirection: 'unset',
                        fontSize: '14px',
                      }}
                      color={PALETTE_COLOR.MAIN_WHITE}
                      bgcolor={PALETTE_COLOR.MAIN_GREEN}
                      bordercolor={PALETTE_COLOR.MAIN_GREEN}
                      bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      size="lg"
                      type="submit"
                      title={getMainButtonText()}
                    >
                      {getMainButtonText()}
                    </AppButton>
                    <AppButton
                      loading={loading}
                      style={{
                        width: 'auto',
                        marginRight: '10px',
                        display: 'unset',
                        flexDirection: 'unset',
                        fontSize: '14px',
                      }}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bordercolor={PALETTE_COLOR.MAIN_GRAY}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      size="lg"
                      type="submit"
                      onClick={() => handleOpenCreateSnglShiftModal(values)}
                      title={t('buttons.btn_create_shift_from_template')}
                    >
                      {t('buttons.btn_create_shift_from_template')}
                    </AppButton>
                    {action === SHIFT_EDIT && (
                      <AppButton
                        loading={loading}
                        style={{
                          width: 40,
                          display: 'unset',
                          flexDirection: 'unset',
                          paddingLeft: 10,
                          marginLeft: 16,
                        }}
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        onClick={() => handleRemoveModal(SHIFT_REMOVE)}
                        title={t('buttons.btn_discard')}
                        icon={trash}
                      ></AppButton>
                    )}
                  </span>
                </StyleButtons>
              </Form>
            )}

            {showErrorMessage.show && (
              <CustomizedSnackbar
                show={showErrorMessage.show}
                type={showErrorMessage.type}
                title={showErrorMessage.title}
                message={showErrorMessage.message}
              />
            )}
          </div>
        )}
      </Formik>
      {draft ? (
        <AppModal
          open={openRemoveModal}
          handleclose={handleRemoveModal}
          title={''}
          boxshadow={'none'}
          inside={1}
          width={600}
        >
          <AppModalStatus
            actionIcon={deleteShiftTemplateModalContent.actionIcon}
            title={deleteShiftTemplateModalContent.title}
            subtitle={
              <DivDraft backgroundColor={item?.organization?.color}>
                <p className="hours">{ShiftTimes(item)}</p>
                {/* <p className="title">{item.title}</p> */}
                <p className="jobtitles">
                  {item?.jobs_titles &&
                    item?.jobs_titles
                      .map((temp) => {
                        return temp?.jobtitle?.description;
                      })
                      .join(', ')}
                </p>
                <p className="location">{item?.site?.name}</p>
              </DivDraft>
            }
            bgcolor={deleteShiftTemplateModalContent.bgcolor}
            bordercolor={deleteShiftTemplateModalContent.bordercolor}
            borderhovercolor={deleteShiftTemplateModalContent.borderhovercolor}
            bghovercolor={deleteShiftTemplateModalContent.bghovercolor}
            boxShadow={deleteShiftTemplateModalContent.boxShadow}
            buttonText={deleteShiftTemplateModalContent.buttonText}
            isLoading={loading}
            buttonAction={deleteShift}
          />
        </AppModal>
      ) : (
        ''
      )}

      {
        /* Edit confirmation modal */
        <AppModal
          open={openConfirmUpdateModal}
          handleclose={handleCloseConfirmUpdateModal}
          boxshadow={'none'}
          inside={1}
        >
          <AppModalStatus
            actionIcon={confirmUpdatePubishModalContent.actionIcon}
            title={confirmUpdatePubishModalContent.title}
            subtitle={
              <DivDraft>
                <p className="hours">
                  {new moment.utc(editingValues.current.start_time).format('h')}
                  {moment(editingValues.current.start_time).format('a') === 'am' ? 'a' : 'p'} -{' '}
                  {new moment.utc(editingValues.current.end_time).format('h')}
                  {moment(editingValues.current.end_time).format('a') === 'am' ? 'a' : 'p'}
                </p>
                <p className="title">{editingValues.current.title}</p>
                <p className="location">{editingValues.current.site?.label}</p>
              </DivDraft>
            }
            bgcolor={confirmUpdatePubishModalContent.bgcolor}
            bordercolor={confirmUpdatePubishModalContent.bordercolor}
            borderhovercolor={confirmUpdatePubishModalContent.borderhovercolor}
            bghovercolor={confirmUpdatePubishModalContent.bghovercolor}
            boxShadow={confirmUpdatePubishModalContent.boxShadow}
            buttonText={confirmUpdatePubishModalContent.buttonText}
            buttonAction={() => {
              confirmEdition.current = true;
              saveAsPublished();
            }}
          />
        </AppModal>
      }
    </>
  );
};
