import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { AppButtonDialog } from '@components';
import { AppModal, AppComponentLoading } from '@components';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CondOperator } from '@nestjsx/crud-request';
import { PALETTE_COLOR, SHIFT_CREATION, SHIFT_EDIT } from '@config/CONSTANTS';
import { AddTemplates } from '../Shifts/AddTemplates';
import { getEntityData } from '@services';
import { SHIFTS_API } from 'services/CONSTANTS';
import { InputForm, AppButton } from '@components';
import ic_search from '@assets/icons/ic_search.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import Inner from '@assets/icons/Inner.svg';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { ShiftForm } from './ShiftForm';
import { ShiftFullTimeDifferences, ShiftTimes } from 'utils/appUtils';

import { useTranslation } from 'react-i18next';

const NoDataPanelText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const Icon = styled.img``;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const StyleButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 13px 30px 13px 30px;
  border-radius: 10px;
  margin-top: 0px;
`;

const StyleOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 13px 30px 13px 30px;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    border: 1px solid #1179BF;
  }
`;

const StyleTitle = styled.div`
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  justify-content: space-between;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  background-repeat: no-repeat;
  background-size: unset;
  padding: 0px 9px;
  background-position: left;
  :hover,: active;
`;

const JobTitles = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Time = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  color: #000000;
`;

const Site = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d737b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TotalHours = styled.div`
  display: flex;
  justify-content: end;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d737b;
`;

//const Icon = styled.img``;

export const ViewTemplates = ({ handleclose, group }) => {
  const { t } = useTranslation();
  const assignOrgEmpSchema = Yup.object().shape({
    organization: Yup.object().nullable().required(`${t('validation.organization_required')}`),
    permittedSites: Yup.array().min(1, `${t('validation.permitted_sites_required')}`),
  });
  const selectedShift = useRef('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openCreateSnglShift, setOpenCreateSnglShift] = useState(false);
  let shiftAction = useRef('');
  const [draft, setDraft] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const handleCloseCreateSnglShiftModal = () => {
    setOpenCreateSnglShift(false);
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const handleClickOpenCreateSnglShiftModal = (action, item = null, draft = false) => {
    shiftAction.current = action;
    selectedShift.current = item;
    setDraft(draft);
    setOpenCreateSnglShift(true);
  };
  //Variables to handle the shift creation modal
  //const [openCreateShift, setOpenCreatelShift] = useState(true);
  const [shiftCreationInfo, setshiftCreationInfo] = useState({ show: false, data: null });

  /***
   * Handle shift creation modal
   */
  const handleCreateShiftModal = () => {
    setshiftCreationInfo({ show: false, data: null });
  };

  /***
   *
   */
  useEffect(() => {
    const getTemplateList = () => {
      let keys = [
        'title',
        'shift_date',
        'end_time',
        'start_time',
        'user_shifts.employees.name',
        'status.description',
        'shift_site.name',
        'jobsTitles.jobsTitlesJobTitle.description',
      ];
      const fieldsFiltering = [];
      if (debouncedSearchTerm) {
        keys.forEach((key) => {
          const fieldSearch = {};
          fieldSearch[key] = { [CondOperator.CONTAINS]: debouncedSearchTerm };
          fieldsFiltering.push(fieldSearch);
        });
      }

      setLoadingTemplates(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'start_time', order: 'ASC' })
        .setJoin([
          { field: 'organization' },
          { field: 'site' },
          { field: 'site.status' },
          { field: 'group' },
          { field: 'status' },
          { field: 'jobs_titles' },
          { field: 'jobs_titles.jobtitle' },
          { field: 'user_shifts' },
          { field: 'user_shifts.user' },
          { field: 'user_shifts.status' },
        ])
        .search({
          $and: [
            {
              'status.value_definition': {
                $eq: '040',
              },
              'group.id': {
                $eq: group.id,
              },
              'shift_site_status.value_definition': {
                $eq: '010', //Onlu active sites
              },
            },
            { $or: fieldsFiltering },
          ],
        });
      getEntityData(SHIFTS_API, qb.query())
        .then((res) => {
          setTemplatesList(res);
          setLoadingTemplates(false);
        })
        .catch((err) => {
          setLoadingTemplates(false);
        });
    };

    getTemplateList();
  }, [showSucessModal, debouncedSearchTerm, group]);

  /***
   *
   */
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={assignOrgEmpSchema}
      initialValues={{
        organization: '',
        permittedSites: [],
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <div style={{ width: '400px', marginBottom: '20px', overflowY: 'hidden' }}>
          <InputForm
            style={{ width: 'inherit' }}
            height={'40px'}
            placeholder={t('placeholder_search')}
            icon={ic_search}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loadingTemplates ? (
            <div style={{ height: '250px' }}>
              {' '}
              <AppComponentLoading style={{ width: 'auto' }} />{' '}
            </div>
          ) : (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {templatesList.length === 0 ? (
                <div style={{ padding: '30px' }}>
                  <Icon
                    src={Inner}
                    style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                  />
                  <NoDataPanelTitle style={{ marginTop: '20px' }}>
                    {t('JobScheduler.nothing_found')}
                  </NoDataPanelTitle>
                  <NoDataPanelText>
                    {t('JobScheduler.try_another')}
                  </NoDataPanelText>
                  <AppButton
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontSize: 14,
                      fontWeight: 500,
                      display: 'flex',
                      margin: 'auto',
                      // width: 161,
                      // height: 40,
                      borderRadius: '20px',
                    }}
                    bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                    bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                    bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                    borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                    icon={ic_add_circle}
                    onClick={() => handleClickOpenCreateSnglShiftModal(SHIFT_CREATION)}
                  >
                    {t('buttons.btn_add_template')}
                  </AppButton>
                </div>
              ) : (
                <StyleButtons>
                  <AppButtonDialog
                    size="lg"
                    style={{
                      width: '100%',
                      //display: 'unset',
                      flexDirection: 'unset',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontSize: 13,
                      fontWeight: 500,
                      display: 'flex',
                      margin: 'auto',
                      //width: 340,
                      height: 52,
                      borderRadius: '10px',
                    }}
                    bgcolor={'#F1F5FD'}
                    bghovercolor={'#F1F5FD'}
                    height="50px"
                    hovercolor={PALETTE_COLOR.MAIN_BLUE}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bordercolor={'transparent'}
                    borderhovercolor={'#F1F5FD'}
                    title={t('buttons.btn_add_template')}
                    onClick={() => handleClickOpenCreateSnglShiftModal(SHIFT_CREATION)}
                  >
                    {t('buttons.btn_add_template')}
                  </AppButtonDialog>
                </StyleButtons>
              )}
              {templatesList.map((template, index) => (
                <StyleOptions
                  style={{
                    background: template.organization?.color
                      ? template.organization.color
                      : '#F1F5FD',
                    padding: '10px',
                  }}
                  onClick={() => handleClickOpenCreateSnglShiftModal(SHIFT_EDIT, template, true)}
                >
                  <StyleTitle>
                    <div style={{ display: 'flex', maxWidth: '60%' }}>
                      <div
                        style={{ boxSizing: 'border-box', display: 'grid', width: 'max-content' }}
                      >
                        <JobTitles>
                          {template?.jobs_titles
                            .map((item) => {
                              return item?.jobtitle?.description;
                            })
                            .join(', ')}
                        </JobTitles>
                        <Site>{template?.site?.name ? template?.site?.name : ` ${t('status_types.No_Site')}`} </Site>
                      </div>
                    </div>
                    <div style={{ display: 'flex', maxWidth: '40%' }}>
                      <div
                        style={{ boxSizing: 'border-box', display: 'grid', width: 'max-content' }}
                      >
                        <Time>
                          {template.start_time && template.end_time ? (
                            <>{ShiftTimes(template)}</>
                          ) : (
                            <></>
                          )}
                        </Time>
                        <TotalHours>
                          {template.start_time && template.end_time ? (
                            ShiftFullTimeDifferences(template) + 'h'
                          ) : (
                            <></>
                          )}
                        </TotalHours>
                      </div>
                    </div>
                  </StyleTitle>
                </StyleOptions>
              ))}
            </div>
          )}
          <AppModal
            open={openCreateSnglShift}
            handleclose={handleCloseCreateSnglShiftModal}
            title={
              shiftAction.current === SHIFT_CREATION
                ? `${t('JobScheduler.modals.add_shift_template_title')}`
                : shiftAction.current === SHIFT_EDIT
                ? `${t('JobScheduler.modals.edit_shift_template_title')}`
                : ''
            }
            inside={1}
          >
            <AddTemplates
              handleclose={handleCloseCreateSnglShiftModal}
              setShowSucessModal={setShowSucessModal}
              setshiftCreationInfo={setshiftCreationInfo}
              action={shiftAction.current}
              shiftID={shiftAction.current === 'edit' && selectedShift.current.id}
              item={selectedShift.current}
              draft={draft}
            />
          </AppModal>

          {/* Modal opened with info returned by child <AddTemplates/> */}
          <AppModal
            open={shiftCreationInfo?.show}
            handleclose={handleCreateShiftModal}
            title={t('JobScheduler.modals.add_new_shift_title')}
            inside={1}
          >
            <ShiftForm
              handleclose={handleCreateShiftModal}
              setShowSucessModal={setShowSucessModal}
              action={SHIFT_CREATION}
              shiftID={null}
              draft={true}
              shiftTemplateInfo={shiftCreationInfo?.data}
            />
          </AppModal>

          {showSucessModal.show ? (
            <CustomizedSnackbar
              show={showSucessModal.show}
              type={showSucessModal.type}
              title={showSucessModal.title}
              message={showSucessModal.message}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </Formik>
  );
};
