import React, { useEffect, useState } from 'react';
import { AppButton, AppComponentLoading } from '@components';
import { BarChart } from '@mui/x-charts/BarChart';
import { mangoFusionPalette } from '@mui/x-charts/colorPalettes';
import { DatePickerByDay } from './DatePickerByDay';
import { DatePickerByWeek } from './DatePickerByWeek';
import { DatePickerByMonth } from './DatePickerByMonth';
import { getEntityData } from '@services';
import { PALETTE_COLOR } from 'config/CONSTANTS';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { Row, Col } from 'react-bootstrap';
import { ORGANIZATIONS_API, SHIFTS_API, TIME_SHEET_API, USERS_API } from '@services/CONSTANTS';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import styled from 'styled-components';
import Avatar from '@assets/icons/ic_no_avatar.svg';
import latinize from 'latinize';
import { useSelector } from 'react-redux';
import { forEach } from 'lodash';
import { useDispatch } from 'react-redux';
import { setGlobalOrganizationAction } from '@redux/actions/appActions';

const DashboardTitle = styled.p`
  font-size: 3rem;
  font-weight: 400;
`;

const MiddleMonthTitle = styled.p`
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
`;

const MiddleMonthTotalTitle = styled.p`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
`;

const MiddleMonthShifts = styled.h1`
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  color: ${PALETTE_COLOR.MAIN_BLUE};
`;

const MiddleMonthTotalShifts = styled.h1`
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  color: ${PALETTE_COLOR.MAIN_GREEN};
`;

const ChartsContainer = styled.div`
  background: white;
  border-radius: 1rem;
  height: 95%;
  margin-bottom: 1rem;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const ChartTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  flex: 1;
  justify-content: left;
  align-items: left;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`;

const ShiftsDay = styled('div')((props) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  textAlign: 'left',
  width: '100%',
  height: '100%',
  verticalAlign: 'middle',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomLeftRadius: `${props.radius}`,
  border: `${props.border} solid ${PALETTE_COLOR.TABLE_BORDERS_DARK}`
}));

const NumberShiftsDay = styled('div')((props) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  textAlign: 'center',
  color: PALETTE_COLOR.BLACK,
  width: '100%',
  height: '100%',
  verticalAlign: 'middle',
  background: props.background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomRightRadius: `${props.radius}`,
  border: `${props.border} solid ${PALETTE_COLOR.TABLE_BORDERS}`
}));

const NoData = styled.div`
  font-weight: 300;
  font-size: 20px;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color: ${PALETTE_COLOR.INACTIVE_GREY_BLUE};
  padding: 60px;
`;

const UnasignedShifts = styled.h1`
  font-weight: 600;
  font-size: 96px;
  line-height: 144px;
  height: 100%;
  width: 100%;
  text-align: center;
  color: ${PALETTE_COLOR.MAIN_BLUE};
`;

const AvailableEmployees = styled.p`
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  width: 100%;
`;

const UserAvatar = styled.img`
  width: 30px;
  height: 30px;
`;

const Icon = styled.img`
  border-radius: 60px;
  width: 30px;
  height: 30px;
  display: flex;
`;

export const DashboardContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { globalOrganizationData, userData } = useSelector((state) => state.app);
  const [openCalendarFilterByDay, setOpenCalendarFilterByDay] = React.useState(false);  
  const [dateFilterByDay, setDateFilterByDay] = React.useState(moment().startOf('day').format());

  const [openCalendarFilterUnassigned, setOpenCalendarFilterUnassigned] = React.useState(false);  
  const [dateFilterUnassigned, setDateFilterUnassigned] = React.useState(moment().startOf('day').format());

  const [calendarWeekConfig, setCalendarWeekConfig] = useState({
    currentWeek: { start: moment.utc().startOf('week'), end: moment.utc().endOf('week') },
    day: moment(),
  });

  const [calendarMonthConfig, setCalendarMonthConfig] = useState({
    currentMonth: { start: moment.utc().startOf('month'), end: moment.utc().endOf('month') },
    day: moment(),
  });

  const [calendarEmployeeConfig, setCalendarEmployeeConfig] = useState({
    currentMonth: { start: moment.utc().startOf('month'), end: moment.utc().endOf('month') },
    day: moment(),
  });

  const [colors, setColors] = React.useState([]);
  const [shiftsByDayDataset, setShiftsByDayDataset] = React.useState([]);
  const [shiftsByWeekDataset, setShiftsByWeekDataset] = React.useState([]);
  const [shiftsByMonthDataset, setShiftsByMonthDataset] = React.useState([]);
  const [employeesByMonthDataset, setEmployeesByMonthDataset] = React.useState([]);
  const [filterShiftsFortnightOne, setFilterShiftsFortnightOne] = React.useState(0);
  const [filterShiftsFortnightTwo, setFilterShiftsFortnightTwo] = React.useState(0);
  const [unassignedShifts, setUnassignedShifts] = React.useState(0);
  const [unassignedEmployees, setUnassignedEmployees] = React.useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [loadingUnassigned, setLoadingUnassigned] = useState(true);
  const [loadingByDay, setLoadingByDay] = useState(true);
  const [loadingByWeek, setLoadingByWeek] = useState(true);
  const [loadingByMonth, setLoadingByMonth] = useState(true);
  const [loadingByEmployee, setLoadingByEmployee] = useState(true);
  
  const shiftsByWeekChartSetting = {
    xAxis: [
      {
        label: '',
      },
    ],
    height: 400,
  };

  const shiftsByMonthChartSetting = {
    yAxis: [
      {
        label: '',
      },
    ],
    height: 400,
  };

  const TOTAL_SHIFTS_WEEK = shiftsByWeekDataset.map((item) => item.count).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL_SHIFTS_WEEK;
    return `${params.label}\n${(percent * 100).toFixed(0)}%`;
  };

  const valueFormatter = (value) => `${value} ${t('Dashboard.charts.shifts')}`;

  const getWeeksOfMonth = (year, month) => {
    const isWeekInMonth = (month, week) => week.startOf('week').month() === month || week.endOf('week') === month
    return Array
      .from({ length: moment().year(year).weeksInYear() }, (_, i) => i + 1)
      .filter((weekNumber) => isWeekInMonth(month, moment().year(year).isoWeek(weekNumber)))
  }

  const getMidTimeBetweenTwoDate = (date1, date2) => {
    let date1Moment = moment(date1);
    let date2Moment = moment(date2);
    let diff = date2Moment.diff(date1Moment, 'minutes');
    let midTime = date1Moment.add(diff / 2, 'minutes');
    return midTime;
  }

  const calcDuration = (mins) => {
    if (!mins) return '0h';
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    setColors([...mangoFusionPalette()]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (Object.keys(globalOrganizationData).length === 0) {
          getEntityData(`${ORGANIZATIONS_API}organization/initial`, '').then((resMinOrg) => {
            dispatch(setGlobalOrganizationAction(resMinOrg));
          });
        }
      } catch (error) {
        console.log('📌 - file: UsersList.js:227 - error:', error);
      }
    })();
  }, [dispatch, globalOrganizationData]);

  /***
   * Load sites depending on user's role
   */
  useEffect(() => {
    if (userData && Object.keys(userData).length > 0 && Object.keys(globalOrganizationData).length > 0) {
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/sitesDashboard`, qb2.query())
        .then((res) => {
          let sitesListTemp = [];
          forEach(res.data, (group) => {
            const sites = group.sites.map((site, index) => ({
              id: site.id,
              // name: site.name
            }));
            sitesListTemp = [...sitesListTemp, ...sites];
          });
          setSitesList(sitesListTemp);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    }
  }, [globalOrganizationData, userData]);

  ////////////////////////////////////////////////////////////////////////
  // GET SHIFTS BY DAY
  useEffect(() => {
    if (sitesList.length > 0) {
      (async () => {
        try {
          setLoadingByDay(true);
          const qb = RequestQueryBuilder.create();
          const url = 
            `${SHIFTS_API}dashboard/byday/shifts/${moment(dateFilterByDay).format('YYYY-MM-DD')} 00:00:00/${moment(dateFilterByDay).endOf('day').format('YYYY-MM-DD')} 23:59:59`;
          getEntityData( url, qb.query())
            .then((res) => {
              let morning_shifts = {};
              let afternoon_shifts = {};
              let complete_shifts = {};
              res.forEach(shift => {
                const duration = moment.duration(moment(shift.end_time.replace('Z', '')).diff(moment(shift.start_time.replace('Z', ''))));
                const hours = duration.asHours();
                if (
                  hours < 8 &&
                  moment(shift.end_time.replace('Z', '')).valueOf() <= moment(`${moment(shift.end_time.replace('Z', '')).format('YYYY-MM-DD')} 12:00:00`).valueOf()
                ) {
                  morning_shifts[shift.id] = 1;
                } else if (
                  hours < 8 &&
                  // moment(shift.start_time.replace('Z', '')).valueOf() >= moment(`${moment(shift.start_time.replace('Z', '')).format('YYYY-MM-DD')} 12:00:00`).valueOf() && 
                  moment(shift.end_time.replace('Z', '')).valueOf() <= moment(`${moment(shift.end_time.replace('Z', '')).format('YYYY-MM-DD')} 18:00:00`).valueOf()
                ) {
                  afternoon_shifts[shift.id] = 1;
                } else {
                  complete_shifts[shift.id] = 1;
                }
              });

              setShiftsByDayDataset([
                {id: `${t('Dashboard.charts.by_day_morning_hours')}`, 'shifts': Object.keys(morning_shifts).length, background: '#FFEAA7', radius: '0px', border: '0px'},
                {id: `${t('Dashboard.charts.by_day_afternoon_hours')}`, 'shifts': Object.keys(afternoon_shifts).length, background: '#C7E0A2', radius: '0px', border: '0px'},
                {id: `${t('Dashboard.charts.by_day_complete_hours')}`, 'shifts': Object.keys(complete_shifts).length, background: '#A5CD69', radius: '0px', border: '0px'}
              ]);
              setLoadingByDay(false);
            })
            .catch((err) => {
              setLoadingByDay(false);
              console.log('axios err=', err);
            });
          } catch (error) {
            setLoadingByDay(false);
            console.log('📌 - file: DashboardContainer.js:249 - error:', error);
          }
        })();
    }
  }, [dateFilterByDay, setShiftsByDayDataset, sitesList, t]);

  ////////////////////////////////////////////////////////////////////
  // GET SHIFTS BY WEEK
  useEffect(() => {
    if (sitesList.length > 0) {
      (async () => {
        try {
          setLoadingByWeek(true);
          const defaultDays = [
            {
              count: 0,
              dayOfWeek: 'Lunes',
            },
            {
              count: 0,
              dayOfWeek: 'Martes',
            },
            {
              count: 0,
              dayOfWeek: 'Miercoles',
            },
            {
              count: 0,
              dayOfWeek: 'Jueves',
            },
            {
              count: 0,
              dayOfWeek: 'Viernes',
            },
            {
              count: 0,
              dayOfWeek: 'Sabado',
            },
            {
              count: 0,
              dayOfWeek: 'Domingo',
            },
          ];

          const qb = RequestQueryBuilder.create();
          const url = 
            `${SHIFTS_API}dashboard/byweek/shifts/${moment(calendarWeekConfig.currentWeek.start).format('YYYY-MM-DD')} 00:00:00/${moment(calendarWeekConfig.currentWeek.end).endOf('day').format('YYYY-MM-DD')} 23:59:59`;
          await getEntityData(url, qb.query())
            .then((res) => {
              const shifts = res;
              const by_day = shifts.map((item) => {
                const day = latinize(moment(item.start_time.replace('Z', '')).format('dddd'));
                return day;
              });
              const gruoped = by_day.reduce((a, v) => {
                a[v] = (a[v] ?? 0) + 1;
                return a;
              }, {});
              
              Object.keys(gruoped).forEach((k) => {
                if (defaultDays.find(el => el.dayOfWeek.toLowerCase() === k.toLowerCase())) {
                  const index = defaultDays.findIndex(el => el.dayOfWeek.toLowerCase() === k.toLowerCase());
                  defaultDays[index].count = gruoped[k];
                }
              });
              setShiftsByWeekDataset([...defaultDays]);
              setLoadingByWeek(false);
            })
            .catch((err) => {
              setLoadingByWeek(false);
              console.log('axios err=', err);
            });
          } catch (error) {
            setLoadingByWeek(false);
            console.log('📌 - file: DashboardContainer.js:249 - error:', error);
          }
        })();
    }
  }, [calendarWeekConfig, setShiftsByWeekDataset, sitesList]);

  ////////////////////////////////////////////////////////////////////
  // GET SHIFTS BY MONTH
  useEffect(() => {
    if (sitesList.length > 0) {
      (async () => {
        try {
          setLoadingByMonth(true);
          const qb = RequestQueryBuilder.create();
          const url = 
            `${SHIFTS_API}dashboard/byweek/shifts/${moment(calendarMonthConfig.currentMonth.start).format('YYYY-MM-DD')} 00:00:00/${moment(calendarMonthConfig.currentMonth.end).endOf('day').format('YYYY-MM-DD')} 23:59:59`;
          await getEntityData(url, qb.query())
            .then((res) => {
              if (res.length > 0) {
                const shifts = res;
                const first_fortnight = getMidTimeBetweenTwoDate(calendarMonthConfig.currentMonth.start, calendarMonthConfig.currentMonth.end);
                const midnight_fortnight = moment(`${moment(first_fortnight).format('YYYY-MM-DD')} 23:59:59`);

                setFilterShiftsFortnightOne(shifts.filter(el => moment(el.start_time.replace('Z', '')) <= midnight_fortnight).length || 0);
                setFilterShiftsFortnightTwo(shifts.filter(el => moment(el.start_time.replace('Z', '')) > midnight_fortnight).length || 0);

                // const weeks = getWeeksOfMonth(calendarMonthConfig.currentMonth.start.year(), calendarMonthConfig.currentMonth.start.month());
                // console.log('📌 - file: DashboardContainer.js:365 - .then - weeks:', weeks);

                const by_week = shifts.map((item) => {
                  const week = (0 | moment(item.start_time.replace('Z', '')).toDate().getDate() / 7) + 1;
                  return week;
                });

                let max_week = 0;
                by_week.forEach(w => {
                  if (w > max_week) max_week = w;
                });
                
                const gruoped = by_week.reduce((a, v) => {
                  a[v] = (a[v] ?? 0) + 1;
                  return a;
                }, {});

                const defaultWeeks = [];
                for(let i = 1; i <= max_week; i++) {
                  defaultWeeks.push({
                    count: 0,
                    week: `${t('Dashboard.charts.week')}\n${i}`,
                    w: i
                  })
                };

                Object.keys(gruoped).forEach((k) => {
                  if (defaultWeeks.find(el => Number(el.w) === Number(k))) {
                    const index = defaultWeeks.findIndex(el => Number(el.w) === Number(k));
                    defaultWeeks[index].count = gruoped[k];
                  }
                });

                setShiftsByMonthDataset([...defaultWeeks]);
              }
              else {
                setFilterShiftsFortnightOne(0);
                setFilterShiftsFortnightTwo(0);
                const weeks = (0 | moment(calendarMonthConfig.currentMonth.start).toDate().getDate() / 7) + 1;
                const defaultWeeks = [];
                for(let i = 1; i <= weeks; i++) {
                  defaultWeeks.push({
                    count: 0,
                    week: `${t('Dashboard.charts.week')}\n${i}`,
                    w: i
                  })
                };
                setShiftsByMonthDataset([...defaultWeeks]);
              }
              setLoadingByMonth(false);
            })
            .catch((err) => {
              setLoadingByMonth(false);
              console.log('axios err=', err);
            });
          } catch (error) {
            setLoadingByMonth(false);
            console.log('📌 - file: DashboardContainer.js:313 - error:', error);
          }
        })();
    }
  }, [calendarMonthConfig, sitesList, t]);

  ////////////////////////////////////////////////////////////////////
  // GET SHIFTS BY EMPLOYEE
  useEffect(() => {
    if (sitesList.length > 0) {
      (async () => {
        try {
          setLoadingByEmployee(true);
          const defaultFilter = [
            {
              field: 'shiftDate',
              operator: CondOperator.BETWEEN,
              value: [
                `${moment(calendarEmployeeConfig.currentMonth.start).format('YYYY-MM-DD')} 00:00:00`,
                `${moment(calendarEmployeeConfig.currentMonth.end).endOf('day').format('YYYY-MM-DD')} 23:59:59`,
              ],
            },
            {
              field: 'innerSite.id',
              operator: CondOperator.IN,
              value: [0] // ...sitesList.map((s) => s.id)
            }
          ];

          const qb = RequestQueryBuilder.create()
            .setFilter(defaultFilter)
            .setJoin([
              { field: 'site' },
              { field: 'userShift' },
              { field: 'userShift.user' },
              { field: 'groupByEmployee' }, //Flag to group by employee, do not remove
              { field: 'sortByEmpAndDate' }, //Flag to sort by employee and date, do not remove
              { field: 'selectSites' },
            ]);

          await getEntityData(TIME_SHEET_API, qb.query())
            .then((res) => {
              const usersArray = [];
              if (res.length > 0) {
                res.forEach((shift, index) => {
                  let h_site_acum = 0;
                  let h_break_acum = 0;
                  shift.timeSheets?.forEach((empSiteTs, i) => {
                    h_site_acum += empSiteTs.onSiteDuration;
                    h_break_acum += empSiteTs.breakDuration;
                  });
                  const user = {
                    name: shift?.user?.name || 'Unknown' ,
                    id: shift?.user?.id || index ,
                    h_site: calcDuration(h_site_acum),
                    h_break: calcDuration(h_break_acum),
                  };
                  usersArray.push(user);
                });
              }
              setEmployeesByMonthDataset(usersArray);
              setLoadingByEmployee(false);
            })
            .catch((err) => {
              setLoadingByEmployee(false);
              console.log('axios err=', err);
            });
          } catch (error) {
            setLoadingByEmployee(false);
            console.log('📌 - file: DashboardContainer.js:313 - error:', error);
          }
        })();
    }
  }, [calendarEmployeeConfig, sitesList, t]);

  ////////////////////////////////////////////////////////////////////
  // GET SHIFTS UNASSIGNED
  useEffect(() => {
    if (sitesList.length > 0) {
      (async () => {
        try {
          setLoadingUnassigned(true);
          const qbu = RequestQueryBuilder.create();
          const urlu = 
            `${SHIFTS_API}dashboard/unassigned/shifts/${moment(dateFilterUnassigned).format('YYYY-MM-DD')} 00:00:00/${moment(dateFilterUnassigned).endOf('day').format('YYYY-MM-DD')} 23:59:59`;
          await getEntityData(urlu, qbu.query())
            .then((res) => {
              setUnassignedShifts(res.length);
              if (res.length > 0) {
                const qb2 = RequestQueryBuilder.create();
                const url = 
                  `${SHIFTS_API}dashboard/available-employees/shifts/${globalOrganizationData.id}/${moment(dateFilterUnassigned).format('YYYY-MM-DD')} 00:00:00/${moment(dateFilterUnassigned).endOf('day').format('YYYY-MM-DD')} 23:59:59`;
                
                getEntityData(
                  url,
                  qb2.query(),
                )
                .then((res) => {
                  setUnassignedEmployees(res);
                  setLoadingUnassigned(false);
                })
                .catch((err) => {
                  setUnassignedEmployees([]);
                  setLoadingUnassigned(false);
                  console.log('axios err=', err);
                });
              } else {
                setUnassignedEmployees([]);
                setLoadingUnassigned(false);
              }
            })
            .catch((err) => {
              setUnassignedShifts(0);
              setLoadingUnassigned(false);
              console.log('axios err=', err);
            });
          } catch (error) {
            setUnassignedShifts(0);
            setLoadingUnassigned(false);
            console.log('📌 - file: DashboardContainer.js:313 - error:', error);
          }
        })();
    }
  }, [dateFilterUnassigned, globalOrganizationData, sitesList, t]);

  const rowClasses = (row, rowIndex) => {
    let classes = 'appTable-row-even';
    if (rowIndex % 2 === 0) {
      classes += ' appTable-row-odd';
    }
    return classes;
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const columnsByDay = [
    {
      dataField: 'id',
      text: `Hora`,
      sort: false,
      search: false,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: '50%', 
        fontWeight: 'normal',
        padding: '0.5rem 1.5rem'
      },
      style: { color: PALETTE_COLOR.LIGHT_GREY, padding: '0px', height: '100%' },
      formatter: (cellContent, row) => {
        return <ShiftsDay radius={row.radius} border={row.border}>{row.id}</ShiftsDay>;
      },
    },
    {
      dataField: 'shifts',
      text: `N° de turnos`,
      sort: false,
      search: true,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: '50%', 
        fontWeight: 'normal',
        padding: '0.5rem 1.5rem'
      },
      style: { padding: '0px', height: '100%' },
      formatter: (cellContent, row) => {
        return <NumberShiftsDay background={row.background} radius={row.radius} border={row.border}>{row.shifts}</NumberShiftsDay>;
      },
    },
  ];

  const columnsByEmployee = [
    {
      dataField: 'id',
      text: `ID`,
      sort: false,
      search: false,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: '14%', 
        fontWeight: 'normal',
        padding: '0.2rem 0rem',
        textAlign: 'center',
        height: '41px'
      },
      style: { padding: '0px', textAlign: 'center', height: '100%' },
      formatter: (cellContent, row) => {
        return <>{row.id}</>;
      },
    },
    {
      dataField: 'name',
      text: `Nombre`,
      sort: false,
      search: true,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: 'auto', 
        fontWeight: 'normal',
        padding: '0.5rem 0.6rem',
        height: '41px'
      },
      style: { padding: '0.1rem 0.6rem', height: '100%' },
      formatter: (cellContent, row) => {
        return <>{row.name}</>;
      },
    },
    {
      dataField: 'h_site',
      text: `Hrs. en sitio`,
      sort: false,
      search: true,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: '20%', 
        fontWeight: 'normal',
        padding: '0.5rem 0.6rem',
        height: '41px'
      },
      style: { padding: '0.1rem 0.6rem', height: '100%' },
      formatter: (cellContent, row) => {
        return <>{row.h_site}</>;
      },
    },
    {
      dataField: 'h_break',
      text: `Hrs. descanso`,
      sort: false,
      search: true,
      headerStyle: { 
        color: PALETTE_COLOR.LIGHT_GREY, 
        width: '20%', 
        fontWeight: 'normal',
        padding: '0.5rem 0.6rem',
        height: '41px'
      },
      style: { padding: '0.1rem 0.6rem', height: '100%' },
      formatter: (cellContent, row) => {
        return <>{row.h_break}</>;
      },
    },
  ];

  return (
    <div className="container-fluid">
      <DashboardTitle>{t('Dashboard.title')}</DashboardTitle>
      <Row>
        <Col xs={7}>
          <Row>
            <Col xs={5}>
              <Row>
                <Col xs={12}>
                  <ChartsContainer style={{ flexDirection: 'column' }}>
                    <div style={{ width: '90%', paddingLeft: 10, paddingBottom: 20 }}>
                      <ChartTitle>{t('Dashboard.charts.counters.shifts_by_day')}</ChartTitle>
                      <Row>
                        <Col xs={8}>
                          <DatePickerByDay
                            dateFilter={dateFilterByDay} 
                            openCalendarFilter={openCalendarFilterByDay} 
                            setDateFilter={setDateFilterByDay} 
                            setOpenCalendarFilter={setOpenCalendarFilterByDay}
                          />
                        </Col>
                        <Col xs={3}>
                          <AppButton
                            size="lg"
                            bgcolor={PALETTE_COLOR.MAIN_WHITE}
                            bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                            color={PALETTE_COLOR.MAIN_BLUE}
                            bordercolor={PALETTE_COLOR.TOP_GRAY}
                            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                            style={{ marginLeft: 6, marginRight: 4 }}
                            onClick={(e) => {
                              setDateFilterByDay(moment().startOf('day'));
                            }}
                          >
                            {t('buttons.btn_today')}
                          </AppButton>
                        </Col>
                      </Row>
                    </div>
                    {loadingByDay ? (
                      <Row>
                        <Col xs={12} style={{ height: '100%', paddingTop: 50, paddingBottom: 50, display: 'flex', justifyContent: 'center' }}>
                          <AppComponentLoading />
                        </Col>
                      </Row>
                    ) : (
                      <div style={{
                        width: '100%', 
                        height: '100%', 
                        border: '0px solid #E7ECF4', 
                        borderBottomLeftRadius: 16, 
                        borderBottomRightRadius: 16
                      }}
                      >
                        {shiftsByDayDataset.length > 0 ? (
                          <BootstrapTable
                            bootstrap4
                            rowClasses={rowClasses}
                            classes={`appTable appTable-row`}
                            keyField={'id'}
                            data={shiftsByDayDataset}
                            columns={columnsByDay}
                            headerClasses="app-table-header-class"
                            rowStyle={ { height: 44 } }
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </ChartsContainer>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <ChartsContainer style={{ height: '477px' }}>
                    <div style={{ width: '90%' }}>
                      <ChartTitle>{t('Dashboard.charts.counters.shifts_unassigned')}</ChartTitle>
                      <Row>
                        <Col xs={8}>
                          <DatePickerByDay
                            dateFilter={dateFilterUnassigned} 
                            openCalendarFilter={openCalendarFilterUnassigned} 
                            setDateFilter={setDateFilterUnassigned} 
                            setOpenCalendarFilter={setOpenCalendarFilterUnassigned}
                          />
                        </Col>
                        <Col xs={3}>
                          <AppButton
                            size="lg"
                            bgcolor={PALETTE_COLOR.MAIN_WHITE}
                            bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                            color={PALETTE_COLOR.MAIN_BLUE}
                            bordercolor={PALETTE_COLOR.TOP_GRAY}
                            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                            style={{ marginLeft: 6, marginRight: 4 }}
                            onClick={(e) => {
                              setDateFilterUnassigned(moment().startOf('day'));
                            }}
                          >
                            {t('buttons.btn_today')}
                          </AppButton>
                        </Col>
                      </Row>
                      {loadingUnassigned ? (
                        <Row style={{ height: '80%'}}>
                          <Col xs={12} style={{ height: '100%', paddingTop: 50, paddingBottom: 50, display: 'flex', justifyContent: 'center' }}>
                            <AppComponentLoading />
                          </Col>
                        </Row>
                      ): (
                        <Row style={{ height: '80%' }}>
                          <Col xs={3}>
                            <UnasignedShifts>
                              {unassignedShifts}
                            </UnasignedShifts>
                          </Col>
                          <Col xs={9} style={{ overflowY: 'auto', height: '100%' }}>
                            {unassignedShifts > 0 && (
                              <Row>
                                <Col xs={12} className="pt-3">
                                  <AvailableEmployees>
                                    {t('Dashboard.charts.available_employees')}
                                  </AvailableEmployees>
                                </Col>
                              </Row>
                            )}
                            {unassignedShifts > 0 && unassignedEmployees.map((emp, index) => (
                              <Row key={emp.id} style={{ marginBottom: 5 }}>
                                <Col xs={12}>
                                  <Row>
                                    <Col xs={2}>
                                      {emp?.photo_url ? (
                                        <Icon src={emp?.photo_url} />
                                      ) : (
                                        <UserAvatar src={Avatar} alt="avatar" />
                                      )}
                                    </Col>
                                    <Col xs={10}>
                                      {emp.name}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      )}
                    </div>
                  </ChartsContainer>
                </Col>
              </Row>
            </Col>
            <Col xs={7}>
              <ChartsContainer>
                <div style={{ width: '90%' }}>
                  <ChartTitle>{t('Dashboard.charts.counters.shifts_by_week')}</ChartTitle>
                  <Row>
                    <Col xs={5}>
                      <DatePickerByWeek
                        calendarConfig={calendarWeekConfig}
                        setCalendarConfig={setCalendarWeekConfig}
                      />
                    </Col>
                    <Col xs={5}>
                      <AppButton
                        size="lg"
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        style={{ width: 'auto' }}
                        onClick={(e) => {
                          setCalendarWeekConfig({
                            ...calendarWeekConfig,
                            currentWeek: {
                              start: moment.utc().startOf('week'),
                              end: moment.utc().endOf('week'),
                            },
                            day: moment(),
                          });
                        }}
                      >
                        {t('buttons.btn_this_week')}
                      </AppButton>
                    </Col>
                  </Row>
                  {loadingByWeek ? (
                    <Row>
                      <Col xs={12} style={{ height: '100%', paddingTop: 50, paddingBottom: 50, display: 'flex', justifyContent: 'center' }}>
                        <AppComponentLoading />
                      </Col>
                    </Row>
                  ): (
                    <Row>
                      <Col xs={12}>
                        <Row className="mt-3">
                          {shiftsByWeekDataset.length > 0 ? (
                            <BarChart
                              dataset={shiftsByWeekDataset}
                              yAxis={[{ scaleType: 'band', dataKey: 'dayOfWeek' }]}
                              series={[{ dataKey: 'count', label: `${t('Dashboard.charts.number_shifts_by_day')}`, valueFormatter }]}
                              layout="horizontal"
                              grid={{ vertical: true }}
                              {...shiftsByWeekChartSetting}
                              margin={{ top: 50, bottom: 30, left: 70, right: 10 }}
                              sx={{
                                "& .MuiChartsLegend-mark": {
                                  fill: "#00000000",
                                },
                                "& .MuiBarElement-root:nth-of-type(1)": {
                                  fill: colors[0]
                                },
                                "& .MuiBarElement-root:nth-of-type(2)": {
                                  fill: colors[1],
                                },
                                "& .MuiBarElement-root:nth-of-type(3)": {
                                  fill: colors[2],
                                },
                                "& .MuiBarElement-root:nth-of-type(4)": {
                                  fill: colors[3],
                                },
                                "& .MuiBarElement-root:nth-of-type(5)": {
                                  fill: colors[4],
                                },
                                "& .MuiBarElement-root:nth-of-type(6)": {
                                  fill: colors[5],
                                },
                                "& .MuiBarElement-root:nth-of-type(7)": {
                                  fill: colors[6],
                                },
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Col>
                      <Col xs={12} className="mt-3 mb-5">
                        {shiftsByWeekDataset.length > 0 ? (
                          <PieChart
                            series={[
                              {
                                arcLabel: getArcLabel,
                                arcLabelMinAngle: 40,
                                data: shiftsByWeekDataset.map((el, ix) => {
                                  return {
                                    id: ix,
                                    value: el.count,
                                    label: el.dayOfWeek,
                                  }
                                }),
                                innerRadius: 50,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                              },
                            ]}
                            sx={{
                              [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 11,
                              },
                            }}
                            height={250}
                            slotProps={{
                              legend: { hidden: true },
                            }}
                            margin={{ left: 90 }}
                            colors={mangoFusionPalette}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              </ChartsContainer>
            </Col>
          </Row>
        </Col>
        <Col xs={5}>
          <Row>
            <Col xs={12}>
              <ChartsContainer>
                <div style={{ width: '90%' }}>
                  <Row>
                    <Col xs={6}>
                      <ChartTitle>{t('Dashboard.charts.counters.shifts_by_month')}</ChartTitle>
                    </Col>
                    <Col xs={3} className="pt-3">
                      <DatePickerByMonth
                        calendarConfig={calendarMonthConfig}
                        setCalendarConfig={setCalendarMonthConfig}
                      />
                    </Col>
                    <Col xs={3} className="pt-3">
                      <AppButton
                        size="lg"
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        style={{ width: 'auto' }}
                        onClick={(e) => {
                          setCalendarMonthConfig({
                            ...calendarMonthConfig,
                            currentMonth: {
                              start: moment.utc().startOf('month'),
                              end: moment.utc().endOf('month'),
                            },
                            day: moment(),
                          });
                        }}
                      >
                        {t('buttons.btn_this_month')}
                      </AppButton>
                    </Col>
                  </Row>
                  {loadingByMonth ? (
                    <Row>
                      <Col xs={12} style={{ height: '100%', paddingTop: 50, paddingBottom: 50, display: 'flex', justifyContent: 'center' }}>
                        <AppComponentLoading />
                      </Col>
                    </Row>
                  ): (
                    <Row>
                      <Col xs={12}>
                        <Row className="mt-3">
                          <Col xs={8}>
                            {shiftsByMonthDataset.length > 0 ? (
                              <BarChart
                                dataset={shiftsByMonthDataset}
                                xAxis={[{ scaleType: 'band', dataKey: 'week' }]}
                                series={[{ dataKey: 'count', label: `${t('Dashboard.charts.number_shifts_by_week')}`, valueFormatter }]}
                                {...shiftsByMonthChartSetting}
                                margin={{ top: 50, bottom: 50, left: 70, right: 10 }}
                                sx={{
                                  "& .MuiChartsLegend-mark": {
                                    fill: "#00000000",
                                  },
                                  "& .MuiBarElement-root:nth-of-type(1)": {
                                    fill: colors[0]
                                  },
                                  "& .MuiBarElement-root:nth-of-type(2)": {
                                    fill: colors[1],
                                  },
                                  "& .MuiBarElement-root:nth-of-type(3)": {
                                    fill: colors[2],
                                  },
                                  "& .MuiBarElement-root:nth-of-type(4)": {
                                    fill: colors[3],
                                  },
                                  "& .MuiBarElement-root:nth-of-type(5)": {
                                    fill: colors[4],
                                  }
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col xs={4} className="mt-4">
                            <Row>
                              <Col xs={12}>
                                <MiddleMonthTitle>{t('Dashboard.charts.first_fortnight')}</MiddleMonthTitle>
                              </Col>
                              <Col xs={12}>
                                <MiddleMonthShifts>
                                  {filterShiftsFortnightOne}
                                </MiddleMonthShifts>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <MiddleMonthTitle>{t('Dashboard.charts.second_fortnight')}</MiddleMonthTitle>
                              </Col>
                              <Col xs={12}>
                                <MiddleMonthShifts>
                                  {filterShiftsFortnightTwo}
                                </MiddleMonthShifts>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <MiddleMonthTotalTitle>{t('form_labels.total')}</MiddleMonthTotalTitle>
                              </Col>
                              <Col xs={12}>
                                <MiddleMonthTotalShifts>
                                  {filterShiftsFortnightOne + filterShiftsFortnightTwo}
                                </MiddleMonthTotalShifts>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
              </ChartsContainer>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ChartsContainer style={{ flexDirection: 'column', height: '310px' }}>
                <div style={{ width: '90%', paddingLeft: 10, paddingBottom: 20 }}>
                  <Row>
                    <Col xs={6}>
                      <ChartTitle>{t('Dashboard.charts.counters.shifts_by_employee')}</ChartTitle>
                    </Col>
                    <Col xs={3} className="pt-3">
                      <DatePickerByMonth
                        calendarConfig={calendarEmployeeConfig}
                        setCalendarConfig={setCalendarEmployeeConfig}
                      />
                    </Col>
                    <Col xs={3} className="pt-3">
                      <AppButton
                        size="lg"
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        style={{ width: 'auto' }}
                        onClick={(e) => {
                          setCalendarEmployeeConfig({
                            ...calendarEmployeeConfig,
                            currentMonth: {
                              start: moment.utc().startOf('month'),
                              end: moment.utc().endOf('month'),
                            },
                            day: moment(),
                          });
                        }}
                      >
                        {t('buttons.btn_this_month')}
                      </AppButton>
                    </Col>
                  </Row>
                </div>
                {loadingByEmployee ? (
                  <Row>
                    <Col xs={12} style={{ height: '100%', paddingTop: 50, paddingBottom: 50, display: 'flex', justifyContent: 'center' }}>
                      <AppComponentLoading />
                    </Col>
                  </Row>
                ): (
                  <div style={{
                    width: '100%', 
                    height: '100%', 
                    border: '0px solid #E7ECF4', 
                    borderBottomLeftRadius: 16, 
                    borderBottomRightRadius: 16,
                    overflowY: 'auto', 
                  }}>
                    {employeesByMonthDataset.length > 0 ? (
                      <BootstrapTable
                        bootstrap4
                        rowClasses={rowClasses}
                        classes={`appTable appTable-row`}
                        keyField={'id'}
                        data={employeesByMonthDataset}
                        columns={columnsByEmployee}
                        headerClasses="app-table-header-class"
                        rowStyle={ { height: 31 } }
                        // wrapperClasses="table-responsive"
                      />
                    ) : (
                      <>
                        <NoData>{t('Dashboard.charts.no_employees_info')}<br/>
                          {t('Dashboard.charts.no_employees_info_on')}<br />
                          {`${capitalize(calendarEmployeeConfig.currentMonth.start.format('MMMM')).replace('.', '')} ${calendarEmployeeConfig.currentMonth.start.format('YYYY')}`}
                        </NoData>
                      </>
                    )}
                  </div>
                )}
              </ChartsContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};