import React, { useEffect } from 'react';
import {
  AppTable,
  AppButton,
  AppModal,
  AppOrgIcon,
  AppDarkTooltip,
  AppModalStatus,
} from '@components';
import EncList from '@components/EncList';
import {
  USERS_API,
  GROUPS_API,
  ORGANIZATIONS_API,
  REPORTS_JOBS_TYPES,
  REPORT_JOBS_API,
} from '@services/CONSTANTS';
import styled from 'styled-components';
import ic_eye from '@assets/icons/ic_eye.svg';
import status_active from '@assets/icons/status_active.svg';
import Status_block from '@assets/icons/Status_block.svg';
import Invited from '@assets/icons/Invited.svg';
import Draft from '@assets/icons/Draft.svg';
import Group3 from '@assets/icons/Group3.svg';
import Archived from '@assets/icons/Archived.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { AddOrganizationUsers } from './OrganizationUsers/AddOrganizationUsers';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import {
  VIEW_USER_ORGANIZATION,
  VIEW_USER_SYSTEM,
  USERS,
  VIEW_USER_EMPLOYEE,
} from '@navigation/CONSTANTS';
import { useHistory, Route, useParams } from 'react-router-dom';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { AddSystemUserForm } from './SystemUsers/AddSystemUserForm';
import { formatFullDate } from '@utils/dateFormatHelper';
import { AddEmplyUserForm } from './EmployeeUsers/AddEmplyUserForm';
import { size } from 'lodash';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getEntityData, obtainEntityData, saveEntityData } from '@services';
import { calcAvatar } from 'utils/avatar';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setGlobalOrganizationAction } from '@redux/actions/appActions';
import ico_warning_triangle from '@assets/icons/ico_warning_triangle.svg';

import { useTranslation } from 'react-i18next';

const StyledGroups = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const Edit = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledTag = styled.div`
  width: fit-content;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const sortArray = (array) => {
  array.sort(function (a, b) {
    if (a.group.name > b.group.name) {
      return 1;
    }
    if (a.group.name < b.group.name) {
      return -1;
    }
    return 0;
  });
  return array;
};

export const UsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = React.useState(false);
  const [openSystemForm, setOpenSystemForm] = React.useState(false);
  const [openEmplyForm, setOpenEmplyForm] = React.useState(false);
  const [organizationsList, setOrganizationsList] = React.useState([]);
  const { valuesDefinitions, userPermissions, userData, globalOrganizationData } = useSelector(
    (state) => state.app,
  );
  const [canCreateAUser, setCanCreateAUser] = React.useState(false);
  const [maxSystemUsers, setMaxSystemUsers] = React.useState(0);
  const [maxOrganizationUsers, setMaxOrganizationUsers] = React.useState(0);
  const [currentActiveUsers, setCurrentActiveUsers] = React.useState(0);
  const [openAlertMaxUsers, setOpenAlertMaxUsers] = React.useState(false);
  const [alertMaxUsersModalData, setAlertMaxUsersModalData] = React.useState({
    buttonText: `${t('buttons.btn_accept')}`,
    actionIcon: ico_warning_triangle,
    title: `${t('Users.modals.denied_request_title')}`,
    subtitle: ``,
    subject: ``,
    subtitleWeight: 400,
    subtitleColor: PALETTE_COLOR.BLACK,
    subjectColor: PALETTE_COLOR.ACCENT_RED,
    subjectWeight: 400,
    bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
    bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
    borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
  });

  const statuses = useMemo(
    () =>
      valuesDefinitions &&
      valuesDefinitions.filter((v) => v.definitionType.definition_type === 'USER_STATUS'),
    [valuesDefinitions],
  );

  const jobs_types = useMemo(
    () =>
      valuesDefinitions &&
      valuesDefinitions.filter((v) => v.definitionType.definition_type === 'JOBS_TYPES'),
    [valuesDefinitions],
  );

  //Filers & joins
  const employeeJoins = useMemo(() => {
    /* return [
       { field: 'organizations_users', select: ['organization_id'] },
       {
         field: 'organizations_users.organization_id',
         select: ['color', 'name'],
       },
     ];*/
    return [{ field: 'omitOrgSiteSearch' }];
  }, []);

  const organizationJoins = useMemo(() => {
    return [
      { field: 'user_sites', select: ['name', 'organization_id'] },
      { field: 'user_sites.organization_id' },
      { field: 'organizations_users' },
      { field: 'organizations_users.organization_id' },
      { field: 'organizations_users.organization_id.organizations_sites' },
      { field: 'organizations_users.organization_id.organizations_sites.groupsSite' },
      { field: 'organizations_users.organization_id.organizations_sites.groupsSite.group' },
      { field: 'organizations_users.organization_user_sites' },
      { field: 'organizations_users.organization_user_sites.site' },
      { field: 'organizations_users.organization_user_sites.site.organization_id' },
    ];
  }, []);

  const [groups, setGroups] = React.useState([]);

  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });

  //Get type from url
  let { type } = useParams();

  //Control what kinf of list user is
  const [userType, setUserType] = React.useState(type ? type : 'employees');

  let history = useHistory();

  let goTo = useCallback(
    (route) => {
      history.push(route);
    },
    [history],
  );

  const [employeeFilters, setEmployeeFilters] = React.useState([
    {
      field: 'type.description',
      operator: CondOperator.EQUALS,
      value: 'Employees',
    },
  ]);

  const [organizationFilters, setOrganizationFilters] = React.useState([
    {
      field: 'type.description',
      operator: CondOperator.EQUALS,
      value: 'Organizations',
    },
  ]);

  const [systemFilter, setSystemFilter] = React.useState([
    {
      field: 'type.description',
      operator: CondOperator.EQUALS,
      value: 'System',
    },
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (Object.keys(globalOrganizationData).length === 0) {
          getEntityData(`${ORGANIZATIONS_API}organization/initial`, '').then((resMinOrg) => {
            dispatch(setGlobalOrganizationAction(resMinOrg));
            const maxSystemUs = Number(
              resMinOrg.organizations_parameters.find(
                (el) => el.name === 'system_users_access_limit',
              ).value,
            );
            const maxOraganizationUs = Number(
              resMinOrg.organizations_parameters.find(
                (el) => el.name === 'organization_users_access_limit',
              ).value,
            );
            setMaxSystemUsers(maxSystemUs);
            setMaxOrganizationUsers(maxOraganizationUs);
          });
        } else {
          const maxSystemUs = Number(
            globalOrganizationData.organizations_parameters.find(
              (el) => el.name === 'system_users_access_limit',
            ).value,
          );
          const maxOraganizationUs = Number(
            globalOrganizationData.organizations_parameters.find(
              (el) => el.name === 'organization_users_access_limit',
            ).value,
          );
          setMaxSystemUsers(maxSystemUs);
          setMaxOrganizationUsers(maxOraganizationUs);
        }
      } catch (error) {
        console.log('📌 - file: UsersList.js:227 - error:', error);
      }
    })();
  }, [dispatch, globalOrganizationData]);

  useEffect(() => {
    if (userType !== 'employees' && Object.keys(globalOrganizationData).length > 0) {
      (async () => {
        try {
          obtainEntityData(
            `${USERS_API}users/count-active-users/${userType}/${globalOrganizationData.id}`,
          )
            .then((res) => {
              setCurrentActiveUsers(Number(res));
            })
            .catch((err) => {
              console.log('📌 - file: UsersList.js:242 - err:', err);
            });
        } catch (error) {
          console.log('📌 - file: UsersList.js:245 - error:', error);
        }
      })();
    }
  }, [globalOrganizationData, organizationFilters, systemFilter, userType]);

  /***
   * Get groups to filter
   */
  useEffect(() => {
    //Redirection if doesn't have enough privileges
    userData.isSiteManager && history.push('/Dashboard');

    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroups(userPermissions?.systemUserGroups);
        } else {
          setGroups([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroups(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions, history, userData.isSiteManager]);

  /***
   * Get organizations to filter
   */
  /* useEffect(() => {
    const getOrganizations = () => {
      let qb;
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name'])
          .setJoin([
            { field: 'organizations_sites' },
            { field: 'organizations_sites.groupsSite' },
            { field: 'organizations_sites.groupsSite.group' },
          ])
          .setFilter({
            field: 'organizationsGroupsSites.id',
            operator: CondOperator.IN,
            value:
              userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0
                ? userPermissions?.systemUserGroups?.map((g) => g.id)
                : -1,
          });
      } else {
        qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
      }
      //Call API
      getEntityData(ORGANIZATIONS_API, qb.query())
        .then((res) => {
          setOrganizationsList(res);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    };
    getOrganizations();
  }, [userPermissions]);*/

  /*useEffect(() => {
    

    //Mandatory Filters for consulting
const filters = [{ field: 'type.description', operator: CondOperator.EQUALS, value: 'System' }];
const filtersOrg = [
  {
    field: 'type.description',
    operator: CondOperator.EQUALS,
    value: 'Organizations',
  },
];
const filtersEmply = [
  {
    field: 'type.description',
    operator: CondOperator.EQUALS,
    value: 'Employees',
  },
];
  

  }, [])*/
  /***
   * Only get org which belong to user groups
   */
  useEffect(() => {
    if (userPermissions && userPermissions?.type) {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setEmployeeFilters([
            {
              field: 'type.description',
              operator: CondOperator.EQUALS,
              value: 'Employees',
            },
            {
              field: 'group.id',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
          setOrganizationFilters([
            {
              field: 'type.description',
              operator: CondOperator.EQUALS,
              value: 'Organizations',
            },
            {
              field: 'orgUsersOrganizationIdSitesGroup.id',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
          setSystemFilter([
            { field: 'type.description', operator: CondOperator.EQUALS, value: 'System' },
            {
              field: 'user_groups.group',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
        } else {
          //User with no groups assigned
          setEmployeeFilters([
            {
              field: 'type.description',
              operator: CondOperator.EQUALS,
              value: 'user', //Something that do not bring data
            },
          ]);
          setOrganizationFilters([
            {
              field: 'type.description',
              operator: CondOperator.EQUALS,
              value: 'user', //Something that do not bring data
            },
          ]);
          setSystemFilter([
            {
              field: 'type.description',
              operator: CondOperator.EQUALS,
              value: 'user', //Something that do not bring data
            },
          ]);
        }
      } else {
        //Not a system user
        setEmployeeFilters([
          {
            field: 'type.description',
            operator: CondOperator.EQUALS,
            value: 'Employees',
          },
        ]);
        setOrganizationFilters([
          {
            field: 'type.description',
            operator: CondOperator.EQUALS,
            value: 'Organizations',
          },
        ]);
        setSystemFilter([
          {
            field: 'type.description',
            operator: CondOperator.EQUALS,
            value: 'System',
          },
        ]);
      }
    }
  }, [userPermissions]);

  /***
   *
   */
  useEffect(() => {
    if (userPermissions) {
      if (userPermissions?.type === 'system') {
        setCanCreateAUser(userPermissions?.systemUserPermission);
      } else {
        setCanCreateAUser(true);
      }
    }
  }, [userPermissions]);

  const handleClickOpenFormModal = () => {
    if (userType === 'employees') {
      setOpenForm(true);
    } else {
      const allowedUsers = userType === 'system' ? maxSystemUsers : maxOrganizationUsers;
      if (allowedUsers > currentActiveUsers) {
        setOpenForm(true);
      } else {
        const subt = `${t('Users.modals.denied_request_subtitle')}`
          .replace('{num}', allowedUsers)
          .replace('{type}', `"${t('Users.header_buttons.organization_users')}"`);
        setAlertMaxUsersModalData((prev) => ({
          ...prev,
          subject: `${t('Users.organizations.modal_add.title')}`,
          subtitle: `${subt}`,
        }));
        setOpenAlertMaxUsers(true);
      }
    }
  };

  const handleClickOpenSystemFormModal = () => {
    const allowedUsers = userType === 'system' ? maxSystemUsers : maxOrganizationUsers;
    if (allowedUsers > currentActiveUsers) {
      setOpenSystemForm(true);
    } else {
      const subt = `${t('Users.modals.denied_request_subtitle')}`
        .replace('{num}', allowedUsers)
        .replace('{type}', `"${t('Users.header_buttons.system_users')}"`);
      setAlertMaxUsersModalData((prev) => ({
        ...prev,
        subject: `${t('Users.system.modal_add.title')}`,
        subtitle: `${subt}`,
      }));
      setOpenAlertMaxUsers(true);
    }
  };

  const handleAlertMaxUsers = () => {
    setOpenAlertMaxUsers(false);
  };

  const handleClickOpenEmplyFormModal = () => {
    setOpenEmplyForm(true);
  };

  const handleCloseSignUpModal = () => {
    setOpenForm(false);
  };

  const handleCloseSystemFormModal = () => {
    setOpenSystemForm(false);
  };

  const handleCloseEmplyFormModal = () => {
    setOpenEmplyForm(false);
  };

  //Employees users columns
  const getEmployeeUserColumns = useMemo(() => {
    return [
      {
        dataField: 'employee_id',
        disableColumnToggle: true,
        text: `${t('table_columns.employee_id')}`,
        sort: true,
        search: true,
        headerStyle: { width: '10%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.employee_id}</StyledLabel>;
        },
      },
      {
        dataField: 'name',
        disableColumnToggle: true,
        text: `${t('table_columns.user')}`,
        sort: true,
        search: true,
        headerStyle: { width: '20%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.name}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {calcAvatar(row)}
                <span style={{ marginLeft: 10 }}>{row.name}</span>
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'email',
        disableColumnToggle: true,
        text: `${t('table_columns.email')}`,
        sort: false,
        search: true,
        headerStyle: { width: '25%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.email}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.email}
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'phone',
        disableColumnToggle: true,
        text: `${t('table_columns.phone')}`,
        sort: false,
        search: true,
        headerStyle: { width: '15%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.phone}</StyledLabel>;
        },
      },
      {
        dataField: 'job_title.description',
        text: `${t('table_columns.job_title')}`,
        exportFormatter: (row) => {
          const jobs_titles = row.jobs_titles.find(
            (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
          );
          //ACTIVE ONLY
          return jobs_titles && jobs_titles.job_title.description;
        },
        sort: false,
        search: false,
        headerStyle: { width: '10%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (size(row.jobs_titles) > 0) {
            const jobs_titles = row.jobs_titles.filter(
              (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
            ); //ACTIVE ONLY
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {jobs_titles.map((org, index) => (
                  <StyledGroups key={index}>{org.job_title.description}</StyledGroups>
                ))}
              </div>
            );
          }
        },
        advanceFilter: jobs_types && [
          ...jobs_types.map((s) => ({
            label: s.description,
            value: s.description,
          })),
        ],
      },
      {
        dataField: 'group.name',
        text: `${t('table_columns.group')}`,
        sort: false,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        exportFormatter: (row) => {
          return row.user_groups && row.user_groups.map((ug, index) => ug.group.name).join(',');
        },
        formatter: (cellContent, row) => {
          if (size(row.user_groups) > 0) {
            const user_groups = row.user_groups.filter((v) => v.created_at !== 'null');
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {user_groups.map((org, index) => (
                  <StyledGroups key={index}>{org.group.name}</StyledGroups>
                ))}
              </div>
            );
          }
        },

        advanceFilter: groups && [...groups.map((s) => ({ label: s.name, value: s.name }))],
      },
      /*   {
           dataField: 'orgUsersOrganizationId.name',
           text: `${t('table_columns.organization')}`,
           sort: false,
           headerStyle: { width: '15%' },
           style: { height: '54px', overflow: 'hidden' },
           exportFormatter: (row) => {
             return (
               row.organizations_users &&
               row.organizations_users.map((org, index) => org.organization_id.name).join(',')
             );
           },
           formatter: (cellContent, row) => {
             if (size(row.organizations_users) > 0) {
               if (size(row.organizations_users) === 1) {
                 return (
                   <div style={{ display: 'flex' }}>
                     {row.organizations_users.map((org, index) => (
                       <div style={{ display: 'flex' }} key={index}>
                         <AppOrgIcon
                           color={org.organization_id.color}
                           style={{ width: 35, height: 35, fontSize: 12, padding: 5 }}
                         >
                           {org.organization_id.name.substring(0, 2).toUpperCase()}
                         </AppOrgIcon>
                         <StyledLabel style={{ margin: '4px' }}>
                           {org.organization_id.name}
                         </StyledLabel>
                       </div>
                     ))}
                   </div>
                 );
               } else {
                 return (
                   <div style={{ display: 'flex', flexWrap: 'wrap', padding: '14px' }}>
                     {row.organizations_users.map((org, index) => (
                       <div style={{ display: 'flex', marginLeft: '-10px' }} key={index}>
                         <AppOrgIcon
                           color={org.organization_id.color}
                           style={{
                             width: 35,
                             height: 35,
                             fontSize: 12,
                             padding: 5,
                             cursor: 'pointer',
                           }}
                           title={org.organization_id.name}
                         >
                           {org.organization_id.name.substring(0, 2).toUpperCase()}
                         </AppOrgIcon>
                       </div>
                     ))}
                   </div>
                 );
               }
             }
           },
           advanceFilter: organizationsList && [
             ...organizationsList.map((s) => ({ label: s.name, value: s.name })),
           ],
         },*/
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        sort: false,
        search: true,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (row.status.value_definition === '010') {
            return (
              <StyledLabel>
                <Status src={status_active} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '030') {
            return (
              <StyledLabel>
                <Status src={Invited} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '020') {
            return (
              <StyledLabel>
                <Status src={Draft} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '050') {
            return (
              <StyledLabel>
                <Status src={Status_block} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '060') {
            return (
              <StyledLabel>
                <Status src={Archived} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
        },

        advanceFilter: statuses && [
          ...statuses
            .filter((s) => s.description !== 'Deactive')
            .map((s) => ({
              label: `${t(`Users.status.${s.description}`)}`,
              value: s.description,
            })),
          {
            label: `${t('Users.archived_only')}`,
            value: 'archived*',
            isFooter: true,
            targetSelection: [`${t('Users.status.Archived')}`],
          },
        ],
      },
      {
        dataField: 'updated_at',
        text: `${t('table_columns.updated_at')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.updated_at !== null ? formatFullDate(row.updated_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'created_at',
        text: `${t('table_columns.created_at')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.created_at && moment(row.created_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.created_at !== null ? formatFullDate(row.created_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'last_login',
        text: `${t('table_columns.last_login')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.last_login && moment(row.last_login).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.last_login !== null ? formatFullDate(row.last_login) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'view',
        isDummyField: true,
        text: `${t('table_columns.view')}`,
        headerStyle: { width: '7%' },
        style: { height: '54px', cursor: 'pointer', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <Route
              render={({ history }) => (
                <StyledLabel
                  style={{
                    marginLeft: '0px',
                    justifyContent: 'center',
                    alignitems: 'center',
                  }}
                  onClick={() => {
                    history.push(VIEW_USER_EMPLOYEE.replace(':userId', row.id) + '?b=true');
                  }}
                >
                  <Edit src={ic_eye} alt="logo"></Edit>
                </StyledLabel>
              )}
            />
          );
        },
      },
    ];
  }, [statuses, jobs_types, groups, organizationsList, t]);

  //Organization users columns
  const getOrgUserColumns = useMemo(() => {
    return [
      {
        dataField: 'name',
        text: `${t('table_columns.user')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '25%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.name}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {calcAvatar(row)}
                <span style={{ marginLeft: 10 }}>{row.name}</span>
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'email',
        text: `${t('table_columns.email')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '25%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.email}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.email}
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'phone',
        text: `${t('table_columns.phone')}`,
        sort: false,
        search: false,
        disableColumnToggle: true,
        headerStyle: { width: '15%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.phone}</StyledLabel>;
        },
      },
      {
        dataField: 'role.description',
        text: `${t('table_columns.role')}`,
        sort: false,
        search: false,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (row.role !== null && row.role !== 'null') {
            return (
              <StyledLabel>
                {t(
                  `Users.organizations.view.header_info.${row.role.description.replace(
                    /\s/g,
                    '_',
                  )}`,
                )}
              </StyledLabel>
            );
          }
        },
      },
      {
        dataField: 'row.organizations',
        text: `${t('table_columns.organization')}`,
        sort: false,
        search: false,
        headerStyle: { width: '25%' },
        exportFormatter: (row) => {
          let dataValues = row.organizations.map((org) => org.name).join(',');
          row.organizations_users.forEach((organization_user) => {
            dataValues =
              dataValues +
              ',' +
              organization_user.organization_user_sites
                .map((orgUserSite) => orgUserSite.site.name)
                .join(',');
          });
          return dataValues;
        },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {row.organizations.map((org, index) => (
                <div
                  style={{ display: 'flex', marginLeft: index === 0 ? '0px' : '-15px' }}
                  key={index}
                >
                  <AppOrgIcon
                    color={org.color}
                    title={org.name}
                    style={{ width: 35, height: 35, fontSize: 12, padding: 5, cursor: 'pointer' }}
                  >
                    {org.name.substring(0, 2).toUpperCase()}
                  </AppOrgIcon>
                  {row.organizations_users.length + row.organizations.length === 1 ? (
                    <StyledLabel style={{ margin: '4px', cursor: 'pointer' }}>
                      {org.name}
                    </StyledLabel>
                  ) : null}
                </div>
              ))}
              {row.organizations_users.map((organization_user) =>
                organization_user.organization_user_sites.map((orgUserSite, index) => (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: index === 0 ? '0px' : '-15px',
                      cursor: 'pointer',
                    }}
                    key={index}
                  >
                    <AppOrgIcon
                      color={orgUserSite.site.organization_id.color}
                      title={orgUserSite.site.name}
                      style={{ width: 35, height: 35, fontSize: 12, padding: 5, cursor: 'pointer' }}
                    >
                      {orgUserSite.site.name.substring(0, 2).toUpperCase()}
                    </AppOrgIcon>
                    {row.organizations_users.length + row.organizations.length === 1 ? (
                      <StyledLabel style={{ margin: '4px' }}>{orgUserSite.site.name}</StyledLabel>
                    ) : null}
                  </div>
                )),
              )}
              {row.organizations_users.length + row.organizations.length === 0 ? (
                <StyledLabel
                  style={{ margin: '4px', color: PALETTE_COLOR.LIGHT_GREY, fontSize: 14 }}
                >
                  {t('not_assigned')}
                </StyledLabel>
              ) : null}
            </div>
          );
        },
      },
      {
        dataField: 'updated_at',
        text: `${t('table_columns.updated_at')}`,
        sort: false,
        search: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.updated_at !== null ? formatFullDate(row.updated_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'created_at',
        text: `${t('table_columns.created_at')}`,
        sort: false,
        search: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.created_at && moment(row.created_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.created_at !== null ? formatFullDate(row.created_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'last_login',
        text: `${t('table_columns.last_login')}`,
        sort: false,
        search: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.last_login && moment(row.last_login).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.last_login !== null ? formatFullDate(row.last_login) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        sort: false,
        search: false,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (row.status.value_definition === '010') {
            return (
              <StyledLabel>
                <Status src={status_active} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '030') {
            return (
              <StyledLabel>
                <Status src={Invited} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '020') {
            return (
              <StyledLabel>
                <Status src={Draft} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '050') {
            return (
              <StyledLabel>
                <Status src={Status_block} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '060') {
            return (
              <StyledLabel>
                <Status src={Archived} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
        },
        advanceFilter: statuses && [
          ...statuses
            .filter((s) => s.description !== 'Deactive')
            .map((s) => ({
              label: `${t(`Users.status.${s.description}`)}`,
              value: s.description,
            })),
          {
            label: `${t('Users.archived_only')}`,
            value: 'archived*',
            isFooter: true,
            targetSelection: [`${t('Users.status.Archived')}`],
          },
        ],
      },
      {
        dataField: 'edit',
        isDummyField: true,
        text: `${t('table_columns.view')}`,
        headerStyle: { width: '7%' },
        style: { height: '54px', cursor: 'pointer', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel
              style={{
                marginLeft: '0px',
                justifyContent: 'center',
                alignitems: 'center',
              }}
            >
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            goTo(VIEW_USER_ORGANIZATION.replace(':userId', `${row.id}?b=true`));
          },
        },
      },
    ];
  }, [goTo, statuses, t]);

  //System users columns
  const getSystemColumns = useMemo(() => {
    return [
      {
        dataField: 'name',
        text: `${t('table_columns.user')}`,
        sort: true,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '20%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.name}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {calcAvatar(row)}
                <span style={{ marginLeft: 10 }}>{row.name}</span>
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'email',
        text: `${t('table_columns.email')}`,
        sort: false,
        search: false,
        disableColumnToggle: true,
        headerStyle: { width: '20%' },
        style: { height: '50px' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={`${row.email}`}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.email}
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'role.description',
        text: `${t('table_columns.type')}`,
        sort: false,
        search: false,
        disableColumnToggle: true,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.role?.description ? (
                t(`roles.${row.role?.description.replace(/\s/g, '_')}`)
              ) : (
                <>&nbsp;</>
              )}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'group.name',
        text: `${t('table_columns.groups')}`,
        sort: false,
        disableColumnToggle: true,
        exportFormatter: (row) => {
          const sortedArray = sortArray(row?.user_groups);
          if (row.role?.value_definition === '040') {
            return `${t('Users.all_groups')}`;
          }
          return sortedArray.map((ug) => ug.group?.name).join(',');
        },
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden', padding: '0px' },
        formatter: (cellContent, row) => {
          const sortedArray = sortArray(row?.user_groups);
          if (row.role?.value_definition === '040') {
            return (
              <StyledCell
                style={{
                  display: 'block',
                  paddingTop: '13px',
                  paddingLeft: 5,
                }}
              >
                {t('Users.all_groups')}
              </StyledCell>
            );
          }
          return row?.user_groups.length > 0 ? (
            sortedArray?.map((item, i) => (
              <div
                style={{
                  borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                }}
                key={i}
              >
                <StyledCell
                  style={{
                    display: 'block',
                    paddingTop: '13px',
                    paddingLeft: 5,
                  }}
                >
                  {item.group?.name}
                </StyledCell>
              </div>
            ))
          ) : (
            <StyledLabel style={{ color: PALETTE_COLOR.LIGHT_GREY }}>
              {t('not_assigned')}
            </StyledLabel>
          );
        },
        advanceFilter: groups && [...groups.map((s) => ({ label: s.name, value: s.name }))],
      },
      {
        dataField: 'permision',
        text: `${t('table_columns.permissions')}`,
        sort: false,
        disableColumnToggle: true,
        exportFormatter: (row) => {
          const sortedArray = sortArray(row?.user_groups);
          if (row.role?.value_definition === '040') {
            return `${t('Users.permissions.Full_Access')}`;
          }
          return sortedArray.map((ug) => ug.group_permission?.description).join(',');
        },
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden', padding: '0px' },
        formatter: (cellContent, row) => {
          if (row.role?.value_definition === '040') {
            return (
              <StyledCell style={{ display: 'block', paddingTop: '9px' }}>
                {' '}
                <StyledTag>{`${t('Users.permissions.Full_Access')}`}</StyledTag>
              </StyledCell>
            );
          }
          return row?.user_groups.length > 0 ? (
            row?.user_groups.map((item, i) => (
              <div
                style={{
                  borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                }}
                key={i}
              >
                <StyledCell style={{ display: 'block', paddingTop: '9px' }}>
                  {item.group_permission?.description && (
                    <StyledTag>{`${t(
                      `Users.permissions.${item.group_permission?.description
                        .replace(/\s{2,}/g, ' ')
                        .replace(/\s/g, '_')}`,
                    )}`}</StyledTag>
                  )}
                </StyledCell>
              </div>
            ))
          ) : (
            <StyledLabel style={{ color: PALETTE_COLOR.LIGHT_GREY }}>{`${t(
              'Users.permissions.non',
            )}`}</StyledLabel>
          );
        },
      },
      {
        dataField: 'user_system_rol',
        text: `${t('table_columns.role')}`,
        sort: false,
        search: false,
        headerStyle: { width: '8%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (row.role?.value_definition === '040') {
            return (
              <StyledCell style={{ display: 'block', paddingTop: '9px' }}>
                {' '}
                <StyledTag>{`N/A`}</StyledTag>
              </StyledCell>
            );
          }
          return <StyledLabel>{row.user_system_rol}</StyledLabel>;
        },
      },
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        sort: false,
        search: false,
        headerStyle: { width: '11%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (row.status.value_definition === '010') {
            return (
              <StyledLabel>
                <Status src={status_active} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '030') {
            return (
              <StyledLabel>
                <Status src={Invited} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '020') {
            return (
              <StyledLabel>
                <Status src={Draft} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '050') {
            return (
              <StyledLabel>
                <Status src={Status_block} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
          if (row.status.value_definition === '060') {
            return (
              <StyledLabel>
                <Status src={Archived} alt="logo"></Status>
                <StyledIconLabel>{t(`Users.status.${row.status.description}`)}</StyledIconLabel>
              </StyledLabel>
            );
          }
        },
        advanceFilter: statuses && [
          ...statuses
            .filter((s) => s.description !== 'Deactive')
            .map((s) => ({
              label: `${t(`Users.status.${s.description}`)}`,
              value: s.description,
            })),
          {
            label: `${t('Users.archived_only')}`,
            value: 'archived*',
            isFooter: true,
            targetSelection: [`${t('Users.status.Archived')}`],
          },
        ],
      },
      {
        dataField: 'updated_at',
        text: `${t('table_columns.updated_at')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.updated_at !== null ? formatFullDate(row.updated_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'created_at',
        text: `${t('table_columns.created_at')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.created_at && moment(row.created_at).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.created_at !== null ? formatFullDate(row.created_at) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'last_login',
        text: `${t('table_columns.last_login')}`,
        sort: false,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        exportFormatter: (row) => {
          return row.last_login && moment(row.last_login).format('MM/DD/yyyy hh:mm A');
        },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel>
              {row.last_login !== null ? formatFullDate(row.last_login) : ''}
            </StyledLabel>
          );
        },
      },
      {
        dataField: 'edit',
        isDummyField: true,
        text: `${t('table_columns.view')}`,
        headerStyle: { width: '6%' },
        style: { height: '54px', cursor: 'pointer', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel
              style={{
                marginLeft: '0px',
                justifyContent: 'center',
                alignitems: 'center',
              }}
            >
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            goTo(VIEW_USER_SYSTEM.replace(':userId', `${row.id}?b=true`));
          },
        },
      },
    ];
  }, [goTo, statuses, groups, t]);

  /***
   * Add to history the current url
   */
  const handleChangeTypeURL = (type) => {
    if (type === 'employees') {
      history.push(USERS.replace(':type', 'employees'));
    } else if (type === 'organization') {
      history.push(USERS.replace(':type', 'organization'));
    } else if (type === 'system') {
      history.push(USERS.replace(':type', 'system'));
    } else {
      history.push(USERS.replace(':type', 'employees'));
    }
  };

  /***
   * To know which user type is and what button activate
   */
  const getActiveTabIndex = (type) => {
    if (type === 'employees') {
      return 0;
    } else if (type === 'organization') {
      return 1;
    } else if (type === 'system') {
      return 2;
    } else {
      return 0;
    }
  };

  const exportEmployees = () => {
    saveEntityData(`${REPORT_JOBS_API}`, { type: REPORTS_JOBS_TYPES.ExportEmployees }).then(
      (res) => {
        console.log('Location aws results=', res);
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.export_request_sent')}`,
          message: '',
        });
      },
      (error) => {
        console.log('Error', error);
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_exporting_request')}`,
          message: '',
        });
      },
    );
  };

  return (
    <div>
      <EncList
        title={t('Users.title')}
        indexActive={getActiveTabIndex(userType)}
        icon={Group3}
        options={[
          {
            label: `${t('Users.header_buttons.employee_users')}`,
            onClick: () => {
              setUserType('employees');
              handleChangeTypeURL('employees');
            },
          },
          {
            label: `${t('Users.header_buttons.organization_users')}`,
            onClick: () => {
              setUserType('organization');
              handleChangeTypeURL('organization');
            },
          },
          {
            label: `${t('Users.header_buttons.system_users')}`,
            onClick: () => {
              setUserType('system');
              handleChangeTypeURL('system');
            },
          },
        ]}
      ></EncList>
      {userType === 'organization' ? (
        <AppTable
          key="organization"
          endpointPath={USERS_API}
          columns={getOrgUserColumns}
          withColumnToggler={true}
          withAdvanceFilter={true}
          joins={organizationJoins}
          csvName={t('csv_names.export_organization_users_csv_name')}
          withExport={true}
          reload={showSucessModal.show}
          filters={organizationFilters}
          trailingBtn={
            canCreateAUser && (
              <AppButton
                style={{
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: 'auto',
                }}
                tooltiptitle={t('tooltips.add_organization_user')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenFormModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      ) : userType === 'system' ? (
        <AppTable
          key="system"
          endpointPath={USERS_API}
          columns={getSystemColumns}
          withColumnToggler={true}
          withAdvanceFilter={true}
          csvName={t('csv_names.export_system_users_csv_name')}
          withExport={true}
          reload={showSucessModal.show}
          filters={systemFilter}
          trailingBtn={
            canCreateAUser && (
              <AppButton
                style={{
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: 'auto',
                }}
                tooltiptitle={t('tooltips.add_system_user')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenSystemFormModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      ) : (
        employeeFilters !== null &&
        userType === 'employees' && (
          <AppTable
            key="employees"
            endpointPath={USERS_API}
            columns={getEmployeeUserColumns}
            reload={showSucessModal.show}
            withAdvanceFilter={true}
            withColumnToggler={true}
            csvName={t('csv_names.export_employee_users_csv_name')}
            withExport={true}
            exportFunction={exportEmployees}
            filters={employeeFilters}
            joins={employeeJoins}
            trailingBtn={
              canCreateAUser && (
                <AppButton
                  style={{
                    fontSize: '14px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: 'auto',
                  }}
                  tooltiptitle={t('tooltips.add_employee_user')}
                  bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                  bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                  bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                  borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                  icon={ic_add_circle}
                  onClick={() => handleClickOpenEmplyFormModal()}
                >
                  {t('buttons.btn_add')}
                </AppButton>
              )
            }
          />
        )
      )}
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      <AppModal
        open={openForm}
        handleclose={handleCloseSignUpModal}
        title={t('Users.organizations.modal_add.title')}
        inside={1}
      >
        <AddOrganizationUsers
          handleclose={handleCloseSignUpModal}
          setShowSucessModal={setShowSucessModal}
        />
      </AppModal>

      <AppModal
        open={openSystemForm}
        handleclose={handleCloseSystemFormModal}
        title={t('Users.system.modal_add.title')}
        inside={1}
      >
        <AddSystemUserForm
          handleclose={handleCloseSystemFormModal}
          setShowSucessModal={setShowSucessModal}
        />
      </AppModal>

      <AppModal
        open={openEmplyForm}
        handleclose={handleCloseEmplyFormModal}
        title={t('Users.employees.modal_add.title')}
        inside={1}
      >
        <AddEmplyUserForm
          handleclose={handleCloseEmplyFormModal}
          setShowSucessModal={setShowSucessModal}
        />
      </AppModal>

      <AppModal
        width={500}
        styleModal={{ padding: '30px 5px 30px 5px', width: '600px' }}
        styles={{}}
        open={openAlertMaxUsers}
        handleclose={handleAlertMaxUsers}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={alertMaxUsersModalData.actionIcon}
          title={alertMaxUsersModalData.title}
          subtitle={alertMaxUsersModalData.subtitle}
          subtitleColor={alertMaxUsersModalData.subtitleColor}
          subtitleWeight={alertMaxUsersModalData.subtitleWeight}
          bgcolor={alertMaxUsersModalData.bgcolor}
          bordercolor={alertMaxUsersModalData.bordercolor}
          borderhovercolor={alertMaxUsersModalData.borderhovercolor}
          bghovercolor={alertMaxUsersModalData.bghovercolor}
          boxShadow={alertMaxUsersModalData.boxShadow}
          buttonText={alertMaxUsersModalData.buttonText}
          subject={alertMaxUsersModalData.subject}
          subjectColor={alertMaxUsersModalData.subjectColor}
          subjectWeight={alertMaxUsersModalData.subjectWeight}
          buttonAction={handleAlertMaxUsers}
        />
      </AppModal>
    </div>
  );
};
