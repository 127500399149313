import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, InputForm, AppDropDownSelect, AppDropDownMultipleSelect } from '@components';
import { saveEntityData, getEntityData } from '@services';
import { USERS_API, ORGANIZATIONS_API, SITES_API, USERS_GROUPS } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { useSelector } from 'react-redux';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { calcOrgCircle, SingleOption } from 'utils/coloredDot';
import EllipseIcon from '@assets/icons/ic_grey_ellipse.svg';
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const AddOrganizationUsers = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  //Get logged user info
  const { userData } = useSelector((state) => state.app);
  const { valuesDefinitions } = useSelector((state) => state.app);
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [roleUser, setRoleUser] = useState();
  const [permissionList, setPermissionList] = useState([]);
  //Variable to set default value. 'Only View'
  const defaultPermission = valuesDefinitions.filter(
    (v) =>
      v.definitionType.definition_type === 'USERS_GROUPS_PERMISSION' &&
      v.value_definition === '010',
  )[0];
  const [loading, setLoading] = useState(false);
  const rolesUsers = valuesDefinitions.filter(
    (v) =>
      v.definitionType.definition_type === 'ORGANIZATION_USER_ROLE' && v.validation_type === null,
  );
  //Store all Org
  const [organizations, setOrganizations] = useState([]);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);
  const [organizationSelected, setOrganizationSelected] = useState();
  //Store all Sites
  const [sites, setSites] = useState([]);
  const [isLoadingSites, setIsLoadingSites] = useState(false);
  //Store all userGropus
  const [loggedUserGroups, setLoggedUserGroups] = useState([]);

  const addUserSchema = yup.object().shape({
    name: yup.string().required(`${t('validation.name_required')}`),
    email: yup
      .string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    phone: yup
      .string()

      .required(`${t('validation.phone_required')}`),
    role_id: yup.object().nullable().required(`${t('validation.role_required')}`),
    permission: yup.object().nullable().required(`${t('validation.permission_required')}`),
    organizationOR: yup.array().nullable(),
    organizationSM: yup.object().nullable(),
    sites: yup
      .array()
      .nullable()
      .when('organizationSM', {
        is: (organizationSM) => organizationSM !== null,
        then: yup
          .array()
          .required(`${t('validation.site_required')}`)
          .min(1, `${t('validation.site_selected')}`),
      }),
  });

  //Obj to be sent to api
  let requestCreateOrgUser = {
    name: '',
    phone: '',
    email: '',
    status: {},
    role_id: '',
    organizations: [],
    sites: [],
  };

  const requestStatusCreate = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '030',
  )[0]; //Invited
  const requestStatusDraft = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '020',
  )[0]; //Draft

  /***
   * Filter only org that belong to user groups
   */
  const getOrgFn = useCallback((org, userGroups) => {
    setLoggedUserGroups(userGroups);
    if (org?.organizations_sites?.length > 0) {
      for (let index = 0; index < org.organizations_sites.length; index++) {
        const site = org.organizations_sites[index];
        if (site?.groupsSite.length > 0) {
          const group = site.groupsSite[0].group;
          const found = userGroups.find((userGroup) => {
            if (userGroup?.group?.id === group.id) {
              return userGroup;
            } else {
              return undefined;
            }
          });
          if (found) {
            return org;
          }
        }
      }
    }
  }, []);

  /***
   * Filter only sites that belong to user groups
   */
  const getSiteFn = useCallback(
    (site) => {
      if (site?.groupsSite?.length > 0) {
        for (let index = 0; index < site?.groupsSite?.length; index++) {
          const containerGroup = site.groupsSite[index];
          const found = loggedUserGroups.find((userGroup) => {
            if (userGroup?.group?.id === containerGroup?.group?.id) {
              return userGroup;
            } else {
              return undefined;
            }
          });
          if (found) {
            return site;
          }
        }
      }
    },
    [loggedUserGroups],
  );

  /**
   * Load all active Organization when page is load
   */
  useEffect(() => {
    //Get all active Org
    const getOrganizations = () => {
      setIsLoadingOrg(true);

      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setJoin({ field: 'organizations_sites' }) //Joins to get the groups of each site the org has
        .setJoin({ field: 'organizations_sites.groupsSite' })
        .setJoin({ field: 'organizations_sites.groupsSite.group' })
        .setFilter({
          field: 'status_id.value_definition',
          operator: CondOperator.EQUALS,
          value: '010', //Active
        });
      //Call API
      getEntityData(ORGANIZATIONS_API, qb.query())
        .then((orgRes) => {
          //If is a system
          if (userData?.cognitoUser?.idToken?.payload['cognito:groups'][0] === 'system') {
            //Get the user groups, only those are "Full access"
            const qb = RequestQueryBuilder.create()
              .setJoin({ field: 'group' })
              .setJoin({ field: 'user' })
              .setJoin({ field: 'group_permission' })
              .setFilter({
                field: 'groupsPermission.value_definition',
                operator: CondOperator.EQUALS,
                value: '020', //Full access
              })
              .setFilter({
                field: 'userGroupsUser.id',
                operator: CondOperator.EQUALS,
                value: userData.id,
              });
            //Call API
            getEntityData(USERS_GROUPS, qb.query())
              .then((userGroups) => {
                //Filter only Org which has the same users group
                const filteredOrg = orgRes.filter((org) => {
                  return getOrgFn(org, userGroups);
                });
                setOrganizations(filteredOrg);
                setIsLoadingOrg(false);
              })
              .catch((err) => {
                console.log('ORGANIZATIONS_API err=', err);
                setIsLoadingOrg(false);
              });
          } else if (userData?.cognitoUser?.idToken?.payload['cognito:groups'][0] === 'admin') {
            //Admin
            setOrganizations(orgRes);
            setIsLoadingOrg(false);
          }
        })
        .catch((err) => {
          console.log('ORGANIZATIONS_API err=', err);
          setIsLoadingOrg(false);
        });
    };
    getOrganizations();
  }, [userData, userData?.cognitoUser?.idToken?.payload, getOrgFn]);

  /**
   * Load all active Sites when a organization is selected
   */
  useEffect(() => {
    //Delete sites
    setSites([]);
    //Get all active Org
    const getSites = () => {
      setIsLoadingSites(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setFilter({
          field: 'status.value_definition',
          operator: CondOperator.EQUALS,
          value: '010', //Active
        })
        .setFilter({
          field: 'organization_id.id',
          operator: CondOperator.EQUALS,
          value: organizationSelected.id,
        });
      //Call API
      getEntityData(SITES_API, qb.query())
        .then((siteRes) => {
          //If is a super admin user
          if (userData?.cognitoUser?.idToken?.payload['cognito:groups'][0] === 'system') {
            //Is a system user
            const filteredSites = siteRes.filter((site) => {
              return getSiteFn(site);
            });
            setSites(filteredSites);
            setIsLoadingSites(false);
          } else if (userData?.cognitoUser?.idToken?.payload['cognito:groups'][0] === 'admin') {
            //Admi user
            setSites(siteRes);
            setIsLoadingSites(false);
          }
        })
        .catch((err) => {
          console.log('SITES_API err=', err);
          setIsLoadingSites(false);
        });
    };
    organizationSelected && getSites();
  }, [organizationSelected, userData?.cognitoUser?.idToken?.payload, getSiteFn]);

  /***
   * Create a Organization User
   */
  const addOrganization = (values) => {
    //Hide message
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for GroupList view
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    nameWithoutSpaces = nameWithoutSpaces.trim();
    values.name = nameWithoutSpaces;
    const status =
      values.action === 'create'
        ? { ...requestStatusCreate }
        : values.action === 'draft' && { ...requestStatusDraft };
    const typeOrganization = valuesDefinitions.filter(
      (v) => v.definitionType.definition_type === 'USER_TYPES' && v.value_definition === '020',
    )[0];
    requestCreateOrgUser.type = typeOrganization;
    requestCreateOrgUser.role = roleUser;
    requestCreateOrgUser = { ...requestCreateOrgUser, ...values, status };
    requestCreateOrgUser.permission = {
      value_definition_id: values.permission.value.value_definition_id,
    };

    let orgs = [];

    if (roleUser.value_definition === '010') {
      //Org representative
      if (values.organizationOR) {
        orgs = values.organizationOR.map((org) => {
          return org.value.id; //To avoid error for too long request
        });
      }
    } else if (roleUser.value_definition === '020') {
      //Site manager
      if (values.organizationSM && values.organizationSM.value) {
        orgs.push(values.organizationSM.value.id); //To avoid error for too long request
      }
    }
    requestCreateOrgUser.organizations = orgs;

    if (values.sites) {
      requestCreateOrgUser.sites = values.sites.map((site) => {
        return site.value;
      });
    }
    //To avoid error for too long request
    delete requestCreateOrgUser.organizationOR;
    delete requestCreateOrgUser.organizationSM;

    setLoading(true);
    //Call API
    saveEntityData(USERS_API, requestCreateOrgUser)
      .then((res) => {
        //Show message on previous view
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_created')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          //Show message
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.user_exists')}`,
            message: '',
          });
        } else {
          //Show message
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_user_creating')}`, message: '' });
        }
        setLoading(false);
      });
  };

  /***
   * Get permissions
   */
  useEffect(() => {
    const gePermissionList = () => {
      const list = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USERS_GROUPS_PERMISSION',
      );
      setPermissionList(list);
    };
    gePermissionList();
  }, [valuesDefinitions]);

  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      <Formik
        enableReinitialize
        validationSchema={addUserSchema}
        onSubmit={addOrganization}
        initialValues={{
          name: '',
          phone: '',
          email: '',
          role_id: '',
          permission: { label: defaultPermission?.description, value: defaultPermission },
          organizationSM: null,
          organizationOR: '',
          sites: '',
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
          <div style={{ width: '590px', fontSize: '14px' }}>
            <Form
              onSubmit={handleSubmit}
              noValidate
              className="baseForm"
              style={{ padding: '23px 30px 20px' }}
            >
              <div style={{ fontSize: 14 }}>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.role')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownSelect
                      options={rolesUsers.map((option) => ({
                        label: `${t(`roles.${option.description.replace(/\s/g, '_')}`)}`,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      name="role_id"
                      placeholder={t('required')}
                      //icon={UserIcon}
                      onChange={(selected) => {
                        setFieldValue('role_id', selected);
                        if (selected) setRoleUser(selected.value);
                        setFieldValue('organizationOR', []);
                        setFieldValue('organizationSM', null);
                        setFieldValue('sites', []);
                      }}
                      invalid={touched.role_id && errors.role_id}
                      defaultValue={values.role_id}
                    />
                    {touched.role_id && errors.role_id && (
                      <ErrorText className="px-2 mb-0">{errors.role_id}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.permission')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownSelect
                      options={permissionList.map((option) => ({
                        label: `${t(`Users.permissions.${option.description.replace(/\s{2,}/g, ' ').replace(/\s/g, '_')}`)}`,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      height="8rem"
                      name="permission"
                      placeholder={t('required')}
                      onChange={(selected) => {
                        setFieldValue('permission', selected);
                      }}
                      invalid={touched.permission && errors.permission}
                      defaultValue={values.permission && {
                        label: `${t(`Users.permissions.${values.permission.label.replace(/\s{2,}/g, ' ').replace(/\s/g, '_')}`)}`, 
                        value: values.permission.value
                      }}
                    />
                    {touched.permission && errors.permission && (
                      <ErrorText className="px-2 mb-0">{errors.permission}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.name')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="name"
                      value={values.name}
                      invalid={touched.name && errors.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.phone')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="phone"
                      ctype={'phone'}
                      inputProps={{
                        name: 'phone',
                      }}
                      country={'us'}
                      value={values.phone}
                      invalid={touched.phone && errors.phone}
                      onChange={(value, data, event, formattedValue) =>
                        setFieldValue('phone', formattedValue)
                      }
                      maxLength="10"
                    />
                    {touched.phone && errors.phone && (
                      <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.email')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="email"
                      value={values.email}
                      invalid={touched.email && errors.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                    )}
                  </Col>
                </Row>

                {roleUser &&
                  roleUser.value_definition === '010' && ( //Org Representative
                    <Row className="mb-3">
                      <Col xs={3}>
                        <LabelForm tittle={t('form_labels.organizations')}></LabelForm>
                      </Col>
                      <Col xs={9}>
                        <AppDropDownMultipleSelect
                          options={organizations.map((option) => ({
                            label: option.name,
                            value: option,
                            color: option.color,
                            innerText: true,
                          }))}
                          noOptionsMessage={() => t('no_options') }
                          isClearable={true}
                          isSearchable={true}
                          name="organizationOR"
                          placeholder={t('optional')}
                          customOption={SingleOption}
                          isLoading={isLoadingOrg}
                          value={values.organizationOR}
                          height={120}
                          onChange={(selected) => {
                            setFieldValue('organizationOR', selected);
                          }}
                          invalid={touched.organizationOR && errors.organizationOR}
                          defaultValue={values.organizationOR}
                        />
                        {touched.organizationOR && errors.organizationOR && (
                          <ErrorText className="px-2 mb-0">{errors.organizationOR}</ErrorText>
                        )}
                      </Col>
                    </Row>
                  )}

                {roleUser &&
                  roleUser.value_definition === '020' && ( //Site Manager
                    <Row className="mb-3">
                      <Col xs={3}>
                        <LabelForm tittle={t('form_labels.organization')}></LabelForm>
                      </Col>
                      <Col xs={9}>
                        <AppDropDownSelect
                          options={organizations.map((option) => ({
                            label: option.name,
                            value: option,
                            color: option.color,
                            innerText: true,
                          }))}
                          noOptionsMessage={() => t('no_options') }
                          isClearable={true}
                          isSearchable={true}
                          name="organizationSM"
                          placeholder={t('optional')}
                          customOption={SingleOption}
                          avatar={
                            values.organizationSM && values.organizationSM.value
                              ? calcOrgCircle(values.organizationSM)
                              : null
                          }
                          icon={EllipseIcon}
                          value={values.organizationSM}
                          invalid={touched.organizationSM && errors.organizationSM}
                          defaultValue={values.organizationSM}
                          height={120}
                          isLoading={isLoadingOrg}
                          onChange={(selected) => {
                            setFieldValue('organizationSM', selected);
                            if (selected) {
                              setOrganizationSelected(selected.value);
                            } else {
                              setOrganizationSelected(null);
                            }
                            setFieldValue('sites', []);
                          }}
                        />
                        {touched.organizationSM && errors.organizationSM && (
                          <ErrorText className="px-2 mb-0">{errors.organizationSM}</ErrorText>
                        )}
                      </Col>
                    </Row>
                  )}

                {roleUser &&
                  roleUser.value_definition === '020' &&
                  values.organizationSM !== null && ( //Site Manager and org dif from null
                    <Row className="mb-3">
                      <Col xs={3}>
                        <LabelForm tittle={t('form_labels.sites')}></LabelForm>
                      </Col>
                      <Col xs={9}>
                        <AppDropDownMultipleSelect
                          options={sites.map((option) => ({
                            label: option.name,
                            value: option,
                          }))}
                          noOptionsMessage={() => t('no_options') }
                          isClearable={true}
                          name="sites"
                          placeholder={t('required')}
                          isLoading={isLoadingSites}
                          value={values.sites}
                          height={120}
                          onChange={(selected) => {
                            setFieldValue('sites', selected);
                          }}
                          invalid={touched.sites && errors.sites}
                          defaultValue={values.sites}
                        />
                        {touched.sites && errors.sites && (
                          <ErrorText className="px-2 mb-0">{errors.sites}</ErrorText>
                        )}
                      </Col>
                    </Row>
                  )}
              </div>
              <StyleButtons>
                <span>
                  <AppButton
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    color={PALETTE_COLOR.WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    onClick={() => {
                      setFieldValue('action', 'create');
                    }}
                  >
                    {t('buttons.btn_create_invite')}
                  </AppButton>
                  <AppButton
                    loading={loading}
                    size="lg"
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={() => {
                      setFieldValue('action', 'draft');
                    }}
                    type="submit"
                  >
                    {t('buttons.btn_save_as_draft')}
                  </AppButton>
                  <AppButton
                    loading={loading}
                    size="lg"
                    style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={handleclose}
                    title={t('buttons.btn_cancel')}
                  >
                    {t('buttons.btn_cancel')}
                  </AppButton>
                </span>
                {showMessage.show && (
                  <CustomizedSnackbar
                    show={showMessage.show}
                    type={showMessage.type}
                    title={showMessage.title}
                    message={showMessage.message}
                  />
                )}{' '}
              </StyleButtons>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
