import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Container, Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';
import * as yup from 'yup';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useAuth } from '@navigation/Auth/ProvideAuth';
import { ValidationTooltip } from './ValidationTooltip';
import { InputForm } from 'components/InputForm';
import { changePasswordFirstLogin } from '@services/CognitoAuthService';
import { AppButton } from 'components';

import passIcon from '@assets/icons/password24x24.svg';
import { CHOOSEAVATAR } from '@navigation/CONSTANTS';

import { useTranslation } from 'react-i18next';

const H2 = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
`;

const P = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const Link = styled.a`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  text-decoration: none;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  :hover,
  :focus {
    border: 1px solid rgba(131, 180, 49, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

export const ChangePassForm = ({ loginInfo }) => {
  const { t } = useTranslation();
  const changePassSchema = yup.object().shape({
    password: yup.string().required(`${t('validation.password_required')}`),
    confPassword: yup.string().required(`${t('validation.password_required')}`),
  });
  let auth = useAuth();
  let history = useHistory();
  const formRef = useRef();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState(false);

  const valitateForm = (values) => {
    return values.password === values.confPassword;
  };

  const setNewPassword = async (values) => {
    //loading(true);
    setShow(false);
    const { user, userAttributes } = loginInfo;

    if (valitateForm(values)) {
      let out = null;
      try {
        out = await changePasswordFirstLogin(values.password, user, userAttributes);
        console.log('changePasswordFirstLogin succeed: ', out);

        auth.signin(true).then((res) => {
          history.replace(CHOOSEAVATAR);
        });
      } catch (error) {
        let msg = `${t('error_messages.password_cant_changed')}`;
        const { code, message } = error;
        if (code === 'InvalidPasswordException') {
          const messageSplitted = message.split(':');
          messageSplitted.length > 1 && (msg = messageSplitted[1]);
        }
        setMessage(msg);
        setShow(true);
      }
    } else {
      setMessage(`${t('error_messages.passwords_not_match')}`);
      setShow(true);
    }
  };

  const isValidFormik = () => {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  };

  return (
    <Formik
      validationSchema={changePassSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        setNewPassword(values);
      }}
      initialValues={{
        password: '',
        confPassword: '',
      }}
      innerRef={formRef}
    >
      {({ handleSubmit, handleChange, values, isValid, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit} className="baseForm">
          <Container className="baseForm px-5 pt-2 pb-2">
            <Row>
              <Col className="mt-2" xs={12} xl={12}>
                <H2 className="text-center">{t('Login.change_password.title')}</H2>
                <P className="text-center mb-2">{t('Login.change_password.subtitle')}</P>

                {visible && !isValid && <ValidationTooltip msg={t('validation.all_required')} />}
                {show && isValid && <ValidationTooltip msg={message} />}
                <Form.Group className="pt-3">
                  <InputForm
                    height={'64px'}
                    ctype="password"
                    placeholder={t('Login.change_password.new_password')}
                    icon={passIcon}
                    name="password"
                    value={values.password}
                    invalid={touched.password && errors.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} xl={12}>
                <Form.Group>
                  <InputForm
                    height={'64px'}
                    ctype="password"
                    placeholder={t('Login.change_password.repeat_password')}
                    icon={passIcon}
                    name="confPassword"
                    value={values.confPassword}
                    invalid={touched.confPassword && errors.confPassword}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} xl={12}>
                <P className="text-center mb-2">{t('Login.change_password.by_continuing')}</P>
                <P className="text-center mb-2" style={{ paddingTop: 10 }}>
                  <Link href="/privacy" target='_blank'>{t('Login.change_password.privacy_policy')}</Link>
                </P>
              </Col>
            </Row>
            <div className="d-flex flex-column justify-content-center align-items-center my-3">
              <AppButton
                style={{ width: 'auto', height: 64, zIndex: 5 }}
                type="submit"
                onClick={() => isValidFormik()}
                bgcolor={PALETTE_COLOR.MAIN_BLUE}
                bordercolor={PALETTE_COLOR.MAIN_BLUE}
                bghovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
                borderhovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
              >
                {t('buttons.btn_accept')}
              </AppButton>
            </div>
          </Container>
        </Form>
      )}
    </Formik>
  );
};
