import React from 'react';

import { Card, InputGroup } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import { AppButton } from '@components';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { PALETTE_COLOR } from '@config/CONSTANTS';
const DEF_WIDTH = '600px';

const StyledCard = styled(Card)`
  width: ${(props) => (props.width ? props.width : DEF_WIDTH)};
  height: auto;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin-bottom: 15px;
  padding-right: ${(props) => (props.padding_card ? props.padding_card : 0)};
  padding-left: ${(props) => (props.padding_card ? props.padding_card : 0)};
`;

const StyledCardLabel = styled.div`
  font-size: 24px;
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  display: flex;
  padding: 10px ${(props) => (props.notitlepadding ? 0 : 30)}px !important;
  font-weight: normal !important;
  justify-content: flex-start;
`;

const Icon = styled.img``;

const StyledCardIcon = styled(InputGroup.Text)`
  border: none;
  padding: 0.375rem 0rem;
  background: unset;
`;

export function Panel(props) {
  const {
    title,
    children,
    icon,
    width,
    icon_label,
    call,
    header = 1,
    button,
    withborder = 1,
  } = props;
  return (
    <>
      <StyledCard width={width} {...props}>
        {header === 1 && (
          <React.Fragment>
            <Row>
              <Col xl={11} lg={11} style={{ display: 'flex' }}>
                <StyledCardLabel
                  style={{ marginLeft: '15px', paddingBottom: '20px !important', width: width || '100%' }}
                  {...props}
                >
                  {icon_label && <Icon src={icon_label} />}
                  <div style={{ padding: '0px 10px', width: width || '100%' }}>{title}</div>
                </StyledCardLabel>
              </Col>
              <Col xl={1} lg={1} style={{ textAlign: 'end' }}>
                <StyledCardIcon>
                  {icon ? (
                    <IconButton
                      aria-label="edit"
                      onClick={call}
                      sx={{
                        position: 'absolute',
                        right: 16,
                        top: 10,
                      }}
                    >
                      <Icon src={icon} />
                    </IconButton>
                  ) : (
                    button && (
                      <AppButton
                        style={{
                          marginBottom: '50px',
                          fontSize: 14,
                          width: 'auto',
                          position: 'absolute',
                          right: 16,
                          top: 10,
                          boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
                        }}
                        bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                        bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                        bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        title={button?.text}
                        icon={button?.icon}
                        onClick={button?.action}
                      >
                        {button?.text}
                      </AppButton>
                    )
                  )}
                </StyledCardIcon>
              </Col>
            </Row>
            {withborder === 1 ? (
              <hr
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.18)',
                  height: 2,
                  padding: 0,
                  margin: 0,
                }}
              />
            ) : null}
          </React.Fragment>
        )}
        {children}
      </StyledCard>
    </>
  );
}
export default Panel;
