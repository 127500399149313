import React from 'react';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { setDestinationChatUserIdAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const ListContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ListColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameStyled = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const SettingsOptions = ({ setSettingSelected }) => {
  const { t } = useTranslation();
  const [settingsOptions, setSettingsOptions] = React.useState([
    { id: 1, value: `${t('SettingsCenter.general')}`, selected: true },
    // { id: 2, value: `${t('SettingsCenter.breaks')}`, selected: false },
  ]);
  const dispatch = useDispatch();
  const { loadingChat } = useSelector((state) => state.app);

  /***
   * Mark the selected setting
   */
  const changeSelectedStatus = (selected) => {
    //Store in redux user id selected
    dispatch(setDestinationChatUserIdAction(selected.id));
    //Mark all as false
    const settings = settingsOptions.map((setting) => {
      if (setting.id === selected.id) {
        setting.selected = true;
      } else {
        setting.selected = false;
      }
      return setting;
    });
    setSettingsOptions(settings);
    setSettingSelected(selected);
  };

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {settingsOptions?.map((setting) => {
            return (
              <div>
                <ListItemButton
                  key={setting.id}
                  sx={{
                    pl: 4,
                    '&.Mui-selected': {
                      backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                      color: 'white !important',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                      color: 'white !important',
                    },
                    ':hover': {
                      backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                      color: 'white !important',
                    },
                  }}
                  selected={setting.selected}
                  onClick={() => changeSelectedStatus(setting)}
                  disabled={loadingChat}
                >
                  <ListContainerRow>
                    <ListColumn
                      style={{
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <NameStyled>{setting.value}</NameStyled>
                    </ListColumn>
                    <div style={{ display: 'flex', flexDirection: 'column' }}></div>
                  </ListContainerRow>
                </ListItemButton>
              </div>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
