import React /*, { useEffect } */ from 'react';
import styled from 'styled-components';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const MainWrapper = styled.div`
  background: #ffffff;
  height: 88%;
  position: absolute;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
  }
`;

export const Breaks = () => {
  return (
    <>
      <MainWrapper style={{ overflowY: 'scroll', width: '74%' }}></MainWrapper>
    </>
  );
};
