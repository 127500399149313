import React, { useEffect, useState } from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import styled from 'styled-components';
import EncView from '@components/EncView';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import {
  AppModal,
  StateTag,
  HeaderBtn,
  CustomizedSnackbar,
  AppTable,
  AppOrgIcon,
} from '@components';
import AppPanel from '@components/AppPanel';
import AppPanelAvatar from '@components/AppPanelAvatar';
import { ChangePasswordForm } from './ChangePassword/ChangePasswordForm';
import { getCurrentSession } from '@services/CognitoAuthService';
import { getUserDetails, getEntityData } from 'services';
import { formatFullDate, formatDate } from '@utils/dateFormatHelper';
import { EditContactInfoForm } from './EditContactInfoForm';
import logo_edit from '@assets/icons/edit.svg';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Status_block.svg';
import ic_big_groups from '@assets/icons/ic_big_groups.svg';
import ic_groups from '@assets/icons/ic_groups.svg';
import ic_organizations from '@assets/icons/ic_organizations.svg';
import ic_big_organizations from '@assets/icons/ic_big_organizations.svg';
import { SITES_API } from '@services/CONSTANTS';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import ic_lock from '@assets/icons/ic_lock.svg';

import { useTranslation } from 'react-i18next';

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const StyleTittle = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: ${PALETTE_COLOR.LIGHT_GREY};
`;

const StyleFields = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyleRow = styled.div`
  padding: 14px 44px 24px;
`;

const StyleColContent = styled(Col)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  color: ${PALETTE_COLOR.BLACK};
`;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const Icon = styled.img``;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledTag = styled.div`
  width: fit-content;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Profile = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [userRole, setUserRole] = useState({});
  const [hasGroups, setHasGroups] = useState(false);
  const [hasOrganizations, setHasOrganizations] = useState(false);
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [openChangePassForm, setOpenChangePassForm] = useState(false);
  const [openEditContactInfoForm, setOpenEditContactInfoForm] = useState(false);
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const adminHeaders = [
    `${t('Profile.headers.name')}`, 
    `${t('Profile.headers.email')}`, 
    `${t('Profile.headers.phone')}`, 
    `${t('Profile.headers.type')}`
  ];
  const groupAdminHeaders = [
    `${t('Profile.headers.name')}`, 
    `${t('Profile.headers.email')}`, 
    `${t('Profile.headers.phone')}`, 
    `${t('Profile.headers.type')}`, 
    `${t('Profile.headers.role')}`
  ];
  const orgHeaders = [
    `${t('Profile.headers.name')}`, 
    `${t('Profile.headers.email')}`, 
    `${t('Profile.headers.phone')}`, 
    `${t('Profile.headers.role')}`, 
    `${t('Profile.headers.permission')}`
  ];
  const SYSTEM_COG_ROLE = 'system';
  const ORG_COG_ROLE = 'organizations';

  const handleClickOpenChangePassFormModal = () => {
    setOpenChangePassForm(true);
  };

  const handleCloseChandePassFormModal = () => {
    setOpenChangePassForm(false);
  };

  const handleClickOpenEditContactInfoFormModal = () => {
    setOpenEditContactInfoForm(true);
  };

  const handleCloseEditContactInfoFormModal = () => {
    setOpenEditContactInfoForm(false);
  };

  const systemColumns = [
    {
      dataField: 'group.name',
      text: `${t('table_columns.group')}`,
      sort: false,
      search: false,
      headerStyle: { width: '40%' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.group?.name}</StyledLabel>;
      },
    },
    {
      dataField: 'group_permission.description',
      text: `${t('table_columns.permission')}`,
      isDummyField: true,
      search: true,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledTag>
              {row.group_permission?.description}
            </StyledTag>
          </div>
        );
      },
    },
  ];

  const orgColumns = [
    {
      dataField: 'organization_id.name',
      text: `${t('table_columns.organization')}`,
      sort: false,
      search: true,
      headerStyle: { width: '30%' },
      style: { height: '54px', display: 'flex', alignItems: 'start' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex' }}>
            <AppOrgIcon
              width="30px"
              height="30px"
              fontSize="12px"
              color={row.organization_id?.color}
            >
              {row.organization_id.name.substring(0, 2).toUpperCase()}
            </AppOrgIcon>
            <StyledLabel style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
              {row.organization_id.name}
            </StyledLabel>
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: false,
      headerStyle: { width: '30%' },
      style: { height: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        console.log(row);
        return (
          <div style={{ borderTop: 'unset' }}>
            <StyledNestedCell
              title={row.name}
              style={{
                backgroundColor: row?.name && row.organization_id?.color,
                display: 'block',
                paddingTop: '13px',
              }}
            >
              {row.name}
            </StyledNestedCell>
          </div>
        );
      },
    },
    {
      dataField: 'address',
      text: `${t('table_columns.address')}`,
      sort: false,
      search: false,
      headerStyle: { width: '40%' },
      style: { height: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ borderTop: 'unset' }}>
            <StyledNestedCell style={{ display: 'block', paddingTop: '13px' }}>
              {row.address}
            </StyledNestedCell>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    /***
     * Get sites for SM
     */
    const getOrgGroups = (userId) => {
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setJoin({ field: 'organizations_users_sites' })
        .setJoin({ field: 'organizations_users_sites.organization_user' })
        .setJoin({ field: 'organizations_users_sites.organization_user.user_id' })
        .setJoin({ field: 'organizations_users_sites.organization_user.user_id.role' })
        .setFilter({
          field: 'organizationsUsersSitesOrganizationUserUserIdRole.value_definition',
          operator: CondOperator.EQUALS,
          value: '020',
        })
        .setFilter({
          field: 'organizationsUsersSitesOrganizationUserUserId.id',
          operator: CondOperator.EQUALS,
          value: userId,
        });

      getEntityData(SITES_API, qb.query())
        .then((res) => {
          console.log('Get groups by user=', res);
          setHasOrganizations(res?.length > 0);
          setUserOrganizations(res);
        })
        .catch((err) => {
          console.log('Get groups by user err=', err);
        });
    };

    /***
     * Refactor user output to SM-OR columns
     */
    const getSitesStructure = (user) => {
      const sitesLisr = [];
      user.organizations.map((o) =>
        o.organizations_sites.length
          ? o.organizations_sites.map((s) => sitesLisr.push({ ...s, organization_id: o }))
          : sitesLisr.push({ organization_id: o }),
      );
      setHasOrganizations(sitesLisr.length > 0);
      setUserOrganizations(sitesLisr);
    };

    async function fetchUserData() {
      setLoading(true);
      const info = await getCurrentSession();
      console.log('info ', info);
      const sub = info.idToken?.payload?.sub;
      setUserRole(info.idToken?.payload['cognito:groups'][0]);

      getUserDetails(sub)
        .then((res) => {
          res.user_groups.sort(function (a, b) {
            if (a.group.name > b.group.name) {
              return 1;
            }
            if (a.group.name < b.group.name) {
              return -1;
            }
            return 0;
          });
          setUserData(res);
          setHasGroups(res.user_groups.length > 0 || res.role?.value_definition === '040');

          //Get organizatins & sites assigned to user depending on his role
          switch (res.role?.value_definition) {
            case '010': //orgRep
              getSitesStructure(res);
              break;
            case '020': //Site manager
              getOrgGroups(res.id);
              break;
            case '030': //Group admin & Super admin
            case '040':
              setUserOrganizations(res?.organizations);
              setHasOrganizations(res.organizations.length > 0);
              break;
            default:
              break;
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    }

    fetchUserData();
  }, [showSucessModal, userRole]);

  return (
    <Container fluid>
      <EncView>
        <SpaceEncName>
          <div>
            <h1 style={{ fontSize: 28 }}>{t('Profile.my_title')}</h1>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {userData?.status?.value_definition === '010' && (
            <>
              <StateTag
                style={{ marginLeft: '5px' }}
                width="157px"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Profile.status.${userData?.status.description}_account`)}
              </StateTag>
              <HeaderBtn icon={ic_lock} width="auto" action={handleClickOpenChangePassFormModal}>
                {t('Profile.change_password')}
              </HeaderBtn>
            </>
          )}
          {/* {
            userData?.status?.value_definition === "020" &&
            <>
              <StateTag style={{ marginLeft: '5px' }} width='89px' icon={status_draft}
                background={PALETTE_COLOR.DRAFT_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.INACTIVE_GRAY}>
                {userData?.status.description}
              </StateTag>
            </>
          }
          {
            userData?.status?.value_definition === "030" &&
            <StateTag style={{ marginLeft: '5px' }} width='102px' icon={status_invited}
              background={PALETTE_COLOR.INVITED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_PURPLE}>
              {userData?.status.description}
            </StateTag>
          } */}
          {userData?.status?.value_definition === '050' && (
            <>
              <StateTag
                style={{ marginLeft: '5px' }}
                width="109px"
                icon={status_blocked}
                background={PALETTE_COLOR.BLOCKED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.TEXT_ORANGE}
              >
                {t(`Profile.status.${userData?.status.description}_account`)}
              </StateTag>
              <HeaderBtn icon={ic_lock} width="auto" action={handleClickOpenChangePassFormModal}>
                {t('Profile.change_password')}
              </HeaderBtn>
            </>
          )}
        </SpaceEnc>
      </EncView>

      {/* Personal Info panel */}
      <Col xs={12} xl={12} className="mt-4">
        <AppPanelAvatar
          style={{ marginTop: 25 }}
          notitlepadding={`true`}
          width="100%"
          padding_card="15px"
          icon={logo_edit}
          enableRandomAvatar={false}
          call={handleClickOpenEditContactInfoFormModal}
        >
          <StyleRow>
            <StyleTittle>
              {userRole === SYSTEM_COG_ROLE && userData?.role?.value_definition === '030'
                ? groupAdminHeaders.map((i) => <Col key={i}>{i}</Col>)
                : userRole === 'admin' && userData?.role?.value_definition === '040'
                ? adminHeaders.map((i) => <Col key={i}>{i}</Col>)
                : orgHeaders.map((i) => <Col key={i}>{i}</Col>)}
            </StyleTittle>
            <StyleFields>
              <Col>{userData?.name}</Col>
              <Col>{userData?.email}</Col>
              <Col>{userData?.phone}</Col>

              {(userRole === SYSTEM_COG_ROLE && userData?.role?.value_definition === '030') ||
               (userRole === 'admin' && userData?.role?.value_definition === '040') ? (
                <Col>{userData?.role?.description ? t(`roles.${userData?.role?.description.replace(/\s/g, '_')}`) : <>&nbsp;</>}</Col>
               ) : null}

              {(userData?.role && userData?.role.value_definition === '030') || userRole === ORG_COG_ROLE ? (
                <Col>
                  {userRole === ORG_COG_ROLE
                    ? userData?.role?.description ? t(`roles.${userData?.role?.description.replace(/\s/g, '_')}`) : <>&nbsp;</>
                    : userData?.user_system_rol}
                </Col>
              ) : !(userRole === 'admin' && userData?.role?.value_definition === '040') && <Col>&nbsp;</Col>}

              {(userData.role?.value_definition === '010' || userData.role?.value_definition === '020') ? ( //Org representative or Site manager
                <Col>{t(`Profile.permissions.${userData?.permission?.description.replace(/\s/g, '_')}`)}</Col>
              ) : !((userRole === SYSTEM_COG_ROLE && userData?.role?.value_definition === '030') || (userRole === 'admin' && userData?.role?.value_definition === '040')) && <Col>&nbsp;</Col>}
            </StyleFields>
          </StyleRow>
        </AppPanelAvatar>
      </Col>

      <Row>
        {/* Usage Info panel */}
        <Col sm={3}>
          <AppPanel notitlepadding={`true`} title={t('Users.view.usage_info_title')} width="100%" padding_card="15px">
            <Card style={{ borderRight: 'unset', borderLeft: 'unset', borderTop: 'unset' }}>
              <ListGroup variant="flush" style={{ borderTop: 'unset' }}>
                <ListGroup.Item style={{ height: '3%' }}>
                  <StyleTittle>
                    <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.invited')}</Col>
                    <StyleColContent>
                      {userData?.date_invited ? formatDate(userData.date_invited) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.registered')}</Col>
                    <StyleColContent>
                      {userData?.date_registered ? formatDate(userData.date_registered) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.last_modified')}</Col>
                    <StyleColContent>
                      {userData?.updated_at ? formatFullDate(userData.updated_at) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.last_login')}</Col>
                    <StyleColContent>
                      {userData?.last_login ? formatFullDate(userData.last_login) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.system_id')}</Col>
                    <StyleColContent>{userData?.id}</StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                {userData?.role?.value_definition === '020' ? (
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col sm={5} style={{ padding: 0 }}>{t('Users.usage_info.pin')}</Col>
                      <StyleColContent>{userData?.pin}</StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                ) : (
                  ''
                )}
              </ListGroup>
            </Card>
          </AppPanel>
        </Col>

        <Col sm={9}>
          {loading ? (
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            </AppPanel>
          ) : userRole === SYSTEM_COG_ROLE || userData.role?.value_definition === '040' ? (
            <AppPanel
              width="100%"
              header={hasGroups ? 1 : 0}
              style={
                hasGroups === false
                  ? {
                      minHeight: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : {}
              }
              icon_label={ic_groups}
              withborder={0}
              title={t('Profile.my_groups')}
            >
              {hasGroups === false ? (
                <div>
                  <Icon
                    src={ic_big_groups}
                    style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                  />
                  <NoDataPanelTitle>{t('Profile.no_groups')}</NoDataPanelTitle>
                </div>
              ) : (
                hasGroups === true && (
                  <AppTable
                    columns={systemColumns}
                    data={
                      userData.role?.value_definition === '040' //Super admin
                        ? [
                            {
                              group: {
                                name: `${t('Users.all_groups')}`,
                              },
                              group_permission: {
                                description: `${t('Profile.permissions.Full_Access')}`,
                              },
                            },
                          ]
                        : userData.user_groups // TODO: Translate group_permission.description if not Super Admin
                    }
                    disableFilters={true}
                    disablePagination={true}
                    disableTopPagination={true}
                    noBorders={true}
                  />
                )
              )}
            </AppPanel>
          ) : (
            userRole === ORG_COG_ROLE && (
              <AppPanel
                width="100%"
                header={hasOrganizations ? 1 : 0}
                style={
                  hasOrganizations === false
                    ? {
                        minHeight: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : {}
                }
                icon_label={ic_organizations}
                withborder={0}
                title={t('Profile.my_organizations')}
              >
                {hasOrganizations === false ? (
                  <div>
                    <Icon
                      src={ic_big_organizations}
                      style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                    />
                    <NoDataPanelTitle style={{ marginTop: '20px' }}>
                      {t('Profile.no_organizations')}
                    </NoDataPanelTitle>
                  </div>
                ) : (
                  hasOrganizations === true && (
                    <AppTable
                      columns={orgColumns}
                      data={userOrganizations}
                      disableFilters={true}
                      disablePagination={true}
                      disableTopPagination={true}
                      noBorders={true}
                      headerIcon={ic_organizations}
                    />
                  )
                )}
              </AppPanel>
            )
          )}
        </Col>
      </Row>

      {/*Change Pass modal*/}
      <AppModal
        open={openChangePassForm}
        handleclose={handleCloseChandePassFormModal}
        title={t('Profile.change_password')}
        inside={1}
      >
        <ChangePasswordForm
          handleclose={handleCloseChandePassFormModal}
          setShowSucessModal={setShowSucessModal}
        />
      </AppModal>

      {/*Edit  contact info*/}
      <AppModal
        open={openEditContactInfoForm}
        handleclose={handleCloseEditContactInfoFormModal}
        title={t('Profile.edit_personal_info')}
        inside={1}
      >
        <EditContactInfoForm
          handleclose={handleCloseEditContactInfoFormModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
        />
      </AppModal>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </Container>
  );
};
