import React from 'react';
import styled from 'styled-components';

const Icon = styled.img``;

const DivIcon = styled.div``;

const StateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px 5px 10px;
  width: 187px;
  height: 40px;
  background: rgba(134, 217, 113, 0.2);
  border-radius: 0px 20px;
`;

const StateTittle = styled.div`
  padding: 7px 7px 7px 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
`;

export const StateTag = ({ width, background, icon, textColor, children }) => {
  return (
    <StateContainer style={{ width, background }}>
      <DivIcon>
        <Icon src={icon}></Icon>
      </DivIcon>
      <StateTittle style={{ color: textColor }}>{children}</StateTittle>
    </StateContainer>
  );
};
