import { AppAvatar } from '@components';
import avatar from '@assets/icons/ic_no_avatar.svg';
import { components } from 'react-select';
import styled from 'styled-components';

const OptionLabelWrapper = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-left: 10px;
`;

const Icon = styled.img`
  height: 30px;
  height: 30px;
  border-radius: 60px;
`;

export const calcAvatar = (value, width, height) => {
  const avatarConfig = value && value.avatar_config ? JSON.parse(value.avatar_config) : null;

  return (
    <>
      {avatarConfig ? (
        <AppAvatar
          avatarId={avatarConfig?.avatarId}
          bgColor={avatarConfig?.bgColor}
          gender={avatarConfig?.gender}
          skinColor={avatarConfig?.skinColor}
          width={width ? width : 30}
          height={height ? height : 30}
        />
      ) : value?.photo_url ? (
        <Icon
          src={value.photo_url}
          alt="user_photo"
          style={{ width: width ? width : 30, height: height ? height : 30 }}
        ></Icon>
      ) : (
        <Icon
          src={avatar}
          alt="user_photo"
          style={{ width: width ? width : 30, height: height ? height : 30 }}
        ></Icon>
      )}
    </>
  );
};

export const CustomOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        {calcAvatar(props.value)}
        <OptionLabelWrapper>{props.label}</OptionLabelWrapper>
      </components.Option>
    </>
  );
};

export const MutipleCustomOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        {calcAvatar(props.data.user)}
        <OptionLabelWrapper>{props.label}</OptionLabelWrapper>
      </components.Option>
    </>
  );
};
