import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import { IconButton } from '@mui/material';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';
import { PALETTE_COLOR } from 'config/CONSTANTS';
import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

export function DatePickerByDay ({ dateFilter, openCalendarFilter, setDateFilter, setOpenCalendarFilter }) {
  const { t } = useTranslation();

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage} style={{ fontSize: '14px' }}>
        <DatePicker
          style={{ fontSize: '14px', display: 'none' }}
          label
          value={moment(dateFilter)}
          inputFormat="MMM DD, yyyy"
          open={openCalendarFilter}
          onChange={(newValue) => {
            setDateFilter(newValue.startOf('day').format());
            setOpenCalendarFilter(!openCalendarFilter);
          }}
          renderInput={(params) => {
            return (
              <div
                style={{
                  border: '1px solid #E7ECF4',
                  borderRadius: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '9px, 12px, 7px, 12px',
                  marginLeft: '0px',
                  width: 'auto'
                }}
              >
                <IconButton
                  aria-label={t('form_labels.job_scheduler')}
                  style={{ width: 24, height: 24, marginTop: 8, marginBottom: 8, marginLeft: 5 }}
                  onClick={(e) => {
                    setDateFilter(moment(dateFilter).subtract(1, 'day').format());
                  }}
                >
                  <ArrowWrapper
                    style={{ width: 5.5, height: 11.5 }}
                    src={ic_arrow_left_enabled}
                    alt="IcArrowLeft"
                  />
                </IconButton>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 9,
                    fontWeight: 400,
                    fontSize: 14,
                    color: PALETTE_COLOR.MAIN_BLUE,
                    cursor: 'pointer',
                    width: '100%',
                    justifyContent: 'space-around'
                  }}
                  onClick={() => {
                    setOpenCalendarFilter(!openCalendarFilter);
                  }}
                >
                  <div style={{ display: 'flex' }} ref={params.inputRef}>{capitalize(params.inputProps.value).replace('.', '')}</div>
                  <input style={{ width: 0, height: 0, zIndex: -1, display: 'none' }} {...params} />
                </div>
                <IconButton
                  aria-label={t('form_labels.job_scheduler')}
                  style={{ width: 24, height: 24, marginTop: 8, marginBottom: 8, marginRight: 5 }}
                  onClick={(e) => {
                    const newDate = moment(dateFilter).add(1, 'day');
                    setDateFilter(newDate.format());
                  }}
                >
                  <ArrowWrapper
                    style={{ width: 5.5, height: 11.5 }}
                    src={ic_arrow_right_enabled}
                    alt="IcArrowLeft"
                  />
                </IconButton>
              </div>
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
};
