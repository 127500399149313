import React, { useState, useEffect } from 'react';
import EncList from '@components/EncList';
import {
  AppDropDownSingleSelect,
  AppComponentLoading,
  AppModal,
  CustomizedSnackbar,
} from '@components';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import Calender from '@assets/icons/Calender.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ICAddButton from '@assets/icons/ic_float_add_button.svg';
import styled from 'styled-components';
import Ellipse from '@assets/icons/Ellipse.svg';
import Down from '@assets/icons/Down.svg';
import { size } from 'lodash';
import { getEntityData } from '@services';
import { USERS_API } from '@services/CONSTANTS';
import { TIME_SHEET, TIME_SHEET_LOBBY } from 'navigation/CONSTANTS';
import { useDispatch } from 'react-redux';
import { setJobSchedulerGroupAction } from '@redux/actions/appActions';
import { DayTimeSheet } from '@pages/TimeSheet/Day';
import { SummaryTimeSheet } from '@pages/TimeSheet/Summary';
import { AddTimeSheet } from './Summary/AddTimeSheet';

import { useTranslation } from 'react-i18next';

const StyleFilter = styled.div`
  display: flex;
  font-weight: 275;
  font-size: 23px;
  line-height: 34px;
  width: max-content;
`;

const TittleFilter = styled.div`
  margin-right: 15px;
  width: max-content;
`;

const FilterIcon = styled.img`
  margin-right: 11px;
`;

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const FloatImg = styled.img`
  position: fixed;
  width: '100%';
  bottom: 20px;
  right: 30px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
`;

/***
 * To know which user type is and what button activate
 */
const getActiveTabIndex = (type) => {
  if (type === 'day') {
    return 0;
  } else if (type === 'summary') {
    return 1;
  } else {
    return 0;
  }
};

const handleChangeTypeURL = (history, type) => {
  if (type === 'day') {
    history.push(TIME_SHEET.replace(':type', 'day'));
  } else if (type === 'summary') {
    history.push(TIME_SHEET.replace(':type', 'summary'));
  } else {
    history.push(TIME_SHEET.replace(':type', 'day'));
  }
};

export const TimeSheet = () => {
  const { t } = useTranslation();
  let { type } = useParams();
  let history = useHistory();
  const [viewType, setViewType] = React.useState(type ? type : 'day');
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [openTimeSheetRecord, setOpenTimeSheetRecord] = useState(false);
  const { userData, jobSchedulerGroup } = useSelector((state) => state.app);
  const [groupsList, setGroupsList] = useState([]);
  const dispatch = useDispatch();
  const handleClickOpenTimeSheetRecordModal = () => {
    setOpenTimeSheetRecord(true);
  };
  const handleClickCloseTimeSheetRecordModal = () => {
    setOpenTimeSheetRecord(false);
  };
  if (!jobSchedulerGroup || !jobSchedulerGroup.id) {
    history.push(TIME_SHEET_LOBBY);
  }
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  /***
   * Load groups depending on user's role
   */
  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setLoadingGroups(true);
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userGroups`, qb2.query())
        .then((res) => {
          setLoadingGroups(false);
          setGroupsList(res.data.map((gu) => gu.group));
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingGroups(false);
        });
    }
  }, [userData]);

  const setSelectedGroup = React.useCallback(
    async (group) => {
      dispatch(setJobSchedulerGroupAction(group));
    },
    [dispatch],
  );

  return (
    <>
      <EncList
        title={t('Timesheet.time_sheet')}
        indexActive={getActiveTabIndex(viewType)}
        icon={Calender}
        filters={[
          {
            filter: loadingGroups ? (
              <AppComponentLoading />
            ) : (
              <AppDropDownSingleSelect
                isSearchable={true}
                allowSelectAll={false}
                height="15rem"
                width={270}
                name="group"
                onChange={(selected) => {
                  setSelectedGroup(selected?.value);
                }}
                value={jobSchedulerGroup.group}
                options={groupsList.map((option) => ({ label: option.name, value: option }))}
                targetComponent={
                  <StyleFilter style={{ cursor: 'pointer' }}>
                    <FilterIcon src={Ellipse}></FilterIcon>
                    <TittleFilter>
                      {jobSchedulerGroup.name
                        ? jobSchedulerGroup.name
                        : size(groupsList) > 0
                        ? groupsList[0].name
                        : `${t('not_found')}`}
                    </TittleFilter>
                    <FilterIcon src={Down}></FilterIcon>
                  </StyleFilter>
                }
                defaultValue={{}}
              />
            ),
          },
        ]}
        options={[
          {
            label: `${t('Timesheet.header_buttons.day')}`,
            onClick: () => {
              setViewType('day');
              handleChangeTypeURL(history, 'day');
            },
          },
          {
            label: `${t('Timesheet.header_buttons.summary')}`,
            onClick: () => {
              setViewType('summary');
              handleChangeTypeURL(history, 'summary');
            },
          },
        ]}
      />
      {loadingGroups ? (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '80%',
            display: 'flex',
          }}
        >
          <AppComponentLoading />
        </div>
      ) : null}
      {!loadingGroups && viewType === 'day' ? <DayTimeSheet /> : null}
      {!loadingGroups && viewType === 'summary' ? <SummaryTimeSheet /> : null}
      <DropDownWrapper
        className="floatButton"
        onClick={(e) => {
          handleClickOpenTimeSheetRecordModal();
        }}
      >
        <FloatImg src={ICAddButton} style={{ cursor: 'pointer' }} />
      </DropDownWrapper>

      {/* New Timesheet Record */}
      <AppModal
        open={openTimeSheetRecord}
        handleclose={handleClickCloseTimeSheetRecordModal}
        title={t('Timesheet.modals.new_timesheet_title')}
        inside={1}
      >
        <AddTimeSheet
          handleclose={handleClickCloseTimeSheetRecordModal}
          setShowSucessModal={setShowSucessModal}
        />
      </AppModal>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </>
  );
};
