import React, { useState, useEffect } from 'react';
import { AppTable, AppModal, AppButton, AppModalStatus, AppOrgIcon } from '@components';
import { GROUPS_API, USERS_GROUPS, SITES_GROUPS_API } from '@services/CONSTANTS';
import styled from 'styled-components';
import logo_edit from '@assets/icons/edit.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { useParams } from 'react-router-dom';
import { obtainEntityData, deleteEntityData } from 'services';
import status_active from '@assets/icons/status_active.svg';
import EncView from '@components/EncView';
import IconButton from '@mui/material/IconButton';
import { AddEmployees } from '@pages/Groups/AddEmployees';
import { AddSystems } from '@pages/Groups/AddSystems';
import group from '@assets/icons/group.svg';
import { EditGroup } from './EditGroup';
import { CondOperator } from '@nestjsx/crud-request';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { Tooltip } from '@mui/material';
import Archived from '@assets/icons/Archived.svg';
import Status_block from '@assets/icons/Status_block.svg';
import Invited from '@assets/icons/Invited.svg';
import Draft from '@assets/icons/Draft.svg';
import { calcAvatar } from 'utils/avatar';
import { size } from 'lodash';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ic_remove from '@assets/icons/ic_remove.svg';
import ic_organizations from '@assets/icons/ic_organizations.svg';
import { DeleteSiteGroup } from './DeleteSiteGroup';
import { AddSiteGroupForm } from './AddSiteGroupForm';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledGroups = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const HeaderTitle = styled.h3`
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const Edit = styled.img`
  width: 12.59px;
  height: 16px;
`;

const StyledLabel = styled.div`
  margin-left: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${PALETTE_COLOR.MAIN_WHITE} !important;
  border: 1px solid ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
  padding: 12px !important;
  :hover {
    background-color: ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
  }
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Icon = styled.img``;

export const ListDetailGroup = () => {
  const { t } = useTranslation();
  let { groupId } = useParams();
  const filtersEmployees = [
    { field: 'userGroupsGroup.id', operator: CondOperator.EQUALS, value: groupId },
    { field: 'userType.value_definition', operator: CondOperator.EQUALS, value: '010' },
  ];
  const filtersSystem = [
    { field: 'userGroupsGroup.id', operator: CondOperator.EQUALS, value: groupId },
    { field: 'userType.value_definition', operator: CondOperator.EQUALS, value: '030' },
  ];
  const filtersSites = [
    { field: 'groups.id', operator: CondOperator.EQUALS, value: groupId },
    // { field: 'site.status', operator: CondOperator.NOT_EQUALS, value: '030' },
  ];
  //const filters = [{ field: 'groups.id', operator: CondOperator.EQUALS, value: groupId }];
  const [dataRemove, setDataRemove] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openEmployee, setOpenEmployee] = React.useState(false);
  const [openSystem, setOpenSystem] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });
  const [groupData, setGroupData] = useState();
  const [deleteSiteGroup, setDeleteSiteGroup] = useState(false);
  const [selectedSiteGroup, setSelectedSiteGroup] = useState({});
  //Redux
  const { userPermissions } = useSelector((state) => state.app);

  const removeOrgModalContent = {
    buttonText: `${t('buttons.btn_remove')}`,
    actionIcon: ico_Archived,
    title: `${t('Groups.modals.remove_assigned_user_title')}`,
    subtitle: `${t('Groups.modals.remove_assigned_user_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };
  
  const handleRemoveModal = () => {
    setOpenRemoveModal((prev) => {
      return !prev;
    });
  };

  const removeUserGroup = () => {
    //Hide message
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    deleteEntityData(`${USERS_GROUPS}${dataRemove?.id}`, {})
      .then((res) => {
        //Close modal
        handleRemoveModal();
        //Show success message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_group_removed')}`,
          message: '',
        });
      })
      .catch((err) => {
        //Close modal
        handleRemoveModal();
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.indexOf('foreign key') !== -1
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.user_group_exists')}`,
            message: '',
          });
        } else {
          showSucessModal({ show: true, type: 'error', title: `${t('error_messages.error_user_group_removing')}`, message: '' });
        }
      });
  };

  //Get group data by id
  useEffect(() => {
    obtainEntityData(`${GROUPS_API}${groupId}`)
      .then((res) => {
        setGroupData(res);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });

    return () => {
      console.log('axios cleanup.');
    };
  }, [groupId, showSucessModal]);

  const handleClickCloseEmployeeModal = () => {
    setOpenEmployee(false);
  };

  const handleClickOpenEmployeeModal = () => {
    setOpenEmployee(true);
  };

  const handleClickCloseSystemModal = () => {
    setOpenSystem(false);
  };

  const handleClickOpenSystemModal = () => {
    setOpenSystem(true);
  };

  const handleOpenDeleteSiteGroupModal = () => {
    setDeleteSiteGroup(true);
  };

  const handleCloseDeleteSiteGroupModal = () => {
    setDeleteSiteGroup(false);
  };

  /***
   * Shows modal
   */
  const handleClickOpenFormModal = () => {
    setOpenForm(true);
  };

  /***
   * Hide modal
   */
  const handleCloseModal = () => {
    setOpenForm(false);
  };

  const handleOpenAddModal = () => {
    setOpenAddForm(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddForm(false);
  };

  const tableColumns = [
    {
      dataField: 'organization.name',
      text: `${t('table_columns.organization')}`,
      sort: true,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex' }}>
            <AppOrgIcon color={row.site.organization_id.color}>
              {row.site.organization_id.name.substring(0, 2).toUpperCase()}
            </AppOrgIcon>
            <StyledLabel style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
              {row.site.organization_id.name}
            </StyledLabel>
          </div>
        );
      },
    },
    {
      dataField: 'sites.name',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledNestedCell
            style={{
              backgroundColor: row.site.organization_id.color,
              display: 'block',
              paddingTop: '13px',
            }}
          >
            {row.site.name}
          </StyledNestedCell>
        );
      },
    },
    {
      dataField: 'sites.address',
      text: `${t('table_columns.address')}`,
      sort: false,
      search: true,
      headerStyle: { width: '60%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.site.address}</StyledLabel>;
      },
    },
    {
      dataField: 'remove',
      isDummyField: true,
      text: `${t('table_columns.remove')}`,
      headerStyle: { width: '7%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        if (userPermissions?.type === 'system') {
          return <></>;
        }
        return (
          <StyledLabel
            style={{
              marginLeft: '0px',
              justifyContent: 'center',
              alignitems: 'center',
            }}
            onClick={() => {
              handleOpenDeleteSiteGroupModal();
              setSelectedSiteGroup(row);
            }}
          >
            <Edit src={ic_remove} alt="logo"></Edit>
          </StyledLabel>
        );
      },
    },
  ];

  const employeesColumns = [
    {
      dataField: 'userGroupsUser.employee_id',
      text: `${t('table_columns.employee_id')}`,
      sort: true,
      search: true,
      headerStyle: { width: '14%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user.employee_id}</StyledLabel>;
      },
    },
    {
      dataField: 'userGroupsUser.name',
      text: `${t('table_columns.name')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {calcAvatar(row.user)}
            <StyledIconLabel>{row.user?.name}</StyledIconLabel>
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'userGroupsUser.email',
      text: `${t('table_columns.email')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title={`${row.user.email}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {row.user.email}
            </StyledLabel>
          </Tooltip>
        );
      },
    },
    {
      dataField: 'userGroupsUser.phone',
      text: `${t('table_columns.phone')}`,
      search: true,
      sort: false,
      headerStyle: { width: '20%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user.phone}</StyledLabel>;
      },
    },
    {
      dataField: 'userJobTittle.description',
      text: `${t('table_columns.job_title')}`,
      search: true,
      sort: false,
      headerStyle: { width: '20%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        if (size(row.user.jobs_titles) > 0) {
          const jobs_titles = row.user.jobs_titles.filter(
            (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
          ); //ACTIVE ONLY
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {jobs_titles.map((org, index) => (
                <StyledGroups key={index}>{org.job_title.description}</StyledGroups>
              ))}
            </div>
          );
        }
      },
    },
    {
      dataField: 'userStatus.description',
      text: `${t('table_columns.status')}`,
      search: true,
      sort: false,
      headerStyle: { width: '12%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        if (row.user.status.value_definition === '010') {
          return (
            <StyledLabel>
              <Status src={status_active} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Users.status.${row.user.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
        if (row.user.status.value_definition === '030') {
          return (
            <StyledLabel>
              <Status src={Invited} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Users.status.${row.user.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
        if (row.user.status.value_definition === '020') {
          return (
            <StyledLabel>
              <Status src={Draft} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Users.status.${row.user.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
        if (row.user.status.value_definition === '050') {
          return (
            <StyledLabel>
              <Status src={Status_block} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Users.status.${row.user.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
        if (row.user.status.value_definition === '060') {
          return (
            <StyledLabel>
              <Status src={Archived} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Users.status.${row.user.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
      },
    },
    {
      dataField: 'remove',
      isDummyField: true,
      text: `${t('table_columns.remove')}`,
      headerStyle: { width: '7%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        if (userPermissions?.type === 'system') {
          return <></>;
        } else {
          return (
            <StyledLabel
              style={{
                marginLeft: '0px',
                justifyContent: 'center',
                alignitems: 'center',
              }}
              onClick={() => {
                setDataRemove(row);
                handleRemoveModal();
              }}
            >
              <Edit src={ic_remove} alt="logo"></Edit>
            </StyledLabel>
          );
        }
      },
    },
  ];

  const systemColumns = [
    {
      dataField: 'userGroupsUser.name',
      text: `${t('table_columns.name')}`,
      sort: true,
      search: true,
      headerStyle: { width: '14%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {calcAvatar(row.user)}
            <StyledIconLabel>{row.user?.name}</StyledIconLabel>
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'userGroupsUser.user_system_rol',
      text: `${t('table_columns.role')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user?.user_system_rol}</StyledLabel>;
      },
    },
    {
      dataField: 'userGroupsUser.email',
      text: `${t('table_columns.email')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title={`${row.user.email}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {row.user.email}
            </StyledLabel>
          </Tooltip>
        );
      },
    },
    {
      dataField: 'userGroupsUser.phone',
      text: `${t('table_columns.phone')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user.phone}</StyledLabel>;
      },
    },

    {
      dataField: 'groupsPermission.permission',
      text: `${t('table_columns.permission')}`,
      headerStyle: { width: '12%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledGroups>
              {`${t(`Users.permissions.${row.group_permission?.description.replace(/\s{2,}/g, ' ').replace(/\s/g, '_')}`)}`}
            </StyledGroups>
          </div>
        );
      },
    },
    {
      dataField: 'remove',
      isDummyField: true,
      text: `${t('table_columns.remove')}`,
      headerStyle: { width: '7%' },
      style: { height: '54px', cursor: 'pointer' },
      formatter: (cellContent, row) => {
        if (userPermissions?.type === 'system') {
          return <></>;
        } else {
          return (
            <StyledLabel
              style={{
                marginLeft: '0px',
                justifyContent: 'center',
                alignitems: 'center',
              }}
              onClick={() => {
                setDataRemove(row);
                handleRemoveModal();
              }}
            >
              <Edit src={ic_remove} alt="logo"></Edit>
            </StyledLabel>
          );
        }
      },
    },
  ];

  return (
    <div>
      <EncView>
        <SpaceEnc>
          <div>
            <HeaderTitle>{groupData?.name}</HeaderTitle>
            <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>{t('Groups.view.groups.header_info')}</p>
          </div>
          <HeaderButton>
            <StyledIconButton
              title={t('buttons.titles.edit')}
              aria-label="edit icon"
              onClick={() => handleClickOpenFormModal()}
            >
              <Icon src={logo_edit} alt="edit icon" />
            </StyledIconButton>
          </HeaderButton>
        </SpaceEnc>
      </EncView>
      <div style={{ paddingBottom: '15px' }}>
        <AppTable
          endpointPath={SITES_GROUPS_API}
          columns={tableColumns}
          filters={filtersSites}
          headerIcon={ic_organizations}
          headerText={t('table_headers.organizations_sites_header')}
          reload={showSucessModal.show}
          joins={[
            { field: 'group' },
            { field: 'site' },
            { field: 'site.organization_id' },
            { field: 'site.status' },
          ]}
          trailingBtn={
            userPermissions?.type === 'system' ? (
              <></>
            ) : (
              <AppButton
                tooltiptitle={t('tooltips.assign_organization_site')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleOpenAddModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      </div>
      {/* succ error message */}
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      <div style={{ paddingBottom: '15px' }}>
        <AppTable
          endpointPath={USERS_GROUPS}
          columns={systemColumns}
          reload={showSucessModal.show}
          headerText={t('table_headers.system_users_header')}
          headerIcon={group}
          joins={[
            { field: 'group', select: ['id'] },
            { field: 'user' },
            { field: 'user.status' },
            { field: 'user.type' },
            { field: 'group_permission' },
          ]}
          filters={filtersSystem}
          trailingBtn={
            userPermissions?.type === 'system' ? (
              <></>
            ) : (
              <AppButton
                tooltiptitle={t('tooltips.add_system_user')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenSystemModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      </div>
      {/* Edit form modal */}
      <AppTable
        endpointPath={USERS_GROUPS}
        columns={employeesColumns}
        reload={showSucessModal.show}
        headerText={t('table_headers.employees_header')}
        headerIcon={group}
        joins={[
          { field: 'group', select: ['id'] },
          { field: 'user' },
          { field: 'user.status' },
          { field: 'user.type' },
          { field: 'user.jobs_titles' },
          { field: 'user.jobs_titles.job_title' },
          { field: 'user.jobs_titles.status' },
        ]}
        filters={filtersEmployees}
        trailingBtn={
          userPermissions?.type === 'system' ? (
            <></>
          ) : (
            <AppButton
              tooltiptitle={t('tooltips.add_employee_user')}
              bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
              bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
              bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
              borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
              icon={ic_add_circle}
              onClick={() => handleClickOpenEmployeeModal()}
            >
              {t('buttons.btn_add')}
            </AppButton>
          )
        }
      />
      <AppModal
        open={openEmployee}
        handleclose={handleClickCloseEmployeeModal}
        title={t('Groups.modals.assign_employee_user_title')}
        inside={1}
      >
        <AddEmployees
          handleclose={handleClickCloseEmployeeModal}
          setShowSucessModal={setShowSucessModal}
          data={groupData}
        />
      </AppModal>
      <AppModal
        open={openSystem}
        handleclose={handleClickCloseSystemModal}
        title={t('Groups.modals.assign_system_user_title')}
        inside={1}
      >
        <AddSystems
          handleclose={handleClickCloseSystemModal}
          setShowSucessModal={setShowSucessModal}
          data={groupData}
        />
      </AppModal>
      <AppModal
        open={openRemoveModal}
        handleclose={handleRemoveModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={removeOrgModalContent.actionIcon}
          title={removeOrgModalContent.title}
          subtitle={removeOrgModalContent.subtitle}
          bgcolor={removeOrgModalContent.bgcolor}
          bordercolor={removeOrgModalContent.bordercolor}
          borderhovercolor={removeOrgModalContent.borderhovercolor}
          bghovercolor={removeOrgModalContent.bghovercolor}
          boxShadow={removeOrgModalContent.boxShadow}
          buttonText={removeOrgModalContent.buttonText}
          subject={''}
          buttonAction={removeUserGroup}
        />
      </AppModal>

      <AppModal open={openForm} handleclose={handleCloseModal} title={t('Groups.modals.edit_group_title')} inside={1}>
        <EditGroup
          handleclose={handleCloseModal}
          setShowSucessModal={setShowSucessModal}
          data={{ id: groupData?.id, name: groupData?.name }}
        />
      </AppModal>
      {/* succ error message */}

      <AppModal
        open={deleteSiteGroup}
        handleclose={handleCloseDeleteSiteGroupModal}
        title={' '}
        boxshadow={'none'}
      >
        <DeleteSiteGroup
          handleCloseDeleteSiteGroupModal={handleCloseDeleteSiteGroupModal}
          setShowSucessModal={setShowSucessModal}
          data={selectedSiteGroup}
        />
      </AppModal>

      <AppModal
        open={openAddForm}
        handleclose={handleCloseAddModal}
        title={t('Groups.modals.assign_site_title')}
        inside={1}
      >
        {
          <AddSiteGroupForm
            handleclose={handleCloseAddModal}
            setShowSucessModal={setShowSucessModal}
            data={groupId}
          />
        }
      </AppModal>
    </div>
  );
};
