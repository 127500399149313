import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, AppDropDownSelect, AppModal, AppModalStatus } from '@components';
import { editEntityData } from '@services';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { USERS_API } from 'services/CONSTANTS';
import ico_warning_triangle from '@assets/icons/ico_warning_triangle.svg';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const EditSystemUserForm = ({
  handleclose,
  setShowSucessModal,
  data: { id, name, phone, email, user_system_rol, role, status },
  editAction,
}) => {
  const { t } = useTranslation();
  const editSystemUserSchema = Yup.object().shape({
    name: Yup.string().max(100, `${t('validation.must_be_name')}`).required(`${t('validation.name_required')}`),
    phone:
      Yup.string()
      .required(`${t('validation.phone_required')}`),
    email: Yup.string().email(`${t('validation.email_invalid')}`).required(`${t('validation.email_required')}`),
    user_system_rol: Yup.string().nullable(),
    role: Yup.object().nullable().required(`${t('validation.type_required')}`),
    action: Yup.string(),
  });
  const { valuesDefinitions } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  //Open/close modal
  const [openEmailConfirmation, setOpenEmailConfirmation] = useState(false);
  //Save form values
  const formValues = useRef({});
  //To handle messages
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  const systemUserTypes = valuesDefinitions.filter(
    (v) =>
      v.definitionType.definition_type === 'ORGANIZATION_USER_ROLE' &&
      v.validation_type === 'SYSTEM_USER_TYPE',
  );

  //Content to show in change email modal
  const changeEmailModalData = {
    buttonText: `${t('buttons.btn_confirm')}`,
    actionIcon: ico_warning_triangle,
    title: ``,
    subtitle: `${t('Profile.change_personal.changing_email_description')}`,
    subtitleWeight: 400,
    subtitleColor: PALETTE_COLOR.BLACK,
    subjectColor: '#000000',
    subjectWeight: 400,
    bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
    bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
    borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
  };

  /***
   * Open/close change email modal
   */
  const handleChangeEmailModal = () => {
    setOpenEmailConfirmation(!openEmailConfirmation);
  };

  /***
   * Decides if open change email modal or not
   */
  const editUserChangeEmail = (values) => {
    formValues.current = values;
    if (email !== values.email) {
      handleChangeEmailModal();
    } else {
      editSysUser();
    }
  };

  /***
   *
   */
  const editSysUser = () => {
    setOpenEmailConfirmation(false);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    var editData = { ...formValues.current, role: formValues.current.role.vdef };

    const { action } = formValues.current;
    if (action) {
      const status = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === action,
      )[0];
      editData = { ...editData, status };
    } else {
      editData = { ...editData, status };
    }
    setLoading(true);
    editEntityData(`${USERS_API}${id}`, editData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title:
            editAction === 'restore'
              ? `${t('success_messages.user_restored')}`
              : `${t('success_messages.user_edited')}`,
          message: '',
        });
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          //Show message
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.user_exists')}`,
            message: '',
          });
        } else {
          //Show message
          setShowErrorMessage({
            show: true,
            type: 'error',
            title:
              editAction === 'restore'
                ? `${t('error_messages.error_user_restoring')}`
                : `${t('error_messages.error_user_editing')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={editSystemUserSchema}
      onSubmit={editUserChangeEmail}
      initialValues={{
        name,
        phone,
        email,
        user_system_rol,
        action: '',
        role: {
          label: `${t(`roles.${role?.description.replace(/\s/g, '_')}`)}`, 
          value: role?.value_definition_id,
          vdef: role,
        },
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px', fontSize: '14px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px ' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                ></InputForm>
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                ></InputForm>
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.type')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={systemUserTypes.map((option) => ({
                    label: `${t(`roles.${option?.description.replace(/\s/g, '_')}`)}`,
                    value: option?.value_definition_id,
                    vdef: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height="8rem"
                  name="role"
                  placeholder={t('required')}
                  onChange={(selected) => {
                    setFieldValue('role', selected);
                    if (selected && selected?.vdef?.value_definition === '040') {
                      setFieldValue('user_system_rol', null);
                    }
                  }}
                  invalid={touched.role && errors.role}
                  defaultValue={values.role && {
                    label: `${t(`roles.${values.role?.vdef.description.replace(/\s/g, '_')}`)}`,
                    value: values.role?.value_definition_id,
                    vdef: values.role
                  }}
                />
                {touched.role && errors.role && (
                  <ErrorText className="px-2 mb-0">{errors.role}</ErrorText>
                )}
              </Col>
            </Row>
            {values.role == null || values?.role?.vdef?.value_definition !== '040' ? (
              <Row className="mb-3">
                <Col xs={3}>
                  <LabelForm tittle={t('form_labels.role')}></LabelForm>
                </Col>
                <Col xs={9}>
                  <InputForm
                    className="mb-0"
                    inpfontsize={'14px'}
                    height={'40px'}
                    placeholder={t('optional')}
                    name="user_system_rol"
                    value={values.user_system_rol}
                    invalid={touched.user_system_rol && errors.user_system_rol}
                    onChange={handleChange}
                  ></InputForm>
                  {touched.user_system_rol && errors.user_system_rol && (
                    <ErrorText className="px-2 mb-0">{errors.user_system_rol}</ErrorText>
                  )}
                </Col>
              </Row>
            ) : null}
            {editAction === 'edit' ? (
              <StyleButtons>
                <span>
                  <AppButton
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bordercolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    title={t('buttons.btn_update')}
                  >
                    {t('buttons.btn_update')}
                  </AppButton>
                  <AppButton
                    loading={loading}
                    size="lg"
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={handleclose}
                    title={t('buttons.btn_cancel')}
                  >
                    {t('buttons.btn_cancel')}
                  </AppButton>
                </span>
              </StyleButtons>
            ) : (
              <StyleButtons>
                <span>
                  <AppButton
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bordercolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    title={t('buttons.btn_restore')}
                    onClick={() => {
                      setFieldValue('action', '010');
                    }}
                  >
                    {t('buttons.btn_restore')}
                  </AppButton>
                  <AppButton
                    size="lg"
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    type="submit"
                    title={t('buttons.btn_restore_blocked')}
                    onClick={() => {
                      setFieldValue('action', '050');
                    }}
                  >
                    {t('buttons.btn_restore_blocked')}
                  </AppButton>
                  <AppButton
                    size="lg"
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={handleclose}
                    title={t('buttons.btn_cancel')}
                  >
                    {t('buttons.btn_cancel')}
                  </AppButton>
                </span>
              </StyleButtons>
            )}
            {showErrorMessage.show && (
              <CustomizedSnackbar
                show={showErrorMessage.show}
                type={showErrorMessage.type}
                title={showErrorMessage.title}
                message={showErrorMessage.message}
              />
            )}
          </Form>
          {/* Change e-mail request */}
          <AppModal
            width={500}
            styleModal={{ padding: '30px 5px 30px 5px', width: '600px' }}
            styles={{}}
            open={openEmailConfirmation}
            handleclose={handleChangeEmailModal}
            title={''}
            boxshadow={'none'}
            inside={1}
          >
            <AppModalStatus
              actionIcon={changeEmailModalData.actionIcon}
              title={`${t('Profile.change_personal.changing_email')}`}
              subtitle={''}
              subtitleColor={changeEmailModalData.subtitleColor}
              subtitleWeight={changeEmailModalData.subtitleWeight}
              bgcolor={changeEmailModalData.bgcolor}
              bordercolor={changeEmailModalData.bordercolor}
              borderhovercolor={changeEmailModalData.borderhovercolor}
              bghovercolor={changeEmailModalData.bghovercolor}
              boxShadow={changeEmailModalData.boxShadow}
              buttonText={changeEmailModalData.buttonText}
              subject={changeEmailModalData.subtitle}
              subjectColor={changeEmailModalData.subjectColor}
              subjectWeight={changeEmailModalData.subjectWeight}
              buttonAction={editSysUser}
              isLoading={loading}
            />
          </AppModal>
        </div>
      )}
    </Formik>
  );
};
