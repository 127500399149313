import React, { useState, useEffect, useCallback } from 'react';
import { AppTable, AppOrgIcon } from '@components';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import EncList from '@components/EncList';
import Calender from '@assets/icons/Calender.svg';
import { useSelector } from 'react-redux';
import { USERS_API } from '@services/CONSTANTS';
import { getEntityData } from '@services';
import { calcAvatar } from 'utils/avatar';
import { calcDuration } from 'utils/appUtils';
import ic_eye from '@assets/icons/ic_eye.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TIME_SHEET } from 'navigation/CONSTANTS';
import { setJobSchedulerGroupAction, setTimeSheetDatesAction } from '@redux/actions/appActions';
import moment from 'moment';
import { IconButton } from '@mui/material';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';

import { useTranslation } from 'react-i18next';

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

const DatesWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1179bf;
  margin-top: 8px;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const TotalLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-left: 23px;
`;

const BtnFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  border: 1px solid #e7ecf4;
  border-radius: 20px;
  padding-right: 9px;
`;

const HeaderRowWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  background: ${(props) => (props.background ? props.background : '#A5CD69')};
  border-radius: 10px;
  padding: 8px 12px;
  color: #ffffff;
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  font-weight: 400;
  font-size: 14px;
`;

export const TimeSheetLobby = (props) => {
  const { t } = useTranslation();
  //Redux
  const { userData } = useSelector((state) => state.app);
  let history = useHistory();
  const [loadingGroups, setLoadingGroups] = useState([{}]);
  const [totals, setTotals] = useState([]);
  const [search, setSearch] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = React.useState(moment().startOf('day').format());

  let goTo = useCallback(
    (route) => {
      history.push(route);
    },
    [history],
  );

  const setSelectedGroup = React.useCallback(
    async (group) => {
      dispatch(setJobSchedulerGroupAction(group));
    },
    [dispatch],
  );

  const systemColumns = [
    {
      dataField: 'id',
      text: `${t('table_columns.ID')}`,
      hidden: true,
      sort: false,
      search: false,
    },
    {
      dataField: 'group.name',
      text: `${t('table_columns.group_name')}`,
      sort: false,
      search: false,
      headerStyle: { width: '15%' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.group?.name}</StyledLabel>;
      },
    },
    {
      dataField: 'group.sites',
      text: `${t('table_columns.sites')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.sites && row.sites.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {row.sites.map((site, index) =>
                index < 5 ? (
                  <div style={{ display: 'flex', marginLeft: '-15px' }} key={index}>
                    <AppOrgIcon color={site.organization_id.color} title={site.name}>
                      {site.name.substring(0, 2).toUpperCase()}
                    </AppOrgIcon>
                  </div>
                ) : (
                  ''
                ),
              )}

              {row.sites.length === 1 ? (
                <StyledLabel style={{ margin: '4px' }}>{row.sites[0].name}</StyledLabel>
              ) : (
                ''
              )}

              {row.sites.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.sites.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'admins',
      text: `${t('table_columns.admins')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.admins && row.admins.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {' '}
              {row.admins.length !== 1 ? (
                row.admins.map((admin, index) =>
                  index < 5 ? (
                    <div style={{ display: 'flex', marginLeft: '-10px' }} key={index}>
                      <span title={admin.user.name}>{calcAvatar(admin.user, 30, 30)}</span>
                    </div>
                  ) : (
                    ''
                  ),
                )
              ) : (
                <div style={{ display: 'flex', marginLeft: '-10px' }}>
                  <span title={row.admins[0].user.name}>
                    {calcAvatar(row.admins[0].user, 30, 30)}
                  </span>
                  <StyledLabel style={{ margin: '4px' }}>{row.admins[0].user.name}</StyledLabel>
                </div>
              )}
              {row.admins.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.admins.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'employees',
      text: `${t('table_columns.employees')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.employees && row.employees.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {' '}
              {row.employees.map((employee, index) =>
                index < 5 ? (
                  <div style={{ display: 'flex', marginLeft: '-10px' }} key={index}>
                    <span title={employee.user.name}>{calcAvatar(employee.user, 30, 30)}</span>
                  </div>
                ) : (
                  ''
                ),
              )}
              {row.employees.length === 1 ? (
                <StyledLabel style={{ margin: '4px' }}>{row.employees[0].user.name}</StyledLabel>
              ) : (
                ''
              )}
              {row.employees.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.employees.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'totalPaidDuration',
      text: `${t('table_columns.paid_h')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        return <TotalLabel>{calcDuration(cellContent)}</TotalLabel>;
      },
    },
    {
      dataField: 'totalRegularDuration',
      text: `${t('table_columns.paid_regular_h')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        return <TotalLabel>{calcDuration(cellContent)}</TotalLabel>;
      },
    },
    {
      dataField: 'totalOvertimeDuration',
      text: `${t('table_columns.paid_overtime_h')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        return <TotalLabel>{calcDuration(cellContent)}</TotalLabel>;
      },
    },
    {
      dataField: 'actions',
      text: `${t('table_columns.view')}`,
      sort: false,
      search: false,
      headerStyle: { width: '7%' },
      formatter: (cellContent, row) => {
        if (row.group) {
          return (
            <StyledLabel>
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedGroup(row.group);
          goTo(TIME_SHEET.replace(':type', 'day'));
        },
      },
    },
  ];

  useEffect(() => {
    if (userData) {
      setLoadingData(true);
      getEntityData(
        `${USERS_API}query/userGroups`,
        `startDate=${`${moment(dateFilter)
          .startOf('week')
          .format('YYYY-MM-DD')}T00:00:00-00:00`}&endDate=${`${moment(dateFilter)
          .endOf('week')
          .format('YYYY-MM-DD')}T23:59:59-00:00`}&search=${search}`,
      )
        .then((res) => {
          setLoadingGroups(res.data);
          setTotals(res.totals);
          if (res.length === 1) {
            setSelectedGroup(res.data[0].group);
          }
          setLoadingData(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    }
  }, [userData, setSelectedGroup, dateFilter, search, dispatch]);

  useEffect(() => {
    //Save data on redux
    dispatch(
      setTimeSheetDatesAction({
        startDate: moment(dateFilter).utc().startOf('week').format('yyyy-MM-DD'),
        endDate: moment(dateFilter).utc().endOf('week').format('yyyy-MM-DD'),
      }),
    );
  }, [dispatch, dateFilter]);

  return (
    <Container fluid>
      <EncList title={t('Timesheet.title')} icon={Calender} />
      <Col>
        <AppTable
          overrideSearch={(searchTerm) => {
            setSearch(searchTerm);
          }}
          columns={systemColumns}
          data={loadingGroups}
          loading={loadingData}
          disableFilters={false}
          disablePagination={true}
          disableTopPagination={true}
          noBorders={true}
          filtersAdditional={[
            {
              filter: (
                <BtnFilterWrapper>
                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    style={{ width: 24, height: 24, marginTop: 8, marginLeft: 8 }}
                    onClick={(e) => {
                      setDateFilter(moment(dateFilter).startOf('week').subtract(1, 'day').format());
                    }}
                  >
                    <ArrowWrapper
                      style={{ width: 5.5, height: 11.5 }}
                      src={ic_arrow_left_enabled}
                      alt="IcArrowLeft"
                    />
                  </IconButton>
                  <DatesWrapper>
                    {`${moment(dateFilter).startOf('week').format('MMMM DD')} - ${moment(dateFilter)
                      .endOf('week')
                      .format('MMMM DD')}`}
                  </DatesWrapper>

                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    style={{ width: 24, height: 24, marginTop: 8 }}
                    onClick={(e) => {
                      const newDate = moment(dateFilter).endOf('week').add(1, 'day');
                      setDateFilter(newDate.format());
                    }}
                  >
                    <ArrowWrapper
                      style={{ width: 5.5, height: 11.5 }}
                      src={ic_arrow_right_enabled}
                      alt="IcArrowLeft"
                    />
                  </IconButton>
                </BtnFilterWrapper>
              ),
            },
            {
              filter: (
                <BtnFilterWrapper style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <DatesWrapper
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      setDateFilter(moment().format());
                    }}
                  >
                    {t('buttons.btn_current_week')}
                  </DatesWrapper>
                </BtnFilterWrapper>
              ),
            },
          ]}
          headerRow={
            <HeaderRowWrapper>
              <HeaderWrapper>
                <div>{`${t('Timesheet.labels.total_paid_hours')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcDuration(totals?.sumTotalPaidDuration)}
                </span>
              </HeaderWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: 0,
                  gap: 8,
                }}
              >
                <HeaderWrapper background={'#8EC044'}>
                  <div>{`${t('Timesheet.labels.paid_regular_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals?.sumTotalRegularDuration)}
                  </span>
                </HeaderWrapper>
                <HeaderWrapper background={'#FF9153'}>
                  <div>{`${t('Timesheet.labels.paid_overtime_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals?.sumTotalOvertimeDuration)}
                  </span>
                </HeaderWrapper>
              </div>
            </HeaderRowWrapper>
          }
        />
      </Col>
    </Container>
  );
};
