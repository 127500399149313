import React, { useState, useMemo } from 'react';
import { AppTable, IcArrow, AppButton, AppModalNotes } from '@components';
import { CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatEspecial } from '@utils/dateFormatHelper';
import { TIME_SHEET_API } from '@services/CONSTANTS';
import moment from 'moment';
import { IconButton /*, Tooltip*/ } from '@mui/material';
import { PALETTE_COLOR } from 'config/CONSTANTS';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { calcAvatar } from 'utils/avatar';
import { saveEntityData } from '@services';

import DateRangePicker from '@mui/lab/DateRangePicker';
//import TimePicker from '@mui/lab/TimePicker';
//import PickersDay, { pickersDayClasses } from '@mui/lab/PickersDay';
//import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import Box from '@mui/material/Box';
//import { makeStyles } from '@mui/styles';

import ic_stacked_close_button from '@assets/icons/ic_stacked_close_button.svg';
//import logo_edit from '@assets/icons/edit.svg';
import ic_status_signed from '@assets/icons/ic_status_signed.svg';
import ic_status_unsigned from '@assets/icons/ic_status_unsigned.svg';
import ic_status_no_time from '@assets/icons/ic_status_no_time.svg';
import ic_note_enable from '@assets/icons/ic_note_enable.svg';

import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
  color: #1179bf;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ImageWrapper = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const TimeSheetColLabel = styled.div`
  font-weight: 300;
  font-size: 14px;
`;

const HeaderRowWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  background: ${(props) => (props.background ? props.background : '#A5CD69')};
  border-radius: 10px;
  padding: 8px 12px;
  color: #ffffff;
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  font-weight: 400;
  font-size: 14px;
`;

const ModalNotes = styled.div`
  .line {
    margin: 0 24px;
    border-bottom: 1px solid ${PALETTE_COLOR.MAIN_GRAY};
  }
`;

const Note = (props) => {
  const { note } = props;
  if (note)
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <img
          style={{ cursor: 'pointer' }}
          src={ic_note_enable}
          width={20}
          height={21}
          alt={`ic_note_enable`}
        />
        <TimeSheetColLabel>{``}</TimeSheetColLabel>
      </div>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
      <img
        style={{ cursor: 'pointer' }}
        src={ic_note_enable}
        width={20}
        height={21}
        alt={`ic_note_enable`}
      />
      <TimeSheetColLabel>{``}</TimeSheetColLabel>
    </div>
  );
};

// const Edit = (props) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'center',
//         alignContent: 'center',
//         alignItems: 'center',
//         gap: 10,
//       }}
//     >
//       <img style={{ cursor: 'pointer' }} src={logo_edit} width={20} height={18} alt={`logo_edit`} />
//       <TimeSheetColLabel>{``}</TimeSheetColLabel>
//     </div>
//   );
// };

const calcDuration = (minutes) => {
  if (minutes && minutes != null) {
    return `${('' + parseInt(minutes / 60)).padStart(2, '0')}:${('' + (minutes % 60)).padStart(
      2,
      '0',
    )}`;
  } else {
    return '--';
  }
};

const calcHoursMinDuration = (mins) => {
  if (!mins) return '0h';
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? 0 + minutes : minutes;
  return minutes !== 0 ? `${hours}h ${minutes}m` : `${hours}h`;
};

export const EmployeeDetails = ({ employee, timesheet, handleClose, day }) => {
  const { t } = useTranslation();
  //Store page conf
  const [selectedConfig, setSelectedConfig] = useState({
    filterDatesSummaryView: 'Weekly',
    viewSummary: 'By Employee',
    start_date: moment(day).startOf('week').format(),
    end_date: moment(day).endOf('week').format(),
  });

  const [value, setValue] = React.useState([null, null]);
  const [open, setOpen] = React.useState(false);
  const [totals, setTotals] = useState({});
  const [notes, setNotes] = React.useState([{}]);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [indexNotes, setIndexNotes] = React.useState('');
  const { jobSchedulerGroup, userData } = useSelector((state) => state.app);
  const [filterDatesSummaryView, setFilterDatesSummaryView] = useState('Weekly');
  //const [openSummaryDetails, setOpenSummaryDetails] = useState(false);

  const setNote = (data, index) => {
    setOpenNotes(true);
    if (data && data.timeSheets && data.timeSheets) {
      setIndexNotes(index);
      setNotes(data.timeSheets.map((ts) => ts.userShift?.shift));
    }
  };

  /***
   * Update filter by group and/or day and/or site
   */
  const filters = useMemo(() => {
    const defaultFilter = [
      {
        field: 'innerGroup.id',
        operator: CondOperator.IN,
        value: jobSchedulerGroup.id,
      },
      {
        field: 'shiftDate',
        operator: CondOperator.BETWEEN,
        value: [
          `${moment(selectedConfig.start_date).format('YYYY-MM-DD')} 00:00:00`,
          `${moment(selectedConfig.end_date).endOf('day').format('YYYY-MM-DD')} 23:59:59`,
        ],
      },
      {
        field: 'userShift.user.id',
        operator: CondOperator.EQUALS,
        value: employee.user.id,
      },
    ];
    return [...defaultFilter];
  }, [selectedConfig, jobSchedulerGroup.id, employee.user.id]);

  // Joins in memo to avoid multi request
  const joins = useMemo(() => {
    return [
      { field: 'userShift' },
      { field: 'site' },
      { field: 'group' },
      { field: 'userShift.shift' },
      { field: 'userShift.user' },
      { field: 'userShift.signer' },
      { field: 'userShift.status' },
      { field: 'site.organization_id' },
      { field: 'groupByDayEmployee' }, //USED AS A FLAG TO BACKEND GROUPING THE DATA
      { field: 'sortByDate' }, //USED AS A FLAG TO SORT BY DATE AND CLOCK IN DATE
      { field: 'filterByUserSites' }, //USED AS A FLAG TO BACKEND FILER TIMESHEET BY USER SITES
    ];
  }, []);

  /***
   * Render status icon
   */
  const Status = (props) => {
    const { userShift, withEndDate } = props;

    if (withEndDate) {
      if (userShift.signer) {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <img style={{ cursor: 'pointer' }} src={ic_status_signed} alt={`ic_status_signed`} />
            <TimeSheetColLabel>{t('form_labels.signed')}</TimeSheetColLabel>
          </div>
        );
      } else {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <img src={ic_status_unsigned} alt={`ic_status_unsigned`} />
            <TimeSheetColLabel>{`${t('form_labels.unsigned').substring(0,7)}...`}</TimeSheetColLabel>
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <img src={ic_status_no_time} alt={`ic_status_no_time`} />
          <TimeSheetColLabel>{t('no_time')}</TimeSheetColLabel>
        </div>
      );
    }
  };

  /***
   * Get totals
   */
  React.useEffect(() => {
    const request = {
      type: 'byEmployee',
      idToFilter: employee.user.id, //User (employee) to filter timesheets
      systemUserToFilter: userData.sub_cognito_id,
      groupIdToFilter: jobSchedulerGroup.id,
      startDate: `${moment(selectedConfig.start_date).format('YYYY-MM-DD')} 00:00:00`,
      endDate: `${moment(selectedConfig.end_date).format('YYYY-MM-DD')} 23:59:59`,
    };
    //Call API
    saveEntityData(`${TIME_SHEET_API}time-sheet/totals`, request)
      .then((res) => {
        setTotals(res);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
  }, [
    employee,
    selectedConfig.start_date,
    selectedConfig.end_date,
    jobSchedulerGroup.id,
    userData,
  ]);

  /**
   *
   */
  const getGroupedTs = React.useCallback((timeSheets) => {
    let result = [];
    for (let i = 0; i < timeSheets.length; i++) {
      let timeSheet = timeSheets[i];
      //Get consecutive ts: Those which have same site consecutively, if are not consecutively array size will be 1
      const consecutiveTS = getConsecutiveTimeShifts(timeSheets, timeSheet, i);
      result.push(consecutiveTS);
      //Add i, to avoid evaluate same ts
      if (consecutiveTS.length > 1) {
        i = i + (consecutiveTS.length - 1);
      }
    }
    return result;
  }, []);

  /***
   *
   */
  const getConsecutiveTimeShifts = (originalTSArray, tsToCompare, index) => {
    let tsConsecutiveFound = false;
    let tsArrayToReturn = [];
    tsArrayToReturn.push(tsToCompare);
    while (index < originalTSArray.length && !tsConsecutiveFound) {
      //Still elements
      if (originalTSArray.length > index + 1) {
        //Same site
        if (tsToCompare.site.id === originalTSArray[index + 1].site.id) {
          tsArrayToReturn.push(originalTSArray[index + 1]);
        } else {
          tsConsecutiveFound = true;
        }
      } else {
        //No more elements
        tsConsecutiveFound = true;
      }
      index++;
    }
    return tsArrayToReturn;
  };

  /***
   *
   */
  const getValidValue = (tsArray, type) => {
    let valueToReturn = null;
    for (let index = 0; index < tsArray.length; index++) {
      const element = tsArray[index];
      if (type === 1) {
        if (element.totalOnSiteDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.onSiteDuration;
          }, 0);
          break;
        }
      } else if (type === 2) {
        if (element.totalBreakDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.breakDuration;
          }, 0);
          break;
        }
      } else if (type === 3) {
        if (element.totalPaidDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.workedDuration;
          }, 0);
          break;
        }
      } else if (type === 4) {
        if (element.totalRegularDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.regularDuration;
          }, 0);
          break;
        }
      } else if (type === 5) {
        if (element.totalOvertimeDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.overtimeDuration;
          }, 0);
          break;
        }
      }
    }
    return valueToReturn;
  };

  const getTimeSheetColumns = useMemo(() => {
    return [
      {
        dataField: 'date',
        text: `${t('table_columns.day')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        style: { height: '54px', padding: 0 },
        headerStyle: { width: '145px' },
        formatter: (cellContent, row) => {
          return (
            <div
              style={{
                // borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel style={{ fontWeight: '500' }}>
                {moment.utc(row.date).format('dddd - DD')}
              </TimeSheetColLabel>
            </div>
          );
        },
      },
      {
        dataField: 'innerSite.name',
        text: `${t('table_columns.site')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '200px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <StyledNestedCell
                style={{
                  backgroundColor: empSiteTs.site.organization_id.color,
                  display: 'block',
                  paddingTop: '13px',
                }}
              >
                {empSiteTs.site.name}
              </StyledNestedCell>
            </div>
          ));
        },
      },
      {
        dataField: 'shiftDate',
        text: `${t('table_columns.start_date')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        style: { height: '54px', padding: 0 },
        headerStyle: { width: '145px' },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment.utc(empSiteTs.shiftDate).format('DD/MM/YYYY')}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'clockInDate',
        text: `${t('table_columns.clock_in')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '115px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment.utc(empSiteTs.clockInDate).format('hh:mm A')}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'endDate',
        text: `${t('table_columns.end_date')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '145px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment(empSiteTs.endDate).isValid()
                  ? moment.utc(empSiteTs.endDate).format('DD/MM/YYYY')
                  : '--'}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'clockOutDate',
        text: `${t('table_columns.clock_out')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '115px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment(empSiteTs.endDate).isValid()
                  ? moment.utc(empSiteTs.clockOutDate).format('hh:mm A')
                  : '--'}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'notes',
        text: `${t('table_columns.notes')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '80px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row, index) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
              id={`noteEmploye${index}-${i}`}
              onClick={() => {
                setNote(row, `${index}-${i}`);
              }}
            >
              <Note note={null} />
            </div>
          ));
        },
      },
      {
        dataField: 'onSiteDuration',
        text: `${t('table_columns.on_site_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.onSiteDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'breakDuration',
        text: `${t('table_columns.break_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.breakDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'workedDuration',
        text: `${t('table_columns.paid_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.workedDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'totalOnSiteDuration',
        text: `${t('table_columns.total_on_site_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.on_site_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 1))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalOnSiteDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'totalBreakDuration',
        text: `${t('table_columns.total_break_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.break_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 2))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalBreakDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'totalPaidDuration',
        text: `${t('table_columns.total_paid_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.paid_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 3))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>{calcDuration(empSiteTs.totalPaidDuration)}</TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'totalRegularDuration',
        text: `${t('table_columns.total_regular_h')}`,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        disableColumnToggle: false,
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.regular_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 4))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalRegularDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'totalOvertimeDuration',
        text: `${t('table_columns.total_overtime_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: false,
        headerStyle: { width: '130px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.overtime_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 5))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalOvertimeDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'userShift.status.description',
        text: `${t('table_columns.status')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '150px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets?.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <Status
                userShift={empSiteTs.userShift}
                withEndDate={moment(empSiteTs.endDate).isValid()}
              />
            </div>
          ));
        },
      },
      // {
      //   dataField: 'editOptions',
      //   text: `${t('table_columns.view')}`,
      //   sort: false,
      //   isDummyField: true,
      //   search: true,
      //   disableColumnToggle: true,
      //   headerStyle: { width: '70px' },
      //   style: { height: '54px', padding: 0 },
      //   formatter: (cellContent, row) => {
      //     return row.timeSheets?.map((empSiteTs, i) => (
      //       <div
      //         style={{
      //           borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
      //           padding: '16px 23px 17px 23px',
      //         }}
      //       >
      //         <Tooltip title={`Edit`}>
      //           <Edit />
      //         </Tooltip>
      //       </div>
      //     ));
      //   },
      // },
    ];
  }, [/*setSelectedTimeSheet, setOpenForm, sitesList*/ getGroupedTs, t]);

  /***
   * Load sites
   */
  /*useEffect(() => {
    if (jobSchedulerGroup !== {} && jobSchedulerGroup.id) {
      console.log(dateFilter);
      setLoadingData(true);
      const qb2 = RequestQueryBuilder.create();
      getEntityData(
        `${TIME_SHEET_API}query/timeSheets/${jobSchedulerGroup.id}/${dateFilter.start_date}/${dateFilter.end_date}`,
        qb2.query(),
      )
        .then((res) => {
          setLoadingData(false);
          console.log(res);
          setSitesList(res);
        })
        .catch((err) => {
          setLoadingData(false);
          console.log('axios err=', err);
        });
    }
  }, [viewSummary, filters, jobSchedulerGroup, dateFilter]);*/

  /***
   * Load sites
   */
  /*useEffect(() => {
    setFilters([
      {
        field: 'innerGroup.id',
        operator: CondOperator.IN,
        value: jobSchedulerGroup.id,
      },
      {
        field: 'shiftDate',
        operator: CondOperator.BETWEEN,
        value: [
          moment(dateFilter.start_date).format('YYYY-MM-DD'),
          moment(dateFilter.end_date).format('YYYY-MM-DD'),
        ],
      },
    ]);
  }, [jobSchedulerGroup.id, dateFilter]);*/

  const generateFilterDates = (view) => {
    switch (view) {
      case 'Weekly':
        setSelectedConfig({
          ...selectedConfig,
          start_date: moment().startOf('week').format('YYYY-MM-DD'),
          end_date: moment().endOf('week').format('YYYY-MM-DD'),
          filterDatesSummaryView: view,
        });
        break;
      default:
        break;
    }
  };

  const continueFilterDates = (action) => {
    let start = '';
    let end = '';
    switch (selectedConfig.filterDatesSummaryView) {
      case 'Weekly':
        if (action === 'Add') {
          start = moment(selectedConfig.start_date).add(7, 'day');
          end = moment(selectedConfig.end_date).add(7, 'day');
        } else {
          start = moment(selectedConfig.start_date).subtract(7, 'day');
          end = moment(selectedConfig.end_date).subtract(7, 'day');
        }
        setSelectedConfig({
          ...selectedConfig,
          start_date: start.format('YYYY-MM-DD'),
          end_date: end.format('YYYY-MM-DD'),
        });
        break;
      default:
        break;
    }
  };

  /***
   * Current functionality
   */
  const getCurrentWeek = () => {
    setSelectedConfig({
      ...selectedConfig,
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
      filterDatesSummaryView: 'Weekly',
    });
    setFilterDatesSummaryView('Weekly');
  };

  /***
   * Apply format MMM D to filter
   */
  const getLabelDate = () => {
    return (
      formatEspecial(moment(selectedConfig.start_date).format('YYYY-MM-DD'), 'MMM D') +
      ' - ' +
      formatEspecial(moment(selectedConfig.end_date).format('YYYY-MM-DD'), 'MMM D')
    );
  };

  return (
    <>
      <AppTable
        avatar={calcAvatar(employee.user, 40, 40)}
        avatarName={employee.user?.name}
        isStackedDetails={true}
        endpointPath={TIME_SHEET_API}
        columns={getTimeSheetColumns}
        filters={filters}
        joins={joins}
        reload={true}
        withColumnToggler={true}
        allowOverFlowX={true}
        disableTopPagination={true}
        withExport={false}
        trailingBtn={
          <ImageWrapper
            src={ic_stacked_close_button}
            alt="close_modal_icon"
            onClick={handleClose}
          />
        }
        filtersAdditional={[
          // {
          //   filter: (
          //     <PaginationTopButton style={{ marginRight: '12px', marginLeft: '12px' }}>
          //       <IconButton
          //         aria-label={t('form_labels.job_scheduler')}
          //         onClick={(e) => {
          //           continueFilterDates('Less');
          //         }}
          //       >
          //         <IcArrow
          //           style={{ height: 10, width: 5, marginRight: '10px' }}
          //           direction="left"
          //           status={'enabled'}
          //         />
          //       </IconButton>
          //       <div style={{ color: '#1179BF' }}>{}</div>
          //       <IconButton
          //         aria-label={t('form_labels.job_scheduler')}
          //         onClick={(e) => {
          //           continueFilterDates('Add');
          //         }}
          //       >
          //         <IcArrow
          //           style={{ height: 10, width: 5 }}
          //           direction={'right'}
          //           status={'enabled'}
          //         />
          //       </IconButton>
          //     </PaginationTopButton>
          //   ),
          // },
          {
            filter: (
              <Menu
                menuButton={
                  <AdvanceFilterButtonWrapper
                    style={{
                      width: 'max-content',
                      marginBottom: '15px',
                      borderRadius: '20px',
                      marginRight: '10px',
                      marginLeft: '10px',
                    }}
                  >
                    {t(`Timesheet.menu.${filterDatesSummaryView.toLowerCase().replace(/\s/g, '_')}`)}
                    <IcArrow style={{ cursor: 'pointer' }} status={'enabled'} direction={'down'} />
                  </AdvanceFilterButtonWrapper>
                }
              >
                <MenuItem
                  onClick={(e) => {
                    setFilterDatesSummaryView('Weekly');
                    generateFilterDates('Weekly');
                  }}
                >
                  {t('Timesheet.menu.weekly')}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setFilterDatesSummaryView('Custom Dates');
                    generateFilterDates('Custom Dates');
                    setTimeout(() => {
                      setValue([new Date(), null]);
                      setOpen(!open);
                    }, 500);
                  }}
                >
                  {t('Timesheet.menu.custom_dates')}
                </MenuItem>
              </Menu>
            ),
          },
          filterDatesSummaryView === 'Custom Dates' && {
            filter: (
              <LocalizationProvider
                dateAdapter={DateAdapter}
                locale={i18next.resolvedLanguage}
                style={{ fontSize: '14px' }}
                localeText={{ start: 'Check-in', end: 'Check-out' }}
              >
                <DateRangePicker
                  open={open}
                  closeOnSelect={true}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    if (newValue[0] && newValue[1]) {
                      setSelectedConfig({
                        ...selectedConfig,
                        start_date: newValue[0],
                        end_date: newValue[1],
                        filterDatesSummaryView: 'Custom Dates',
                      });
                      setOpen((isOpen) => !isOpen);
                    }
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <PaginationTopButton
                        onClick={() => {
                          setValue([value[0], null]);
                          setOpen((isOpen) => !isOpen);
                        }}
                        style={{ marginRight: '12px', cursor: 'pointer' }}
                        {...startProps}
                        {...endProps}
                      >
                        <div style={{ color: '#1179BF' }}>
                          {`${
                            moment(value[0]).isValid()
                              ? moment(value[0]).format('MMM D')
                              : `${t('form_labels.start_date')}`
                          } 
                          - 
                          ${
                            moment(value[1]).isValid()
                              ? moment(value[1]).format('MMM D')
                              : `${t('form_labels.end_date')}`
                          }`}
                        </div>
                      </PaginationTopButton>
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            ),
          },
          filterDatesSummaryView === 'Weekly' && {
            filter: (
              <PaginationTopButton style={{ marginRight: '12px' }}>
                <IconButton
                  aria-label={t('form_labels.job_scheduler')}
                  style={{}}
                  onClick={(e) => {
                    continueFilterDates('Less');
                  }}
                >
                  <IcArrow style={{ height: 10, width: 5 }} direction="left" status={'enabled'} />
                </IconButton>
                <div style={{ color: '#1179BF' }}>{getLabelDate()}</div>
                <IconButton
                  aria-label={t('form_labels.job_scheduler')}
                  onClick={(e) => {
                    continueFilterDates('Add');
                  }}
                >
                  <IcArrow
                    style={{ height: 10, width: 5 }}
                    direction={'right'}
                    status={'enabled'}
                  />
                </IconButton>
              </PaginationTopButton>
            ),
          },
          {
            filter: (
              <AppButton
                size="lg"
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                color={PALETTE_COLOR.MAIN_BLUE}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                style={{ fontSize: '14px', marginRight: '10px' }}
                onClick={() => getCurrentWeek()}
              >
                {t('buttons.btn_current')}
              </AppButton>
            ),
          },
        ]}
        headerRow={
          <HeaderRowWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flex: 1,
                padding: 0,
                gap: 8,
              }}
            >
              <HeaderWrapper background={'#8989FF'}>
                <div>{`${t('Timesheet.labels.on_site_total')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcHoursMinDuration(totals?.onSiteDuration)}
                </span>
              </HeaderWrapper>
              <HeaderWrapper background={'#9AAEC8'}>
                <div>{`${t('Timesheet.labels.break_total')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcHoursMinDuration(totals?.breakDuration)}
                </span>
              </HeaderWrapper>
              <HeaderWrapper background={'#A5CD69'}>
                <div>{`${t('Timesheet.labels.total_paid_hours')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcHoursMinDuration(totals?.paidDuration)}
                </span>
              </HeaderWrapper>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                padding: 0,
                gap: 8,
              }}
            >
              <HeaderWrapper background={'#8EC044'}>
                <div>{`${t('Timesheet.labels.paid_regular_hours')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcHoursMinDuration(totals?.regularDuration)}
                </span>
              </HeaderWrapper>
              <HeaderWrapper background={'#FF9153'}>
                <div>{`${t('Timesheet.labels.paid_overtime_hours')}: `}</div>
                <span style={{ fontWeight: 600 }}>
                  {calcHoursMinDuration(totals?.overtimeDuration)}
                </span>
              </HeaderWrapper>
            </div>
          </HeaderRowWrapper>
        }
      />

      <AppModalNotes
        onClose={() => {
          setOpenNotes(false);
        }}
        open={openNotes}
        position={document.getElementById(`noteEmploye${indexNotes}`)}
      >
        <ModalNotes style={{ width: 448 }}>
          {notes && notes.length > 0
            ? notes.map((n, i) => (
                <>
                  {i > 0 ? <div className="line"></div> : ''}
                  <div style={{ padding: 24 }}>
                    <h2 style={{ fontSize: 16, fontWeight: 500 }}>{t('form_labels.shift_notes')}</h2>
                    <p style={{ fontSize: 14, marginBottom: 0 }}>{n.note}</p>
                  </div>
                </>
              ))
            : ''}
        </ModalNotes>
      </AppModalNotes>
    </>
  );
};
