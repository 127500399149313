import * as React from 'react';
import styled from 'styled-components';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import ic_arrow_left from '@assets/icons/ic_arrow_left.svg';
import ic_arrow_right from '@assets/icons/ic_arrow_right.svg';

import ic_arrow_down_enabled from '@assets/icons/ic_arrow_down_enabled.svg';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

export function IcArrow({ direction, status, ...other }) {
  const calcIcon = () => {
    switch (direction) {
      case 'down':
        if (status === 'enabled') return ic_arrow_down_enabled;
        return ic_arrow_down;
      case 'left':
        if (status === 'enabled') return ic_arrow_left_enabled;
        return ic_arrow_left;
      case 'right':
        if (status === 'enabled') return ic_arrow_right_enabled;
        return ic_arrow_right;
      default:
        break;
    }
  };

  return <ArrowWrapper src={calcIcon()} {...other} alt="IcArrowDown"></ArrowWrapper>;
}
