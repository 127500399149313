import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { saveEntityData, getEntityData } from '@services';
import { USERS_API, VALUES_DEFINITIONS_API, ORGANIZATIONS_USERS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { AppDropDownSelect } from '@components';
import { CustomOption, calcAvatar } from 'utils/avatar';
import UserIcon from '@assets/icons/User.svg';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 25px;
  margin: 0.575rem 0px 30px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const AddEmployeeSite = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [groupsList, setGroupsList] = useState([]);
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoadingJobTitles, setIsLoadingJobTitles] = useState(false);
  const [isLoadingEmployees, setisLoadingEmployees] = useState(false);
  const [jobTitleF, setJobTitleF] = useState('');
  const [loading, setLoading] = useState(false);

  //Redux
  //const { userPermissions } = useSelector((state) => state.app);

  /***
   * Set group option list
   * Get job titles list
   */
  useEffect(() => {
    setGroupsList([data?.groupsSite[0]?.group]);

    const getJobTitles = () => {
      setIsLoadingJobTitles(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'description', order: 'ASC' })
        .select(['definition_type_id', 'description'])
        .setFilter({
          field: 'definitionType.definition_type',
          operator: CondOperator.EQUALS,
          value: 'JOBS_TYPES',
        });
      getEntityData(VALUES_DEFINITIONS_API, qb.query())
        .then((res) => {
          setJobTitlesList(res);
          setIsLoadingJobTitles(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setIsLoadingJobTitles(false);
        });
    };

    getJobTitles();
  }, [data]);

  /***
   * Get employees by site - group - job title
   * depending on form selections
   */
  useEffect(() => {
    setEmployeeList([]);
    if (jobTitleF) {
      setisLoadingEmployees(true);

      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setJoin({ field: 'user_groups' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.status' })
        .setJoin({ field: 'organizations_users' })
        .setJoin({ field: 'organizations_users.organization_user_sites' })
        .setJoin({ field: 'organizations_users.organization_user_sites.site' })
        .setJoin({ field: 'status' })
        .setFilter({
          field: 'jobs_titles.job_title.value_definition_id',
          operator: CondOperator.EQUALS,
          value: jobTitleF.value?.value_definition_id,
        })
        .setFilter({
          field: 'status.value_definition',
          operator: CondOperator.EQUALS,
          value: '010',
        })
        .setFilter({
          field: 'jobTitleStatus.value_definition',
          operator: CondOperator.EQUALS,
          value: '020', //Active JOB TITLE
        })
        .setFilter({
          field: 'user_groups.group.id',
          operator: CondOperator.EQUALS,
          value: groupsList[0].id,
        });

      getEntityData(USERS_API, qb.query())
        .then((res) => {
          /***
           * Exclude employees who are already assigned to the selected site
           ***/
          const test = res
            .map((user) => {
              let exclude = false;
              user.organizations_users.filter((ou) =>
                ou.organization_user_sites.filter((ous) => {
                  if (ous.site.id === data.id) {
                    exclude = true;
                  }
                  return ous;
                }),
              );
              return { ...user, exclude: exclude };
            })
            .filter((user) => !user.exclude);

          setEmployeeList(test);
          setisLoadingEmployees(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setisLoadingEmployees(false);
        });
    }
  }, [jobTitleF, data, groupsList]);

  /***
   * Call api to assign an empoyee to a site
   */
  const assignEmployeeToSite = (values) => {
    //Hide message
    setLoading(true);

    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Fill the obj
    let request = {};
    request.user_id = values.employee.value;
    request.organization_id = data.organization_id;
    request.sites = [{ ...data }];
    //Call api
    saveEntityData(`${ORGANIZATIONS_USERS_API}add/site-employee`, request)
      .then((res) => {
        //Show success message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.employee_assigned')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        setShowMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_employee_assigning')}`,
          message: '',
        });
        setLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          setShowSucessModal((prev) => {
            return { ...prev, show: false };
          });
        }, 2000);
      });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={object().shape({
        group_id: yup.object().required(`${t('validation.group_required')}`),
        job_title: yup.object().nullable().required(`${t('validation.job_title_required')}`),
        employee: yup.object().nullable().required(`${t('validation.employee_required')}`),
      })}
      onSubmit={assignEmployeeToSite}
      initialValues={{
        group_id: data
          ? { label: data.groupsSite[0].group.name, value: data.groupsSite[0].group }
          : '',
        job_title: '',
        employee: '',
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
        <div style={{ width: '600px', fontSize: 14 }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px', fontSize: 14 }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.group')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={groupsList.map((option) => ({ label: option.name, value: option }))}
                  noOptionsMessage={() => t('no_options') }
                  isDisabled
                  setLeftPadding
                  name="group_id"
                  placeholder={t('required')}
                  onChange={(selected) => {
                    if (selected === null || selected === 'null') {
                      selected = '';
                    }
                    setFieldValue('group_id', selected);
                  }}
                  invalid={touched.group_id && errors.group_id}
                  defaultValue={values.group_id}
                />
                {touched.group_id && errors.group_id && (
                  <ErrorText className="px-2 mb-0">{errors.group_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.job_title')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={jobTitlesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  setLeftPadding
                  height="150px"
                  name="job_title"
                  placeholder={t('required')}
                  isLoading={isLoadingJobTitles}
                  value={values.job_title}
                  onChange={(selected) => {
                    setFieldValue('job_title', selected);
                    setJobTitleF(selected);
                    setFieldValue('employee', null);
                  }}
                  invalid={touched.job_title && errors.job_title}
                  defaultValue={values.job_title}
                />
                {touched.job_title && errors.job_title && (
                  <ErrorText className="px-2 mb-0">{errors.job_title}</ErrorText>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.employee')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={employeeList.map((option) => ({
                    label: option.name,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  name="employee"
                  placeholder={t('required')}
                  icon={UserIcon}
                  value={values.employee}
                  height={150}
                  onChange={(selected) => {
                    setFieldValue('employee', selected);
                    // if (selected) setUser(selected.value);
                  }}
                  customOption={CustomOption}
                  avatar={
                    values.employee && values.employee.value
                      ? calcAvatar(values.employee.value)
                      : null
                  }
                  isLoading={isLoadingEmployees}
                  invalid={touched.employee && errors.employee}
                  defaultValue={values.employee}
                />
                {touched.employee && errors.employee && (
                  <ErrorText className="px-2 mb-0">{errors.employee}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons style={{ marginTop: 30 }}>
              <AppButton
                size="lg"
                type="submit"
                title={t('buttons.btn_assign')}
                loading={loading}
                style={{
                  width: 'auto',
                  fontSize: '14px',
                }}
                color={PALETTE_COLOR.MAIN_WHITE}
                bgcolor={PALETTE_COLOR.MAIN_GREEN}
                bordercolor={PALETTE_COLOR.MAIN_GREEN}
                bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              >
                {t('buttons.btn_assign')}
              </AppButton>
              <div style={{ margin: '0.0rem 20px 0px' }}>
                <AppButton
                  size="lg"
                  style={{
                    width: 'auto',
                    fontSize: '14px',
                  }}
                  title={t('buttons.btn_cancel')}
                  loading={loading}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </div>
              {showMessage.show && (
                <CustomizedSnackbar
                  show={showMessage.show}
                  type={showMessage.type}
                  title={showMessage.title}
                  message={showMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
