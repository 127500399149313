import React from 'react';
import AsyncSelect from 'react-select/async';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import ic_location from '@assets/icons/ic_strong_location.svg';
import { components } from 'react-select';

import { useTranslation } from 'react-i18next';

const buildStyles = (height, setLeftPadding) => {
  return {
    control: (styles, state) => {
      const isInvalid = state.selectProps.invalid;
      return {
        ...styles,
        backgroundColor: `${
          state.isFocused || state.hasValue ? PALETTE_COLOR.WHITE : PALETTE_COLOR.FIELD_COLOR
        }`,
        borderRadius: 16,
        border: isInvalid
          ? `1px solid ${PALETTE_COLOR.FIELD_BORDER_ERROR}`
          : state.isFocused
          ? `1px solid ${PALETTE_COLOR.MAIN_BLUE}`
          : state.hasValue
          ? `1px solid ${PALETTE_COLOR.FIELD_BORDER_COLOR}`
          : 'none',
        paddingLeft: setLeftPadding ? 16 : 'unset',
      };
    },
    placeholder: (styles, state) => {
      return {
        ...styles,
        color: PALETTE_COLOR.INACTIVE_GREY_BLUE,
      };
    },
    indicatorSeparator: (styles, state) => {
      const isDisabled = state.selectProps.isDisabled;
      return {
        ...styles,
        backgroundColor: isDisabled
          ? `${PALETTE_COLOR.INACTIVE_GREY_BLUE}`
          : `${PALETTE_COLOR.MAIN_BLUE}`,
      };
    },
    singleValue: (styles, state) => {
      const isDisabled = state.selectProps.isDisabled;
      return {
        ...styles,
        color: isDisabled ? PALETTE_COLOR.INACTIVE_GREY_BLUE : PALETTE_COLOR.BLACK,
      };
    },
    menu: (styles, state) => {
      return {
        ...styles,
        background: PALETTE_COLOR.MAIN_WHITE,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
        borderRadius: 16,
        marginTop: 0,
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        fontWeight: isSelected ? 'bold' : 'normal',
        ':active': {
          ...styles[':active'],
          backgroundColor: PALETTE_COLOR.MAIN_BLUE,
          color: PALETTE_COLOR.MAIN_WHITE,
        },
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      borderRadius: 16,
      maxHeight: height,
      /* Styles for built-in scrollbar for webkit browsers */

      '::-webkit-scrollbar': {
        width: '0.4rem',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0)', // transparent
        borderRadius: '1rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: PALETTE_COLOR.FIELD_BORDER_COLOR,
        borderRadius: '1rem',
        boxShadow: 'inset 0 0 0.6rem rgba(0, 0, 0, 0.2)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: PALETTE_COLOR.FIELD_BORDER_COLOR,
      },
    }),
  };
};

const { Option } = components;

const getHighlightedText = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part) => (part.toLowerCase() === highlight.toLowerCase() ? <b>{part}</b> : part))}
    </span>
  );
};

const IconOption = (props) => {
  return (
    <Option {...props}>
      <img
        src={ic_location}
        // style={{ width: 14 }}
        alt={props.data.label}
      />
      {getHighlightedText(props.data.label, props.data.find)}
    </Option>
  );
};

export const AppAsyncLocationDropdown = (props) => {
  const { t } = useTranslation();
  return (
    <AsyncSelect
      styles={buildStyles(350, props.setLeftPadding)}
      components={{ Option: IconOption }}
      {...props}
      noOptionsMessage={() => <div>{t('start_typing') !== 'start_typing' ? t('start_typing') : `Start typing...`}</div>}
      loadingMessage={() => props.loadingMessage || t('loading')}
    />
  );
};
