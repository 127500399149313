import * as React from 'react';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const MAIN_WHITE = PALETTE_COLOR.MAIN_WHITE;

const IconButtonWrapper = styled(IconButton)`
  width: 40px;
  height: 40px;
  margin-right: 20px !important;
`;

const IconWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `2px solid ${MAIN_WHITE}`,
    padding: '0 4px',
    background: 'red',
  },
}));

export function AppIconButton({ icon, title, onClick, badgeContent, iconWrapperStyle, ...other }) {
  return (
    <div>
      <Tooltip title={title} placement="bottom">
        <IconButtonWrapper
          style={{ width: 40, height: 40, border: '1px solid #E7ECF4' }}
          aria-label={title}
          onClick={onClick}
        >
          <StyledBadge badgeContent={badgeContent} color="success">
            <IconWrapper src={icon} style={iconWrapperStyle} alt="Cap logo" />
          </StyledBadge>
        </IconButtonWrapper>
      </Tooltip>
    </div>
  );
}
