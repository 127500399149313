import React, { useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Col, Card, Stack, Image, Row } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '@assets/images/logo.svg';
import Banner from '@assets/images/banner.png';
import { Redirect, useLocation } from 'react-router-dom';
import { LoginForm } from './LoginForm';
import { ChangePassForm } from './ChangePassForm';
import { AppModal } from '@components/AppModal';
import { useCognitoAuth } from './LoginUtils';
import { ROOT } from 'navigation/CONSTANTS';
import { ResetPassForm } from './ResetPassForm';
import { ResetPassSuccess } from './ResetPassSuccess';
import packageInfo from '../../../package.json';

import appConfig from '@config/appConfig';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #e0f4ff;
`;

const AppTitle = styled.h2`
  font-size: 30px;
  line-height: 35.22px;
  text-align: center;
  color: ${PALETTE_COLOR.MAIN_BLUE_DARK};
  margin-bottom: unset;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

const StyledCard = styled(Card)`
  min-width: 35rem;
  min-height: 25rem;
  height: 100%;
  border-radius: 32px;
  border: none;
  @media (max-width: 1399.98px) {
    margin-top: ${({ body }) => (body ? '80px' : 'unset')};
  }
`;

const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

const ModalText = styled.p`
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
`;

const ModalEmail = styled.a`
  color: ${PALETTE_COLOR.MAIN_BLUE};
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  :hover {
    color: ${PALETTE_COLOR.MAIN_BLUE};
  }
`;

const ModalContentWrapper = styled.div`
  padding: 20px 20px 10px 30px;
`;

const P = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  padding-right: 20px;
`;

const Link = styled.a`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  text-decoration: none;
  :hover,
  :focus {
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

function redirectToHome(from) {
  return <Redirect to={from} />;
}

export default function Login() {
  const { t } = useTranslation();
  let location = useLocation();
  const [cognitoUser, error, loading] = useCognitoAuth();
  if (error) console.log(error);
  const [loginInfo, setloginInfo] = useState({
    isFirstLogin: false,
    resetPass: { isResetPass: false, success: false },
    user: {},
    userAttributes: {},
  });
  const [openSignUp, setOpenSignUp] = useState(false);
  const { isFirstLogin } = loginInfo;
  const { resetPass } = loginInfo;
  const { isResetPass, success } = resetPass;

  const handleClickOpenSignUpModal = () => {
    setOpenSignUp(true);
  };

  const handleCloseSignUpModal = () => {
    setOpenSignUp(false);
  };

  // let { from } = { from: { pathname: ROOT } }; // Always redirect to dashboard when user is logged
  let { from } = location.state || { from: { pathname: ROOT } };
  if (loading) {
    return t('loading');
  }
  if (cognitoUser) {
    return redirectToHome(from);
  }

  return (
    <>
      <Wrapper style={{ overflowY: isResetPass ? 'hiddenx' : 'unset' }}>
        <label
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'flex-end',
            fontSize: '6px',
          }}
        >{`v. ${packageInfo.version}`}</label>
        <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
          <Stack
            direction="horizontal"
            gap={0}
            style={{
              alignSelf: 'center',
            }}
          >
            <div>
              <StyledCard body={isResetPass}>
                <Row style={{ padding: '40px' }}>
                  <Col>
                    <div style={{ maxHeight: '25rem' }}>
                      <Image src={Banner} alt="banner" width="100%" height="100%" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-center">
                      <Logo src={logo} alt="logo" />
                    </div>
                    <div>
                      <AppTitle>{t('appName')}</AppTitle>
                    </div>

                    {/* TODO */}
                    {isFirstLogin ? (
                      <ChangePassForm loginInfo={loginInfo} setloginInfo={loginInfo} />
                    ) : isResetPass ? (
                      <ResetPassForm loginInfo={loginInfo} setloginInfo={setloginInfo} />
                    ) : success ? (
                      <ResetPassSuccess setloginInfo={setloginInfo} />
                    ) : (
                      <LoginForm setloginInfo={setloginInfo} onOpen={handleClickOpenSignUpModal} />
                    )}
                  </Col>
                </Row>
              </StyledCard>
              {!isFirstLogin && (
                <label
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingTop: '20px',
                  }}
                >
                  <P>
                    <Link href="/privacy" target="_blank">
                      {t('Login.change_password.privacy_policy')}
                    </Link>
                  </P>
                </label>
              )}
            </div>
          </Stack>
        </div>
      </Wrapper>

      <AppModal
        open={openSignUp}
        handleclose={handleCloseSignUpModal}
        title={t('Login.signup.title')}
      >
        <ModalContentWrapper>
          <ModalTitle>{t('Login.login.where')}</ModalTitle>
          <ModalText>{t('Login.signup.description')}</ModalText>
          <ModalText>
            {t('Login.signup.description_2')}
            <ModalEmail href={`mailto:${appConfig.CONTACT_EMAIL}`}>
              {' '}
              {appConfig.CONTACT_EMAIL}
            </ModalEmail>
          </ModalText>
        </ModalContentWrapper>
      </AppModal>
    </>
  );
}
