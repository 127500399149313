import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, AppDropDownSelect, AppCheckBoxList } from '@components';
import { getEntityData, saveEntityData } from '@services';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { ORGANIZATIONS_API, ORGANIZATIONS_USERS_API } from 'services/CONSTANTS';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 00px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleItalicLabel = styled.div`
  padding-top: 10px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Poppins';
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
  margin-bottom: 30px;
`;

const requestAssignOrganization = {
  user_id: {},
  organization_id: {},
  sites: [],
};

export const AssignOrganizationForm = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const assignOrgEmpSchema = Yup.object().shape({
    organization: Yup.object().nullable().required(`${t('validation.organization_required')}`),
    permittedSites: Yup.array().min(1, `${t('validation.permitted_sites_required')}`),
  });
  //To handle message
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  //To store org loaded from api
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationDataList, setOrganizationDataList] = useState([]);
  //To know if org are loaded
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);
  const [loading, setLoading] = useState(false);

  /***
   *
   */
  const getDifference = (array1, array2) => {
    return array1.filter((item1) => {
      return !array2.some((item2) => {
        return item1.id === item2.id;
      });
    });
  };

  //Get all organizations
  useEffect(() => {
    //Query builder to get sites with its groups
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'name', order: 'ASC' })
      .setJoin({ field: 'organizations_sites' })
      .setJoin({ field: 'organizations_sites.status' })
      .setJoin({ field: 'organizations_sites.groupsSite', select: ['group'] })
      .setJoin({ field: 'organizations_sites.groupsSite.group' })
      .setFilter({
        field: 'organizationsGroupsSites.id',
        operator: CondOperator.IN,
        value: data?.user_groups.map((userGroup) => userGroup.group.id),
      });
    setIsLoadingOrg(true);
    getEntityData(`${ORGANIZATIONS_API}`, qb.query())
      .then((res) => {
        setOrganizationDataList(res);
        setOrganizationList(getDifference(res, data.organizations));
        setIsLoadingOrg(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setIsLoadingOrg(false);
      });
    return () => {
      console.log('axios cleanup.');
    };
  }, [data.organizations, data?.user_groups]);

  /***
   * Filter sites which id group is same as user group
   */
  const getSitesBelogSameUserGrop = (orgObj) => {
    if (data?.user_groups.length > 0) {
      const userGroups = data?.user_groups.map((userGroup) => userGroup.group.id);

      let sitesArray = orgObj?.organizations_sites.map((orgSite) => orgSite);
      sitesArray = sitesArray.filter(
        (site) => getEquals(site.groupsSite, userGroups).length > 0 && site,
      );
      console.log('sitesArray: ', sitesArray);
      return sitesArray;
    } else {
      return [];
    }
  };

  const getEquals = (array1, array2) => {
    const result = array1.filter((item1) => {
      return array2.some((item2) => {
        return item1.group.id === item2;
      });
    });
    return result;
  };

  /***
   * Call api to assign a organization to an employee
   */
  const assignOrganizationToEmployee = (values) => {
    //Hide message
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Fill the obj
    requestAssignOrganization.user_id = data;
    requestAssignOrganization.organization_id = values.organization.value;
    requestAssignOrganization.sites = values.permittedSites.map((site) => site.value);
    //Call api
    saveEntityData(`${ORGANIZATIONS_USERS_API}`, requestAssignOrganization)
      .then((res) => {
        //Show success message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.organization_assigned')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        setShowMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_organization_assign')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={assignOrgEmpSchema}
      onSubmit={assignOrganizationToEmployee}
      initialValues={{
        organization: '',
        permittedSites: [],
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <div style={{ width: '590px', maxHeight: values.organization ? '550px' : 'auto', overflow: 'hidden' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
            <Row className="mb-3">
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.organization')}></LabelForm>
              </Col>
              <Col xs={8}>
                <AppDropDownSelect
                  options={organizationList.map((option) => ({
                    label: option.name,
                    value: option,
                  }))}
                  isClearable={false}
                  height="8rem"
                  noOptionsMessage={() =>
                    organizationDataList.length > 0 && organizationList.length === 0
                      ? t('all_organizations_already')
                      : t('no_options')
                  }
                  isSearchable={true}
                  name="organization"
                  placeholder={t('required')}
                  isLoading={isLoadingOrg}
                  onChange={(selected) => {
                    setFieldValue('permittedSites', []);
                    values.permittedSites = [];
                    setFieldValue('organization', selected);
                    console.log('permittedSites: ', values.permittedSites);
                    setFieldValue(
                      'permittedSites',
                      getSitesBelogSameUserGrop(selected.value).map((option) => ({
                        label: option.name,
                        subtitle: option.address,
                        id: option.id,
                        value: option,
                      })),
                    );
                  }}
                  invalid={touched.organization && errors.organization}
                  defaultValue={values.organization}
                />
                {touched.organization && errors.organization && (
                  <ErrorText className="px-2 mb-0">{errors.organization}</ErrorText>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.permitted_sites')}></LabelForm>
              </Col>
              <Col xs={8} style={{ overflowY: 'auto',  maxHeight: values.organization ? '370px' : 'auto', paddingBottom: '10px' }}>
                {values.organization ? (
                  <AppCheckBoxList
                    options={organizationList
                      .find((org) => org.id === values.organization.value.id)
                      .organizations_sites.filter((os) => os.status.value_definition !== '030') //HIDE ARCHIVE SITES
                      .map((option) => ({
                        label: option.name,
                        subtitle: option.address,
                        id: option.id,
                        value: option,
                      }))}
                    name="permittedSites"
                    onClick={(selectedValues) => {
                      console.log('selectedValues: ', selectedValues);
                      setFieldValue('permittedSites', selectedValues);
                    }}
                    invalid={touched.permittedSites && errors.permittedSites}
                    defaultValue={values.permittedSites.map((option) => ({
                      label: option.name,
                      subtitle: option.address,
                      id: option.id,
                      value: option,
                    }))}
                  />
                ) : (
                  <StyleItalicLabel>
                    {t('select_organization')}
                  </StyleItalicLabel>
                )}
                {touched.permittedSites && errors.permittedSites && (
                  <ErrorText className="px-2 mb-0">{errors.permittedSites}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_confirm')}
                >
                  {t('buttons.btn_confirm')}
                </AppButton>
                <AppButton
                  loading={loading}
                  size="lg"
                  style={{ width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
            </StyleButtons>
          </Form>
          {showMessage.show && (
            <CustomizedSnackbar
              show={showMessage.show}
              type={showMessage.type}
              title={showMessage.title}
              message={showMessage.message}
            />
          )}
        </div>
      )}
    </Formik>
  );
};
