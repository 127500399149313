import React from 'react';
import styled from 'styled-components';
import { AppButton } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleSpaceLabel = styled.div`
  display: flex;
  justify-content: center;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 10px 10px 10px 10px;
  text-align: center;
`;

const StyleSpaceName = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
`;
const ModalText = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 5px 5px 5px;
  text-align: center;
`;

const InputIcon = styled.img``;

const ModalContentWrapper = styled.div`
  padding: ${(props) => (props.mainPadding ? props.mainPadding : '5px 75px 34px 75px')};
  width: ${(props) => (props.width ? props.width : 'none')};
`;

export const AppModalStatus = ({
  actionIcon,
  title,
  subtitle,
  subtitleColor = '#000000',
  subtitleWeight = 300,
  subject,
  subjectColor,
  subjectWeight = 500,
  bgcolor,
  bordercolor,
  borderhovercolor,
  bghovercolor,
  boxShadow,
  buttonText,
  buttonAction,
  isLoading = false,
  mainPadding,
  width,
  errorMessage = '',
  buttonWidth = 'auto',
}) => {
  return (
    <ModalContentWrapper mainPadding={mainPadding} width={width}>
      <StyleSpaceIcon>
        <InputIcon src={actionIcon} />
      </StyleSpaceIcon>
      <StyleSpaceLabel className="pb-0">{title}</StyleSpaceLabel>
      <StyleSpaceName
        style={{ color: subjectColor ? subjectColor : bgcolor, fontWeight: subjectWeight }}
      >
        {subject}
      </StyleSpaceName>
      <ModalText style={{ color: subtitleColor, fontWeight: subtitleWeight }}>{subtitle}</ModalText>
      <ModalText
        style={{
          color: PALETTE_COLOR.ACCENT_RED,
          fontWeight: subtitleWeight,
          minWidth: 400,
          fontSize: 13,
        }}
      >
        {errorMessage}
      </ModalText>

      <StyleSpaceButtons className="mt-3">
        <AppButton
          style={{ width: buttonWidth, height: 64, boxShadow }}
          type="submit"
          loading={isLoading}
          bgcolor={bgcolor}
          bordercolor={bordercolor}
          borderhovercolor={borderhovercolor}
          bghovercolor={bghovercolor}
          onClick={buttonAction}
        >
          {buttonText}
        </AppButton>
      </StyleSpaceButtons>
    </ModalContentWrapper>
  );
};
