import React from 'react';
import { Row, Col, Card, Stack, Image } from 'react-bootstrap';
import styled from 'styled-components';
import Banner from '@assets/images/banner.png';
import logo from '@assets/images/logo.svg';
import { useLocation, useHistory } from 'react-router-dom';
import { AppButton } from '@components';
import packageInfo from '../../../package.json';
import { useSelector } from 'react-redux';
import { USERS_API } from 'services/CONSTANTS';
import { editEntityData, saveEntityFormData } from '@services';
import { useDispatch } from 'react-redux';
import { setAppLoadUserAction, setProfilePhotoToSaveAction } from '@redux/actions/appActions';
import { useAuth } from '@navigation/Auth/ProvideAuth';
import { EditProfileImage } from '@components';

import { useTranslation } from 'react-i18next';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #e0f4ff;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

const StyledCard = styled(Card)`
  min-width: 35rem;
  min-height: 25rem;
  border-radius: 32px;
  border: none;
  @media (max-width: 1399.98px) {
    margin-top: ${({ body }) => (body ? '80px' : 'unset')};
  }
`;

const H2 = styled.h2`
  font-size: 28px;
  line-height: 42px;
  font-family: Poppins;
  font-weight: 600;
  color: #000000;
`;
const P = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const ImageRequiredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ff5555;
  margin-bottom: 10px;
`;

export default function ChooseAvatarForm() {
  const { t } = useTranslation();
  let location = useLocation();
  let auth = useAuth();
  let history = useHistory();
  const { userData, profileImageToSave } = useSelector((state) => state.app);
  const [loading, setLoading] = React.useState(false);
  const [elementRequired, setElementRequired] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProfilePhotoToSaveAction({ isAvatar: false, config: null }));
  }, [dispatch]);

  /***
   * Save profile photo
   */
  const editUser = () => {
    if (profileImageToSave && profileImageToSave.config === null) {
      setElementRequired(`${t('validation.avatar_photo')}`);
      setTimeout(() => {
        setElementRequired('');
      }, 3000);
    } else {
      setLoading(true);
      let { from } = location.state || { from: { pathname: '/' } };
      let profilePhoto;
      if (profileImageToSave.isAvatar) {
        //Build request
        profilePhoto = JSON.stringify({
          avatarId: profileImageToSave.config.avatarId,
          gender: profileImageToSave.config.gender,
          skinColor: profileImageToSave.config.skinColor,
          bgColor: profileImageToSave.config.bgColor,
        });
      } else {
        profilePhoto = profileImageToSave.config;
      }
      //Update avatar
      if (profileImageToSave.isAvatar) {
        editEntityData(`${USERS_API}${userData.id}`, {
          avatar_config: profilePhoto,
          photo_url: null,
        })
          .then((res) => {
            //UPDATE LOCAL DATA
            dispatch(setAppLoadUserAction({ ...userData, avatar_config: profilePhoto }));
            setLoading(false);
            auth.signin(false).then((res) => {
              history.replace(from);
            });
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        //Cast blob to file
        let file = new File([profilePhoto.blob], `wecare_profile_photo_${Date.now()}.png`, {
          lastModified: Date.now(),
          type: 'image/png',
        });
        let formData = new FormData();
        formData.append('image', file);
        //Call API
        saveEntityFormData(`${USERS_API}upload/photo`, formData)
          .then((res) => {
            //UPDATE LOCAL DATA
            dispatch(
              setAppLoadUserAction({
                ...userData,
                avatar_config: null,
                photo_url: profilePhoto.url,
              }),
            );
            setLoading(false);
            auth.signin(false).then((res) => {
              history.replace(from);
            });
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Wrapper style={{ overflowY: 1 ? 'auto' : 'unset' }}>
        <label
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'flex-end',
            fontSize: '6px',
          }}
        >{`v. ${packageInfo.version}`}</label>
        <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
          <Stack
            direction="horizontal"
            gap={0}
            style={{
              alignSelf: 'center',
            }}
          >
            <div>
              <StyledCard body={1}>
                <Row>
                  <Col>
                    <div style={{ maxHeight: '25rem' }}>
                      <Image src={Banner} alt="banner" width="100%" height="100%" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-center">
                      <Logo src={logo} alt="logo" />
                    </div>

                    <P className="text-center mb-2">{t('Login.choose_avatar.title')}</P>
                    <H2 className="text-center">{t('Login.choose_avatar.subtitle')}</H2>
                    <EditProfileImage
                      handleclose={() => {}}
                      setShowSucessModal={null}
                      onAvatarChanged={(e) => {}}
                      container={0}
                    />

                    <ImageRequiredText>{elementRequired}</ImageRequiredText>

                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <AppButton
                        style={{ width: 'auto', height: 64 }}
                        type="submit"
                        onClick={editUser}
                        loading={loading}
                        bgcolor={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.MAIN_BLUE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
                        borderhovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
                      >
                        {t('buttons.btn_lets_go')}
                      </AppButton>
                    </div>
                  </Col>
                </Row>
              </StyledCard>
            </div>
          </Stack>
        </div>
      </Wrapper>
    </>
  );
}
