import React /*, { useEffect } */ from 'react';
import styled from 'styled-components';
import { getEntityData, saveEntityData } from 'services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import { USER_SETTINGS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppCheckBox, AppComponentLoading, AppButton } from '@components';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@redux/actions/appActions';
//import { setNewChatMessageAction, setLoadingChatAction } from '@redux/actions/appActions';
//import Avatar from '@assets/icons/ic_no_avatar.svg';
import Down from '@assets/icons/Down.svg';
import { AppDropDownSingleSelect } from '@components';
import { Form } from 'react-bootstrap';
import { ShortTimeZone, getTimeZone /*, ShiftFullTimeDifferences*/ } from 'utils/appUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

const MainWrapper = styled.div`
  background: #ffffff;
  height: 88%;
  position: absolute;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TzHeaderLabel = styled.div`
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;

const TzHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

const TzHeaderValue = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

const WrapperFilter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: space-between;
`;

const StyleFilter = styled.div`
  background: #ffffff;
  border: 1px solid #e7ecf4;
  border-radius: 16px;
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  width: 500px;
  padding: 6px 10px;
`;

const TittleFilter = styled.div`
  margin-right: 15px;
  width: max-content;
`;

const FilterIcon = styled.img`
  margin-right: 11px;
  text-align: right;
  width: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Subtitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #6d737b;
`;

const Separator = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #edf1f8;
`;

const StyleButtons = styled.div`
  padding-bottom: 20px;
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
`;

const initialValuesC = {
  timeZone: {
    label: 'America/Bogota',
    value: {
      value_definition: '060',
      description: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
      active: true,
      validation_type: 'America/Bogota',
    },
  },
};

export const General = () => {
  const { t } = useTranslation();
  const generalSchema = Yup.object().shape({});
  //Get data from redux
  const { valuesDefinitions, userData } = useSelector((state) => state.app);
  const timeZones = React.useMemo(
    () =>
      valuesDefinitions
        ? valuesDefinitions
            .filter((v) => v.definitionType.definition_type === 'TIME_ZONES')
            .sort((a, b) => a.value_definition - b.value_definition)
        : [],
    [valuesDefinitions],
  );
  const localTz = timeZones.find((tz) => tz.description.includes(getTimeZone()));
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSavingData, setIsSavingData] = React.useState(false);
  const [selectedTz, setSelectedTz] = React.useState(
    timeZones.filter((tz) => tz.value_definition === '060')[0],
  );

  const [initialValues, setInitialValues] = React.useState(initialValuesC);
  const [apiData, setApiData] = React.useState();

  const dispatch = useDispatch();

  //Snackbar
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Look for user settings
   */
  React.useEffect(() => {
    setIsLoading(true);
    const qb = RequestQueryBuilder.create()
      .setJoin({
        field: 'user',
      })
      .setFilter({
        field: 'user.id',
        operator: CondOperator.EQUALS,
        value: userData.id,
      })
      .setFilter({
        field: 'code',
        operator: CondOperator.EQUALS,
        value: '010',
      });
    //Call API
    getEntityData(USER_SETTINGS_API, qb.query())
      .then((res) => {
        //User with timezone setting already saved
        if (res && res.length > 0) {
          const userTimeZone = timeZones.filter((tz) => tz.value_definition === res[0]?.detail)[0];
          setInitialValues({
            timeZone: userTimeZone
              ? {
                  label: userTimeZone?.timeZone?.description,
                  value: userTimeZone,
                  active:
                    userTimeZone?.timeZone?.value_definition ===
                    initialValuesC.timeZone.value.value_definition,
                }
              : initialValuesC.timeZone,
          });
          setApiData(res[0]);
          setSelectedTz(userTimeZone);
        } else {
          setInitialValues({
            timeZone: initialValuesC.timeZone,
          });
          setApiData(null);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
    return () => {
      setIsLoading(false);
      console.log('axios cleanup.');
    };
  }, [userData, timeZones]);

  /***
   *
   */
  const BuildHeader = (props) => {
    return (
      <>
        <TzHeader
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
          }}
        >
          <AppCheckBox
            checked={props?.value?.value_definition === selectedTz?.value_definition}
            onChange={() => {}}
          />
          <div style={{ display: 'flex', flexDirection: 'column', color: '#000000', textAlign: 'left' }}>
            <TzHeaderLabel>{t('my_local_time')}</TzHeaderLabel>
            <TzHeaderValue>{`${localTz.description}`}</TzHeaderValue>
          </div>
        </TzHeader>
      </>
    );
  };

  /***
   * In charge of save time zone conf for logged user
   */
  const saveGeneralSettings = (values) => {
    console.log('values: ', values);
    setIsSavingData(true);
    let request;
    if (apiData) {
      request = {
        id: apiData.id,
        user: userData.id,
        code: '010',
        name: 'Time Zone',
        detail: values.timeZone.value.value_definition,
      };
    } else {
      request = {
        user: userData.id,
        code: '010',
        name: 'Time Zone',
        detail: values.timeZone.value.value_definition,
      };
    }

    saveEntityData(USER_SETTINGS_API, request)
      .then((res) => {
        setIsSavingData(false);
        //Show message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.time_zone_saved')}`,
          message: ``,
        });
        setApiData(res);
      })
      .catch((err) => {
        setIsSavingData(false);
        //Show message
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_edit_settings')}`,
          message: '',
        });
        console.log('saveGeneralSettings error = ', err);
      });
  };

  return (
    <>
      <MainWrapper style={{ overflowY: 'scroll', width: '74%' }}>
        {isLoading ? (
          <LoadingContainer>
            <AppComponentLoading width={'100px'} height={'50px'} />
          </LoadingContainer>
        ) : (
          <Formik
            enableReinitialize
            validationSchema={generalSchema}
            onSubmit={saveGeneralSettings}
            initialValues={initialValues}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
              <div>
                {isLoading ? (
                  <LoadingContainer>
                    <AppComponentLoading width={'100px'} height={'50px'} />
                  </LoadingContainer>
                ) : (
                  <Form
                    onSubmit={handleSubmit}
                    style={{ padding: '30px 0px 20px 30px', fontSize: '14px' }}
                  >
                    <Row>
                      <Title>{t('SettingsCenter.title_default')}</Title>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <Subtitle>{t('SettingsCenter.subtitle_default')}</Subtitle>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                      <AppDropDownSingleSelect
                        isSearchable={true}
                        allowSelectAll={false}
                        height="16rem"
                        width={'auto'}
                        menuStyle={{ position: 'fixed' }}
                        name="group"
                        placeholder={t('placeholder_search')}
                        BuildHeader={BuildHeader}
                        headerOptions={[
                          {
                            label: ShortTimeZone(localTz),
                            value: localTz,
                          },
                        ]}
                        onChange={(selected) => {
                          setSelectedTz(selected.value);
                          setFieldValue('timeZone', selected);
                        }}
                        value={values.timeZone}
                        options={timeZones.map((option) => ({
                          label: option.description,
                          value: option,
                        }))}
                        targetComponent={
                          <StyleFilter style={{ cursor: 'pointer' }}>
                            <WrapperFilter>
                              <TittleFilter>{ShortTimeZone(values.timeZone?.value)}</TittleFilter>
                              <FilterIcon src={Down}></FilterIcon>
                            </WrapperFilter>
                          </StyleFilter>
                        }
                        defaultValue={values.timeZone}
                      />
                    </Row>
                    <Separator />
                    <StyleButtons>
                      <span>
                        <AppButton
                          style={{
                            width: 'auto',
                            height: 40,
                            marginRight: '20px',
                            display: 'unset',
                            flexDirection: 'unset',
                            fontSize: '14px',
                          }}
                          color={PALETTE_COLOR.MAIN_WHITE}
                          bgcolor={PALETTE_COLOR.MAIN_GREEN}
                          bordercolor={PALETTE_COLOR.MAIN_GREEN}
                          bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                          borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                          size="lg"
                          type="submit"
                          title={t('buttons.btn_save_changes')}
                          loading={isSavingData}
                        >
                          {t('buttons.btn_save_changes')}
                        </AppButton>
                      </span>
                    </StyleButtons>
                  </Form>
                )}
              </div>
            )}
          </Formik>
        )}
      </MainWrapper>
    </>
  );
};
