import React, { useState } from 'react';
import styled from 'styled-components';
import { deleteEntityData } from '@services';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { EMPLOYEES_NOTES_API } from 'services/CONSTANTS';
import { useSelector } from 'react-redux';
import { AppAvatar, AppModal } from '@components';
import logo_edit from '@assets/icons/edit.svg';
import Avatar from '@assets/icons/ic_no_avatar.svg';
import ic_remove from '@assets/icons/ic_remove.svg';
import moment from 'moment';
import { NoteForm } from './NoteForm';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { AppModalStatus } from 'components/AppModalStatus';
import { showMessageAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

const NoteWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #f1f5fd;
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 10px;
`;

const AvatarWrapper = styled.div`
  flex-direction: column;
  flex: 0 0 40px;
  margin-right: 10px;
`;

const ContentWrapper = styled.div`
  flex-direction: column;
  flex: 1;
`;

const AuthorDateText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const SubjectText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

const NoteText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

const ActionsWrapper = styled.div`
  flex-direction: column;
  flex: 0 0 40px;
  margin-left: 10px;
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
`;

const Icon = styled.img``;

const EditIcon = styled.img`
  width: 12.59px;
  height: 16px;
`;

const DeleteIcon = styled.img`
  width: 12.59px;
  height: 16px;
`;

export const NoteCard = ({ data, setReloadEmployeeNotes }) => {
  const { t } = useTranslation();
  //Get creator user avatar
  const avatarConfig = data.author.id
    ? data.author.avatar_config
      ? JSON.parse(data.author.avatar_config)
      : null
    : null;
  const [loading, setLoading] = useState(false);
  //Redux
  const dispatch = useDispatch();
  const { userData, userPermissions } = useSelector((state) => state.app);
  //Controls if edit note modal is shown or not
  const [openEditNoteForm, setOpenEditNoteForm] = React.useState(false);
  //Controls if edit note modal is shown or not
  const [openDeleteNoteForm, setOpenDeleteNoteForm] = React.useState(false);

  //Info to send to component which shows confirmation to delete
  const deleteNoteModalContent = {
    buttonText: `${t('buttons.btn_remove')}`,
    actionIcon: ico_Archived,
    title: `${t('Users.employees.view.modals.delete_note_title')}`,
    subtitle: `${t('Users.employees.view.modals.delete_note_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  //Snackbar
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Show/close modal to edit a note
   */
  const handleEditNoteModal = () => {
    setOpenEditNoteForm(!openEditNoteForm);
  };

  /***
   * Show/close modal to delete a note
   */
  const handleDeleteNoteModal = () => {
    setOpenDeleteNoteForm(!openDeleteNoteForm);
  };

  /***
   * Call api to delete note by id
   */
  const deleteNote = () => {
    setLoading(true);
    //Call api
    deleteEntityData(`${EMPLOYEES_NOTES_API}${data.id}`, {})
      .then((res) => {
        //Close modal
        handleDeleteNoteModal();
        //Show message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.note_deleted')}`,
          message: '',
        });
        setReloadEmployeeNotes(true);
        setLoading(false);
      })
      .catch((err) => {
        //Close modal
        handleDeleteNoteModal();
        //Show message
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_note_deleting')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  return (
    <NoteWrapper>
      {/* Avatar */}
      <AvatarWrapper>
        {avatarConfig ? (
          <AppAvatar
            avatarId={avatarConfig?.avatarId}
            bgColor={avatarConfig?.bgColor}
            gender={avatarConfig?.gender}
            skinColor={avatarConfig?.skinColor}
            width={32}
            height={32}
          />
        ) : data?.author?.photo_url ? (
          <Icon
            src={data.author.photo_url}
            style={{
              width: 32,
              height: 32,
              borderRadius: 60,
              display: 'flex',
              margin: 'auto',
            }}
          />
        ) : (
          <Icon
            src={Avatar}
            style={{
              width: 32,
              height: 32,
              display: 'flex',
              margin: 'auto',
            }}
          />
        )}
      </AvatarWrapper>
      {/* Content */}
      <ContentWrapper>
        <AuthorDateText>{`${moment
          .utc(data.updated_at ? data.updated_at : data.created_at)
          .format('MMMM D, YYYY')} ${t('by_low')} ${data.author.name}`}</AuthorDateText>
        <SubjectText>{data.subject}</SubjectText>
        <NoteText>{data.note}</NoteText>
      </ContentWrapper>
      {/* Actions */}
      <ActionsWrapper>
        {/* Only super admin and note owner can edit and delete note  */}
        <ActionsRow>
          {userPermissions.role === '040' || userData.id === data.author.id ? (
            <>
              <div style={{ cursor: 'pointer' }} onClick={handleEditNoteModal}>
                <EditIcon src={logo_edit} alt="edit icon"></EditIcon>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={handleDeleteNoteModal}>
                <DeleteIcon src={ic_remove} alt="delete icon"></DeleteIcon>
              </div>
            </>
          ) : (
            <></>
          )}
        </ActionsRow>
      </ActionsWrapper>

      {/* Edit note */}
      <AppModal
        open={openEditNoteForm}
        handleclose={handleEditNoteModal}
        title={t('Users.employees.view.note.modal_edit.title')}
        inside={1}
      >
        <NoteForm
          handleclose={handleEditNoteModal}
          setReloadEmployeeNotes={setReloadEmployeeNotes}
          data={userData}
          noteData={data}
        />
      </AppModal>

      {/* Delete modal */}
      <AppModal
        open={openDeleteNoteForm}
        handleclose={handleDeleteNoteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
        width={'600px'}
      >
        <AppModalStatus
          actionIcon={deleteNoteModalContent.actionIcon}
          title={deleteNoteModalContent.title}
          subtitle={deleteNoteModalContent.subtitle}
          bgcolor={deleteNoteModalContent.bgcolor}
          bordercolor={deleteNoteModalContent.bordercolor}
          borderhovercolor={deleteNoteModalContent.borderhovercolor}
          bghovercolor={deleteNoteModalContent.bghovercolor}
          boxShadow={deleteNoteModalContent.boxShadow}
          buttonText={deleteNoteModalContent.buttonText}
          subject={''}
          buttonAction={deleteNote}
          isLoading={loading}
        />
      </AppModal>
    </NoteWrapper>
  );
};
