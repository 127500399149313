import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import * as yup from 'yup';
import { formatEspecial } from '@utils/dateFormatHelper';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import UserIcon from '@assets/icons/User.svg';
import { useDispatch } from 'react-redux';
import { setReloadTimeSheetAction } from '@redux/actions/appActions';
import {
  ShiftFullTimeDifferences,
  calculateTimeHours /*, calcDurationHours*/,
} from 'utils/appUtils';
import EllipseIcon from '@assets/icons/ic_grey_ellipse.svg';
import { calcDot, SingleOption } from 'utils/coloredDot';
import { Row, Col, Form } from 'react-bootstrap';
import { saveEntityData, getEntityData } from '@services';
import { calcAvatar, CustomOption } from 'utils/avatar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import OverlappingShift from '@assets/icons/OverlappingShift.svg';
import PickersDay, { pickersDayClasses } from '@mui/lab/PickersDay';
import { TIME_SHEET_API, USERS_API, USER_SHIFTS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import DPICon from '@assets/icons/ic_datepicker.svg';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import {
  LabelForm,
  AppButton,
  CustomizedSnackbar,
  InputForm,
  AppModal,
  AppDropDownSelect,
} from '@components';
import DatePicker from '@mui/lab/DatePicker';

import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 5px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const DivShiftsConflicts = styled.div`
  padding: 0 32px;
  font-size: 13px;
  max-height: 500px;
  h4 {
    color: ${PALETTE_COLOR.LIGHT_GREY};
    font-weight: 400;
    font-size: 13px;
  }
  .cont {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-bottom: 16px;
  }
  .col1 {
    width: 40%;
    float: left;
    color: ${PALETTE_COLOR.BLACK};
  }
  .col2 {
    width: 60%;
    float: left;
    .reason {
      border-radius: 10px;
      padding: 8px 16px;
      margin-bottom: 16px;
      .gray {
        color: ${PALETTE_COLOR.MIDDLE_GRAY};
      }
    }
    .reasonLabel {
      font-size: 12px;
      color: ${PALETTE_COLOR.ACCENT_RED_DARK};
      background: ${PALETTE_COLOR.ACCENT_RED_BACKGROUND};
      border-radius: 10px;
      width: 145px;
      margin-bottom: 6px;
      img {
        margin-right: 8px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const TimeRangeInput = styled(TextField)`
  border: 1px solid #E7ECF4;
  border-radius: 16px;
  & label.Mui-focused {
    display: none;
  }
  & label {
    display: none;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #E7ECF4;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #E7ECF4;
    }
    &.Mui-focused fieldset {
      border: 1px solid blue;
    }
  }
  &.MuiInputBase-root{
    padding: 0px;
    background-color: green;
    & .MuiButtonBase-root{
      padding: 0,
          padding-left: 10;
          background-color: gold;
    }
    & .MuiInputBase-input{
      padding: 8;
      padding-left: 0;
      background-color: cyan;
    }
  }
`;

const DatePickerInput = styled(TextField)`
border: 1px solid #E7ECF4;
border-radius: 16px;
& label.Mui-focused {
  color: transparent;
 
}
& label {
  
  color: transparent
 
}
& .MuiInput-underline:after {
  border-bottom-color: white;
  
}
& .MuiOutlinedInput-root {
  & fieldset {
    border: 1px solid #E7ECF4;
    border-radius: 16px;
    
  }
  &:hover fieldset {
    border-color: #E7ECF4;
  }
  &.Mui-focused fieldset {
    border: 1px solid blue;
  }
}
&.MuiInputBase-root{
  padding: 0px;
  background-color: green;
  & .MuiButtonBase-root{
    padding: 0,
        padding-left: 10;
        background-color: gold;
  }
  & .MuiInputBase-input{
    padding: 15;
        padding-left: 0;
        background-color: cyan;
  }
}
`;

const HoursDiff = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const AddTimeSheet = ({ handleclose, setShowSucessModal, mode }) => {
  const { t } = useTranslation();
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const dispatch = useDispatch();
  const [startTimeF, setStartTimeF] = useState(moment(new Date().setHours(0, 0, 0, 0)));
  const [startDate, setStartDate] = useState('');
  const [overTime, setOverTime] = useState('');
  const [workedWeek, setWorkedWeek] = useState('');
  const [endTimeF, setEndTimeF] = useState(moment(new Date().setHours(12, 0, 0, 0)));
  const [diffHours, setDiffHours] = useState('-');
  const [regularHours, setRegularHours] = useState({});
  const [breakTime, setBreakTime] = useState('');
  const [siteList, setSiteList] = useState([]);
  const [createShift, setCreateShift] = useState(false);
  const [loadingSites, setLoadingSites] = useState(false);
  const [siteF, setSiteF] = useState('');
  const [shiftsConflicts, setShiftsConflicts] = useState([]);
  const { jobSchedulerGroup, valuesDefinitions } = useSelector((state) => state.app);
  const [initialValues, setInitialValues] = useState(null);
  const [loadingShift, setLoadingShift] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeID, setEmployeeID] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openShiftsConflictsModal, setOpenShiftsConflictsModal] = useState(false);

  //Get values definitions - Week days and invoicing period
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: MAIN_BLUE,
          },
        }}
      />
    );
  };

  const handleClickOpenShiftsConflictsModal = () => {
    setOpenShiftsConflictsModal(true);
  };

  const handleClickCloseShiftsConflictsModal = () => {
    setOpenShiftsConflictsModal(false);
  };

  useEffect(() => {
    if (jobSchedulerGroup) {
      setLoadingSites(true);
      const qb = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userSites/${jobSchedulerGroup.id}`, qb.query())
        .then((res) => {
          setLoadingSites(false);
          const sites = res.map((site) => ({
            label: site.name,
            value: site,
            color: site.organization_id.color,
            organization_id: site.organization_id,
            address: site.address,
            status: site.status,
          }));
          setSiteList(sites.filter((site) => site.status.value_definition === '010')); //Only active sites
          setLoadingSites(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingSites(false);
        });
    }
  }, [jobSchedulerGroup]);

  useEffect(() => {
    setEmployeeList([]);
    setLoadingEmployees(true);
    if (siteF?.value?.id > 0) {
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setJoin({ field: 'user_groups' })
        .setJoin({ field: 'organizations_users' })
        .setJoin({ field: 'organizations_users.organization_user_sites' })
        .setJoin({ field: 'organizations_users.organization_user_sites.site' })
        .setJoin({ field: 'status' })
        .setFilter({
          field: 'status.value_definition',
          operator: CondOperator.EQUALS,
          value: '010',
        })
        .setFilter({
          field: 'organizationUserSitesSite.id',
          operator: CondOperator.EQUALS,
          value: siteF?.value?.id,
        })
        .setFilter({
          field: 'user_groups.group.id',
          operator: CondOperator.EQUALS,
          value: jobSchedulerGroup.id,
        });
      getEntityData(USERS_API, qb.query())
        .then((res) => {
          setEmployeeList(res);
          setLoadingEmployees(false);
        })
        .catch((err) => {
          console.log('GetEmployeesBySiteJobTitle err=', err);
          setLoadingEmployees(false);
        });
    }
  }, [jobSchedulerGroup, siteF]);

  useEffect(() => {
    if (siteF?.value?.id > 0 && employeeID?.id > 0) {
      setShiftList([]);
      setLoadingShift(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'shift' })
        .setJoin({ field: 'shift.site' })
        .setJoin({ field: 'shift.jobs_titles' })
        .setJoin({ field: 'shift.jobs_titles.jobtitleid' })
        .setJoin({ field: 'user' })
        .setFilter({
          field: 'shift.site',
          operator: CondOperator.EQUALS,
          value: siteF?.value?.id,
        })
        .setFilter({
          field: 'user.id',
          operator: CondOperator.EQUALS,
          value: employeeID?.id,
        });
      getEntityData(USER_SHIFTS_API, qb.query())
        .then((res) => {
          setShiftList(res);
          setLoadingShift(false);
        })
        .catch((err) => {
          console.log('GetSHIFTS err=', err);
          setLoadingEmployees(false);
        });
    }
  }, [employeeID, siteF]);

  useEffect(() => {
    let values = {
      start_date: moment(),
      end_date: moment(),
      start_time: '00:00' /*moment(new Date().setHours(0, 0, 0, 0))*/,
      end_time: '12:00' /*moment(new Date().setHours(12, 0, 0, 0))*/,
      shift: '',
      user_id: '',
      site: '',
      break: '',
      jobtitle: '',
      employee: '',
      note: '',
      title: '',
    };
    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startTimeF && endTimeF) {
      try {
        const diff = ShiftFullTimeDifferences({ start_time: startTimeF, end_time: endTimeF });
        isNaN(diff) ? setDiffHours('-') : setDiffHours(`${diff}h`);
      } catch (e) {
        console.log('error: ', e);
        setDiffHours('-');
      }
    } else {
      setDiffHours('-');
    }
  }, [startTimeF, endTimeF]);

  useEffect(() => {
    let res = calculateTimeHours({
      start_time: startTimeF,
      end_time: endTimeF,
      breakTime: breakTime,
    });
    setRegularHours(res);
    let total = workedWeek + res.time_total;
    if (parseInt(total) >= 2400) {
      let over_time = parseInt(workedWeek - res.time_total);
      setOverTime(over_time);
    } else {
      setOverTime(0);
    }
  }, [startTimeF, endTimeF, breakTime, workedWeek]);

  useEffect(() => {
    if (employeeID && startDate) {
      const qb2 = RequestQueryBuilder.create();
      let url = `${TIME_SHEET_API}query/calculateTimesTimeSheetsByEmployee/${employeeID.id}/${moment
        .utc(startDate)
        .toDate()}`;
      getEntityData(url, qb2.query())
        .then((res) => {
          if (res.data) {
            setWorkedWeek(res.data);
          }
        })
        .catch((err) => {
          console.log('error=', err);
        });
    }
  }, [employeeID, startDate]);

  const addOrganization = (values) => {
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setLoading(true);
    let array = {};
    array.userShift = values.user_id;
    array.site = values.site.value;
    array.organization = values.site.organization_id;
    array.job_title = values.jobtitle;
    array.group = jobSchedulerGroup;
    array.createShift = createShift;
    array.userStatus = valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'USER_SHIFT_STATUS' && v.value_definition === '040',
    )[0];
    array.status = valuesDefinitions.filter(
      (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '010',
    )[0]; //Draft
    let formattedFStartTime = null;
    let formattedFEndTime = null;
    let formattedStartDate = null;
    let formattedEndDate = null;
    //New logic for new timepicker component
    const timeRangeStart = values.start_time.split(':');
    const timeRangeEnd = values.end_time.split(':');

    if (new moment(values.start_date).isValid() && new moment(values.end_date).isValid()) {
      const startDate = moment(values.start_date)
        .utcOffset(0)
        .set({
          hour: timeRangeStart[0],
          minute: timeRangeStart[1],
          second: 0,
          dayOfYear: values.start_date.get('dayOfYear'),
          month: values.start_date.get('month'),
          year: values.start_date.get('year'),
        });
      formattedStartDate = startDate.format();
      const endDate = moment(values.end_date)
        .utcOffset(0)
        .set({
          hour: timeRangeEnd[0],
          minute: timeRangeEnd[1],
          second: 0,
          dayOfYear: values.end_date.get('dayOfYear'),
          month: values.end_date.get('month'),
          year: values.end_date.get('year'),
        });
      formattedEndDate = endDate.format();
      formattedFStartTime = startDate
        .set({
          hour: timeRangeStart[0],
          minute: timeRangeStart[1],
          second: 0,
        })
        .format();
      formattedFEndTime = endDate.set({
        hour: timeRangeEnd[0],
        minute: timeRangeEnd[1],
        second: 0,
      });
    } else {
      formattedFStartTime = moment()
        .utcOffset(0)
        .set({
          hour: timeRangeStart[0],
          minute: timeRangeStart[1],
          second: 0,
        })
        .format();
      formattedFEndTime = moment().utcOffset(0).set({
        hour: timeRangeEnd[0],
        minute: timeRangeEnd[1],
        second: 0,
      });
    }
    array.shiftDate = formattedStartDate;
    array.shift_date = formattedStartDate;
    array.clockInDate = formattedFStartTime;
    array.start_time = formattedFStartTime;
    array.clockOutDate = formattedFEndTime;
    array.end_time = formattedFEndTime;
    array.endDate = formattedEndDate;
    array.onSiteDuration = regularHours.time_init;
    array.breakDuration = values.break;
    array.workedDuration = regularHours.time_total;
    array.overtimeDuration = overTime;
    array.regularDuration = regularHours.time_total;
    array.totalOnSiteDuration = regularHours.time_init;
    array.totalBreakDuration = values.break;
    array.totalPaidDuration = regularHours.time_total;
    array.totalRegularDuration = regularHours.time_total;
    array.totalOvertimeDuration = overTime;
    array.employee = values.employee;
    array.title = values.title === '' ? formattedFStartTime + ` ${t('Timesheet.automatic_shift')}` : values.title;
    array.location = values.site.address;
    array.note = values.note;
    saveEntityData(TIME_SHEET_API, array)
      .then((resultado) => {
        if (resultado.conflicts) {
          setShiftsConflicts(resultado.conflicts);
          handleClickOpenShiftsConflictsModal();
        } else {
          setLoading(false);
          setShowSucessModal({
            show: true,
            type: 'success',
            title: `${t('success_messages.timesheet_created')}`,
            message: '',
          });
          reloadSchedulerCalendar(true);
          handleclose();
        }
      })
      .catch((err) => {
        reloadSchedulerCalendar(false);
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.timesheet_exists')}`,
            message: '',
          });
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_timesheet_creating')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadTimeSheetAction(isLoading));
    },
    [dispatch],
  );

  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      {initialValues && (
        <Formik
          enableReinitialize
          validationSchema={object().shape({
            shift:
              createShift === false &&
              yup.object().nullable().required(`${t('validation.shift_required')}`),
            site: yup.object().nullable().required(`${t('validation.site_required')}`),
            employee: yup.object().nullable().required(`${t('validation.employee_required')}`),
            /* start_date: yup.date().nullable().required('The start date field is required'),
            end_date: yup.date().nullable().required('The end date field is required'),
            start_time: yup
              .string()
              .nullable()
              .required('Required')
              .test('not-date', 'Invalid value', function (value) {
                return moment(value).isValid();
              }),
            end_time: yup
              .string()
              .nullable()
              .required('Required')
              .test('not-date', 'Invalid value', function (value) {
                return moment(value).isValid();
              }),*/
          })}
          onSubmit={addOrganization}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <div style={{ width: '600px', fontSize: 14 }}>
              <Form
                onSubmit={handleSubmit}
                className="baseForm"
                style={{ padding: '23px' }}
                novalidate="novalidate"
              >
                <div>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.site')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <AppDropDownSelect
                        options={siteList}
                        noOptionsMessage={() => t('no_options') }
                        isClearable={true}
                        name="site"
                        isDisabled={createShift}
                        placeholder={t('required')}
                        isLoading={loadingSites}
                        customOption={SingleOption}
                        avatar={
                          values.site && values.site.value ? calcDot(values.site.color) : null
                        }
                        icon={EllipseIcon}
                        value={values.site}
                        onChange={(selected) => {
                          setFieldValue('site', selected);
                          setSiteF(selected);
                          setFieldValue('employee', null);
                          setEmployeeID('');
                          setWorkedWeek(0);
                          setBreakTime(0);
                          setFieldValue('shift', null);
                          setFieldValue('user_id', null);
                          setFieldValue('jobtitle', null);
                          setFieldValue('note', `${t('Timesheet.not_shift_note')}`);
                          setFieldValue('start_date', initialValues.start_date);
                          setFieldValue('end_date', initialValues.end_date);
                          setFieldValue('start_time', initialValues.start_time);
                          setStartDate(initialValues.start_time);
                          setFieldValue('end_time', initialValues.end_time);
                          setStartTimeF(initialValues.start_time);
                          setEndTimeF(initialValues.end_time);
                        }}
                        invalid={touched.site && errors.site}
                        defaultValue={values.site}
                      />
                      {touched.site && errors.site && (
                        <ErrorText className="px-2 mb-0">{errors.site}</ErrorText>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.employee')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <AppDropDownSelect
                        options={employeeList.map((option) => ({
                          label: option.name,
                          value: option,
                          id: option,
                        }))}
                        noOptionsMessage={() => t('no_options') }
                        isClearable={true}
                        menuStyle={{
                          position: 'fixed',
                          backgroundColor: '#FFFFFF',
                          width: 360,
                          marginTop: 8,
                          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
                          borderRadius: 16,
                        }}
                        isLoading={loadingEmployees}
                        name="employee"
                        customOption={CustomOption}
                        avatar={values.employee ? calcAvatar(values.employee) : null}
                        placeholder={t('required')}
                        isDisabled={createShift}
                        icon={UserIcon}
                        height={230}
                        onChange={(selected) => {
                          if (selected) {
                            setFieldValue('employee', selected.value);
                            setEmployeeID(selected.value);
                            setFieldValue('shift', null);
                            setFieldValue('user_id', null);
                            setFieldValue('jobtitle', null);
                            setFieldValue('note', `${t('Timesheet.not_shift_note')}`);
                            setFieldValue('start_date', initialValues.start_date);
                            setFieldValue('end_date', initialValues.end_date);
                            setFieldValue('start_time', initialValues.start_time);
                            setStartDate(initialValues.start_time);
                            setFieldValue('end_time', initialValues.end_time);
                            setStartTimeF(initialValues.start_time);
                            setEndTimeF(initialValues.end_time);
                            setWorkedWeek(0);
                            setBreakTime(0);
                          } else {
                            setFieldValue('employee', '');
                            setEmployeeID('');
                          }
                        }}
                        invalid={touched.employee && errors.employee}
                        defaultValue={values.employee}
                      />
                      {touched.employee && errors.employee && (
                        <ErrorText className="px-2 mb-0">{errors.employee}</ErrorText>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {createShift === true ? (
                      <>
                        <Col xs={3}>
                          <LabelForm tittle={t('form_labels.shift_title')}></LabelForm>
                        </Col>
                        <Col xs={9}>
                          <InputForm
                            className="mb-0"
                            inpfontsize={'14px'}
                            height={'40px'}
                            placeholder={t('optional')}
                            name="title"
                            value={values.title}
                            invalid={touched.title && errors.title}
                            onChange={handleChange}
                          ></InputForm>
                          {touched.title && errors.title && (
                            <ErrorText className="px-2 mb-0">{errors.title}</ErrorText>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={3}>
                          <LabelForm tittle={t('form_labels.related_shift')}></LabelForm>
                        </Col>
                        <Col xs={9}>
                          <AppDropDownSelect
                            // hello={console.log('Shift List', shiftList)}
                            options={shiftList.map((option) => ({
                              label:
                                formatEspecial(option.shift.start_time, 'h a') +
                                ' - ' +
                                formatEspecial(option.shift.end_time, 'h a') +
                                ', ' +
                                /*option.shift.jobs_titles?.map((item, index) => (
                                  <>
                                    {index > 0 ? ', ' : ''}
                                    {item.jobtitle?.description}
                                  </>
                                )) +*/
                                ' ' +
                                option.shift.title,
                              value: option.id,
                              id: option.id,
                              shift: option.shift.id,
                              note: option.shift.note,
                              start_date: option.shift.shift_date,
                              end_date: option.shift.shift_date,
                              start_time: option.shift.start_time,
                              end_time: option.shift.end_time,
                            }))}
                            noOptionsMessage={() => t('no_options') }
                            isClearable={true}
                            name="shift"
                            isDisabled={createShift}
                            placeholder={
                              createShift === true
                                ? `${t('optional')}`
                                : `${t('placeholder_select_shift')}`
                            }
                            isLoading={loadingShift}
                            value={values.shift}
                            onChange={(selected) => {
                              setFieldValue('shift', selected);
                              setFieldValue('user_id', selected);
                              setFieldValue('jobtitle', selected?.jobtitle);
                              setFieldValue(
                                'note',
                                selected?.note ? selected?.note : `${t('Timesheet.not_shift_note')}`,
                              );
                              setFieldValue(
                                'start_date',
                                selected?.start_date
                                  ? moment.utc(selected.start_date)
                                  : initialValues.start_date,
                              );
                              setFieldValue(
                                'end_date',
                                selected?.end_date
                                  ? moment.utc(selected.end_date)
                                  : initialValues.end_date,
                              );
                              setFieldValue(
                                'start_time',
                                selected?.start_time
                                  ? moment.utc(selected?.start_time).format('HH:mm')
                                  : initialValues.start_time,
                              );
                              setStartDate(
                                selected?.start_time
                                  ? moment.utc(selected?.start_time)
                                  : initialValues.start_time,
                              );
                              setFieldValue(
                                'end_time',
                                selected?.end_time
                                  ? moment.utc(selected?.end_time).format('HH:mm')
                                  : initialValues.end_time,
                              );
                              setStartTimeF(
                                selected?.start_time
                                  ? moment.utc(selected.start_time)
                                  : initialValues.start_time,
                              );
                              setEndTimeF(
                                selected?.end_time
                                  ? moment.utc(selected.end_time)
                                  : initialValues.end_time,
                              );
                              setBreakTime(0);
                            }}
                            invalid={touched.shift && errors.shift}
                            defaultValue={values.shift}
                          />
                          {touched.shift && errors.shift && (
                            <ErrorText className="px-2 mb-0">{errors.shift}</ErrorText>
                          )}
                        </Col>
                      </>
                    )}
                    <Col xs={3}>&nbsp;</Col>
                    <Col xs={9}>
                      <Form.Check
                        style={{ fontSize: '14px' }}
                        className="pt-2"
                        type="checkbox"
                        checked={values.enable_claim}
                        label={t('form_labels.automatic_shift')}
                        onChange={(selected) => {
                          const value = selected.target.checked;
                          setCreateShift(value);
                          setFieldValue('shift', null);
                          setFieldValue('note', `${t('Timesheet.not_shift_note')}`);
                          setFieldValue('start_date', initialValues.start_date);
                          setFieldValue('end_date', initialValues.end_date);
                          setFieldValue('start_time', initialValues.start_time);
                          setStartDate(initialValues.start_time);
                          setFieldValue('end_time', initialValues.end_time);
                          setStartTimeF(initialValues.start_time);
                          setEndTimeF(initialValues.end_time);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.shift_note')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        placeholder={t('placeholder_select_related_shift')}
                        style={{ width: '100%', resize: 'none', paddingLeft: 2, paddingRight: 2 }}
                        inpfontsize={'14px'}
                        name="note"
                        ctype="textarea"
                        rows={3}
                        value={values.note && values.note.toLowerCase().replace(/\s/g, '_') === 'not_shift_note' ? `${t('Timesheet.not_shift_note')}` : values.note}
                        invalid={touched.note && errors.note}
                        onChange={handleChange}
                      ></InputForm>
                      {touched.note && errors.note && (
                        <ErrorText className="px-2 mb-0">{errors.note}</ErrorText>
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.start_date')}></LabelForm>
                    </Col>
                    <Col xs={6}>
                      <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage} style={{ fontSize: '14px' }}>
                        <DatePicker
                          style={{ fontSize: '14px' }}
                          label
                          value={values.start_date}
                          inputFormat="LL"
                          onChange={(newValue) => {
                            setFieldValue('start_date', newValue);
                            setStartDate(newValue);
                          }}
                          components={{
                            OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                          }}
                          renderDay={renderWeekPickerDay}
                          renderInput={(params) => (
                            <DatePickerInput
                              InputLabelProps={{ shrink: false }}
                              hiddenLabel
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.end_date')}></LabelForm>
                    </Col>
                    <Col xs={6}>
                      <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage} style={{ fontSize: '14px' }}>
                        <DatePicker
                          style={{ fontSize: '14px' }}
                          label
                          value={values.end_date}
                          inputFormat="LL"
                          onChange={(newValue) => {
                            setFieldValue('end_date', newValue);
                          }}
                          components={{
                            OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                          }}
                          renderDay={renderWeekPickerDay}
                          renderInput={(params) => (
                            <DatePickerInput
                              InputLabelProps={{ shrink: false }}
                              hiddenLabel
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.clock_in_out')}></LabelForm>
                    </Col>
                    <Col xs={3}>
                      {/* <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage}>
                        <StyledTimePicker
                          disabled={false}
                          label
                          InputProps={{
                            classes: { root: classes.root },
                          }}
                          value={values.start_time}
                          onChange={(newValue) => {
                            setFieldValue('start_time', newValue);
                            setStartTimeF(newValue);
                          }}
                          components={{
                            OpenPickerIcon: () => <img src={TPIcon} alt="clock" className="p0" />,
                          }}
                          renderInput={(params) => (
                            <DatePickerInput
                              InputLabelProps={{ shrink: false, fontSize: 12 }}
                              //hiddenLabel
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider> */}
                      <TimeRangeInput
                        id="time"
                        label="."
                        type="time"
                        value={values.start_time}
                        onChange={(event, newValue) => {
                          //New way: Add Shift date
                          const hours = event.target.value.split(':');
                          const dateTime = moment(values.shift_date).set({
                            hour: hours[0],
                            minute: hours[1],
                            second: 0,
                            millisecond: 0,
                          });
                          setFieldValue('start_time', event.target.value);
                          setStartTimeF(dateTime);
                        }}
                        InputLabelProps={{
                          shrink: true,
                          fontSize: 1,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '300',
                            fontSize: '14px',
                            color: '#000000',
                            padding: 0,
                          },
                        }}
                        sx={{
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            filter:
                              'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                          },
                        }}
                      />
                      {touched.start_time && errors.start_time && (
                        <ErrorText className="px-2 mb-0">{errors.start_time}</ErrorText>
                      )}
                    </Col>

                    <Col
                      xs={1}
                      style={{ textAlign: 'center', padding: '0', width: '6%', marginLeft: 5 }}
                    >
                      <LabelForm tittle="-"></LabelForm>
                    </Col>

                    <Col xs={3}>
                      {/* <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage}>
                        <TimePicker
                          disabled={false}
                          label
                          InputProps={{
                            classes: { root: classes.root },
                          }}
                          value={values.end_time}
                          //minTime={values.start_time ? moment(values.start_time) : moment()}
                          onChange={(newValue) => {
                            setFieldValue('end_time', newValue);
                            setEndTimeF(newValue);
                          }}
                          components={{
                            OpenPickerIcon: () => <img src={TPIcon} alt="clock" />,
                          }}
                          renderInput={(params) => (
                            <DatePickerInput
                              InputLabelProps={{ shrink: false }}
                              hiddenLabel
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider> */}
                      <TimeRangeInput
                        id="time"
                        label="."
                        type="time"
                        value={values.end_time}
                        onChange={(event, newValue) => {
                          //New way: Add Shift date
                          const hours = event.target.value.split(':');
                          const dateTime = moment(values.shift_date).set({
                            hour: hours[0],
                            minute: hours[1],
                            second: 0,
                            millisecond: 0,
                          });
                          setFieldValue('end_time', event.target.value);
                          setEndTimeF(dateTime);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '300',
                            fontSize: '14px',
                            color: '#000000',
                            padding: 0,
                          },
                        }}
                        sx={{
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            filter:
                              'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                          },
                        }}
                      />
                      {touched.end_time && errors.end_time && (
                        <ErrorText className="px-2 mb-0">{errors.end_time}</ErrorText>
                      )}
                    </Col>
                    <Col
                      xs={2}
                      className="px-0"
                      style={{ textAlign: 'center', padding: '0', width: '18%' }}
                    >
                      <HoursDiff>{diffHours} {t('form_labels.total').toLowerCase()}</HoursDiff>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.break')}></LabelForm>
                    </Col>
                    <Col xs={3}>
                      <InputForm
                        placeholder={t('optional')}
                        style={{ width: '100%', resize: 'none' }}
                        inpfontsize={'14px'}
                        name="break"
                        rows={3}
                        value={values.break}
                        invalid={touched.break && errors.break}
                        onChange={(option) => {
                          handleChange(option);
                          setFieldValue('break', option.target.value);
                          setBreakTime(option.target.value);
                        }}
                      ></InputForm>
                      {touched.break && errors.break && (
                        <ErrorText className="px-2 mb-0">{errors.break}</ErrorText>
                      )}
                    </Col>
                    {/* <Col xs={5} className="px-2">
                      <HoursDiff>
                        {regularHours.horas_break} Regular Hours / {calcDurationHours(overTime)}{' '}
                        Overtime
                      </HoursDiff>
                    </Col> */}
                  </Row>
                </div>
                <StyleButtons>
                  <AppButton
                    loading={loading}
                    style={{
                      width: 'auto',
                      fontSize: '14px',
                    }}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bordercolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    title={t('buttons.btn_confirm')}
                  >
                    {t('buttons.btn_confirm')}
                  </AppButton>
                  <div style={{ margin: '0.0rem 20px 0px' }}>
                    <AppButton
                      loading={loading}
                      style={{
                        width: 'auto',
                        fontSize: '14px',
                      }}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      onClick={handleclose}
                    >
                      {t('buttons.btn_cancel')}
                    </AppButton>
                  </div>
                </StyleButtons>
              </Form>
              {showErrorMessage.show && (
                <CustomizedSnackbar
                  show={showErrorMessage.show}
                  type={showErrorMessage.type}
                  title={showErrorMessage.title}
                  message={showErrorMessage.message}
                />
              )}
            </div>
          )}
        </Formik>
      )}
      {shiftsConflicts.length > 0 ? (
        /* Edit confirmation modal */
        <AppModal
          open={openShiftsConflictsModal}
          handleclose={handleClickCloseShiftsConflictsModal}
          boxshadow={'none'}
          inside={1}
          title={t('JobScheduler.modals.conflicts_found_title')}
          width="600px"
        >
          <DivShiftsConflicts>
            <div className="cont">
              <div className="col1">
                <h4>{t('form_labels.employee')}</h4>
              </div>
              <div className="col2">
                <h4>{t('form_labels.reason')}</h4>
              </div>
            </div>

            {shiftsConflicts.map((c) => (
              <div className="cont">
                <div className="col1">
                  <div style={{ display: 'flex' }}>
                    {calcAvatar(c, 29, 29)}
                    <StyledIconLabel>{c.name}</StyledIconLabel>
                  </div>
                </div>
                <div className="col2">
                  {c.user_shift.map((us) => (
                    <>
                      <div className="reasonLabel">
                        <img src={OverlappingShift} alt="OverlappingShift" />
                        {t('JobScheduler.overlapping_shift')}
                      </div>
                      <div className="reason" style={{ backgroundColor: c.color }}>
                        <div style={{ overflow: 'hidden' }}>
                          <p style={{ float: 'left' }}>
                            {us.shift.jobs_titles?.length > 0 ? (
                              us.shift.jobs_titles?.map((item, index) => (
                                <>
                                  {index > 0 ? ', ' : ''}
                                  {item.jobtitle.description}
                                </>
                              ))
                            ) : (
                              <>{us.shift.job_title?.description}</>
                            )}{' '}
                            - {us.shift.title}
                          </p>
                          <p style={{ float: 'right' }}>
                            {new moment.utc(us.shift.start_time).format('h')}
                            {moment(us.shift.start_time).format('a') === 'am' ? 'a' : 'p'} -{' '}
                            {new moment.utc(us.shift.end_time).format('h')}
                            {moment(us.shift.end_time).format('a') === 'am' ? 'a' : 'p'}
                          </p>
                        </div>
                        <div className="gray" style={{ overflow: 'hidden' }}>
                          <p style={{ float: 'left' }}>{us.shift.site.name}</p>
                          <p style={{ float: 'right' }}>
                            {moment(us.shift.end_time).diff(moment(us.shift.start_time), 'h')}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            ))}
            {/* <AppButton
              size="lg"
              style={{
                marginTop: 30,
                marginBottom: 30,
              }}
              bgcolor={PALETTE_COLOR.MAIN_WHITE}
              bghovercolor={PALETTE_COLOR.MAIN_BLUE}
              color={PALETTE_COLOR.MAIN_BLUE}
              bordercolor={PALETTE_COLOR.TOP_GRAY}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              onClick={() => console.log('test !!!!')}
              title={'Exclude conflict employees'}
            >
              {'Exclude conflict employees'}
            </AppButton> */}
          </DivShiftsConflicts>
        </AppModal>
      ) : (
        ''
      )}
    </div>
  );
};
