import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { obtainEntityData, editEntityData } from 'services';
import { USERS_API } from '@services/CONSTANTS';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import EncView from '@components/EncView';
import {
  AppModal,
  StateTag,
  SendInviteBtn,
  HeaderBtn,
  AppModalStatus,
  CustomizedSnackbar,
  AppButton,
  AppTable,
} from '@components';
import AppPanel from '@components/AppPanel';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { formatFullDate, formatDate } from '@utils/dateFormatHelper';
import { EditSystemUserForm } from '../EditSystemUserForm';
import { AddGroupForm } from './Groups/AddGroupForm';
import { EditGroupForm } from './Groups/EditGroupForm';
import { Menu, MenuItem } from '@szhsin/react-menu';
import IconButton from '@mui/material/IconButton';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Status_block.svg';
import status_invited from '@assets/icons/Invited.svg';
import status_draft from '@assets/icons/Draft.svg';
import status_archived from '@assets/icons/Archived.svg';
import ic_groups from '@assets/icons/ic_groups.svg';
import ic_big_groups from '@assets/icons/ic_big_groups.svg';
import logo_edit from '@assets/icons/edit.svg';
import { calcAvatar } from 'utils/avatar';
import ic_send_invite from '@assets/icons/ic_send_invite.svg';
import Button_Outline from '@assets/icons/Button_Outline.svg';
import restore from '@assets/icons/ic_restore.svg';
import ico_Restore from '@assets/icons/ico_Restore.svg';
import ico_Block from '@assets/icons/ico_Block.svg';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { getCurrentSession } from '@services/CognitoAuthService';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';

import { useTranslation } from 'react-i18next';

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Icon = styled.img``;

const LinkAction = styled(Button)`
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  :hover,
  :focus {
    border: 1px solid rgba(131, 180, 49, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

const StyleDivTitle = styled.div`
  bottom: 80.54%;
  border-radius: 52px;
  margin-right: 9px;
  margin-left: -15px;
  font-size: 20px;
  font-family: 'Poppins';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const StyleRow = styled.div`
  padding: 14px 44px 24px;
`;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const NoDataPanelText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const StyleTittle = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: ${PALETTE_COLOR.LIGHT_GREY};
`;

const StyleFields = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyleColContent = styled(Col)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledTag = styled.div`
  width: fit-content;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

export const ViewSystemUser = () => {
  const { t } = useTranslation();
  let { userId } = useParams();
  const { valuesDefinitions, userPermissions } = useSelector((state) => state.app);
  const [userData, setUserData] = useState();
  const [hasGroups, setHasGroups] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  //Store the mail from logged user to know if it can or not edit
  const [loggedUserMail, setLoggedUserEmail] = useState('');
  //Store the role from logged user to know if it can or not edit
  const loggedUserRole = useRef('');
  const [openEditUserForm, setOpenEditUserForm] = useState(false);
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [openSendInvite, setOpenSendInvite] = useState(false);
  const [openGroupsForm, setOpenGroupsForm] = useState(false);
  const [openEditGroupsForm, setOpenEditGroupsForm] = useState(false);
  const [selectedGroup] = useState({});
  const [newUserStatus, setNewUserStatus] = useState();
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [userStatusModalContent, setUserStatusModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });
  //Enable buttons
  const [enableButton, setEnableButton] = useState(true);
  //Loading var
  const [loading, setLoading] = useState(false);

  const sendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  //Content to show in re-sent invitation modal
  const resendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.resend_invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  const handleClickOpenSystemFormModal = (action) => {
    setOpenEditUserForm(true);
    setEditAction(action);
  };

  const handleCloseSystemFormModal = () => {
    setOpenEditUserForm(false);
  };

  const handleClickOpenGroupsModal = () => {
    setOpenGroupsForm(true);
  };

  const handleCloseGroupsModal = () => {
    setOpenGroupsForm(false);
  };

  const handleCloseEditGroupsModal = () => {
    setOpenEditGroupsForm(false);
  };

  const handleCloseChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  /***
   * To show/close modal to send invite
   */
  const handleSendInviteModal = () => {
    setOpenSendInvite((prev) => {
      return !prev;
    });
  };

  /***
   * Show/close modal to re-send invite
   */
  const handleReSendInviteModal = () => {
    setOpenReSendInvite((prev) => {
      return !prev;
    });
  };

  //Controls if re-sent invitation modal is shown or not
  const [openReSendInvite, setOpenReSendInvite] = React.useState(false);

  const columns = useMemo(
    () => [
      {
        dataField: 'group.name',
        text: `${t('table_columns.group')}`,
        sort: false,
        search: false,
        headerStyle: { width: '40%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.group?.name}</StyledLabel>;
        },
      },
      {
        dataField: 'group_permission.description',
        text: `${t('table_columns.permission')}`,
        sort: false,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <StyledTag>{t(`Users.permissions.${row.group_permission?.description.replace(/\s/g, '_')}`)}</StyledTag>
            </div>
          );
        },
      },
    ],
    [t],
  );

  /***
   *
   */
  useEffect(() => {
    async function fetchData() {
      const info = await getCurrentSession();
      setLoggedUserEmail(info.idToken?.payload?.email);
      loggedUserRole.current = info.idToken?.payload['cognito:groups'][0];
    }
    fetchData();
  }, []);

  /***
   * Depend on which groups system user has and its permissions, and which groups org has,
   * show or hide buttons
   */
  const showButtonsToSystemUser = React.useCallback(
    (empInfo) => {
      let showButtons = false;
      //Get employee groups
      const empGroups = empInfo?.user_groups.map((userGroup) => userGroup?.group?.id);
      if (empGroups.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (empGroups.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );

  //Get user by id and get logged user data
  useEffect(() => {
    obtainEntityData(`${USERS_API}${userId}`)
      .then((res) => {
        res.user_groups.sort(function (a, b) {
          if (a.group.name > b.group.name) {
            return 1;
          }
          if (a.group.name < b.group.name) {
            return -1;
          }
          return 0;
        });
        setUserData(res);
        setHasGroups(res.user_groups.length > 0 || res.role?.value_definition === '040');
        if (loggedUserMail === userData?.email || loggedUserRole.current === 'admin') {
          setIsEditEnabled(true);
        }
        //If it is a system user, determine if he can edit user
        if (userPermissions?.type === 'system') {
          setEnableButton(showButtonsToSystemUser(res));
        }
      })
      .catch((err) => {
        console.log('axios err=', err);
      });

    return () => {
      console.log('axios cleanup.');
    };
  }, [
    userId,
    showSucessModal,
    userData?.email,
    loggedUserMail,
    loggedUserRole,
    showButtonsToSystemUser,
    userPermissions,
  ]);

  const handleClickOpenChangeStatus = (newStatus) => {
    setOpenChangeStatus(true);
    setNewUserStatus(newStatus);

    switch (newStatus) {
      case '060':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_archive')}`,
          actionIcon: ico_Archived,
          title: `${t('Users.modals.archive_title')}`,
          subtitle: `${t('Users.modals.archive_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_RED,
          bordercolor: PALETTE_COLOR.ACCENT_RED,
          borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        });
        break;
      case '050':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_block')}`,
          actionIcon: ico_Block,
          title: `${t('Users.modals.block_title')}`,
          subtitle: `${t('Users.modals.block_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
          bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
          borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
        });
        break;
      case '010':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_unblock')}`,
          actionIcon: ico_Restore,
          title: `${t('Users.modals.unblock_title')}`,
          subtitle: `${t('Users.modals.unblock_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_GREEN,
          bordercolor: PALETTE_COLOR.ACCENT_GREEN,
          borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          boxShadow: '0px 4px 10px rgba(134, 217, 113, 0.3)',
        });
        break;
      default:
        break;
    }
  };

  /***
   *
   */
  const changeUserStatus = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    const status = valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === newUserStatus,
    )[0];
    editEntityData(`${USERS_API}${userId}`, { status })
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_status_changed')}`,
          message: '',
        });
        setLoading(false);
        handleCloseChangeStatus();
      })
      .catch((err) => {
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_user_status_changing')}`,
          message: '',
        });
        setLoading(false);
        handleCloseChangeStatus();
      });
  };

  /***
   * Send invitation to user and change its state
   */
  const sendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}sendInvite`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      });
  };

  /***
   * Re-send invitation to user and change its state
   */
  const reSendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}resend/invitation`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      });
  };

  return (
    <Container fluid>
      {/* Header section */}
      <EncView>
        <StyleDivTitle>{calcAvatar(userData, 60, 60)}</StyleDivTitle>
        <SpaceEncName>
          <div>
            <h3 style={{ margin: '0px' }}>{userData?.name}</h3>
            <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>
              {userData?.user_system_rol || <>&nbsp;</>}
            </p>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {userData?.status?.value_definition === '010' && (
            <>
              <StateTag
                width="auto"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Profile.status.${userData?.status.description}_account`)}
              </StateTag>
            </>
          )}
          {enableButton && userData?.status?.value_definition === '020' && (
            <>
              <StateTag
                width="auto"
                icon={status_draft}
                background={PALETTE_COLOR.DRAFT_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.INACTIVE_GRAY}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
              <SendInviteBtn width="auto" action={handleSendInviteModal}>
                {t('buttons.btn_send_invite')}
              </SendInviteBtn>
            </>
          )}
          {userData?.status?.value_definition === '030' && (
            <StateTag
              width="auto"
              icon={status_invited}
              background={PALETTE_COLOR.INVITED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_PURPLE}
            >
              {t(`Users.status.${userData?.status.description}`)}
            </StateTag>
          )}
          {userData?.status?.value_definition === '050' && (
            <>
              <StateTag
                width="auto"
                icon={status_blocked}
                background={PALETTE_COLOR.BLOCKED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.TEXT_ORANGE}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
            </>
          )}
          {userData?.status?.value_definition === '060' && (
            <>
              <StateTag
                width="auto"
                icon={status_archived}
                background={PALETTE_COLOR.ARCHIVED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_RED}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
              {enableButton && (
                <HeaderBtn
                  width={'auto'}
                  icon={restore}
                  action={() => handleClickOpenSystemFormModal('restore')}
                >
                  {t('buttons.btn_restore_account')}
                </HeaderBtn>
              )}
            </>
          )}
        </SpaceEnc>
        {enableButton && userData?.status?.value_definition !== '060' && (
          <Menu
            align="end"
            direction="bottom"
            menuButton={
              <DropDownWrapper style={{ marginLeft: '5px' }}>
                <IconButton aria-label="edit" sx={{}}>
                  <Icon src={Button_Outline} />
                </IconButton>
              </DropDownWrapper>
            }
          >
            {userData?.status?.value_definition === '030' && (
              <MenuItem onClick={handleReSendInviteModal}>{t('Users.menu.resent_invite')}</MenuItem>
            )}
            {(userData?.status?.value_definition === '010' ||
              userData?.status?.value_definition === '050') && (
              <MenuItem onClick={() => console.log('')}>{t('Users.menu.reset_password')}</MenuItem>
            )}
            {userData?.status?.value_definition === '010' && (
              // || userData?.status?.value_definition === '030'
              //|| userData?.status?.value_definition === '020'
              <MenuItem onClick={() => handleClickOpenChangeStatus('050')}>
                {t('Users.menu.block_account')}
              </MenuItem>
            )}
            {userData?.status?.value_definition === '050' && (
              <MenuItem onClick={() => handleClickOpenChangeStatus('010')}>
                {t('Users.menu.unblock_account')}
              </MenuItem>
            )}
            {(userData?.status?.value_definition === '010' ||
              userData?.status?.value_definition === '050') && (
              //|| userData?.status?.value_definition === '030'
              //|| userData?.status?.value_definition === '020'
              <MenuItem onClick={() => handleClickOpenChangeStatus('060')}>
                {t('Users.menu.archive_account')}
              </MenuItem>
            )}
          </Menu>
        )}
      </EncView>

      {/* Personal Info panel */}
      <Col xs={12} xl={12}>
        <AppPanel
          notitlepadding={`true`}
          title={t('Users.view.personal_title')}
          width="100%"
          padding_card="15px"
          icon={userData?.status?.value_definition !== '060' && isEditEnabled && logo_edit}
          call={() => handleClickOpenSystemFormModal('edit')}
        >
          <StyleRow>
            <StyleTittle>
              <Col>{t('form_labels.name')}</Col>
              <Col>{t('form_labels.email')}</Col>
              <Col>{t('form_labels.phone')}</Col>
              {userData?.role && userData?.role.value_definition !== '040' ? (
                <Col>{t('form_labels.role')}</Col>
              ) : null}
              <Col>{t('form_labels.type')}</Col>
            </StyleTittle>
            <StyleFields>
              <Col>{userData?.name}</Col>
              <Col>{userData?.email}</Col>
              <Col>{userData?.phone}</Col>
              {userData?.role && userData?.role?.value_definition !== '040' ? (
                <Col>{userData?.user_system_rol}</Col>
              ) : null}
              <Col>
                {userData?.role?.description ? (
                  t(`roles.${userData?.role?.description.replace(/\s/g, '_')}`)
                ) : (
                  <>&nbsp;</>
                )}
              </Col>
            </StyleFields>
          </StyleRow>
        </AppPanel>
      </Col>

      <Row>
        {/* Usage Info panel */}
        <Col sm={3}>
          <AppPanel
            notitlepadding={`true`}
            title={t('Users.view.usage_info_title')}
            width="100%"
            padding_card="15px"
          >
            <Card style={{ borderRight: 'unset', borderLeft: 'unset', borderTop: 'unset' }}>
              <ListGroup variant="flush" style={{ borderTop: 'unset' }}>
                <ListGroup.Item style={{ height: '3%' }}>
                  <StyleTittle>
                    <Col sm={5}>{t('Users.usage_info.invited')}</Col>
                    <StyleColContent>
                      {userData?.date_invited ? formatDate(userData.date_invited) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5}>{t('Users.usage_info.registered')}</Col>
                    <StyleColContent>
                      {userData?.date_registered ? formatDate(userData.date_registered) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5}>{t('Users.usage_info.last_modified')}</Col>
                    <StyleColContent>
                      {userData?.updated_at ? formatFullDate(userData.updated_at) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5}>{t('Users.usage_info.last_login')}</Col>
                    <StyleColContent>
                      {userData?.last_login ? formatFullDate(userData.last_login) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col sm={5}>{t('Users.usage_info.user_id')}</Col>
                    <StyleColContent>{userData?.id}</StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
              </ListGroup>
            </Card>

            {userData?.status?.value_definition === '010' && (
              <LinkAction variant="link" className="mb-2">
                {t('Users.usage_info.view_user_activities')}
              </LinkAction>
            )}
          </AppPanel>
        </Col>

        {/* Assigned Groups */}
        <Col sm={9}>
          {!userData ? (
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            </AppPanel>
          ) : (
            <AppPanel
              width="100%"
              header={hasGroups ? 1 : 0}
              style={
                hasGroups === false
                  ? {
                      minHeight: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : {}
              }
              icon_label={ic_groups}
              withborder={0}
              title={t('Users.view.assigned_groups_title')}
              button={
                isEditEnabled &&
                userData.role?.value_definition !== '040' && {
                  text: `${t('buttons.btn_add')}`,
                  icon: ic_add_circle,
                  action: handleClickOpenGroupsModal,
                }
              }
            >
              {hasGroups === false ? (
                <div>
                  <Icon
                    src={ic_big_groups}
                    style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                  />
                  <NoDataPanelTitle>
                    {t('Users.system.view.assignation.no_groups_title')}
                  </NoDataPanelTitle>
                  <NoDataPanelText>
                    {t('Users.system.view.assignation.no_groups_subtitle')}
                  </NoDataPanelText>
                  {userData?.status?.value_definition !== '060' && isEditEnabled && (
                    <AppButton
                      style={{ fontSize: 14, width: 'auto', display: 'flex', margin: 'auto' }}
                      bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                      bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                      bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                      borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                      icon={ic_add_circle}
                      onClick={() => handleClickOpenGroupsModal()}
                    >
                      {t('buttons.btn_assign')}
                    </AppButton>
                  )}
                </div>
              ) : (
                hasGroups === true && (
                  <AppTable
                    columns={
                      isEditEnabled && userData.role?.value_definition !== '040'
                        ? columns
                        : columns.filter((item) => item.dataField !== 'editUser')
                    }
                    data={
                      userData.role?.value_definition === '040'
                        ? [
                            {
                              group: {
                                name: `${t('Users.all_groups')}`,
                              },
                              group_permission: {
                                description: `Full_Access`,
                              },
                            },
                          ]
                        : userData.user_groups // TODO: Translate group_permission.description if not Super Admin
                    }
                    reload={showSucessModal.show}
                    disableFilters={true}
                    disablePagination={true}
                    disableTopPagination={true}
                    noBorders={true}
                  />
                )
              )}
            </AppPanel>
          )}
        </Col>
      </Row>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {/* Edit/Restore user */}
      <AppModal
        open={openEditUserForm}
        handleclose={handleCloseSystemFormModal}
        title={
          editAction === 'edit'
            ? t('Users.system.modal_edit.title')
            : t('Users.system.modal_edit.restore_title')
        }
        inside={1}
      >
        <EditSystemUserForm
          handleclose={handleCloseSystemFormModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
          editAction={editAction}
        />
      </AppModal>

      {/*Add group to user */}
      <AppModal
        open={openGroupsForm}
        handleclose={handleCloseGroupsModal}
        title={t('Users.system.view.modals.assign_group_title')}
        inside={1}
      >
        <AddGroupForm
          handleclose={handleCloseGroupsModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
        />
      </AppModal>
      {/*Edit group to user */}
      <AppModal
        open={openEditGroupsForm}
        handleclose={handleCloseEditGroupsModal}
        title={t('Users.system.view.modals.edit_assign_group_title')}
        inside={1}
      >
        <EditGroupForm
          handleclose={handleCloseEditGroupsModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
          selectedGroup={selectedGroup}
        />
      </AppModal>

      {/* Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        open={openSendInvite}
        handleclose={handleSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={sendInvitationModalData.actionIcon}
          title={`${t('Users.modals.invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={sendInvitationModalData.subtitleColor}
          subtitleWeight={sendInvitationModalData.subtitleWeight}
          bgcolor={sendInvitationModalData.bgcolor}
          bordercolor={sendInvitationModalData.bordercolor}
          borderhovercolor={sendInvitationModalData.borderhovercolor}
          bghovercolor={sendInvitationModalData.bghovercolor}
          boxShadow={sendInvitationModalData.boxShadow}
          buttonText={sendInvitationModalData.buttonText}
          subject={sendInvitationModalData.subtitle}
          subjectColor={sendInvitationModalData.subjectColor}
          subjectWeight={sendInvitationModalData.subjectWeight}
          buttonAction={sendInvite}
          isLoading={loading}
        />
      </AppModal>

      {/* Re-Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        styles={{}}
        open={openReSendInvite}
        handleclose={handleReSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={resendInvitationModalData.actionIcon}
          title={`${t('Users.modals.resend_invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={resendInvitationModalData.subtitleColor}
          subtitleWeight={resendInvitationModalData.subtitleWeight}
          bgcolor={resendInvitationModalData.bgcolor}
          bordercolor={resendInvitationModalData.bordercolor}
          borderhovercolor={resendInvitationModalData.borderhovercolor}
          bghovercolor={resendInvitationModalData.bghovercolor}
          boxShadow={resendInvitationModalData.boxShadow}
          buttonText={resendInvitationModalData.buttonText}
          subject={resendInvitationModalData.subtitle}
          subjectColor={resendInvitationModalData.subjectColor}
          subjectWeight={resendInvitationModalData.subjectWeight}
          buttonAction={reSendInvite}
          isLoading={loading}
        />
      </AppModal>

      {/* Change user status */}
      <AppModal
        width={500}
        styleModal={{ padding: '30px 40px 60px 40px' }}
        open={openChangeStatus}
        handleclose={handleCloseChangeStatus}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          width={'500px'}
          mainPadding={'0px 0px 0px 0px'}
          actionIcon={userStatusModalContent.actionIcon}
          title={userStatusModalContent.title}
          subtitle={userStatusModalContent.subtitle}
          bgcolor={userStatusModalContent.bgcolor}
          bordercolor={userStatusModalContent.bordercolor}
          borderhovercolor={userStatusModalContent.borderhovercolor}
          bghovercolor={userStatusModalContent.bghovercolor}
          boxShadow={userStatusModalContent.boxShadow}
          buttonText={userStatusModalContent.buttonText}
          subject={userData?.email}
          buttonAction={changeUserStatus}
          isLoading={loading}
        />
      </AppModal>
    </Container>
  );
};
