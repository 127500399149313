import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getEntityData, editEntityData } from 'services';
import { USERS_API } from '@services/CONSTANTS';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import EncView from '@components/EncView';
import { AppModalStatus } from '@components/AppModalStatus';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ico_Block from '@assets/icons/ico_Block.svg';
import restore from '@assets/icons/ic_restore.svg';
import { AppModal, StateTag, AppTable } from '@components';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Status_block.svg';
import ico_Restore from '@assets/icons/ico_Restore.svg';
import status_invited from '@assets/icons/Invited.svg';
import status_draft from '@assets/icons/Draft.svg';
import ic_location from '@assets/icons/ic_location.svg';
import ic_work from '@assets/icons/ic_work.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import AppPanel from '@components/AppPanel';
import logo_edit from '@assets/icons/edit.svg';
import IconButton from '@mui/material/IconButton';
import Archived from '@assets/icons/Archived.svg';
import { formatFullDate, formatDate } from '@utils/dateFormatHelper';
import { calcAvatar } from 'utils/avatar';
import { EditOrganizationUsers } from '../EditOrganizationUsers';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { Menu, MenuItem } from '@szhsin/react-menu';
import Button_Outline from '@assets/icons/Button_Outline.svg';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useSelector } from 'react-redux';
import ic_send_invite from '@assets/icons/ic_send_invite.svg';
import { SendInviteBtn, AppOrgIcon } from '@components';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';

import { useTranslation } from 'react-i18next';

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const LinkAction = styled(Button)`
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  :hover,
  :focus {
    border: 1px solid rgba(131, 180, 49, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

const Icon = styled.img``;

const StyleDivTitle = styled.div`
  bottom: 80.54%;
  border-radius: 52px;
  margin-right: 9px;
  margin-left: -15px;
  font-size: 20px;
  font-family: 'Poppins';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const SpacButtonRestore = styled(IconButton)`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 236, 244);
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  padding: 7px 7px 7px 7px;
  border: 1px solid #e7ecf4;
  padding: 7px 7px 7px 7px;
  margin-left: 2%;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const StyleRow = styled.div`
  padding: 14px 44px 24px;
`;

const StyleTittle = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: ${PALETTE_COLOR.LIGHT_GREY};
`;

const StyleFields = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyleColContent = styled(Col)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  color: ${PALETTE_COLOR.BLACK};
`;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const NoDataPanelText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ViewOrganizationUsers = () => {
  const { t } = useTranslation();
  const { valuesDefinitions, userPermissions } = useSelector((state) => state.app);
  let { userId } = useParams();
  const newStatusActive = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '010',
  )[0]; //Active
  const newStatusBlocked = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '050',
  )[0]; //Blocked
  const newStatusArchive = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '060',
  )[0]; //Archived
  const [userData, setUserData] = useState();
  const [openChangeStatusUser, setOpenChangeStatusUser] = React.useState(false);
  const [openEditUserForm, setOpenEditUserForm] = React.useState(false);
  const [newUserStatus, setNewUserStatus] = useState();
  const [openRestoreUser, setOpenRestoreUser] = React.useState(false);
  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [userStatusModalContent, setUserStatusModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });
  const [openSendInvite, setOpenSendInvite] = React.useState(false);
  //Enable buttons
  const [enableButton, setEnableButton] = useState(true);
  //Loading var
  const [loading, setLoading] = useState(false);
  //Controls if re-sent invitation modal is shown or not
  const [openReSendInvite, setOpenReSendInvite] = React.useState(false);

  const siteManagerColumns = useMemo(
    () => [
      {
        dataField: 'organization_id.name',
        text: `${t('table_columns.organization')}`,
        isDummyField: true,
        sort: false,
        search: false,
        headerStyle: { width: '30%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex' }}>
              <AppOrgIcon
                color={row.site.organization_id.color}
                title={row.site.organization_id.name}
                width={'30px'}
                height={'30px'}
                padding={'4px'}
                fontSize={'12px'}
              >
                {row.site.organization_id.name.substring(0, 2).toUpperCase()}
              </AppOrgIcon>
              <StyledLabel style={{ margin: '4px' }}>{row.site.organization_id.name}</StyledLabel>
            </div>
          );
        },
      },
      {
        dataField: 'name',
        text: `${t('table_columns.site')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '25%' },
        formatter: (cellContent, row) => {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: row.site.organization_id.color,
                display: 'block',
                paddingTop: '13px',
              }}
            >
              {row.site.name}
            </StyledNestedCell>
          );
          //return <StyledLabel>{row.name}</StyledLabel>;
        },
      },
      {
        dataField: 'address',
        text: `${t('table_columns.address')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '35%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.site.address}</StyledLabel>;
        },
      },
    ],
    [t],
  );

  const columnsOrgs = useMemo(
    () => [
      {
        dataField: 'name',
        text: `${t('table_columns.organization')}`,
        isDummyField: true,
        sort: false,
        search: false,
        headerStyle: { width: '25%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex' }}>
              <AppOrgIcon
                color={row.color}
                title={row.name}
                width={'30px'}
                height={'30px'}
              ></AppOrgIcon>
              <StyledLabel style={{ margin: '4px' }}>{row.name}</StyledLabel>
            </div>
          );
        },
      },
      {
        dataField: 'phone',
        text: `${t('table_columns.contact_phone')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.phone}</StyledLabel>;
        },
      },
      {
        dataField: 'email',
        text: `${t('table_columns.contact_email')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.email}</StyledLabel>;
        },
      },
      {
        dataField: 'address',
        text: `${t('table_columns.address')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '35%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.address}</StyledLabel>;
        },
      },
    ],
    [t],
  );

  const sendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  //Content to show in re-sent invitation modal
  const resendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.resend_invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  const handleCloseChangeStatusUser = () => {
    setOpenChangeStatusUser(false);
  };
  const handleClickOpenSystemFormModal = () => {
    setOpenEditUserForm(true);
  };

  const handleCloseSystemFormModal = () => {
    setOpenEditUserForm(false);
  };

  /***
   *
   */
  const changeStatusUser = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call API
    editEntityData(`${USERS_API}${userData.id}`, { status: newUserStatus })
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_status_changed')}`,
          message: '',
        });
        setLoading(false);
        handleCloseChangeStatusUser();
      })
      .catch((err) => {
        setLoading(false);
        handleCloseChangeStatusUser();
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_user_status_changing')}`,
          message: '',
        });
      });
  };

  /***
   * Depend on which groups system user has and its permissions, and which groups org user has,
   * show or hide buttons
   */
  const showButtonsToSystemUserOR = React.useCallback(
    (ORInfo) => {
      let showButtons = false;
      //Get OR organizations
      const userOrgs = ORInfo?.organizations_users?.map(
        (userOrganizations) => userOrganizations?.organization_id,
      );
      //Get groups of each site
      let groups = [];
      for (let i = 0; i < userOrgs.length; i++) {
        const orgSites = userOrgs[i]?.organizations_sites;
        for (let j = 0; j < orgSites.length; j++) {
          const groupSite = orgSites[j]?.groupsSite;
          if (groupSite[0]?.group.id) {
            groups.push(groupSite[0]?.group.id);
          }
        }
      }

      if (groups.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (groups.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );

  /***
   * Depend on which groups system user has and its permissions, and which groups org user has,
   * show or hide buttons
   */
  const showButtonsToSystemUserSM = React.useCallback(
    (SMInfo) => {
      let showButtons = false;
      //Get SM organizations
      const orgUserSites = SMInfo?.organizations_users?.map(
        (userOrganizations) => userOrganizations?.organization_user_sites,
      );
      //Get groups of each site
      let groups = [];
      for (let i = 0; i < orgUserSites.length; i++) {
        const orgSites = orgUserSites[i];
        for (let j = 0; j < orgSites.length; j++) {
          const groupSite = orgSites[j]?.site?.groupsSite;
          if (groupSite[0]?.group.id) {
            groups.push(groupSite[0]?.group.id);
          }
        }
      }

      if (groups.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (groups.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );

  /***
   * Get user by id
   */
  useEffect(() => {
    setIsLoadingData(true);
    const qb = RequestQueryBuilder.create()
      .setJoin({ field: 'organizations_users' })
      .setJoin({ field: 'organizations_users.organization_id' })
      //Next 3 joins: For system user permission over org rep
      .setJoin({ field: 'organizations_users.organization_id.organizations_sites' })
      .setJoin({ field: 'organizations_users.organization_id.organizations_sites.groupsSite' })
      .setJoin({
        field: 'organizations_users.organization_id.organizations_sites.groupsSite.group',
      })
      .setJoin({ field: 'organizations_users.organization_user_sites' })
      .setJoin({ field: 'organizations_users.organization_user_sites.site' })
      .setJoin({ field: 'organizations_users.organization_user_sites.site.organization_id' })
      //Next 2 joins: For system user permission over site manager
      .setJoin({ field: 'organizations_users.organization_user_sites.site.groupsSite' })
      .setJoin({ field: 'organizations_users.organization_user_sites.site.groupsSite.group' });
    getEntityData(`${USERS_API}${userId}`, qb.query())
      .then((res) => {
        setUserData(res);
        setIsLoadingData(false);
        //If it is a system user, determine if he can edit org or add sites/employees
        if (userPermissions?.type === 'system') {
          if (res?.role?.value_definition === '010') {
            //Org representative
            setEnableButton(showButtonsToSystemUserOR(res));
          } else if (res?.role?.value_definition === '020') {
            //Site namager
            setEnableButton(showButtonsToSystemUserSM(res));
          }
        }
      })
      .catch((err) => {
        console.log('axios err=', err);
        setIsLoadingData(false);
      });
    return () => {
      console.log('axios cleanup.');
    };
  }, [
    userId,
    showSucessModal,
    showButtonsToSystemUserOR,
    showButtonsToSystemUserSM,
    userPermissions,
  ]);

  /***
   *
   */
  const handleClickOpenChangeStatusSite = (newStatus) => {
    setOpenChangeStatusUser(true);
    setNewUserStatus(newStatus);
    switch (newStatus.value_definition) {
      case '060':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_archive')}`,
          actionIcon: ico_Archived,
          title: `${t('Users.modals.archive_title')}`,
          subtitle: `${t('Users.modals.archive_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_RED,
          bordercolor: PALETTE_COLOR.ACCENT_RED,
          borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        });
        break;
      case '050':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_block')}`,
          actionIcon: ico_Block,
          title: `${t('Users.modals.block_title')}`,
          subtitle: `${t('Users.modals.block_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
          bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
          borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
        });
        break;
      case '010':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_unblock')}`,
          actionIcon: ico_Restore,
          title: `${t('Users.modals.unblock_title')}`,
          subtitle: `${t('Users.modals.unblock_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_GREEN,
          bordercolor: PALETTE_COLOR.ACCENT_GREEN,
          borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          boxShadow: '0px 4px 10px rgba(134, 217, 113, 0.3)',
        });
        break;
      default:
        break;
    }
  };

  /***
   * To show/close modal to send invite
   */
  const handleSendInviteModal = () => {
    setOpenSendInvite((prev) => {
      return !prev;
    });
  };

  /***
   * Show/close modal to re-send invite
   */
  const handleReSendInviteModal = () => {
    setOpenReSendInvite((prev) => {
      return !prev;
    });
  };

  const handleClickOpenRestoreUserModal = () => {
    setOpenRestoreUser(true);
  };

  const handleCloseRestoreUser = () => {
    setOpenRestoreUser(false);
  };

  /***
   * Send invitation to user and change its state
   */
  const sendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}sendInvite`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      });
  };

  /***
   * Re-send invitation to user and change its state
   */
  const reSendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}resend/invitation`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      });
  };

  return (
    <Container fluid>
      {/* Header section */}
      <EncView>
        <StyleDivTitle>{calcAvatar(userData, 60, 60)}</StyleDivTitle>
        <SpaceEncName>
          <div>
            <h3 style={{ margin: '0px' }}>{userData?.name}</h3>
            <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>
              {userData?.role?.description ? (
                t(
                  `Users.organizations.view.header_info.${userData?.role?.description.replace(
                    /\s/g,
                    '_',
                  )}`,
                )
              ) : (
                <>&nbsp;</>
              )}
            </p>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {userData?.status?.value_definition === '010' && (
            <>
              <StateTag
                width="auto"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Profile.status.${userData?.status.description}_account`)}
              </StateTag>
            </>
          )}
          {userData?.status?.value_definition === '060' && (
            <StateTag
              width="auto"
              icon={Archived}
              background={PALETTE_COLOR.ARCHIVED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_RED}
            >
              {t(`Profile.status.${userData?.status.description}_account`)}
            </StateTag>
          )}
          {enableButton && userData?.status?.value_definition === '060' && (
            <SpacButtonRestore
              aria-label="edit"
              sx={{ padding: '17px 10px' }}
              style={{
                background: '#FFFFFF',
                border: '1px solid #E7ECF4',
                boxSizing: 'border-box',
                borderRadius: '20px',
                height: '40px',
                marginLeft: '2%',
              }}
              onClick={() => handleClickOpenRestoreUserModal()}
            >
              <Icon src={restore} />
              <div
                style={{
                  fontWeight: '600',
                  fontSize: '13px',
                  color: '#1179BF',
                  padding: 'inherit',
                }}
              >
                {t('buttons.btn_restore_account')}
              </div>
            </SpacButtonRestore>
          )}
          {userData?.status?.value_definition === '050' && (
            <>
              <StateTag
                width="auto"
                icon={status_blocked}
                background={PALETTE_COLOR.BLOCKED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.TEXT_ORANGE}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
            </>
          )}
          {enableButton && userData?.status?.value_definition === '020' && (
            <>
              <StateTag
                width="auto"
                icon={status_draft}
                background={PALETTE_COLOR.DRAFT_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.INACTIVE_GRAY}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
              <SendInviteBtn width="auto" action={handleSendInviteModal}>
                {t('buttons.btn_send_invite')}
              </SendInviteBtn>
            </>
          )}
          {userData?.status?.value_definition === '030' && (
            <StateTag
              width="auto"
              icon={status_invited}
              background={PALETTE_COLOR.INVITED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_PURPLE}
            >
              {t(`Users.status.${userData?.status.description}`)}
            </StateTag>
          )}
        </SpaceEnc>
        {enableButton && userData?.status?.value_definition !== '060' ? (
          <Menu
            align="end"
            direction="bottom"
            menuButton={
              <DropDownWrapper style={{ marginLeft: '5px' }}>
                <IconButton aria-label="edit" sx={{}}>
                  <Icon src={Button_Outline} />
                </IconButton>
              </DropDownWrapper>
            }
          >
            {userData?.status?.value_definition === '030' && (
              <MenuItem onClick={handleReSendInviteModal}>{t('Users.menu.resent_invite')}</MenuItem>
            )}
            {userData?.status?.value_definition === '050' && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusActive)}>
                {t('Users.menu.unblock_account')}
              </MenuItem>
            )}
            {userData?.status?.value_definition === '010' && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusBlocked)}>
                {t('Users.menu.block_account')}
              </MenuItem>
            )}
            {(userData?.status?.value_definition === '050' ||
              userData?.status?.value_definition === '010') && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusArchive)}>
                {t('Users.menu.archive_account')}
              </MenuItem>
            )}
          </Menu>
        ) : (
          <div></div>
        )}
      </EncView>
      {/* Restore organization user */}
      <AppModal
        open={openRestoreUser}
        handleclose={handleCloseRestoreUser}
        title={t('Users.organizations.modal_edit.restore_title')}
        inside={1}
      >
        <EditOrganizationUsers
          handleclose={handleCloseRestoreUser}
          setShowSucessModal={setShowSucessModal}
          data={userData}
          action="restore"
        />
      </AppModal>
      {/* Change user status */}
      <AppModal
        width={500}
        styleModal={{ padding: '30px 40px 60px 40px' }}
        open={openChangeStatusUser}
        handleclose={handleCloseChangeStatusUser}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          width={'500px'}
          mainPadding={'0px 0px 0px 0px'}
          actionIcon={userStatusModalContent.actionIcon}
          title={userStatusModalContent.title}
          subtitle={userStatusModalContent.subtitle}
          bgcolor={userStatusModalContent.bgcolor}
          bordercolor={userStatusModalContent.bordercolor}
          borderhovercolor={userStatusModalContent.borderhovercolor}
          bghovercolor={userStatusModalContent.bghovercolor}
          boxShadow={userStatusModalContent.boxShadow}
          buttonText={userStatusModalContent.buttonText}
          subject={userData?.email}
          buttonAction={changeStatusUser}
          isLoading={loading}
        />
      </AppModal>
      {/* Personal Info panel */}
      <Col xs={12} xl={12}>
        <AppPanel
          notitlepadding={`true`}
          title={t('Users.view.personal_title')}
          icon={enableButton && logo_edit}
          width="100%"
          padding_card="15px"
          call={handleClickOpenSystemFormModal}
        >
          <StyleRow>
            <StyleTittle>
              <Col>{t('form_labels.name')}</Col>
              <Col>{t('form_labels.email')}</Col>
              <Col>{t('form_labels.phone')}</Col>
              <Col>{t('form_labels.role')}</Col>
              <Col>{t('form_labels.permission')}</Col>
            </StyleTittle>
            <StyleFields>
              <Col>{userData?.name}</Col>
              <Col>{userData?.email}</Col>
              <Col>{userData?.phone}</Col>
              <Col>
                {userData?.role?.description ? (
                  t(`roles.${userData?.role?.description.replace(/\s/g, '_')}`)
                ) : (
                  <>&nbsp;</>
                )}
              </Col>
              <Col>
                {userData?.permission
                  ? t(
                      `Profile.permissions.${userData?.permission?.description.replace(
                        /\s/g,
                        '_',
                      )}`,
                    )
                  : t(`Profile.permissions.non`)}
              </Col>
            </StyleFields>
          </StyleRow>
        </AppPanel>
      </Col>

      <Row>
        {/* Usage Info panel */}
        <Col sm={3}>
          <AppPanel
            notitlepadding={`true`}
            title={t('Users.view.usage_info_title')}
            width="100%"
            padding_card="15px"
          >
            <Card style={{ borderRight: 'unset', borderLeft: 'unset', borderTop: 'unset' }}>
              <ListGroup variant="flush" style={{ borderTop: 'unset' }}>
                <ListGroup.Item style={{ height: '3%' }}>
                  <StyleTittle>
                    <Col>{t('Users.usage_info.invited')}</Col>
                    <StyleColContent>
                      {userData?.date_invited ? formatDate(userData.date_invited) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col>{t('Users.usage_info.registered')}</Col>
                    <StyleColContent>
                      {userData?.date_registered ? formatDate(userData.date_registered) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col>{t('Users.usage_info.last_modified')}</Col>
                    <StyleColContent>
                      {userData?.updated_at ? formatFullDate(userData.updated_at) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col>{t('Users.usage_info.last_login')}</Col>
                    <StyleColContent>
                      {userData?.last_login ? formatFullDate(userData?.last_login) : '-'}
                    </StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                <ListGroup.Item>
                  <StyleTittle>
                    <Col>{t('Users.usage_info.user_id')}</Col>
                    <StyleColContent>{userData?.id}</StyleColContent>
                  </StyleTittle>
                </ListGroup.Item>
                {userData?.role?.value_definition === '020' ? (
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.pin')}</Col>
                      <StyleColContent>{userData?.pin}</StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                ) : (
                  ''
                )}
              </ListGroup>
            </Card>

            {userData?.status?.value_definition === '010' && (
              <LinkAction variant="link" className="mb-2">
                {t('Users.usage_info.view_user_activities')}
              </LinkAction>
            )}
          </AppPanel>
        </Col>

        {/* Assigned Org/Sites */}
        <Col sm={9}>
          {isLoadingData ? (
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            </AppPanel>
          ) : !userData ||
            (userData?.role?.value_definition === '020' &&
              userData?.organizations_users.length === 0) || //Site manager
            (userData?.role?.value_definition === '010' && userData?.organizations.length === 0) ? ( // Org representative
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon src={ic_work} style={{ width: '47px', height: '47px' }} />
              <NoDataPanelTitle>
                {t('Users.organizations.view.assignation.no_organizations_title')}
              </NoDataPanelTitle>
              {userData?.status?.value_definition === '060' ? (
                <NoDataPanelText>
                  {t('Users.organizations.view.assignation.archived_cannot')}
                </NoDataPanelText>
              ) : (
                ''
              )}
            </AppPanel>
          ) : (
            <AppPanel
              style={{ minWidth: '-moz-available' }} //Fix bad visualization on Fire fox
              title={`${
                userData?.role?.value_definition === '020' //Site Manager
                  ? `${t('Users.view.assigned_sites_title')}`
                  : `${t('Users.view.assigned_organization_title')}`
              }`}
              width="100%"
              icon_label={userData?.role?.value_definition === '020' ? ic_location : ic_work}
            >
              <AppTable
                columns={
                  userData?.role?.value_definition === '020' ? siteManagerColumns : columnsOrgs
                }
                data={
                  userData?.role?.value_definition === '020' //Site manager
                    ? userData?.organizations_users[0]?.organization_user_sites
                    : userData.organizations
                }
                disableFilters={true}
                disablePagination={true}
                disableTopPagination={true}
                noBorders={true}
              />
            </AppPanel>
          )}
        </Col>
      </Row>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {/* Edit user */}
      <AppModal
        open={openEditUserForm}
        handleclose={handleCloseSystemFormModal}
        title={t('Users.organizations.modal_edit.title')}
        inside={1}
      >
        <EditOrganizationUsers
          handleclose={handleCloseSystemFormModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
          action="edit"
        />
      </AppModal>

      {/* Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        open={openSendInvite}
        handleclose={handleSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={sendInvitationModalData.actionIcon}
          title={`${t('Users.modals.invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={sendInvitationModalData.subtitleColor}
          subtitleWeight={sendInvitationModalData.subtitleWeight}
          bgcolor={sendInvitationModalData.bgcolor}
          bordercolor={sendInvitationModalData.bordercolor}
          borderhovercolor={sendInvitationModalData.borderhovercolor}
          bghovercolor={sendInvitationModalData.bghovercolor}
          boxShadow={sendInvitationModalData.boxShadow}
          buttonText={sendInvitationModalData.buttonText}
          subject={sendInvitationModalData.subtitle}
          subjectColor={sendInvitationModalData.subjectColor}
          subjectWeight={sendInvitationModalData.subjectWeight}
          buttonAction={sendInvite}
          isLoading={loading}
        />
      </AppModal>

      {/* Re-Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        styles={{}}
        open={openReSendInvite}
        handleclose={handleReSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={resendInvitationModalData.actionIcon}
          title={`${t('Users.modals.resend_invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={resendInvitationModalData.subtitleColor}
          subtitleWeight={resendInvitationModalData.subtitleWeight}
          bgcolor={resendInvitationModalData.bgcolor}
          bordercolor={resendInvitationModalData.bordercolor}
          borderhovercolor={resendInvitationModalData.borderhovercolor}
          bghovercolor={resendInvitationModalData.bghovercolor}
          boxShadow={resendInvitationModalData.boxShadow}
          buttonText={resendInvitationModalData.buttonText}
          subject={resendInvitationModalData.subtitle}
          subjectColor={resendInvitationModalData.subjectColor}
          subjectWeight={resendInvitationModalData.subjectWeight}
          buttonAction={reSendInvite}
          isLoading={loading}
        />
      </AppModal>
    </Container>
  );
};
