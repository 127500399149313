import React from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import styled from 'styled-components';
import { calcAvatar } from 'utils/avatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyleDivTitle = styled.div`
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.98)',
    color: 'white',
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderRadius: 12,
    padding: '8px 16px 8px 16px',
    left: 0,
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0.98)',
    },
  },
}));

export const FilterRepresentatives = ({ org }) => {
  const { t } = useTranslation();
  const representatives = org?.representatives
    ? org.representatives.filter((or) => or.user_id.role?.value_definition === '010')
    : [];
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {representatives.map((user, index) => (
        <div
          style={{
            display: 'flex',
            marginLeft: index === 0 ? '0px' : '-10px',
          }}
          key={index}
        >
          {representatives.length === 1 ? (
            <>
              <span style={{ cursor: 'pointer' }}>{calcAvatar(user.user_id, 30, 30)}</span>

              <StyledLabel style={{ margin: '4px' }}>{user.user_id.name}</StyledLabel>
            </>
          ) : (
            <>
              <DarkTooltip title={user.user_id.name} arrow placement="top">
                <StyleDivTitle style={{ cursor: 'pointer' }}>
                  {calcAvatar(user.user_id, 30, 30)}
                </StyleDivTitle>
              </DarkTooltip>
            </>
          )}
        </div>
      ))}
      {representatives.length === 0 ? (
        <StyledLabel style={{ margin: '4px', color: PALETTE_COLOR.LIGHT_GREY, fontSize: 14 }}>
          {t('not_assigned')}
        </StyledLabel>
      ) : null}
    </div>
  );
};

export const FilterSiteRepresentatives = ({ site }) => {
  const { t } = useTranslation();
  const representatives = site.organizations_users_sites
    .filter(
      (ous) =>
        ous.organization_user.user_id.role &&
        ous.organization_user.user_id.role.value_definition === '020',
    )
    .map((ous) => ous.organization_user.user_id);
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {representatives.map((user, index) => (
        <div
          style={{
            display: 'flex',
            marginLeft: index === 0 ? '0px' : '-10px',
          }}
          key={index}
        >
          {representatives.length === 1 ? (
            <>
              <span style={{ cursor: 'pointer' }}>{calcAvatar(user, 30, 30)}</span>

              <StyledLabel style={{ margin: '4px' }}>{user.name}</StyledLabel>
            </>
          ) : (
            <>
              <DarkTooltip title={user.name} arrow placement="top">
                <StyleDivTitle style={{ cursor: 'pointer' }}>
                  {calcAvatar(user, 30, 30)}
                </StyleDivTitle>
              </DarkTooltip>
            </>
          )}
        </div>
      ))}
      {representatives.length === 0 ? (
        <StyledLabel style={{ margin: '4px', color: PALETTE_COLOR.LIGHT_GREY, fontSize: 14 }}>
          {t('not_assigned')}
        </StyledLabel>
      ) : null}
    </div>
  );
};
