import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  PALETTE_COLOR,
  SHIFT_EDIT,
  SHIFT_REMOVE,
  DISCARD_SHIFT,
  DISCARD_SPOT,
} from '@config/CONSTANTS';
import { AppModal, AppOrgIcon, AppButton, AppModalStatus } from '@components';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { USERS_API, SHIFTS_API, USER_SHIFTS_API, USER_LOCATIONS_API } from '@services/CONSTANTS';
import { getEntityData, editEntityData, deleteEntityData } from '@services';
import time_circle from '@assets/icons/time_circle.svg';
import calender2 from '@assets/icons/calender2.svg';
import Location from '@assets/icons/Location.svg';
import ic_document_minus from '@assets/icons/ic_document_minus.svg';
import Work from '@assets/icons/Work.svg';
import group from '@assets/icons/group.svg';
import { Badge, Tooltip } from '@mui/material';
import { calcAvatar } from 'utils/avatar';
import { ShiftFullTimeDifferences, ShiftTimes } from 'utils/appUtils';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import trash from '@assets/icons/ic_red_trash.svg';
import personalcard from '@assets/icons/personalcard.svg';
import ico_check from '@assets/icons/ico_check.svg';
import ico_decline from '@assets/icons/ico_decline.svg';
//import ic_rejected from '@assets/icons/ic_rejected.svg';
// import ico_cancel from '@assets/icons/ico_cancel.svg';
//Status icons
import status_draft from '@assets/icons/status_draft.svg';
import ic_claimed from '@assets/icons/ic_claimed.svg';
import ic_pending_approval from '@assets/icons/ic_pending_approval.svg';
import ic_assigned from '@assets/icons/ic_assigned.svg';
import ic_cancellation_request from '@assets/icons/ic_cancellation_request.svg';
import ic_canceled from '@assets/icons/ic_canceled.svg';
import ic_no_show from '@assets/icons/ic_no_show.svg';
import ic_unsiged_hours from '@assets/icons/ic_unsiged_hours.svg';
import ic_completed_and_signed from '@assets/icons/ic_completed_and_signed.svg';
import ic_running_late from '@assets/icons/ic_running_late.svg';
import ic_clock_in from '@assets/icons/ic_clock_in.svg';
import ic_published from '@assets/icons/ic_published.svg';
//End status icons
import { useDispatch } from 'react-redux';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import { showMessageAction } from '@redux/actions/appActions';

import ico_Archived from '@assets/icons/ico_Archived.svg';
import { AppComponentLoading } from '@components';

import appConfig from '@config/appConfig';
import ic_arrow_right from '@assets/icons/ic_arrow_right.svg';
import ic_arrow_right_black from '@assets/icons/ic_arrow_right_black.svg';
import ic_arrow_down_enabled from '@assets/icons/ic_arrow_down_enabled_2.svg';
import ic_navigation_enabled from '@assets/icons/ic_navigation_enabled.svg';
import ic_navigation_disabled from '@assets/icons/ic_navigation_disabled.svg';
import ic_location_enabled from '@assets/icons/ic_location_enabled.svg';
import ic_location_disabled from '@assets/icons/ic_location_disabled.svg';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import MovingMarkerMap from './MovingMarkerMap';

import i18next from 'i18n';
i18next.changeLanguage(i18next.resolvedLanguage);

let MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const ShiftWrapper = styled.div`
  background: ${(props) =>
    props.shift.status?.value_definition === '020' || !props.shiftBackground
      ? '#FFFFFF'
      : props.shift.organization?.color};
  border: ${(props) =>
    props.shift.status?.value_definition === '020' && !props.shift.site
      ? '6px solid #EEEEEE'
      : props.shift.site
      ? `6px solid ${props.shift.organization?.color}`
      : '0px'};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${(props) =>
    props.shift.status?.value_definition === '020' || props.shift.status?.value_definition === '050'
      ? '600'
      : '500'};
  font-size: 13px;
  line-height: 20px;
  padding: 8px 14px 8px 14px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  .float {
    float: right;
    color: ${PALETTE_COLOR.LIGHT_GREY};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .jobTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #6d737b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const ShiftSubLabelWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StatusWrapper = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 0px;
`;

const EmployeeStatusWrapper = styled.img`
  width: 20px;
  height: 20px;
`;

const EmployeeStatusLabel = styled.div`
  font-size: 12px;
  color: #7c4f2f;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  width: '100%',
  '& .MuiBadge-badge': {
    top: 6,
    left: 6,
    background: MAIN_BLUE,
  },
}));

const CustomList = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 26px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 358px;
  max-width: 358px;
`;

const CustomListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .gmnoprint {
    display: none;
  }

  .gm-style > div > div > a > div > img {
      display: none;
  }
  
  .gmnoprint.gm-bundled-control {
    display: block;

    div:nth-child(2) {
      display: block;
    }
  }

  .szh-accordion {
    width: 100%;

    .szh-accordion__item {
      width: 100%;

      h3.szh-accordion__item-heading {
        width: 100%;

        button {
          width: 100%;
          display: flex;
          gap: 10px;
          align-items: center;
          border: 0px;
          background: transparent;
        }
      }
      
      .szh-accordion__item-content {
        padding-top: 15px;
      }
    }
  }
`;

const CustomListUsersAvatar = styled.div`
  float: left;
  margin-top: -6px;
  height: 30px;
  width: 30px;
`;

const Separator = styled.div`
  background: ${PALETTE_COLOR.BORDER_LIST};
  height: 1px;
`;

const AvatarEmpy = styled.div`
  height: 100%;
  width: 100%;
  border: 1px dashed rgba(109, 115, 123, 0.4);
  border-radius: 15px;
  font-size: 13px;
  padding-top: 5px;
  text-align: center;
`;

const StatusImg = styled.div`
  font-size: 12px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : PALETTE_COLOR.MAIN_PURPLE_LIGHT};
  color: ${(props) => (props.fontColor ? props.fontColor : PALETTE_COLOR.MAIN_PURPLE_LIGHT)};
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 6px;
  margin-left: 36px;
  img {
    margin-right: 8px;
  }
`;

const ShowLocationEnabled = styled.div`
  color: ${PALETTE_COLOR.BLACK};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const ShowLocationDisabled = styled.div`
  color: ${PALETTE_COLOR.INACTIVE_GREY_BLUE};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

/***
 * According to user shift info and user, get img status
 */
const getShiftStatusIco = (shift, user) => {
  switch (shift.status?.value_definition) {
    case '020':
      return <StatusWrapper src={status_draft} />;
    case '030':
      return <StatusWrapper src={ic_pending_approval} />;
    default:
      break;
  }

  if (user && user.id) {
    //Get shift
    const userShift = shift.user_shifts.filter((us) => us.user.id === user.id).map((us) => us)[0];
    //Get status
    const statusValue = userShift.status?.value_definition;
    //Get event running late (if applies)
    const runningLate = userShift.userShiftEvents.find(
      (usE) => usE.type.value_definition === '050',
    );
    //Get event no-show (if applies)
    const noShow = userShift.userShiftEvents.find((usE) => usE.type.value_definition === '070');
    //Get cancelation request (if applies)
    const cancelationRequest = userShift.user_shifts_requests.find(
      (usCR) => usCR.status.value_definition === '010',
    );
    //Invited
    if (statusValue === '020') {
      return <StatusWrapper src={ic_assigned} />;
      //Pending for approval
    } else if (statusValue === '030') {
      return <StatusWrapper src={ic_pending_approval} />;
      //Employee accpeted invitation
    } else if (statusValue === '040') {
      if (userShift.inProgress === true) {
        return <StatusWrapper src={ic_clock_in} />;
      } else if (
        userShift.inProgress === false &&
        userShift.sign_time === null &&
        userShift.signer_id === null
      ) {
        return <StatusWrapper src={ic_unsiged_hours} />;
      } else if (
        userShift.inProgress === false &&
        userShift.sign_time !== null &&
        userShift.signer_id !== null
      ) {
        return <StatusWrapper src={ic_completed_and_signed} />;
      } else if (cancelationRequest) {
        return <StatusWrapper src={ic_cancellation_request} />;
      } else if (noShow) {
        return <StatusWrapper src={ic_no_show} />;
      } else if (runningLate) {
        return <StatusWrapper src={ic_running_late} />;
      } else {
        return <StatusWrapper src={ic_claimed} />;
      }
    } else if (statusValue === '090') {
      return <StatusWrapper src={ic_canceled} />;
    } else {
      return;
    }
    /*switch (status?.value_definition) {
      case '020':
        return <StatusWrapper src={ic_assigned} />; //Invited
      case '030':
        return <StatusWrapper src={ic_pending_approval} />;
      case '040':
        return <StatusWrapper src={ic_claimed} />; //Employee accpeted invitation
      case '060':
        return <StatusWrapper src={ic_rejected} />; //Seems
      default:
        break;
    }*/
  }
};

const userShiftstatus = (userShift) => {
  if (userShift) {
    switch (userShift.status?.value_definition) {
      case '030':
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              backgroundColor: chroma('#FFC656').alpha(0.1),
            }}
          >
            <EmployeeStatusWrapper src={ic_pending_approval} />
            <EmployeeStatusLabel>
              {i18next.t(`Users.status.${userShift.status.description}`)}
            </EmployeeStatusLabel>
          </div>
        );
      default:
        break;
    }
  }
};

function employeesOnShift(shift) {
  if (shift.status?.value_definition === '010') {
    if (shift.user_shifts.length <= 0 && shift.open_spots > 1) {
      MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
      return shift.open_spots;
    }
  }
}

const obtainUserShiftEmployee = (shift, user) => {
  const response =
    shift.user_shifts &&
    shift.user_shifts.length > 0 &&
    shift.user_shifts.filter((us) => us.user.id === user.id);
  return response.length > 0 ? response[0] : null;
};

const removeOrgModalContent = {
  buttonText: `${i18next.t('buttons.btn_discard')}`,
  actionIcon: ico_Archived,
  title: `${i18next.t('JobScheduler.modals.discard_shift_title')}`,
  subtitle: `${i18next.t('JobScheduler.modals.delete_shift_draft_subtitle')}`,
  bgcolor: PALETTE_COLOR.ACCENT_RED,
  bordercolor: PALETTE_COLOR.ACCENT_RED,
  borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

let cancelModalContent = {
  buttonText: `${i18next.t('buttons.btn_confirm')}`,
  actionIcon: ico_Archived,
  title: `${i18next.t('JobScheduler.modals.cancel_shift_title')}`,
  subtitle: '',
  bgcolor: PALETTE_COLOR.ACCENT_RED,
  bordercolor: PALETTE_COLOR.ACCENT_RED,
  borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const CANCELLATION_FEE_MSG = `${i18next.t('JobScheduler.modals.cancel_shift_subtitle_fee')}`;
const CANCELLATION_NO_FEE_MSG = `${i18next.t('JobScheduler.modals.cancel_shift_subtitle_no_fee')}`;
const SPOT_DISCARD = `${i18next.t('JobScheduler.modals.discard_spot_subtitle')}`;
const SHIFT_DISCARD = `${i18next.t('JobScheduler.modals.discard_shift_subtitle')}`;

export const GlobalView = ({
  shift,
  user,
  handleclose,
  handleClickOpenCreateSnglShiftModal,
  setOpenShiftDetail,
}) => {
  const mapCenter = { lat: parseFloat(shift.site.latitude), lng: parseFloat(shift.site.longitude) };
  const googleMapsApiKey = appConfig.google.REACT_GOOGLE_MAP_KEY;
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loadingCancelShift, setLoadingCancelShift] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userData, valuesDefinitions } = useSelector((state) => state.app);
  const [isAbleToEdit, setIsAbleToEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //Store shift status to future features
  const [shiftStatus, setShiftStatus] = useState({
    iconName: null,
    statusName: null,
    statusBackground: null,
    fontColor: null,
    code: null, // This code does not represent BDD code, it is only a reference to this page logic
  });
  const [employeeLatitude, setEmployeeLatitude] = React.useState(0);
  const [employeeLongitude, setEmployeeLongitude] = React.useState(0);
  const [mapAccordionOpened, setMapAccordionOpened] = React.useState(false);
  const [openShiftDetailMap, setOpenShiftDetailMap] = React.useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const SYSTEM_ROLE = 'system';
  const ORG_ROLE = 'organizations';
  const CASALIMPIA_ADMIN_ROLE = 'admin';
  const cognitoRole = userData.cognitoUser.idToken?.payload['cognito:groups'][0];
  const fullAccessPermission =
    valuesDefinitions &&
    valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'USERS_GROUPS_PERMISSION' &&
        v.value_definition === '020',
    )[0];

  /***
   * Load User permissions to perfom
   * edit, remove shift actions
   */
  useEffect(() => {
    const valid = () => {
      if (userData) {
        if (cognitoRole === ORG_ROLE) {
          if (userData?.role.value_definition === '020') {
            //Site manager
            setIsAbleToEdit(
              userData.permission?.value_definition === fullAccessPermission?.value_definition,
            );
          } else if (userData?.role.value_definition === '010') {
            //Org representative
            setIsAbleToEdit(
              userData.permission?.value_definition === fullAccessPermission?.value_definition,
            );
          }
        } else if (cognitoRole === CASALIMPIA_ADMIN_ROLE || cognitoRole === SYSTEM_ROLE) {
          setIsAbleToEdit(true);
        }
      }
    };

    valid();
  }, [userData, cognitoRole, fullAccessPermission?.value_definition]);

  useEffect(() => {
    /***
     * Store shift status to be easily handled
     */
    const getStoreShiftStatus = () => {
      switch (shift.status?.value_definition) {
        case '020':
          return setShiftStatus({
            iconName: status_draft,
            statusName: `${i18next.t('JobScheduler.shift_status.Draft')}`,
            statusBackground: PALETTE_COLOR.TOP_GRAY,
            fontColor: PALETTE_COLOR.TOP_GRAY,
            code: '1',
          });
        case '030':
          return setShiftStatus({
            iconName: ic_pending_approval,
            statusName: `${i18next.t('JobScheduler.shift_status.Pending_Request')}`,
            statusBackground: PALETTE_COLOR.PENDING_FOR_APROVAL_STATUS_SHIFT_BACKGROUND,
            fontColor: PALETTE_COLOR.TOP_GRAY,
            code: '3',
          });
        default:
          break;
      }

      if (user && user.id) {
        //Get shift
        const userShift = shift.user_shifts
          .filter((us) => us.user.id === user.id)
          .map((us) => us)[0];
        //Get status
        const statusValue = userShift.status?.value_definition;
        //Get event running late (if applies)
        const runningLate = userShift.userShiftEvents.find(
          (usE) => usE.type.value_definition === '050',
        );
        //Get event no-show (if applies)
        const noShow = userShift.userShiftEvents.find((usE) => usE.type.value_definition === '070');
        //Get cancelation request (if applies)
        const cancelationRequest = userShift.user_shifts_requests.find(
          (usCR) => usCR.status.value_definition === '010',
        );
        //Invited
        if (statusValue === '020') {
          setShiftStatus({
            iconName: ic_assigned,
            statusName: `${i18next.t('JobScheduler.shift_status.Assigned')}`,
            statusBackground: PALETTE_COLOR.ASSIGNED_STATUS_SHIFT_BACKGROUND,
            fontColor: PALETTE_COLOR.ASSIGNED_STATUS_SHIFT_FONT_COLOR,
            code: '2',
          });
          //Pending for approval
        } else if (statusValue === '030') {
          setShiftStatus({
            iconName: ic_pending_approval,
            statusName: `${i18next.t('JobScheduler.shift_status.Pending_Request')}`,
            statusBackground: PALETTE_COLOR.PENDING_FOR_APROVAL_STATUS_SHIFT_BACKGROUND,
            fontColor: PALETTE_COLOR.PENDING_FOR_APROVAL_STATUS_SHIFT_FONT_COLOR,
            code: '3',
          });
          //Employee accpeted invitation
        } else if (statusValue === '040') {
          if (userShift.inProgress === true) {
            setShiftStatus({
              iconName: ic_clock_in,
              statusName: `${i18next.t('JobScheduler.shift_status.Clocked_In')}`,
              statusBackground: PALETTE_COLOR.CLOCKED_IN_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.CLOCKED_IN_STATUS_SHIFT_FONT_COLOR,
              code: '41',
            });
          } else if (
            userShift.inProgress === false &&
            userShift.sign_time === null &&
            userShift.signer_id === null
          ) {
            setShiftStatus({
              iconName: ic_unsiged_hours,
              statusName: `${i18next.t('JobScheduler.shift_status.Unsigned_Hours')}`,
              statusBackground: PALETTE_COLOR.UNSIGNED_HOURS_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.UNSIGNED_HOURS_STATUS_SHIFT_FONT_COLOR,
              code: '42',
            });
          } else if (
            userShift.inProgress === false &&
            userShift.sign_time !== null &&
            userShift.signer_id !== null
          ) {
            setShiftStatus({
              iconName: ic_completed_and_signed,
              statusName: `${i18next.t('JobScheduler.shift_status.Completed_And_Signed')}`,
              statusBackground: PALETTE_COLOR.COMPLETED_SIGNED_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.COMPLETED_SIGNED_STATUS_SHIFT_FONT_COLOR,
              code: '43',
            });
          } else if (cancelationRequest) {
            setShiftStatus({
              iconName: ic_cancellation_request,
              statusName: `${i18next.t('JobScheduler.shift_status.Cancellation_Request')}`,
              statusBackground: PALETTE_COLOR.CANCELLATION_REQUEST_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.CANCELLATION_REQUEST_STATUS_SHIFT_FONT_COLOR,
              code: '44',
            });
          } else if (noShow) {
            setShiftStatus({
              iconName: ic_no_show,
              statusName: `${i18next.t('JobScheduler.shift_status.No_Show')}`,
              statusBackground: PALETTE_COLOR.NO_SHOW_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.NO_SHOW_STATUS_SHIFT_FONT_COLOR,
              code: '45',
            });
          } else if (runningLate) {
            setShiftStatus({
              iconName: ic_running_late,
              statusName: `${i18next.t('JobScheduler.shift_status.Running_Late')}`,
              statusBackground: PALETTE_COLOR.RUNNING_LATE_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.RUNNING_LATE_STATUS_SHIFT_FONT_COLOR,
              code: '46',
            });
          } else {
            setShiftStatus({
              iconName: ic_claimed,
              statusName: `${i18next.t('JobScheduler.shift_status.Covered')}`,
              statusBackground: PALETTE_COLOR.COVERED_STATUS_SHIFT_BACKGROUND,
              fontColor: PALETTE_COLOR.COVERED_STATUS_SHIFT_FONT_COLOR,
              code: '47',
            });
          }
        } else if (statusValue === '090') {
          setShiftStatus({
            iconName: ic_canceled,
            statusName: `${i18next.t('JobScheduler.shift_status.Canceled')}`,
            statusBackground: PALETTE_COLOR.CANCELED_STATUS_SHIFT_BACKGROUND,
            fontColor: PALETTE_COLOR.CANCELED_STATUS_SHIFT_FONT_COLOR,
            code: '9',
          });
          return <StatusWrapper src={ic_canceled} />;
        } else {
          return;
        }
      } else {
        setShiftStatus({
          iconName: ic_published,
          statusName: `${i18next.t('JobScheduler.shift_status.Published')}`,
          statusBackground: PALETTE_COLOR.PUBLISHED_STATUS_SHIFT_BACKGROUND,
          fontColor: PALETTE_COLOR.PUBLISHED_STATUS_SHIFT_FONT_COLOR,
          code: '5',
        });
      }
    };
    getStoreShiftStatus();
  }, [shift, user]);

  /***
   * Show sucess or error message
   */
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Open/close modal
   */
  const handleRemoveModal = (action) => {
    setErrorMessage('');
    if (action && action === DISCARD_SHIFT) {
      removeOrgModalContent.title = `${i18next.t('JobScheduler.modals.discard_shift_title')} ${
        shift.open_spots > 1 ? ' • ' + shift.open_spots : ''
      }`;
      removeOrgModalContent.subtitle = SHIFT_DISCARD;
      removeOrgModalContent.action = DISCARD_SHIFT;
    } else {
      removeOrgModalContent.title = `${i18next.t('JobScheduler.modals.discard_shift_title')}`;
      removeOrgModalContent.subtitle = SPOT_DISCARD;
      removeOrgModalContent.action = DISCARD_SPOT;
    }
    setOpenRemoveModal(!openRemoveModal);
  };

  /***
   * Close cancel modal
   */
  const handleCloseCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  /***
   * Open cancel modal
   */
  const handleCancelModal = () => {
    setLoadingCancelShift(true);
    getShiftTimezone()
      .then((timeZone) => {
        //Calc if cancel action will include penalty
        isCancellationFee(timeZone);
        setLoadingCancelShift(false);
        setOpenCancelModal(!openCancelModal);
      })
      .catch((err) => {
        setLoadingCancelShift(false);
      });
  };

  const getShiftTimezone = () => {
    return new Promise(async (resolve, reject) => {
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'timeZone' })
        .setJoin({ field: 'site' })
        .setJoin({ field: 'group' });
      getEntityData(`${SHIFTS_API}${shift.id}`, qb.query())
        .then((res) => {
          //Include group and site block into shift info, this is required to cancel a shift
          shift.group = res.group;
          shift.site = res.site;
          setLoading(false);
          resolve(res.timeZone);
        })
        .catch((err) => {
          console.log('getShiftTimezone error = ', err);
        });
    });
  };

  const isCancellationFee = (timeZone) => {
    const currentTimeZonedTime = moment().tz(timeZone.validation_type);
    const currentTZnoConversion = moment()
      .utc()
      .set({
        day: currentTimeZonedTime.get('day'),
        month: currentTimeZonedTime.get('month'),
        year: currentTimeZonedTime.get('year'),
        hour: currentTimeZonedTime.get('hour'),
        minute: currentTimeZonedTime.get('minutes'),
      });
    const diff = moment(shift.start_time).utc().diff(currentTZnoConversion, 'hours', true);
    if (currentTZnoConversion.isBefore(moment(shift.start_time).utc()) && diff > 2) {
      cancelModalContent.subtitle = CANCELLATION_NO_FEE_MSG;
    } else {
      cancelModalContent.subtitle = CANCELLATION_FEE_MSG;
    }
  };

  /***
   * Reload shceduler if necessary
   */
  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Delete a entire shift
   */
  const deleteShift = () => {
    setErrorMessage('');
    setLoading(true);
    deleteEntityData(`${SHIFTS_API}${shift.id}`)
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${i18next.t('success_messages.shift_deleted')}`,
          message: '',
        });
        handleclose(shift);
        handleRemoveModal();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let mess = '';

        switch (err.response?.data?.statusCode) {
          case 403:
            mess = `${i18next.t('error_messages.error_shifts_permissions')}`;
            break;
          case 400.1:
          case 400.2:
            mess = err.response?.data?.message;
            break;
          default:
            mess = `${i18next.t('error_messages.error_shift_discarding')}`;
            break;
        }
        setErrorMessage(mess);
        /* showMessage({
          show: true,
          type: 'error',
          title: mess,
          message: '',
        });*/
        console.log('Deleting shift error = ', err);
      });
  };

  const discardSpot = () => {
    setLoading(true);
    const userShift = shift.user_shifts.filter((us) => us.user.id === user.id).map((us) => us)[0];
    deleteEntityData(`${USER_SHIFTS_API}discard/spot/${userShift.id}/${userData.sub_cognito_id}`)
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${i18next.t('success_messages.shift_discarded')}`,
          message: '',
        });
        handleclose(shift);
        handleRemoveModal();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let mess = '';

        switch (err.response?.data?.statusCode) {
          case 403:
            mess = `${i18next.t('error_messages.error_shifts_permissions')}`;
            break;
          case 400.1:
          case 400.2:
            mess = err.response?.data?.message;
            break;
          default:
            mess = `${i18next.t('error_messages.error_shift_discarding')}`;
            break;
        }

        showMessage({
          show: true,
          type: 'error',
          title: mess,
          message: '',
        });
        console.log('shifts deleting error = ', err);
      });
  };

  const cancelShift = () => {
    setLoading(true);
    //Remove record from user shifts, in this way record is going to be cancelled
    const userShift = shift.user_shifts.filter((us) => us.user.id === user.id).map((us) => us)[0];

    deleteEntityData(`${USER_SHIFTS_API}discard/spot/${userShift.id}/${userData.sub_cognito_id}`)
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${i18next.t('success_messages.shift_canceled')}`,
          message: '',
        });
        handleclose(shift);
        handleCloseCancelModal();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let mess = '';

        switch (err.response?.data?.statusCode) {
          case 403:
            mess = `${i18next.t('error_messages.error_shifts_permissions')}`;
            break;
          case 400.1:
          case 400.2:
            mess = err.response?.data?.message;
            break;
          default:
            mess = `${i18next.t('error_messages.error_shift_canceling')}`;
            break;
        }

        showMessage({
          show: true,
          type: 'error',
          title: mess,
          message: '',
        });
        console.log('shifts deleting error = ', err);
      });
  };

  const getEmployeeLocation = async (idUserShift) => {
    try {
      const qb = RequestQueryBuilder.create()
        .setJoin({
          field: 'userShift',
        })
        .setFilter({ field: 'userShift.id', operator: CondOperator.EQUALS, value: idUserShift })
        .setLimit(1);

      getEntityData(`${USER_LOCATIONS_API}`, qb.query()).then((res) => {
        if (res && res.length > 0) {
          setEmployeeLatitude(parseFloat(res[0].currentLatitude));
          setEmployeeLongitude(parseFloat(res[0].currentLongitude));
        }
      });
    } catch (error) {
      console.log('📌 - file: index.js:842 - getEmployeeLocation - error:', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (shiftStatus.statusName) {
          await getEmployeeLocation(shift.user_shifts[0].id);
        }
      } catch (error) {
        console.log('📌 - file: UsersList.js:227 - error:', error);
      }
    })();
  }, [shift, shiftStatus]);

  return (
    <>
      <CustomList>
        <Separator />
        <CustomListItem>
          <div style={{ display: 'inline' }}>
            <img src={ic_document_minus} alt="ic_document_minus" />
            <div style={{ display: 'inline', marginLeft: 10 }}> {shift?.title}</div>
            <StatusImg
              backgroundColor={shiftStatus.statusBackground}
              fontColor={shiftStatus.fontColor}
            >
              <img src={shiftStatus.iconName} alt={shiftStatus.statusName} />
              {shiftStatus.statusName}
            </StatusImg>
          </div>
        </CustomListItem>
        <Separator />
        <CustomListItem style={{ cursor: 'pointer' }}>
          {employeeLatitude === 0 && employeeLongitude === 0 ? (
            <>
              <img src={ic_navigation_disabled} alt="ic_navigation" />
              <ShowLocationDisabled>{i18next.t('form_labels.navigation')}</ShowLocationDisabled>
              <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'end', flex: 1, paddingRight: 1 }}>
                <img src={ic_location_disabled} alt="ic_location_disabled" />
              </div>
            </>
          ) : (
            // <Accordion>
            //   <AccordionItem header={
            //     <>
            //       <img onClick={() => setMapAccordionOpened(!mapAccordionOpened)} src={ic_navigation_enabled} alt="ic_navigation" />
            //       <ShowLocationEnabled onClick={() => setMapAccordionOpened(!mapAccordionOpened)}>{i18next.t('form_labels.navigation')}</ShowLocationEnabled>
            //       <div onClick={() => setMapAccordionOpened(!mapAccordionOpened)} style={{ display: 'flex', justifyContent: 'right', alignItems: 'end', flex: 1, paddingRight: 1 }}>
            //         <img src={mapAccordionOpened ? ic_location_enabled : ic_location_enabled} alt="ic_location_enabled" />
            //       </div>
            //     </>}
            //   >
            //     {mapAccordionOpened && (
            //       <MovingMarkerMap
            //         center={mapCenter}
            //         apiKey={googleMapsApiKey}
            //         idUserShift={shift.user_shifts[0].id}
            //         initial={{ lat: parseFloat(employeeLatitude), lng: parseFloat(employeeLongitude) }}
            //       />
            //     )}
            //   </AccordionItem>
            // </Accordion>
            <>
              <img onClick={() => setOpenShiftDetailMap(!mapAccordionOpened)} src={ic_navigation_enabled} alt="ic_navigation" />
              <ShowLocationEnabled onClick={() => setOpenShiftDetailMap(!mapAccordionOpened)}>{i18next.t('form_labels.navigation')}</ShowLocationEnabled>
              <div onClick={() => setOpenShiftDetailMap(!mapAccordionOpened)} style={{ display: 'flex', justifyContent: 'right', alignItems: 'end', flex: 1, paddingRight: 1 }}>
                <img src={mapAccordionOpened ? ic_location_enabled : ic_location_enabled} alt="ic_location_enabled" />
              </div>
            </>
          )}
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={personalcard} alt="personalcard" />
          <div>{i18next.t('form_labels.job_title')}</div>
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'end', flex: 1 }}>
            {shift.jobs_titles?.length > 0 ? (
              shift.jobs_titles?.map((item, index) => (
                <>
                  {index > 0 ? ', ' : ''}
                  {item.jobtitle.description}
                </>
              ))
            ) : (
              <>{shift.job_title?.description}</>
            )}
          </div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={time_circle} alt="time_circle" />
          <div>{ShiftTimes(shift)}</div>
          <div
            style={{ position: 'absolute', right: 30, color: '#6D737B' }}
          >{`${ShiftFullTimeDifferences(shift)} ${
            Number(ShiftFullTimeDifferences(shift)) !== 1
              ? i18next.t('form_labels.hours').toLowerCase()
              : i18next.t('form_labels.hour').toLowerCase()
          }`}</div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={calender2} alt="calender2" />
          <div>{moment.utc(shift.shift_date).format('ddd, MMMM D, YYYY')}</div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={Location} alt="Location" />
          <div>{shift.location} </div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={Work} alt="Work" />
          <div>{shift.organization ? shift.organization.name : ''}</div>
          <div style={{ position: 'absolute', right: 30 }}>
            <AppOrgIcon
              style={{ width: 32, height: 32 }}
              color={shift.organization && shift.organization.color}
            />
          </div>
        </CustomListItem>

        {shiftStatus.code === '5' && (
          <div>
            <Separator />
            <CustomListItem style={{ marginTop: '16px' }}>
              <img src={group} alt="group" />
              {shift.user_shifts &&
              shift.user_shifts.filter(
                (us) =>
                  us.status.value_definition !== '050' && us.status.value_definition !== '060',
              ).length > 0
                ? shift.user_shifts
                    .filter(
                      (us) =>
                        us.status.value_definition !== '050' &&
                        us.status.value_definition !== '060',
                    )
                    .map((us) => (
                      <Tooltip title={us.user.name}>
                        <CustomListUsersAvatar>{calcAvatar(us.user, 32, 32)}</CustomListUsersAvatar>
                      </Tooltip>
                    ))
                : ''}
              {shift.open_spots -
                shift.user_shifts.filter(
                  (us) =>
                    us.status.value_definition !== '050' && us.status.value_definition !== '060',
                ).length >
              0 ? (
                <CustomListUsersAvatar>
                  <AvatarEmpy>
                    {shift.open_spots -
                      shift.user_shifts.filter(
                        (us) =>
                          us.status.value_definition !== '050' &&
                          us.status.value_definition !== '060',
                      ).length}
                  </AvatarEmpy>
                </CustomListUsersAvatar>
              ) : (
                ''
              )}
            </CustomListItem>
          </div>
        )}
        {isAbleToEdit &&
        (shiftStatus.code === '5' ||
          shiftStatus.code === '47' ||
          shiftStatus.code === '2' ||
          shiftStatus.code === '46') ? (
          // Published || Covered || Assigned || Running late
          <>
            <Separator />
            <CustomListItem>
              {(shiftStatus.code === '5' ||
                shiftStatus.code === '47' ||
                shiftStatus.code === '2') && (
                // Published|| Covered || Assigned
                <AppButton
                  style={{ marginBottom: '16px', fontSize: '14px' }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  border-color
                  size="lg"
                  type="submit"
                  title={i18next.t('buttons.btn_edit')}
                  onClick={() => {
                    setOpenShiftDetail(false);
                    handleClickOpenCreateSnglShiftModal(SHIFT_EDIT, shift, false);
                  }}
                  loading={loadingCancelShift}
                >
                  {i18next.t('buttons.btn_edit')}
                </AppButton>
              )}
              {shiftStatus.code === '5' && ( // Published
                <AppButton
                  style={{ marginBottom: '16px', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.ACCENT_RED}
                  color={PALETTE_COLOR.ACCENT_RED}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.MAIN_WHITE}
                  onClick={() => handleRemoveModal(DISCARD_SHIFT)}
                  title={i18next.t('buttons.btn_discard')}
                >
                  {' '}
                  {`${i18next.t('buttons.btn_discard')} ${
                    shift.open_spots > 1 ? ' • ' + shift.open_spots : ''
                  }`}
                </AppButton>
              )}
              {shiftStatus.code === '47' && ( //Covered
                <AppButton
                  style={{ marginBottom: '16px', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.ACCENT_RED}
                  color={PALETTE_COLOR.ACCENT_RED}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.MAIN_WHITE}
                  onClick={() => handleCancelModal(SHIFT_REMOVE)}
                  title={i18next.t('buttons.btn_cancel_shift')}
                  loading={loadingCancelShift}
                >
                  {i18next.t('buttons.btn_cancel_shift')}
                </AppButton>
              )}
              {(shiftStatus.code === '2' || shiftStatus.code === '46') && (
                // Assigned || Running late
                <AppButton
                  style={{ marginBottom: '16px', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.ACCENT_RED}
                  color={PALETTE_COLOR.ACCENT_RED}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.MAIN_WHITE}
                  onClick={() => handleRemoveModal(SHIFT_REMOVE)}
                  title={i18next.t('buttons.btn_discard_shift')}
                >
                  {i18next.t('buttons.btn_discard_shift')}
                </AppButton>
              )}
            </CustomListItem>
          </>
        ) : (
          <div style={{ marginBottom: '10px' }}></div>
        )}
      </CustomList>

      <AppModal
        open={openRemoveModal}
        handleclose={handleRemoveModal}
        title={''}
        boxshadow={'none'}
        inside={1}
        styleModal={{ maxWidth: '635px' }}
      >
        <AppModalStatus
          actionIcon={removeOrgModalContent.actionIcon}
          title={removeOrgModalContent.title}
          subtitle={
            <>
              <p style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                {shift.title}
              </p>
              <p style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                {removeOrgModalContent.subtitle}
              </p>
            </>
          }
          bgcolor={removeOrgModalContent.bgcolor}
          bordercolor={removeOrgModalContent.bordercolor}
          borderhovercolor={removeOrgModalContent.borderhovercolor}
          bghovercolor={removeOrgModalContent.bghovercolor}
          boxShadow={removeOrgModalContent.boxShadow}
          buttonText={removeOrgModalContent.buttonText}
          isLoading={loading}
          errorMessage={errorMessage}
          buttonAction={() =>
            removeOrgModalContent.action === DISCARD_SHIFT ? deleteShift() : discardSpot()
          }
        />
      </AppModal>

      <AppModal
        open={openCancelModal}
        handleclose={handleCloseCancelModal}
        title={''}
        boxshadow={'none'}
        inside={1}
        width={'635px'}
        styleModal={{ maxWidth: '635px' }}
      >
        <AppModalStatus
          actionIcon={cancelModalContent.actionIcon}
          title={cancelModalContent.title}
          subtitle={
            <>
              <p style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                {shift.title}
              </p>
              <div style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                {cancelModalContent.subtitle}
              </div>
            </>
          }
          bgcolor={cancelModalContent.bgcolor}
          bordercolor={cancelModalContent.bordercolor}
          borderhovercolor={cancelModalContent.borderhovercolor}
          bghovercolor={cancelModalContent.bghovercolor}
          boxShadow={cancelModalContent.boxShadow}
          buttonText={cancelModalContent.buttonText}
          isLoading={loading}
          buttonAction={cancelShift}
        />
      </AppModal>

      {/* Modal which shows map user shift location */}
      <AppModal
        open={openShiftDetailMap}
        handleclose={() => setOpenShiftDetailMap(false)}
        title={
          user && user.name
            ? `${i18next.t('form_labels.navigation')} - ${user.name}`
            : `${i18next.t('form_labels.navigation')}`
        }
        inside={1}
        width={`${width - 50}px`}
        height={`${height - 50}px`}
        styleModal={{ maxWidth: `${width - 50}px` }}
      >
        {openShiftDetailMap && (
          <MovingMarkerMap
            center={mapCenter}
            apiKey={googleMapsApiKey}
            idUserShift={shift.user_shifts[0].id}
            initial={{ lat: parseFloat(employeeLatitude), lng: parseFloat(employeeLongitude) }}
            height={height - 150}
            zoom={14}
          />
        )}
      </AppModal>
    </>
  );
};

const confirmAccept = {
  buttonText: `${i18next.t('buttons.btn_approve')}`,
  actionIcon: ico_check,
  title: `${i18next.t('JobScheduler.modals.approve_shift_title')}`,
  subtitle: ``,
  bgcolor: PALETTE_COLOR.ACCENT_GREEN,
  bordercolor: PALETTE_COLOR.ACCENT_GREEN,
  borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const confirmDecline = {
  buttonText: `${i18next.t('buttons.btn_decline')}`,
  actionIcon: ico_decline,
  title: `${i18next.t('JobScheduler.modals.decline_shift_title')}`,
  subtitle: ``,
  bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
  bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
  borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

/* const confirmCancel = {
  buttonText: 'Discard',
  actionIcon: ico_cancel,
  title: 'Discard Shift',
  subtitle: 'The shift will be removed from the schedule',
  bgcolor: PALETTE_COLOR.ACCENT_RED,
  bordercolor: PALETTE_COLOR.ACCENT_RED,
  borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
}; */

export const EmployeeShiftView = ({
  shift,
  user,
  handleClickOpenCreateSnglShiftModal,
  closeMainModal,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
  const [isApprove, setIsApprove] = React.useState(true);
  const { userData, valuesDefinitions } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const requestStatusApprove = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_SHIFT_STATUS' && v.value_definition === '040',
  )[0]; //Approved
  const requestStatusDecline = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_SHIFT_STATUS' && v.value_definition === '060',
  )[0]; //Approved

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  const handleConfirmationModal = (isApprove) => {
    setIsApprove(isApprove);
    setOpenConfirmationModal((prev) => {
      return !prev;
    });
  };

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Call api to approve or deny request
   */
  const approveDenyRequest = () => {
    //Call api
    setLoading(true);
    if (isApprove === null) {
      deleteEntityData(`${SHIFTS_API}${shift.id}`)
        .then((res) => {
          //Close modal
          setLoading(false);
          handleConfirmationModal(isApprove);
          reloadSchedulerCalendar(true);
          showMessage({
            show: true,
            type: 'success',
            title: `${i18next.t('success_messages.shift_job_scheduler_removed')}`,
            message: '',
          });
          closeMainModal();
        })
        .catch((err) => {
          setLoading(false);
          //Close modal
          handleConfirmationModal(true);
          showMessage({
            show: true,
            type: 'error',
            title: `${i18next.t('error_messages.error_shift_editing')}`,
            message: '',
          });
        });
    } else {
      editEntityData(`${USER_SHIFTS_API}${obtainUserShiftEmployee(shift, user).id}`, {
        status: isApprove ? requestStatusApprove : requestStatusDecline,
      })
        .then((res) => {
          //Close modal
          setLoading(false);
          handleConfirmationModal(isApprove);
          reloadSchedulerCalendar(true);
          showMessage({
            show: true,
            type: 'success',
            title: `${i18next.t('success_messages.shift_edited')}`,
            message: '',
          });
          closeMainModal();
        })
        .catch((err) => {
          setLoading(false);
          //Close modal
          handleConfirmationModal(isApprove);
          showMessage({
            show: true,
            type: 'error',
            title: `${i18next.t('error_messages.error_shift_editing')}`,
            message: '',
          });
        });
    }
  };

  const discardSpot = () => {
    setLoading(true);
    const userShift = shift.user_shifts.filter((us) => us.user.id === user.id).map((us) => us)[0];
    deleteEntityData(`${USER_SHIFTS_API}discard/spot/${userShift.id}/${userData.sub_cognito_id}`)
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${i18next.t('success_messages.shift_discarded')}`,
          message: '',
        });
        closeMainModal();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let mess = '';

        switch (err.response?.data?.statusCode) {
          case 403:
            mess = `${i18next.t('error_messages.error_shifts_permissions')}`;
            break;
          case 400.1:
          case 400.2:
            mess = err.response?.data?.message;
            break;
          default:
            mess = `${i18next.t('error_messages.error_shift_discarding')}`;
            break;
        }

        showMessage({
          show: true,
          type: 'error',
          title: mess,
          message: '',
        });
        console.log('shifts deleting error = ', err);
      });
  };

  return (
    <>
      <CustomList>
        <Separator />
        <CustomListItem>
          <img src={ic_document_minus} alt="ic_document_minus" />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div>{shift.title}</div>
            <div>{userShiftstatus(obtainUserShiftEmployee(shift, user))}</div>
          </div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={time_circle} alt="time_circle" />
          <div>
            {moment.utc(shift.start_time).format('h')}
            {moment.utc(shift.start_time).format('a')} - {moment.utc(shift.end_time).format('h')}
            {moment.utc(shift.end_time).format('a')}
          </div>
          <div
            style={{ position: 'absolute', right: 30, color: '#6D737B' }}
          >{`${ShiftFullTimeDifferences(shift)} ${i18next
            .t('form_labels.hours')
            .toLowerCase()}`}</div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={calender2} alt="calender2" />
          <div>{moment.utc(shift.shift_date).format('ddd, MMMM D, YYYY')}</div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={Location} alt="Location" />
          <div>{shift.location} </div>
        </CustomListItem>
        <Separator />
        <CustomListItem>
          <img src={Work} alt="Work" />
          <div>{shift.organization ? shift.organization.name : ''}</div>
          <AppOrgIcon color={shift.organization && shift.organization.color} />
        </CustomListItem>
        <Separator />
        <CustomListItem style={{ marginBottom: 15 }}>
          <AppButton
            loading={loading}
            style={{
              fontSize: 14,
            }}
            color={PALETTE_COLOR.MAIN_WHITE}
            bgcolor={PALETTE_COLOR.MAIN_GREEN}
            bordercolor={PALETTE_COLOR.MAIN_GREEN}
            bghovercolor={PALETTE_COLOR.MAIN_GREEN}
            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            size="lg"
            type="submit"
            title={i18next.t('buttons.btn_approve')}
            onClick={() => {
              handleConfirmationModal(true);
            }}
          >
            {i18next.t('buttons.btn_approve')}
          </AppButton>
          <AppButton
            loading={loading}
            style={{
              fontSize: 14,
            }}
            color={PALETTE_COLOR.MAIN_WHITE}
            bgcolor={PALETTE_COLOR.ACCENT_ORANGE}
            bordercolor={PALETTE_COLOR.ACCENT_ORANGE}
            bghovercolor={PALETTE_COLOR.ACCENT_ORANGE}
            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            size="lg"
            type="submit"
            title={i18next.t('buttons.btn_decline')}
            onClick={() => {
              handleConfirmationModal(false);
            }}
          >
            {i18next.t('buttons.btn_decline')}
          </AppButton>
          <AppButton
            loading={loading}
            style={{
              width: 40,
              display: 'unset',
              flexDirection: 'unset',
              paddingLeft: 7,
              marginLeft: 16,
            }}
            bgcolor={PALETTE_COLOR.MAIN_WHITE}
            bghovercolor={PALETTE_COLOR.MAIN_WHITE}
            color={PALETTE_COLOR.MAIN_BLUE}
            bordercolor={PALETTE_COLOR.TOP_GRAY}
            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            title={i18next.t('buttons.btn_discard')}
            icon={trash}
            onClick={() => {
              handleConfirmationModal(null);
            }}
          ></AppButton>
        </CustomListItem>
      </CustomList>

      {/* Aprove modal */}
      <AppModal
        open={openConfirmationModal}
        handleclose={handleConfirmationModal}
        title={''}
        boxshadow={'none'}
        inside={1}
        styleModal={{ maxWidth: '635px' }}
      >
        {isApprove === null ? (
          <AppModalStatus
            actionIcon={removeOrgModalContent.actionIcon}
            title={removeOrgModalContent.title}
            subtitle={
              <>
                <p style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                  {shift.title}
                </p>
                <p style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                  {i18next.t('JobScheduler.modals.discard_shift_subtitle')}
                </p>
              </>
            }
            bgcolor={removeOrgModalContent.bgcolor}
            bordercolor={removeOrgModalContent.bordercolor}
            borderhovercolor={removeOrgModalContent.borderhovercolor}
            bghovercolor={removeOrgModalContent.bghovercolor}
            boxShadow={removeOrgModalContent.boxShadow}
            buttonText={removeOrgModalContent.buttonText}
            isLoading={loading}
            buttonAction={discardSpot}
          />
        ) : isApprove ? (
          <AppModalStatus
            actionIcon={confirmAccept.actionIcon}
            title={confirmAccept.title}
            subtitle={
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                  {shift.title}
                </div>
                <div>
                  {`${i18next.t('JobScheduler.modals.approve_shift_subtitle')} `}
                  <span style={{ fontWeight: 'bold' }}>{`${user.name}`}</span>
                </div>
              </div>
            }
            bgcolor={confirmAccept.bgcolor}
            bordercolor={confirmAccept.bordercolor}
            borderhovercolor={confirmAccept.borderhovercolor}
            bghovercolor={confirmAccept.bghovercolor}
            boxShadow={confirmAccept.boxShadow}
            buttonText={confirmAccept.buttonText}
            subject={''}
            isLoading={loading}
            buttonAction={approveDenyRequest}
          />
        ) : (
          <AppModalStatus
            actionIcon={confirmDecline.actionIcon}
            title={confirmDecline.title}
            subtitle={
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                  {shift.title}
                </div>
                <div>{i18next.t('JobScheduler.modals.decline_shift_subtitle')}</div>
              </div>
            }
            bgcolor={confirmDecline.bgcolor}
            bordercolor={confirmDecline.bordercolor}
            borderhovercolor={confirmDecline.borderhovercolor}
            bghovercolor={confirmDecline.bghovercolor}
            boxShadow={confirmDecline.boxShadow}
            buttonText={confirmDecline.buttonText}
            subject={''}
            isLoading={loading}
            buttonAction={approveDenyRequest}
          />
        )}
      </AppModal>
    </>
  );
};

export const ShiftView = ({
  shift,
  user,
  data,
  handleClickOpenCreateSnglShiftModal,
  width,
  marginLeft,
  viewType,
  ...others
}) => {
  const [openShiftDetail, setOpenShiftDetail] = React.useState(false);
  const [avataraShiftDetail, setAvataraShiftDetail] = React.useState({});
  const { userData } = useSelector((state) => state.app);
  const [originShift, setOriginShift] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  //If user is not recieved, try to get it from
  //shift.user_shift because calendar by site called to <ShiftView/>
  if (!user) {
    user = shift.user_shifts.length > 0 ? shift.user_shifts[0].user : null;
  }

  const consultAvatar = (subCogId) => {
    try {
      const qb = RequestQueryBuilder.create()
        .setFilter({ field: 'sub_cognito_id', operator: CondOperator.EQUALS, value: subCogId })
        .setLimit(1);

      getEntityData(USERS_API, qb.query()).then((res) => {
        let userData = res[0];
        setAvataraShiftDetail(userData);
      });
    } catch (error) {
      console.error('in userServices > getUserDetails1, Err===', error);
    }
  };

  /***
   * Reload shceduler if necessary
   */
  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Show sucess or error message
   */
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  const handleShiftDetail = (shift) => {
    shift.current = shift;
    consultAvatar(shift.creator_user_id);
    setOpenShiftDetail((prev) => {
      return !prev;
    });
  };

  /***
   * Get info from a specific shift
   */
  const consultShift = (idShift) => {
    try {
      setIsLoading(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'organization' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.jobtitle' })
        .setJoin({ field: 'status' })
        .setJoin({ field: 'site' })
        .setJoin({ field: 'user_shifts.userShiftEvents' })
        .setJoin({ field: 'user_shifts.userShiftEvents.type' })
        .setJoin({ field: 'user_shifts.user_shifts_requests' })
        .setJoin({ field: 'user_shifts.user_shifts_requests.status' })
        .setLimit(1);

      getEntityData(`${SHIFTS_API}${idShift}`, qb.query()).then((res) => {
        //Validates if shift did not change for another user
        if (user === null || user?.id === null) {
          //Shift card from first row
          setOriginShift(res);
          setOpenShiftDetail(true);
          setIsLoading(false);
        } else if (
          res?.user_shifts?.length > 0 &&
          res?.user_shifts?.find((us) => us.user.id === user.id)
        ) {
          setOriginShift(res);
          setOpenShiftDetail(true);
          setIsLoading(false);
        } else {
          //No data, shift or user_shift was deleted
          setIsLoading(false);
          setOpenShiftDetail(false);
          //Reload calendar
          reloadSchedulerCalendar(true);
          //Message
          showMessage({
            show: true,
            type: 'success',
            title: `${i18next.t('success_messages.shift_job_scheduler_status_changed')}`,
            message: '',
          });
        }
      });
    } catch (error) {
      console.error('in userServices > getUserDetails1, Err===', error);
      setIsLoading(false);
    }
  };

  const shiftBackgroundCalculate = (shift, user) => {
    if (
      shift.user_shifts.length > 0 ||
      shift.user_shifts.filter(
        (us) => us.user.id === user.id && us.status && us.status.value_definition === '040',
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  /***
   * Do not show user shifts with status
   * 050 Declined
   * 060 Request declined
   */
  const shiftNotDeclined = (shift, user) => {
    if (
      shift.user_shifts.length > 0 &&
      shift.user_shifts.filter(
        (us) =>
          us.user?.id === user?.id &&
          us.status &&
          (us.status?.value_definition === '050' || us.status?.value_definition === '060'),
      ).length > 0
    ) {
      return false;
    }
    return true;
  };

  if (!shift) return '';

  return shiftNotDeclined(shift, user) ? (
    <>
      <StyledBadge
        badgeContent={employeesOnShift(shift)}
        color="success"
        style={
          width && marginLeft
            ? { width: `${width}px`, marginLeft: `${marginLeft}px` }
            : width
            ? { width: `${width}px` }
            : {}
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ShiftWrapper
          shift={shift}
          shiftBackground={shiftBackgroundCalculate(shift, user)}
          onClick={() =>
            shift.status?.value_definition !== '020'
              ? consultShift(shift.id)
              : handleClickOpenCreateSnglShiftModal(
                  SHIFT_EDIT,
                  shift,
                  shift.status?.value_definition === '020',
                )
          }
        >
          {getShiftStatusIco(shift, user)}
          {isLoading ? (
            <AppComponentLoading width={'100%'} height={'40px'} />
          ) : (
            <>
              <div>
                {ShiftTimes(shift)}
                <span className="float">
                  {`${ShiftFullTimeDifferences({
                    start_time: shift.start_time,
                    end_time: shift.end_time,
                  })}h`}
                </span>
              </div>
              <span className="jobTitle">
                {shift.jobs_titles?.length > 0 ? (
                  shift.jobs_titles?.map((item, index) => (
                    <>
                      {index > 0 ? ', ' : ''}
                      {item.jobtitle.description}
                    </>
                  ))
                ) : (
                  <> {shift.job_title?.description}</>
                )}
              </span>
              <ShiftSubLabelWrapper>
                {viewType === 'View by Employees'
                  ? shift.site
                    ? `${shift.site.name}`
                    : `${i18next.t('status_types.No_Site')}`
                  : shift.user_shifts.length > 0
                  ? shift.user_shifts[0].user.name
                  : ''}
              </ShiftSubLabelWrapper>
            </>
          )}
        </ShiftWrapper>
      </StyledBadge>

      {/* Modal which shows shift info */}
      <AppModal
        open={openShiftDetail}
        handleclose={() => handleShiftDetail(shift)}
        title={
          user && user.name
            ? user.name
            : `${i18next.t('form_labels.spots_left')}: ${
                originShift.open_spots -
                originShift?.user_shifts?.filter((us) => us.status.value_definition !== '050')
                  .length
              }/${originShift.open_spots}`
        }
        subtitle={
          user && user.name
            ? `${i18next.t('form_labels.employee')}`
            : `${i18next.t('form_labels.open_shift')}`
        }
        icon={user ? user : avataraShiftDetail}
        inside={1}
      >
        {user &&
        userData &&
        userData.role &&
        userData.role.value_definition === '020' && //Site manager
        originShift?.user_shifts?.filter((us) => us.status.value_definition !== '050').length > 0 && //No declined status
        obtainUserShiftEmployee(shift, user)?.status?.value_definition === '030' ? ( //Pending for approval
          <EmployeeShiftView
            shift={shift}
            user={user}
            setOpenShiftDetail={setOpenShiftDetail}
            handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
            closeMainModal={() => setOpenShiftDetail(false)}
          />
        ) : (
          <GlobalView
            shift={originShift}
            user={user}
            handleclose={handleShiftDetail}
            setOpenShiftDetail={setOpenShiftDetail}
            handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
          />
        )}
      </AppModal>
    </>
  ) : (
    ''
  );
};
