import React, { useEffect } from 'react';
import { Card, InputGroup } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import avatar from '@assets/icons/ic_no_avatar.svg';
import arrow_left from '@assets/icons/ic_arrow_left_linear.svg';
import arrow_right from '@assets/icons/ic_arrow_right_linear.svg';
import { ChangeAvatarForm } from '@pages/Profile/ChangeAvatar/ChangeAvatarForm';
import { EditProfileImage } from '@components';
import { AppModal, CustomizedSnackbar, AppAvatar, AppButton } from '@components';
import { editEntityData } from '@services';
import { USERS_API } from 'services/CONSTANTS';
import { setAppLoadUserAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const DEF_WIDTH = '600px';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const StyledCard = styled(Card)`
  width: ${(props) => (props.width ? props.width : DEF_WIDTH)};
  height: auto;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin-bottom: 15px;
  padding-right: ${(props) => (props.padding_card ? props.padding_card : 0)};
  padding-left: ${(props) => (props.padding_card ? props.padding_card : 0)};
`;

const StyledCardLabel = styled.div`
  font-size: 24px;
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  display: flex;
  padding: 10px ${(props) => (props.notitlepadding ? 0 : 30)}px !important;
  font-weight: normal !important;
  justify-content: flex-start;
`;

const Icon = styled.img`
  border-radius: 60px;
`;

const StyledCardIcon = styled(InputGroup.Text)`
  border: none;
  padding: 0.375rem 0rem;
  background: unset;
`;

const ActionButton = styled(Button)`
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  :hover,
  :focus {
    border: none;
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

export function InnerAppPanelAvatar(props) {
  const { t } = useTranslation();
  const {
    children,
    icon,
    call,
    withborder = 1,
    showButtons = 1,
    onAvatarChange = null,
    enableRandomAvatar = true,
  } = props;
  const { userData } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const avatarConfig = userData.id
    ? userData.avatar_config
      ? JSON.parse(userData.avatar_config)
      : null
    : null;
  //Old way
  const [openChangeAvatarForm, setOpenChangeAvatarForm] = React.useState(false);
  //New way
  const [changeProfileImage, setChangeProfileImage] = React.useState(false);
  const [searches] = React.useState([]);
  const [contAvatars, setContAvatars] = React.useState(0);
  const [fillAvatars, setFillAvatars] = React.useState(false);
  const genderOptions = ['female', 'male'];
  const avatarIdOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const skinColorOptions = ['#F5D0C5', '#FFCB7E', '#836055'];
  const bgColorOptions = [
    PALETTE_COLOR.AVATAR_LIGHT_BLUE,
    PALETTE_COLOR.AVATAR_PURPLE,
    PALETTE_COLOR.AVATAR_GREEN,
    PALETTE_COLOR.AVATAR_ORANGE,
    PALETTE_COLOR.AVATAR_YELLOW,
    PALETTE_COLOR.AVATAR_PINK,
    PALETTE_COLOR.AVATAR_BLUE,
  ];
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [changeAvatar, setChangeAvatar] = React.useState({
    data: false,
    avatarId: '',
    gender: '',
    skinColor: '',
    bgColor: '',
  });

  /***
   *
   */
  const handleClickOpenChangeAvatarFormModal = () => {
    setOpenChangeAvatarForm(true);
  };

  /***
   *
   */
  const handleEditProfileImage = () => {
    setChangeProfileImage(!changeProfileImage);
  };

  /***
   *
   */
  useEffect(() => {
    const handleRandemAvatar = () => {
      for (var i = 0; i < 16; i++) {
        let randomAvatar = {
          avatarId: avatarIdOptions[Math.floor(Math.random() * avatarIdOptions.length)],
          gender: genderOptions[Math.floor(Math.random() * genderOptions.length)],
          skinColor: skinColorOptions[Math.floor(Math.random() * skinColorOptions.length)],
          bgColor: bgColorOptions[Math.floor(Math.random() * bgColorOptions.length)],
        };
        searches.push(randomAvatar);
        setFillAvatars(true);
      }
    };
    if (fillAvatars === false) {
      handleRandemAvatar();
    }
  });

  useEffect(() => {
    const avatarConfig = userData.id
      ? userData.avatar_config
        ? JSON.parse(userData.avatar_config)
        : null
      : null;
    setChangeAvatar({ ...avatarConfig, data: false });
    if (onAvatarChange) {
      onAvatarChange(avatarConfig);
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseChandeAvatarFormModal = () => {
    setOpenChangeAvatarForm(false);
  };
  const handleChangeOldAvatar = () => {
    let new_contAvatars = contAvatars;
    if (contAvatars === 0) {
      new_contAvatars = 15;
    } else {
      new_contAvatars = contAvatars - 1;
    }
    setContAvatars(new_contAvatars);
    let dataAvatar = {
      avatarId: searches[new_contAvatars].avatarId,
      gender: searches[new_contAvatars].gender,
      skinColor: searches[new_contAvatars].skinColor,
      bgColor: searches[new_contAvatars].bgColor,
    };
    setChangeAvatar({ ...dataAvatar, data: true });
    if (onAvatarChange) {
      onAvatarChange(dataAvatar);
    }
  };
  const handleChangeNextAvatar = () => {
    let new_contAvatars = contAvatars;
    if (contAvatars === 15) {
      new_contAvatars = 0;
    } else {
      new_contAvatars = contAvatars + 1;
    }
    setContAvatars(new_contAvatars);
    let dataAvatar = {
      avatarId: searches[new_contAvatars].avatarId,
      gender: searches[new_contAvatars].gender,
      skinColor: searches[new_contAvatars].skinColor,
      bgColor: searches[new_contAvatars].bgColor,
    };
    setChangeAvatar({ ...dataAvatar, data: true });
    if (onAvatarChange) {
      onAvatarChange(dataAvatar);
    }
  };
  const handleClose = () => {
    setChangeAvatar({
      data: false,
      avatarId: '',
      gender: '',
      skinColor: '',
      bgColor: '',
    });
  };
  const editUser = () => {
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Add group
    const avatar_config = JSON.stringify({
      avatarId: changeAvatar.avatarId,
      gender: changeAvatar.gender,
      skinColor: changeAvatar.skinColor,
      bgColor: changeAvatar.bgColor,
    });
    editEntityData(`${USERS_API}${userData.id}`, {
      avatar_config: avatar_config,
    })
      .then((res) => {
        //Show message on GroupList view
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('PanelAvatar.user_updated')}`,
          message: '',
        });
        //Close modal
        //UPDATE LOCAL DATA
        dispatch(setAppLoadUserAction({ ...userData, avatar_config: avatar_config }));
        handleClose();
      })
      .catch((err) => {
        setShowSucessModal({
          show: true,
          type: 'error',
          title:  `${t('error_messages.error_edit_user')}`,
          message: '',
        });
      });
  };

  return (
    <>
      <>
        <Row>
          <Col
            md={{ span: 10, offset: 1 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StyledCardLabel style={{ paddingBottom: '20px !important' }} {...props}>
              <div>
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {enableRandomAvatar && (
                      <IconButton onClick={handleChangeOldAvatar}>
                        <Icon
                          src={arrow_left}
                          style={{
                            width: 24,
                            height: 24,
                            display: 'flex',
                            margin: 'auto',
                          }}
                        />
                      </IconButton>
                    )}
                  </Col>
                  <Col>
                    {changeAvatar.data === false && userData.avatar_config ? (
                      //User has not changed his avatar with arrows. Shows stored in BDD
                      <AppAvatar
                        avatarId={avatarConfig?.avatarId}
                        bgColor={avatarConfig?.bgColor}
                        gender={avatarConfig?.gender}
                        skinColor={avatarConfig?.skinColor}
                        width={128}
                        height={128}
                      />
                    ) : changeAvatar.data === true ? ( //User has changed his avatar with arrows. Shows random
                      <AppAvatar
                        avatarId={changeAvatar?.avatarId}
                        bgColor={changeAvatar?.bgColor}
                        gender={changeAvatar?.gender}
                        skinColor={changeAvatar?.skinColor}
                        width={128}
                        height={128}
                      />
                    ) : changeAvatar.data === false && userData.photo_url ? ( //If there is not avatar_config but there is profile photo
                      <Icon
                        src={userData?.photo_url}
                        style={{
                          width: 128,
                          height: 128,
                          display: 'flex',
                          margin: 'auto',
                        }}
                      />
                    ) : (
                      //If there are not avatar_config neither photo profile
                      <Icon
                        src={avatar}
                        style={{
                          width: 128,
                          height: 128,
                          display: 'flex',
                          margin: 'auto',
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {enableRandomAvatar && (
                      <IconButton onClick={handleChangeNextAvatar}>
                        <Icon
                          src={arrow_right}
                          style={{
                            width: 24,
                            height: 24,
                            display: 'flex',
                            margin: 'auto',
                          }}
                        />
                      </IconButton>
                    )}
                  </Col>
                </Row>
                {changeAvatar.data === true && showButtons === 1 ? (
                  <Row>
                    <StyleButtons>
                      <AppButton
                        style={{
                          fontSize: 14,
                          width: 'auto',
                          marginRight: 16,
                          display: 'unset',
                          flexDirection: 'unset',
                        }}
                        color={PALETTE_COLOR.MAIN_WHITE}
                        bgcolor={PALETTE_COLOR.MAIN_GREEN}
                        bordercolor={PALETTE_COLOR.MAIN_GREEN}
                        bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        size="lg"
                        onClick={editUser}
                        title={t('buttons.btn_save_new_avatar')}
                      >
                        {t('buttons.btn_save_new_avatar')}
                      </AppButton>

                      <AppButton
                        size="lg"
                        style={{
                          fontSize: 14,
                          width: 'auto',
                          display: 'unset',
                          flexDirection: 'unset',
                        }}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        title={t('buttons.btn_cancel')}
                        onClick={handleClose}
                      >
                        {t('buttons.btn_cancel')}
                      </AppButton>
                    </StyleButtons>
                  </Row>
                ) : (
                  <></>
                )}
                <Row>
                  <ActionButton
                    variant="link"
                    className="my-1"
                    onClick={
                      enableRandomAvatar
                        ? handleClickOpenChangeAvatarFormModal
                        : handleEditProfileImage
                    }
                  >
                    {enableRandomAvatar ? `${t('Login.choose_avatar.create_avatar')}` : `${t('Login.choose_avatar.modal_edit_image_title')}`}
                  </ActionButton>
                </Row>
              </div>
            </StyledCardLabel>
          </Col>
          <Col md={1} style={{ textAlign: 'end' }}>
            <StyledCardIcon>
              {icon && (
                <IconButton
                  aria-label="edit"
                  onClick={call}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 10,
                  }}
                >
                  <Icon src={icon} />
                </IconButton>
              )}
            </StyledCardIcon>
          </Col>
        </Row>
        {withborder === 1 ? (
          <hr
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.18)',
              height: 2,
              padding: 0,
              margin: 0,
            }}
          />
        ) : null}
      </>
      {children}

      {/*Change Avatar modal*/}
      <AppModal
        PaperProps={{ sx: { maxWidth: 800 } }}
        open={openChangeAvatarForm}
        handleclose={handleCloseChandeAvatarFormModal}
        title={t('Login.choose_avatar.modal_create_avatar_title')}
        inside={1}
      >
        <ChangeAvatarForm
          handleclose={handleCloseChandeAvatarFormModal}
          setShowSucessModal={setShowSucessModal}
          userdata={userData}
          onAvatarChanged={(e) => {
            //console.log('🚀 ~ file: AppPanelAvatar.js ~ line 428 ~ InnerAppPanelAvatar ~ e', e);
          }}
        />
      </AppModal>

      {/*New: Change Edit profile Image*/}
      <AppModal
        PaperProps={{ sx: { maxWidth: 800 } }}
        open={changeProfileImage}
        handleclose={handleEditProfileImage}
        title={t('Login.choose_avatar.modal_edit_image_title')}
        inside={0}
      >
        <EditProfileImage
          handleclose={handleEditProfileImage}
          setShowSucessModal={setShowSucessModal}
          onAvatarChanged={(e) => {
            //console.log('🚀 ~ file: AppPanelAvatar.js ~ line 428 ~ InnerAppPanelAvatar ~ e', e);
          }}
          container={1}
        />
      </AppModal>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </>
  );
}

export function AppPanelAvatar(props) {
  const { width, showStyledCard = 1 } = props;
  // console.log(
  // "🚀 ~ file: AppPanelAvatar.js ~ line 314 ~ AppPanelAvatar ~ showStyledCard",
  // showStyledCard
  // );
  return (
    <>
      {showStyledCard === 1 ? (
        <StyledCard width={width}>
          <InnerAppPanelAvatar {...props} />
        </StyledCard>
      ) : (
        <InnerAppPanelAvatar {...props} />
      )}
    </>
  );
}

export default AppPanelAvatar;
