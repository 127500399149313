import moment from 'moment';

import {
  DECREMENT,
  INCREMENT,
  LOADING,
  LOAD_VALUES_DEFINITIONS,
  LOAD_LOGGED_USER,
  SET_JOB_SCHEDULER_GROUP,
  SET_RELOAD_SCHEDULER,
  SHOW_MESSAGE,
  SHOW_TEMPLATES,
  SET_JOB_SCHEDULER_QUERIES,
  SET_SITE_WEEK_SCHEDULER_MODAL_OPEN,
  LOAD_USER_PERMISSIONS,
  NEW_CHAT_MESSAGE,
  LOAD_NEW_CHAT_MESSAGE_FOCUS,
  SET_DESTINATION_CHAT_USER_ID,
  LOADING_CHAT,
  SET_PROFILE_IMAGE_TO_SAVE,
  SET_RELOAD_TIMESHEET,
  SET_RELOAD_NOTIFICATIONS,
  SET_TIME_SHEET_DATES,
  SET_COUNT_CHAT_MESSAGES,
  SET_USERS_CHAT_MESSAGES,
  SET_UPDATED_CHAT_MESSAGES,
  FOCUS_STATUS,
  GLOBAL_ORGANIZATION
} from 'redux/actions/appActions';

const initialState = {
  counter: 0,
  appLoading: false,
  valuesDefinitions: [],
  userData: {},
  jobSchedulerGroup: {},
  reloadScheduler: false,
  reloadTimeSheet: false,
  messageData: null,
  showTemplates: { visible: false },
  jobSchedulerQueries: {},
  siteWeekJobSchedulerModalOpen: false,
  userPermissions: {},
  newChatMessage: {},
  loadNewChatMessageFocus: false,
  destinationChatUserId: 0,
  loadingChat: false,
  profileImageToSave: {},
  reloadNotifications: false,
  timeSheetSelectedDates: {
    startDate: moment().startOf('week').format(),
    endDate: moment().endOf('week').format(),
  },
  updatedChatMessages: false,
  usersChatMessages: [],
  countChatMessages: 0,
  focusStatus: false,
  globalOrganizationData: {},
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        appLoading: action.payload,
      };
    case INCREMENT:
      return {
        ...state,
        counter: state.counter + (action.payload || 1),
      };
    case DECREMENT:
      return {
        ...state,
        counter: state.counter - 1,
      };
    case LOAD_VALUES_DEFINITIONS:
      return {
        ...state,
        valuesDefinitions: action.payload,
      };
    case LOAD_LOGGED_USER:
      return {
        ...state,
        userData: {
          ...action.payload,
          isSuperAdmin: action.payload.role?.value_definition === '040',
          isGroupAdmin: action.payload.role?.value_definition === '030',
          isSiteManager: action.payload.role?.value_definition === '020',
          isOrganizationRepresentative: action.payload.role?.value_definition === '010',
        },
      };
    case SET_JOB_SCHEDULER_GROUP:
      return {
        ...state,
        jobSchedulerGroup: action.payload,
      };
    case SET_RELOAD_SCHEDULER:
      return {
        ...state,
        reloadScheduler: action.payload,
      };
    case SET_RELOAD_TIMESHEET:
      return {
        ...state,
        reloadTimeSheet: action.payload,
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        messageData: action.payload,
      };
    case SHOW_TEMPLATES:
      return {
        ...state,
        showTemplates: action.payload,
      };
    case SET_JOB_SCHEDULER_QUERIES:
      return {
        ...state,
        jobSchedulerQueries: action.payload,
      };
    case SET_SITE_WEEK_SCHEDULER_MODAL_OPEN:
      return {
        ...state,
        siteWeekJobSchedulerModalOpen: action.payload,
      };
    case LOAD_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case NEW_CHAT_MESSAGE:
      return {
        ...state,
        newChatMessage: action.payload,
      };
    case LOAD_NEW_CHAT_MESSAGE_FOCUS:
      return {
        ...state,
        loadNewChatMessageFocus: action.payload,
      };
    case SET_DESTINATION_CHAT_USER_ID:
      return {
        ...state,
        destinationChatUserId: action.payload,
      };
    case LOADING_CHAT:
      return {
        ...state,
        loadingChat: action.payload,
      };
    case SET_RELOAD_NOTIFICATIONS:
      return {
        ...state,
        reloadNotifications: action.payload,
      };
    case SET_PROFILE_IMAGE_TO_SAVE:
      return {
        ...state,
        profileImageToSave: action.payload,
      };
    case SET_TIME_SHEET_DATES:
      return {
        ...state,
        timeSheetSelectedDates: action.payload,
      };
    case SET_COUNT_CHAT_MESSAGES:
        return {
          ...state,
          countChatMessages: action.payload,
        };
    case SET_USERS_CHAT_MESSAGES:
      return {
        ...state,
        usersChatMessages: action.payload,
      };
    case SET_UPDATED_CHAT_MESSAGES:
      return {
        ...state,
        updatedChatMessages: action.payload,
      };
    case FOCUS_STATUS:
      return {
        ...state,
        focusStatus: action.payload,
      };
    case GLOBAL_ORGANIZATION:
      return {
        ...state,
        globalOrganizationData: action.payload,
      };
    default:
      return state;
  }
};
