import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { getEntityData, saveEntityData, editEntityData } from 'services';
import { SITES_API, USERS_API, ORGANIZATIONS_USERS_API } from '@services/CONSTANTS';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import EncView from '@components/EncView';
import {
  AppModal,
  StateTag,
  HeaderBtn,
  AppModalStatus,
  CustomizedSnackbar,
  AppButton,
  AppTable,
  AppOrgIcon,
  AppDarkTooltip,
} from '@components';
import AppPanel from '@components/AppPanel';
import { EditSite } from '@pages/Organizations/EditSite';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Menu, MenuItem } from '@szhsin/react-menu';
import IconButton from '@mui/material/IconButton';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Blocked.svg';
import status_archived from '@assets/icons/Archived.svg';
import ic_users from '@assets/icons/Group3.svg';
import ic_users_big from '@assets/icons/ic_user_group_thin.svg';
import logo_edit from '@assets/icons/edit.svg';
import { calcAvatar } from 'utils/avatar';
import Button_Outline from '@assets/icons/Button_Outline.svg';
import restore from '@assets/icons/ic_restore.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import Status_block from '@assets/icons/Status_block.svg';
import ic_remove from '@assets/icons/ic_remove.svg';
import Invited from '@assets/icons/Invited.svg';
import Draft from '@assets/icons/Draft.svg';
import Archived from '@assets/icons/Archived.svg';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ico_Block from '@assets/icons/ico_Block.svg';
//import { getCurrentSession } from '@services/CognitoAuthService';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import { FilterSiteManagers } from '../FilterSiteManagers';
import { CondOperator } from '@nestjsx/crud-request';
import { AddEmployeeSite } from '../AddEmployeeSite';
import { RestoreSite } from 'pages/Organizations/RestoreSite';
import ico_Restore from '@assets/icons/ico_Restore.svg';
import ic_eye from '@assets/icons/ic_eye.svg';
import { VIEW_USER_EMPLOYEE, DASHBOARD } from '@navigation/CONSTANTS';

import { useTranslation } from 'react-i18next';

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Icon = styled.img``;

const StyleDivTitle = styled.div`
  bottom: 80.54%;
  border-radius: 52px;
  margin-right: 9px;
  margin-left: -15px;
  font-size: 20px;
  font-family: 'Poppins';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const StyleRow = styled.div`
  padding: 14px 28px 24px;
`;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const NoDataPanelText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const StyleTittle = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: ${PALETTE_COLOR.LIGHT_GREY};
`;

const StyleFields = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledTag = styled.div`
  width: fit-content;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const SiteName = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

const SiteP = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const ViewSite = () => {
  const { t } = useTranslation();
  let { siteId } = useParams();
  let history = useHistory();
  const [openUnassignEmployee, setOpenUnassignEmployee] = useState(false);

  const { valuesDefinitions, userData } = useSelector((state) => state.app);
  const [siteData, setSiteData] = useState();

  const filters = React.useMemo(
    () => [
      { field: 'organizationUserSitesSite.id', operator: CondOperator.EQUALS, value: siteId },
      { field: 'type.value_definition', operator: CondOperator.EQUALS, value: '010' },
    ],
    [siteId],
  );

  const joins = React.useMemo(
    () => [
      { field: 'organizations_users' },
      { field: 'organizations_users.organization_user_sites' },
      { field: 'organizations_users.organization_user_sites.site' },
    ],
    [],
  );

  //Enable buttons
  const [enableButton, setEnableButton] = useState(true);
  const [hasEmployees, setHasEmployees] = useState(false);
  const [openEditSite, setOpenEditSite] = useState(false);
  const [openAssignEmployee, setOpenAssignEmployee] = useState(false);
  const [openRestoreSite, setOpenRestoreSite] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);

  let selectedEmployee = useRef({});
  let newSiteStatus = useRef('');
  const [isLoading, setIsLoading] = useState(false);
  const [unassignEmplModalContent, setUnassignEmpModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });
  const [siteStatusModalContent, setSiteStatusModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });

  //const loggedUserRole = useRef('');
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  /***
   * If it is a SM, only show site details from those which he is Site Manger
   */
  React.useEffect(() => {
    if (userData) {
      if (userData?.isSiteManager) {
        if (userData?.sites && userData?.sites.length > 0) {
          const smSitesIdS = userData?.sites.map((site) => site.id);
          if (!smSitesIdS.includes(parseInt(siteId))) {
            history.push(DASHBOARD);
          }
        } else {
          //SM without sites
          history.push(DASHBOARD);
        }
      }
    }
  }, [userData, history, siteId]);

  /***
   *
   */
  useEffect(() => {
    if (userData) {
      if (userData.isSiteManager) {
        setEnableButton(false);
      } else {
        setEnableButton(true);
      }
    } else {
      setEnableButton(false);
    }
  }, [userData]);

  const handleEditSiteModal = () => {
    setOpenEditSite(!openEditSite);
  };

  const handleAssignEmployeeModal = () => {
    setOpenAssignEmployee(!openAssignEmployee);
  };

  const handleRestoreSiteModal = () => {
    setOpenRestoreSite(!openRestoreSite);
  };

  const handleChangeSiteStatusModal = (newStatus) => {
    setOpenChangeStatus(!openChangeStatus);
    newSiteStatus.current = newStatus;

    switch (newStatus) {
      case '030':
        setSiteStatusModalContent({
          buttonText: `${t('buttons.btn_archive')}`,
          actionIcon: ico_Archived,
          title: `${t('Organization.modals.archive_site_title')}`,
          subtitle: `${t('Organization.modals.archive_site_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_RED,
          bordercolor: PALETTE_COLOR.ACCENT_RED,
          borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        });
        break;
      case '020':
        setSiteStatusModalContent({
          buttonText: `${t('buttons.btn_block')}`,
          actionIcon: ico_Block,
          title: `${t('Organization.modals.block_site_title')}`,
          subtitle: `${t('Organization.modals.block_site_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
          bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
          borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
        });
        break;
      case '010':
        setSiteStatusModalContent({
          buttonText: `${t('buttons.btn_unblock')}`,
          actionIcon: ico_Restore,
          title: `${t('Organization.modals.unblock_site_title')}`,
          subtitle: `${t('Organization.modals.unblock_site_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_GREEN,
          bordercolor: PALETTE_COLOR.ACCENT_GREEN,
          borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          boxShadow: '0px 4px 10px rgba(134, 217, 113, 0.3)',
        });
        break;
      default:
        break;
    }
  };

  const handleUnassignEmployeeModal = useCallback(
    (employee) => {
      selectedEmployee.current = employee;
      setOpenUnassignEmployee(!openUnassignEmployee);

      setUnassignEmpModalContent({
        buttonText: `${t('buttons.btn_unassign')}`,
        actionIcon: ico_Archived,
        title: `${t('Organization.modals.unassign_employee_title')}`,
        subtitle: `${t('Organization.modals.unassign_employee_subtitle')}`,
        bgcolor: PALETTE_COLOR.ACCENT_RED,
        bordercolor: PALETTE_COLOR.ACCENT_RED,
        borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
        bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
        boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        subject: employee?.name,
      });
    },
    [openUnassignEmployee, setOpenUnassignEmployee, t],
  );

  const adminColumns = useMemo(
    () => [
      {
        dataField: 'name',
        text: `${t('table_columns.name')}`,
        sort: true,
        search: true,
        headerStyle: { width: '30%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex' }}>
              <span style={{ cursor: 'pointer', marginLeft: 10 }}>{calcAvatar(row, 30, 30)}</span>
              <AppDarkTooltip title={row?.name}>
                <StyledLabel
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    margin: '4px',
                  }}
                >
                  {row.name}
                </StyledLabel>
              </AppDarkTooltip>
            </div>
          );
        },
      },
      {
        dataField: 'email',
        text: `${t('table_columns.email')}`,
        search: true,
        headerStyle: { width: '25%' },
        formatter: (cellContent, row) => {
          return (
            <AppDarkTooltip title={row?.email}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.email}
              </StyledLabel>
            </AppDarkTooltip>
          );
        },
      },
      {
        dataField: 'phone',
        text: `${t('table_columns.phone')}`,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.phone}</StyledLabel>;
        },
      },
      {
        dataField: 'job_title.description',
        text: `${t('table_columns.job_title')}`,
        search: true,
        headerStyle: { width: '12%' },
        formatter: (cellContent, row) => {
          if (row.jobs_titles.length > 0) {
            const jobs_titles = row.jobs_titles.filter(
              (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
            ); //ACTIVE ONLY
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {jobs_titles.map((org, index) => (
                  <StyledTag key={index}>{org.job_title.description}</StyledTag>
                ))}
              </div>
            );
          }
        },
      },
      {
        dataField: 'id',
        text: `${t('table_columns.group')}`,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {row.user_groups.map((group) => (
                <StyledTag>{group?.group.name}</StyledTag>
              ))}
            </div>
          );
        },
      },
      {
        dataField: 'employee_id',
        text: `${t('table_columns.employee_id')}`,
        search: true,
        headerStyle: { width: '15%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.employee_id}</StyledLabel>;
        },
      },
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        search: true,
        headerStyle: { width: '15%' },
        formatter: (cellContent, row) => {
          switch (row.status.value_definition) {
            case '010':
              return (
                <StyledLabel>
                  <Status src={status_active} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '020':
              return (
                <StyledLabel>
                  <Status src={Draft} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '030':
              return (
                <StyledLabel>
                  <Status src={Invited} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '050':
              return (
                <StyledLabel>
                  <Status src={Status_block} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '060':
              return (
                <StyledLabel>
                  <Status src={Archived} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );
            default:
              return '';
          }
        },
      },
      {
        dataField: 'unassign',
        text: `${t('table_columns.unassign')}`,
        isDummyField: true,
        headerStyle: { width: '12%' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel
              style={{
                marginLeft: '0px',
                justifyContent: 'center',
                alignitems: 'center',
              }}
              onClick={() => handleUnassignEmployeeModal(row)}
            //onClick={() => handleClickOpenChangeStatusSite(row, s)}
            >
              <Icon
                src={ic_remove}
                alt="unassign"
                title={t('buttons.btn_unassign')}
                style={{ cursor: 'pointer' }}
              ></Icon>
            </StyledLabel>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { },
        },
      },
    ],
    [handleUnassignEmployeeModal, t],
  );

  //If user logged is a SM
  const siteManagerColumns = useMemo(
    () => [
      {
        dataField: 'name',
        text: `${t('table_columns.name')}`,
        sort: true,
        search: true,
        headerStyle: { width: '20%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex' }}>
              <span style={{ cursor: 'pointer', marginLeft: 10 }}>{calcAvatar(row, 30, 30)}</span>
              <AppDarkTooltip title={row?.name}>
                <StyledLabel
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    margin: '4px',
                  }}
                >
                  {row.name}
                </StyledLabel>
              </AppDarkTooltip>
            </div>
          );
        },
      },
      {
        dataField: 'job_title.description',
        text: `${t('table_columns.job_title')}`,
        search: true,
        headerStyle: { width: '10%' },
        formatter: (cellContent, row) => {
          if (row.jobs_titles.length > 0) {
            const jobs_titles = row.jobs_titles.filter(
              (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
            ); //ACTIVE ONLY
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {jobs_titles.map((org, index) => (
                  <StyledTag key={index}>{org.job_title.description}</StyledTag>
                ))}
              </div>
            );
          }
        },
      },
      {
        dataField: 'id',
        text: `${t('table_columns.group')}`,
        search: true,
        headerStyle: { width: '15%' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {row.user_groups.map((group) => (
                <StyledTag>{group?.group.name}</StyledTag>
              ))}
            </div>
          );
        },
      },
      {
        dataField: 'employee_id',
        text: `${t('table_columns.employee_id')}`,
        search: true,
        headerStyle: { width: '8%' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.employee_id}</StyledLabel>;
        },
      },
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        search: true,
        headerStyle: { width: '8%' },
        formatter: (cellContent, row) => {
          switch (row.status.value_definition) {
            case '010':
              return (
                <StyledLabel>
                  <Status src={status_active} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '020':
              return (
                <StyledLabel>
                  <Status src={Draft} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '030':
              return (
                <StyledLabel>
                  <Status src={Invited} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '050':
              return (
                <StyledLabel>
                  <Status src={Status_block} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );

            case '060':
              return (
                <StyledLabel>
                  <Status src={Archived} alt="logo"></Status>
                  <StyledIconLabel>
                    {t(`Users.status.${row.status.description}`)}
                  </StyledIconLabel>
                </StyledLabel>
              );
            default:
              return '';
          }
        },
      },
      {
        dataField: 'view',
        text: `${t('table_columns.view')}`,
        isDummyField: true,
        headerStyle: { width: '4%' },
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel style={{ marginLeft: 0 }}>
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            history.push(VIEW_USER_EMPLOYEE.replace(':userId', `${row.id}?b=true`));
          },
        },
      },
    ],
    [history, t],
  );

  /***
   * Depend on which groups system user has and its permissions, and which groups org has,
   * show or hide buttons
   */
  /*const showButtonsToSystemUser = React.useCallback(
    (empInfo) => {
      let showButtons = false;
      //Get employee groups
      const empGroups = empInfo?.user_groups.map((userGroup) => userGroup?.group?.id);
      if (empGroups.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (empGroups.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );*/

  //Get site data
  useEffect(() => {
    const qb = RequestQueryBuilder.create().setJoin([
      { field: 'organizations_users_sites' },
      { field: 'organizations_users_sites.organization_user' },
      { field: 'organizations_users_sites.organization_user.user_id' },
      { field: 'organizations_users_sites.organization_user.user_id.role' },
    ]);
    getEntityData(`${SITES_API}${siteId}`, qb.query())
      .then((res) => {
        setSiteData(res);
        setHasEmployees(
          res?.organizations_users_sites.filter((e) => e.organization_user.user_id.role === null)
            .length > 0,
        );
        //console.log('Employees ', res?.organizations_users_sites.filter((e) => e.organization_user.user_id.role === null));
      })
      .catch((err) => {
        console.log('axios err=', err);
      });

    return () => {
      console.log('axios cleanup.');
    };
  }, [siteId, showSucessModal]);

  const unassignEmployee = () => {
    setIsLoading(true);

    //Fill the obj
    let request = {};
    request.user_id = selectedEmployee.current.id;
    request.organization_id = siteData.organization_id;
    request.site = siteData;
    //Call api
    saveEntityData(`${ORGANIZATIONS_USERS_API}remove/site-employee`, request)
      .then((res) => {
        //Show success message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.employee_unassign')}`,
          message: '',
        });
      })
      .catch((err) => {
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_employee_unassigning')}`,
          message: '',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setShowSucessModal((prev) => {
            return { ...prev, show: false };
          });
        }, 2000);
        handleUnassignEmployeeModal();
        setIsLoading(false);
      });
  };

  const changeSiteStatus = () => {
    setIsLoading(true);
    const status = valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'SITE_STATUS' &&
        v.value_definition === newSiteStatus.current,
    )[0];
    editEntityData(`${SITES_API}/${siteData.id}`, { status })
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.site_status_changed')}`,
          message: '',
        });
      })
      .catch((err) => {
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_site_status_changing')}`,
          message: '',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setShowSucessModal((prev) => {
            return { ...prev, show: false };
          });
        }, 2000);
        handleChangeSiteStatusModal();
        setIsLoading(false);
      });
  };

  return (
    <Container fluid>
      {/* Header section */}
      <EncView>
        <StyleDivTitle>
          <AppOrgIcon
            color={siteData?.organization_id.color}
            style={{ width: 55, height: 55, fontSize: 20, padding: 5 }}
          >
            {siteData?.name.substring(0, 1).toUpperCase()}
          </AppOrgIcon>
        </StyleDivTitle>
        <SpaceEncName>
          <div>
            <SiteName style={{ margin: '0px' }}>{siteData?.name}</SiteName>
            <SiteP style={{ color: PALETTE_COLOR.LIGHT_GREY }}>
              {siteData?.organization_id.name}
            </SiteP>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {siteData?.status?.value_definition === '010' && (
            <>
              <StateTag
                width="auto"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Organization.sites_status.${siteData?.status.description}_site`)}
              </StateTag>
            </>
          )}
          {siteData?.status?.value_definition === '020' && (
            <>
              <StateTag
                width="auto"
                icon={status_blocked}
                background={PALETTE_COLOR.BLOCKED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.TEXT_ORANGE}
              >
                {t(`Organization.status.${siteData?.status.description}`)}
              </StateTag>
            </>
          )}
          {siteData?.status?.value_definition === '030' && (
            <>
              <StateTag
                width="auto"
                icon={status_archived}
                background={PALETTE_COLOR.ARCHIVED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_RED}
              >
                {t(`Organization.status.${siteData?.status.description}`)}
              </StateTag>
              {!userData.isSiteManager && (
                <HeaderBtn icon={restore} width="auto" action={() => handleRestoreSiteModal()}>
                  {t('buttons.btn_restore_site')}
                </HeaderBtn>
              )}
            </>
          )}
        </SpaceEnc>
        {enableButton && siteData?.status?.value_definition !== '030' && (
          <Menu
            align="end"
            direction="bottom"
            menuButton={
              <DropDownWrapper style={{ marginLeft: '5px' }}>
                <IconButton aria-label="edit" sx={{}}>
                  <Icon src={Button_Outline} />
                </IconButton>
              </DropDownWrapper>
            }
          >
            {siteData?.status?.value_definition === '010' && (
              <MenuItem onClick={() => handleChangeSiteStatusModal('020')}>
                {t('Organization.menu.block_site')}
              </MenuItem>
            )}
            {siteData?.status?.value_definition === '020' && (
              <MenuItem onClick={() => handleChangeSiteStatusModal('010')}>
                {t('Organization.menu.unblock_site')}
              </MenuItem>
            )}
            {(siteData?.status?.value_definition === '010' ||
              siteData?.status?.value_definition === '020') && (
                <MenuItem onClick={() => handleChangeSiteStatusModal('030')}>
                  {t('Organization.menu.archive_site')}
                </MenuItem>
              )}
          </Menu>
        )}
      </EncView>

      {/* Site Information Panel */}
      <Col xs={12} xl={12}>
        <AppPanel
          notitlepadding={`true`}
          title={t('Organization.view.site_information_title')}
          width="100%"
          padding_card="15px"
          icon={siteData?.status?.value_definition !== '030' && enableButton && logo_edit}
          call={() => handleEditSiteModal()}
        >
          <StyleRow>
            <StyleTittle>
              <Col>{t('form_labels.name')}</Col>
              <Col>{t('form_labels.address')}</Col>
              <Col>{t('form_labels.phone')}</Col>
              <Col>{t('form_labels.email')}</Col>
            </StyleTittle>
            <StyleFields>
              <Col>{siteData?.name}</Col>
              <Col>{siteData?.address}</Col>
              <Col>{siteData?.phone}</Col>
              <Col>{siteData?.email}</Col>
            </StyleFields>
          </StyleRow>
          <StyleRow>
            <StyleTittle>
              <Col>{t('form_labels.site_manager')}</Col>
              <Col>{t('form_labels.organization')}</Col>
              <Col>{t('form_labels.group')}</Col>
              <Col>{t('form_labels.coordinates')}</Col>
            </StyleTittle>
            <StyleFields>
              <Col>
                <FilterSiteManagers site={siteData} />
              </Col>
              <Col>
                <div style={{ display: 'flex', marginTop: 3 }}>
                  <AppOrgIcon
                    color={siteData?.organization_id.color}
                    style={{ width: 30, height: 30, fontSize: 12, padding: 5 }}
                  >
                    {siteData?.organization_id.name.substring(0, 2).toUpperCase()}
                  </AppOrgIcon>
                  <StyledLabel style={{ margin: '4px' }}>
                    {siteData?.organization_id.name}
                  </StyledLabel>
                </div>
              </Col>
              <Col>
                <div style={{ marginTop: 8 }}>{siteData?.groupsSite[0]?.group.name}</div>
              </Col>   <Col>
                <StyledLabel style={{ margin: '4px' }}>{siteData?.latitude}  </StyledLabel>
                <StyledLabel style={{ margin: '4px' }}>{siteData?.longitude}  </StyledLabel>
              </Col>
            </StyleFields>
          </StyleRow>
        </AppPanel>
      </Col>

      {/*<Row>
        <Col xs={12} xl={12}>
          {!siteData ? (
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            </AppPanel>
          ) : (
            <AppPanel
              width="100%"
              header={hasEmployees ? 1 : 0}
              style={
                hasEmployees === false
                  ? {
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                  : {}
              }
              withborder={0}

          
            >
              {!hasEmployees || siteData?.status?.value_definition === '030' ? (
                <div className="pt-4 pb-5 mt-3">
                  <Icon
                    src={ic_users_big}
                    style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                  />
                  <NoDataPanelTitle>
                    {t('Organization.site.view.assignation.no_employees_title')}
                  </NoDataPanelTitle>

                  {siteData?.status?.value_definition !== '030' ? (
                    //No archived sites are able to add employees
                    <>
                      <NoDataPanelText>
                        {t('Organization.site.view.assignation.no_employees_assign_title')}
                      </NoDataPanelText>
                      <AppButton
                        style={{ fontSize: 14, width: 'auto', display: 'flex', margin: 'auto' }}
                        bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                        bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                        bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        icon={ic_add_circle}
                        onClick={() => handleAssignEmployeeModal()}
                      >
                        {t('buttons.btn_assign')}
                      </AppButton>
                    </>
                  ) : (
                    //Message for archived  sites
                    <NoDataPanelText>
                      {t('Organization.site.view.assignation.no_employees_archived_site_title')}
                    </NoDataPanelText>
                  )}
                </div>
              ) : (
                hasEmployees && (
                  <AppTable
                    endpointPath={USERS_API}
                    columns={
                      userData ? (userData?.isSiteManager ? siteManagerColumns : adminColumns) : []
                    }
                    filters={filters}
                    joins={joins}
                    disableTopPagination={true}
                    noBorders={true}
                    headerIcon={ic_users}
                    headerText={t('table_headers.assigned_employees_header')}
                    reload={!showSucessModal.show}
                    trailingBtn={
                      enableButton && (
                        <AppButton
                          tooltiptitle={t('tooltips.assigned_employees')}
                          bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                          bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                          bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                          borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                          icon={ic_add_circle}
                          onClick={() => handleAssignEmployeeModal()}
                        >
                          {t('buttons.btn_add')}
                        </AppButton>
                      )
                    }
                  />
                )
              )}
            </AppPanel>
          )}
        </Col>
      </Row>*/}
      {//TODO LADY
      }
      {/* button={
              isEditEnabled &&
              userData.role?.value_definition !== '040' && {
                text: 'Add',
                icon: ic_add_circle,
                action: handleClickOpenGroupsModal,
              }
            }*/}
      {/* Edit site */}
      <AppModal
        open={openEditSite}
        handleclose={handleEditSiteModal}
        title={t('Organization.modals.edit_site_title')}
        inside={1}
      >
        <EditSite
          handleclose={handleEditSiteModal}
          setShowSucessModal={setShowSucessModal}
          siteData={siteData}
        />
      </AppModal>

      {/* Assign Employee */}
      <AppModal
        open={openAssignEmployee}
        handleclose={handleAssignEmployeeModal}
        title={t('Organization.modals.assign_site_employee_title')}
        inside={1}
      >
        <AddEmployeeSite
          handleclose={handleAssignEmployeeModal}
          setShowSucessModal={setShowSucessModal}
          data={siteData}
        />
      </AppModal>

      {/* Unassign Employee */}
      <AppModal
        open={openUnassignEmployee}
        handleclose={handleUnassignEmployeeModal}
        boxshadow={'none'}
        inside={0}
        width={600}
      >
        <AppModalStatus
          mainPadding={'25px 30px 35px 30px'}
          actionIcon={unassignEmplModalContent.actionIcon}
          title={unassignEmplModalContent.title}
          subtitle={unassignEmplModalContent.subtitle}
          bgcolor={unassignEmplModalContent.bgcolor}
          bordercolor={unassignEmplModalContent.bordercolor}
          borderhovercolor={unassignEmplModalContent.borderhovercolor}
          bghovercolor={unassignEmplModalContent.bghovercolor}
          boxShadow={unassignEmplModalContent.boxShadow}
          buttonText={unassignEmplModalContent.buttonText}
          subject={unassignEmplModalContent.subject}
          isLoading={isLoading}
          buttonAction={unassignEmployee}
        />
      </AppModal>

      {/* Change Site status */}
      <AppModal
        open={openChangeStatus}
        handleclose={handleChangeSiteStatusModal}
        boxshadow={'none'}
        inside={0}
        width={600}
      >
        <AppModalStatus
          mainPadding={'25px 30px 35px 30px'}
          actionIcon={siteStatusModalContent.actionIcon}
          title={siteStatusModalContent.title}
          subtitle={siteStatusModalContent.subtitle}
          bgcolor={siteStatusModalContent.bgcolor}
          bordercolor={siteStatusModalContent.bordercolor}
          borderhovercolor={siteStatusModalContent.borderhovercolor}
          bghovercolor={siteStatusModalContent.bghovercolor}
          boxShadow={siteStatusModalContent.boxShadow}
          buttonText={siteStatusModalContent.buttonText}
          subject={`${siteData?.name} (${siteData?.organization_id?.name})`}
          isLoading={isLoading}
          buttonAction={changeSiteStatus}
        />
      </AppModal>

      {/* Restore Site */}
      <AppModal
        open={openRestoreSite}
        handleclose={handleRestoreSiteModal}
        title={t('Organization.modals.restore_site_title')}
        inside={1}
      >
        <RestoreSite
          handleclose={handleRestoreSiteModal}
          setShowSucessModal={setShowSucessModal}
          siteData={siteData}
        />
      </AppModal>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </Container>
  );
};
