import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { changePassword } from '@services/CognitoAuthService';
import { useAuth } from '@auth/ProvideAuth';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const ChangePasswordForm = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  const changePassSchema = Yup.object().shape({
    oldPass: Yup.string()
      .min(8, `${t('validation.at_least_password')}`)
      .max(99, `${t('validation.must_be_password')}`)
      .required(`${t('validation.old_password_required')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
        `${t('validation.invalid_password')}`,
      ),
    newPass: Yup.string()
      .min(8, `${t('validation.at_least_password')}`)
      .max(99, `${t('validation.must_be_password')}`)
      .required(`${t('validation.new_password_required')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
        `${t('validation.invalid_password')}`,
      )
      .oneOf([Yup.ref('newPass2'), null], `${t('validation.new_password_match')}`)
      .notOneOf([Yup.ref('oldPass'), null], `${t('validation.new_old_password_same')}`),
    newPass2: Yup.string()
      .min(8, `${t('validation.at_least_password')}`)
      .max(99, `${t('validation.must_be_password')}`)
      .required(`${t('validation.confirm_password_required')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
        `${t('validation.invalid_password')}`,
      )
      .oneOf([Yup.ref('newPass'), null], `${t('validation.new_password_match')}`),
  });
  //To handle message
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  let auth = useAuth();
  const [loading, setLoading] = useState(false);

  async function changeUserPassword(values) {
    setLoading(true);
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });

    const cognitoUser = await auth.getUserData();
    const username = cognitoUser.idToken?.payload?.email;

    try {
      await changePassword(username, values.oldPass, values.newPass);
      setLoading(false);
      setShowSucessModal({
        show: true,
        type: 'success',
        title: `${t('Profile.change_pwd.change_success_title')}`,
        message: `${t('Profile.change_pwd.change_success_description')}`,
      });
      handleclose();
    } catch (error) {
      const { code } = error;
      if (code === 'NotAuthorizedException') {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title: `${t('Profile.change_pwd.change_error_incorrect_old')}`,
          message: '',
        });
      } else if (code === 'LimitExceededException' || code === 'TooManyRequestsException') {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title: `${t('Profile.change_pwd.change_error_too_many')}`,
          message: '',
        });
      } else {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title: `${t('Profile.change_pwd.change_error_changing')}`,
          message: '',
        });
      }
      setLoading(false);
    }
  }

  return (
    <Formik
      validationSchema={changePassSchema}
      onSubmit={changeUserPassword}
      initialValues={{
        oldPass: '',
        newPass: '',
        newPass2: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <div style={{ width: '590px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.old_password')}></LabelForm>
              </Col>
              <Col xs={8}>
                <InputForm
                  ctype="password"
                  maxLength="99"
                  className="mb-0"
                  height={'40px'}
                  placeholder={t('required')}
                  name="oldPass"
                  value={values.oldPass}
                  invalid={touched.oldPass && errors.oldPass}
                  onChange={handleChange}
                ></InputForm>
                {touched.oldPass && errors.oldPass && (
                  <ErrorText className="px-2 mb-0">{errors.oldPass}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.new_password')}></LabelForm>
              </Col>
              <Col xs={8}>
                <InputForm
                  ctype="password"
                  maxLength="99"
                  className="mb-0"
                  height={'40px'}
                  placeholder={t('required')}
                  name="newPass"
                  value={values.newPass}
                  invalid={touched.newPass && errors.newPass}
                  onChange={handleChange}
                ></InputForm>
                {touched.newPass && errors.newPass && (
                  <ErrorText className="px-2 mb-0">{errors.newPass}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.confirm_new_password')}></LabelForm>
              </Col>
              <Col xs={8}>
                <InputForm
                  ctype="password"
                  maxLength="99"
                  className="mb-0"
                  height={'40px'}
                  placeholder={t('required')}
                  name="newPass2"
                  value={values.newPass2}
                  invalid={touched.newPass2 && errors.newPass2}
                  onChange={handleChange}
                ></InputForm>
                {touched.newPass2 && errors.newPass2 && (
                  <ErrorText className="px-2 mb-0">{errors.newPass2}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_update')}
                >
                  {t('buttons.btn_update')}
                </AppButton>
                <AppButton
                  loading={loading}
                  size="lg"
                  style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              {showErrorMessage.show && (
                <CustomizedSnackbar
                  show={showErrorMessage.show}
                  type={showErrorMessage.type}
                  title={showErrorMessage.title}
                  message={showErrorMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
