import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ic_avatar_empty from '@assets/icons/ic_avatar_empty.svg';
import chroma from 'chroma-js';
import ico_time from '@assets/icons/ico_time.svg';
import ico_empty from '@assets/icons/ico_empty.svg';
import { AditionalSiteActions } from '../pages/JobScheduler/AditionalSiteActions';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const IcoWrapper = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 4px;
`;

const SiteWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SiteCircle = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 5px;
`;

const SiteName = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

const InfoWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8d939c;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  gap: 14px;
`;

export const SiteNestedCell = (props) => {
  const { t } = useTranslation();
  const { row, data, calendarConfig, userWithPermissions } = props;

  const [showMenuOption, setShowMenuOption] = useState(false);

  //Redux
  const { siteWeekJobSchedulerModalOpen } = useSelector((state) => state.app);

  //Avoid aditional option menu stay when modal is closed
  useEffect(() => {
    if (!siteWeekJobSchedulerModalOpen) {
      setShowMenuOption(false);
    }
  }, [siteWeekJobSchedulerModalOpen]);

  return (
    <SiteWrapper
      onMouseEnter={() => setShowMenuOption(true)}
      //Variable on redux to avoid modal closes because the onMouseLeave action
      onMouseLeave={() => setShowMenuOption(siteWeekJobSchedulerModalOpen)}
      {...props}
    >
      {row.site.id === null ? (
        <SiteCircle src={ic_avatar_empty} />
      ) : (
        <div
          style={{
            width: 40,
            height: 40,
            backgroundColor: row.site.color,
            border: `1px solid ${chroma(row.site.color).darken().hex()}`,
            borderRadius: 200,
            marginRight: 5,
          }}
        ></div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 8 }}>
        <SiteName>{row.site.id === null ? `${t('JobScheduler.shifts_without_site')}` : row.site.name}</SiteName>
        {/* <InfoWrapper style={{ justifyContent: 'left' }}>
          <div>
            <IcoWrapper src={ico_time} />
            16
          </div>
          <div>
            <IcoWrapper src={ico_empty} />
            4
          </div>
        </InfoWrapper> */}
      </div>
      {/* if is not the first site, is hoverd and user with permissions */}
      {row.site.id !== null && showMenuOption && userWithPermissions && (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-end' }}>
          <AditionalSiteActions
            currentdata={data}
            currentWeek={calendarConfig.currentWeek}
            type={calendarConfig.calendarType}
            row={row}
           />
        </div>
      )}
    </SiteWrapper>
  );
};
