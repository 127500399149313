import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { getEntityData, saveEntityData, editEntityData } from '@services';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { EMPLOYEES_NOTES_API } from 'services/CONSTANTS';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { showMessageAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 10px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const NoteForm = ({ handleclose, setReloadEmployeeNotes, data, noteData = null }) => {
  const { t } = useTranslation();
  const noteSchema = Yup.object().shape({
    subject: Yup.string()
      .max(300, `${t('Users.empleyees.view.validation.must_be_subject')}`)
      .required(`${t('validation.subject_required')}`),
    note: Yup.string()
      .max(500, `${t('Users.empleyees.view.validation.must_be_note')}`)
      .required(`${t('validation.note_required')}`),
  });
  //Form initial values
  const [initialValues, setInitialValues] = useState({ subject: '', note: '' });
  //Handle message
  const [showSnackBarMessage, setShowSnackBarMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //Loading action as create or edit
  const [loading, setLoading] = useState(false);
  //Is loading the note data when editing
  const [isLoadingNote, setIsLoadingNote] = useState(false);
  //Redux
  const { userData } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  //Request obj to create/edit
  let noteRequest = {
    user: null,
    author: null,
    subject: '',
    note: '',
  };

  //Snackbar
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Get note info
   */
  React.useEffect(() => {
    if (noteData) {
      setIsLoadingNote(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'user' })
        .setJoin({ field: 'author' });
      getEntityData(`${EMPLOYEES_NOTES_API}${noteData.id}`, qb.query())
        .then((res) => {
          setInitialValues({ ...res });
          setIsLoadingNote(false);
        })
        .catch((err) => {
          console.log('get note data err=', err);
          setIsLoadingNote(false);
        });
    }
  }, [noteData]);

  /***
   * Decide if add or edit a note
   */
  const handleAddEditNote = (values) => {
    if (noteData) {
      editNote(values);
    } else {
      addNote(values);
    }
  };

  /***
   * Calling to WS to add a group
   */
  const addNote = (values) => {
    //Hide modal for previous view
    setShowSnackBarMessage((prev) => {
      return { ...prev, show: false };
    });
    //Build request
    noteRequest.user = data.id;
    noteRequest.author = userData.id;
    noteRequest.subject = values.subject;
    noteRequest.note = values.note;

    setLoading(true);
    //Add note
    saveEntityData(EMPLOYEES_NOTES_API, noteRequest)
      .then((res) => {
        //Show message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.note_created')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
        //Reload previous page
        setReloadEmployeeNotes(true);
      })
      .catch((err) => {
        //Show message
        setShowSnackBarMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_note_creating')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  /***
   * Call to WS to edit a note
   */
  const editNote = (values) => {
    //Hide message
    setShowSnackBarMessage((prev) => {
      return { ...prev, show: false };
    });
    //Build request
    noteRequest.user = values.user.id;
    noteRequest.author = userData.id;
    noteRequest.subject = values.subject;
    noteRequest.note = values.note;

    setLoading(true);
    //Edit note
    editEntityData(`${EMPLOYEES_NOTES_API}${noteData.id}`, noteRequest)
      .then((res) => {
        //Show message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.note_edited')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
        //Reload previous page
        setReloadEmployeeNotes(true);
      })
      .catch((err) => {
        //Show message
        setShowSnackBarMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_note_editing')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={noteSchema}
      onSubmit={handleAddEditNote}
      initialValues={initialValues}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <div style={{ width: '590px' }}>
          {isLoadingNote ? (
            <Lottie
              animationData={groovyWalkAnimation}
              autoPlay={true}
              loop={true}
              style={{ height: 60 }}
            />
          ) : (
            <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
              <Row className="mb-3">
                <Col xs={3}>
                  <LabelForm tittle={t('form_labels.subject')}></LabelForm>
                </Col>
                <Col xs={9}>
                  <InputForm
                    className="mb-0"
                    inpfontsize={'14px'}
                    height={'40px'}
                    placeholder={t('required')}
                    name="subject"
                    value={values.subject}
                    invalid={touched.subject && errors.subject}
                    onChange={handleChange}
                  ></InputForm>
                  {touched.subject && errors.subject && (
                    <ErrorText className="px-2 mb-0">{errors.subject}</ErrorText>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <LabelForm tittle={t('form_labels.note')}></LabelForm>
                </Col>
                <Col xs={9}>
                  <InputForm
                    placeholder={t('required')}
                    style={{ width: '100%', resize: 'none', marginBottom: '0px' }}
                    inpfontsize={'14px'}
                    name="note"
                    ctype="textarea"
                    rows={6}
                    value={values.note}
                    invalid={touched.note && errors.note}
                    onChange={handleChange}
                  ></InputForm>
                  {touched.note && errors.note && (
                    <ErrorText className="px-2 mb-0">{errors.note}</ErrorText>
                  )}
                </Col>
              </Row>
              <StyleButtons>
                <AppButton
                  loading={loading}
                  style={{ width: 'auto', marginRight: '20px', fontSize: '14px' }}
                  size="lg"
                  type="submit"
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                >
                  {noteData ? `${t('buttons.btn_update')}` : `${t('buttons.btn_add')}`}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={loading}
                  style={{ width: 'auto', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
                {showSnackBarMessage.show && (
                  <CustomizedSnackbar
                    show={showSnackBarMessage.show}
                    type={showSnackBarMessage.type}
                    title={showSnackBarMessage.title}
                    message={showSnackBarMessage.message}
                  />
                )}
              </StyleButtons>
            </Form>
          )}
        </div>
      )}
    </Formik>
  );
};
