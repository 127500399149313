import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { editEntityData, deleteEntityData } from '@services';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { GROUPS_API } from 'services/CONSTANTS';
import { AppModalStatus } from 'components/AppModalStatus';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { GROPUS } from '@navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import { AppModal } from '@components';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const requestEditGroup = {
  name: '',
};

export const EditGroup = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const editGroupSchema = Yup.object().shape({
    name: Yup.string().max(20, `${t('Groups.modal_group_edit.validation.must_be_name')}`).required(`${t('validation.name_required')}`),
  });
  const { id, name } = data;
  //To handle message
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //To handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  //Info to send to component which shows confirmation to delete
  const removeGroupModalContent = {
    buttonText: `${t('buttons.btn_remove')}`,
    actionIcon: ico_Archived,
    title: `${t('Groups.modals.remove_group_title')}`,
    subtitle: `${t('Groups.modals.remove_group_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  let history = useHistory();
  let goTo = (route) => {
    history.push(route);
  };

  /***
   * Shows remove group modal
   */
  const handleRemoveGroupModal = () => {
    setOpenRemoveModal((prev) => {
      return !prev;
    });
  };

  /***
   * Calling to WS to edit a Job Title
   */
  const editGroup = (values) => {
    //Hide message
    setLoading(true);
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for Group view
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    nameWithoutSpaces = nameWithoutSpaces.trim();
    requestEditGroup.name = nameWithoutSpaces;

    editEntityData(`${GROUPS_API}${id}`, requestEditGroup)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.group_edited')}`,
          message: '',
        });
        //Close form modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.group_exists')}`,
            message: '',
          });
        } else {
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_group_editing')}`, message: '' });
        }
        setLoading(false);
      });
  };

  /***
   * Call api to delete the job title by id
   */
  const removeGroup = () => {
    //Hide message
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    setLoading(true);
    //Call api
    deleteEntityData(`${GROUPS_API}${data.id}`, {})
      .then((res) => {
        //Close modal
        handleRemoveGroupModal();
        //Show success message
        setShowMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.group_deleted')}`,
          message: '',
        });
        setTimeout(() => {
          goTo(GROPUS.replace(':type', 'group'));
        }, 2000);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //Close modal
        handleRemoveGroupModal();
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.indexOf('foreign key') !== -1
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_group_deleting_relations')}`,
            message: '',
          });
        } else {
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_group_deleting')}`, message: '' });
        }
      });
  };

  return (
    <Formik
      validationSchema={editGroupSchema}
      onSubmit={editGroup}
      initialValues={{
        name: name,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <div style={{ width: '590px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
            <Row>
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                ></InputForm>
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                    fontSize: '14px',
                  }}
                  loading={loading}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_update')}
                >
                  {t('buttons.btn_update')}
                </AppButton>
                <AppButton
                  size="lg"
                  style={{ width: 'auto', display: 'unset', flexDirection: 'unset', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                  loading={loading}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              <AppButton
                // className="ml-auto p-2"
                style={{ width: 'auto', fontSize: '14px' }}
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.ACCENT_RED}
                color={PALETTE_COLOR.ACCENT_RED}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                onClick={handleRemoveGroupModal}
                title={t('buttons.btn_remove')}
                loading={loading}
              >
                {t('buttons.btn_remove')}
              </AppButton>
            </StyleButtons>
            {/* Remove modal */}
            <AppModal
              open={openRemoveModal}
              handleclose={handleRemoveGroupModal}
              title={''}
              boxshadow={'none'}
              inside={1}
              width={500}
            >
              <AppModalStatus
                mainPadding={'25px 30px 35px 30px'}
                actionIcon={removeGroupModalContent.actionIcon}
                title={removeGroupModalContent.title}
                subtitle={removeGroupModalContent.subtitle}
                bgcolor={removeGroupModalContent.bgcolor}
                bordercolor={removeGroupModalContent.bordercolor}
                borderhovercolor={removeGroupModalContent.borderhovercolor}
                bghovercolor={removeGroupModalContent.bghovercolor}
                boxShadow={removeGroupModalContent.boxShadow}
                buttonText={removeGroupModalContent.buttonText}
                subject={name}
                isLoading={loading}
                buttonAction={removeGroup}
              />
            </AppModal>
          </Form>
          {showMessage.show && (
            <CustomizedSnackbar
              show={showMessage.show}
              type={showMessage.type}
              title={showMessage.title}
              message={showMessage.message}
            />
          )}
        </div>
      )}
    </Formik>
  );
};
