import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { AppButton, UploadProfileImage } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { editEntityData, saveEntityFormData } from '@services';
import { USERS_API } from 'services/CONSTANTS';
import { useDispatch } from 'react-redux';
import { setAppLoadUserAction, setProfilePhotoToSaveAction } from '@redux/actions/appActions';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ContainerWrapper = styled(Container)`
  margin: 0px;
  padding: 0px;
  width: ${(props) => (props.container === 1 ? '800px' : 'unset')};
`;

const ImageRequiredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ff5555;
`;

export const EditProfileImage = (props) => {
  const { t } = useTranslation();
  const imageTypes = [
    {
      label: `${t('Login.choose_avatar.avatar')}`,
      value: 'avatar',
    },
    {
      label: `${t('Login.choose_avatar.photo')}`,
      value: 'photo',
    },
  ];
  const { handleclose, setShowSucessModal, container = 1 } = props;
  const { userData, profileImageToSave } = useSelector((state) => state.app);
  const [loading, setLoading] = React.useState(false);
  const [elementRequired, setElementRequired] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProfilePhotoToSaveAction({ isAvatar: false, config: null }));
    return () => {
      dispatch(setProfilePhotoToSaveAction({ isAvatar: false, config: null }));
    };
  }, [dispatch]);

  /***
   * Save profile photo
   */
  const editUser = () => {
    if (profileImageToSave.config === null) {
      setElementRequired(`${t('validation.avatar_photo')}`);
      setTimeout(() => {
        setElementRequired('');
      }, 3000);
    } else {
      setElementRequired('');
      setLoading(true);
      setShowSucessModal((prev) => {
        return { ...prev, show: false };
      });
      let profilePhoto;
      if (profileImageToSave.isAvatar) {
        //Build request
        profilePhoto = JSON.stringify({
          avatarId: profileImageToSave.config.avatarId,
          gender: profileImageToSave.config.gender,
          skinColor: profileImageToSave.config.skinColor,
          bgColor: profileImageToSave.config.bgColor,
        });
      } else {
        profilePhoto = profileImageToSave.config;
      }
      //Update avatar
      if (profileImageToSave.isAvatar) {
        //Call API
        editEntityData(`${USERS_API}${userData.id}`, {
          avatar_config: profilePhoto,
          photo_url: null,
        })
          .then((res) => {
            //UPDATE LOCAL DATA
            dispatch(setAppLoadUserAction({ ...userData, avatar_config: profilePhoto }));
            setLoading(false);
            //Close modal
            handleclose();
            //Show message on previous view
            setShowSucessModal({
              show: true,
              type: 'success',
              title: `${t('Login.choose_avatar.image_updated')}`,
              message: '',
            });
          })
          .catch((err) => {
            setLoading(false);
            //Close modal
            handleclose();
            setShowSucessModal({
              show: true,
              type: 'error',
              title: `${t('Login.choose_avatar.error_image_updated')}`,
              message: '',
            });
          });
      } else {
        //Cast blob to file
        let file = new File([profilePhoto.blob], `wecare_profile_photo_${Date.now()}.png`, {
          lastModified: Date.now(),
          type: 'image/png',
        });
        let formData = new FormData();
        formData.append('image', file);
        //Call API
        saveEntityFormData(`${USERS_API}upload/photo`, formData)
          .then((res) => {
            //UPDATE LOCAL DATA
            dispatch(
              setAppLoadUserAction({
                ...userData,
                avatar_config: null,
                photo_url: profilePhoto.url,
              }),
            );
            //dispatch(setAppLoadUserAction({ ...userData, avatar_config: null, photo_url: res }));
            setLoading(false);
            //Close modal
            handleclose();
            //Show message on previous view
            setShowSucessModal({
              show: true,
              type: 'success',
              title: `${t('Login.choose_avatar.image_updated')}`,
              message: '',
            });
          })
          .catch((err) => {
            setLoading(false);
            //Close modal
            handleclose();
            setShowSucessModal({
              show: true,
              type: 'error',
              title: `${t('Login.choose_avatar.error_image_updated')}`,
              message: '',
            });
          });
      }
    }
  };

  return (
    <ContainerWrapper container={container}>
      <UploadProfileImage />
      <ImageRequiredText>{elementRequired}</ImageRequiredText>
      {/* Action buttons */}
      {container === 1 && (
        <StyleButtons style={{ marginLeft: 40, marginRight: 40 }}>
          <span>
            <AppButton
              style={{
                fontSize: 14,
                width: 'auto',
                marginRight: 16,
                display: 'unset',
                flexDirection: 'unset',
              }}
              color={PALETTE_COLOR.MAIN_WHITE}
              bgcolor={PALETTE_COLOR.MAIN_GREEN}
              bordercolor={PALETTE_COLOR.MAIN_GREEN}
              bghovercolor={PALETTE_COLOR.MAIN_GREEN}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              size="lg"
              type="submit"
              title={t('buttons.btn_save')}
              onClick={editUser}
              loading={loading}
            >
              {t('buttons.btn_save')}
            </AppButton>
            <AppButton
              size="lg"
              style={{
                fontSize: 14,
                width: 'auto',
                display: 'unset',
                flexDirection: 'unset',
              }}
              color={PALETTE_COLOR.MAIN_BLUE}
              bgcolor={PALETTE_COLOR.MAIN_WHITE}
              bordercolor={PALETTE_COLOR.TOP_GRAY}
              bghovercolor={PALETTE_COLOR.MAIN_BLUE}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              onClick={handleclose}
              title={t('buttons.btn_cancel')}
              loading={loading}
            >
              {t('buttons.btn_cancel')}
            </AppButton>
          </span>
        </StyleButtons>
      )}
    </ContainerWrapper>
  );
};
