// Local endpoints. Always using dummy local data.

export const USERS_API = '/users/api/';
export const ORGANIZATIONS_API = '/organizations/api/';
export const SITES_API = '/sites/api/';
export const ORGANIZATIONS_USERS_API = '/organizations-users/api/';
export const USERS_GROUPS = '/users-groups/api/';
export const SITES_GROUPS_API = '/sites-groups/api/';

export const GROUPS_API = '/groups/api/';
export const VALUES_DEFINITIONS_API = '/values-definitions/api/';
export const SHIFTS_API = '/shifts/api/';
export const SHIFTS_WEEEK_TEMPLATES = '/shifts-week-templates/api/';
export const GET_USER_DETAILS = (id) => `/data/user`;
export const USER_SHIFTS_API = '/users-shifts/api/';
export const SHIFT_ACTIVITY_API = '/shift-activity/api/';
export const NOTIFICATIONS_API = '/app-notifications/api/';
export const TIME_SHEET_API = '/time-sheet/api/';
export const EMPLOYEES_NOTES_API = '/employees-notes/api/';
export const MESSAGES_API = '/messages/api/';
export const CHAT_MESSAGES_API = '/chat-messages/api/';
export const USER_SETTINGS_API = '/user-system-settings/api/';
export const USER_LOCATIONS_API = '/users-shifts-locations/api';
export const REPORT_JOBS_API = '/reports-jobs/api';
export const REPORTS_JOBS_TYPES = {
  ExportEmployees: 'ExportEmployees',
};
