import React from 'react';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Container, Row, Stack } from 'react-bootstrap';

import { AppButton, InputForm, CustomizedSnackbar } from '@components';

import { PALETTE_COLOR } from '@config/CONSTANTS';
import { showMessageAction } from '@redux/actions/appActions';
import { requestDeleteAccount } from 'services';

import logo from '@assets/images/logo.svg';
import ic_black_trash from '@assets/icons/black-trash.svg';

const Main = styled.main`
  display: flex;
  height: 100%;
  width: 100%;
  background: #f5f9ff;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const Logo = styled.img`
  width: 5rem;
  height: 5rem;
`;
const TrashIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

const ContainerWrapper = styled(Container)`
  width: 63rem;
  background: #ffffff;
  border-radius: 1.3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #e7ecf4;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e7ecf4;
  }
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED_DARK2};
`;
const Label = styled.p`
  font-size: 21px;
  font-weight: 700;
  color: ${PALETTE_COLOR.BLUE_DARK};
  margin: 0;
`;

export default function DeleteAccount() {
  const { t } = useTranslation();

  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const dispatch = useDispatch();
  const { messageData } = useSelector((state) => state.app);

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (messageData && messageData.show) {
      setTimeout(() => {
        showMessage({ ...messageData, show: false });
      }, 5000);
    }
  }, [messageData, showMessage]);

  const deleteAccountSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
    username: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.username_required')}`),
    phone: Yup.string().required(`${t('validation.phone_required')}`),
    email: Yup.string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    lastName: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.last_name_required')}`),
    reason: Yup.string()
      .max(500, `${t('validation.must_be_name')}`)
      .required(`${t('validation.body_text_required')}`),
  });

  return (
    <Main>
      <ContainerWrapper>
        {showSuccessAlert ? (
          <Stack
            gap={5}
            style={{
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            <Row className="d-flex justify-content-center">
              <Logo src={logo} alt="logo" />
            </Row>
            <Row className="d-flex justify-content-center">
              <div className="d-flex justify-content-center align-items-center">
                <h2
                  style={{
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  {t('deleteAccount.success')}
                </h2>
              </div>
            </Row>
            <Row>
              <div className="d-flex justify-content-center align-items-center">
                <p
                  style={{
                    fontSize: '17px',
                    fontWeight: 300,
                    textAlign: 'center',
                  }}
                >
                  {t('deleteAccount.info')}
                </p>
              </div>
            </Row>
          </Stack>
        ) : (
          <Formik
            validationSchema={deleteAccountSchema}
            initialValues={{
              name: '',
              phone: '',
              email: '',
              username: '',
              lastName: '',
              reason: '',
            }}
            validateOnChange
            onSubmit={async (data) => {
              try {
                await requestDeleteAccount(data);
                setShowSuccessAlert(true);
              } catch (error) {
                console.error(error);
                showMessage({
                  show: true,
                  type: 'error',
                  title: t('deleteAccount.failed'),
                  message: '',
                });
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <Stack gap={5}>
                <Row className="d-flex justify-content-center">
                  <Logo src={logo} alt="logo" />
                </Row>
                <Row className="d-flex justify-content-center">
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    <TrashIcon src={ic_black_trash} alt="trash-icon" />
                    <Label>{t('deleteAccount.title')}</Label>
                  </div>
                </Row>

                <Row md="2" xs="1">
                  <Stack gap={3}>
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.name')}
                      name="name"
                      value={values.name}
                      invalid={touched.name && errors.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                    )}
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.username')}
                      name="username"
                      value={values.username}
                      invalid={touched.username && errors.username}
                      onChange={handleChange}
                    />
                    {touched.username && errors.username && (
                      <ErrorText className="px-2 mb-0">{errors.username}</ErrorText>
                    )}
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.phone')}
                      name="phone"
                      value={values.phone}
                      invalid={touched.phone && errors.phone}
                      onChange={handleChange}
                    />
                    {touched.phone && errors.phone && (
                      <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                    )}
                  </Stack>
                  <Stack gap={3}>
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.lastName')}
                      name="lastName"
                      value={values.lastName}
                      invalid={touched.lastName && errors.lastName}
                      onChange={handleChange}
                    />
                    {touched.lastName && errors.lastName && (
                      <ErrorText className="px-2 mb-0">{errors.lastName}</ErrorText>
                    )}
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.email')}
                      name="email"
                      value={values.email}
                      invalid={touched.email && errors.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                    )}
                    <InputForm
                      className="mb-0"
                      height={'110px'}
                      inpfontsize={'14px'}
                      placeholder={t('deleteAccount.form.reason')}
                      name="reason"
                      value={values.reason}
                      invalid={touched.reason && errors.reason}
                      onChange={handleChange}
                      ctype="textarea"
                      style={{
                        width: '100%',
                      }}
                    />
                    {touched.reason && errors.reason && (
                      <ErrorText className="px-2 mb-0">{errors.reason}</ErrorText>
                    )}
                  </Stack>
                </Row>
                <Stack>
                  <div className="d-flex justify-content-center">
                    <AppButton
                      bgcolor="#EF4040"
                      bordercolor="#EF4040"
                      borderhovercolor="#EF4040"
                      bghovercolor="#EF4040"
                      size="lg"
                      onClick={handleSubmit}
                    >
                      {t('deleteAccount.submit')}
                    </AppButton>
                  </div>
                  {messageData && messageData.show && (
                    <CustomizedSnackbar
                      show={messageData.show}
                      type={messageData.type}
                      title={messageData.title}
                      message={messageData.message}
                    />
                  )}
                </Stack>
              </Stack>
            )}
          </Formik>
        )}
      </ContainerWrapper>
    </Main>
  );
}
