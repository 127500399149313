import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@szhsin/react-menu';
import Button_Outline from '@assets/icons/Button_Outline.svg';
import { IconButton } from '@mui/material';
import template from '@assets/icons/template.svg';
import { Form } from 'react-bootstrap';
import { object } from 'yup';
import * as yup from 'yup';
import { AppButton, AppModal, InputForm, AppModalStatus, AppComponentLoading } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Formik } from 'formik';
import { saveEntityData, getEntityData } from '@services';
import { SHIFTS_WEEEK_TEMPLATES, SHIFTS_API } from '@services/CONSTANTS';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  showMessageAction,
  showTemplatesAction,
  setReloadSchedulerAction,
} from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { WeekTeemplatesLoader } from './WeekTeemplatesLoader';
import ico_upload from '@assets/icons/ico_upload.svg';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { UnpublishWeek } from './UnpublishWeek';
import * as XLSX from 'xlsx/xlsx.mjs';
import latinize from 'latinize';

import { useTranslation } from 'react-i18next';

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Icon = styled.img``;

const ModalContentWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  background-color: ${(props) => (props.bg ? props.bg : `${PALETTE_COLOR.MAIN_WHITE}`)};
  padding: 5px 30px 34px 30px;
`;

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const InputIcon = styled.img``;

const ModalText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
`;

const DivBlueBox = styled.div`
  background: #e7f5ff;
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: 10px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK} p {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-size: 32px;
  }
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const AditionalActions = ({
  currentdata,
  currentWeek,
  type,
  group = '',
  allowEditActions,
}) => {
  const { t } = useTranslation();
  const prevStart = moment.utc(currentWeek.start).add(-7, 'day');
  const prevEnd = moment.utc(currentWeek.start).add(-1, 'day');
  const dispatch = useDispatch();
  const [openSaveShiftsWeekTemplate, setOpenSaveShiftsWeekTemplate] = useState(false);
  const [openUnpublishShiftsWeek, setOpenUnpublishShiftsWeek] = useState(false);
  const [initialValuesTemplate, setInitialValuesTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openTemplates, setOpenTemplates] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [loadingPrevWeek, setLoadingPrevWeek] = useState(false);
  const [prevWeekData, setPrevWeekData] = useState([]);
  const { jobSchedulerQueries } = useSelector((state) => state.app);

  const confirmCopy = {
    buttonText: `${t('buttons.btn_copy')}`,
    actionIcon: ico_upload,
    title: `${t('JobScheduler.modals.copy_shifts_title')}`,
    subtitle: ``,
    bgcolor: PALETTE_COLOR.MAIN_BLUE,
    bordercolor: PALETTE_COLOR.MAIN_BLUE,
    borderhovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    bghovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  };

  //Array to be returned to parent
  const exportRef = React.useRef();
  const updateShowWeekTemplate = React.useCallback(
    async (payload) => {
      dispatch(showTemplatesAction(payload));
    },
    [dispatch],
  );

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  const handleClickCloseTemplatesModal = () => {
    setOpenTemplates(false);
  };

  const handleClickOpenSaveShiftsWeekTemplateModal = () => {
    setOpenSaveShiftsWeekTemplate(true);
  };

  const handleClickCloseSaveShiftsWeekTemplateModal = () => {
    setOpenSaveShiftsWeekTemplate(false);
  };

  /***
   * Show or hide modal
   */
  const handleUnpublishWeekModal = () => {
    setOpenUnpublishShiftsWeek(!openUnpublishShiftsWeek);
  };

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  useEffect(() => {
    let values = {
      name: '',
      total_shifts: 0,
      shifts: [],
    };
    setInitialValuesTemplate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openCopy) {
      setLoadingPrevWeek(true);
      let currentAnd = JSON.parse(jobSchedulerQueries?.draft?.queryObject?.s).$and[0];
      currentAnd.shift_date = {
        $between: [
          `${prevStart.format('YYYY-MM-DD')} 00:00`,
          `${prevEnd.format('YYYY-MM-DD')} 23:59`,
        ],
      };
      jobSchedulerQueries.draft.search({
        $and: [currentAnd],
      });
      getEntityData(SHIFTS_API, jobSchedulerQueries.draft.query())
        .then((res) => {
          //USER allow to see SHIFTS
          let currentSearch = JSON.parse(jobSchedulerQueries?.publish.queryObject.s).$and;
          currentSearch[0] = {
            ...currentSearch[0],
            shift_date: {
              $between: [
                `${prevStart.format('YYYY-MM-DD')} 00:00`,
                `${prevEnd.format('YYYY-MM-DD')} 23:59`,
              ],
            },
          };
          jobSchedulerQueries.publish.search({
            $and: currentSearch,
          });
          getEntityData(SHIFTS_API, jobSchedulerQueries.publish.query())
            .then((resShifts) => {
              setLoadingPrevWeek(false);
              setPrevWeekData([...res, ...resShifts]);
            })
            .catch((err) => {
              setLoadingPrevWeek(false);
              console.log('axios err=', err);
            });
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCopy]);

  const copyShifts = () => {
    let values = {};
    values.prevStartAt = moment
      .utc(prevStart)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    values.prevEndAt = moment
      .utc(prevEnd)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    values.startAt = moment
      .utc(currentWeek.start)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    values.endAt = moment
      .utc(currentWeek.end)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    values.shifts = prevWeekData.map((pw) => ({ id: pw.id }));
    setLoading(true);
    saveEntityData(`${SHIFTS_API}schedule/copy/week`, { ...values })
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.previous_week_shifts_copy_created')}`,
          message: '',
        });
        //Close form modal
        setOpenCopy(false);
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_previous_week_shifts_copy_creating')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  const saveWeekTemplate = (values) => {
    if (currentdata && currentdata.length > 0) {
      values.total_shifts = currentdata.length;
      values.type = type;
      currentdata.forEach((data, i) => {
        delete data.rows;
        values.shifts[i] = { id: data.id };
      });
      values.startAt = moment
        .utc(currentWeek.start)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format();
      values.endAt = moment
        .utc(currentWeek.end)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format();
      setLoading(true);
      saveEntityData(`${SHIFTS_WEEEK_TEMPLATES}`, values)
        .then((res) => {
          showMessage({
            show: true,
            type: 'success',
            title: `${t('success_messages.weekly_shift_template_created')}`,
            message: '',
          });
          //Close form modal
          handleClickCloseSaveShiftsWeekTemplateModal();
          setLoading(false);
        })
        .catch((err) => {
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_weekly_shift_template_creating')}`,
            message: '',
          });
          setLoading(false);
        });
    }
  };

  const mapDataToExport = () => {
    //console.log('data export', currentdata);
    return currentdata.map((cd) => ({
      date: moment.utc(cd.shift_date).format('MM/DD/YYYY'),
      start: moment.utc(cd.start_time).format('HH:mm a'),
      end: moment.utc(cd.end_time).format('HH:mm a'),
      shiftTitle: cd.title ? cd.title : '',
      job: cd.jobs_titles?.map((jobtitle) => jobtitle.jobtitle.description).join(', '),
      draft: cd.status?.value_definition === '020' ? 'true' : 'false',
      user: cd.user_shifts?.map((us) => us.user.name).join(', '),
      location: cd.site?.name,
      note: '',
      noteha: '',
      lastStatus: cd.status?.description,
      task: '',
      checkInNote: '',
    }));
  };

  const printData = () => {
    const input = document.getElementsByClassName('appTable')[0];
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var i = new Image();
      i.onload = function () {
        console.log('🚀 ~ file: index.js ~ line 347 ~ html2canvas ~ i', i.width + ', ' + i.height);
      };
      i.src = imgData;
      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16, // or "smart", default is 16
      });
      pdf.setFontSize(14);
      pdf.text(`${t('JobScheduler.job_scheduler')}`, 15, 15);
      pdf.setFontSize(20);
      pdf.text(
        `${moment(currentWeek.start).format('MMMM DD, YYYY')} - ${moment(currentWeek.end).format(
          'MMMM DD, YYYY',
        )}`,
        90,
        14,
      );
      pdf.setFontSize(14);
      pdf.text(`${group.name}`, 265, 15);
      pdf.addImage(imgData, 'JPEG', 15, 25, 271, i.height);
      // pdf.output('dataurlnewwindow');
      pdf.save('download.pdf');
    });
  };

  return (
    <>
      <Menu
        align="end"
        direction="bottom"
        menuButton={
          <DropDownWrapper>
            <IconButton aria-label="edit">
              <Icon src={Button_Outline} />
            </IconButton>
          </DropDownWrapper>
        }
        transition
      >
        {
          //Only super admin, group admin and SM-OG full acess can edit shifts
          allowEditActions && (
            <>
              <MenuItem onClick={() => setOpenCopy(true)}>{t(`JobScheduler.menu.copy_previous_${type.toLowerCase()}`)}</MenuItem>
              <MenuItem onClick={() => handleClickOpenSaveShiftsWeekTemplateModal()}>
                {t(`JobScheduler.menu.save_${type.toLowerCase()}_as_template`)}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  updateShowWeekTemplate({ visible: true, title: `${t(`JobScheduler.menu.${type.toLowerCase()}_templates`)}`, type })
                }
              >
                {t(`JobScheduler.menu.load_${type.toLowerCase()}_template`)}
              </MenuItem>
              {/* <MenuItem
                disabled={!moment.utc(new Date()).isBefore(currentWeek.end)}
                onClick={() => handleUnpublishWeekModal()}
              >
                {t(`JobScheduler.menu.unpublish_${type.toLowerCase()}`)}
              </MenuItem> */}
            </>
          )
        }
        <MenuItem
          onClick={() => {
            const worksheet = XLSX.utils.json_to_sheet(mapDataToExport());
            // Process Data (add a new row)
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
            XLSX.utils.sheet_add_aoa(
              worksheet,
              [
                [
                  'Date',
                  'Start',
                  'End',
                  'Shift title',
                  'Job',
                  'Draft',
                  'User',
                  'Location',
                  'Note',
                  'Note has Attachments',
                  'Last status',
                  'Task',
                  'Check In',
                ],
              ],
              { origin: 'A1' },
            );
            XLSX.writeFile(
              workbook,
              `${latinize(t('JobScheduler.job_scheduler'))} - ${moment(currentWeek.start).format('MMMM DD, YYYY')} - ${moment(
                currentWeek.end,
              ).format('MMMM DD, YYYY')}.xlsx`,
            );
          }}
        >
          {t(`JobScheduler.menu.export_${type.toLowerCase()}`)}
        </MenuItem>
        {/* {type === 'Week' && (
          <MenuItem onClick={() => printData()}>
            {t('JobScheduler.menu.print')}
          </MenuItem>
        )} */}
      </Menu>

      {openUnpublishShiftsWeek && (
        <UnpublishWeek
          currentdata={currentdata}
          currentWeek={currentWeek}
          // type={type}
          // row={row}
          handleUnpublishWeekModal={handleUnpublishWeekModal}
          openUnpublishShiftsWeek={openUnpublishShiftsWeek}
        />
      )}

      <AppModal
        open={openSaveShiftsWeekTemplate}
        handleclose={handleClickCloseSaveShiftsWeekTemplateModal}
        inside={1}
      >
        <ModalContentWrapper width={'600px'}>
          <StyleSpaceIcon style={{ marginTop: 30, marginBottom: 24 }}>
            <InputIcon src={template} />
          </StyleSpaceIcon>
          <ModalText style={{ marginBottom: 16 }}>{t(`JobScheduler.modals.title_${type.toLowerCase()}_template_title`)}</ModalText>
          <Formik
            validationSchema={object().shape({
              name: yup.string().required(`${t('validation.title_required')}`),
            })}
            onSubmit={saveWeekTemplate}
            initialValues={initialValuesTemplate}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <div style={{ width: '600px' }}>
                <Form onSubmit={handleSubmit} noValidate className="baseForm">
                  <InputForm
                    className="mb-0"
                    height={'40px'}
                    width={'530px'}
                    placeholder={t('placeholder_title')}
                    name="name"
                    value={values.name}
                    invalid={touched.name && errors.name}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name && (
                    <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                  )}
                  <DivBlueBox width={'530px'}>
                    <p>{t('JobScheduler.modals.subtitle_save_info')}</p>
                    <span>{currentdata.length}</span>
                  </DivBlueBox>
                  <StyleSpaceButtons width={'530px'}>
                    <AppButton
                      style={{ width: 'auto', height: 64 }}
                      type="submit"
                      loading={loading}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      bgcolor={PALETTE_COLOR.MAIN_BLUE}
                      bordercolor={PALETTE_COLOR.MAIN_BLUE_LIGHT}
                      borderhovercolor={PALETTE_COLOR.MAIN_BLUE_LIGHT}
                    >
                      {t('buttons.btn_save')}
                    </AppButton>
                  </StyleSpaceButtons>
                </Form>
              </div>
            )}
          </Formik>
        </ModalContentWrapper>
      </AppModal>

      {/*Open Templates*/}
      <AppModal
        open={openTemplates}
        handleclose={handleClickCloseTemplatesModal}
        title={t('JobScheduler.modals.week_templates_title')}
        inside={1}
        styleModal={{ position: 'absolute', bottom: '0px', right: '0px' }}
      >
        <WeekTeemplatesLoader handleclose={handleClickCloseTemplatesModal} type={type} />
      </AppModal>

      {/**********COPY ***** */}
      <AppModal
        open={openCopy}
        handleclose={() => setOpenCopy(false)}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={confirmCopy.actionIcon}
          title={confirmCopy.title}
          subtitle={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <div style={{ color: '#000000', fontSize: 16, fontWeight: 400 }}>
                {t('JobScheduler.modals.copy_shifts_subtitle')}
              </div>
              <div
                style={{
                  background: '#E7F5FF',
                  borderRadius: 10,
                  height: 94,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <div style={{ fontWeight: 400, fontSize: 13 }}>{t('JobScheduler.modals.subtitle_copy_info')}</div>
                {loadingPrevWeek ? (
                  <AppComponentLoading style={{ width: 'auto' }} />
                ) : (
                  <div style={{ fontWeight: 400, fontSize: 32 }}> {prevWeekData.length}</div>
                )}
              </div>
            </div>
          }
          bgcolor={confirmCopy.bgcolor}
          bordercolor={confirmCopy.bordercolor}
          borderhovercolor={confirmCopy.borderhovercolor}
          bghovercolor={confirmCopy.bghovercolor}
          boxShadow={confirmCopy.boxShadow}
          buttonText={confirmCopy.buttonText}
          subject={''}
          isLoading={loadingPrevWeek || loading}
          buttonAction={() => copyShifts()}
        />
      </AppModal>

      <CSVLink
        headers={[
          { label: 'Date', key: 'date' },
          { label: 'Start', key: 'start' },
          { label: 'End', key: 'end' },
          { label: 'Shift title', key: 'shiftTitle' },
          { label: 'Job', key: 'job' },
          { label: 'Draft', key: 'draft' },
          { label: 'User', key: 'user' },
          { label: 'Location', key: 'location' },
          { label: 'Note', key: 'note' },
          { label: 'Note has Attachments', key: 'noteha' },
          { label: 'Last status', key: 'lastStatus' },
          { label: 'Task', key: 'task' },
          { label: 'Check In', key: 'checkInNote' },
        ]}
        filename={`${t('JobScheduler.schedule_export')} ${moment(currentWeek.start).format('YYYY-MM-DD')} ${t('JobScheduler.to')} ${moment(
          currentWeek.end,
        ).format('YYYY-MM-DD')}`}
        data={mapDataToExport()}
        ref={exportRef}
      />
    </>
  );
};
