import { useState, useEffect } from 'react';
import { useAuth } from '@auth/ProvideAuth';
import { editEntityData, getEntityData, getUserDetails } from 'services';
import { VALUES_DEFINITIONS_API } from '@services/CONSTANTS';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import {
  setAppLoadValuesDefinitionsAction,
  setAppLoadUserAction,
  setUserPermissionsAction,
} from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { USERS_API } from 'services/CONSTANTS';
const { detect } = require('detect-browser');

export function useCognitoAuth() {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  let auth = useAuth();
  const dispatch = useDispatch();
  const qb = RequestQueryBuilder.create().sortBy({ field: 'value_definition_id', order: 'DESC' });

  async function getUser() {
    try {
      setLoading(true);
      const cognitoUserData = await auth.getUserData();
      setCognitoUser(cognitoUserData);
      setLoading(false);
      getEntityData(VALUES_DEFINITIONS_API, qb.query())
        .then((res) => {
          dispatch(setAppLoadValuesDefinitionsAction(res));
        })
        .catch((err) => {
          console.log('App >> LOAD_VALUES_DEFINITIONS >> axios err=', err);
        });
      //load current user info
      getUserDetails(cognitoUserData.idToken?.payload?.sub)
        .then(async (res) => {
          //Return an array with groups and permission, a more digestible one. Only for system users
          if (cognitoUserData?.idToken?.payload['cognito:groups'][0] === 'system') {
            const groups = await getGroupsForSystemUser(res?.user_groups);
            const permission = await getPermissionForSystemUser(res?.user_groups);
            dispatch(
              setUserPermissionsAction({
                type: 'system',
                role: res?.role?.value_definition,
                systemUserGroups: groups,
                systemUserPermission: permission,
              }),
            );
            dispatch(
              setAppLoadUserAction({
                ...res,
                cognitoUser: cognitoUserData,
              }),
            );
          } else {
            dispatch(
              setAppLoadUserAction({
                ...res,
                cognitoUser: cognitoUserData,
              }),
            );
            dispatch(
              setUserPermissionsAction({
                type: cognitoUserData?.idToken?.payload['cognito:groups'][0],
                role: res?.role?.value_definition,
              }),
            );
          }
          //send FCM information
          const browser = detect();
          editEntityData(`${USERS_API}logUser/information/device`, {
            user: res,
            deviceName: browser.name,
            os: browser.os,
            version: browser.version,
            fcmtoken: localStorage.getItem('fcmtoken'),
          })
            .then((res) => {})
            .catch((err) => {
              console.log('App >> setting logged user >> axios err=', err);
            });
        })
        .catch((err) => {
          console.log('App >> Getting logged user >> axios err=', err);
        });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser(); // eslint-disable-next-line
  }, []);

  //To sort
  function compare(a, b) {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
  }

  /***
   * Build an array of groups
   */
  const getGroupsForSystemUser = (userGroups) => {
    return new Promise((resolve, reject) => {
      let groups = userGroups.map((ug) => {
        return {
          id: ug?.group?.id,
          name: ug?.group?.name,
          permissionId: ug?.group_permission?.value_definition_id,
          permission: ug?.group_permission?.value_definition,
          permissionName: ug?.group_permission?.description,
        };
      });
      //Sort
      resolve((groups = groups.sort(compare)));
    });
  };

  /***
   * Return true if system user has more than one group with full access permission
   */
  const getPermissionForSystemUser = (userGroups) => {
    return new Promise((resolve, reject) => {
      resolve(userGroups.filter((g) => g?.group_permission?.value_definition === '020').length > 0);
    });
  };

  return [cognitoUser, error, loading]; // highlight-line
}
