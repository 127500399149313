import React, { useState } from 'react';
import styled from 'styled-components';

const defaultColours = [
  '#FFE7D6',
  '#CBF3ED',
  '#E7DFFF',
  '#D0ECFF',
  '#D6FFBD',
  '#FFD9D9',
  '#F4F6C1',
  '#FEDFFE',
  '#D6DEFF',
  '#CBF9F6',
];

const StyleButtonSelected = styled.span`
  margin-left: -11px;
  width: 40%;
  height: 40%;
  padding: 7px;
  border-radius: 50%;
  color: black;
`;

const StyleButton = styled.span`
  margin-left: -11px;
  width: 40%;
  height: 40%;
  padding: 7px;
  background-color: #eff4fb;
  border-radius: 50%;
  color: #bec9d6;
`;

const StyleOptions = styled.div`
  box-shadow: rgb(0 0 0 / 18%) 0px 6px 12px;
  z-index: 10;
  background: white;
  border-radius: 16px;
  position: absolute;
`;

const StyleRow = styled.ul`
  width: 262px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 1px;
`;

const StyleRowLine = styled.li`
  list-style: none;
  float: left;
  text-align: center;
  padding: 5px;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 5px;
`;
const StyleRowLineSpan = styled.span`
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  display: block;
  &.border-radius {
    border-radius: 50%;
  }
  &:hover,
  &.selected {
    border: 2px solid black;
    &:before {
      content: ' ';
      border-radius: 50%;
      position: absolute;
      z-index: 100;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border: 2px solid #fff;
    }
  }
`;

export const IconColourPicker = ({ defaultValue, onClick, testId }) => {
  const [selectedColour, setSelectedColour] = useState(defaultValue);
  const [wrapperRef, setWrapperRef] = React.useState(false);
  const openToggleDropdown = () => {
    wrapperRef ? setWrapperRef(false) : setWrapperRef(true);
  };
  const updateOnClick = (value) => {
    setSelectedColour(value);
    onClick(value);
    openToggleDropdown(false);
  };

  const componentColours = defaultColours;
  return (
    <div className={`dropdown icon-picker open`}>
      <button
        className="btn btn-default dropdown-toggle"
        type="button"
        id="dropdownMenu1"
        data-testid={testId}
        onClick={() => openToggleDropdown(true)}
      >
        {selectedColour ? (
          <StyleButtonSelected style={{ backgroundColor: selectedColour }}> CL</StyleButtonSelected>
        ) : (
          <StyleButton>CL</StyleButton>
        )}
      </button>
      {wrapperRef ? (
        <StyleOptions>
          <StyleRow>
            {componentColours.map((colour, index) => {
              return (
                <StyleRowLine
                  key={index}
                  data-testid={`${testId}_color_${index}`}
                  onClick={() => updateOnClick(colour)}
                >
                  <StyleRowLineSpan
                    className={`color border-radius ${selectedColour === colour ? 'selected' : ''}`}
                    style={{
                      border: `6px solid ${colour}`,
                      backgroundColor: colour,
                    }}
                  ></StyleRowLineSpan>
                </StyleRowLine>
              );
            })}
          </StyleRow>
        </StyleOptions>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default IconColourPicker;
