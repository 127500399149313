import React, { useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { AppButton } from 'components';
import { ORGANIZATIONS_API } from 'services/CONSTANTS';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { useSelector } from 'react-redux';
import { editEntityData } from '@services';

import { useTranslation } from 'react-i18next';

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleSpaceLabel = styled.div`
  display: flex;
  justify-content: center;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 10px 10px 10px 10px;
`;

const StyleSpaceName = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ff5555;
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalText = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  padding: 5px 5px 5px 5px;
  text-align: center;
`;

const InputIcon = styled.img``;

const ModalContentWrapper = styled.div`
  padding: 5px 75px 34px 75px;
`;

export const ArchiveOrganization = ({
  handleCloseOpenArchiveModal,
  setShowArchivedModal,
  data,
  finish,
}) => {
  const { t } = useTranslation();
  let requestArchiveOrganization = {
    status_id: {},
  };
  const { valuesDefinitions } = useSelector((state) => state.app);
  const status_id = valuesDefinitions.filter(
    (v) =>
      v.definitionType.definition_type === 'ORGANIZATION_STATUS' && v.value_definition === '030',
  )[0];

  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const archiveOrganizations = (values) => {
    setLoading(true);
    //Hide message
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for GroupList view
    setShowArchivedModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    //Add group
    requestArchiveOrganization.status_id = status_id;
    editEntityData(`${ORGANIZATIONS_API}${data.id}`, requestArchiveOrganization)
      .then((res) => {
        //Show message on GroupList view
        setShowArchivedModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.organization_archived')}`,
          message: '',
        });
        //Close modal
        handleCloseOpenArchiveModal();
        finish();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          //Show message
          showErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.organization_exists')}`,
            message: '',
          });
        } else {
          //Show message
          showErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_organization_editing')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };
  return (
    <ModalContentWrapper>
      <StyleSpaceIcon>
        <InputIcon src={ico_Archived} />
      </StyleSpaceIcon>
      <StyleSpaceLabel>
        {t('Organization.modals.archive_organization_title')}
      </StyleSpaceLabel>
      <StyleSpaceName>{data?.name}</StyleSpaceName>
      <ModalText className="mb-0">
        {t('Organization.modals.archive_organization_subtitle')}
      </ModalText>
      <StyleSpaceButtons>
        <Formik
          onSubmit={archiveOrganizations}
          initialValues={{
            email: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
            <Form noValidate onSubmit={handleSubmit} className="baseForm">
              <AppButton
                style={{ width: 'auto', height: 64, marginTop: '6%' }}
                type="submit"
                bgcolor="#FF5555"
                bordercolor={PALETTE_COLOR.ACCENT_RED}
                borderhovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
                bghovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
                loading={loading}
              >
                {t('buttons.btn_archive')}
              </AppButton>
            </Form>
          )}
        </Formik>
      </StyleSpaceButtons>
    </ModalContentWrapper>
  );
};
