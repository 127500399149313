import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppButton,
  InputForm,
  CustomizedSnackbar,
  AppDropDownCheckBoxSelect,
  AppAsyncLocationDropdown,
  AppModal,
} from '@components';
import { editEntityData, getEntityData, obtainEntityData, saveEntityData } from '@services';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { USERS_API, VALUES_DEFINITIONS_API, GROUPS_API } from '@services/CONSTANTS';
import { AppDropDownSelect } from '@components/AppDropDownSelect';
import dollarCircleIcon from '@assets/icons/dollar_circle.svg';
import ico_warning_triangle from '@assets/icons/ico_warning_triangle.svg';
import { AppModalStatus } from '@components/AppModalStatus';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  height: 80px;
  display: flex;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

let reqRestoreEmployee = {
  name: '',
  phone: '',
  email: '',
  pay_rate: '',
  home_address: '',
  type: {},
  action: '',
  request_jobs_titles: [],
  request_user_groups: [],
};

export const RestoreEmplyUserForm = ({ handleclose, setShowSucessModal, id, jobTittles }) => {
  const { t } = useTranslation();
  const restoreEmployeeSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
    phone: Yup.string().required(`${t('validation.phone_required')}`),
    email: Yup.string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    groups: Yup.array().nullable(),
    pay_rate: Yup.string()
      .required(`${t('validation.pay_rate_required')}`)
      .matches(/^\d{1,5}(\.\d{1,2})?$/, `${t('validation.pay_rate_invalid')}`),
    home_address: Yup.object()
      .nullable()
      .required(`${t('validation.home_address_required')}`),
    employee_id: Yup.string()
      .max(10, `${t('Users.employees.modal_edit.validation.must_be_employee')}`)
      .required(`${t('validation.employee_id_required')}`),
    job_title_id: Yup.object()
      .nullable()
      .required(`${t('validation.job_title_required')}`),
    action: Yup.string(),
  });
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //Open/close modal
  const [openEmailConfirmation, setOpenEmailConfirmation] = useState(false);
  //Save form values
  const formValues = useRef({});
  //Redux
  const { userPermissions } = useSelector((state) => state.app);
  //Content to show in change email modal
  const changeEmailModalData = {
    buttonText: `${t('buttons.btn_confirm')}`,
    actionIcon: ico_warning_triangle,
    title: ``,
    subtitle: `${t('Profile.change_personal.changing_email_description')}`,
    subtitleWeight: 400,
    subtitleColor: PALETTE_COLOR.BLACK,
    subjectColor: '#000000',
    subjectWeight: 400,
    bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
    bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
    borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
  };

  /***
   * Open/close change email modal
   */
  const handleChangeEmailModal = () => {
    setOpenEmailConfirmation(!openEmailConfirmation);
  };

  /***
   *
   */
  useEffect(() => {
    setLoading(true);
    obtainEntityData(`${USERS_API}${id}`)
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoading(false);
      });
  }, [id]);

  /***
   *
   */
  useEffect(() => {
    setLoadingJobTitles(true);
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'description', order: 'ASC' })
      .select(['definition_type_id', 'description'])
      .setFilter({
        field: 'definitionType.definition_type',
        operator: CondOperator.EQUALS,
        value: 'JOBS_TYPES',
      });
    getEntityData(VALUES_DEFINITIONS_API, qb.query())
      .then((res) => {
        setJobTitlesList(res);
        setLoadingJobTitles(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoadingJobTitles(false);
      });
  }, []);

  /***
   *
   */
  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          console.log('Location aws results=', res);
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  /***
   * Get groups to filter
   */
  useEffect(() => {
    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroupsList(
            userPermissions?.systemUserGroups?.filter((group) => group.permission === '020'),
          );
        } else {
          setGroupsList([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroupsList(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions]);

  const { valuesDefinitions } = useSelector((state) => state.app);
  const employeeType = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_TYPES' && v.value_definition === '010',
  )[0]; //Employees
  const statusUserList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS',
  );
  reqRestoreEmployee.type = employeeType;

  /***
   * Decides if open change email modal or not
   */
  const restoreUserChangeEmail = (values) => {
    formValues.current = values;
    if (user.email !== values.email) {
      handleChangeEmailModal();
    } else {
      restoreEmployee();
    }
  };

  /***
   *
   */
  const restoreEmployee = () => {
    setOpenEmailConfirmation(false);
    reqRestoreEmployee.name = formValues.current.name;
    reqRestoreEmployee.phone = formValues.current.phone;
    reqRestoreEmployee.email = formValues.current.email;
    reqRestoreEmployee.pay_rate = formValues.current.pay_rate;
    reqRestoreEmployee.home_address = formValues.current.home_address.value;
    reqRestoreEmployee.employee_id = formValues.current.employee_id;
    const status = statusUserList.filter(
      (status) => status.value_definition === formValues.current.action,
    )[0];
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    const request_jobs_titles = [formValues.current.job_title_id.value];
    const request_user_groups = formValues.current?.groups.map((s) => ({
      group: s.value,
      group_permission: {
        value_definition_id: null,
      },
    }));
    reqRestoreEmployee.status = user.status;
    reqRestoreEmployee = {
      ...reqRestoreEmployee,
      request_jobs_titles,
      request_user_groups,
      status,
    };
    setRequestLoading(true);
    editEntityData(`${USERS_API}${id}`, reqRestoreEmployee)
      .then((res) => {
        //Show message on GroupList view
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_edited')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setRequestLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          if (err.response?.data?.message === 'employeeID') {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.employee_id_exists')}`,
              message: '',
            });
          } else {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.user_exists')}`,
              message: '',
            });
          }
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_user_editing')}`,
            message: '',
          });
        }
        setRequestLoading(false);
      });
  };

  /*if (loading) {
    return <h3 style={{ width: 600, textAlign: 'center' }}>{'Loading...'}</h3>;
  }*/

  if (loading) {
    return (
      <div style={{ width: '590px' }}>
        <Lottie
          animationData={groovyWalkAnimation}
          autoPlay={true}
          loop={true}
          style={{ height: 60 }}
        />
      </div>
    );
  }
  return (
    <Formik
      validationSchema={restoreEmployeeSchema}
      onSubmit={restoreUserChangeEmail}
      initialValues={{
        name: user.name,
        email: user.email,
        phone: user.phone,
        pay_rate: user.pay_rate,
        home_address: { label: user.home_address, value: user.home_address },
        employee_id: user.employee_id,
        job_title_id:
          user?.jobs_titles && user?.jobs_titles.length > 0
            ? {
                label: user.jobs_titles[0].job_title.description,
                value: user.jobs_titles[0].job_title,
              }
            : null,
        groups: user.user_groups
          ? user.user_groups.map((gs) => ({ label: gs.group.name, value: gs.group.id }))
          : [],
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px', fontSize: '14px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  maxLength={100}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.job_title')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={jobTitlesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="job_title_id"
                  placeholder={t('required')}
                  isLoading={loadingJobTitles}
                  onChange={(selected) => {
                    setFieldValue('job_title_id', selected);
                  }}
                  invalid={touched.job_title_id && errors.job_title_id}
                  defaultValue={values.job_title_id}
                />
                {touched.job_title_id && errors.job_title_id && (
                  <ErrorText className="px-2 mb-0">{errors.job_title_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.groups')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownCheckBoxSelect
                  isSearchable={true}
                  allowSelectAll={false}
                  height="7rem"
                  width={390}
                  options={groupsList.map((option) => ({ label: option.name, value: option }))}
                  defaultValue={values.groups}
                  onChange={(selected, event, selectedValues) => {
                    setFieldValue('groups', selectedValues);
                  }}
                  targetComponent={
                    <AppButton
                      size="lg"
                      loading={requestLoading}
                      style={{
                        fontSize: 14,
                        width: 'auto',
                        display: 'unset',
                        flexDirection: 'unset',
                      }}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      hovercolor={PALETTE_COLOR.MAIN_BLUE}
                    >
                      {t('buttons.btn_add')}
                    </AppButton>
                  }
                />
                {touched.groups && errors.groups && (
                  <ErrorText className="px-2 mb-0">{errors.groups}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.pay_rate')}></LabelForm>
              </Col>
              <Col xs={5}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  icon={dollarCircleIcon}
                  iconpaddingright="unset"
                  placeholder={t('placeholder_zero_money')}
                  name="pay_rate"
                  value={values.pay_rate}
                  invalid={touched.pay_rate && errors.pay_rate}
                  onChange={handleChange}
                  maxLength="8"
                />
                {touched.pay_rate && errors.pay_rate && (
                  <ErrorText className="px-2 mb-0">{errors.pay_rate}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.address')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppAsyncLocationDropdown
                  loadOptions={(values) => loadLocationOptions(values)}
                  //onInputChange={(value) => setQuery(value)}
                  setLeftPadding
                  height="200px"
                  placeholder={t('required')}
                  isClearable={true}
                  name="home_address"
                  defaultValue={values.home_address}
                  onChange={(selected) => {
                    setFieldValue('home_address', selected?.value);
                  }}
                />
                {touched.home_address && errors.home_address && (
                  <ErrorText className="px-2 mb-0">{errors.home_address}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.employee_id')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('required')}
                  name="employee_id"
                  value={values.employee_id}
                  invalid={touched.employee_id && errors.employee_id}
                  maxLength={10}
                  onChange={handleChange}
                />
                {touched.employee_id && errors.employee_id && (
                  <ErrorText className="px-2 mb-0">{errors.employee_id}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={requestLoading}
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                    fontSize: 14,
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  type="submit"
                  title={t('buttons.btn_restore_invite')}
                  onClick={() => {
                    setFieldValue('action', '030');
                  }}
                >
                  {t('buttons.btn_restore_invite')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={requestLoading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    // marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  type="submit"
                  title={t('buttons.btn_restore_blocked')}
                  onClick={() => {
                    setFieldValue('action', '050');
                  }}
                >
                  {t('buttons.btn_restore_blocked')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={requestLoading}
                  style={{
                    width: 'auto',
                    display: 'unset',
                    flexDirection: 'unset',
                    fontSize: 14,
                    marginLeft: '17px',
                  }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
                {showErrorMessage.show && (
                  <CustomizedSnackbar
                    show={showErrorMessage.show}
                    type={showErrorMessage.type}
                    title={showErrorMessage.title}
                    message={showErrorMessage.message}
                  />
                )}{' '}
              </span>{' '}
            </StyleButtons>
          </Form>
          {/* Change e-mail request */}
          <AppModal
            width={500}
            styleModal={{ padding: '30px 5px 30px 5px', width: '600px' }}
            open={openEmailConfirmation}
            handleclose={handleChangeEmailModal}
            title={''}
            boxshadow={'none'}
            inside={1}
          >
            <AppModalStatus
              actionIcon={changeEmailModalData.actionIcon}
              title={`${t('Profile.change_personal.changing_email')}`}
              subtitle={''}
              subtitleColor={changeEmailModalData.subtitleColor}
              subtitleWeight={changeEmailModalData.subtitleWeight}
              bgcolor={changeEmailModalData.bgcolor}
              bordercolor={changeEmailModalData.bordercolor}
              borderhovercolor={changeEmailModalData.borderhovercolor}
              bghovercolor={changeEmailModalData.bghovercolor}
              boxShadow={changeEmailModalData.boxShadow}
              buttonText={changeEmailModalData.buttonText}
              subject={changeEmailModalData.subtitle}
              subjectColor={changeEmailModalData.subjectColor}
              subjectWeight={changeEmailModalData.subjectWeight}
              buttonAction={restoreEmployee}
              isLoading={loading}
            />
          </AppModal>
        </div>
      )}
    </Formik>
  );
};
