import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AppTable, AppAvatar, AppModal, AppModalNotes, AppButton } from '@components';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getEntityData } from '@services';
import { USERS_API, USER_SHIFTS_API } from '@services/CONSTANTS';
import { TIME_SHEET_API } from '@services/CONSTANTS';
import logo_edit from '@assets/icons/edit.svg';
import ic_close_shift from '@assets/icons/ic_close_shift.svg';
import ic_document from '@assets/icons/ic_document.svg';
import { useDispatch } from 'react-redux';
import ic_status_signed from '@assets/icons/ic_status_signed.svg';
import ic_status_unsigned from '@assets/icons/ic_status_unsigned.svg';
import ic_status_no_time from '@assets/icons/ic_status_no_time.svg';
import ic_close_shift_modal from '@assets/icons/ic_close_shift_modal.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { TimeSheetForm } from '@pages/TimeSheet/TimeSheetForm';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import DPICon from '@assets/icons/ic_datepicker.svg';
import { PALETTE_COLOR } from 'config/CONSTANTS';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';
import { EmployeeDetails } from './EmployeeDetails';
import ic_note_enable from '@assets/icons/ic_note_enable.svg';
import Avatar from '@assets/icons/ic_no_avatar.svg';
import { setReloadTimeSheetAction } from 'redux/actions/appActions';
import { AppModalStatus } from '@components/AppModalStatus';
import i18next from 'i18n';
import { useTranslation } from 'react-i18next';
import { saveEntityData } from '@services';
import { CustomizedSnackbar } from 'components';

const Edit = styled.img`
  width: 12px;
  height: 16px;
`;

const CloseShift = styled.img`
  width: 12px;
  height: 16px;
`;

const UserWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 300;
  font-size: 14px;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TimeSheetColLabel = styled.div`
  font-weight: 300;
  font-size: 14px;
`;

const ModalNotes = styled.div`
  .line {
    margin: 0 24px;
    border-bottom: 1px solid ${PALETTE_COLOR.MAIN_GRAY};
  }
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 60px;
`;

const calcDuration = (minutes) => {
  if (minutes && minutes != null) {
    return `${('' + parseInt(minutes / 60)).padStart(2, '0')}:${('' + (minutes % 60)).padStart(
      2,
      '0',
    )}`;
  } else {
    return '--';
  }
};

const Note = (props) => {
  const { note } = props;
  if (note)
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <img
          src={ic_note_enable}
          style={{ cursor: 'pointer' }}
          width={20}
          height={21}
          alt={`ic_note_enable`}
        />
        <TimeSheetColLabel>{``}</TimeSheetColLabel>
      </div>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
      <img src={ic_note_enable} width={20} height={21} alt={`ic_note_enable`} />
      <TimeSheetColLabel>{``}</TimeSheetColLabel>
    </div>
  );
};

const Status = (props) => {
  const { t } = useTranslation();
  const { userShift, withEndDate } = props;

  if (withEndDate) {
    if (userShift.signer) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <img src={ic_status_signed} alt={`ic_status_signed`} />
          <TimeSheetColLabel>{t('form_labels.signed')}</TimeSheetColLabel>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <img src={ic_status_unsigned} alt={`ic_status_unsigned`} />
          <TimeSheetColLabel>{`${t('form_labels.unsigned').substring(0, 7)}...`}</TimeSheetColLabel>
        </div>
      );
    }
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <img src={ic_status_no_time} alt={`ic_status_no_time`} />
        <TimeSheetColLabel>{t('no_time')}</TimeSheetColLabel>
      </div>
    );
  }
};

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

export const DayTimeSheet = () => {
  const { t } = useTranslation();
  const { jobSchedulerGroup, valuesDefinitions, reloadTimeSheet, timeSheetSelectedDates } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  //
  const jobs_types = useMemo(
    () =>
      valuesDefinitions &&
      valuesDefinitions.filter((v) => v.definitionType.definition_type === 'JOBS_TYPES'),
    [valuesDefinitions],
  );
  // Get clock-out event
  const clockOutEvent =
    valuesDefinitions &&
    valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'USERS_SHIFTS_EVENTS_TYPE' &&
        v.value_definition === '020',
    )[0];
  const [sitesList, setSitesList] = useState([]);
  const [dateFilter, setDateFilter] = React.useState(
    moment(timeSheetSelectedDates.startDate).startOf('day').format(),
  );
  // Check if clocking out is in progress
  const [isLoading, setIsLoading] = useState(false);
  // Modal fie success or error
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  /***
   * Load sites for filtering
   */
  const reloadTimeSheets = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadTimeSheetAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Get sites filter
   */
  useEffect(() => {
    if (jobSchedulerGroup && jobSchedulerGroup.id) {
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userSites/${jobSchedulerGroup.id}`, qb2.query())
        .then((res) => {
          setSitesList(res);
          reloadTimeSheets(false);
        })
        .catch((err) => {
          reloadTimeSheets(false);
          console.log('axios err=', err);
        });
    }
  }, [jobSchedulerGroup, reloadTimeSheet, reloadTimeSheets]);

  /***
   * Call API to set the clock out for shift
   */
  const setClockOutEvent = () => {
    //Call api
    setIsLoading(true);
    //Build obj to be sent
    const req = {
      event_time: selectedTimeSheet?.userShift?.shift?.end_time,
      employee_cognito_id: selectedTimeSheet?.userShift?.user?.sub_cognito_id,
      type: clockOutEvent,
    };
    saveEntityData(
      `${USER_SHIFTS_API}userShift/event/clock-out/${selectedTimeSheet?.userShift?.shift?.id}`,
      req,
    )
      .then((res) => {
        // Show message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.shift_edited')}`,
          message: '',
        });
        // Reload timesheet
        reloadTimeSheets(true);
      })
      .catch((err) => {
        // Show message
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_shifts_clock_out')}`,
          message: '',
        });
      })
      .finally(() => {
        // Close modal
        setOpenCloseShiftModal(false);
        // Stop modal button loading animation
        setIsLoading(false);
        // Close message
        setTimeout(() => {
          setShowSucessModal({
            show: false,
            type: '',
            title: ``,
            message: '',
          });
        }, 3000);
      });
  };

  //Table filters
  const filters = useMemo(() => {
    return [
      {
        field: 'innerGroup.id',
        operator: CondOperator.EQUALS,
        value: jobSchedulerGroup.id,
      },
      {
        field: 'shiftDate',
        operator: CondOperator.BETWEEN,
        value: [
          `${moment(dateFilter).format('YYYY-MM-DD')} 00:00:00`,
          `${moment(dateFilter).endOf('day').format('YYYY-MM-DD')} 23:59:59`,
        ],
      },
    ];
  }, [dateFilter, jobSchedulerGroup.id]);

  //Table joins
  const dailyJoins = React.useMemo(() => {
    return [
      { field: 'userShift' },
      { field: 'site' },
      { field: 'group' },
      { field: 'job_title' },
      { field: 'userShift.shift' },
      { field: 'userShift.user' },
      { field: 'userShift.signer' },
      { field: 'userShift.status' },
      { field: 'site.organization_id' },
      { field: 'groupByEmployee' }, //USED AS A FLAG TO BACKEND GROUPING THE DATA
      // { field: 'filterByUserSites' }, //USED AS A FLAG TO BACKEND FILER TIMESHEET BY USER SITES
    ];
  }, []);
  const [openForm, setOpenForm] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [notes, setNotes] = React.useState([{}]);
  const [indexNotes, setIndexNotes] = React.useState('');
  const [openCalendarFilter, setOpenCalendarFilter] = React.useState(false);
  const [selectedTimeSheet, setSelectedTimeSheet] = React.useState(null);
  const [employeeSelected, setEmployeeSelected] = React.useState({});
  const [openEmployeeDetails, setOpenEmployeeDetails] = React.useState(false);
  const [openCloseShiftModal, setOpenCloseShiftModal] = React.useState(false);

  const handleEmployeeDetails = useCallback(() => {
    setOpenEmployeeDetails(!openEmployeeDetails);
  }, [openEmployeeDetails, setOpenEmployeeDetails]);

  const setNote = (data, index) => {
    setOpenNotes(true);
    if (data && data.timeSheets && data.timeSheets) {
      setIndexNotes(index);
      setNotes(data.timeSheets.map((ts) => ts.userShift?.shift));
    }
  };

  /**
   *
   */
  const getGroupedTs = React.useCallback((timeSheets) => {
    let result = [];
    for (let i = 0; i < timeSheets.length; i++) {
      let timeSheet = timeSheets[i];
      //Get consecutive ts: Those which have same site consecutively, if are not consecutively array size will be 1
      const consecutiveTS = getConsecutiveTimeShifts(timeSheets, timeSheet, i);
      result.push(consecutiveTS);
      //Add i, to avoid evaluate same ts
      if (consecutiveTS.length > 1) {
        i = i + (consecutiveTS.length - 1);
      }
    }
    return result;
  }, []);

  /***
   *
   */
  const getConsecutiveTimeShifts = (originalTSArray, tsToCompare, index) => {
    let tsConsecutiveFound = false;
    let tsArrayToReturn = [];
    tsArrayToReturn.push(tsToCompare);
    while (index < originalTSArray.length && !tsConsecutiveFound) {
      //Still elements
      if (originalTSArray.length > index + 1) {
        //Same site
        if (tsToCompare.site.id === originalTSArray[index + 1].site.id) {
          tsArrayToReturn.push(originalTSArray[index + 1]);
        } else {
          tsConsecutiveFound = true;
        }
      } else {
        //No more elements
        tsConsecutiveFound = true;
      }
      index++;
    }
    return tsArrayToReturn;
  };

  /***
   *
   */
  const getValidValue = (tsArray, type) => {
    let valueToReturn = null;
    for (let index = 0; index < tsArray.length; index++) {
      const element = tsArray[index];
      if (type === 1) {
        if (element.totalOnSiteDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.onSiteDuration;
          }, 0);
          break;
        }
      } else if (type === 2) {
        if (element.totalBreakDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.breakDuration;
          }, 0);
          break;
        }
      } else if (type === 3) {
        if (element.totalPaidDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.workedDuration;
          }, 0);
          break;
        }
      } else if (type === 4) {
        if (element.totalRegularDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.regularDuration;
          }, 0);
          break;
        }
      } else if (type === 5) {
        if (element.totalOvertimeDuration) {
          valueToReturn = tsArray.reduce((accumulator, object) => {
            return accumulator + object.overtimeDuration;
          }, 0);
          break;
        }
      }
    }
    return valueToReturn;
  };

  const getTimeSheetColumns = useMemo(() => {
    return [
      {
        dataField: 'userShift.user.name',
        text: `${t('table_columns.employee')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '200px' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          const avatarConfig = row.user.avatar_config ? JSON.parse(row.user.avatar_config) : null;
          return (
            <UserWrapper>
              {avatarConfig ? (
                <AppAvatar
                  avatarId={avatarConfig.avatarId}
                  bgColor={avatarConfig.bgColor}
                  gender={avatarConfig.gender}
                  skinColor={avatarConfig.skinColor}
                  width={40}
                  height={40}
                />
              ) : row?.user?.photo_url ? (
                <Icon src={row.user.photo_url} alt="photo" />
              ) : (
                <Icon src={Avatar} alt="avatar" />
              )}
              <div>{row.user.name}</div>
            </UserWrapper>
          );
        },
      },
      {
        dataField: 'innerSite.name',
        text: `${t('table_columns.site')}`,
        isDummyField: true,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '200px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <StyledNestedCell
                style={{
                  backgroundColor: empSiteTs.site.organization_id.color,
                  display: 'block',
                  paddingTop: '13px',
                }}
              >
                {empSiteTs.site.name}
              </StyledNestedCell>
            </div>
          ));
        },
        advanceFilter: sitesList && [...sitesList.map((s) => ({ label: s.name, value: s.name }))],
      },
      {
        dataField: 'job_title.description',
        text: `${t('table_columns.job_title')}`,
        isDummyField: true,
        sort: false,
        search: true,
        style: { height: '54px', padding: '14px 23px 14px 23px' },
        headerStyle: { width: '100px' },
        formatter: (cellContent, row) => {
          const jobTitles = row.timeSheets.map((ts) => ts.job_title);
          const uniqueJobTitles = [
            ...new Map(jobTitles.map((item) => [item['value_definition_id'], item])).values(),
          ];
          return uniqueJobTitles.map((jobTitle, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <TimeSheetColLabel>{jobTitle.description}</TimeSheetColLabel>
            </div>
          ));
        },
        advanceFilter: jobs_types && [
          ...jobs_types.map((s) => ({
            label: s.description,
            value: s.description,
          })),
        ],
      },
      {
        dataField: 'user.employee_id',
        text: `${t('table_columns.employee_id')}`,
        sort: false,
        search: true,
        headerStyle: { width: '100px' },
        style: { height: '54px', padding: '14px 23px 14px 23px' },
        formatter: (cellContent, row) => {
          return <TimeSheetColLabel> {row.user.employee_id}</TimeSheetColLabel>;
        },
      },
      {
        dataField: 'user.avatar_config',
        isDummyField: true,
        text: `${t('table_columns.start_date')}`,
        sort: false,
        search: true,
        style: { height: '54px', padding: 0 },
        headerStyle: { width: '145px' },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment.utc(empSiteTs.shiftDate).format('DD/MM/YYYY')}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.name',
        isDummyField: true,
        text: `${t('table_columns.clock_in')}`,
        sort: false,
        search: true,
        headerStyle: { width: '115px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment.utc(empSiteTs.clockInDate).format('hh:mm A')}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.date_registered',
        isDummyField: true,
        text: `${t('table_columns.end_date')}`,
        sort: false,
        search: true,
        headerStyle: { width: '145px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment(empSiteTs.endDate).isValid()
                  ? moment.utc(empSiteTs.endDate).format('DD/MM/YYYY')
                  : '--'}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.email',
        isDummyField: true,
        text: `${t('table_columns.clock_out')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '115px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>
                {moment(empSiteTs.endDate).isValid()
                  ? moment.utc(empSiteTs.clockOutDate).format('hh:mm A')
                  : '--'}
              </TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.home_address',
        isDummyField: true,
        text: `${t('table_columns.notes')}`,
        sort: false,
        search: false,
        headerStyle: { width: '80px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row, index) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
              id={`note${index}-${i}`}
              aria-label="close"
              onClick={() => {
                setNote({ timeSheets: [empSiteTs] }, `${index}-${i}`);
              }}
            >
              <Note note={null} />
            </div>
          ));
        },
      },
      {
        dataField: 'user.hoursWorkedCurrentWeek',
        isDummyField: true,
        text: `${t('table_columns.on_site_h')}`,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.onSiteDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.id',
        isDummyField: true,
        text: `${t('table_columns.break_h')}`,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.breakDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.last_login',
        isDummyField: true,
        text: `${t('table_columns.paid_h')}`,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <TimeSheetColLabel>{calcDuration(empSiteTs.workedDuration)}</TimeSheetColLabel>
            </div>
          ));
        },
      },
      {
        dataField: 'user.date_invited',
        text: `${t('table_columns.total_on_site_h')}`,
        isDummyField: true,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.on_site_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          /*console.log('🦖 ROW: ', row);
          let result = [];
          for (let i = 0; i < row.timeSheets.length; i++) {
            let timeSheet = row.timeSheets[i];
            console.log('🦖 timeSheet to test: ', timeSheet);
            //Get consecutive ts: Those which have same site consecutively, if are not consecutively array size will be 1
            const consecutiveTS = getConsecutiveTimeShifts(row.timeSheets, timeSheet, i);
            console.log('🦖 consecutiveTS: ', consecutiveTS);
            result.push(consecutiveTS);
            //Add i, to avoid evaluate same ts
            if (consecutiveTS.length > 1) {
              i = i + (consecutiveTS.length - 1);
            }
          }
          console.log('🦖 result: ', result);*/
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                //console.log('🦖 to return tsGrouped > 1 : ', tsGrouped[0].totalOnSiteDuration);
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 1))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalOnSiteDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'user.pay_rate',
        text: `${t('table_columns.total_break_h')}`,
        isDummyField: true,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.break_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 2))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalBreakDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'user.phone',
        text: `${t('table_columns.total_paid_h')}`,
        isDummyField: true,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.paid_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 3))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>{calcDuration(empSiteTs.totalPaidDuration)}</TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'user.photo_url',
        text: `${t('table_columns.total_regular_h')}`,
        isDummyField: true,
        sort: false,
        search: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.regular_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 4))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalRegularDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'user.pin',
        text: `${t('table_columns.total_overtime_h')}`,
        isDummyField: true,
        sort: false,
        search: true,
        headerStyle: { width: '130px' },
        style: { height: '54px', padding: 0 },
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <TimeSheetColLabel>{t('form_labels.total')}</TimeSheetColLabel>
              <TimeSheetColLabel>{t('form_labels.overtime_h')}</TimeSheetColLabel>
            </div>
          );
        },
        formatter: (cellContent, row) => {
          return getGroupedTs(row.timeSheets).map((tsGrouped, i) => {
            let render = false;
            if (tsGrouped.length > 1) {
              return tsGrouped.map((ts, j) => {
                if (!render) {
                  render = true;
                  return (
                    <div
                      style={{
                        borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                        padding: '16px 23px 14px 23px',
                      }}
                    >
                      <TimeSheetColLabel>
                        {calcDuration(getValidValue(tsGrouped, 5))}
                      </TimeSheetColLabel>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        borderTop: 'unset',
                        padding: '38px 23px 14px 23px',
                      }}
                    ></div>
                  );
                }
              });
            } else {
              return tsGrouped.map((empSiteTs, j) => (
                <div
                  style={{
                    borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                    padding: '16px 23px 14px 23px',
                  }}
                >
                  <TimeSheetColLabel>
                    {calcDuration(empSiteTs.totalOvertimeDuration)}
                  </TimeSheetColLabel>
                </div>
              ));
            }
          });
        },
      },
      {
        dataField: 'user.sub_cognito_id',
        text: `${t('table_columns.status')}`,
        isDummyField: true,
        sort: false,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '150px' },
        customFilter: (selected) => {
          const filters = selected.map((f) => f.value);
          if (filters.includes('Signed')) {
            return {
              [CondOperator.NOT_NULL]: true,
            };
          }
          if (filters.includes('Unsigned')) {
            return {
              [CondOperator.IS_NULL]: true,
            };
          }
        },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <Status
                userShift={empSiteTs.userShift}
                withEndDate={moment(empSiteTs.endDate).isValid()}
              />
            </div>
          ));
        },
        advanceFilter: [
          {
            label: `${t('form_labels.signed')}`,
            value: 'Signed',
          },
          {
            label: `${t('form_labels.unsigned')}`,
            value: 'Unsigned',
          },
        ],
      },
      {
        dataField: '',
        text: `${t('table_columns.details')}`,
        sort: false,
        isDummyField: true,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <Tooltip title={t('buttons.titles.edit')}>
                <Edit
                  style={{ cursor: 'pointer', width: 20, height: 18 }}
                  src={logo_edit}
                  alt="logo"
                  aria-label="close"
                  onClick={() => {
                    setOpenForm(true);
                    setSelectedTimeSheet(empSiteTs);
                  }}
                ></Edit>
              </Tooltip>

              <Tooltip title={t('buttons.titles.details')}>
                <Edit
                  style={{ cursor: 'pointer', width: 20, height: 18, marginLeft: 10 }}
                  src={ic_document}
                  alt="logo"
                  onClick={() => {
                    handleEmployeeDetails();
                    setEmployeeSelected(row);
                    setSelectedTimeSheet(empSiteTs);
                  }}
                ></Edit>
              </Tooltip>
            </div>
          ));
        },
      },
      {
        dataField: '',
        text: `${t('table_columns.close_shift')}`,
        sort: false,
        isDummyField: true,
        search: true,
        disableColumnToggle: true,
        headerStyle: { width: '120px' },
        style: { height: '54px', padding: 0 },
        formatter: (cellContent, row) => {
          return row.timeSheets.map((empSiteTs, i) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                padding: '16px 23px 14px 23px',
              }}
            >
              <Tooltip title={t('buttons.titles.close_shift')}>
                <CloseShift
                  style={{ cursor: 'pointer', width: 22, height: 22 }}
                  src={ic_close_shift}
                  alt="logo"
                  aria-label="close"
                  onClick={() => {
                    // Only if shift is accepted and inProgress
                    if (
                      // 2024-05-29T15:20:00.000Z
                      // 2024-05-29T15:20:00.000Z
                      // true ||
                      empSiteTs?.userShift?.status?.value_definition === '040' &&
                      empSiteTs?.userShift?.inProgress
                    ) {
                      setOpenCloseShiftModal(true);
                      setSelectedTimeSheet(empSiteTs);
                    }
                  }}
                ></CloseShift>
              </Tooltip>
            </div>
          ));
        },
      },
    ];
  }, [
    jobs_types,
    setSelectedTimeSheet,
    setOpenForm,
    setOpenCloseShiftModal,
    sitesList,
    handleEmployeeDetails,
    getGroupedTs,
    t,
  ]);

  return (
    <>
      <AppTable
        endpointPath={TIME_SHEET_API}
        joins={dailyJoins}
        filters={filters}
        columns={getTimeSheetColumns}
        withAdvanceFilter={true}
        withColumnToggler={true}
        allowOverFlowX={true}
        reload={!reloadTimeSheet}
        disableTopPagination={true}
        filtersAdditional={[
          {
            filter: (
              <>
                <LocalizationProvider
                  dateAdapter={DateAdapter}
                  locale={i18next.resolvedLanguage}
                  style={{ fontSize: '14px' }}
                >
                  <DatePicker
                    style={{ fontSize: '14px', display: 'none' }}
                    label
                    value={moment(dateFilter)}
                    inputFormat="LL"
                    open={openCalendarFilter}
                    onChange={(newValue) => {
                      setDateFilter(newValue.startOf('day').format());
                      setOpenCalendarFilter(!openCalendarFilter);
                    }}
                    components={{
                      OpenPickerIcon: () => (
                        <img style={{ width: 18, height: 20 }} src={DPICon} alt="calendar" />
                      ),
                    }}
                    renderInput={(params) => {
                      return (
                        <div
                          style={{
                            border: '1px solid #E7ECF4',
                            borderRadius: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 5,
                            padding: '9px, 12px, 7px, 12px',
                            marginLeft: '8px',
                          }}
                        >
                          <IconButton
                            aria-label={t('form_labels.job_scheduler')}
                            style={{ width: 24, height: 24, marginTop: 8, marginLeft: 8 }}
                            onClick={(e) => {
                              setDateFilter(moment(dateFilter).subtract(1, 'day').format());
                            }}
                          >
                            <ArrowWrapper
                              style={{ width: 5.5, height: 11.5 }}
                              src={ic_arrow_left_enabled}
                              alt="IcArrowLeft"
                            />
                          </IconButton>
                          <IconButton
                            aria-label={t('form_labels.job_scheduler')}
                            style={{ width: 24, height: 24, marginTop: 8 }}
                            onClick={(e) => {
                              const newDate = moment(dateFilter).add(1, 'day');
                              setDateFilter(newDate.format());
                            }}
                          >
                            <ArrowWrapper
                              style={{ width: 5.5, height: 11.5 }}
                              src={ic_arrow_right_enabled}
                              alt="IcArrowLeft"
                            />
                          </IconButton>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 5,
                              marginTop: 10,
                              fontWeight: 400,
                              fontSize: 14,
                              color: PALETTE_COLOR.MAIN_BLUE,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setOpenCalendarFilter(!openCalendarFilter);
                            }}
                          >
                            <div ref={params.inputRef}>{params.inputProps.value}</div>
                            <img style={{ width: 18, height: 20 }} src={DPICon} alt="calendar" />
                            <input style={{ width: 0, height: 0, zIndex: -1 }} {...params} />
                          </div>
                        </div>
                      );
                    }}
                  />
                </LocalizationProvider>
                <AppButton
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  style={{ marginLeft: 6, marginRight: 4 }}
                  onClick={(e) => {
                    setDateFilter(moment().startOf('day'));
                  }}
                >
                  {t('buttons.btn_today')}
                </AppButton>
              </>
            ),
          },
        ]}
      />

      {/* TimeSheet form modal */}
      <AppModal
        open={openForm}
        handleclose={() => {
          setOpenForm(false);
        }}
        title={t('Timesheet.modals.edit_shift_title')}
        inside={1}
      >
        <TimeSheetForm
          handleclose={() => {
            setOpenForm(false);
          }}
          data={selectedTimeSheet}
        />
      </AppModal>

      {/* Employee details modal */}
      <AppModal
        open={openEmployeeDetails}
        handleclose={handleEmployeeDetails}
        isStacked={true}
        inside={0}
        boxshadow={'none'}
        styleModal={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          width: '100%',
          height: '95%',
        }}
      >
        <EmployeeDetails
          employee={employeeSelected}
          timesheet={setSelectedTimeSheet}
          handleClose={handleEmployeeDetails}
          day={dateFilter}
        />
      </AppModal>

      <AppModalNotes
        onClose={() => {
          setOpenNotes(false);
        }}
        open={openNotes}
        position={document.getElementById(`note${indexNotes}`)}
      >
        <ModalNotes style={{ width: 448 }}>
          {notes && notes.length > 0
            ? notes.map((n, i) => (
                <>
                  {i > 0 ? <div className="line"></div> : ''}
                  <div style={{ padding: 24 }}>
                    <h2 style={{ fontSize: 16, fontWeight: 500 }}>
                      {t('form_labels.shift_notes')}
                    </h2>
                    <p style={{ fontSize: 14, marginBottom: 0 }}>{n.note}</p>
                  </div>
                </>
              ))
            : ''}
        </ModalNotes>
      </AppModalNotes>

      {/* Close shift modal modal */}
      <AppModal
        width={500}
        styleModal={{ padding: '30px 40px 60px 40px' }}
        open={openCloseShiftModal}
        handleclose={() => {
          setOpenCloseShiftModal(false);
        }}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          width={'500px'}
          mainPadding={'0px 0px 0px 0px'}
          actionIcon={ic_close_shift_modal}
          title={`${t('Timesheet.modals.close_shift_title')}`}
          subtitle={`${t('Timesheet.modals.close_shift_subtitle')}`}
          subject={`${selectedTimeSheet?.userShift?.user?.name} (${selectedTimeSheet?.site?.name})`}
          bgcolor={PALETTE_COLOR.ACCENT_ORANGE}
          bordercolor={PALETTE_COLOR.ACCENT_ORANGE}
          borderhovercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
          bghovercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
          boxShadow={'0px 4px 10px rgba(255, 124, 50, 0.3)'}
          buttonText={`${t('Timesheet.buttons.close_shift')}`}
          buttonWidth={132}
          buttonAction={setClockOutEvent}
          isLoading={isLoading}
        />
      </AppModal>
      {showSucessModal.show && showSucessModal.title !== '' && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </>
  );
};
