import React from 'react';
import styled from 'styled-components';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import { AppCheckBox, AppOrgIcon } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import ic_search from '@assets/icons/ic_search.svg';
import { IconButton } from '@mui/material';
import ic_remove from '@assets/icons/ic_remove.svg';

import i18next from 'i18n'
i18next.changeLanguage(i18next.resolvedLanguage);

const FIELD_BORDER_COLOR = PALETTE_COLOR.FIELD_BORDER_COLOR;
const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
const DEFAULT_ALL_CONTROL_OPTION = { label: 'select all', value: '*' };

const OptionLabelWrapper = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const FilterWrapper = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  background: #eff4fb;
  border-radius: 20px;
  color: ${MAIN_BLUE};
  height: 40px;
  padding: 10px 10px 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
`;

const IconWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const buildStyles = (width, height) => {
  return {
    container: (provided, state) => ({
      ...provided,
      width,
    }),
    control: (styles, { selectProps }) => {
      return !selectProps.isSearchable
        ? { display: 'none' }
        : { borderBottom: 'solid 1px #EEEEEE' };
    },
    dropdownIndicator: (provided, state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      display: 'none',
    }),
    option: (styles, { isDisabled, value, data }) => {
      return {
        ...styles,
        backgroundColor: undefined,
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: undefined,
        },
        borderBottom: value === DEFAULT_ALL_CONTROL_OPTION.value ? 'solid 1px #EEEEEE' : 'none',
        borderTop: data.isFooter ? 'solid 1px #EEEEEE' : 'none',
      };
    },
    menu: () => ({
      padding: 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: height,
      padding: '0px',
      borderRadius: 16,
      /* Styles for built-in scrollbar for webkit browsers */

      '::-webkit-scrollbar': {
        width: '0.4rem',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0)', // transparent
        borderRadius: '1rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: FIELD_BORDER_COLOR,
        borderRadius: '1rem',
        boxShadow: 'inset 0 0 0.6rem rgba(0, 0, 0, 0.2)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: FIELD_BORDER_COLOR,
      },
    }),
  };
};

const Option = (props) => {
  return (
    <>
      {props.value === DEFAULT_ALL_CONTROL_OPTION.value ? (
        props.options.filter((o) => !o.value.includes('*')).length > 0 &&
        props.data?.typeSelectAll === 'allEmpy' ? (
          <components.Option {...props}>
            <AppCheckBox
              checked={
                !(
                  props.selectProps.value.filter((o) => !o.value.includes('*')).length > 0 &&
                  props.selectProps.value.filter((o) => !o.value.includes('*')).length !==
                    props.options.filter((o) => !o.value.includes('*')).length + 1
                )
              }
              onChange={() => null}
            />
            <OptionLabelWrapper style={{ fontWeight: 600 }}>{props.label}</OptionLabelWrapper>
          </components.Option>
        ) : (
          <components.Option {...props} width='100%'>
            <AppCheckBox
              indeterminated={
                props.selectProps.value.filter((o) => !o.value.includes('*')).length > 0 &&
                props.selectProps.value.filter((o) => !o.value.includes('*')).length !==
                  props.options.filter((o) => !o.value.includes('*')).length
              }
              checked={
                props.selectProps.value.length > 0 &&
                props.selectProps.value.filter((o) => !o.value.includes('*')).length ===
                  props.options.filter((o) => !o.value.includes('*')).length
              }
              onChange={() => null}
            />
            <OptionLabelWrapper style={{ fontWeight: 600 }}>{props.label}</OptionLabelWrapper>
          </components.Option>
        )
      ) : (
        <components.Option {...props}>
          <AppCheckBox
            checked={props.isSelected}
            onChange={() => null}
            disabled={props.isDisabled}
            style={
              props.options.filter((o) => o.coloredCircles).length > 0 ? { float: 'left' } : {}
            }
          />
          {props.options.filter((o) => o.coloredCircles).length > 0 ? (
            <AppOrgIcon
              color={props.data.valueObj?.color}
              style={{
                float: 'left',
                marginTop: 5,
                marginRight: 10,
                width: 30,
                height: 30,
                fontSize: 12,
                padding: 5,
              }}
            ></AppOrgIcon>
          ) : (
            ''
          )}
          <OptionLabelWrapper style={{ fontWeight: 400 }}>{props.label}</OptionLabelWrapper>
        </components.Option>
      )}
    </>
  );
};

// Good: Custom component declared outside of the Select scope
const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <div style={{ display: 'flex' }}>
      <span
        style={{
          backgroundImage: `url(${ic_search})`,
          width: 16,
          height: 16,
          marginTop: 18,
          marginLeft: 24,
          marginBottom: 18,
        }}
      ></span>
      {children}
    </div>
  </components.Control>
);

export const AppDropDownCheckBoxSelect = ({
  options,
  defaultValue = [],
  onChange,
  selectAllControlOption,
  targetComponent,
  allowSelectAll = false,
  showSelection = true,
  height = '30rem',
  width = 250,
  typeSelectAll = '',
  placeholder = `${i18next.t('placeholder_search')}`,
  ...other
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const selectAllOption = selectAllControlOption
    ? selectAllControlOption
    : DEFAULT_ALL_CONTROL_OPTION;
  const initialVisibleOptions = allowSelectAll ? [selectAllOption, ...options] : options;
  const calcDefaultValues = () => {
    return defaultValue.filter((defaul) => defaul.value !== DEFAULT_ALL_CONTROL_OPTION.value);
  };
  const [selectedValues, setSelectedValues] = React.useState(
    options.length === calcDefaultValues()?.length ? options : calcDefaultValues(),
  );
  const relayOnChange = (newSelectedOptions, event, props) => {
    const newComponentState =
      event.option.value === selectAllOption.value
        ? {
            selectedValues:
              event.action === 'select-option'
                ? typeSelectAll === 'allEmpy'
                  ? []
                  : initialVisibleOptions.filter((o) => !o.isFooter)
                : [],
          }
        : {
            selectedValues: newSelectedOptions,
          };
    setSelectedValues(newComponentState.selectedValues);
    //setAvailableOptions(newComponentState.availableOptions);
    onChange(newSelectedOptions, event, newComponentState.selectedValues);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    setSelectedValues(
      options.length === calcDefaultValues()?.length ? [...options] : calcDefaultValues(),
    );
  }, [defaultValue, options, selectAllOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {showSelection &&
          selectedValues.map((filter, i) => (
            <FilterWrapper key={i}>
              {filter.label}
              <IconButton
                aria-label={i18next.t('remove_filter')}
                onClick={() => {
                  setSelectedValues(selectedValues.filter((f) => f.value !== filter.value));
                  onChange(
                    null,
                    null,
                    selectedValues.filter((f) => f.value !== filter.value),
                  );
                }}
              >
                <IconWrapper style={{ width: 8, height: 8 }} src={ic_remove} alt="remove" />
              </IconButton>
            </FilterWrapper>
          ))}
      </div>
      <div>
        <Dropdown
          isOpen={isOpen}
          style={{ borderRadius: 16 }}
          onClose={toggleOpen}
          target={
            <div onClick={toggleOpen} style={{ marginTop: showSelection ? 5 : 0 }}>
              {targetComponent}
            </div>
          }
        >
          <ReactSelect
            {...other}
            className="ianMoone"
            autoFocus
            backspaceRemovesValue={false}
            components={{
              Option,
              Control,
              IndicatorsSeparator: null,
            }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            isMulti
            closeMenuOnSelect={false}
            options={initialVisibleOptions}
            value={selectedValues}
            defaultValue={selectedValues}
            onChange={relayOnChange}
            placeholder={placeholder}
            styles={buildStyles(width, height)}
            tabSelectsValue={false}
          />
        </Dropdown>
      </div>
    </>
  );
};

// styled components

const Menu = (props) => {
  const shadow = 'rgba(0, 0, 0, 0.15)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 16,
        boxShadow: `0px 4px 16px ${shadow}`,
        marginTop: 0,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
