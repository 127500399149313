import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.div`
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
`;

export const LabelForm = (props) => {
  const { tittle } = props;
  return (
    <>
      <StyledLabel>{tittle}</StyledLabel>
    </>
  );
};
export default LabelForm;
