import React from 'react';
import EncList from '@components/EncList';
import ic_settings_white from '@assets/icons/ic_settings_white.svg';
import { Settings } from '@pages/SettingsCenter/Settings/Settings';

import { useTranslation } from 'react-i18next';

export const SettingsCenter = () => {
  const { t } = useTranslation();
  return (
    <div>
      <EncList title={t('SettingsCenter.title')} icon={ic_settings_white} indexActive={null}></EncList>
      <Settings />
    </div>
  );
};
