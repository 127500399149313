import ic_eye from '@assets/icons/ic_eye.svg';
import ic_remove from '@assets/icons/ic_remove.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import 'react-phone-input-2/lib/style.css';

const StyledInputIcon = styled(InputGroup.Text)`
  background: transparent;
  border-radius: 16px;
  border: none;
  padding: 0.375rem 0.5rem;
  padding-right: ${(props) => (props.iconpaddingright ? props.iconpaddingright : '0.5rem')};
`;

const StyledInput = styled(InputGroup.Text)`
  background: ${(props) =>
    props.background
      ? props.background
      : props.disabled
      ? '#e9ecef'
      : props.focused || props.hastext
      ? '#FFFFFF'
      : '#EFF4FB'};
  border-radius: ${(props) => (props.borderRadius ? props.heigborderRadiusht : '16px')};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'inherit')};
  border: ${(props) =>
    props.focused && props.invalid === undefined
      ? `1px solid ${PALETTE_COLOR.MAIN_BLUE}`
      : props.hastext && props.invalid === undefined
      ? '1px solid #E7ECF4'
      : props.invalid
      ? '1px solid #FFACAC'
      : '0px solid transparent'};
  height: ${(props) => (props.height ? props.height : '45px')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1rem')};
  width: ${(props) => (props.width ? props.width : props.widthspan ? props.widthspan : 'auto')};
`;

const StyledInputTA = styled(InputGroup.Text)`
  background: ${(props) => (props.focused || props.hastext ? '#FFFFFF' : '#EFF4FB')};
  border-radius: 16px;
  border: ${(props) =>
    props.focused && props.invalid === undefined
      ? `1px solid ${PALETTE_COLOR.MAIN_BLUE}`
      : props.hastext && props.invalid === undefined
      ? '1px solid #E7ECF4'
      : props.invalid
      ? '1px solid #FFACAC'
      : '0px solid transparent'};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1rem')};
`;

const StyledFormControl = styled(FormControl)`
  font-style: normal;
  font-weight: 300;
  width: ${(props) => (props.widthinput ? props.widthinput : '100%')};
  font-size: ${(props) => (props.inpfontsize ? props.inpfontsize : '16px')};
  line-height: 24px;
  background: transparent;
  border-radius: 16px;
  border-color: transparent;
  color: #000000;
  padding: 0.375rem 0.75rem 0.375rem 0px;
  &:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: unset;
  }
  &:focus-visible {
    background: transparent;
    border-color: transparent;
    box-shadow: unset;
  }
  ::placeholder {
    color: #b4c0cf;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: #000000;
    -webkit-background-color: #000000;
    background-color: #000000 !important;
  }
  :-webkit-autofill:focus {
    -webkit-text-fill-color: #000000;
    -webkit-background-color: #000000 !important;
  }
  :-webkit-appearance: none;
  :-internal-autofill-selected {
    background-color: rgb(232, 240, 254) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
  }
  :valid {
    border-color: transparent;
  }
  :invalid &:focus {
    border-color: transparent;
  }
`;

const StyledFormControlTA = styled(FormControl)`
  font-style: normal;
  font-weight: 300;
  font-size: ${(props) => (props.inpfontsize ? props.inpfontsize : '16px')};
  line-height: 24px;
  background: transparent;
  border-color: transparent;
  color: #000000;
  ::placeholder {
    color: #B4C0CF;
  }
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  margin-left:10px;
  height:80px;
}
`;

const InputIcon = styled.img``;

const ViewAction = styled(Button)`
  font-size: 13px;
  text-decoration: none;
  text-align: left;
  padding: unset;
  :focus {
    box-shadow: unset;
  }
`;

export const InputForm = (props) => {
  const { t } = useTranslation();
  const { icon, ctype = 'text', value, inpfontsize, setSearchTerm, testId } = props;
  const [inputType, setInputType] = useState(ctype);
  const [, setHideText] = useState(t('show'));
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const phoneStyles = {
    borderRadius: 16,
    width: '100%',
    background: focused || (value && value.length > 0) ? '#FFFFFF' : '#EFF4FB',
    border:
      focused && props.invalid === undefined
        ? `1px solid ${PALETTE_COLOR.MAIN_BLUE}`
        : value && value.length > 0 && props.invalid === undefined
        ? '1px solid #E7ECF4'
        : props.invalid
        ? '1px solid #FFACAC'
        : '0px solid transparent',
    height: props.height ? props.height : '45px',
  };

  const phoneButtonStyles = {
    borderBottomLeftRadius: 16,
    borderTopLeftRadius: 16,
    border: 'none',
    '&:hover': {
      borderBottomLeftRadius: 16,
      borderTopLeftRadius: 16,
    },
  };

  const phoneDropDownStyles = {
    borderRadius: 16,
    border: 'none',
    background: focused || (value && value.length > 0) ? '#FFFFFF' : '#EFF4FB',
  };

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInputType((prev) => {
      return prev === 'text' ? 'password' : 'text';
    });
    setHideText((prev) => {
      return prev === t('show') ? t('hide') : t('show');
    });
    //this.input.focus();
  };

  const removeText = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchTerm('');
  };

  return (
    <>
      {ctype && ctype === 'phone' ? (
        <PhoneInput
          onFocus={onFocus}
          dropdownStyle={phoneDropDownStyles}
          buttonStyle={phoneButtonStyles}
          onBlur={onBlur}
          inputStyle={phoneStyles}
          {...props}
        />
      ) : ctype && ctype === 'textarea' ? (
        //TextArea
        <StyledInputTA
          focused={focused ? 1 : undefined}
          hastext={value && value.length > 0 ? 1 : undefined}
          {...props}
        >
          <StyledFormControlTA
            as="textarea"
            onFocus={onFocus}
            onBlur={onBlur}
            type={inputType}
            inpfontsize={inpfontsize}
            {...props}
          />
        </StyledInputTA>
      ) : (
        //InputText/Password
        <StyledInput
          focused={focused ? 1 : undefined}
          hastext={value && value.length > 0 ? 1 : undefined}
          data-testid={testId}
          {...props}
        >
          {icon && (
            <StyledInputIcon>
              <InputIcon src={icon}></InputIcon>
            </StyledInputIcon>
          )}
          <StyledFormControl
            onFocus={onFocus}
            onBlur={onBlur}
            type={inputType}
            inpfontsize={inpfontsize}
            {...props}
          />

          {ctype === 'password' && (
            <StyledInputIcon>
              <ViewAction variant="link" onClick={showHide}>
                <InputIcon src={ic_eye}></InputIcon>
              </ViewAction>
            </StyledInputIcon>
          )}
          {/* New kind of input that allows to remove text clicking on x icon */}
          {ctype === 'remove' && (
            <StyledInputIcon>
              <ViewAction variant="link" onClick={removeText}>
                <InputIcon src={ic_remove}></InputIcon>
              </ViewAction>
            </StyledInputIcon>
          )}
        </StyledInput>
      )}
    </>
  );
};
