import React, { useState, useEffect } from 'react';
import UserIcon from '@assets/icons/User.svg';
import { Formik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, AppDropDownSelect } from '@components';
import { saveEntityData, getEntityData } from '@services';
import { USERS_GROUPS, USERS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;
export const AddEmployees = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [user, setUser] = useState();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [employeesUsers, setEmployeesUsers] = useState([]);
  const addUserSchema = yup.object().shape({
    user_id: yup.object().nullable().required(`${t('validation.employee_required')}`),
  });
  const [loading, setLoading] = useState(false);
  let requestCreateSysUser = {};
  useEffect(() => {
    setLoadingUsers(true);
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'name', order: 'ASC' })
      .setFilter({ field: 'type.description', operator: CondOperator.EQUALS, value: 'Employees' });
    getEntityData(USERS_API, qb.query())
      .then((res) => {
        console.log('AppOrganizations > getEntityData > res=', res);
        setEmployeesUsers(res);
        setLoadingUsers(false);
      })
      .catch((err) => {
        console.log('AppOrganizations > getEntityData > res=', err);
        setLoadingUsers(false);
      });
    return () => {
      console.log('axios cleanup.');
    };
  }, []);
  const addAssignEmployees = (values) => {
    //Hide message
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for GroupList view
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    requestCreateSysUser.user = user;
    requestCreateSysUser.group = data;
    setLoading(true);
    //Add group
    saveEntityData(USERS_GROUPS, requestCreateSysUser)
      .then((res) => {
        //Show message on GroupList view
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_created')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          //Show message
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_employee_user_group_exists')}`,
            message: '',
          });
        } else {
          //Show message
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_user_creating')}`, message: '' });
        }
        setLoading(false);
      });
  };
  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      <Formik
        enableReinitialize
        validationSchema={addUserSchema}
        onSubmit={addAssignEmployees}
        initialValues={{
          user_id: '',
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
          <div style={{ width: '590px', fontSize: '14px' }}>
            <Form
              onSubmit={handleSubmit}
              noValidate
              className="baseForm"
              style={{ padding: '33px 40px 17px' }}
            >
              <div>
                <Row>
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.employee')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownSelect
                      options={employeesUsers.map((option) => ({
                        label: option.name,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      name="user_id"
                      placeholder={t('required')}
                      icon={UserIcon}
                      menuStyle={{
                        backgroundColor: '#FFFFFF',
                        height: 110,
                        marginTop: 8,
                        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
                        borderRadius: 16,
                      }}
                      onChange={(selected) => {
                        setFieldValue('user_id', selected);
                        if (selected) setUser(selected.value);
                      }}
                      isLoading={loadingUsers}
                      invalid={touched.user_id && errors.user_id}
                      defaultValue={values.user_id}
                    />
                    {touched.user_id && errors.user_id && (
                      <ErrorText className="px-2 mb-0">{errors.user_id}</ErrorText>
                    )}
                  </Col>
                </Row>
              </div>
              <StyleButtons>
                <span>
                  <AppButton
                    loading={loading}
                    style={{
                      fontSize: 14,
                      width: 'auto',
                      marginRight: 16,
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    size="lg"
                    type="submit"
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  >
                    {t('buttons.btn_confirm')}
                  </AppButton>
                  <AppButton
                    size="lg"
                    loading={loading}
                    style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={handleclose}
                    title={t('buttons.btn_cancel')}
                  >
                    {t('buttons.btn_cancel')}
                  </AppButton>
                </span>
                {showMessage.show && (
                  <CustomizedSnackbar
                    show={showMessage.show}
                    type={showMessage.type}
                    title={showMessage.title}
                    message={showMessage.message}
                  />
                )}{' '}
              </StyleButtons>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
