import React from 'react';
import styled from 'styled-components';
import { PALETTE_COLOR, ItemTypes } from '@config/CONSTANTS';
import Drag from '@assets/icons/Drag.svg';
import Drag_Blue from '@assets/icons/Drag_Blue.svg';
import ButtonEdit from '@assets/icons/ButtonEdit.svg';
import { AppModal } from '@components';
import { FormWeekTemplate } from './FormWeekTemplate';
import { useDrag } from 'react-dnd';

import { useTranslation } from 'react-i18next';

const TemplateView = styled.div`
  background: ${PALETTE_COLOR.BACKGROUND_BlUE};
  border-radius: 10px;
  min-height: 52px;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-top: 8px;
  gap: 12px;
  cursor: pointer;
`;

const IconWrapper = styled.img`
  height: 80%;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const TitleWraper = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

const SubTitleWrapper = styled.div`
  color: #6d737b;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const WeekTemplateView = ({ data, reloadData }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: ItemTypes.WEEK_TEMPLATE,
      item: data,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 1 : 1,
      }),
    }),
    [],
  );

  return (
    <>
      <TemplateView
        ref={dragRef}
        style={{ opacity }}
        onMouseOver={(e) => {
          setIsHover(true);
        }}
        onMouseOut={(e) => {
          setIsHover(false);
        }}
        onClick={() => setOpenForm(true)}
      >
        <IconWrapper alt="Drag" src={isHover ? Drag_Blue : Drag}></IconWrapper>
        <LabelWrapper>
          <TitleWraper>{data.name}</TitleWraper>
          <SubTitleWrapper>{`${data.total_shifts} ${t('form_labels.shifts').toLowerCase()}`}</SubTitleWrapper>
        </LabelWrapper>
        {isHover ? <IconWrapper alt="ButtonEdit" src={ButtonEdit}></IconWrapper> : null}
      </TemplateView>

      <AppModal
        open={openForm}
        handleclose={() => setOpenForm(false)}
        title={t('JobScheduler.modals.edit_week_template_title')}
        inside={1}
      >
        <FormWeekTemplate
          data={data}
          handleclose={() => setOpenForm(false)}
          reloadData={reloadData}
        />
      </AppModal>
    </>
  );
};
export default WeekTemplateView;
