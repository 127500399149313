import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import styled from 'styled-components';
import ic_arrow_down_enabled from '@assets/icons/ic_arrow_down_enabled.svg';
import ic_arrow_down_disabled from '@assets/icons/ic_arrow_down_disabled.svg';

import i18next from 'i18n'
i18next.changeLanguage(i18next.resolvedLanguage);

const ArrowWrapper = styled.img`
  border: none;
`;

const buildStyles = (height) => {
  return {
    control: (styles, state) => {
      const isInvalid = state.selectProps.invalid;
      return {
        ...styles,
        backgroundColor: `${
          state.isFocused || state.hasValue ? PALETTE_COLOR.WHITE : PALETTE_COLOR.FIELD_COLOR
        }`,
        borderRadius: 16,
        border: isInvalid
          ? `1px solid ${PALETTE_COLOR.FIELD_BORDER_ERROR}`
          : state.isFocused
          ? `1px solid ${PALETTE_COLOR.MAIN_BLUE}`
          : state.hasValue
          ? `1px solid ${PALETTE_COLOR.FIELD_BORDER_COLOR}`
          : 'none',
      };
    },
    placeholder: (styles, state) => {
      return {
        ...styles,
        color: PALETTE_COLOR.INACTIVE_GREY_BLUE,
      };
    },
    indicatorSeparator: (styles, state) => {
      const isDisabled = state.selectProps.isDisabled;
      return {
        ...styles,
        backgroundColor: isDisabled
          ? `${PALETTE_COLOR.INACTIVE_GREY_BLUE}`
          : `${PALETTE_COLOR.MAIN_BLUE}`,
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: PALETTE_COLOR.FIELD_COLOR,
        color: PALETTE_COLOR.MAIN_BLUE,
        borderRadius: 20,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: PALETTE_COLOR.MAIN_BLUE,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: PALETTE_COLOR.MAIN_BLUE,
      ':hover': {
        backgroundColor: PALETTE_COLOR.FIELD_COLOR,
        color: PALETTE_COLOR.BLACK,
        borderRadius: 20,
      },
    }),
    menu: (styles, state) => {
      return {
        ...styles,
        background: PALETTE_COLOR.MAIN_WHITE,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
        borderRadius: 16,
        marginTop: 0,
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        fontWeight: isSelected ? 'bold' : 'normal',
        ':active': {
          ...styles[':active'],
          backgroundColor: PALETTE_COLOR.MAIN_BLUE,
          color: PALETTE_COLOR.MAIN_WHITE,
        },
      };
    },
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      borderRadius: 16,
      maxHeight: height,
      /* Styles for built-in scrollbar for webkit browsers */

      '::-webkit-scrollbar': {
        width: '0.4rem',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0)', // transparent
        borderRadius: '1rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: PALETTE_COLOR.FIELD_BORDER_COLOR,
        borderRadius: '1rem',
        boxShadow: 'inset 0 0 0.6rem rgba(0, 0, 0, 0.2)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: PALETTE_COLOR.FIELD_BORDER_COLOR,
      },
    }),
  };
};

const InputIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 8px;
`;

const AvatarWrapper = styled.div`
  margin-left: 10px;
`;

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.selectProps.icon && !props.selectProps.avatar ? (
        <InputIcon src={props.selectProps.icon} alt="SelectIcon" />
      ) : props.selectProps.avatar ? (
        <AvatarWrapper>{props.selectProps.avatar}</AvatarWrapper>
      ) : null}{' '}
      {children}
    </components.Control>
  );
};
// Good: Custom component declared outside of the Select scope
const DropdownIndicator = ({ children, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowWrapper
        src={props.isDisabled ? ic_arrow_down_disabled : ic_arrow_down_enabled}
        alt="IcArrowWrapper"
      />
    </components.DropdownIndicator>
  );
};

export const AppDropDownMultipleSelect = ({ height = 350, customOption, loadingMessage = i18next.t('loading'),  ...other }) => {
  return (
    <Fragment>
      <Select
        styles={buildStyles(height)}
        components={
          customOption
            ? { Control, DropdownIndicator, Option: customOption }
            : { Control, DropdownIndicator }
        }
        {...other}
        isMulti
        loadingMessage={() => loadingMessage}
      />
    </Fragment>
  );
};
