import React from 'react';
import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.98)',
    color: 'white',
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderRadius: 12,
    padding: '8px 16px 8px 16px',
    left: 0,
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0.98)',
    },
  },
}));

export const AppDarkTooltip = (props) => {
  const { title } = props;
  return (
    <>
      <DarkTooltip title={title} arrow placement="top">
        {props.children}
      </DarkTooltip>
    </>
  );
};
export default AppDarkTooltip;
