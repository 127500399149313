import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import chroma from 'chroma-js';

const BpIcon = styled('span')((props) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: `${props.color}`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: `${chroma(props.color).darken().hex()}`,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: props.theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)((props) => ({
  backgroundColor: `${props.color}`,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: `${props.color}`,
  },
}));

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color={'default'}
      checkedIcon={<BpCheckedIcon color={props.value} />}
      icon={<BpIcon color={props.value} />}
      {...props}
    />
  );
}

export const AppColorRadioSelector = (props) => {
  const { value, options, ...other } = props;

  return (
    <>
      <RadioGroup
        row
        defaultValue={value}
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        {...other}
      >
        {options &&
          options.map((item, index) => {
            return (
              <FormControlLabel
                style={{ marginRight: 5 }}
                key={index}
                value={item.value}
                control={<BpRadio />}
                label={item.label}
              />
            );
          })}
      </RadioGroup>
    </>
  );
};
