import React from 'react';

import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

const StyledCard = styled.div`
  width: auto;
  height: auto;
  left: 0px;
  top: 0px;
  color: #000000;
  display: flex;
  padding: 1px 17px !important;
`;
const Icon = styled.img``;
const StyledCardLabel = styled.div`
  justify-content: start !important;
  -webkit-flex-direction: row !important;
  margin-bottom: 20px;
  font-size: 28px;
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
`;

export function Encabezado(props) {
  const { title, children, icon_button } = props;
  return (
    <>
      <StyledCard>
        <StyledCardLabel>{title} </StyledCardLabel>
        {children}

        {icon_button && (
          <IconButton
            aria-label="edit"
            sx={{
              position: 'absolute',
              right: 16,
              top: 10,
            }}
          >
            <Icon src={icon_button} />
          </IconButton>
        )}
      </StyledCard>
    </>
  );
}
export default Encabezado;
