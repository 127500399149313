import React from 'react';
import styled from 'styled-components';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import ic_search from '@assets/icons/ic_search.svg';

import i18next from 'i18n'
i18next.changeLanguage(i18next.resolvedLanguage);

const FIELD_BORDER_COLOR = PALETTE_COLOR.FIELD_BORDER_COLOR;
const DEFAULT_ALL_CONTROL_OPTION = { label: 'select all', value: '*' };

const OptionLabelWrapper = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin-left: 20px;
  cursor: pointer;
  color: #000;
`;

const buildStyles = (width, height) => {
  return {
    container: (provided, state) => ({
      ...provided,
      width,
    }),
    control: (styles, { selectProps }) => {
      return !selectProps.isSearchable
        ? { display: 'none' }
        : { borderBottom: 'solid 1px #EEEEEE' };
    },
    dropdownIndicator: (provided, state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      display: 'none',
    }),
    option: (styles, { isDisabled, value, data, isSelected }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
        },
        backgroundColor: data.forHeader ? 'white' : styles['backgroundColor'],
        borderBottom: value === DEFAULT_ALL_CONTROL_OPTION.value ? 'solid 1px #EEEEEE' : 'none',
        borderTop: data.isFooter ? 'solid 1px #EEEEEE' : 'none',
      };
    },
    menu: () => ({
      padding: 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: height,
      padding: '0px',
      borderRadius: 16,
      /* Styles for built-in scrollbar for webkit browsers */

      '::-webkit-scrollbar': {
        width: '0.4rem',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0)', // transparent
        borderRadius: '1rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: FIELD_BORDER_COLOR,
        borderRadius: '1rem',
        boxShadow: 'inset 0 0 0.6rem rgba(0, 0, 0, 0.2)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: FIELD_BORDER_COLOR,
      },
    }),
  };
};

const Option = (props) => {
  return (
    <>
      {props.value === DEFAULT_ALL_CONTROL_OPTION.value ? (
        <components.Option {...props}>
          <OptionLabelWrapper style={{ fontWeight: 800 }}>{props.label}</OptionLabelWrapper>
        </components.Option>
      ) : (
        <components.Option {...props}>
          {props.data.forHeader ? (
            props.selectProps.BuildHeader && props.selectProps.BuildHeader(props)
          ) : (
            <OptionLabelWrapper style={{ color: props.isSelected ? '#FFFFFF' : '#000000' }}>
              {props.label}
            </OptionLabelWrapper>
          )}
        </components.Option>
      )}
    </>
  );
};

// Good: Custom component declared outside of the Select scope
const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <div style={{ display: 'flex' }}>
      <span
        style={{
          backgroundImage: `url(${ic_search})`,
          width: 16,
          height: 16,
          marginTop: 18,
          marginLeft: 24,
          marginBottom: 18,
        }}
      ></span>
      {children}
    </div>
  </components.Control>
);

export const AppDropDownSingleSelect = ({
  options,
  defaultValue,
  onChange,
  selectAllControlOption,
  targetComponent,
  allowSelectAll = false,
  showSelection = true,
  height = '30rem',
  width = 250,
  menuStyle,
  headerOptions = [],
  placeholder = `${i18next.t('placeholder_search')}`,
  ...other
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const selectAllOption = selectAllControlOption
    ? selectAllControlOption
    : DEFAULT_ALL_CONTROL_OPTION;

  const initialVisibleOptions = allowSelectAll
    ? [selectAllOption, ...options]
    : [...headerOptions.map((h) => ({ ...h, forHeader: true })), ...options];
  const [selectedValues, setSelectedValues] = React.useState(defaultValue);
  const relayOnChange = (newSelectedOptions, event, props) => {
    setSelectedValues(newSelectedOptions);
    setIsOpen(false);
    setIsOpen(false);
    onChange(newSelectedOptions, event, newSelectedOptions);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    setSelectedValues(defaultValue ? defaultValue : {});
  }, [selectedValues, defaultValue, onChange]);

  return (
    <>
      <div>
        <Dropdown
          isOpen={isOpen}
          style={{ borderRadius: 16 }}
          onClose={toggleOpen}
          menuStyle={menuStyle}
          target={
            <div
              onClick={toggleOpen}
              style={{
                marginTop: showSelection ? 5 : 0,
              }}
            >
              {targetComponent}
            </div>
          }
        >
          <ReactSelect
            {...other}
            className="ianMoone"
            autoFocus
            backspaceRemovesValue={false}
            components={{
              Option,
              Control,
              IndicatorsSeparator: null,
            }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            closeMenuOnSelect={false}
            options={initialVisibleOptions}
            value={selectedValues}
            onChange={relayOnChange}
            placeholder={placeholder}
            styles={buildStyles(width, height)}
            tabSelectsValue={false}
          />
        </Dropdown>
      </div>
    </>
  );
};

// styled components

const Menu = (props) => {
  const shadow = 'rgba(0, 0, 0, 0.15)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 16,
        boxShadow: `0px 4px 16px ${shadow}`,
        marginTop: 0,
        position: 'absolute',
        zIndex: 2,
        ...props.menuStyle,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose, menuStyle }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu menuStyle={menuStyle}>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
