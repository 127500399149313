import React, { useState, useEffect, useCallback } from 'react';
import { AppTable, AppOrgIcon, AppButton } from '@components';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import EncList from '@components/EncList';
import Calender from '@assets/icons/Calender.svg';
import { useSelector } from 'react-redux';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { USERS_API } from '@services/CONSTANTS';
import { getEntityData } from '@services';
import { calcAvatar } from 'utils/avatar';
import ic_eye from '@assets/icons/ic_eye.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { JOB_SCHEDULER, GROPUS, TIME_SHEET } from 'navigation/CONSTANTS';
import { setJobSchedulerGroupAction } from '@redux/actions/appActions';

import { useTranslation } from 'react-i18next';

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Lobby = (props) => {
  const { t } = useTranslation();
  const { destination } = props;
  let history = useHistory();
  const [loadingGroups, setLoadingGroups] = useState([{}]);
  const { userData } = useSelector((state) => state.app);
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();

  let goTo = useCallback(
    (route) => {
      history.push(route);
    },
    [history],
  );

  const setSelectedGroup = React.useCallback(
    async (group) => {
      dispatch(setJobSchedulerGroupAction(group));
    },
    [dispatch],
  );

  const systemColumns = [
    {
      dataField: 'id',
      text: `${t('table_columns.ID')}`,
      hidden: true,
      sort: false,
      search: false,
    },
    {
      dataField: 'group.name',
      text: `${t('table_columns.group_name')}`,
      sort: false,
      search: false,
      headerStyle: { width: '15%' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.group?.name}</StyledLabel>;
      },
    },
    {
      dataField: 'group.sites',
      text: `${t('table_columns.group_sites')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.sites && row.sites.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {row.sites.map((site, index) =>
                index < 5 ? (
                  <div style={{ display: 'flex', marginLeft: '-15px' }} key={index}>
                    <AppOrgIcon color={site.organization_id.color} title={site.name}>
                      {site.name.substring(0, 2).toUpperCase()}
                    </AppOrgIcon>
                  </div>
                ) : (
                  ''
                ),
              )}

              {row.sites.length === 1 ? (
                <StyledLabel style={{ margin: '4px' }}>{row.sites[0].name}</StyledLabel>
              ) : (
                ''
              )}

              {row.sites.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.sites.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'admins',
      text: `${t('table_columns.admins')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.admins && row.admins.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {' '}
              {row.admins.length !== 1 ? (
                row.admins.map((admin, index) =>
                  index < 5 ? (
                    <div style={{ display: 'flex', marginLeft: '-10px' }} key={index}>
                      <span title={admin.user.name}>{calcAvatar(admin.user, 30, 30)}</span>
                    </div>
                  ) : (
                    ''
                  ),
                )
              ) : (
                <div style={{ display: 'flex', marginLeft: '-10px' }}>
                  <span title={row.admins[0].user.name}>
                    {calcAvatar(row.admins[0].user, 30, 30)}
                  </span>
                  <StyledLabel style={{ margin: '4px' }}>{row.admins[0].user.name}</StyledLabel>
                </div>
              )}
              {row.admins.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.admins.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'employees',
      text: `${t('table_columns.employees')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      formatter: (cellContent, row) => {
        if (row.employees && row.employees.length > 0) {
          return (
            <div
              style={{
                display: 'flex',
                marginLeft: '15px',
              }}
            >
              {' '}
              {row.employees.map((employee, index) =>
                index < 5 ? (
                  <div style={{ display: 'flex', marginLeft: '-10px' }} key={index}>
                    <span title={employee.user.name}>{calcAvatar(employee.user, 30, 30)}</span>
                  </div>
                ) : (
                  ''
                ),
              )}
              {row.employees.length === 1 ? (
                <StyledLabel style={{ margin: '4px' }}>{row.employees[0].user.name}</StyledLabel>
              ) : (
                ''
              )}
              {row.employees.length > 5 ? (
                <StyledLabel style={{ margin: '4px' }}>
                  {row.employees.length - 5}
                  {` ${t('form_labels.more')}`}
                </StyledLabel>
              ) : (
                ''
              )}
            </div>
          );
        }
      },
    },
    {
      dataField: 'actions',
      text: `${t('table_columns.view')}`,
      sort: false,
      search: false,
      headerStyle: { width: '5%' },
      formatter: (cellContent, row) => {
        if (row.group) {
          return (
            <StyledLabel>
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedGroup(row.group);
          if (destination) {
            goTo(TIME_SHEET.replace(':type', 'day'));
          } else {
            goTo(JOB_SCHEDULER.replace(':type', 'calendar'));
          }
        },
      },
    },
  ];

  useEffect(() => {
    if (userData) {
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userGroups`, qb2.query())
        .then((res) => {
          setLoadingGroups(res.data);
          if (res.length === 1) {
            setSelectedGroup(res.data[0].group);
          }
          setLoadingData(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    }
  }, [userData, setSelectedGroup]);

  return (
    <Container fluid>
      <EncList
        title={`${destination ? t('Timesheet.title') : t('JobScheduler.title')}`}
        icon={Calender}
      >
        {!destination ? (
          <HeaderButton>
            <AppButton
              tooltiptitle={t('tooltips.new_group')}
              bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
              bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
              bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
              borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
              icon={ic_add_circle}
              onClick={() => goTo(GROPUS.replace(':type', 'group'))}
            >
              {t('buttons.btn_new_group')}
            </AppButton>
          </HeaderButton>
        ) : null}
      </EncList>
      <Col>
        <AppTable
          columns={systemColumns}
          data={loadingGroups}
          loading={loadingData}
          disableFilters={true}
          disablePagination={true}
          disableTopPagination={true}
          noBorders={true}
        />
      </Col>
    </Container>
  );
};
