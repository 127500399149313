import React, { useEffect, useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppDivButton, AppModal, AppModalStatus } from '@components';
import styled from 'styled-components';
import ic_send_green_blue from '@assets/icons/ic_send_green_blue.svg';
import { SHIFTS_API } from 'services/CONSTANTS';
import { saveEntityData } from '@services';
import { useDispatch, useSelector } from 'react-redux';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import { showMessageAction } from '@redux/actions/appActions';

import { useTranslation } from 'react-i18next';

const DivMessage = styled.div`
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
`;

const DivTotalShifts = styled.div`
  background: #e7f5ff;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 15px;
  text-align: center;
  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .number-shifts {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #000000;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0px;
  }
`;

const requestPublishMassShifts = {
  shifts: [],
  user: {},
};

export const AppMassPublishShiftButton = (props) => {
  const { t } = useTranslation();
  const { shiftsList, loading } = props;

  //Redux
  const dispatch = useDispatch();
  //Get logged user info
  const { userData } = useSelector((state) => state.app);

  const [draftShifts, setDraftShifts] = useState([]);
  const [isPublising, setIsPublising] = useState(false);
  //Modal
  const [openModal, setOpenModal] = useState(false);
  //Modal content
  const modalContent = {
    buttonText: `${t('buttons.btn_publish')}`,
    actionIcon: ic_send_green_blue,
    title: `${t('JobScheduler.modals.publish_shifts_title')}`,
    subTitle: `${t('JobScheduler.modals.publish_shifts_subtitle')}`,
    bgcolor: PALETTE_COLOR.MAIN_BLUE,
    bordercolor: PALETTE_COLOR.MAIN_BLUE,
    borderhovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    bghovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  };
  //Snackbar
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  useEffect(() => {
    //Filter only shifts with draft as status
    setDraftShifts(shiftsList.filter((shift) => shift.status.value_definition === '020'));
  }, [shiftsList]);

  /***
   * To open and close the confirmation modal
   */
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  /***
   * To publish draft shifts, only which are valid
   */
  const handlePublishDrafts = () => {
    setIsPublising(true);
    //Remove unnecessary array
    setDraftShifts(
      draftShifts.map((draft) => {
        if (draft.rows) delete draft.rows;
        return draft;
      }),
    );
    //Build request
    requestPublishMassShifts.shifts = draftShifts;
    requestPublishMassShifts.user = userData;
    //Call api
    saveEntityData(`${SHIFTS_API}publish/draft-shifts`, requestPublishMassShifts)
      .then((res) => {
        //Close modal
        handleModal();
        //Show message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.shifts_published')}`,
          message: `${t('success_messages.shifts_info_published')}: ${res}`,
        });
        //Reload calendar or list view
        dispatch(setReloadSchedulerAction(true));
        setIsPublising(false);
      })
      .catch((err) => {
        //Close modal
        handleModal();
        //Show message
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_shifts_publishing')}`,
          message: '',
        });
        setIsPublising(false);
      });
  };

  return (
    <>
      <AppDivButton
        style={{ width: 150, height: 40 }}
        type="submit"
        bgcolor={PALETTE_COLOR.MAIN_GREEN}
        bghovercolor={PALETTE_COLOR.MAIN_GREEN}
        loading={loading}
        onClick={draftShifts.length > 0 ? handleModal : null}
      >
        {t('buttons.btn_publish')} • {draftShifts ? draftShifts.length : 0}
      </AppDivButton>
      {
        /* Edit confirmation modal */
        <AppModal open={openModal} handleclose={handleModal} boxshadow={'none'} inside={1}>
          <AppModalStatus
            actionIcon={modalContent.actionIcon}
            title={modalContent.title}
            subtitle={
              <>
                <DivMessage>{modalContent.subTitle}</DivMessage>
                <DivTotalShifts>
                  <p className="title">{t('JobScheduler.modals.publish_shifts_info')}</p>
                  <p className="number-shifts">{draftShifts.length}</p>
                </DivTotalShifts>
              </>
            }
            bgcolor={modalContent.bgcolor}
            bordercolor={modalContent.bordercolor}
            borderhovercolor={modalContent.borderhovercolor}
            bghovercolor={modalContent.bghovercolor}
            boxShadow={modalContent.boxShadow}
            buttonText={modalContent.buttonText}
            buttonAction={handlePublishDrafts}
            mainPadding={'25px 30px 64px 30px'}
            isLoading={isPublising}
          />
        </AppModal>
      }
    </>
  );
};
export default AppMassPublishShiftButton;
