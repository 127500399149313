import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { InputForm, AppDropDownMultipleSelect, AppButton } from '@components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { editEntityData, getEntityData, obtainEntityData, saveEntityData } from '@services';
import { SITES_API, USERS_API, GROUPS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { AppDropDownSelect, AppAsyncLocationDropdown } from '@components';
import { CustomOption } from 'utils/avatar';
import { useSelector } from 'react-redux';
import { calcOrgCircle, SingleOption } from 'utils/coloredDot';
import EllipseIcon from '@assets/icons/ic_grey_ellipse.svg';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 25px;
  margin: 0.575rem 0px 15px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const StyleCheckBox = styled.div`
  display: flex;
  padding-left: 5%;
`;

const StyleCheckLabel = styled(Form.Check.Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  margin-left: 10px;
  font-weight: 300;
  color: ${PALETTE_COLOR.INACTIVE_GRAY};
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleSpaceButtonsRigth = styled.div`
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
`;

export const RestoreSite = ({ handleclose, setShowSucessModal, siteData }) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [sitesUsers, setSitesUsers] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [site, setSite] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  //Redux
  const { valuesDefinitions, userPermissions } = useSelector((state) => state.app);
  //Get site status
  const siteStatusList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'SITE_STATUS',
  );
  const organizations = [siteData.organization_id];

  /***
   *
   */
  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  //Get site info
  useEffect(() => {
    setLoading(true);
    let qb = RequestQueryBuilder.create().setJoin([
      { field: 'organizations_users_sites' },
      { field: 'organizations_users_sites.organization_user' },
      { field: 'organizations_users_sites.organization_user.user_id' },
      { field: 'organizations_users_sites.organization_user.user_id.role' },
    ]);
    obtainEntityData(`${SITES_API}${siteData.id}?${qb.query()}`)
      .then((res) => {
        setSite(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoading(false);
      });

    return () => {
      console.log('axios cleanup.');
    };
  }, [siteData]);

  /***
   * Get all Site Managers who belong to same Org or those
   * who do not belong to any Org
   */
  useEffect(() => {
    setLoadingUsers(true);
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'name', order: 'ASC' })
      .setFilter({
        field: 'type.value_definition',
        operator: CondOperator.EQUALS,
        value: '020', //Organizations
      })
      .setFilter({
        field: 'role.description',
        operator: CondOperator.NOT_NULL,
      })
      .setFilter({
        field: 'role.value_definition',
        operator: CondOperator.EQUALS,
        value: '020', //Site manager
      })
      .setFilter({
        field: 'status.value_definition',
        operator: CondOperator.EQUALS,
        value: '010', //Active
      });

    getEntityData(USERS_API, qb.query())
      .then((res) => {
        let filteredData = [];
        //Filter org
        res.forEach((siteManager) => {
          //Get group site ids
          let siteManagerOrg = siteManager.organizations.map((org) => org.id);
          if (siteManagerOrg.some((idOrg) => idOrg === Number(siteData.organization_id.id))) {
            filteredData.push(siteManager);
          }
        });
        setSitesUsers(filteredData);
        setLoadingUsers(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoadingUsers(false);
      });
    return () => {
      console.log('axios cleanup.');
    };
  }, [siteData]);

  /***
   * Get groups
   */
  useEffect(() => {
    setLoadingGroups(true);
    if (userPermissions?.type === 'system') {
      setGroupsList(
        userPermissions?.systemUserGroups?.filter((group) => group.permission === '020'),
      ); //Only groups with full access
      setLoadingGroups(false);
    } else {
      const qb = RequestQueryBuilder.create().sortBy({ field: 'name', order: 'ASC' });
      getEntityData(GROUPS_API, qb.query())
        .then((res) => {
          setGroupsList(res);
          setLoadingGroups(false);
        })
        .catch((err) => {
          setLoadingGroups(false);
          console.log('axios err=', err);
        });
    }
    return () => {
      console.log('axios cleanup.');
    };
  }, [userPermissions]);

  let restoreSiteRequest = {
    name: '',
    phone: '',
    email: '',
    address: '',
    use_data: '',
    groups: [],
    representatives: [],
    representative_phone: '',
    representative_email: '',
  };

  /***
   * Restore a site
   */
  const restoreSite = (values) => {
    setRequestLoading(true);
    //Hide message
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for GroupList view
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    nameWithoutSpaces = nameWithoutSpaces.trim();
    values.name = nameWithoutSpaces;
    const { action } = values;
    const request_user_groups = [values.group_id.value];
    restoreSiteRequest = { ...values };
    restoreSiteRequest.address = values.address.label;
    restoreSiteRequest.groups = request_user_groups;
    restoreSiteRequest.organization_id = { id: siteData.organization_id.id };
    if (values.representatives) {
      restoreSiteRequest.representatives = values.representatives.map((rep) => {
        return rep.value;
      });
    }

    const status = siteStatusList.filter((status) => status.value_definition === action)[0];
    restoreSiteRequest.status = status;
    //Call API
    editEntityData(`${SITES_API}${siteData.id}`, restoreSiteRequest)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.site_restored')}`,
          message: '',
        });
        handleclose();
        setRequestLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.site_exists')}`,
            message: '',
          });
        } else {
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_site_restoring')}`, message: '' });
        }
        setRequestLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{ width: 600 }}>
        <Lottie
          animationData={groovyWalkAnimation}
          autoPlay={true}
          loop={true}
          style={{ height: 60 }}
        />
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={object().shape({
        name: yup.string().required(`${t('validation.name_required')}`),
        organization: yup.object().nullable().required(`${t('validation.organization_required')}`),
        address: yup.object().nullable().required(`${t('validation.address_required')}`),
        group_id: yup.object().required(`${t('validation.group_required')}`),
        email: yup
          .string()
          .email(`${t('validation.email_invalid')}`)
          .required(`${t('validation.email_required')}`),
        phone: yup
          .string()
          .required(`${t('validation.phone_required')}`),
        representatives: yup.array().nullable(),
      })}
      onSubmit={restoreSite}
      initialValues={{
        ...site,
        organization: {
          label: siteData.organization_id.name,
          value: siteData.organization_id,
          color: siteData.organization_id.color,
          innerText: true,
        },
        group_id:
          site.groupsSite && site.groupsSite.length > 0
            ? { label: site.groupsSite[0].group.name, value: site.groupsSite[0].group }
            : '',
        address: { label: site.address, value: site.address },
        representatives:
          site &&
          site.organizations_users_sites &&
          site.organizations_users_sites
            .filter(
              (ous) =>
                ous.organization_user.user_id.role &&
                ous.organization_user.user_id.role.value_definition === '020',
            )
            .map((ous) => {
              return {
                label: ous.organization_user.user_id.name,
                value: ous.organization_user.user_id,
              };
            }),
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
        <div style={{ width: '600px', fontSize: 14 }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.organization')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={organizations.map((option) => ({
                    label: option.name,
                    value: option,
                    color: option.color,
                    innerText: true,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  isSearchable={true}
                  isDisabled={true}
                  name="organization"
                  placeholder={t('required')}
                  customOption={SingleOption}
                  avatar={
                    values.organization && values.organization.value
                      ? calcOrgCircle(values.organization)
                      : null
                  }
                  icon={EllipseIcon}
                  value={values.organization}
                  invalid={touched.organization && errors.organization}
                  defaultValue={values.organization}
                  height={180}
                  onChange={(selected) => {
                    setFieldValue('organization', selected);
                  }}
                />
                {touched.organization && errors.organization && (
                  <ErrorText className="px-2 mb-0">{errors.organization}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.address')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppAsyncLocationDropdown
                  loadOptions={(values) => loadLocationOptions(values)}
                  //onInputChange={(value) => setQuery(value)}
                  setLeftPadding
                  height="200px"
                  placeholder={t('required')}
                  isClearable={true}
                  name="address"
                  defaultValue={values.address}
                  onChange={(selected) => {
                    setFieldValue('address', selected);
                    if (selected && selected.place) {
                      setFieldValue('latitude', selected.place.Geometry.Point[1]);
                      setFieldValue('longitude', selected.place.Geometry.Point[0]);
                    }
                  }}
                />
                {touched.address && errors.address && (
                  <ErrorText className="px-2 mb-0">{errors.address}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.site_managers')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownMultipleSelect
                  options={sitesUsers.map((option) => ({
                    label: option.name,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  customOption={CustomOption}
                  name="representatives"
                  placeholder={t('optional')}
                  isLoading={loadingUsers}
                  value={values.representatives}
                  height={300}
                  onChange={(selected) => {
                    setFieldValue('representatives', selected);
                  }}
                  invalid={touched.representatives && errors.representatives}
                  defaultValue={values.representatives}
                />
                {touched.representatives && errors.representatives && (
                  <ErrorText className="px-2 mb-0">{errors.representatives}</ErrorText>
                )}
                <StyleCheckBox className="pl-2">
                  <Form.Check
                    className="pt-2"
                    type="checkbox"
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    <Form.Check.Input
                      type="checkbox"
                      style={{ width: 22, height: 22, marginTop: 0, borderRadius: 8 }}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          if (values.representatives && values.representatives.length > 0) {
                            setFieldValue(`email`, values.representatives[0].value.email);
                            setFieldValue(`phone`, values.representatives[0].value.phone);
                          }
                        } else {
                          setFieldValue(`email`, '');
                          setFieldValue(`phone`, '');
                        }
                      }}
                      value={values.use_data}
                    />
                    <StyleCheckLabel style={{ fontSize: '14px' }}>
                      {t('Organization.use_first_representative')}
                    </StyleCheckLabel>
                  </Form.Check>
                </StyleCheckBox>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.group')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={groupsList.map((option) => ({ label: option.name, value: option }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height={120}
                  menuStyle={{
                    position: 'fixed',
                    backgroundColor: '#FFFFFF',
                    width: 400,
                    marginTop: 8,
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
                    borderRadius: 16,
                  }}
                  name="group_id"
                  placeholder={t('required')}
                  isLoading={loadingGroups}
                  onChange={(selected) => {
                    if (selected === null || selected === 'null') {
                      selected = '';
                    }
                    setFieldValue('group_id', selected);
                  }}
                  invalid={touched.group_id && errors.group_id}
                  defaultValue={values.group_id}
                />
                {touched.group_id && errors.group_id && (
                  <ErrorText className="px-2 mb-0">{errors.group_id}</ErrorText>
                )}
              </Col>
            </Row>

            <StyleButtons style={{ marginTop: 30 }}>
              <AppButton
                size="lg"
                type="submit"
                onClick={() => {
                  setFieldValue('action', '010');
                }}
                loading={requestLoading}
                style={{
                  width: 'auto',
                  fontSize: '14px',
                }}
                color={PALETTE_COLOR.MAIN_WHITE}
                bgcolor={PALETTE_COLOR.MAIN_GREEN}
                bordercolor={PALETTE_COLOR.MAIN_GREEN}
                bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              >
                {t('buttons.btn_restore')}
              </AppButton>
              <div style={{ margin: '0.0rem 20px 0px' }}>
                <AppButton
                  loading={requestLoading}
                  style={{
                    fontSize: '14px',
                  }}
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </div>
              <StyleSpaceButtonsRigth>
                <AppButton
                  type="submit"
                  onClick={() => {
                    setFieldValue('action', '020');
                  }}
                  style={{
                    width: 'auto',
                    fontSize: '14px',
                  }}
                  loading={requestLoading}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  title={t('buttons.btn_restore_blocked')}
                >
                  {t('buttons.btn_restore_blocked')}
                </AppButton>
              </StyleSpaceButtonsRigth>
              {showMessage.show && (
                <CustomizedSnackbar
                  show={showMessage.show}
                  type={showMessage.type}
                  title={showMessage.title}
                  message={showMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
