import React from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Stack } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '@assets/images/logo.svg';
import horizen from '@assets/images/Horizen.svg';
import ic_document from '@assets/icons/ic_document_terms.svg';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #e0f4ff;
`;

const Title = styled.h2`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: unset;
  margin-top: 50px;
`;

const ImageWrapper = styled.img`
  height: 80px;
  width: auto;
`;

const LogoWrapper = styled.img`
  height: auto;
  width: 152px;
  margin-top: 30px;
`;

const SubTitle = styled.h3`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  width: 100%;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 20px;
`;

const Content = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: ${PALETTE_COLOR.BLACK};

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;

export default function Privacy() {
  return (
    <Wrapper style={{ overflowY: 'auto' }}>
      <div className="container d-flex flex-column h-100 align-items-center">
        {/* Logo Section */}
        <Stack
          direction="horizontal"
          gap={30}
          style={{
            alignSelf: 'center',
          }}
        >
          <div
            className="container d-flex flex-column align-items-center"
            style={{ paddingTop: '48px' }}
          >
            <ImageWrapper src={logo} alt="Horizen Logo" style={{ display: 'block' }} />
            <LogoWrapper src={horizen} alt="Horizen Name" style={{ display: 'block' }} />
          </div>
        </Stack>

        {/* Términos y Condiciones */}
        <Stack
          direction="horizontal"
          gap={0}
          style={{
            justifyContent: 'normal',
          }}
        >
          <Title>
            <img src={ic_document} alt="ic_document" style={{ paddingRight: 20 }} />
            Términos y Condiciones
          </Title>
        </Stack>
        <Stack
          direction="vertical"
          gap={0}
          style={{
            justifyContent: 'normal',
            marginTop: 20,
          }}
        >
          <Content>
            Bienvenido/a al portal web de nuestra aplicación de gestión de turnos. Antes de utilizar
            nuestros servicios, por favor lea detenidamente los siguientes términos y condiciones.
            Al acceder y utilizar nuestro portal web, usted acepta estar legalmente vinculado por
            estos términos y condiciones.
          </Content>

          {/* Terminología */}
          <SubTitle>Terminología:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>"Nosotros", "nuestro"</strong>: se refiere a la empresa propietaria y
                desarrolladora del portal web de la aplicación de gestión de turnos.
              </li>
              <li>
                <strong>"Usted", "su"</strong>: se refiere al usuario del portal web, que puede ser
                un administrador de la aplicación.
              </li>
              <li>
                <strong>"Administrador"</strong>: se refiere a los usuarios con privilegios de
                administración en el portal web.
              </li>
              <li>
                <strong>"Aplicación Web"</strong>: se refiere al portal en línea utilizado por los
                administradores para gestionar la aplicación móvil.
              </li>
              <li>
                <strong>"Turnos"</strong>: se refiere a los horarios de trabajo asignados a los
                empleados.
              </li>
            </ul>
          </Content>

          {/* Descripción de los Servicios */}
          <SubTitle>Descripción de los Servicios:</SubTitle>
          <Content>
            Nuestra aplicación web ofrece a los administradores las siguientes funciones:
            <ul>
              <li>
                <strong>Gestión de Usuarios:</strong> Los administradores pueden crear, editar y
                bloquear cuentas de usuarios, incluyendo empleados y administradores.
              </li>
              <li>
                <strong>Gestión de Turnos:</strong> Los administradores pueden crear, editar y
                eliminar turnos asignados a los empleados.
              </li>
              <li>
                <strong>Hojas de Registro:</strong> Registro de todas las horas trabajadas por los
                empleados.
              </li>
              <li>
                <strong>Chat:</strong> Los administradores pueden comunicarse entre ellos y con los
                empleados a través de un sistema de chat integrado en el portal web.
              </li>
            </ul>
          </Content>

          {/* Uso del Software */}
          <SubTitle>Uso del Software:</SubTitle>
          <Content>
            Al utilizar nuestro portal web, usted acepta:
            <ul>
              <li>No revender, sublicenciar o extraer el código fuente de la aplicación.</li>
              <li>
                Que podemos realizar actualizaciones automáticas del portal web sin previo aviso.
              </li>
            </ul>
          </Content>

          {/* Asuntos Legales */}
          <SubTitle>Asuntos Legales:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Limitaciones y Exclusiones:</strong> Nos esforzamos por proporcionar
                servicios de alta calidad, pero no garantizamos que el portal web esté libre de
                errores o que funcione sin interrupciones.
              </li>
              <li>
                <strong>Responsabilidades de Uso:</strong> Usted es responsable de la seguridad de
                su cuenta y del uso que haga del portal web. No nos hacemos responsables de ningún
                daño o pérdida causada por el uso indebido del mismo.
              </li>
            </ul>
          </Content>
        </Stack>

        {/* Política de Privacidad */}
        <Stack
          direction="horizontal"
          gap={0}
          style={{
            justifyContent: 'normal',
            marginTop: 40, // Added margin for separation
          }}
        >
          <Title>
            <img src={ic_document} alt="ic_document" style={{ paddingRight: 20 }} />
            Política de Privacidad
          </Title>
        </Stack>
        <Stack
          direction="vertical"
          gap={0}
          style={{
            justifyContent: 'normal',
            marginTop: 20,
          }}
        >
          <Content>
            En nuestro portal web de la aplicación de gestión de turnos, nos comprometemos a
            proteger su privacidad y mantener la confidencialidad de su información personal. A
            continuación, detallamos cómo recopilamos, utilizamos y protegemos la información que
            usted nos proporciona:
          </Content>

          {/* 1. Información Recopilada */}
          <SubTitle>1. Información Recopilada:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Información de la Cuenta:</strong> Al crear una cuenta en nuestro portal
                web, recopilamos la siguiente información: su nombre completo, es decir nombres y
                apellidos, dirección de correo electrónico, número telefónico, documento de
                identidad, dirección y contraseña y foto de perfil, todo almacenado seguramente y
                con control de acceso solo a los administradores autorizados.
              </li>
              <li>
                <strong>Información de Usuarios y Empleados:</strong> Para la gestión de usuarios y
                empleados, recopilamos datos como nombres, direcciones de correo electrónico,
                números de teléfono y detalles de empleo, de tal forma que cada usuario pueda
                ingresar y gestionar sus credenciales, y la información de contacto se usa para
                contactar y enviar mensajes de notificaciones de los turnos y actualizaciones de los
                mismos. Información de Usuarios y Empleados: Para la gestión de usuarios y
                empleados, recopilamos datos como nombres, direcciones de correo electrónico,
                números de teléfono y detalles de empleo. Uso exclusivo de los datos: Los datos de
                los usuarios tanto administradores como empleados no serán divulgados ni compartido
                a terceros, puesto que su uso es exclusivo de la aplicación y su operación.
              </li>
              <li>
                <strong>Datos de Turnos y Hojas de Registro:</strong> Registramos los turnos
                asignados a los empleados, así como las horas trabajadas, para fines de seguimiento
                y gestión.
              </li>
            </ul>
          </Content>

          {/* 2. Uso de la Información */}
          <SubTitle>2. Uso de la Información:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Funcionalidad de la Aplicación:</strong> Utilizamos la información
                recopilada para proporcionar y mejorar los servicios ofrecidos a través de nuestro
                portal web administrativo controlando el acceso a solo el personal autorizado,
                incluida la gestión de turnos y la comunicación entre usuarios.
              </li>
              <li>
                <strong>Comunicación:</strong> Utilizamos su dirección de correo electrónico y
                número de teléfono para enviar notificaciones sobre turnos, actualizaciones del
                portal web y comunicaciones importantes relacionadas con su cuenta.
              </li>
              <li>
                <strong>Mejora del Servicio:</strong> Analizamos los datos de uso del portal web
                para mejorar la experiencia del usuario, identificar áreas de mejora y solucionar
                problemas técnicos.
              </li>
            </ul>
          </Content>

          {/* 3. Geolocalización y Uso de la Ubicación */}
          <SubTitle>3. Geolocalización y Uso de la Ubicación:</SubTitle>
          <Content>
            Nuestra aplicación móvil utiliza la geolocalización para optimizar la gestión de turnos
            y mejorar la eficiencia operativa. El acceso a la ubicación se utiliza exclusivamente
            bajo los siguientes términos:
            <ul>
              <li>
                HORIZEN recopila y transmite datos de ubicación para habilitar habilitar turnos y
                mostrar ubicación de los empleados a los administradores en el portal web, solo
                cuando el usuario tiene la aplicación en segundo o primer plano, adicionalmente solo
                se consultan los datos de ubicación dos horas antes del inico de turno y durante el
                turno. Si el usuario no da permiso explícito de acceso a la ubicación, tiene la
                aplicación cerrada o tiene la GPS apagado los datos dde ubicación no serán
                recopilados ni consultados.
              </li>
              <li>
                <strong>Activación de Turnos:</strong> La aplicación registra la ubicación del
                empleado para permitir la activación del turno solo cuando el empleado se encuentra
                físicamente en el sitio del servicio. Esto asegura que el turno se registre
                adecuadamente y evita activaciones falsas.
              </li>
              <li>
                <strong>Supervisión por Administradores:</strong> Los administradores tienen acceso
                a la ubicación del empleado en tres momentos clave:
                <ul>
                  <li>Dos horas antes del inicio del turno,</li>
                  <li>Durante el turno,</li>
                  <li>
                    Y hasta dos horas después del turno, si el empleado tiene otro turno consecutivo
                    programado. Esta funcionalidad tiene como objetivo la mejora de la supervisión
                    operativa y la gestión de llegadas tardías o ausencias, siempre con el
                    consentimiento previo del empleado.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Consentimiento del Empleado:</strong> La ubicación solo se rastrea si el
                empleado ha dado los permisos necesarios en la aplicación. Si el empleado decide
                revocar estos permisos, la funcionalidad de geolocalización será desactivada y el
                administrador no podrá acceder a su ubicación.
              </li>
            </ul>
          </Content>

          {/* 4. Seguridad de la Información de Ubicación */}
          <SubTitle>4. Seguridad de la Información de Ubicación:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Seguridad de Datos:</strong> Implementamos medidas de seguridad para
                proteger la información de ubicación del empleado. Solo el personal autorizado tiene
                acceso a estos datos, y la información se almacena de forma segura para evitar
                accesos no autorizados o divulgaciones indebidas.
              </li>
              <li>
                <strong>Acceso Restringido:</strong> La información de ubicación es utilizada
                exclusivamente para los fines mencionados anteriormente y no se comparte con
                terceros, a menos que sea requerido por ley.
              </li>
            </ul>
          </Content>

          {/* 5. Compartir Información */}
          <SubTitle>5. Compartir Información:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Terceros:</strong> No compartimos su información personal con terceros,
                excepto cuando sea necesario para proporcionar los servicios solicitados por usted o
                cuando estemos legalmente obligados a hacerlo.
              </li>
              <li>
                <strong>Empleador:</strong> Los datos de turnos y horas trabajadas pueden ser
                compartidos con su empleador con el propósito de gestionar la asistencia y la
                nómina.
              </li>
            </ul>
          </Content>

          {/* 6. Retención de Datos */}
          <SubTitle>6. Retención de Datos:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Duración de la Retención:</strong> Conservamos sus datos personales durante
                el tiempo necesario para cumplir con los fines para los que fueron recopilados, a
                menos que se requiera o permita una retención más prolongada por ley.
              </li>
            </ul>
          </Content>

          {/* 7. Derechos del Usuario */}
          <SubTitle>7. Derechos del Usuario:</SubTitle>
          <Content>
            <ul>
              <li>
                <strong>Eliminación de Datos:</strong> Usted puede solicitar la eliminación de su
                cuenta y datos personales, sujeto a las obligaciones legales de retención de datos.
              </li>
            </ul>
          </Content>

          {/* 8. Solicitud de Cancelación o Eliminación de Datos */}
          <SubTitle>8. Consultas y Solicitud de Eliminación de Datos:</SubTitle>
          <Content>
            Si desea cancelar su cuenta, solicitar la eliminación de datos, o realizar cualquier
            consultada, por favor póngase en contacto con el propietario de la licencia de la
            aplicación a través del correo de soporte Carlos Méndez en{' '}
            <a href="mailto:info@bananascript.io">info@bananascript.io</a>.
          </Content>

          {/* Finalización */}
          <Content style={{ marginTop: 20 }}>
            Al utilizar nuestro portal web de gestión de turnos, usted acepta esta política de
            privacidad. Si tiene alguna pregunta o inquietud sobre nuestras prácticas de privacidad,
            no dude en ponerse en contacto con nosotros a través de los canales de soporte
            proporcionados.
          </Content>

          {/* Agradecimiento */}
          <Content style={{ marginTop: 20, paddingBottom: 100 }}>
            Gracias por utilizar nuestro portal web de gestión de turnos.
          </Content>
        </Stack>
      </div>
    </Wrapper>
  );
}
