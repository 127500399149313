import React, { useState, useEffect } from 'react';
import UserIcon from '@assets/icons/User.svg';
import { Formik } from 'formik';
import { object } from 'yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, AppCheckBoxList, AppDropDownSelect } from '@components';
import { saveEntityData, getEntityData, obtainEntityData } from '@services';
import { USERS_API, ORGANIZATIONS_USERS_API, ORGANIZATIONS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { calcAvatar, CustomOption } from 'utils/avatar';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 25px;
  margin: 0.575rem 0px 30px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const ErrorText = styled.p`
  font-size: 13px;

  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleItalicLabel = styled.div`
  padding-top: 10px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Poppins';
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

export const AddEmployee = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const { id_organization } = data;
  const [employeesUsers, setEmployeesUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  //Redux
  const { userPermissions } = useSelector((state) => state.app);

  //LOAD ORGANIZATION INFO
  useEffect(() => {
    setLoading(true);
    if (id_organization) {
      let qb = RequestQueryBuilder.create()
        .setJoin([
          { field: 'organizations_sites' },
          { field: 'organizations_sites.groupsSite' },
          { field: 'organizations_sites.groupsSite.group' },
        ])
        .sortBy({ field: 'organizations_sites.name', order: 'ASC' });
      obtainEntityData(`${ORGANIZATIONS_API}${id_organization}?${qb.query()}`)
        .then((res) => {
          setOrganization(res);
          //LOAD USERS WITHIN SITES GROUPS
          if (res.organizations_sites && res.organizations_sites.length > 0) {
            let groupSites = res.organizations_sites.map((os) => os.groupsSite);
            let allGroups = [];
            groupSites.forEach((groupSite) => {
              groupSite.forEach((groupSiteInner) => {
                if (allGroups.filter((g) => g.id === groupSiteInner.group.id).length === 0) {
                  allGroups.push(groupSiteInner.group);
                }
              });
            });
            const qb = RequestQueryBuilder.create()
              .sortBy({ field: 'name', order: 'ASC' })
              .setFilter([
                {
                  field: 'type.value_definition',
                  operator: CondOperator.EQUALS,
                  value: '010',
                },
                {
                  field: 'user_groups.group',
                  operator: CondOperator.IN,
                  value: allGroups.map((ag) => ag.id),
                },
              ]);
            getEntityData(USERS_API, qb.query())
              .then((res) => {
                setEmployeesUsers(res);
                setLoading(false);
              })
              .catch((err) => {
                console.log('USERS_API err=', err);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log('ORGANIZATIONS_API err=', err);
          setLoading(false);
        });
    }
  }, [id_organization]);

  let requestCreateEmployee = {
    //cambiar
    user_id: '',
    sites: [],
  };

  /***
   *
   */
  const AddEmployee = (values) => {
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setLoading(true);
    const request_sites = values?.sites.map((s) => s.value);
    requestCreateEmployee = { ...values };
    requestCreateEmployee.sites = request_sites;
    requestCreateEmployee.organization_id = id_organization;
    saveEntityData(ORGANIZATIONS_USERS_API, requestCreateEmployee)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.employee_created')}`,
          message: '',
        });
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_employee_user_organization_exists')}`,
            message: '',
          });
        } else {
          setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_employee_creating')}`, message: '' });
        }
        setLoading(false);
      });
  };

  /***
   * If employee selected: Return sites where employee belongs
   */
  const getSitesOptions = (user) => {
    let userGroups = user && user.user_groups ? user.user_groups.map((ug) => ug.group.id) : [];
    //SITES that are included in the user groups
    let sites = [];
    organization &&
      organization.organizations_sites &&
      organization.organizations_sites.length > 0 &&
      organization.organizations_sites.forEach((element) => {
        //Get group site ids
        let orgSitesGroups = element.groupsSite.map((gs) => gs.group.id);
        //Only groups where employee belongs
        if (userGroups.some((r) => orgSitesGroups.includes(r))) {
          //Only groups where system user has full access permission
          if (userPermissions?.type === 'system') {
            if (
              userPermissions?.systemUserGroups.some(
                (sysG) => orgSitesGroups.includes(sysG.id) && sysG.permission === '020',
              )
            ) {
              sites.push(element);
            }
          } else {
            //Not a system user
            sites.push(element);
          }
        }
      });
    return organization && !user //When an employee has not been selected
      ? organization.organizations_sites.map((option) => ({
          label: option.name,
          subtitle: option.address,
          id: option.id,
          value: option,
        }))
      : user //When an employee has been selected
      ? sites.map((option) => ({
          label: option.name,
          subtitle: option.address,
          id: option.id,
          value: option,
        }))
      : [];
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={object().shape({
        user_id: yup.object().required(`${t('validation.employee_required')}`),
        sites: yup.array().min(1, `${t('validation.permitted_sites_required')}`),
      })}
      onSubmit={AddEmployee}
      initialValues={{
        user_id: '',
        sites: [],
      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors }) => (
        <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
          <div style={{ minWidth: '500px', fontSize: '14px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.employee')}></LabelForm>
              </Col>
              <Col xs={9}>
                {values.user_id === null}
                <AppDropDownSelect
                  options={employeesUsers.map((option) => ({ label: option.name, value: option }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  menuStyle={{
                    position: 'fixed',
                    backgroundColor: '#FFFFFF',
                    width: 360,
                    marginTop: 8,
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
                    borderRadius: 16,
                  }}
                  isLoading={loading}
                  name="user_id"
                  customOption={CustomOption}
                  avatar={values.user_id ? calcAvatar(values.user_id) : null}
                  placeholder={t('required')}
                  icon={UserIcon}
                  height={230}
                  onChange={(selected) => {
                    if (selected) {
                      setFieldValue('user_id', selected.value);
                    } else {
                      setFieldValue('user_id', null);
                    }
                    //reset the sites selection
                    setFieldValue('sites', []);
                  }}
                  invalid={touched.user_id && errors.user_id}
                  defaultValue={values.user_id}
                />
                {touched.user_id && errors.user_id && (
                  <ErrorText className="px-2 mb-0">{errors.user_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.permitted_sites')}></LabelForm>
              </Col>
              <Col xs={9}>
                {id_organization ? (
                  <AppCheckBoxList
                    options={getSitesOptions(values.user_id)}
                    name="sites"
                    onClick={(selectedValues) => {
                      setFieldValue('sites', selectedValues);
                    }}
                    invalid={touched.sites && errors.sites}
                    defaultValue={values.sites.map((option) => ({
                      label: option.name,
                      subtitle: option.address,
                      id: option.id,
                      value: option,
                    }))}
                  />
                ) : (
                  <StyleItalicLabel>
                    {t('Organization.select_employee')}
                  </StyleItalicLabel>
                )}
                {touched.sites && errors.sites && (
                  <ErrorText className="px-2 mb-0">{errors.sites}</ErrorText>
                )}
              </Col>
            </Row>
          </div>
          <StyleButtons style={{ marginTop: 30 }}>
            <AppButton
              size="lg"
              type="submit"
              loading={loading}
              style={{
                width: 'auto',
                fontSize: '14px',
              }}
              color={PALETTE_COLOR.MAIN_WHITE}
              bgcolor={PALETTE_COLOR.MAIN_GREEN}
              bordercolor={PALETTE_COLOR.MAIN_GREEN}
              bghovercolor={PALETTE_COLOR.MAIN_GREEN}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            >
              {t('buttons.btn_confirm')}
            </AppButton>
            <div style={{ margin: '0.0rem 20px 0px' }}>
              <AppButton
                loading={loading}
                size="lg"
                style={{
                  width: 'auto',
                  fontSize: '14px',
                }}
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                color={PALETTE_COLOR.MAIN_BLUE}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                onClick={handleclose}
              >
                {t('buttons.btn_cancel')}
              </AppButton>
            </div>
            {showMessage.show && (
              <CustomizedSnackbar
                show={showMessage.show}
                type={showMessage.type}
                title={showMessage.title}
                message={showMessage.message}
              />
            )}
          </StyleButtons>
        </Form>
      )}
    </Formik>
  );
};
