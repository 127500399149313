import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@szhsin/react-menu';
import Button_Outline_No_Border from '@assets/icons/Button_Outline_No_Border.svg';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSiteWeekSchedulerModalOpenAction } from '@redux/actions/appActions';
import { SaveSiteWeekTemplate } from './SaveSiteWeekTemplate';

import { useTranslation } from 'react-i18next';

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Icon = styled.img``;

export const AditionalSiteActions = ({ currentdata, currentWeek, type, row }) => {
  const { t } = useTranslation();
  //Redux
  const dispatch = useDispatch();

  //In charge on show or hide save shift week as template modal
  const [openSaveShiftsWeekTemplate, setOpenSaveShiftsWeekTemplate] = useState(false);

  /***
   * Show or hide modal
   */
  const handleSaveSiteWeekAsTemplateModal = () => {
    setOpenSaveShiftsWeekTemplate(!openSaveShiftsWeekTemplate);
    //Do the dispatch for other modals on this page
    dispatch(setSiteWeekSchedulerModalOpenAction(!openSaveShiftsWeekTemplate));
  };

  return (
    <>
      <Menu
        align="end"
        direction="bottom"
        menuButton={
          <DropDownWrapper>
            <IconButton aria-label="edit">
              <Icon src={Button_Outline_No_Border} />
            </IconButton>
          </DropDownWrapper>
        }
        transition
      >
        <MenuItem
          onClick={() => handleSaveSiteWeekAsTemplateModal()}
        >{t('JobScheduler.menu.save_site_week_template')}</MenuItem>
        <MenuItem onClick={() => null}> {t('JobScheduler.menu.load_site_week_template')} </MenuItem>
        <MenuItem onClick={() => null}> {t('JobScheduler.menu.publish_site_shifts')} </MenuItem>
        <MenuItem onClick={() => null}> {t('JobScheduler.menu.clear_site_week')} </MenuItem>
      </Menu>

      {openSaveShiftsWeekTemplate && (
        <SaveSiteWeekTemplate
          currentdata={currentdata}
          currentWeek={currentWeek}
          type={type}
          row={row}
          handleSaveSiteWeekAsTemplateModal={handleSaveSiteWeekAsTemplateModal}
          openSaveShiftsWeekTemplate={openSaveShiftsWeekTemplate}
        />
      )}
    </>
  );
};
