import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { USER_LOCATIONS_API } from '@services/CONSTANTS';
import { getEntityData } from '@services';
import { randomColor } from 'randomcolor';

import cleaning_1 from '@assets/images/cleaning/cleaning-1.png';
import cleaning_2 from '@assets/images/cleaning/cleaning-2.png';
import cleaning_3 from '@assets/images/cleaning/cleaning-3.png';
import cleaning_4 from '@assets/images/cleaning/cleaning-4.png';
import cleaning_5 from '@assets/images/cleaning/cleaning-5.png';
import cleaning_6 from '@assets/images/cleaning/cleaning-6.png';
import cleaning_7 from '@assets/images/cleaning/cleaning-7.png';
import cleaning_8 from '@assets/images/cleaning/cleaning-8.png';
import cleaning_9 from '@assets/images/cleaning/cleaning-9.png';
import cleaning_10 from '@assets/images/cleaning/cleaning-10.png';
import cleaning_11 from '@assets/images/cleaning/cleaning-11.png';
import cleaning_12 from '@assets/images/cleaning/cleaning-12.png';


import i18next from 'i18n';
i18next.changeLanguage(i18next.resolvedLanguage);

const MovingMarkerMap = ({ center, apiKey, idUserShift, initial, height = 208, zoom=8 }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey
  });

  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(initial);
  const [markerImage, setMarkerImage] = useState(cleaning_1);
  const [markerColor, setMarkerColor] = useState(randomColor({
    luminosity: 'light',
    hue: 'blue'
  }));
  const [markerBorderColor, setMarkerBorderColor] = useState(randomColor({
    luminosity: 'dark',
    hue: 'blue'
  }));

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {

    const getRandomPosition = (min, max) => {
      const floatRandom = Math.random()
      const difference = max - min
      const random = Math.round(difference * floatRandom)
      const randomWithinRange = random + min
      return randomWithinRange
    };

    const getImage = () => {
      const imagesList = [
        cleaning_1, cleaning_2, cleaning_3, cleaning_4, cleaning_5,
        cleaning_6, cleaning_7, cleaning_8, cleaning_9, cleaning_10,
        cleaning_11, cleaning_12
      ];

      return imagesList[getRandomPosition(0, imagesList.length - 1)];

    };

    const getEmployeeLocation = async () => {
      try {
        const qb = RequestQueryBuilder.create()
          .setJoin({
            field: 'userShift',
          })
          .setFilter({ field: 'userShift.id', operator: CondOperator.EQUALS, value: idUserShift })
          .setLimit(1);
  
        getEntityData(`${USER_LOCATIONS_API}`, qb.query()).then((res) => {
          if (res && res.length > 0) {
            setMarkerColor(randomColor({
              luminosity: 'light',
              hue: 'blue'
            }));
            setMarkerBorderColor(randomColor({
              luminosity: 'dark',
              hue: 'blue'
            }));
            setMarkerImage(getImage());
            setMarkerPosition({ lat: parseFloat(res[0].currentLatitude), lng: parseFloat(res[0].currentLongitude) });
          }
        });
      } catch (error) {
        console.log('📌 - file: index.js:842 - getEmployeeLocation - error:', error);
      }
    };

    const interval = setInterval(() => {
      getEmployeeLocation();
    }, 5000);
    return () => clearInterval(interval);
  }, [idUserShift]);

  useEffect(() => {
    setMarkerPosition(initial);
  }, [initial]);
  
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: height
      }}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        // gestureHandling: 'none'
      }}
    >
      <Marker position={center} />
      <Marker 
        position={markerPosition}
        icon={{
          url: markerImage,
          scaledSize: new window.google.maps.Size(60, 60),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(30, 30)
        }}
        // icon={{
        //   path: window.google.maps.SymbolPath.CIRCLE,
        //   scale: 12,
        //   fillColor: 'red',
        //   strokeColor: 'black',
        //   fillOpacity: 0.5,
        //   strokeWeight: 2
        // }}
      />
    </GoogleMap>
  ) : <div>{i18next.t('loading')}`</div>;
}

export default React.memo(MovingMarkerMap);