import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppButton,
  InputForm,
  CustomizedSnackbar,
  AppDropDownSelect,
  AppDropDownCheckBoxSelect,
  AppAsyncLocationDropdown,
} from '@components';
import { saveEntityData, getEntityData } from '@services';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { USERS_API, VALUES_DEFINITIONS_API, GROUPS_API } from '@services/CONSTANTS';
import dollarCircleIcon from '@assets/icons/dollar_circle.svg';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  height: 80px;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

let requestCreateEmplyUser = {
  name: '',
  phone: '',
  email: '',
  pay_rate: '',
  home_address: '',
  status: {},
  type: {},
  request_jobs_titles: [],
  request_user_groups: [],
};

export const AddEmplyUserForm = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  const createEmplyUserSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
    phone: Yup.string().required(`${t('validation.phone_required')}`),
    email: Yup.string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    groups: Yup.array().nullable(),
    pay_rate: Yup.string()
      .required(`${t('validation.pay_rate_required')}`)
      .matches(/^\d{1,5}(\.\d{1,2})?$/, `${t('validation.pay_rate_invalid')}`),
    home_address: Yup.string().required(`${t('validation.home_address_required')}`),
    employee_id: Yup.string()
      .max(10, `${t('Users.employees.modal_add.validation.must_be_employee')}`)
      .required(`${t('validation.employee_id_required')}`),
    job_title_id: Yup.object()
      .nullable()
      .required(`${t('validation.job_title_required')}`),
    action: Yup.string(),
  });
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [loading, setLoading] = useState(false);
  //To handle message
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //Redux
  const { userPermissions } = useSelector((state) => state.app);

  /***
   *
   */
  const getJobTitles = () => {
    setLoadingJobTitles(true);
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'description', order: 'ASC' })
      .select(['definition_type_id', 'description'])
      .setFilter({
        field: 'definitionType.definition_type',
        operator: CondOperator.EQUALS,
        value: 'JOBS_TYPES',
      });
    getEntityData(VALUES_DEFINITIONS_API, qb.query())
      .then((res) => {
        console.log('AddEmplyUserForm > getJobTypes > res=', res);
        setJobTitlesList(res);
        setLoadingJobTitles(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoadingJobTitles(false);
      });
  };

  /***
   * Get groups to filter
   */
  useEffect(() => {
    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroupsList(
            userPermissions?.systemUserGroups?.filter((group) => group.permission === '020'),
          );
        } else {
          setGroupsList([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroupsList(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions]);

  /***
   *
   */
  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          console.log('Location aws results=', res);
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  useEffect(() => {
    getJobTitles();
    return () => {
      console.log('axios cleanup.');
    };
  }, []);

  const { valuesDefinitions } = useSelector((state) => state.app);
  const requestStatusCreate = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '030',
  )[0]; //Invited
  const requestStatusDraft = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '020',
  )[0]; //Draft
  const typeSystem = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_TYPES' && v.value_definition === '010',
  )[0]; //Employees

  requestCreateEmplyUser.type = typeSystem;

  const createEmplyUser = (values) => {
    setLoading(true);
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });

    const status =
      values.action === 'create'
        ? { ...requestStatusCreate }
        : values.action === 'draft' && { ...requestStatusDraft };
    console.log('values', values);
    const request_jobs_titles = [values.job_title_id.value];
    const request_user_groups = values?.groups.map((s) => ({
      group: s.value,
      group_permission: {
        value_definition_id: null,
      },
    }));
    requestCreateEmplyUser = {
      ...requestCreateEmplyUser,
      ...values,
      status,
      request_jobs_titles,
      request_user_groups,
    };
    console.log('requestCreateSysUser ', JSON.stringify(requestCreateEmplyUser));

    saveEntityData(USERS_API, requestCreateEmplyUser)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_created')}`,
          message: '',
        });
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        console.log('err ', JSON.stringify(err));
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          if (err.response?.data?.message === 'employeeID') {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.employee_id_exists')}`,
              message: '',
            });
          } else {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.user_exists')}`,
              message: '',
            });
          }
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_user_creating')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={createEmplyUserSchema}
      onSubmit={createEmplyUser}
      initialValues={{
        name: '',
        phone: '',
        email: '',
        job_title: '',
        pay_rate: '',
        home_address: '',
        employee_id: '',
        job_title_id: '',
        groups: [],
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px', fontSize: '14px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  maxLength={100}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.job_title')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={jobTitlesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="job_title_id"
                  placeholder={t('required')}
                  isLoading={loadingJobTitles}
                  onChange={(selected) => {
                    setFieldValue('job_title_id', selected);
                  }}
                  invalid={touched.job_title_id && errors.job_title_id}
                  defaultValue={values.job_title_id}
                />
                {touched.job_title_id && errors.job_title_id && (
                  <ErrorText className="px-2 mb-0">{errors.job_title_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.groups')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownCheckBoxSelect
                  isSearchable={true}
                  allowSelectAll={false}
                  placeholder={t('placeholder_search')}
                  height="7rem"
                  width={390}
                  options={groupsList.map((option) => ({ label: option.name, value: option }))}
                  defaultValue={values.groups}
                  onChange={(selected, event, selectedValues) => {
                    setFieldValue('groups', selectedValues);
                  }}
                  targetComponent={
                    <AppButton
                      loading={loading}
                      size="lg"
                      style={{
                        fontSize: 14,
                        width: 'auto',
                        display: 'unset',
                        flexDirection: 'unset',
                      }}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      hovercolor={PALETTE_COLOR.MAIN_BLUE}
                    >
                      {t('buttons.btn_add')}
                    </AppButton>
                  }
                />
                {touched.groups && errors.groups && (
                  <ErrorText className="px-2 mb-0">{errors.groups}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.pay_rate')}></LabelForm>
              </Col>
              <Col xs={5}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  icon={dollarCircleIcon}
                  iconpaddingright="unset"
                  placeholder={t('placeholder_zero_money')}
                  name="pay_rate"
                  value={values.pay_rate}
                  invalid={touched.pay_rate && errors.pay_rate}
                  onChange={handleChange}
                  maxLength="8"
                />
                {touched.pay_rate && errors.pay_rate && (
                  <ErrorText className="px-2 mb-0">{errors.pay_rate}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.address')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppAsyncLocationDropdown
                  loadOptions={(values) => loadLocationOptions(values)}
                  setLeftPadding
                  height="200px"
                  placeholder={t('required')}
                  isClearable={true}
                  name="home_address"
                  onChange={(selected) => {
                    setFieldValue('home_address', selected?.value);
                  }}
                />
                {touched.home_address && errors.home_address && (
                  <ErrorText className="px-2 mb-0">{errors.home_address}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.employee_id')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="employee_id"
                  value={values.employee_id}
                  invalid={touched.employee_id && errors.employee_id}
                  maxLength={10}
                  onChange={handleChange}
                />
                {touched.employee_id && errors.employee_id && (
                  <ErrorText className="px-2 mb-0">{errors.employee_id}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  onClick={() => {
                    setFieldValue('action', 'create');
                  }}
                  title={t('buttons.btn_create_invite')}
                >
                  {t('buttons.btn_create_invite')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={loading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={() => {
                    setFieldValue('action', 'draft');
                  }}
                  type="submit"
                  title={t('buttons.btn_save_as_draft')}
                >
                  {t('buttons.btn_save_as_draft')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={loading}
                  style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              {showErrorMessage.show && (
                <CustomizedSnackbar
                  show={showErrorMessage.show}
                  type={showErrorMessage.type}
                  title={showErrorMessage.title}
                  message={showErrorMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
