import close_square from '@assets/icons/close_square.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled as muiStyled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { calcAvatar } from 'utils/avatar';

const BootstrapDialog = muiStyled(Dialog)((props) => ({
  '& .MuiDialogContent-root': {
    padding: '5px',
  },
  '& .MuiDialogActions-root': {
    padding: props.theme.spacing(1),
  },
  '& .MuiPaper-root': {
    borderRadius: props.inside ? '16px' : props.isStacked ? '16px 16px 0px 0px !important' : '32px',
    overflowX: 'hidden',
    margin: props.isStacked && '5% 0px 0px 0px',
  },
}));

const DialogTitleWrapper = styled(DialogTitle)`
  background: #ffffff;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: ${(props) => (props.inside ? '400 !important' : '600 !important')};
  font-size: ${(props) => (props.inside ? '24px !important' : '20px !important')};
  line-height: 30px !important;
  color: #000000;
  text-align: ${(props) => (props.inside ? 'left' : 'center')};
  padding-left: ${(props) => (props.inside ? '30px !important' : '0px')};
  box-shadow: ${(props) =>
    props.boxshadow === 'none' ? 'none' : '0px 10px 20px rgba(0, 0, 0, 0.04)'};
  min-width: 180px;
  h3 {
    font-size: 14px !important;
  }
  p {
    color: ${PALETTE_COLOR.LIGHT_GREY};
    font-size: 12px !important;
    margin: 0;
  }
`;

const DialogActionsWrapper = styled.div`
  padding: 16px;
`;

const StyleDivTitle = styled.div`
  float: left;
  margin-right: 16px;
  margin-top: 6px;
`;

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const BootstrapDialogTitle = (props) => {
  const { children, onClose, isStacked, ...other } = props;

  return (
    <DialogTitleWrapper sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 10,
            display: isStacked && 'none',
          }}
        >
          <img src={close_square} alt="close_square" />
        </IconButton>
      ) : null}
    </DialogTitleWrapper>
  );
};

export const AppModal = (props) => {
  const {
    handleclose,
    open,
    title,
    children,
    actions,
    subtitle = null,
    icon = null,
    styleModal,
    testId,
  } = props;
  return (
    <BootstrapDialog
      onClose={handleclose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ style: { overflowY: 'visible', ...styleModal } }}
      scroll={'body'}
      maxWidth={'max-content'}
      data-testid={`${testId}_dialog`}
      {...props}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleclose}
        {...props}
        height={'auto'}
      >
        {icon ? <StyleDivTitle>{calcAvatar(icon, 40, 40)}</StyleDivTitle> : null}
        {subtitle ? (
          <SpaceEncName>
            <div>
              {subtitle ? <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>{subtitle}</p> : null}
              <h3 style={{ margin: '0px' }}>{title}</h3>
            </div>
          </SpaceEncName>
        ) : (
          title
        )}
      </BootstrapDialogTitle>
      <DialogContent
        dividers={false}
        style={{ borderTop: 'unset', borderBottom: 'unset', overflowY: 'visible' }}
      >
        {children}
      </DialogContent>
      {actions ? (
        <DialogActionsWrapper data-testId={`${testId}_modal_actions`}>
          {actions}
        </DialogActionsWrapper>
      ) : null}
    </BootstrapDialog>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
