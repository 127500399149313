import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { IcArrow } from '@components';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

export function DatePickerByWeek ({ calendarConfig, setCalendarConfig }) {
  const { t } = useTranslation();

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <PaginationTopButton>
        <IconButton
          aria-label={t('form_labels.job_scheduler')}
          style={{ marginLeft: 2, marginRight: 2 }}
          onClick={(e) => {
            const prevStart = moment
              .utc(calendarConfig.currentWeek.start)
              .add(-7, 'day');
            const prevEnd = moment
              .utc(calendarConfig.currentWeek.start)
              .add(-1, 'day');
            setCalendarConfig({
              ...calendarConfig,
              currentWeek: { start: prevStart, end: prevEnd },
            });
          }}
        >
          <IcArrow
            style={{ height: 10, width: 5 }}
            direction="left"
            status={'enabled'}
          />
        </IconButton>
        <div style={{ color: '#1179BF', marginRight: 5 }}>
          {`${capitalize(calendarConfig.currentWeek.start.format('MMM')).replace('.', '')} ${calendarConfig.currentWeek.start.format('DD')} - 
            ${capitalize(calendarConfig.currentWeek.end.format('MMM')).replace('.', '')} ${calendarConfig.currentWeek.end.format('DD')}`}
        </div>
        <IconButton
          aria-label={t('form_labels.job_scheduler')}
          onClick={(e) => {
            const nextStart = moment
              .utc(calendarConfig.currentWeek.end)
              .add(1, 'day');
            const nextEnd = moment.utc(calendarConfig.currentWeek.end).add(7, 'day');
            setCalendarConfig({
              ...calendarConfig,
              currentWeek: { start: nextStart, end: nextEnd },
            });
          }}
        >
          <IcArrow
            style={{ height: 10, width: 5 }}
            direction={'right'}
            status={'enabled'}
          />
        </IconButton>
      </PaginationTopButton>
    </>
  );
};
