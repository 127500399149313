import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { saveEntityData } from '@services';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { VALUES_DEFINITIONS_API } from 'services/CONSTANTS';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const requestAddJobTitle = {
  description: '',
  definitionType: {
    definition_type: 'JOBS_TYPES',
  },
};

export const AddJobTitle = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  const addJobTitleSchema = Yup.object().shape({
    name: Yup.string().max(20, `${t('Groups.add_job_title.validation.must_be_name')}`).required(`${t('validation.name_required')}`),
  });
  //To handle message
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [loading, setLoading] = useState(false);
  /***
   * Calling to WS to add a Job Title
   */
  const addJobTitle = (values) => {
    //Hide message
    setLoading(true);
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    //Hide modal for GroupList view - Job Title section
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Remove white spaces
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    nameWithoutSpaces = nameWithoutSpaces.trim();
    values.name = nameWithoutSpaces;
    requestAddJobTitle.description = nameWithoutSpaces;
    //Add Job Title
    saveEntityData(VALUES_DEFINITIONS_API, requestAddJobTitle)
      .then((res) => {
        //Show message on GroupList view - Job Title section
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.job_title_created')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          //Show message
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.job_title_exists')}`,
            message: '',
          });
        } else {
          //Show message
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_job_title_creating')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={addJobTitleSchema}
      onSubmit={addJobTitle}
      initialValues={{
        name: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <div style={{ width: '590px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
            <Row>
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                ></InputForm>
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <AppButton
                style={{ marginRight: '20px', fontSize: '14px' }}
                size="lg"
                type="submit"
                loading={loading}
                bgcolor={PALETTE_COLOR.MAIN_GREEN}
                bghovercolor={PALETTE_COLOR.MAIN_GREEN}
              >
                {t('buttons.btn_confirm')}
              </AppButton>
              <AppButton
                style={{ fontSize: '14px' }}
                loading={loading}
                size="lg"
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                color={PALETTE_COLOR.MAIN_BLUE}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                onClick={handleclose}
              >
                {t('buttons.btn_cancel')}
              </AppButton>
              {showMessage.show && (
                <CustomizedSnackbar
                  show={showMessage.show}
                  type={showMessage.type}
                  title={showMessage.title}
                  message={showMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
