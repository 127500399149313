import React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';

const BootstrapDialog = muiStyled(Popover)((props) => ({
  '& .MuiDialogContent-root': {
    padding: '5px',
  },
  '& .MuiDialogActions-root': {
    padding: props.theme.spacing(1),
  },
  '& .MuiPaper-root': {
    borderRadius: props.inside ? '16px' : props.isStacked ? '16px 16px 0px 0px !important' : '32px',
    overflowX: 'hidden',
    margin: props.isStacked && '5% 0px 0px 0px',
  },
}));

export const AppModalNotes = (props) => {
  const { handleclose, open, children, position } = props;
  return (
    <BootstrapDialog
      onClose={handleclose}
      open={open}
      maxWidth={'max-content'}
      anchorEl={position}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      {children}
    </BootstrapDialog>
  );
};
