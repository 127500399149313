import Vector from '@assets/icons/Vector.svg';
import EncList from '@components/EncList';
import { DASHBOARD, ORGANIZATIONS_MANAGEMENT, VIEW_ORGANIZATIONS } from '@navigation/CONSTANTS';
import { OrganizationList } from '@pages/Organizations/OrganizationList';
import { SitesList } from '@pages/Sites';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

export const OrganizationManagement = () => {
  const { t } = useTranslation();
  let history = useHistory();
  //Get type from url
  let { type } = useParams();
  //Control if it is org or sites table
  const [teamType, setTeamType] = useState(type ? type : 'organizations');
  //Get logged user info.
  const { userData } = useSelector((state) => state.app);

  /***
   * If it is a SM, not show normal pages, redirect to Org detail
   */
  React.useEffect(() => {
    if (userData?.isSiteManager) {
      if (userData?.organizations && userData?.organizations.length > 0) {
        history.push(
          VIEW_ORGANIZATIONS.replace(':organizationId', `${userData?.organizations[0].id}`),
        );
      } else {
        //Site manager without Org
        history.push(DASHBOARD);
      }
    }
  }, [userData, history]);

  /***
   * Add to history the current url
   */
  const handleChangeTypeURL = (type) => {
    if (type === 'organizations') {
      history.push(ORGANIZATIONS_MANAGEMENT.replace(':type', 'organizations'));
    } else if (type === 'sites') {
      history.push(ORGANIZATIONS_MANAGEMENT.replace(':type', 'sites'));
    } else {
      history.push(ORGANIZATIONS_MANAGEMENT).replace(':type', 'organizations');
    }
  };

  /***
   * To know which type is and what button activate
   */
  const getActiveTabIndex = (type) => {
    if (type === 'organizations') {
      return 0;
    } else if (type === 'sites') {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <EncList
        title={t('Organization.title')}
        icon={Vector}
        testId={'organizationList'}
        indexActive={getActiveTabIndex(teamType)}
        options={[
          {
            label: `${t('Organization.header_buttons.organizations')}`,
            onClick: () => {
              setTeamType('organizations');
              handleChangeTypeURL('organizations');
            },
          },
          {
            label: `${t('Organization.header_buttons.sites')}`,
            onClick: () => {
              setTeamType('sites');
              handleChangeTypeURL('sites');
            },
          },
        ]}
      ></EncList>
      {teamType === 'organizations' && <OrganizationList />}
      {teamType === 'sites' && <SitesList />}
    </div>
  );
};
