import passIcon from '@assets/icons/password24x24.svg';
import profileIcon from '@assets/icons/profilesquare24x24.svg';
import { AppModal } from '@components/AppModal';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useAuth } from '@navigation/Auth/ProvideAuth';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { getEntityData } from '@services';
import { getCurrentSession, signIn } from '@services/CognitoAuthService';
import { USERS_API } from '@services/CONSTANTS';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { AppButton } from 'components';
import { InputForm } from 'components/InputForm';

import { ForgotPassForm } from './ForgotPassForm';
import { ValidationTooltip } from './ValidationTooltip';

const ForgotAction = styled(Button)`
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  :hover,
  :focus {
    border: 1px solid rgba(131, 180, 49, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

const SingUpAction = styled(Button)`
  font-size: 14px;
  text-align: center;
  position: relative;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 20px;
  :hover,
  :focus {
    border: 1px solid rgba(109, 115, 123, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MIDDLE_GRAY};
    box-shadow: unset;
  }
`;

export const LoginForm = ({ setloginInfo, onOpen }) => {
  const { t } = useTranslation();
  const loginSchema = yup.object().shape({
    username: yup
      .string()
      .required(`${t('validation.email_required')}`)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        `${t('validation.email_invalid')}`,
      ),
    password: yup.string().required(`${t('validation.password_required')}`),
  });

  let auth = useAuth();
  let history = useHistory();
  let location = useLocation();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState();
  const [openSignUp, setOpenSignUp] = useState(false);

  const handleClickOpenSignUpModal = () => {
    setOpenSignUp(true);
  };

  const handleCloseSignUpModal = () => {
    setOpenSignUp(false);
  };

  const login = async (values) => {
    let { from } = { from: { pathname: '/' } };
    //loading(true);
    setShow(false);
    let loginResponse = null;
    try {
      loginResponse = await signIn(values.username, values.password);
      const { isFirstLogin } = loginResponse;
      if (isFirstLogin) {
        setloginInfo((pre) => {
          return {
            ...pre,
            isFirstLogin,
            user: loginResponse.user,
            userAttributes: loginResponse.userAttributes,
          };
        });
      } else {
        if (loginResponse.result.accessToken.payload['cognito:groups'].includes('employees')) {
          setShow(true);
          setMessage(`${t('validation.invalid_access')}`);
          localStorage.clear();
        } else {
          const user = await checkUser();
          if (user.status.description === 'Active') {
            auth.signin(false).then((res) => {
              history.replace(from);
            });
          } else {
            setShow(true);
            setMessage(`${t('validation.user_not_enabled')}`);
            localStorage.clear();
          }
        }
      }
    } catch (error) {
      console.log('login error:', error);
      setShow(true);
      setMessage(`${t('validation.user_password_incorrect')}`);
    }
  };

  const isValidFormik = () => {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  };

  const checkUser = async () => {
    //Get info for employee logged in and store it in redux
    let info = await getCurrentSession();
    const qb = RequestQueryBuilder.create()
      .setFilter({
        field: 'sub_cognito_id',
        operator: CondOperator.EQUALS,
        value: info.idToken.payload.sub,
      });
    //Call api to get user info
    return getEntityData(USERS_API, qb.query())
      .then((res) => {
        return res[0];
      })
      .catch(err => {
        console.log('📌 - file: LoginForm.js:154 - checkUser - err:', err);
        return null;
      });
      //End
  };
  
  return (
    <>
      <Formik
        validationSchema={loginSchema}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => {
          login(values);
          setSubmitting(false);
        }}
        initialValues={{
          username: '',
          password: '',
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Container className="baseForm px-5 pt-3 pb-2">
              {visible && !isValid && <ValidationTooltip msg={t('validation.all_required')} />}

              {show && isValid && <ValidationTooltip msg={message} />}

              <Row>
                <Col className="mt-3" xs={12} xl={12}>
                  <Form.Group controlId="baseForm">
                    <InputForm
                      height={'64px'}
                      placeholder={t('Login.login.user')}
                      icon={profileIcon}
                      name="username"
                      value={values.username}
                      invalid={touched.username && errors.username}
                      onChange={handleChange}
                      testId="username"
                    ></InputForm>
                    <Form.Control.Feedback type="invalid">
                      {errors.username} ++
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Group>
                    <InputForm
                      height={'64px'}
                      ctype="password"
                      placeholder={t('Login.login.password')}
                      icon={passIcon}
                      name="password"
                      value={values.password}
                      invalid={touched.password && errors.password}
                      onChange={handleChange}
                      testId="password"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>

            <Stack className="justify-content-center align-items-center" gap={8}>
              <AppButton
                style={{ width: 'auto', height: 64, paddingLeft: 20, paddingRight: 20 }}
                type="submit"
                onClick={() => isValidFormik()}
                bgcolor={PALETTE_COLOR.MAIN_BLUE}
                bordercolor={PALETTE_COLOR.MAIN_BLUE}
                bghovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
                borderhovercolor={PALETTE_COLOR.MAIN_BLUE_HOVER}
                testId="login"
              >
                {t('buttons.btn_login')}
              </AppButton>

              <ForgotAction variant="link" onClick={handleClickOpenSignUpModal}>
                {t('Login.login.forgot')}
              </ForgotAction>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <SingUpAction variant="link" onClick={onOpen}>
                  {t('Login.login.where')}
                </SingUpAction>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>

      <AppModal
        open={openSignUp}
        handleclose={handleCloseSignUpModal}
        title={t('Login.forgot.title')}
      >
        <ForgotPassForm
          handleCloseSignUpModal={handleCloseSignUpModal}
          setloginInfo={setloginInfo}
        />
      </AppModal>
    </>
  );
};
