import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { PALETTE_COLOR } from '@config/CONSTANTS';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    paddingLeft: 16,
    paddingRight: 16,
    border: 0,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    height: 32,
    color: PALETTE_COLOR.MAIN_BLUE,
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      backgroundColor: PALETTE_COLOR.MAIN_BLUE,
      color: PALETTE_COLOR.MAIN_WHITE,
    },
    '&:not(:first-of-type)': {
      textTransform: 'capitalize',
      borderRadius: 16,
      height: 32,
    },
    '&:first-of-type': {
      textTransform: 'capitalize',
      borderRadius: 16,
      height: 32,
    },
  },
}));

export const AppSwitchSelector = (props) => {
  const { value, width = 168, options, ...other } = props;

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${PALETTE_COLOR.FIELD_BORDER_COLOR}`,
          flexWrap: 'wrap',
          width: width,
          borderRadius: 16,
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          aria-label="text alignment"
          {...other}
        >
          {options &&
            options.map((item, index) => {
              return (
                <ToggleButton key={index} value={item.value} aria-label="left aligned">
                  {item.label}
                </ToggleButton>
              );
            })}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};
