import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import ic_line from '@assets/icons/ic_line.svg';
import ic_check from '@assets/icons/ic_check.svg';
import ic_check_locked from '@assets/icons/ic_check_locked.svg';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 8,
  width: 22,
  height: 22,
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#E7ECF4',
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    display: 'block',
    width: 22,
    height: 22,
    backgroundImage: `url(${ic_check_locked})`,
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: MAIN_BLUE,
  boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 22,
    height: 22,
    backgroundImage: `url(${ic_check})`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4274ed',
  },
});

const BpIndetermiedIcon = styled(BpIcon)({
  backgroundColor: MAIN_BLUE,
  boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 22,
    height: 22,
    backgroundImage: `url(${ic_line})`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4274ed',
  },
});

// Inspired by blueprintjs
export function AppCheckBox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={props.disabled ? <BpIcon /> : <BpCheckedIcon />}
      icon={props.indeterminated ? <BpIndetermiedIcon /> : <BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}
