import React, { useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { AppButton } from 'components';
import { SITES_GROUPS_API } from 'services/CONSTANTS';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { deleteEntityData } from '@services';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';

import { useTranslation } from 'react-i18next';

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleSpaceLabel = styled.div`
  display: flex;
  justify-content: center;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 10px 10px 10px 10px;
`;

const StyleSpaceName = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ff5555;
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalText = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  padding: 5px 5px 5px 5px;
  text-align: center;
`;

const InputIcon = styled.img``;

const ModalContentWrapper = styled.div`
  padding: 5px 35px 34px 35px;
`;

export const DeleteSiteGroup = ({ handleCloseDeleteSiteGroupModal, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const siteGroup = data;
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [loading, setLoading] = useState(false);
  const DeleteSiteGroup = () => {
    setLoading(true);
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    deleteEntityData(`${SITES_GROUPS_API}${siteGroup.id}`)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.site_deleted')}`,
          message: '',
        });
        handleCloseDeleteSiteGroupModal();
        setLoading(false);
      })
      .catch((err) => {
        setShowMessage({ show: true, type: 'error', title: `${t('error_messages.error_site_deleting')}`, message: '' });
        setLoading(false);
      });
  };

  return (
    <div style={{ width: '600px' }}>
      <ModalContentWrapper>
        <StyleSpaceIcon>
          <InputIcon src={ico_Archived} />
        </StyleSpaceIcon>
        <StyleSpaceLabel>{t('Groups.modals.remove_assigned_site_title')}</StyleSpaceLabel>
        <StyleSpaceName>
          {siteGroup?.site.name} ({siteGroup?.site.address})
        </StyleSpaceName>
        <ModalText className="mb-0">{t('Groups.modals.remove_assigned_site_subtitle')}</ModalText>
        <StyleSpaceButtons>
          <Formik
            onSubmit={DeleteSiteGroup}
            initialValues={{
              email: '',
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
              <Form noValidate onSubmit={handleSubmit} className="baseForm">
                <AppButton
                  style={{ width: 'auto', height: 64, marginTop: '20px' }}
                  type="submit"
                  loading={loading}
                  bgcolor="#FF5555"
                  bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                  borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                  bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                >
                  {t('buttons.btn_remove')}
                </AppButton>
                {showMessage.show && (
                  <CustomizedSnackbar
                    show={showMessage.show}
                    type={showMessage.type}
                    title={showMessage.title}
                    message={showMessage.message}
                  />
                )}
              </Form>
            )}
          </Formik>
        </StyleSpaceButtons>
      </ModalContentWrapper>
    </div>
  );
};
