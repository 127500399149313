import React from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { AppButton } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { getCroppedImg } from '../utils/canvasUtils';
import { useDispatch } from 'react-redux';
import { setProfilePhotoToSaveAction } from '@redux/actions/appActions';

import { useTranslation } from 'react-i18next';

const CropContainer = styled.div`
  position: absolute;
  bottom: 20%;
  left: 30%;
  right: 20%;
  top: 25%;
  height: 40%;
  width: 40%;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 25%;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  justify-content: center;
`;

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  position: absolute;
  bottom: 5%;
  width: 100%;
`;

export const CropProfileImage = (props) => {
  const { t } = useTranslation();
  const { imageSrc, handleChangePhotoModal } = props;
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedImage, setCroppedImage] = React.useState(null);
  const dispatch = useDispatch();

  /**
   *
   */
  const onCropComplete = React.useCallback(
    async (croppedArea, croppedAreaPixels) => {
      console.log(croppedArea, croppedAreaPixels);
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc],
  );

  /***
   *
   */
  const onCreate = () => {
    //Store in redux to be sent to WS on "Save" button
    dispatch(setProfilePhotoToSaveAction({ isAvatar: false, config: croppedImage }));
    handleChangePhotoModal();
  };

  return (
    <div style={{ height: 400, width: 850 }}>
      <div>
        <CropContainer>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CropContainer>
        <Controls>
          <input
            type="range"
            style={{ background: '#3f51b5' }}
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
        </Controls>
        <StyleButtons style={{ marginLeft: 40, marginRight: 40 }}>
          <span>
            <AppButton
              style={{
                fontSize: 14,
                width: 'auto',
                marginRight: 16,
                display: 'unset',
                flexDirection: 'unset',
              }}
              color={PALETTE_COLOR.MAIN_WHITE}
              bgcolor={PALETTE_COLOR.MAIN_GREEN}
              bordercolor={PALETTE_COLOR.MAIN_GREEN}
              bghovercolor={PALETTE_COLOR.MAIN_GREEN}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              size="lg"
              type="submit"
              title={t('buttons.btn_accept')}
              onClick={onCreate}
            >
              {t('buttons.btn_accept')}
            </AppButton>
            <AppButton
              size="lg"
              style={{
                fontSize: 14,
                width: 'auto',
                display: 'unset',
                flexDirection: 'unset',
              }}
              color={PALETTE_COLOR.MAIN_BLUE}
              bgcolor={PALETTE_COLOR.MAIN_WHITE}
              bordercolor={PALETTE_COLOR.TOP_GRAY}
              bghovercolor={PALETTE_COLOR.MAIN_BLUE}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              title={t('buttons.btn_cancel')}
              onClick={handleChangePhotoModal}
            >
              {t('buttons.btn_cancel')}
            </AppButton>
          </span>
        </StyleButtons>
      </div>
    </div>
  );
};
