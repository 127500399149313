import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import { AppButton, InputForm, LabelForm } from '@components';
import { editEntityData } from '@services';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import DPICon from '@assets/icons/ic_datepicker.svg';
import { setReloadTimeSheetAction } from '@redux/actions/appActions';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { TIME_SHEET_API } from 'services/CONSTANTS';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { CalcDuration, ShiftFullTimeDifferencesHhMm } from 'utils/appUtils';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageAction } from '@redux/actions/appActions';
import ic_status_signed from '@assets/icons/ic_status_signed.svg';
import ic_status_unsigned from '@assets/icons/ic_status_unsigned.svg';
import ic_status_no_time from '@assets/icons/ic_status_no_time.svg';

import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const DatePickerInput = styled(TextField)`
border: 1px solid #E7ECF4;
border-radius: 16px;
& label.Mui-focused {
  color: transparent;
 
}
& label {
  
  color: transparent
 
}
& .MuiInput-underline:after {
  border-bottom-color: white;
  
}
& .MuiOutlinedInput-root {
  & fieldset {
    border: 1px solid #E7ECF4;
    border-radius: 16px;
    
  }
  &:hover fieldset {
    border-color: #E7ECF4;
  }
  &.Mui-focused fieldset {
    border: 1px solid blue;
  }
}
&.MuiInputBase-root{
  padding: 0px;
  background-color: green;
  & .MuiButtonBase-root{
    padding: 0,
        padding-left: 10;
        background-color: gold;
  }
  & .MuiInputBase-input{
    padding: 15;
        padding-left: 0;
        background-color: cyan;
  }
}
`;

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const HoursDiff = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const TimeRangeInput = styled(TextField)`
  border: 1px solid #E7ECF4;
  border-radius: 16px;
  & label.Mui-focused {
    display: none;
  }
  & label {
    display: none;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #E7ECF4;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #E7ECF4;
    }
    &.Mui-focused fieldset {
      border: 1px solid blue;
    }
  }
  &.MuiInputBase-root{
    padding: 0px;
    background-color: green;
    & .MuiButtonBase-root{
      padding: 0,
          padding-left: 10;
          background-color: gold;
    }
    & .MuiInputBase-input{
      padding: 8;
      padding-left: 0;
      background-color: cyan;
    }
  }
`;

const TimeSheetColLabel = styled.div`
  font-weight: 300;
  font-size: 14px;
`;

const Status = (props) => {
  const { t } = useTranslation();
  const { userShift, endDate } = props;
  if (moment(endDate).isValid()) {
    if (userShift.signer) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <img src={ic_status_signed} alt={`ic_status_signed`} />
          <TimeSheetColLabel>
            {`${t('form_labels.signed')} ${new Date(userShift.sign_time).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })} ${t('at')} ${new Date(userShift.sign_time).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })} ${t('by_low')} `}
            <span style={{ color: PALETTE_COLOR.MAIN_BLUE }}>{`${userShift.signer.name}`}</span>
          </TimeSheetColLabel>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <img src={ic_status_unsigned} alt={`ic_status_unsigned`} />
          <TimeSheetColLabel>{`${t('form_labels.unsigned').substring(0,7)}...`}</TimeSheetColLabel>
        </div>
      );
    }
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <img src={ic_status_no_time} alt={`ic_status_no_time`} />
        <TimeSheetColLabel>{t('no_time')}</TimeSheetColLabel>
      </div>
    );
  }
};

export const TimeSheetForm = ({ handleclose, data }) => {
  const { t } = useTranslation();
  //console.log('moment.utc(data.clockInDate): ', moment.utc(data.clockInDate));
  const { id, endDate } = data;
  //To handle remove modal
  const [loading, setLoading] = useState(false);
  //Handle new clock lib
  const [clockInF, setClockInF] = useState(moment.utc(data.clockInDate));
  const [clockOutF, setClockOutF] = useState(moment.utc(data.clockOutDate));
  //console.log('🦖 ~ clockInF', clockInF);
  //console.log('🦖 ~ clockOutF', clockOutF);
  const dispatch = useDispatch();

  //Get logged user info
  const { userData } = useSelector((state) => state.app);

  const editTimeSheetSchema = Yup.object().shape({
    shiftDate: Yup.date().nullable().required(`${t('validation.start_date_required')}`),
    endDate: moment(endDate).isValid()
      ? Yup.date().nullable().required(`${t('validation.end_date_required')}`)
      : Yup.string().nullable(),
    breakDuration: moment(endDate).isValid()
      ? Yup.number().required(`${t('validation.break_time_required')}`)
      : Yup.number().nullable(),
  });

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );
  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadTimeSheetAction(isLoading));
    },
    [dispatch],
  );
  /***
   * Calling to WS to edit timeSheet
   */
  const editTimeSheet = (values) => {
    let formattedShiftDate = moment(values.shiftDate)
      .utcOffset(0)
      .set({
        hour: clockInF.get('hour'),
        minute: clockInF.get('minute'),
        second: 0,
        dayOfYear: values.shiftDate.get('dayOfYear'),
        month: values.shiftDate.get('month'),
        year: values.shiftDate.get('year'),
      })
      .format();
    let formattedEndShiftDate = moment(values.endDate)
      .utcOffset(0)
      .set({
        hour: clockOutF.get('hour'),
        minute: clockOutF.get('minute'),
        second: 0,
        dayOfYear: values.endDate.get('dayOfYear'),
        month: values.endDate.get('month'),
        year: values.endDate.get('year'),
      })
      .format();
    let formattedFStartTime = moment(values.shiftDate)
      .utcOffset(0)
      .set({
        hour: clockInF.get('hour'),
        minute: clockInF.get('minute'),
        second: 0,
      })
      .format();
    let formattedFEndTime = moment(values.endDate)
      .utcOffset(0)
      .set({
        hour: clockOutF.get('hour'),
        minute: clockOutF.get('minute'),
        second: 0,
      });
    //Hide message
    setLoading(true);
    editEntityData(`${TIME_SHEET_API}${id}`, {
      ...values,
      clockInDate: formattedFStartTime,
      clockOutDate: moment(endDate).isValid() ? formattedFEndTime : null,
      shiftDate: formattedShiftDate,
      endDate: moment(endDate).isValid() ? formattedEndShiftDate : null,
    })
      .then((res) => {
        //Close form modal
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.timesheet_edited')}`,
          message: '',
        });
        reloadSchedulerCalendar(true);
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        reloadSchedulerCalendar(true);
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_timesheet_editing')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={editTimeSheetSchema}
      onSubmit={editTimeSheet}
      initialValues={{
        ...data,
        shiftDate: moment.utc(data.shiftDate),
        endDate: moment.utc(data.endDate),
        clockInDate: moment.utc(data.clockInDate).format('HH:mm'),
        clockOutDate: moment.utc(data.clockOutDate).format('HH:mm'),
        title: data.userShift?.shift?.title,
        note: data.userShift?.shift?.note,
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <div style={{ width: '620px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.start_date')}></LabelForm>
              </Col>
              <Col xs={6} style={{ fontSize: '14px' }}>
                <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage} style={{ fontSize: '14px' }}>
                  <DatePicker
                    style={{ fontSize: '14px' }}
                    label
                    value={values.shiftDate}
                    inputFormat="LL"
                    onChange={(newValue) => {
                      setFieldValue('shiftDate', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false }}
                        hiddenLabel
                        size="small"
                        {...params}
                      />
                    )}
                    disabled={!moment(endDate).isValid()}
                  />
                </LocalizationProvider>
                {touched.shiftDate && errors.shiftDate && (
                  <ErrorText className="px-2 mb-0">{errors.shiftDate}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.end_date')}></LabelForm>
              </Col>
              <Col xs={6} style={{ fontSize: '14px' }}>
                <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage} style={{ fontSize: '14px' }}>
                  <DatePicker
                    style={{ fontSize: '14px' }}
                    label
                    value={values.endDate}
                    inputFormat="LL"
                    onChange={(newValue) => {
                      setFieldValue('endDate', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false }}
                        hiddenLabel
                        size="small"
                        {...params}
                      />
                    )}
                    disabled={!moment(endDate).isValid()}
                  />
                </LocalizationProvider>
                {touched.endDate && errors.endDate && (
                  <ErrorText className="px-2 mb-0">{errors.endDate}</ErrorText>
                )}
              </Col>
            </Row>
            {/* <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.clock_in_out')}></LabelForm>
              </Col>
              <Col xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage}>
                  <StyledTimePicker
                    label
                    style={{ fontSize: '14px' }}
                    InputProps={{
                      classes: { root: classes.root },
                    }}
                    value={values.clockInDate}
                    onChange={(newValue) => {
                      setFieldValue('clockInDate', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={TPIcon} alt="clock" className="p0" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false, fontSize: 12 }}
                        //hiddenLabel
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {touched.clockInDate && errors.clockInDate && (
                  <ErrorText className="px-2 mb-0">{errors.clockInDate}</ErrorText>
                )}
              </Col>

              <Col xs={1} style={{ textAlign: 'center', padding: '0', width: '4%' }}>
                <LabelForm tittle="-"></LabelForm>
              </Col>

              <Col xs={{ span: 3 }}>
                <LocalizationProvider dateAdapter={DateAdapter} locale={i18next.resolvedLanguage}>
                  <TimePicker
                    label
                    style={{ fontSize: '14px' }}
                    InputProps={{
                      classes: { root: classes.root },
                    }}
                    value={values.clockOutDate}
                    onChange={(newValue) => {
                      setFieldValue('clockOutDate', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={TPIcon} alt="clock" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false }}
                        hiddenLabel
                        size="small"
                        {...params}
                      />
                    )}
                    disabled={!moment(endDate).isValid()}
                  />
                </LocalizationProvider>
                {touched.clockOutDate && errors.clockOutDate && (
                  <ErrorText className="px-2 mb-0">{errors.clockOutDate}</ErrorText>
                )}
              </Col>
              <Col xs={2} className="px-0 mt-2">
                {moment(endDate).isValid() ? (
                  <HoursDiff>
                    {ShiftFullTimeDifferencesHhMm({
                      shift_date: values.shiftDate,
                      start_time: values.clockInDate,
                      end_time: `${moment(values.endDate).format('YYYY-MM-DD')}T${moment(
                        values.clockOutDate,
                      ).format('HH:mm:ss')}.000Z`,
                    })}
                    {` ${t('form_labels.total')}`}
                  </HoursDiff>
                ) : (
                  <></>
                )}
              </Col>
            </Row> */}
            {/* NEW TIMEPICKER */}
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.clock_in_out')}></LabelForm>
              </Col>
              <Col xs={3}>
                <TimeRangeInput
                  id="time"
                  label="."
                  type="time"
                  value={values.clockInDate}
                  onChange={(event, newValue) => {
                    //New way: Add ClockIn
                    const hours = event.target.value.split(':');
                    const dateTime = moment(values.shiftDate).set({
                      hour: hours[0],
                      minute: hours[1],
                      second: 0,
                      millisecond: 0,
                    });
                    setFieldValue('clockInDate', event.target.value);
                    setClockInF(dateTime);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    fontSize: 1,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  InputProps={{
                    style: {
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      color: '#000000',
                      padding: 0,
                    },
                  }}
                  sx={{
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter:
                        'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                    },
                  }}
                />
                {touched.clockInDate && errors.clockInDate && (
                  <ErrorText className="px-2 mb-0">{errors.clockInDate}</ErrorText>
                )}
              </Col>

              <Col xs={1} style={{ textAlign: 'center', padding: '0', width: '6%', marginLeft: 5 }}>
                <LabelForm tittle="-"></LabelForm>
              </Col>

              <Col xs={3}>
                <TimeRangeInput
                  id="time"
                  label="."
                  type="time"
                  value={values.clockOutDate}
                  disabled={!moment(endDate).isValid()}
                  onChange={(event, newValue) => {
                    //New way: Add Shift date
                    const hours = event.target.value.split(':');
                    const dateTime = moment(values.endDate).set({
                      hour: hours[0],
                      minute: hours[1],
                      second: 0,
                      millisecond: 0,
                    });
                    setFieldValue('clockOutDate', event.target.value);
                    setClockOutF(dateTime);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    fontSize: 1,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  InputProps={{
                    style: {
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      fontSize: '14px',
                      color: '#000000',
                      padding: 0,
                    },
                  }}
                  sx={{
                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                      filter:
                        'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                    },
                  }}
                />
                {touched.clockOutDate && errors.clockOutDate && (
                  <ErrorText className="px-2 mb-0">{errors.clockOutDate}</ErrorText>
                )}
              </Col>
              <Col
                xs={2}
                className="px-0 mt-2"
                style={{ textAlign: 'center', padding: '0', width: '18%' }}
              >
                {moment(endDate).isValid() ? (
                  <HoursDiff>
                    {ShiftFullTimeDifferencesHhMm({
                      shift_date: values.shiftDate,
                      //start_time: values.clockInDate,
                      start_time: `${moment(values.shiftDate).format('YYYY-MM-DD')}T${moment(
                        clockInF,
                      ).format('HH:mm:ss')}.000Z`,
                      end_time: `${moment(values.endDate).format('YYYY-MM-DD')}T${moment(
                        clockOutF,
                      ).format('HH:mm:ss')}.000Z`,
                    })}
                    {`h ${t('form_labels.total')}`}
                  </HoursDiff>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {/* END NEW TIMEPICKER */}
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.break')}></LabelForm>
              </Col>
              <Col xs={3}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('optional')}
                  name="breakDuration"
                  value={values.breakDuration}
                  invalid={touched.breakDuration && errors.breakDuration}
                  onChange={handleChange}
                  disabled={!moment(endDate).isValid()}
                ></InputForm>
                {touched.breakDuration && errors.breakDuration && (
                  <ErrorText className="px-2 mb-0">{errors.breakDuration}</ErrorText>
                )}
              </Col>
              <Col xs={6} className="px-0 mt-2">
                <HoursDiff>
                  {' '}
                  {`${CalcDuration(values.regularDuration)} ${t('form_labels.regular_hours')} / ${CalcDuration(
                    values.overtimeDuration,
                  )} ${t('form_labels.overtime')}`}
                </HoursDiff>
              </Col>
            </Row>
            <StyleButtons />
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.shift')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  height={'40px'}
                  inpfontsize={'14px'}
                  placeholder={t('optional')}
                  disabled={true}
                  name="title"
                  value={values.title}
                  invalid={touched.title && errors.title}
                  onChange={handleChange}
                ></InputForm>
                {touched.title && errors.title && (
                  <ErrorText className="px-2 mb-0">{errors.title}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.note')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  placeholder={t('optional')}
                  style={{ width: '100%', resize: 'none' }}
                  inpfontsize={'14px'}
                  name="note"
                  ctype="textarea"
                  rows={3}
                  value={values.note && values.note.toLowerCase().replace(/\s/g, '_') === 'not_shift_note' ? `${t('Timesheet.not_shift_note')}` : values.note}
                  invalid={touched.note && errors.note}
                  onChange={handleChange}
                ></InputForm>
                {touched.note && errors.note && (
                  <ErrorText className="px-2 mb-0">{errors.note}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.status')}></LabelForm>
              </Col>
              <Col xs={9}>
                <Status userShift={data.userShift} endDate={endDate} />
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                    fontSize: '14px',
                  }}
                  loading={loading}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={
                    !data.userShift.signer && userData.isSiteManager ? `${t('buttons.btn_update_approve')}` : `${t('buttons.btn_update')}`
                  }
                >
                  {!data.userShift.signer && userData.isSiteManager ? `${t('buttons.btn_update_approve')}` : `${t('buttons.btn_update')}`}
                </AppButton>
                <AppButton
                  size="lg"
                  style={{ width: 'auto', display: 'unset', flexDirection: 'unset', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                  loading={loading}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
