import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

const StyledColor = styled.div`
  border: 1px solid ${(props) => (props.color ? chroma(props.color).darken().hex() : 'white')};
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: ${(props) => (props.padding ? props.padding : '6px')};
  width: ${(props) => (props.width ? props.width : '40px')};
  height: ${(props) => (props.height ? props.height : '40px')};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppOrgIcon = ({ children, ...other }) => {
  return <StyledColor {...other}>{children}</StyledColor>;
};
export default AppOrgIcon;
