import React, { useEffect, useState } from 'react';
import { AppTable, AppButton, IcArrow, AppOrgIcon } from '@components';
import { AditionalActions } from '../AditionalActions';
import { ViewDetailsActivity } from '../AditionalActions/ViewDetailsActivity';
import styled from 'styled-components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { Menu, MenuItem } from '@szhsin/react-menu';
import currentWeekNumber from 'current-week-number';
import { formatEspecial } from '@utils/dateFormatHelper';
import { SHIFT_ACTIVITY_API } from '@services/CONSTANTS';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { InputForm } from '@components';
import ic_search from '@assets/icons/ic_search.svg';
import { useSelector } from 'react-redux';
import { AppModal } from '@components';
import { size } from 'lodash';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { getEntityData } from '@services';
import { calcAvatar } from 'utils/avatar';
import * as XLSX from 'xlsx/xlsx.mjs';

import { useTranslation } from 'react-i18next';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const ShiftSubLabelWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #878e97;
`;

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  height: 73px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledNestedCellShfit = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  height: 73px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  :hover {
    cursor: pointer;
    color: ${MAIN_BLUE};
  }
`;

const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
  color: #1179BF;
`;

const ShiftWrapper = styled.div`
  background: ${(props) =>
    props.shift.shift_id?.status?.value_definition === '020'
      ? props.shift.shift_id?.site?.organization_id?.color
      : props.shift.shift_id?.site?.organization_id?.color};
  border: ${(props) =>
    props.shift.shift_id?.status?.value_definition === '020' && !props.shift.shift_id.site
      ? `6px solid ${props.shift?.shift_id?.organization?.color}`
      : props.shift.site
      ? `6px solid ${props.shift?.shift_id?.organization?.color}`
      : '0px'};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${(props) =>
    props.shift.shift_id?.status?.value_definition === '020' ||
    props.shift.shift_id?.status?.value_definition === '050'
      ? '600'
      : '400'};
  font-size: 13px;
  line-height: 20px;
  padding: 5px 5px 5px 5px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export function Activity({
  sites,
  group,
  handleShowShiftDetail,
  handleClickOpenCreateSnglShiftModal,
  allowEditActions,
}) {
  const { t } = useTranslation();
  const { reloadScheduler } = useSelector((state) => state.app);
  const [weekList, setWeekList] = useState(currentWeekNumber(new Date()));
  const [yearList, setYearList] = useState(formatEspecial(new Date(), 'YYYY'));
  const [dayList, setDayList] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDatesListView, setFilterDatesListView] = useState('Week');
  const [conditionDatesListView, setConditionDatesListView] = useState([]);
  const [labelDatesListView, setLabelDatesListView] = useState(formatEspecial(new Date(), 'MMM D'));
  const [dataList, setDataList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(group);
  const [dataListView, setDataListView] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openViewDetails, setOpenViewDetails] = useState(false);
  const [filterDates, setFilterDates] = useState({
    start: moment.utc().startOf('week'),
    end: moment.utc().endOf('week'),
  });
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => {
    setGroupFilter(group);
    generateFilterDates('Week');
  }, [group]);
  const handleOpenViewDetails = (row) => {
    setOpenViewDetails(true);
    setDataView(row);
  };
  const handleCloseViewDetails = () => {
    setOpenViewDetails(false);
  };
  const exportDataXlsx = () => {
    let report = [];

    if (dataList.length > 0) {
      let descriptionFinal = '';
      dataList.forEach((dataShift, i) => {
        let relaredTo = `${t('status_types.No_Users')}`;

        if (dataShift.shift_users.length > 0) {
          relaredTo = '';
          if (dataShift.shift_users.length === 1) {
            relaredTo = dataShift.shift_users[0].user_id.name;
          } else {
            dataShift.shift_users.forEach((user) => {
              relaredTo = relaredTo + user.user_id.name + ' , ';
            });
          }

          descriptionFinal =
            `${dataShift?.perfomer?.name} ${dataShift?.type_activity?.description} ` +
            `${t('form_labels.shift').toLowecase()} "${dataShift?.activity_shifts[0]?.shift_id?.jobs_titles
              ?.map((jobtitle) => jobtitle.jobtitle.description)
              .join(', ')} - ${dataShift?.activity_shifts[0]?.shift_id.title}" ${t('JobScheduler.activities.on')} ` +
            `${moment.utc(dataShift?.activity_shifts[0]?.shift_id?.start_time).format('ddd')} ` +
            `${moment.utc(dataShift?.activity_shifts[0]?.shift_id?.start_time).format('M')}.${moment
              .utc(dataShift?.activity_shifts[0]?.shift_id?.start_time)
              .format('d')} ` +
            `${t('JobScheduler.activities.from')} ${new moment(dataShift?.activity_shifts[0]?.shift_id?.start_time).format('h')}` +
            `${
              moment(dataShift?.activity_shifts[0]?.shift_id?.start_time).format('a') === 'am'
                ? 'a'
                : 'p'
            } ${t('JobScheduler.activities.to')} ` +
            `${new moment(dataShift?.activity_shifts[0]?.shift_id?.end_time).format('h')}` +
            `${
              moment(dataShift?.activity_shifts[0]?.shift_id?.end_time).format('a') === 'am'
                ? 'a'
                : 'p'
            } ${t('JobScheduler.activities.in_the')} ` +
            `${dataShift?.activity_shifts[0]?.shift_id?.site.name} ${t('JobScheduler.activities.group')}`;
        }

        report.push({
          DateActivity: moment(dataShift.activity_date).format('MM/DD/YYYY'),
          Time: moment(dataShift.activity_time).format(' h:mm:ss'),
          DateShift: moment(dataShift.activity_shifts[0]?.shift_id?.start_time).format(
            'MM/DD/YYYY',
          ),
          Start: moment(dataShift.activity_shifts[0]?.shift_id?.start_time).format('h:mm:ss A'),
          End: moment(dataShift.activity_shifts[0]?.shift_id?.end_time).format('h:mm:ss A'),
          ShiftTitle: dataShift.activity_shifts[0]?.shift_id?.title,
          ShiftJobtitle: dataShift?.activity_shifts[0]?.shift_id?.jobs_titles
            ?.map((jobtitle) => jobtitle.jobtitle.description)
            .join(', '),
          ShiftSite: dataShift.activity_shifts[0]?.shift_id.site?.name,
          Performer: dataShift.perfomer?.name,
          RelatedTo: relaredTo,
          Type: dataShift.type_activity?.description,
          Details: descriptionFinal, //{f: '=HYPERLINK("http://www.google.com","View")'}
        });
      });
    }

    let wp = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(report);

    XLSX.utils.book_append_sheet(wp, ws, `${t('JobScheduler.activities.book_sheet')}`);
    XLSX.writeFile(wp, `${t('JobScheduler.activities.book_sheet_file_name')}.xlsx`);
  };

  const listViewColumns = [
    {
      dataField: 'activity_date',
      text: `${t('table_columns.date')}`,
      sort: false,
      search: false,
      headerStyle: { width: '10%' },
      style: { alignItems: 'start' },
      formatter: (cellContent, row) => {
        if (row.activity_date) {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: 'transparent',
                display: 'block',
                alignItems: 'start',
              }}
            >
              {formatEspecial(row.activity_date, 'ddd') +
                ' ' +
                row.activity_date.substring(8, 10) +
                '.' +
                formatEspecial(row.activity_date, 'MM')}
            </StyledNestedCell>
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'block',
                paddingTop: '13px',
                margin: '3px',
                border: '6px solid #EEEEEE',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('status_types.No_Date')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'activity_time',
      text: `${t('table_columns.time')}`,
      sort: false,
      search: false,
      headerStyle: { width: '10%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.activity_time ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                  }}
                >
                  {formatEspecial(orgSite.activity_time, 'h a')}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                    alignItems: 'start',
                  }}
                >
                  {' '}
                  {t('no_time')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('no_time')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'shift',
      text: `${t('table_columns.shift')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows?.map((orgSite, i) =>
            size(orgSite.activity_shifts) > 0 ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                  }}
                >
                  {size(orgSite.activity_shifts) === 1 ? (
                    <>
                      {' '}
                      {orgSite.activity_shifts.map((shift, i) => (
                        <ShiftWrapper shift={shift}>
                          {`${moment.utc(shift.shift_id.start_time).format('h')}${
                            moment.utc(shift.shift_id.start_time).format('a') === 'am' ? 'a' : 'p'
                          } - ${moment.utc(shift.shift_id.end_time).format('h')}${
                            moment.utc(shift.shift_id.end_time).format('a') === 'am' ? 'a' : 'p'
                          }`}
                          <ShiftSubLabelWrapper>
                            {shift.shift_id.title
                              ? `${shift.shift_id.title}`
                              : !shift.shift_id.site
                              ? `${t('status_types.No_Site')}`
                              : ''}
                          </ShiftSubLabelWrapper>
                          <ShiftSubLabelWrapper>
                            {' '}
                            {shift.shift_id?.jobs_titles
                              ?.map((jobtitle) => jobtitle.jobtitle.description)
                              .join(', ') + ' > '}{' '}
                            {shift.shift_id?.site?.name ? shift.shift_id?.site?.name : `${t('status_types.No_Site')}`}{' '}
                          </ShiftSubLabelWrapper>
                        </ShiftWrapper>
                      ))}
                    </>
                  ) : (
                    <>
                      {orgSite.activity_shifts.map((org, i) => (
                        <div style={{ display: 'flex', marginLeft: i !== 0 ? '-15px' : '0px' }}>
                          <AppOrgIcon color={org.shift_id?.site?.organization_id?.color} />
                        </div>
                      ))}
                      <StyledIconLabel>{size(orgSite.activity_shifts)} {t('form_labels.shifts')}</StyledIconLabel>
                    </>
                  )}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                    alignItems: 'start',
                  }}
                >
                  {t('status_types.No_Shift')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('status_types.No_Shift')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'performer',
      text: `${t('table_columns.performer')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.perfomer?.id ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                  }}
                >
                  <>
                    {' '}
                    <div style={{ display: 'flex' }}>
                      {calcAvatar(orgSite.perfomer)}
                      <StyledIconLabel>{orgSite.perfomer?.name}</StyledIconLabel>
                    </div>
                  </>
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) === 0 ? '40px' : '73px',
                  }}
                >
                  {t('status_types.No_Users')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('status_types.No_Users')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'related_to',
      text: `${t('table_columns.related_to')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            size(orgSite.shift_users) > 0 ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                  }}
                >
                  {size(orgSite.shift_users) === 1 ? (
                    <>
                      {' '}
                      {orgSite.shift_users.map((user, i) => (
                        <div style={{ display: 'flex' }}>
                          {calcAvatar(user?.user_id)}
                          <StyledIconLabel>{user.user_id.name}</StyledIconLabel>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex' }}>
                        {orgSite.shift_users.map((user, i) => (
                          <>
                            <div style={{ marginLeft: i !== 0 ? '-15px' : '0px' }}>
                              {calcAvatar(user.user_id)}
                            </div>
                          </>
                        ))}
                      </div>
                      <StyledIconLabel>{size(orgSite.shift_users)} {t('form_labels.Employees')}</StyledIconLabel>
                    </>
                  )}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                  }}
                >
                  {t('status_types.No_Users')}
                </StyledNestedCell>
              </div>
            ),
          );
        }
      },
    },
    {
      dataField: 'type',
      text: `${t('table_columns.type')}`,
      sort: false,
      search: false,
      headerStyle: { width: '10%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map(
            (orgSite, i) => (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                    color:
                      orgSite.type_activity?.value_definition === '010'
                        ? '#8989FF'
                        : orgSite.type_activity?.value_definition === '020'
                        ? '#FF5555'
                        : orgSite.type_activity?.value_definition === '040'
                        ? '#52CC34'
                        : orgSite.type_activity?.value_definition === '050'
                        ? '#52CC34'
                        : orgSite.type_activity?.value_definition === '030' && '#FFB800',
                  }}
                >
                  {orgSite.type_activity.description === 'Delete'
                    ? `${t('buttons.btn_cancel')}`
                    : `${t(`JobScheduler.activities.types.${orgSite.type_activity?.description.toLowerCase().replace(/\s/g, '_')}`)}`}
                </StyledNestedCell>
              </div>
            ),
            // orgSite.activity_shifts.map((c) => ());
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                alignItems: 'start',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('status_types.No_Shift')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'details',
      text: `${t('table_columns.details')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      style: { padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map(
            (orgSite, i) =>
              size(orgSite.activity_shifts) > 0 ? (
                <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                  <StyledNestedCellShfit
                    style={{
                      backgroundColor: 'transparent',
                      display: 'flex',
                      margin: '3px',
                      color: '#1179BF',
                      alignItems: 'start',
                      height: size(orgSite.activity_shifts) > 1 ? '40px' : '73px',
                    }}
                    text={t('table_columns.view')}
                    showIcon={true}
                    onClick={() => handleOpenViewDetails(orgSite)}
                  >
                    {t('table_columns.view')}
                  </StyledNestedCellShfit>
                </div>
              ) : (
                <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                  <StyledNestedCellShfit
                    style={{
                      backgroundColor: 'transparent',
                      display: 'flex',
                      margin: '3px',
                      color: '#1179BF',
                      alignItems: 'start',
                    }}
                    text={t('table_columns.view')}
                    showIcon={true}
                  ></StyledNestedCellShfit>
                </div>
              ),
            // orgSite.activity_shifts.map((c) => ());
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('status_types.No_Shift')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];

  const generateFilterDates = (view) => {
    let fecha = new Date();
    let filters = [];
    if (view === 'Day') {
      setLabelDatesListView(formatEspecial(fecha, 'MMM D'));
      let dates = [];
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00');
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59');
      filters = [{ field: 'activity_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setDayList(fecha);
      setYearList(formatEspecial(fecha, 'YYYY'));
      setWeekList(currentWeekNumber(fecha));
      setFilterDates({
        start: formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59',
      });
    } else if (view === 'Week') {
      let week = currentWeekNumber(fecha);
      let primerdia = new Date(fecha.getFullYear(), 0, 1);
      let correccion = 6 - primerdia.getDay();
      let primer = new Date(fecha.getFullYear(), 0, (week - 1) * 7 + 3 + correccion);
      let ultimo = new Date(fecha.getFullYear(), 0, (week - 1) * 7 + 9 + correccion);
      primer = primer.setDate(primer.getDate() - 1);
      ultimo = ultimo.setDate(ultimo.getDate() - 1);
      let primerMes = formatEspecial(primer, 'MMMM');
      let segundoMes = formatEspecial(ultimo, 'MMMM');
      if (primerMes === segundoMes) {
        setLabelDatesListView(
          formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'D'),
        );
      } else {
        setLabelDatesListView(
          formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'MMM D'),
        );
      }
      let dates = [];
      dates.push(formatEspecial(primer, 'YYYY-MM-DD'));
      dates.push(formatEspecial(ultimo, 'YYYY-MM-DD'));
      filters = [{ field: 'activity_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setFilterDates({
        start: formatEspecial(primer, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(ultimo, 'YYYY-MM-DD') + ' 23:59',
      });
    } else {
      setLabelDatesListView(formatEspecial(fecha, 'MMM'));
    }
  };

  const continueDate = (mode) => {
    let filters = [];
    if (mode !== 'Today') {
      if (filterDatesListView === 'Day') {
        if (mode === 'Add') {
          dayList.setDate(dayList.getDate() + 1);
        } else {
          dayList.setDate(dayList.getDate() - 1);
        }
        setLabelDatesListView(formatEspecial(dayList, 'MMM D'));
        setDayList(dayList);
        setYearList(formatEspecial(dayList, 'YYYY'));
        setWeekList(currentWeekNumber(dayList));
        let dates = [];
        dates.push(formatEspecial(dayList, 'YYYY-MM-DD') + ' 00:00');
        dates.push(formatEspecial(dayList, 'YYYY-MM-DD') + ' 23:59');
        filters = [{ field: 'activity_date', operator: CondOperator.BETWEEN, value: dates }];
        setConditionDatesListView(filters);
        setFilterDates({
          start: formatEspecial(dayList, 'YYYY-MM-DD') + ' 00:00',
          end: formatEspecial(dayList, 'YYYY-MM-DD') + ' 23:59',
        });
      } else if (filterDatesListView === 'Week') {
        let week = weekList;
        let year = yearList;
        if (mode === 'Add') {
          week = parseFloat(weekList) + 1;
        } else {
          week = parseFloat(weekList) - 1;
        }
        if (week === 0) {
          week = 52;
          year = parseFloat(year) - 1;
        }
        if (parseFloat(week) >= 53) {
          week = 1;
        }
        let primerdia = new Date(year, 0, 1);
        let correccion = 6 - primerdia.getDay();
        let primer = new Date(year, 0, (week - 1) * 7 + 3 + correccion);
        let ultimo = new Date(year, 0, (week - 1) * 7 + 9 + correccion);
        primer = primer.setDate(primer.getDate() - 1);
        ultimo = ultimo.setDate(ultimo.getDate() - 1);
        let primerMes = formatEspecial(primer, 'MMMM');
        let segundoMes = formatEspecial(ultimo, 'MMMM');
        if (primerMes === segundoMes) {
          setLabelDatesListView(
            formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'D'),
          );
        } else {
          setLabelDatesListView(
            formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'MMM D'),
          );
        }
        setWeekList(week);
        if (mode === 'Add') {
          if (year !== formatEspecial(ultimo, 'YYYY')) {
            setYearList(formatEspecial(ultimo, 'YYYY'));
          }
        } else {
          if (year !== formatEspecial(primer, 'YYYY')) {
            setYearList(formatEspecial(primer, 'YYYY'));
          }
        }
        let dates = [];
        console.log(formatEspecial(primer, 'YYYY-MM-DD'));
        console.log(formatEspecial(ultimo, 'YYYY-MM-DD'));
        dates.push(formatEspecial(primer, 'YYYY-MM-DD') + ' 00:00');
        dates.push(formatEspecial(ultimo, 'YYYY-MM-DD') + ' 23:59');
        filters = [{ field: 'activity_date', operator: CondOperator.BETWEEN, value: dates }];
        setConditionDatesListView(filters);
        setFilterDates({
          start: formatEspecial(primer, 'YYYY-MM-DD') + ' 00:00',
          end: formatEspecial(ultimo, 'YYYY-MM-DD') + ' 23:59',
        });
      }
    } else {
      let fecha = new Date();
      setLabelDatesListView(formatEspecial(fecha, 'MMM D'));
      let dates = [];
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00');
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59');
      filters = [{ field: 'activity_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setDayList(fecha);
      setYearList(formatEspecial(fecha, 'YYYY'));
      setWeekList(currentWeekNumber(fecha));
      setFilterDates({
        start: formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59',
      });
    }
  };

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  useEffect(() => {
    const res = dataList;
    setLoadingData(false);
    if (size(res) > 0) {
      let array_data = [];
      let array_row = [];
      let ref_id = 0;
      let row_id = 0;
      let row_data = {};
      let total_time = 0;
      res.filter(function (row, index) {
        if (index === 0) {
          ref_id = row?.activity_date ? formatEspecial(row.activity_date, 'YYYY MM DD') : '';
          row_data = row;
          row_data.total_time = total_time;
          array_data.push(row_data);
          if (size(res) === 1) {
            row_data.rows = array_data;
            row_data.total_time = total_time;
            array_row.push(row_data);
          }
        } else {
          row_id = row?.activity_date ? formatEspecial(row.activity_date, 'YYYY MM DD') : '';
          if (ref_id === row_id) {
            row_data.total_time = total_time;
            array_data.push(row);
          } else {
            row_data.rows = array_data;
            row_data.total_time = total_time;
            row_data.rows.sort((a, b) => (a.activity_date > b.activity_date ? -1 : 1));
            array_row.push(row_data);
            row_data = {};
            total_time = 0;

            ref_id = row?.activity_date ? formatEspecial(row.activity_date, 'YYYY MM DD') : '';
            row_data = row;
            array_data = [];
            row_data.total_time = total_time;
            array_data.push(row);
          }

          let final = parseFloat(size(res)) - 1;
          if (index === final) {
            row_data.rows = array_data;
            row_data.total_time = total_time;
            row_data.rows.sort((a, b) => (a.activity_date > b.activity_date ? -1 : 1));
            array_row.push(row_data);
            row_data = {};
            total_time = 0;
          }
        }
        // array_row.sort((a, b) => (a.activity_date > b.activity_date ? 1 : -1));
        return array_row;
      });
      setDataListView(array_row);
    } else {
      setDataListView([]);
    }
  }, [dataList, reloadSchedulerCalendar]);

  useEffect(() => {
    if (size(conditionDatesListView) > 0) {
      setLoadingData(true);
      let keys = [
        'shift_users.user_id.name',
        'perfomer.name',
        'activityShifts.activityShiftsShift.title',
        'activityShifts.activityShiftsShift.activityShiftsShiftJobtitle.description',
      ];
      //    const fieldSearch = {};
      const fieldsFiltering = [];
      if (debouncedSearchTerm) {
        keys.forEach((key) => {
          const fieldSearch = {};
          fieldSearch[key] = { [CondOperator.CONTAINS]: debouncedSearchTerm };
          fieldsFiltering.push(fieldSearch);
        });
      }
      let filters = [
        { field: 'groupsActivity.id', operator: CondOperator.EQUALS, value: groupFilter.id },
      ];
      const fieldsFixedFiltering = [];
      let joins = [
        { field: 'perfomer' },
        { field: 'group' },
        { field: 'shift_users' },
        { field: 'activity_details' },
        { field: 'activity_shifts' },
        { field: 'activity_shifts.shift_id' },
        { field: 'type_activity' },
        { field: 'activity_shifts.shift_id.jobs_titles' },
        { field: 'activity_shifts.shift_id.status' },
        { field: 'activity_shifts.shift_id.site' },
        { field: 'activity_shifts.shift_id.site.organization_id' },
        { field: 'activity_shifts.shift_id.jobs_titles.jobtitle' },
        { field: 'shift_users.user_id' },
      ];
      let endPoint = SHIFT_ACTIVITY_API;
      let sort = [{ field: 'activity_date', order: 'DESC' }];
      filters = [...filters, ...conditionDatesListView];
      filters &&
        filters.forEach((filter) => {
          const fieldSearch = {};
          fieldSearch[filter.field] = { [filter.operator]: filter.value };
          fieldsFixedFiltering.push(fieldSearch);
        });
      fieldsFixedFiltering.push({ $or: fieldsFiltering });
      const s = { $and: fieldsFixedFiltering };
      //    const search = filters ? s : { $or: fieldsFiltering };
      const qb = RequestQueryBuilder.create().sortBy(sort).setJoin(joins).search(s);

      getEntityData(endPoint, qb.query())
        .then((res) => {
          let data = [...res];
          //    data.sort((a, b) => (a.activity_date > b.activity_date ? -1 : 1));
          setDataList(data);
        })
        .catch((err) => {
          setLoadingData(false);
          console.log('axios err=', err);
        });
    }
  }, [
    groupFilter,
    conditionDatesListView,
    debouncedSearchTerm,
    reloadScheduler,
    reloadSchedulerCalendar,
  ]);

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <AppTable
      key="activities-view"
      columns={listViewColumns}
      filtersAdditional={[
        {
          filter: (
            <Menu
              menuButton={
                <AdvanceFilterButtonWrapper
                  style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                >
                  {t(`JobScheduler.menu.${filterDatesListView.toLowerCase()}`)}
                  <IcArrow
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    status={'enabled'}
                    direction={'down'}
                  />
                </AdvanceFilterButtonWrapper>
              }
            >
              <MenuItem
                onClick={(e) => {
                  generateFilterDates('Day');
                  setFilterDatesListView('Day');
                }}
              >
                {t(`JobScheduler.menu.day`)}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  generateFilterDates('Week');
                  setFilterDatesListView('Week');
                }}
              >
                {t(`JobScheduler.menu.week`)}
              </MenuItem>
            </Menu>
          ),
        },
        {
          filter: (
            <PaginationTopButton>
              <IconButton
                aria-label={t('form_labels.job_scheduler')}
                style={{ marginLeft: 2, marginRight: 2 }}
                onClick={(e) => {
                  continueDate('Quit');
                }}
              >
                <IcArrow style={{ height: 10, width: 5 }} direction="left" status={'enabled'} />
              </IconButton>
              <div style={{ color: '#1179BF', marginRight: 5 }}>{labelDatesListView}</div>
              <IconButton
                aria-label={t('form_labels.job_scheduler')}
                onClick={(e) => {
                  continueDate('Add');
                }}
              >
                <IcArrow style={{ height: 10, width: 5 }} direction={'right'} status={'enabled'} />
              </IconButton>
            </PaginationTopButton>
          ),
        },
        {
          filter: (
            <AppButton
              size="lg"
              bgcolor={PALETTE_COLOR.MAIN_WHITE}
              bghovercolor={PALETTE_COLOR.MAIN_BLUE}
              color={PALETTE_COLOR.MAIN_BLUE}
              bordercolor={PALETTE_COLOR.TOP_GRAY}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              style={{ marginRight: '-5px', marginLeft: '10px' }}
              onClick={(e) => {
                setFilterDatesListView('Day');
                generateFilterDates('Day');
                continueDate('Today');
              }}
            >
              {t('buttons.btn_today')}
            </AppButton>
          ),
        },
      ]}
      disablePagination={true}
      disableTopPagination={true}
      withAdvanceFilter={true}
      onFilterChange={(selected) => {}}
      csvName={t('csv_names.export_list_view_csv_name')}
      isSearchable={false}
      // search_alter={
      //   <InputForm
      //     style={{ marginLeft: 15, marginRight: 15, width: '100%' }}
      //     height={'40px'}
      //     placeholder={t('placeholder_search')}
      //     icon={ic_search}
      //     onChange={(e) => setSearchTerm(e.target.value)}
      //   />
      // }
      joins={[
        { field: 'organization' },
        { field: 'status' },
        { field: 'site' },
        { field: 'user_shifts' },
        { field: 'user_shifts.user' },
      ]}
      data={dataListView}
      loading={loadingData}
      trailingBtn={
        <div style={{ display: 'flex', height: '40px', position: 'absolute', right: 0, paddingRight: 20 }}>
          {/* <AditionalActions
            currentdata={dataList}
            type={filterDatesListView.toLowerCase()}
            currentWeek={filterDates}
            allowEditActions={allowEditActions}
          /> */}
          {
            //Only super admin, group admin and SM-OG full acess can publish massive shifts
            allowEditActions && (
              <AppButton
                style={{ width: 130, height: 40 }}
                type="submit"
                bgcolor={PALETTE_COLOR.MAIN_GREEN}
                onClick={exportDataXlsx}
              >
                {t('buttons.btn_export')}
              </AppButton>
            )
          }
          <AppModal
            open={openViewDetails}
            handleclose={handleCloseViewDetails}
            title={t('JobScheduler.modals.activity_details_title')}
            inside={1}
          >
            <ViewDetailsActivity handleclose={handleCloseViewDetails} data={dataView} />
          </AppModal>
        </div>
      }
    />
  );
}
