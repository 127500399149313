import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import appConfig from '@config/appConfig';

var firebaseConfig = appConfig.firebase;
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: appConfig.firebase.vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem('fcmtoken', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      //console.log('🚀 ~ file: firebase.js ~ line 29 ~ fetchToken ~ err', err);
      console.log('An error occurred while retrieving token. ', err);
      setTokenFound(false);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
