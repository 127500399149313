import React, { useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppModal, InputForm, LabelForm, AppButton, AppModalStatus } from '@components';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@redux/actions/appActions';
import { editEntityData, deleteEntityData } from '@services';
import { SHIFTS_WEEEK_TEMPLATES } from '@services/CONSTANTS';
import trash from '@assets/icons/trash.svg';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const DeleteLabelWrapper = styled.div`
  background: #e7f5ff;
  border-radius: 10px;
  height: 62px;
  padding: 8px;
`;

const TitleWraper = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

const SubTitleWrapper = styled.div`
  color: #878e97;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

export const FormWeekTemplate = ({ data, handleclose, reloadData }) => {
  const { t } = useTranslation();
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .max(20, `${t('JobScheduler.validation.edit_week_template.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
  });
  //To handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  //Redux
  const dispatch = useDispatch();
  //Info to send to component which shows confirmation to delete
  const removeGroupModalContent = {
    buttonText: `${t('buttons.btn_remove')}`,
    actionIcon: ico_Archived,
    title: `${t('JobScheduler.modals.delete_week_template_title')}`,
    subtitle: (
      <DeleteLabelWrapper>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <TitleWraper>{data.name}</TitleWraper>
          <SubTitleWrapper>{`${data.total_shifts} ${t('form_labels.shifts').toLowerCase()}`}</SubTitleWrapper>
        </div>
      </DeleteLabelWrapper>
    ),
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Calling to WS to edit a Job Title
   */
  const updateWeekTemplate = (values) => {
    //Hide message
    setLoading(true);
    //Remove white spaces
    editEntityData(`${SHIFTS_WEEEK_TEMPLATES}${data.id}`, { name: values.name.trim() })
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.week_template_edited')}`,
          message: '',
        });
        //Close form modal
        handleclose();
        setLoading(false);
        reloadData();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.week_template_exists')}`,
            message: '',
          });
        } else {
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_week_template_editing')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  /***
   * Call api to delete the job title by id
   */
  const removeTemplate = () => {
    //Hide message
    //Call api
    setLoading(true);
    deleteEntityData(`${SHIFTS_WEEEK_TEMPLATES}${data.id}`, {})
      .then((res) => {
        //Show success message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.template_removed')}`,
          message: '',
        });
        setOpenRemoveModal(false);
        handleclose();
        setLoading(false);
        reloadData();
      })
      .catch((err) => {
        //Close modal
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.indexOf('foreign key') !== -1
        ) {
          //Conflict
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_week_template_deleting_relations')}`,
            message: '',
          });
        } else {
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_week_template_removing')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Formik
        validationSchema={formSchema}
        onSubmit={updateWeekTemplate}
        initialValues={{
          name: data.name,
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <div style={{ width: '590px' }}>
            <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
              <Row>
                <Col xs={3}>
                  <LabelForm tittle={t('form_labels.title')}></LabelForm>
                </Col>
                <Col xs={9}>
                  <InputForm
                    className="mb-0"
                    height={'40px'}
                    placeholder={t('required')}
                    name="name"
                    value={values.name}
                    invalid={touched.name && errors.name}
                    onChange={handleChange}
                  ></InputForm>
                  {touched.name && errors.name && (
                    <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                  )}
                </Col>
              </Row>
              <StyleButtons>
                <span>
                  <AppButton
                    style={{
                      width: 'auto',
                      marginRight: '20px',
                      display: 'unset',
                      flexDirection: 'unset',
                    }}
                    loading={loading}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bordercolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    title={t('buttons.btn_update_template')}
                  >
                    {t('buttons.btn_update_template')}
                  </AppButton>
                  <AppButton
                    size="lg"
                    style={{ width: 'auto', display: 'unset', flexDirection: 'unset' }}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={handleclose}
                    title={t('buttons.btn_discard')}
                    loading={loading}
                  >
                    {t('buttons.btn_discard')}
                  </AppButton>
                  <AppButton
                    loading={loading}
                    style={{
                      width: 40,
                      display: 'unset',
                      flexDirection: 'unset',
                      paddingLeft: 10,
                      marginLeft: 16,
                    }}
                    bgcolor={PALETTE_COLOR.MAIN_WHITE}
                    bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                    color={PALETTE_COLOR.MAIN_BLUE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={() => setOpenRemoveModal(true)}
                    title={t('buttons.btn_discard')}
                    icon={trash}
                  ></AppButton>
                </span>
              </StyleButtons>
              {/* Remove modal */}
              <AppModal
                open={openRemoveModal}
                handleclose={() => setOpenRemoveModal(false)}
                title={''}
                boxshadow={'none'}
                inside={1}
              >
                <AppModalStatus
                  actionIcon={removeGroupModalContent.actionIcon}
                  title={removeGroupModalContent.title}
                  subtitle={removeGroupModalContent.subtitle}
                  bgcolor={removeGroupModalContent.bgcolor}
                  bordercolor={removeGroupModalContent.bordercolor}
                  borderhovercolor={removeGroupModalContent.borderhovercolor}
                  bghovercolor={removeGroupModalContent.bghovercolor}
                  boxShadow={removeGroupModalContent.boxShadow}
                  buttonText={removeGroupModalContent.buttonText}
                  isLoading={loading}
                  buttonAction={removeTemplate}
                />
              </AppModal>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};
export default FormWeekTemplate;
