import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { AppSwitchSelector, AppColorRadioSelector, AppAvatar, AppButton } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useDispatch } from 'react-redux';
import { setProfilePhotoToSaveAction } from '@redux/actions/appActions';
import { useSelector } from 'react-redux';

import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ContainerWrapper = styled(Container)`
  margin: 0px;
  padding: 0px;
  width: 800px;
`;

const ControlLabelWrapper = styled.div`
  margin-bottom: 12px;
  font-family: 'Poppins';
  font-style: nold;
  font-weight: 600;
  font-size: 16px;
`;
const ControlWrapper = styled.div`
  flex: 1;
  flex-direction: 'column';
  padding: 30px;
`;

const skinColorOptions = [
  {
    label: '',
    value: '#F5D0C5',
  },
  {
    label: '',
    value: '#FFCB7E',
  },
  {
    label: '',
    value: '#836055',
  },
];

const bgColorOptions = [
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_LIGHT_BLUE,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_PURPLE,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_GREEN,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_ORANGE,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_YELLOW,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_PINK,
  },
  {
    label: '',
    value: PALETTE_COLOR.AVATAR_BLUE,
  },
];

const avatars = [
  {
    avatarId: 1,
  },
  {
    avatarId: 2,
  },
  {
    avatarId: 3,
  },
  {
    avatarId: 4,
  },
  {
    avatarId: 5,
  },
  {
    avatarId: 6,
  },
  {
    avatarId: 7,
  },
  {
    avatarId: 8,
  },
  {
    avatarId: 9,
  },
  {
    avatarId: 10,
  },
  {
    avatarId: 11,
  },
  {
    avatarId: 12,
  },
  {
    avatarId: 13,
  },
  {
    avatarId: 14,
  },
  {
    avatarId: 15,
  },
  {
    avatarId: 16,
  },
];

export const ChangeAvatarForm = ({ handleclose, setShowSucessModal, onAvatarChanged }) => {
  const { t } = useTranslation();
  const genderOptions = [
    {
      label: `${t('Login.choose_avatar.male')}`,
      value: 'male',
    },
    {
      label: `${t('Login.choose_avatar.female')}`,
      value: 'female',
    },
  ];
  const { userData } = useSelector((state) => state.app);
  const user = userData.avatar_config
    ? JSON.parse(userData.avatar_config)
    : {
        avatarId: 1,
        gender: 'male',
        skinColor: '#F5D0C5',
        bgColor: '#E4ECF7',
      };
  const [gender, setGender] = React.useState(user.gender);
  const [skinColor, setSkinColor] = React.useState(user.skinColor);
  const [bgColor, setBgColor] = React.useState(user.bgColor);
  const [selected, setSelected] = React.useState(user.avatarId);
  const dispatch = useDispatch();

  const handleGender = (event, newGender) => {
    setGender(newGender);
  };

  const handleSkinColor = (event) => {
    setSkinColor(event.target.value);
  };

  const handleBgColor = (event) => {
    setBgColor(event.target.value);
  };

  /***
   *
   */
  const storeSelectedAvatar = () => {
    const avatar_config = {
      avatarId: selected,
      gender: gender,
      skinColor: skinColor,
      bgColor: bgColor,
    };
    //Store in redux
    dispatch(setProfilePhotoToSaveAction({ isAvatar: true, config: avatar_config }));
    handleclose();
  };

  return (
    <ContainerWrapper>
      <Row>
        <Col xl={3} lg={3}>
          <ControlWrapper>
            <ControlLabelWrapper>{t('Login.choose_avatar.gender')}</ControlLabelWrapper>
            <AppSwitchSelector width={i18next.resolvedLanguage === 'es' ? 178 : 168} onChange={handleGender} value={gender} options={genderOptions} />
          </ControlWrapper>
        </Col>
        <Col xl={3} lg={3}>
          <ControlWrapper>
            <ControlLabelWrapper>{t('Login.choose_avatar.skincolor')}</ControlLabelWrapper>
            <AppColorRadioSelector
              onChange={handleSkinColor}
              options={skinColorOptions}
              value={skinColor}
            />
          </ControlWrapper>
        </Col>
        <Col xl={6} lg={6}>
          <ControlWrapper>
            <ControlLabelWrapper>{t('Login.choose_avatar.background')}</ControlLabelWrapper>
            <AppColorRadioSelector
              onChange={handleBgColor}
              options={bgColorOptions}
              value={bgColor}
            />
          </ControlWrapper>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col style={{ display: 'flex', flexWrap: 'wrap', padding: 32 }}>
          {avatars.map((a, i) => (
            <div
              key={i}
              style={{
                marginRight: 20,
                marginBottom: 15,
                cursor: 'pointer',
                border: `${selected === a.avatarId ? `2px solid ${PALETTE_COLOR.MAIN_BLUE}` : ''}`,
                borderRadius: 50,
                padding: 5,
              }}
              onClick={() => setSelected(a.avatarId)}
            >
              <AppAvatar
                avatarId={a.avatarId}
                bgColor={bgColor}
                gender={gender}
                skinColor={skinColor}
                width={64}
                height={64}
              />
            </div>
          ))}
        </Col>
      </Row>
      <StyleButtons style={{ marginLeft: 40, marginRight: 40 }}>
        <span>
          <AppButton
            style={{
              fontSize: 14,
              width: 'auto',
              marginRight: 16,
              display: 'unset',
              flexDirection: 'unset',
            }}
            color={PALETTE_COLOR.MAIN_WHITE}
            bgcolor={PALETTE_COLOR.MAIN_GREEN}
            bordercolor={PALETTE_COLOR.MAIN_GREEN}
            bghovercolor={PALETTE_COLOR.MAIN_GREEN}
            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            size="lg"
            type="submit"
            title={t('buttons.btn_create')}
            onClick={storeSelectedAvatar}
          >
            {t('buttons.btn_create')}
          </AppButton>
          <AppButton
            size="lg"
            style={{
              fontSize: 14,
              width: 'auto',
              display: 'unset',
              flexDirection: 'unset',
            }}
            color={PALETTE_COLOR.MAIN_BLUE}
            bgcolor={PALETTE_COLOR.MAIN_WHITE}
            bordercolor={PALETTE_COLOR.TOP_GRAY}
            bghovercolor={PALETTE_COLOR.MAIN_BLUE}
            borderhovercolor={PALETTE_COLOR.TOP_GRAY}
            onClick={handleclose}
            title={t('buttons.btn_back')}
          >
            {t('buttons.btn_back')}
          </AppButton>
        </span>
      </StyleButtons>
    </ContainerWrapper>
  );
};
