import React from 'react';
import styled from 'styled-components';
import { AppModal, CropProfileImage } from '@components';
import { useSelector } from 'react-redux';
import ic_profile_photo from '@assets/icons/ic_profile_photo.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';

import { useTranslation } from 'react-i18next';

const UploadPhotoLabel = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const PhotoErrorLabel = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #ff5555;
  margin-top: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  height: 128px;
  width: 128px;
  border-radius: 100px;
`;

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const UploadProfileImage = () => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = React.useState(null);
  const { profileImageToSave } = useSelector((state) => state.app);
  const [imageSizeError, setImageSizeError] = React.useState(null);

  //Open modal to select a custom avatar
  const [openChangePhoto, setOpenChangePhoto] = React.useState(false);

  /***
   *
   */
  const handleChangePhotoModal = () => {
    setOpenChangePhoto(!openChangePhoto);
    setImageSrc(null);
  };

  /***
   *
   */
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size / 1000000 > 25 || (file.type !== 'image/png' && file.type !== 'image/jpeg')) {
        //Higher than 25mb
        setImageSrc(null);
        setImageSizeError(`${t('Login.choose_avatar.error_upload_file')}`);
      } else {
        let imageDataUrl = await readFile(file);
        setImageSizeError(null);
        setImageSrc(imageDataUrl);
      }
    }
  };

  return (
    <div className="App">
      <ImageWrapper
        onClick={() => {
          document.getElementById('myProfilePhoto').click();
        }}
      >
        {!profileImageToSave.isAvatar && profileImageToSave.config ? (
          <Image src={profileImageToSave.config.url} alt="cropped" />
        ) : (
          <Image src={ic_profile_photo} alt="ic_profile_photo" />
        )}
      </ImageWrapper>
      {imageSrc ? (
        <>
          <AppModal
            PaperProps={{ sx: { maxWidth: 800 } }}
            open={true}
            handleclose={handleChangePhotoModal}
            title={`${t('Login.choose_avatar.modal_edit_image_title')}`}
            inside={0}
          >
            <CropProfileImage imageSrc={imageSrc} handleChangePhotoModal={handleChangePhotoModal} />
          </AppModal>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <UploadPhotoLabel
            onClick={() => {
              document.getElementById('myProfilePhoto').click();
            }}
          >
            {t('Login.choose_avatar.upload_photo')}
          </UploadPhotoLabel>
          <input
            style={{ display: 'none' }}
            id="myProfilePhoto"
            type="file"
            onChange={onFileChange}
            accept="image/png, image/jpeg"
          />
          {imageSizeError && <PhotoErrorLabel>{imageSizeError}</PhotoErrorLabel>}
        </div>
      )}
    </div>
  );
};
