import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { NOTIFICATIONS_API, USERS_API, USER_SHIFTS_API, SHIFTS_API } from '@services/CONSTANTS';
import { getEntityData, editEntityData, deleteEntityData } from 'services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { AppComponentLoading, AppAvatar, AppButton, AppModal, AppModalStatus } from '@components';
import moment from 'moment';
import { Badge } from '@mui/material';
import ico_linear_tick from '@assets/icons/ico_linear_tick.svg';
import ico_linear_close from '@assets/icons/ico_linear_close.svg';
import ico_linear_delete from '@assets/icons/ico_linear_delete.svg';
import ico_linear_document from '@assets/icons/ico_linear_document.svg';
import ico_linear_note from '@assets/icons/ico_linear_note.svg';
import ico_linear_time_circle from '@assets/icons/ico_linear_time_circle.svg';
import ico_linear_document_align_right from '@assets/icons/ico_linear_document_align_right.svg';
import ico_linear_document_fail from '@assets/icons/ico_linear_document_fail.svg';
import ico_linear_frame from '@assets/icons/ico_linear_frame.svg';
import ico_trash from '@assets/icons/ico_trash.svg';
import { ShiftTimes, ShiftDate } from 'utils/appUtils';
import { Scrollbars } from 'react-custom-scrollbars';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Button } from 'react-bootstrap';
import ico_decline from '@assets/icons/ico_decline.svg';
import ico_check from '@assets/icons/ico_check.svg';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@redux/actions/appActions';
import ico_cancel from '@assets/icons/ico_cancel.svg';
import avatar from '@assets/icons/ic_no_avatar.svg';

import i18next from 'i18n';
i18next.changeLanguage(i18next.resolvedLanguage);

const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const DayLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

const StyledBadge = styled(Badge)((props) => ({
  width: '50%',
  '& .MuiBadge-badge': {
    top: 16,
    left: 163,
    background: props.background,
    color: props.bgcolor,
  },
}));

const DayWrapper = styled.div`
  background: #f5f9ff;
  border-radius: 8px;
  padding: 4px 16px 4px 16px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #000000;
`;

const NotificationWrapper = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => (props.color ? props.color : 'rgba(131, 180, 49, 0.6)')};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

const NotificationBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #6d737b;
`;

const TitleWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const ImageWrapper = styled.img`
  width: 40.53px;
  height: 40px;
`;

const PerformerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const PerformerNameWrapper = styled.div`
  font-weight: 500;
  font-size: 13px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  width: -webkit-fill-available;
  padding: 9px 21px 9px 21px;
  margin-right: 2px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  background-color: #e4ebf6;
  border-color: #e4ebf6 !important;
  :active {
    border: 1px solid rgba(109, 115, 123, 0.2);
    box-sizing: border-box;
    color: white;
    background-color: ${PALETTE_COLOR.MAIN_BLUE} !important;
    box-shadow: 0px 4px 10px rgba(131, 180, 49, 0.3) !important;
    border: unset;
  }
  :focus,
  :hover {
    color: white;
    background-color: ${PALETTE_COLOR.MAIN_BLUE} !important;
    box-shadow: unset !important;
  }
`;

const PerformerNameDetailWrapper = styled.span`
  color: #1179bf;
`;

const LoadMoreWrapper = styled.span`
  background: rgba(131, 180, 49, 0.08);
  border-radius: 16px;
  padding: 16px;
  margin: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1179bf;
  cursor: pointer;
`;

const Icon = styled.img`
  border-radius: 60px;
`;

const StyledGroupContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 5px 10px 5px 10px;
  margin-right: 14px;
  background-color: #e4ebf6;
  border-radius: 16px;
`;

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: 'MMMM D, YYYY',
    lastWeek: 'MMMM D, YYYY',
    nextWeek: 'MMMM D, YYYY',
    sameElse: 'MMMM D, YYYY',
  },
});

const obtainIcon = (icon) => {
  switch (icon) {
    case 'ico_linear_tick':
      return ico_linear_tick;
    case 'ico_linear_close':
      return ico_linear_close;
    case 'ico_linear_delete':
      return ico_linear_delete;
    case 'ico_linear_document':
      return ico_linear_document;
    case 'ico_linear_note':
      return ico_linear_note;
    case 'ico_linear_document_fail':
      return ico_linear_document_fail;
    case 'ico_linear_frame':
      return ico_linear_frame;
    case 'ico_linear_time_circle':
      return ico_linear_time_circle;
    case 'ico_linear_document_align_right':
      return ico_linear_document_align_right;
    case 'ico_trash':
      return ico_trash;
    default:
      return ico_linear_tick;
  }
};

export const AppNotificationList = ({ handleClickCloseNotificationsModal }) => {
  const { userData } = useSelector((state) => state.app);
  const [notifications, setNotifications] = React.useState([]); //hold grouped data to show
  const [data, setData] = React.useState([]); //hold orginal data
  const [requestNotifications, setRequestNotifications] = React.useState([]); //Group data By Type (Request)
  const [noticesNotifications, setNoticesNotifications] = React.useState([]); //Group data By Type (Notices)
  const [readedNotices, setReadedNotices] = React.useState([]); //Group data By Type (Notices)
  const [loading, setLoading] = React.useState(false);
  const [dataCompleted, setDataCompleted] = React.useState([]);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({ limit: 10, page: 1 });
  const [total, setTotal] = React.useState({ limit: 10, page: 1 });
  const { height } = useWindowDimensions();
  const [selectedBtn, setSelectedBtn] = React.useState('request');

  /**********************fetch data **************************/
  React.useEffect(() => {
    if (userData && userData.sub_cognito_id) {
      if (pageConfig.page === 1) setLoading(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'timeStamp', order: 'DESC' })
        .setLimit(pageConfig.limit)
        .setPage(pageConfig.page)
        .setJoin({ field: 'userJobTitle.status' })
        .setFilter([
          {
            field: 'notificationDestinations.destination.sub_cognito_id',
            operator: CondOperator.EQUALS,
            value: userData.sub_cognito_id,
          },
        ]);
      getEntityData(NOTIFICATIONS_API, qb.query())
        .then((res) => {
          const qb = RequestQueryBuilder.create()
            .sortBy({ field: 'timeStamp', order: 'DESC' })
            .setJoin({ field: 'userJobTitle.status' })
            .select(['id', 'type', 'userJobTitle', 'userJobTitle.status', 'title'])
            .setFilter([
              {
                field: 'notificationDestinations.destination.sub_cognito_id',
                operator: CondOperator.EQUALS,
                value: userData.sub_cognito_id,
              },
              {
                field: 'notificationDestinations.status.value_definition',
                operator: CondOperator.EQUALS,
                value: '010',
              },
            ]);
          getEntityData(NOTIFICATIONS_API, qb.query())
            .then((resultado) => {
              setDataCompleted(resultado);
            })
            .catch((err) => {
              setLoading(false);
              setLoadingMore(false);
            });

          setData((d) => [...d, ...res.data]);
          setLoading(false);
          setLoadingMore(false);
          setTotal(res.total);
        })
        .catch((err) => {
          setLoading(false);
          setLoadingMore(false);
        });
    }
  }, [userData, pageConfig]);

  React.useEffect(() => {
    if (selectedBtn === 'notices') {
      for (let i = 0; i < readedNotices.length; i++) {
        editEntityData(`${NOTIFICATIONS_API}notifications/readed`, {
          notification: readedNotices[i].notificationDestinations,
        })
          .then((res) => {})
          .catch((err) => {
            console.log('🚀 ~ file: AppNotificationList.js ~ line 1072 ~ processAction ~ err', err);
          });
      }
    }
  }, [selectedBtn, noticesNotifications, loadingMore, readedNotices]);

  /**********************LOAD MORE****************************** */
  React.useEffect(() => {
    if (loadingMore) {
      setPageConfig((c) => ({ limit: c.limit, page: c.page + 1 }));
    }
  }, [loadingMore]);

  /****************Transform Data***************************/
  React.useEffect(() => {
    if (data && data.length > 0) {
      let notificationsRequest = [];
      let notificationsNotices = [];
      let completeNotificationsRequest = [];
      let completeNotificationsNotices = [];
      switch (userData.role?.value_definition) {
        case '040':
          notificationsNotices = data;
          completeNotificationsNotices = dataCompleted;
          break;
        case '030': // Group Admin
          notificationsRequest = data.filter((data) => data.type.value_definition === '020');
          notificationsNotices = data.filter((data) => data.type.value_definition !== '020');
          completeNotificationsRequest = dataCompleted.filter(
            (data) => data.type.value_definition === '020',
          );
          completeNotificationsNotices = dataCompleted.filter(
            (data) => data.type.value_definition !== '020',
          );
          break;
        case '020': // Site manager
          notificationsRequest = data.filter(
            (data) => data.type.value_definition === '030' && !data.title.includes('by Employee'),
          );
          notificationsNotices = data.filter(
            (data) =>
              data.type.value_definition !== '030' ||
              (data.type.value_definition === '030' && data.title.includes('by Employee')),
          );

          completeNotificationsRequest = dataCompleted.filter(
            (data) => data.type.value_definition === '030' && !data.title.includes('by Employee'),
          );
          completeNotificationsNotices = dataCompleted.filter(
            (data) =>
              data.type.value_definition !== '030' ||
              (data.type.value_definition === '030' && data.title.includes('by Employee')),
          );
          break;
        default:
          break;
      }

      // this gives an object with dates as keys
      const notificationsReq = notificationsRequest.reduce((notifications, notification) => {
        const date = notification.timeStamp.split('T')[0];
        if (!notifications[date]) {
          notifications[date] = [];
        }
        notifications[date].push(notification);
        return notifications;
      }, {});
      // Edit: to add it in the array format instead
      const requestedNotifications = Object.keys(notificationsReq).map((date) => {
        return {
          date,
          notifications: notificationsReq[date],
        };
      });

      const notificationsNot = notificationsNotices.reduce((notifications, notification) => {
        const date = notification.timeStamp.split('T')[0];
        if (!notifications[date]) {
          notifications[date] = [];
        }
        notifications[date].push(notification);
        return notifications;
      }, {});
      // Edit: to add it in the array format instead
      const noticeNotifications = Object.keys(notificationsNot).map((date) => {
        return {
          date,
          notifications: notificationsNot[date],
        };
      });

      selectedBtn === 'request'
        ? setNotifications(requestedNotifications)
        : setNotifications(noticeNotifications);
      let unReadNotifications = [];
      for (let i = 0; i < completeNotificationsNotices.length; i++) {
        let bandera = false;
        for (let z = 0; z < completeNotificationsNotices[i].notificationDestinations.length; z++) {
          if (
            completeNotificationsNotices[i].notificationDestinations[z].status.value_definition ===
            '010'
          ) {
            bandera = true;
          }
        }
        if (bandera === true) {
          unReadNotifications.push(completeNotificationsNotices[i]);
        }
      }
      setNoticesNotifications(unReadNotifications);
      let unReadRequest = [];
      for (let i = 0; i < completeNotificationsRequest.length; i++) {
        let bandera = false;
        for (let z = 0; z < completeNotificationsRequest[i].notificationDestinations.length; z++) {
          if (
            completeNotificationsRequest[i].notificationDestinations[z].status.value_definition ===
            '010'
          ) {
            bandera = true;
          }
        }
        if (bandera === true) {
          unReadRequest.push(completeNotificationsRequest[i]);
        }
      }
      setRequestNotifications(unReadRequest);

      let readNotifications = [];
      for (let i = 0; i < noticeNotifications.length; i++) {
        for (let y = 0; y < noticeNotifications[i].notifications.length; y++) {
          let bandera = false;
          for (
            let z = 0;
            z < noticeNotifications[i].notifications[y].notificationDestinations.length;
            z++
          ) {
            if (
              noticeNotifications[i].notifications[y].notificationDestinations[z].status
                .value_definition === '010'
            ) {
              bandera = true;
            }
          }
          if (bandera === true) {
            readNotifications.push(noticeNotifications[i].notifications[y]);
          }
        }
      }
      setReadedNotices(readNotifications);
    }
  }, [data, selectedBtn, userData, dataCompleted]);

  function unReadedNotifications(type) {
    return type === 'request'
      ? requestNotifications.length === 0 || requestNotifications[0].length
        ? 0
        : requestNotifications.length
      : noticesNotifications.length === 0 || noticesNotifications[0].length
      ? 0
      : noticesNotifications.length;
  }
  function borderRequestNotification(notification) {
    if (notification.notificationDestinations[0].status.value_definition === '010') {
      return 'rgba(131, 180, 49, 0.6)';
    } else {
      return '#E9E9E9';
    }
  }
  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={height * 0.9}>
      <StyledGroupContainer>
        <StyledBadge
          badgeContent={unReadedNotifications('request')}
          background={
            unReadedNotifications('request') > 0
              ? selectedBtn === 'request'
                ? '#FFFFFF'
                : '#FF5555'
              : 'transparent'
          }
          color="success"
          bgcolor={
            unReadedNotifications('request') > 0
              ? selectedBtn === 'request'
                ? 'blue'
                : 'white'
              : 'transparent'
          }
          type={'request'}
          style={{ marginLeft: `3px` }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <StyledButton
            key={'request'}
            style={{
              backgroundColor: selectedBtn === 'request' && PALETTE_COLOR.MAIN_BLUE,
              color: selectedBtn === 'request' && 'white',
              width: '100%',
            }}
            onClick={() => {
              setSelectedBtn('request');
            }}
          >
            {i18next.t('NotificationsList.requests')}
          </StyledButton>
        </StyledBadge>
        <StyledBadge
          badgeContent={unReadedNotifications('notices')}
          color="success"
          type={'notices'}
          bgcolor={
            unReadedNotifications('notices') > 0
              ? selectedBtn === 'notices'
                ? 'blue'
                : 'white'
              : 'transparent'
          }
          background={
            unReadedNotifications('notices') > 0
              ? selectedBtn === 'notices'
                ? '#FFFFFF'
                : '#FF5555'
              : 'transparent'
          }
          style={{ marginLeft: `3px` }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <StyledButton
            key={'notices'}
            style={{
              backgroundColor: selectedBtn === 'notices' && PALETTE_COLOR.MAIN_BLUE,
              color: selectedBtn === 'notices' && 'white',
              width: '100%',
            }}
            onClick={() => {
              setSelectedBtn('notices');
            }}
          >
            {i18next.t('NotificationsList.notices')}
          </StyledButton>
        </StyledBadge>
      </StyledGroupContainer>
      <NotificationsWrapper>
        {loading ? (
          <AppComponentLoading style={{ width: 'auto' }} />
        ) : (
          notifications.map((item, index) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }} key={index}>
                <DayLabelWrapper>
                  <DayWrapper>{moment.utc(item.date).calendar()}</DayWrapper>
                </DayLabelWrapper>
                {item.notifications.map((notification, index) => {
                  if (JSON.parse(notification.jsonDynamicConfig) !== null) {
                    const icon =
                      JSON.parse(notification.jsonDynamicConfig).icon !== undefined
                        ? JSON.parse(notification.jsonDynamicConfig).icon
                        : JSON.parse(notification.jsonDynamicConfig).styleObj.icon;

                    const avatarConfig =
                      JSON.parse(notification.jsonDynamicConfig).userNote !== undefined
                        ? JSON.parse(notification.jsonDynamicConfig).userNote.avatar_config
                        : null;

                    const photo_url =
                      JSON.parse(notification.jsonDynamicConfig).userNote !== undefined
                        ? JSON.parse(notification.jsonDynamicConfig).userNote.photo_url
                        : null;

                    return (
                      <NotificationWrapper
                        key={index}
                        color={
                          selectedBtn === 'notices'
                            ? notification.notificationDestinations[0].status.value_definition ===
                              '010'
                              ? 'rgba(131, 180, 49, 0.6)'
                              : '#E9E9E9'
                            : borderRequestNotification(notification)
                        }
                      >
                        <ImageWrapper src={obtainIcon(icon)} alt={icon} />
                        <NotificationBodyWrapper>
                          <TimeWrapper>
                            {moment(notification.timeStamp).format('HH:mm A')}
                          </TimeWrapper>
                          <TitleWrapper
                            style={{ color: JSON.parse(notification.jsonDynamicConfig).titleColor }}
                          >
                            {notification.title}
                          </TitleWrapper>
                          <Performer notification={notification} />
                          {avatarConfig !== null || photo_url != null ? (
                            <EmployeeNotes
                              data={JSON.parse(notification.jsonDynamicConfig).userNote}
                              note={JSON.parse(notification.jsonDynamicConfig).note}
                              avatarConfig={avatarConfig}
                              photo_url={photo_url}
                            />
                          ) : (
                            <Shift notification={notification} />
                          )}

                          <Reason notification={notification} />
                          <Details notification={notification} />
                          <ActionsButtons
                            notification={notification}
                            handleClickCloseNotificationsModal={handleClickCloseNotificationsModal}
                          />
                        </NotificationBodyWrapper>
                      </NotificationWrapper>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          })
        )}
        {/************************LOAD MORE************************** */}
        {total !== data.length ? (
          <LoadMoreWrapper onClick={() => setLoadingMore(true)}>
            {loadingMore ? (
              <AppComponentLoading style={{ width: 'auto' }} />
            ) : (
              <div>{i18next.t('NotificationsList.load_more')}</div>
            )}
          </LoadMoreWrapper>
        ) : null}
      </NotificationsWrapper>
    </Scrollbars>
  );
};

const Performer = ({ notification }) => {
  const getUserInvolved = () => {
    if (notification.performer) {
      return notification.performer;
    }
    if (['050', '060'].includes(notification.type.value_definition)) {
      return notification.userShift.user;
    } else if (['080'].includes(notification.type.value_definition)) {
      return notification.performer;
    }
  };

  const calcLabel = () => {
    if (
      notification.title.includes('by Employee') ||
      notification.title.includes('Request') ||
      notification.title.includes('Solicitud')
    ) {
      return (
        <PerformerNameWrapper>
          {i18next.t('NotificationsList.by')}{' '}
          <PerformerNameDetailWrapper>{getUserInvolved().name}</PerformerNameDetailWrapper>
        </PerformerNameWrapper>
      );
    }
    if (
      notification.title.includes('Employee is running late') ||
      notification.title.includes('Employee shift is unsigned') ||
      notification.title.includes('Empleado llegará tarde') ||
      notification.title.includes('Turno pendiente de aprobación')
    ) {
      return (
        <PerformerNameWrapper>
          <PerformerNameDetailWrapper>{getUserInvolved()?.name}</PerformerNameDetailWrapper>
        </PerformerNameWrapper>
      );
    }
    if (notification.title.includes('about employee')) {
      return (
        <PerformerNameWrapper>
          {i18next.t('NotificationsList.for')}{' '}
          <PerformerNameDetailWrapper>{getUserInvolved().name}</PerformerNameDetailWrapper>
        </PerformerNameWrapper>
      );
    }
  };

  return (
    <>
      {getUserInvolved()?.avatar_config ? (
        <PerformerWrapper>
          {(notification.performer ||
            notification.type.value_definition === '050' ||
            notification.type.value_definition === '080') && (
            <AppAvatar
              avatarId={JSON.parse(getUserInvolved().avatar_config)?.avatarId}
              bgColor={JSON.parse(getUserInvolved().avatar_config)?.bgColor}
              gender={JSON.parse(getUserInvolved().avatar_config)?.gender}
              skinColor={JSON.parse(getUserInvolved().avatar_config)?.skinColor}
              width={30}
              height={30}
            />
          )}
          {calcLabel()}
        </PerformerWrapper>
      ) : getUserInvolved()?.photo_url ? (
        <PerformerWrapper>
          {(notification.performer ||
            notification.type.value_definition === '050' ||
            notification.type.value_definition === '080') && (
            <Icon
              src={getUserInvolved()?.photo_url}
              style={{
                width: 30,
                height: 30,
                display: 'flex',
              }}
            />
          )}
          {calcLabel()}
        </PerformerWrapper>
      ) : (
        // For notifications which does not show user avatar /photo
        <></>
      )}
    </>
  );
};

const DetailsWrapper = styled.div`
  font-weight: 300;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  margin-top: 8px;
  margin-bottom: 8px;
`;
const Details = ({ notification }) => {
  if (!notification.detail) return null;
  return <DetailsWrapper dangerouslySetInnerHTML={{ __html: notification.detail }} />;
};
const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin-top: 8px;
`;

const Reason = ({ notification }) => {
  if (notification?.userShift?.decline_reason) {
    return (
      <ReasonWrapper>
        <div style={{ fontWeight: 600 }}>{i18next.t('NotificationsList.reason')}</div>
        <div style={{ fontWeight: 400 }}>{notification?.userShift?.decline_reason}</div>
      </ReasonWrapper>
    );
  } else return null;
};

const ShiftWrapper = styled.div`
  border-radius: 16px;
  padding: 13px 20px 13px 20px;
  margin-top: 5px;
  width: 280px;
  display: flex;
  flex-direction: column;
`;

const ShiftRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
`;

const ShiftRowLigthWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #6d737b;
  font-weight: 300;
  font-size: 12px;
`;

const Shift = ({ notification }) => {
  if (!notification.shift && !notification.userShift) return null;
  const shift = notification.shift ? notification.shift : notification.userShift.shift;
  return (
    <ShiftWrapper
      style={{ backgroundColor: shift.site ? shift.site.organization_id.color : '#E7ECF4' }}
    >
      <ShiftRowWrapper>
        <div title={shift.title}>{`${
          shift.title?.length > 28 ? shift.title.substring(0, 28) + '...' : shift.title
        }`}</div>
        <div>{ShiftTimes(shift)}</div>
      </ShiftRowWrapper>
      <ShiftRowLigthWrapper>
        <div>{shift.site && shift.site.name}</div>
        <div>{ShiftDate(shift)}</div>
      </ShiftRowLigthWrapper>
    </ShiftWrapper>
  );
};

const NoteWrapper = styled.div`
  display: flex;
  width: 280px;
  background-color: #f1f5fd;
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 10px;
`;

const AvatarWrapper = styled.div`
  flex-direction: column;
  flex: 0 0 40px;
  margin-right: 10px;
`;

const ContentWrapper = styled.div`
  flex-direction: column;
  flex: 1;
`;

const AuthorDateText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const SubjectText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

const NoteText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

const EmployeeNotes = ({ data, note, avatarConfig, photo_url }) => {
  return (
    <NoteWrapper>
      {/* Avatar */}
      {
        <AvatarWrapper>
          {avatarConfig ? (
            <AppAvatar
              avatarId={JSON.parse(avatarConfig)?.avatarId}
              bgColor={JSON.parse(avatarConfig)?.bgColor}
              gender={JSON.parse(avatarConfig)?.gender}
              skinColor={JSON.parse(avatarConfig)?.skinColor}
              width={32}
              height={32}
            />
          ) : photo_url ? (
            <Icon
              src={photo_url}
              style={{
                width: 32,
                height: 32,
                display: 'flex',
              }}
            />
          ) : (
            <Icon
              src={avatar}
              style={{
                width: 32,
                height: 32,
                display: 'flex',
              }}
            />
          )}
        </AvatarWrapper>
      }
      {/* Content */}
      <ContentWrapper>
        <AuthorDateText>{` ${i18next.t('by_low')} ${data.name}`}</AuthorDateText>
        <SubjectText>{note.subject}</SubjectText>
        <NoteText>{note.note}</NoteText>
      </ContentWrapper>
    </NoteWrapper>
  );
};

const ActionsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 16px;
  gap: 6px;
  width: 93px;
  height: 32px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 20px;
`;

const declineJobTitleConfig = {
  buttonText: `${i18next.t('buttons.btn_decline')}`,
  actionIcon: ico_decline,
  title: `${i18next.t('NotificationsList.decline_job')}`,
  bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
  bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
  borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const approveJobTitleConfig = {
  buttonText: `${i18next.t('buttons.btn_approve')}`,
  actionIcon: ico_check,
  title: `${i18next.t('NotificationsList.approve_job')}`,
  bgcolor: PALETTE_COLOR.ACCENT_GREEN,
  bordercolor: PALETTE_COLOR.ACCENT_GREEN,
  borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const declineShiftRequestConfig = {
  buttonText: `${i18next.t('buttons.btn_decline')}`,
  actionIcon: ico_decline,
  title: `${i18next.t('NotificationsList.decline_shift')}`,
  subtitle: ``,
  bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
  bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
  borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const cancelShiftRequestConfig = {
  buttonText: `${i18next.t('buttons.btn_discard')}`,
  actionIcon: ico_cancel,
  title: `${i18next.t('NotificationsList.discard_shift')}`,
  subtitle: `${i18next.t('NotificationsList.discard_shift_subtitle')}`,
  bgcolor: PALETTE_COLOR.ACCENT_RED,
  bordercolor: PALETTE_COLOR.ACCENT_RED,
  borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const approveShiftRequestConfig = {
  buttonText: `${i18next.t('buttons.btn_approve')}`,
  actionIcon: ico_check,
  title: `${i18next.t('NotificationsList.approve_shift')}`,
  subtitle: ``,
  bgcolor: PALETTE_COLOR.ACCENT_GREEN,
  bordercolor: PALETTE_COLOR.ACCENT_GREEN,
  borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
  boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
};

const ActionsButtons = ({ notification, handleClickCloseNotificationsModal }) => {
  const [loading, setLoading] = React.useState(false);
  const { userData } = useSelector((state) => state.app);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [notificacionType, setNotificacionType] = useState({});
  const { valuesDefinitions } = useSelector((state) => state.app);
  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const shift = notification.shift ? notification.shift : notification.userShift?.shift;
  const requestStatusApprove = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_SHIFT_STATUS' && v.value_definition === '040',
  )[0]; //Approved
  const requestStatusDecline = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_SHIFT_STATUS' && v.value_definition === '060',
  )[0]; //Declined
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  const handleOpenNotificationModal = (type) => {
    setType(type);
    switch (type) {
      case 'ApproveJT':
        setNotificacionType(approveJobTitleConfig);
        break;
      case 'DeclineJT':
        setNotificacionType(declineJobTitleConfig);
        break;
      case 'ApproveSR':
        setNotificacionType(approveShiftRequestConfig);
        break;
      case 'DeclineSR':
        setNotificacionType(declineShiftRequestConfig);
        break;
      case 'CancelSR':
        setNotificacionType(cancelShiftRequestConfig);
        break;

      default:
        break;
    }

    setOpenRemoveModal(true);
  };

  const handleCloseNotificationModal = () => {
    setOpenRemoveModal(false);
  };

  const processAction = () => {
    setLoading(true);
    editEntityData(`${NOTIFICATIONS_API}notifications/readed`, {
      notification: notification.notificationDestinations,
    })
      .then((res) => {
        switch (type) {
          case 'ApproveJT':
          case 'DeclineJT':
            editEntityData(`${USERS_API}jobtitle/approve`, {
              idNotificacion: notification.id,
              type:
                type === 'ApproveJT'
                  ? 'Approve' // Do not translate
                  : 'Decline', // Do not translate
            })
              .then((res) => {
                showMessage({
                  show: true,
                  type: 'success',
                  title: `${i18next.t('NotificationsList.job_title_request')} ${
                    type === 'ApproveJT'
                      ? `${i18next.t('buttons.btn_approve')}`
                      : `${i18next.t('buttons.btn_decline')}`
                  }`,
                  message: '',
                });
                handleCloseNotificationModal();
                handleClickCloseNotificationsModal(false);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                showMessage({
                  show: true,
                  type: 'error',
                  title: `${i18next.t('NotificationsList.error_job_title_request')}`,
                  message: '',
                });
                console.log('Job Title Change Request Error = ', err);
              });
            break;
          case 'ApproveSR':
          case 'DeclineSR':
            editEntityData(`${USER_SHIFTS_API}${notification.userShift.id}`, {
              status: type === 'ApproveSR' ? requestStatusApprove : requestStatusDecline,
            })
              .then((res) => {
                showMessage({
                  show: true,
                  type: 'success',
                  title: `${i18next.t('NotificationsList.shift_updated')}`,
                  message: '',
                });
                handleCloseNotificationModal();
                handleClickCloseNotificationsModal(false);
                setLoading(false);
              })
              .catch((err) => {
                console.log(
                  '🚀 ~ file: AppNotificationList.js ~ line 525 ~ processAction ~ err',
                  err,
                );
                setLoading(false);
                showMessage({
                  show: true,
                  type: 'error',
                  title: `${i18next.t('NotificationsList.error_shift_updating')}`,
                  message: '',
                });
              });
            break;
          case 'CancelSR':
            deleteEntityData(`${SHIFTS_API}${shift.id}`)
              .then((res) => {
                showMessage({
                  show: true,
                  type: 'success',
                  title: `${i18next.t('NotificationsList.error_shift_removed')}`,
                  message: '',
                });
                handleCloseNotificationModal();
                handleClickCloseNotificationsModal(false);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                let mess = '';
                switch (err.response?.data?.statusCode) {
                  case 403:
                    mess = `${i18next.t('NotificationsList.insufficient_privileges')}`;
                    break;
                  case 400.1:
                  case 400.2:
                    mess = err.response?.data?.message;
                    break;
                  default:
                    mess = `${i18next.t('NotificationsList.error_shift_deleting')}`;
                    break;
                }

                showMessage({
                  show: true,
                  type: 'error',
                  title: mess,
                  message: '',
                });

                //Close modal
              });
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        console.log('🚀 ~ file: AppNotificationList.js ~ line 1072 ~ processAction ~ err', err);
        showMessage({
          show: true,
          type: 'error',
          title: `${i18next.t('NotificationsList.error_shift_updating')}`,
          message: '',
        });
      });
  };

  const bodyButtons = () => {
    switch (notification.type.value_definition) {
      case '020':
        if (userData.role?.value_definition === '020') return <></>; //to Site Manager
        switch (notification?.userJobTitle?.status?.value_definition) {
          case '020':
            return (
              <ActionsButtonsWrapper style={{ gap: 4 }}>
                <StatusWrapper
                  style={{
                    background: 'rgba(61, 215, 113, 0.16)',
                    color: '#8EC044',
                  }}
                >
                  {i18next.t('buttons.btn_approved')}
                </StatusWrapper>
              </ActionsButtonsWrapper>
            );
          case '040':
            return (
              <ActionsButtonsWrapper style={{ gap: 4 }}>
                <StatusWrapper
                  style={{
                    background: 'rgba(239, 64, 64, 0.16)',
                    color: '#EF4040',
                  }}
                >
                  {i18next.t('buttons.btn_declined')}
                </StatusWrapper>
              </ActionsButtonsWrapper>
            );
          default:
            if (userData.role?.value_definition === '040') return <></>;
            return (
              <ActionsButtonsWrapper>
                <AppButton
                  style={{
                    fontSize: 12,
                    width: 'auto',
                    height: 34,
                    fontWeight: 600,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  onClick={() => handleOpenNotificationModal('ApproveJT')}
                  title={i18next.t('buttons.btn_approve')}
                >
                  {i18next.t('buttons.btn_approve')}
                </AppButton>{' '}
                <AppButton
                  style={{
                    fontSize: 12,
                    width: 'auto',
                    height: 34,
                    fontWeight: 600,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.ACCENT_ORANGE}
                  bordercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                  bghovercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  onClick={() => handleOpenNotificationModal('DeclineJT')}
                  title={i18next.t('buttons.btn_decline')}
                >
                  {i18next.t('buttons.btn_decline')}
                </AppButton>
              </ActionsButtonsWrapper>
            );
        }

      case '030':
        if (userData.role?.value_definition !== '020') return <></>; //diferent to Site Manager
        switch (notification.userShift.status.value_definition) {
          case '030':
            if (userData.role?.value_definition !== '020') return <></>; //diferent to Site Manager
            return (
              <ActionsButtonsWrapper style={{ gap: 4 }}>
                <AppButton
                  style={{
                    fontSize: 12,
                    width: 'auto',
                    height: 34,
                    fontWeight: 600,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  onClick={() => handleOpenNotificationModal('ApproveSR')}
                  title={i18next.t('buttons.btn_approve')}
                >
                  {i18next.t('buttons.btn_approve')}
                </AppButton>{' '}
                <AppButton
                  style={{
                    fontSize: 12,
                    width: 'auto',
                    height: 34,
                    fontWeight: 600,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.ACCENT_ORANGE}
                  bordercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                  bghovercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  onClick={() => handleOpenNotificationModal('DeclineSR')}
                  title={i18next.t('buttons.btn_decline')}
                >
                  {i18next.t('buttons.btn_decline')}
                </AppButton>
                <AppButton
                  style={{
                    fontSize: 12,
                    width: 'auto',
                    height: 34,
                    fontWeight: 600,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={`rgba(131, 180, 49, 0.2)`}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.MAIN_BLUE}
                  size="lg"
                  onClick={() => handleOpenNotificationModal('CancelSR')}
                  title={i18next.t('buttons.btn_discard_shift')}
                >
                  {i18next.t('buttons.btn_discard')}
                </AppButton>
              </ActionsButtonsWrapper>
            );
          case '040':
            return (
              <ActionsButtonsWrapper style={{ gap: 4 }}>
                <StatusWrapper
                  style={{
                    background: 'rgba(61, 215, 113, 0.16)',
                    color: '#8EC044',
                  }}
                >
                  {i18next.t('buttons.btn_approved')}
                </StatusWrapper>
              </ActionsButtonsWrapper>
            );
          case '060':
            return (
              <ActionsButtonsWrapper style={{ gap: 4 }}>
                <StatusWrapper
                  style={{
                    background: 'rgba(239, 64, 64, 0.16)',
                    color: '#EF4040',
                  }}
                >
                  {i18next.t('buttons.btn_declined')}
                </StatusWrapper>
              </ActionsButtonsWrapper>
            );
          default:
            return null;
        }

      default:
        return null;
    }
  };

  return (
    <>
      {bodyButtons()}
      <AppModal
        open={openRemoveModal}
        handleclose={handleCloseNotificationModal}
        title={''}
        boxshadow={'none'}
        inside={1}
        width={'600px'}
      >
        <AppModalStatus
          actionIcon={notificacionType.actionIcon}
          title={notificacionType.title}
          subtitle={
            type?.includes('JT') ? (
              <>
                <p style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                  {notification.performer?.name}
                </p>
                <p style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                  <DetailsWrapper
                    fontSize="16px"
                    dangerouslySetInnerHTML={{ __html: notification.detail }}
                  />
                </p>
              </>
            ) : type?.includes('SR') ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                  {shift.title}
                </div>
                <div>
                  {type === 'ApproveSR' ? (
                    <>
                      {i18next.t('NotificationsList.shift_will_covered')}{' '}
                      <span
                        style={{ fontWeight: 'bold' }}
                      >{`${notification.performer?.name}`}</span>
                    </>
                  ) : type === 'DeclineSR' ? (
                    `${i18next.t('NotificationsList.shift_will_reopened')}`
                  ) : (
                    `${i18next.t('NotificationsList.shift_will_removed')}`
                  )}
                </div>
              </div>
            ) : (
              `${i18next.t('NotificationsList.other')}`
            )
          }
          bgcolor={notificacionType.bgcolor}
          bordercolor={notificacionType.bordercolor}
          borderhovercolor={notificacionType.borderhovercolor}
          bghovercolor={notificacionType.bghovercolor}
          boxShadow={notificacionType.boxShadow}
          buttonText={notificacionType.buttonText}
          isLoading={loading}
          buttonAction={processAction}
        />
      </AppModal>
    </>
  );
};
