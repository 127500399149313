import React, { useState, useEffect, useCallback } from 'react';
import {
  AppTable,
  IcArrow,
  AppButton,
  AppModal,
  AppDropDownCheckBoxSelect,
  InputForm,
  AppAvatar,
} from '@components';
//import { size } from 'lodash';
import ic_search from '@assets/icons/ic_search.svg';
import { setReloadTimeSheetAction } from '@redux/actions/appActions';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getEntityData } from '@services';
import { formatEspecial } from '@utils/dateFormatHelper';
import { TIME_SHEET_API, VALUES_DEFINITIONS_API, SITES_GROUPS_API } from '@services/CONSTANTS';
import ic_document from '@assets/icons/ic_document.svg';
import moment from 'moment';
import Collapse from '@mui/material/Collapse';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { PALETTE_COLOR } from 'config/CONSTANTS';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { SummaryBySite } from './SummaryBySite';
import ic_remove from '@assets/icons/ic_remove.svg';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import ic_advance_filter_active from '@assets/icons/ic_advance_filter_active.svg';
import ic_advance_filter from '@assets/icons/ic_advance_filter.svg';
import Badge from '@mui/material/Badge';
import { size } from 'lodash';
import * as XLSX from 'xlsx/xlsx.mjs';
import Avatar from '@assets/icons/ic_no_avatar.svg';
import { EmployeeDetails } from '../Day/EmployeeDetails';

import { useTranslation } from 'react-i18next';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
const MAIN_WHITE = PALETTE_COLOR.MAIN_WHITE;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 6,
    border: `2px solid ${MAIN_WHITE}`,
    padding: '0 4px',
    background: 'red',
  },
}));

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  background: ${(props) => (props.background ? props.background : '#A5CD69')};
  border-radius: 10px;
  padding: 8px 12px;
  color: #ffffff;
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  font-weight: 400;
  font-size: 14px;
`;

const HeaderRowWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  gap: 16px;
`;

const IconButtonWrapper = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

const FilterWrapper = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  background: #eff4fb;
  border-radius: 20px;
  color: ${MAIN_BLUE};
  height: 40px;
  padding: 10px 10px 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
`;

const IconWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const Edit = styled.img`
  width: 18px;
  height: 18px;
`;

const UserWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 300;
  font-size: 14px;
`;

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

const AdvanceFilterWrapper = styled(Collapse)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-top: 1px solid #edf1f8;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
  color: #1179bf;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 60px;
`;

const calcDuration = (mins) => {
  if (!mins) return '0h';
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}h ${minutes}m`;
};

const calcDurationHours = (mins) => {
  if (!mins) return 0;
  return Math.floor((mins / 60) * 100) / 100;
};

const AdvanceFilterWrapperInner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 4px;
  flex-wrap: wrap;
`;

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const SummaryTimeSheet = () => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(true);
  const { jobSchedulerGroup, reloadTimeSheet, timeSheetSelectedDates } = useSelector(
    (state) => state.app,
  );
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const dispatch = useDispatch();
  /*const jobs_types = useMemo(
    () =>
      valuesDefinitions &&
      valuesDefinitions.filter((v) => v.definitionType.definition_type === 'JOBS_TYPES'),
    [valuesDefinitions],
  );*/
  const [dateFilter, setDateFilter] = useState({
    start_date: moment(timeSheetSelectedDates.startDate).startOf('week').format(),
    end_date: moment(timeSheetSelectedDates.startDate).endOf('week').format(),
  });
  const [labelDatesListView, setLabelDatesListView] = useState(
    formatEspecial(
      moment(timeSheetSelectedDates.startDate).startOf('week').format('YYYY-MM-DD'),
      'MMM D',
    ) +
      ' - ' +
      formatEspecial(
        moment(timeSheetSelectedDates.startDate).endOf('week').format('YYYY-MM-DD'),
        'MMM D',
      ),
  );
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [siteFilterList, setSiteFilterList] = useState([]);
  const [filterDatesSummaryView, setFilterDatesSummaryView] = useState('Weekly');
  const [viewSummary, setViewSummary] = useState('By Sites');
  const [sitesList, setSitesList] = useState([]);
  const [totals, setTotals] = useState([]);
  const [advanceFilterColumns, setAvanceFilterColumns] = useState([]);
  const [openSummaryDetails, setOpenSummaryDetails] = useState(false);
  //Store site selected
  const [siteSelected, setSiteSelected] = useState();
  const [employeeSelected, setEmployeeSelected] = React.useState({});
  const [openEmployeeDetails, setOpenEmployeeDetails] = React.useState(false);

  const handleEmployeeDetails = useCallback(() => {
    setOpenEmployeeDetails(!openEmployeeDetails);
  }, [openEmployeeDetails, setOpenEmployeeDetails]);

  /***
   * Build obj for EmployeeDetails component
   */
  const mapRowEmployee = (row) => {
    return {
      ...row?.rows[0],
      user: {
        id: row.UserID,
        name: row.user_name,
        avatar_config: row.user_avatar_config,
        photo_url: row.user_photo_url,
      },
    };
  };

  const handleShowAdvanceFilter = () => {
    setShowAdvanceFilter((prev) => !prev);
  };
  const handleSummaryDetails = () => {
    setOpenSummaryDetails(!openSummaryDetails);
  };

  const onFilterChange = (newSelectedOptions, event, selectedValues) => {
    if (event.action === 'select-option') {
      if (event.option.value.includes('*')) {
        handleAllSelections(event);
      } else {
        setSelectedFilters(selectedValues);
      }
    }
    if (event.action === 'deselect-option') {
      if (event.option.value.includes('*')) {
        handleAllSelections(event);
      } else {
        setSelectedFilters(
          selectedFilters.filter(
            (f) => f.filter === event.option.filter && f.value !== event.option.value,
          ),
        );
      }
    }
  };

  const handleAllSelections = (event) => {
    //console.log('🚀 ~ file: AppTable.js ~ line 438 ~ handleAllSelections ~ event', event);
    let filter = event.option.filter;
    let options = advanceFilterColumns.filter((o) => o.dataField === filter);
    if (selectedFilters.filter((sf) => sf.filter === filter).length > 0) {
      //UNSELECT
      setSelectedFilters(selectedFilters.filter((sf) => sf.filter !== filter));
    } else {
      //SELECT
      if (event.option.targetSelection && event.option.targetSelection.length > 0) {
        let fullArray = [
          ...selectedFilters.filter((sf) => sf.filter !== filter),
          ...options[0].advanceFilter
            .map((o) => ({ ...o, filter: filter }))
            .filter((o) => event.option.targetSelection.includes(o.label)),
          ...[event.option],
        ];
        setSelectedFilters(fullArray);
      } else {
        let fullArray = [
          ...selectedFilters.filter((sf) => sf.filter !== filter),
          ...options[0].advanceFilter.map((o) => ({ ...o, filter: filter })),
          ...[event.option],
        ];
        setSelectedFilters(fullArray);
      }
    }
  };

  const exportDataXlsx = () => {
    let report = [];
    if (sitesList.length > 0) {
      if (viewSummary === 'By Employees') {
        report.push([
          '',
          '',
          '',
          '',
          '',
          calcDurationHours(totals[0]?.onSiteDuration),
          calcDurationHours(totals[0]?.breakDuration),
          calcDurationHours(totals[0]?.paidDuration),
          calcDurationHours(totals[0]?.regularDuration),
          calcDurationHours(totals[0]?.overtimeDuration),
        ]);
        report.push([
          'FIRST NAME',
          'LAST NAME',
          'EMP ID',
          'JOB TITLE',
          'Site',
          'TOTAL ON-SITE HOURS',
          'TOTAL BREAK HOURS',
          'TOTAL PAID HOURS',
          'PAID REGULAR HOURS',
          'PAID OT HOURS',
          'Period',
        ]);
      } else {
        report.push([
          '',
          calcDurationHours(totals[0]?.onSiteDuration),
          calcDurationHours(totals[0]?.breakDuration),
          calcDurationHours(totals[0]?.paidDuration),
          calcDurationHours(totals[0]?.regularDuration),
          calcDurationHours(totals[0]?.overtimeDuration),
        ]);
        report.push([
          'Site',
          'TOTAL ON-SITE HOURS',
          'TOTAL BREAK HOURS',
          'TOTAL PAID HOURS',
          'PAID REGULAR HOURS',
          'PAID OT HOURS',
          'Period',
        ]);
      }
      sitesList.forEach((dataShift, i) => {
        if (viewSummary === 'By Employees') {
          let names = dataShift.user_name.split(' ');
          dataShift.rows.forEach((timeSheet) => {
            let site = timeSheet.site_name;
            let onSiteDuration = calcDurationHours(timeSheet.onSiteDuration);
            let breakDuration = calcDurationHours(timeSheet.breakDuration);
            let paidDuration = calcDurationHours(timeSheet.paidDuration);
            let regularDuration = calcDurationHours(timeSheet.regularDuration);
            let overtimeDuration = calcDurationHours(timeSheet.overtimeDuration);
            report.push([
              names[0],
              names[1],
              dataShift.user_employee_id,
              dataShift.job_title,
              site,
              onSiteDuration,
              breakDuration,
              paidDuration,
              regularDuration,
              overtimeDuration,
              moment(dateFilter.start_date).format('MM/DD/YYYY') +
                ' - ' +
                moment(dateFilter.end_date).format('MM/DD/YYYY'),
            ]);
          });
        } else {
          let site_name = dataShift.site_name;
          let onSiteDuration = calcDurationHours(dataShift.onSiteDuration);
          let breakDuration = calcDurationHours(dataShift.breakDuration);
          let paidDuration = calcDurationHours(dataShift.paidDuration);
          let regularDuration = calcDurationHours(dataShift.regularDuration);
          let overtimeDuration = calcDurationHours(dataShift.overtimeDuration);
          report.push([
            site_name,
            onSiteDuration,
            breakDuration,
            paidDuration,
            regularDuration,
            overtimeDuration,
            moment(dateFilter.start_date).format('MM/DD/YYYY') +
              ' - ' +
              moment(dateFilter.end_date).format('MM/DD/YYYY'),
          ]);
        }
      });
    }

    let wp = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(report);
    ws['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    console.log(ws);

    ws['A1'].s = {
      font: { bold: true },
    };
    ws['B1'].s = {
      font: { bold: true },
    };
    console.log(ws);
    XLSX.utils.book_append_sheet(wp, ws, 'Summay');
    XLSX.writeFile(wp, 'Summary.xlsx');
  };

  const filters = React.useMemo(() => {
    return [
      {
        field: 'innerGroup.id',
        operator: CondOperator.IN,
        value: jobSchedulerGroup.id,
      },
      {
        field: 'shiftDate',
        operator: CondOperator.BETWEEN,
        value: [
          moment(dateFilter.start_date).format('YYYY-MM-DD'),
          moment(dateFilter.end_date).format('YYYY-MM-DD'),
        ],
      },
    ];
  }, [jobSchedulerGroup.id, dateFilter]);

  /*const [filters, setFilters] = useState([
    {
      field: 'innerGroup.id',
      operator: CondOperator.IN,
      value: jobSchedulerGroup.id,
    },
    {
      field: 'shiftDate',
      operator: CondOperator.BETWEEN,
      value: [moment().startOf('month').format(), moment().endOf('month').format()],
    },
  ]);*/
  /*const advanceColumns = () => {
    if (viewSummary === 'By Sites') {
      return getSiteColumns.filter((c) => c.advanceFilter !== undefined);
    } else {
      return getEmployeeColumns.filter((c) => c.advanceFilter !== undefined);
    }
  };*/

  useEffect(() => {
    if (size(siteFilterList) > 0) {
      const advanceFilter = [
        {
          dataField: 'site_id',
          text: `${t('table_columns.site')}`,
          advanceFilter: siteFilterList.map((s) => ({
            label: s.site.name,
            value: s.site.name,
            filter: 'site',
            values: s.site.id,
            codOperator: '$eq',
          })),
        },
        {
          dataField: 'status.value_definition',
          text: `${t('table_columns.job_title')}`,
          advanceFilter: jobTitlesList.map((s) => ({
            label: s.description,
            value: s.description,
            filter: 'jobtitle',
            values: s.value_definition_id,
            codOperator: '$eq',
          })),
        },
      ];
      setAvanceFilterColumns(advanceFilter);
    }
  }, [siteFilterList, jobTitlesList, t]);

  const getSiteColumns = [
    {
      dataField: 'name',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: true,
      headerStyle: { width: '200px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        return (
          <StyledNestedCell
            style={{
              backgroundColor: row.color,
              display: 'block',
              paddingTop: '13px',
            }}
          >
            {row.site_name}
          </StyledNestedCell>
        );
      },
    },
    {
      dataField: 'onSiteDuration',
      text: `${t('table_columns.on_site_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: '14px 23px 14px 23px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{calcDuration(row.onSiteDuration)}</StyledLabel>;
      },
    },
    {
      dataField: 'breakDuration',
      text: `${t('table_columns.break_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      style: { height: '54px', padding: 0 },
      headerStyle: { width: '100px' },
      formatter: (cellContent, row) => {
        return <StyledLabel> {calcDuration(row.breakDuration)}</StyledLabel>;
      },
    },
    {
      dataField: 'paid_hours',
      text: `${t('table_columns.paid_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel style={{ fontWeight: 'bold' }}>
            {' '}
            {calcDuration(row.paidDuration)}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'paid_regular_hours',
      text: `${t('table_columns.paid_regular_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel style={{ fontWeight: 'bold' }}>
            {' '}
            {calcDuration(row.regularDuration)}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'Paid Overtime',
      text: `${t('table_columns.paid_overtime_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel style={{ fontWeight: 'bold' }}>
            {' '}
            {calcDuration(row.overtimeDuration)}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'details',
      text: `${t('table_columns.details')}`,
      sort: false,
      isDummyField: true,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '6%' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel
            style={{
              marginLeft: '0px',
              justifyContent: 'center',
              alignitems: 'center',
            }}
          >
            <Tooltip title={`Details`}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleSummaryDetails();
                  setSiteSelected(row);
                }}
              >
                <Edit style={{ cursor: 'pointer' }} src={ic_document} alt="logo"></Edit>
              </IconButton>
            </Tooltip>
          </StyledLabel>
        );
      },
    },
  ];

  useEffect(() => {
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'description', order: 'ASC' })
      .select(['definition_type_id', 'description'])
      .setFilter({
        field: 'definitionType.definition_type',
        operator: CondOperator.EQUALS,
        value: 'JOBS_TYPES',
      });
    getEntityData(VALUES_DEFINITIONS_API, qb.query())
      .then((res) => {
        setJobTitlesList(res);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
  }, []);

  useEffect(() => {
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'sites.name', order: 'ASC' })
      .select(['site.name', 'site.id'])
      .setJoin([{ field: 'group' }, { field: 'site' }])
      .setFilter({
        field: 'groups.id',
        operator: CondOperator.EQUALS,
        value: jobSchedulerGroup.id,
      });

    getEntityData(SITES_GROUPS_API, qb.query())
      .then((res) => {
        setSiteFilterList(res);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
  }, [jobSchedulerGroup]);

  const getEmployeeColumns = [
    {
      dataField: 'userShift.user.name',
      text: `${t('table_columns.employee')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '150px' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        const avatarConfig = row.user_avatar_config ? JSON.parse(row.user_avatar_config) : null;
        return (
          <UserWrapper>
            {avatarConfig ? (
              <AppAvatar
                avatarId={avatarConfig.avatarId}
                bgColor={avatarConfig.bgColor}
                gender={avatarConfig.gender}
                skinColor={avatarConfig.skinColor}
                width={40}
                height={40}
              />
            ) : row?.user_photo_url ? (
              <Icon src={row?.user_photo_url} alt="photo" />
            ) : (
              <Icon src={Avatar} alt="avatar" />
            )}
            <div>{row.user_name}</div>
          </UserWrapper>
        );
      },
    },
    {
      dataField: 'name',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: true,
      headerStyle: { width: '120px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.site_id ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: orgSite.color ? orgSite.color : '#e7e9eb',
                    display: 'flex',
                    margin: '3px',
                  }}
                >
                  {orgSite.site_name}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('status_types.No_Site')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('status_types.No_Site')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'onSiteDuration',
      text: `${t('table_columns.on_site_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      style: { height: '54px', padding: 0 },
      headerStyle: { width: '100px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.breakDuration ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {calcDuration(orgSite.onSiteDuration)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {'--'}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <div style={{ borderTop: 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                {'--'}
              </StyledNestedCell>
            </div>
          );
        }
      },
    },
    {
      dataField: 'breakDuration',
      text: `${t('table_columns.break_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      style: { height: '54px', padding: 0 },
      headerStyle: { width: '100px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.breakDuration ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {calcDuration(orgSite.breakDuration)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {'--'}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <div style={{ borderTop: 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                {'--'}
              </StyledNestedCell>
            </div>
          );
        }
      },
    },
    {
      dataField: 'paid_hours',
      text: `${t('table_columns.paid_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.paidDuration ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {calcDuration(orgSite.paidDuration)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {'--'}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <div style={{ borderTop: 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                {'--'}
              </StyledNestedCell>
            </div>
          );
        }
      },
    },
    {
      dataField: 'paid_regular_hours',
      text: `${t('table_columns.paid_regular_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: false,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.regularDuration ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {calcDuration(orgSite.regularDuration)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {'--'}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <div style={{ borderTop: 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                {'--'}
              </StyledNestedCell>
            </div>
          );
        }
      },
    },
    {
      dataField: 'Paid Overtime',
      text: `${t('table_columns.paid_overtime_h')}`,
      sort: false,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '100px' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.overtimeDuration ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {calcDuration(orgSite.overtimeDuration)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
                >
                  {'--'}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <div style={{ borderTop: 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                {'--'}
              </StyledNestedCell>
            </div>
          );
        }
      },
    },
    {
      dataField: '',
      text: `${t('table_columns.details')}`,
      sort: false,
      isDummyField: true,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '6%' },
      style: { height: '54px', padding: 0 },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <StyledNestedCell
                style={{ backgroundColor: '#FFFFFF', display: 'flex', margin: '3px' }}
              >
                <Tooltip title={`Details`}>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      handleEmployeeDetails();
                      setEmployeeSelected(mapRowEmployee(row));
                    }}
                  >
                    <Edit style={{ cursor: 'pointer' }} src={ic_document} alt="logo"></Edit>
                  </IconButton>
                </Tooltip>
              </StyledNestedCell>
            </div>
          ));
        }
      },
    },
  ];

  const reloadTimeSheets = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadTimeSheetAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Load sites
   */
  useEffect(() => {
    setSitesList([]);
    if (jobSchedulerGroup !== {} && jobSchedulerGroup.id) {
      setLoadingData(true);
      let filters_advance = 'true';
      if (size(selectedFilters) > 0) {
        filters_advance = JSON.stringify(selectedFilters);
      }
      const qb2 = RequestQueryBuilder.create();
      let url = '';
      if (viewSummary === 'By Sites') {
        url = `${TIME_SHEET_API}query/timeSheets/${jobSchedulerGroup.id}/${dateFilter.start_date}/${dateFilter.end_date}/${filters_advance}`;
      } else {
        url = `${TIME_SHEET_API}query/timeSheetsByEmployee/${jobSchedulerGroup.id}/${dateFilter.start_date}/${dateFilter.end_date}/${filters_advance}`;
      }
      getEntityData(
        // `${TIME_SHEET_API}query/timeSheets/${jobSchedulerGroup.id}/${dateFilter.start_date}/${dateFilter.end_date}/${debouncedSearchTerm}/${filters_advance}`,
        url,
        qb2.query(),
      )
        .then((res) => {
          reloadTimeSheets(false);
          setLoadingData(false);
          setSitesList(res.data);
          setTotals(res.totals);
        })
        .catch((err) => {
          reloadTimeSheets(false);
          setLoadingData(false);
          console.log('axios err=', err);
        });
    }
  }, [
    viewSummary,
    filters,
    jobSchedulerGroup,
    dateFilter,
    debouncedSearchTerm,
    selectedFilters,
    reloadTimeSheets,
    reloadTimeSheet,
  ]);

  /***
   * Load sites
   */
  /*useEffect(() => {
    setFilters([
      {
        field: 'innerGroup.id',
        operator: CondOperator.IN,
        value: jobSchedulerGroup.id,
      },
      {
        field: 'shiftDate',
        operator: CondOperator.BETWEEN,
        value: [
          moment(dateFilter.start_date).format('YYYY-MM-DD'),
          moment(dateFilter.end_date).format('YYYY-MM-DD'),
        ],
      },
    ]);
  }, [jobSchedulerGroup.id, dateFilter]);*/

  const generateFilterDates = (view) => {
    switch (view) {
      case 'Weekly':
        setDateFilter({
          start_date: moment().startOf('week').format('YYYY-MM-DD'),
          end_date: moment().endOf('week').format('YYYY-MM-DD'),
        });
        setLabelDatesListView(
          formatEspecial(moment().startOf('week').format('YYYY-MM-DD'), 'MMM D') +
            ' - ' +
            formatEspecial(moment().endOf('week').format('YYYY-MM-DD'), 'MMM D'),
        );
        break;
      default:
        break;
    }
  };

  const continueFilterDates = (action) => {
    let start = '';
    let end = '';
    switch (filterDatesSummaryView) {
      case 'Weekly':
        if (action === 'Add') {
          start = moment(dateFilter.start_date).add(7, 'day');
          end = moment(dateFilter.end_date).add(7, 'day');
        } else {
          start = moment(dateFilter.start_date).subtract(7, 'day');
          end = moment(dateFilter.end_date).subtract(7, 'day');
        }
        setDateFilter({
          start_date: start.format('YYYY-MM-DD'),
          end_date: end.format('YYYY-MM-DD'),
        });
        break;
      default:
        break;
    }
    setLabelDatesListView(
      formatEspecial(moment(start).format('YYYY-MM-DD'), 'MMM D') +
        ' - ' +
        formatEspecial(moment(end).format('YYYY-MM-DD'), 'MMM D'),
    );
  };

  /***
   * Current functionality
   */
  const getCurrentWeek = () => {
    setDateFilter({
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
    });
    setFilterDatesSummaryView('Weekly');
    generateFilterDates('Weekly');
  };

  return (
    <>
      {viewSummary === 'By Sites' ? (
        <AppTable
          key={'sites_table'}
          filters={filters}
          loading={loadingData}
          columns={getSiteColumns}
          withAdvanceFilter={false}
          withColumnToggler={false}
          allowOverFlowX={true}
          disablePagination={true}
          disableTopPagination={true}
          data={sitesList}
          reload={reloadTimeSheet}
          isSearchable={false}
          search_alter={
            <InputForm
              style={{ marginLeft: 15, marginRight: 15, width: '100%' }}
              height={'40px'}
              placeholder={t('placeholder_search')}
              icon={ic_search}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          }
          trailingBtn={
            <div style={{ display: 'flex', height: '40px' }}>
              {
                <AppButton
                  style={{ width: 'auto', height: 40 }}
                  type="submit"
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  onClick={exportDataXlsx}
                >
                  {t('buttons.btn_export')}
                </AppButton>
              }
            </div>
          }
          advanceFilterAlter={
            <AdvanceFilterWrapper in={showAdvanceFilter}>
              <AdvanceFilterWrapperInner>
                {advanceFilterColumns.map((c, index) => (
                  <>
                    <AppDropDownCheckBoxSelect
                      key={index}
                      showSelection={false}
                      options={c.advanceFilter.map((d) => ({
                        ...d,
                        filter: c.dataField,
                      }))}
                      isSearchable={false}
                      allowSelectAll={true}
                      height={300}
                      width={400}
                      onChange={onFilterChange}
                      selectAllControlOption={{
                        label: `${t('lbl_all')}`,
                        value: '*',
                        filter: c.dataField,
                      }}
                      defaultValue={selectedFilters}
                      targetComponent={
                        <AdvanceFilterButtonWrapper>
                          {c.text}
                          <ArrowWrapper
                            src={ic_arrow_down}
                            style={{ marginLeft: 5 }}
                            alt="IcArrowWrapper"
                          ></ArrowWrapper>
                        </AdvanceFilterButtonWrapper>
                      }
                    />
                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length >= c.advanceFilter.length ? (
                      <FilterWrapper>{t('lbl_all')}</FilterWrapper>
                    ) : null}

                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length < c.advanceFilter.length &&
                      selectedFilters
                        .filter((f) => f.filter === c.dataField && !f.value.includes('*'))
                        .map((filter, i) => (
                          <FilterWrapper key={i}>
                            {filter.label}
                            <IconButton
                              aria-label={t('AppTable.remove_filter')}
                              onClick={() => {
                                selectedFilters.splice(selectedFilters.indexOf(filter), 1);
                                setSelectedFilters([...selectedFilters]);
                              }}
                            >
                              <IconWrapper
                                style={{ width: 8, height: 8 }}
                                src={ic_remove}
                                alt="remove"
                              />
                            </IconButton>
                          </FilterWrapper>
                        ))}
                  </>
                ))}
                {selectedFilters.length > 0 ? (
                  <AdvanceFilterButtonWrapper
                    style={{ cursor: 'pointer', color: MAIN_BLUE }}
                    onClick={(e) => setSelectedFilters([])}
                  >
                    {t('AppTable.reset_all')}
                  </AdvanceFilterButtonWrapper>
                ) : null}
              </AdvanceFilterWrapperInner>
            </AdvanceFilterWrapper>
          }
          filtersAdditional={[
            {
              filter: (
                <Tooltip title={t('AppTable.advanced_filter')} placement="bottom">
                  <IconButtonWrapper
                    style={{ width: 40, height: 40 }}
                    aria-label={t('form_labels.organizations')}
                    onClick={handleShowAdvanceFilter}
                  >
                    <StyledBadge badgeContent={selectedFilters.length} color="success">
                      <IconWrapper
                        style={
                          showAdvanceFilter || selectedFilters.length > 0
                            ? { width: 30, height: 30 }
                            : {}
                        }
                        src={
                          showAdvanceFilter || selectedFilters.length > 0
                            ? ic_advance_filter_active
                            : ic_advance_filter
                        }
                        alt="Cap logo"
                      />
                    </StyledBadge>
                  </IconButtonWrapper>
                </Tooltip>
              ),
            },
            {
              filter: (
                <Menu
                  menuButton={
                    <AdvanceFilterButtonWrapper
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        width: 'max-content',
                        marginBottom: '15px',
                      }}
                    >
                      {t(`Timesheet.menu.${viewSummary.toLowerCase().replace(/\s/g, '_')}`)}
                      <IcArrow
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        status={'enabled'}
                        direction={'down'}
                      />
                    </AdvanceFilterButtonWrapper>
                  }
                >
                  <MenuItem
                    onClick={(e) => {
                      setViewSummary('By Sites');
                    }}
                  >
                    {t('Timesheet.menu.by_sites')}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setViewSummary('By Employees');
                    }}
                  >
                    {t('Timesheet.menu.by_employees')}
                  </MenuItem>
                </Menu>
              ),
            },
            {
              filter: (
                <Menu
                  menuButton={
                    <AdvanceFilterButtonWrapper
                      style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                    >
                      {t(`Timesheet.menu.${filterDatesSummaryView.toLowerCase().replace(/\s/g, '_')}`)}
                      <IcArrow
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                        status={'enabled'}
                        direction={'down'}
                      />
                    </AdvanceFilterButtonWrapper>
                  }
                >
                  <MenuItem
                    onClick={(e) => {
                      setFilterDatesSummaryView('Weekly');
                      generateFilterDates('Weekly');
                    }}
                  >
                    {t('Timesheet.menu.weekly')}
                  </MenuItem>
                  {/* <MenuItem
                    onClick={(e) => {
                      setFilterDatesSummaryView('Custom Dates');
                      generateFilterDates('Custom Dates');
                    }}
                  >
                    {t('Timesheet.menu.custom_dates')}
                  </MenuItem> */}
                </Menu>
              ),
            },
            {
              filter: (
                <PaginationTopButton>
                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    style={{ marginLeft: 2, marginRight: 2 }}
                    onClick={(e) => {
                      continueFilterDates('Less');
                    }}
                  >
                    <IcArrow style={{ height: 10, width: 5 }} direction="left" status={'enabled'} />
                  </IconButton>
                  <div style={{ color: '#1179BF', marginRight: 5 }}>{labelDatesListView}</div>
                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    onClick={(e) => {
                      continueFilterDates('Add');
                    }}
                  >
                    <IcArrow
                      style={{ height: 10, width: 5 }}
                      direction={'right'}
                      status={'enabled'}
                    />
                  </IconButton>
                </PaginationTopButton>
              ),
            },
            {
              filter: (
                <AppButton
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  style={{ marginRight: '-5px', marginLeft: '10px' }}
                  onClick={() => getCurrentWeek()}
                >
                  {t('buttons.btn_current')}
                </AppButton>
              ),
            },
          ]}
          headerRow={
            <HeaderRowWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: 0,
                  gap: 8,
                }}
              >
                <HeaderWrapper background={'#8989FF'}>
                  <div>{`${t('Timesheet.labels.on_site_total')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.onSiteDuration)}</span>
                </HeaderWrapper>
                <HeaderWrapper background={'#9AAEC8'}>
                  <div>{`${t('Timesheet.labels.break_total')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.breakDuration)}</span>
                </HeaderWrapper>
                <HeaderWrapper background={'#A5CD69'}>
                  <div>{`${t('Timesheet.labels.total_paid_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.paidDuration)}</span>
                </HeaderWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: 0,
                  gap: 8,
                }}
              >
                <HeaderWrapper background={'#8EC044'}>
                  <div>{`${t('Timesheet.labels.paid_regular_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals[0]?.regularDuration)}
                  </span>
                </HeaderWrapper>
                <HeaderWrapper background={'#FF9153'}>
                  <div>{`${t('Timesheet.labels.paid_overtime_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals[0]?.overtimeDuration)}
                  </span>
                </HeaderWrapper>
              </div>
            </HeaderRowWrapper>
          }
        />
      ) : (
        <AppTable
          key={'employees_table'}
          filters={filters}
          loading={loadingData}
          columns={getEmployeeColumns}
          withAdvanceFilter={false}
          withColumnToggler={false}
          allowOverFlowX={true}
          disablePagination={true}
          disableTopPagination={true}
          data={sitesList}
          isSearchable={false}
          search_alter={
            <InputForm
              style={{ marginLeft: 15, marginRight: 15, width: '100%' }}
              height={'40px'}
              placeholder={t('placeholder_search')}
              icon={ic_search}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          }
          trailingBtn={
            <div style={{ display: 'flex', height: '40px' }}>
              {
                <AppButton
                  style={{ width: 'auto', height: 40 }}
                  type="submit"
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  onClick={exportDataXlsx}
                >
                  {t('buttons.btn_export')}
                </AppButton>
              }
            </div>
          }
          advanceFilterAlter={
            <AdvanceFilterWrapper in={showAdvanceFilter}>
              <AdvanceFilterWrapperInner>
                {advanceFilterColumns.map((c, index) => (
                  <>
                    <AppDropDownCheckBoxSelect
                      key={index}
                      showSelection={false}
                      options={c.advanceFilter.map((d) => ({
                        ...d,
                        filter: c.dataField,
                      }))}
                      isSearchable={false}
                      allowSelectAll={true}
                      height={300}
                      width={400}
                      onChange={onFilterChange}
                      selectAllControlOption={{
                        label: `${t('lbl_all')}`,
                        value: '*',
                        filter: c.dataField,
                      }}
                      defaultValue={selectedFilters}
                      targetComponent={
                        <AdvanceFilterButtonWrapper>
                          {c.text}
                          <ArrowWrapper
                            src={ic_arrow_down}
                            style={{ marginLeft: 5 }}
                            alt="IcArrowWrapper"
                          ></ArrowWrapper>
                        </AdvanceFilterButtonWrapper>
                      }
                    />
                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length >= c.advanceFilter.length ? (
                      <FilterWrapper>{t('lbl_all')}</FilterWrapper>
                    ) : null}

                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length < c.advanceFilter.length &&
                      selectedFilters
                        .filter((f) => f.filter === c.dataField && !f.value.includes('*'))
                        .map((filter, i) => (
                          <FilterWrapper key={i}>
                            {filter.label}
                            <IconButton
                              aria-label={t('AppTable.remove_filter')}
                              onClick={() => {
                                selectedFilters.splice(selectedFilters.indexOf(filter), 1);
                                setSelectedFilters([...selectedFilters]);
                              }}
                            >
                              <IconWrapper
                                style={{ width: 8, height: 8 }}
                                src={ic_remove}
                                alt="remove"
                              />
                            </IconButton>
                          </FilterWrapper>
                        ))}
                  </>
                ))}
                {selectedFilters.length > 0 ? (
                  <AdvanceFilterButtonWrapper
                    style={{ cursor: 'pointer', color: MAIN_BLUE }}
                    onClick={(e) => setSelectedFilters([])}
                  >
                    {t('AppTable.reset_all')}
                  </AdvanceFilterButtonWrapper>
                ) : null}
              </AdvanceFilterWrapperInner>
            </AdvanceFilterWrapper>
          }
          filtersAdditional={[
            {
              filter: (
                <Tooltip title={t('AppTable.advanced_filter')} placement="bottom">
                  <IconButtonWrapper
                    style={{ width: 40, height: 40 }}
                    aria-label={t('form_labels.organizations')}
                    onClick={handleShowAdvanceFilter}
                  >
                    <StyledBadge badgeContent={selectedFilters.length} color="success">
                      <IconWrapper
                        style={
                          showAdvanceFilter || selectedFilters.length > 0
                            ? { width: 30, height: 30 }
                            : {}
                        }
                        src={
                          showAdvanceFilter || selectedFilters.length > 0
                            ? ic_advance_filter_active
                            : ic_advance_filter
                        }
                        alt="Cap logo"
                      />
                    </StyledBadge>
                  </IconButtonWrapper>
                </Tooltip>
              ),
            },
            {
              filter: (
                <Menu
                  menuButton={
                    <AdvanceFilterButtonWrapper
                      style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                    >
                      {t(`Timesheet.menu.${viewSummary.toLowerCase().replace(/\s/g, '_')}`)}
                      <IcArrow
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                        status={'enabled'}
                        direction={'down'}
                      />
                    </AdvanceFilterButtonWrapper>
                  }
                >
                  <MenuItem
                    onClick={(e) => {
                      setViewSummary('By Sites');
                    }}
                  >
                    {t('Timesheet.menu.by_sites')}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setViewSummary('By Employees');
                    }}
                  >
                    {t('Timesheet.menu.by_employees')}
                  </MenuItem>
                </Menu>
              ),
            },
            {
              filter: (
                <Menu
                  menuButton={
                    <AdvanceFilterButtonWrapper
                      style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                    >
                      {t(`Timesheet.menu.${filterDatesSummaryView.toLowerCase().replace(/\s/g, '_')}`)}
                      <IcArrow
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                        status={'enabled'}
                        direction={'down'}
                      />
                    </AdvanceFilterButtonWrapper>
                  }
                >
                  <MenuItem
                    onClick={(e) => {
                      setFilterDatesSummaryView('Weekly');
                      generateFilterDates('Weekly');
                    }}
                  >
                    {t('Timesheet.menu.weekly')}
                  </MenuItem>
                  {/* <MenuItem
                    onClick={(e) => {
                      setFilterDatesSummaryView('Custom Dates');
                      generateFilterDates('Custom Dates');
                    }}
                  >
                    {t('Timesheet.menu.custom_dates')}
                  </MenuItem> */}
                </Menu>
              ),
            },
            {
              filter: (
                <PaginationTopButton>
                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    style={{ marginLeft: 2, marginRight: 2 }}
                    onClick={(e) => {
                      continueFilterDates('Less');
                    }}
                  >
                    <IcArrow style={{ height: 10, width: 5 }} direction="left" status={'enabled'} />
                  </IconButton>
                  <div style={{ color: '#1179BF', marginRight: 5 }}>{labelDatesListView}</div>
                  <IconButton
                    aria-label={t('form_labels.job_scheduler')}
                    onClick={(e) => {
                      continueFilterDates('Add');
                    }}
                  >
                    <IcArrow
                      style={{ height: 10, width: 5 }}
                      direction={'right'}
                      status={'enabled'}
                    />
                  </IconButton>
                </PaginationTopButton>
              ),
            },
            {
              filter: (
                <AppButton
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  style={{ marginRight: '-5px', marginLeft: '10px' }}
                  onClick={() => getCurrentWeek()}
                >
                  {t('buttons.btn_current')}
                </AppButton>
              ),
            },
          ]}
          headerRow={
            <HeaderRowWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: 0,
                  gap: 8,
                }}
              >
                <HeaderWrapper background={'#8989FF'}>
                  <div>{`${t('Timesheet.labels.on_site_total')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.onSiteDuration)}</span>
                </HeaderWrapper>
                <HeaderWrapper background={'#9AAEC8'}>
                  <div>{`${t('Timesheet.labels.break_total')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.breakDuration)}</span>
                </HeaderWrapper>
                <HeaderWrapper background={'#A5CD69'}>
                  <div>{`${t('Timesheet.labels.total_paid_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>{calcDuration(totals[0]?.paidDuration)}</span>
                </HeaderWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: 0,
                  gap: 8,
                }}
              >
                <HeaderWrapper background={'#8EC044'}>
                  <div>{`${t('Timesheet.labels.paid_regular_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals[0]?.regularDuration)}
                  </span>
                </HeaderWrapper>
                <HeaderWrapper background={'#FF9153'}>
                  <div>{`${t('Timesheet.labels.paid_overtime_hours')}: `}</div>
                  <span style={{ fontWeight: 600 }}>
                    {calcDuration(totals[0]?.overtimeDuration)}
                  </span>
                </HeaderWrapper>
              </div>
            </HeaderRowWrapper>
          }
        />
      )}

      {/* Summary detais modal */}
      <AppModal
        open={openSummaryDetails}
        handleclose={handleSummaryDetails}
        isStacked={true}
        inside={0}
        boxshadow={'none'}
        styleModal={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          width: '100%',
          height: '90%',
        }}
      >
        <SummaryBySite
          site={siteSelected}
          handleClose={handleSummaryDetails}
          day={dateFilter.start_date}
        />
      </AppModal>

      {/* Employee details modal */}
      <AppModal
        open={openEmployeeDetails}
        handleclose={handleEmployeeDetails}
        isStacked={true}
        inside={0}
        boxshadow={'none'}
        styleModal={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          width: '100%',
          height: '95%',
        }}
      >
        <EmployeeDetails
          employee={employeeSelected}
          handleClose={handleEmployeeDetails}
          day={dateFilter.start_date}
        />
      </AppModal>
    </>
  );
};
