export const ROOT = '/';
export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const PAGE1 = '/page1';
export const AUTH_PAGE1 = '/authorized1';
export const JOB_SCHEDULER = '/jobscheduler/:type';
export const TIME_SHEET_LOBBY = '/timeSheetLobby';
export const TIME_SHEET = '/timeSheet/:type';
export const MESSAGE_CENTER = '/message-center/:type';
export const CONFLICS = '/conflics';
export const ORGANIZATIONS = '/organizations';
export const ORGANIZATIONS_MANAGEMENT = '/organizations-management/:type';
export const VIEW_ORGANIZATIONS = '/organizations/view/:organizationId';
export const VIEW_ORGANIZATIONS_USERS = '/users/organizationusers/view/:userId';
export const USERS = '/users/:type';
export const GROPUS = '/groups/:type';
export const VIEW_JOB_TITLES = '/job-titles/view/:jobTitleId';
export const VIEW_USER_ORGANIZATION = '/users/organization/view/:userId';
export const VIEW_USER_SYSTEM = '/users/system/view/:userId';
export const VIEW_GROUP = '/groups/view/:groupId';
export const VIEW_USER_EMPLOYEE = '/users/employee/view/:userId';
export const VIEW_SITE = '/sites/view/:siteId';
export const PROFILE = '/profile';
export const CHOOSEAVATAR = '/chooseavatar';
export const SITES = '/sites';
export const LOBBY = '/lobby';
export const SETTINGS = '/settings';
