import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppButton,
  InputForm,
  CustomizedSnackbar,
  AppDropDownSelect,
  AppSwitch,
  AppDropDownMultipleSelect,
  AppDropDownSingleSelect,
  AppCheckBox,
  AppDivButton,
  AppModal,
  AppModalStatus,
} from '@components';
import {
  saveEntityData,
  getEntityData,
  getCurrentSession,
  editEntityData,
  deleteEntityData,
} from '@services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { PALETTE_COLOR, SHIFT_EDIT, SHIFT_CREATION, SHIFT_REMOVE } from '@config/CONSTANTS';
import {
  USERS_API,
  VALUES_DEFINITIONS_API,
  SHIFTS_API,
  USER_SETTINGS_API,
} from 'services/CONSTANTS';
import { useSelector } from 'react-redux';
import { calcDot, SingleOption } from 'utils/coloredDot';
import moment from 'moment';
import { isEqual } from 'lodash';
import { MutipleCustomOption } from 'utils/avatar';
import { ShortTimeZone, ShiftFullTimeDifferences, getTimeZone } from 'utils/appUtils';
import DatePicker from '@mui/lab/DatePicker';
import PickersDay, { pickersDayClasses } from '@mui/lab/PickersDay';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useDispatch } from 'react-redux';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import Lottie from 'lottie-react';

import DPICon from '@assets/icons/ic_datepicker.svg';
import EllipseIcon from '@assets/icons/ic_grey_ellipse.svg';
import trash from '@assets/icons/trash.svg';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ico_update_publish from '@assets/icons/ico_update_publish.svg';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import OverlappingShift from '@assets/icons/OverlappingShift.svg';
import MinusCircleGray from '@assets/icons/MinusCircleGray.svg';
import MinusCircleBlue from '@assets/icons/MinusCircleBlue.svg';
import PlusCircleBlue from '@assets/icons/PlusCircleBlue.svg';
import ExclamationCircleBlue from '@assets/icons/ExclamationCircleBlue.svg';
import { calcAvatar } from 'utils/avatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;

const TzHeaderLabel = styled.div`
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;

const TzHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
`;

const TzHeaderValue = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

const StyleButtons = styled.div`
  padding-bottom: 20px;
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
`;

const HoursDiff = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const Zone = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${MAIN_BLUE};
  top: -8px;
  position: relative;
  cursor: pointer;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const DatePickerInput = styled(TextField)`
border: 1px solid #E7ECF4;
border-radius: 16px;
& label.Mui-focused {
  color: transparent;
 
}
& label {
  
  color: transparent
 
}
& .MuiInput-underline:after {
  border-bottom-color: white;
  
}
& .MuiOutlinedInput-root {
  & fieldset {
    border: 1px solid #E7ECF4;
    border-radius: 16px;
    
  }
  &:hover fieldset {
    border-color: #E7ECF4;
  }
  &.Mui-focused fieldset {
    border: 1px solid blue;
  }
}
&.MuiInputBase-root{
  padding: 0px;
  background-color: green;
  & .MuiButtonBase-root{
    padding: 0,
        padding-left: 10;
        background-color: gold;
  }
  & .MuiInputBase-input{
    padding: 15;
        padding-left: 0;
        background-color: cyan;
  }
}
`;

const TimeRangeInput = styled(TextField)`
  border: 1px solid #E7ECF4;
  border-radius: 16px;
  & label.Mui-focused {
    display: none;
  }
  & label {
    display: none;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #E7ECF4;
      border-radius: 50px;
    }
    &:hover fieldset {
      border-color: #E7ECF4;
    }
    &.Mui-focused fieldset {
      border: 1px solid blue;
    }
  }
  &.MuiInputBase-root{
    padding: 0px;
    background-color: green;
    & .MuiButtonBase-root{
      padding: 0,
          padding-left: 10;
          background-color: gold;
    }
    & .MuiInputBase-input{
      padding: 8;
      padding-left: 0;
      background-color: cyan;
    }
  }
`;

// const DivDraft = styled.div`
//   background: #e7f5ff;
//   width: 100%;
//   padding: 10px;
//   border-radius: 10px;
//   margin-top: 24px;
//   margin-bottom: 32px;
//   text-align: center;
//   .hours {
//     font-size: 13px;
//     font-weight: bold;
//   }
//   .title {
//     font-size: 12px;
//   }
//   .location {
//     font-size: 12px;
//     color: #878e97;
//   }
//   p {
//     margin-bottom: 0px;
//   }
// `;

const EditShiftDetail = styled.div`
  p {
    width: 400px;
    margin: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .borderTop {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .text {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    margin: 23px auto 23px auto;
    width: 150px;
    float: left;
  }
  .avatars {
    margin: 16px 8px 16px 8px;
    float: left;
  }
  .spots {
    font-size: 13px;
    float: left;
    border: 1px dashed rgba(109, 115, 123, 0.4);
    border-radius: 16px;
    padding: 5px 12px;
    margin: 16px 8px 16px 8px;
    font-weight: 400;
  }
  .borderBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const DivShiftsConflicts = styled.div`
  padding: 0 32px;
  font-size: 13px;
  max-height: 500px;
  h4 {
    color: ${PALETTE_COLOR.LIGHT_GREY};
    font-weight: 400;
    font-size: 13px;
  }
  .cont {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-bottom: 16px;
  }
  .col1 {
    width: 40%;
    float: left;
    color: ${PALETTE_COLOR.BLACK};
  }
  .col2 {
    width: 60%;
    float: left;
    .reason {
      border-radius: 10px;
      padding: 8px 16px;
      margin-bottom: 16px;
      .gray {
        color: ${PALETTE_COLOR.MIDDLE_GRAY};
      }
    }
    .reasonLabel {
      font-size: 12px;
      color: ${PALETTE_COLOR.ACCENT_RED_DARK};
      background: ${PALETTE_COLOR.ACCENT_RED_BACKGROUND};
      border-radius: 10px;
      width: 145px;
      margin-bottom: 6px;
      img {
        margin-right: 8px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
`;

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.98)',
    color: 'white',
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderRadius: 12,
    padding: '8px 16px 8px 16px',
    left: 0,
  },
  '& .MuiTooltip-arrow': {
    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0.98)',
    },
  },
}));

const emptyForm = {
  shift_date: null,
  start_time: null,
  end_time: null,
  title: '',
  enable_claim: false,
  open_spots: null,
  site: '',
  job_title: '',
  location: '',
  note: '',
  user_shifts: [],
  repeat_shift: false,
};

const requestCreateShiftC = {
  shift_date: '',
  start_time: '',
  end_time: '',
  enable_claim: '',
  open_spots: '',
  location: '',
  note: '',
  repeat_shift: '',
  status: {},
  organization: {},
  site: {},
  group: {},
  job_title: [],
  user_shifts: [],
};

export const ShiftForm = ({
  handleclose,
  setShowSucessModal,
  action,
  shiftID = null,
  draft = false,
  shiftTemplateInfo = null,
}) => {
  const { t } = useTranslation();
  const shiftSchema = Yup.object().shape({
    shift_date: Yup.date()
      .nullable()
      .required(`${t('validation.date_required')}`),
    start_time: Yup.string()
      .nullable()
      .required(`${t('validation.value_required')}`),
    /*.test('not-date', 'Invalid value', function (value) {
        return moment(value).isValid();
      })*/
    end_time: Yup.string()
      .nullable()
      .required(`${t('validation.value_required')}`),
    /*.test('not-date', 'Invalid value', function (value) {
        return moment(value).isValid();
      })*/
    title: Yup.string()
      .max(200, `${t('JobScheduler.validation.shift_form.must_be_title')}`)
      .required(`${t('validation.title_required')}`),
    site: Yup.object()
      .nullable()
      .required(`${t('validation.site_required')}`),
    job_title: Yup.array()
      .nullable()
      .required(`${t('validation.job_titles_required')}`),
    employees: Yup.array().nullable(),
    enable_claim: Yup.boolean().nullable(),
    open_spots: Yup.string()
      .nullable()
      .matches(/^[1-9][0-9]*$/, `${t('JobScheduler.validation.shift_form.must_be_number')}`)
      .when('enable_claim', {
        is: true,
        then: Yup.string().required(`${t('validation.spots_required')}`),
      }),
    location: Yup.string()
      .max(400, `${t('JobScheduler.validation.shift_form.must_be_location')}`)
      .required(`${t('validation.location_required')}`),
    note: Yup.string(),
    //repeat_shift: Yup.boolean(),
    repeat_shift_option: Yup.object(),
    end_repeat_shift_option: Yup.object(),
    workingDays: Yup.array()
      .nullable()
      .when('repeat_shift_option.value.value_definition', {
        is: '040',
        then: Yup.array().test(
          'one-selected',
          `${t('validation.day_at_least_one')}`,
          function (value) {
            const found = value.find((v) => v.isActive === true);
            return found ? true : false;
          },
        ),
      }),
    repeat_number_shifts: Yup.string()
      .nullable()
      .when('end_repeat_shift_option.value.id', {
        is: 2,
        then: Yup.string()
          .required(`${t('validation.value_required')}`)
          .matches(/^[1-9][0-9]*$/, `${t('JobScheduler.validation.shift_form.must_be_number')}`),
      }),
    end_repeat_date: Yup.date()
      .nullable()
      .when('end_repeat_shift_option.value.id', {
        is: 1,
        then: Yup.date()
          .nullable()
          .required(`${t('validation.value_required')}`),
      }),
    action: Yup.string(),
  });

  const initialValuesC = useMemo(() => {
    return {
      shift_date: moment(),
      start_time: '00:00' /*moment(new Date().setHours(00, 0, 0, 0)),*/,
      end_time: '12:00' /*moment(new Date().setHours(12, 0, 0, 0)),*/,
      title: '',
      enable_claim: false,
      open_spots: null,
      site: '',
      job_title: '',
      location: '',
      note: '',
      user_shifts: [],
      repeat_shift: false,
      timeZone: {
        label: 'America/Bogota',
        value: {
          value_definition: '060',
          description: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
          active: true,
          validation_type: 'America/Bogota',
        },
      },
      repeat_shift_option: {
        label: `${t('JobScheduler.options.Daily')}`,
        value: {
          value_definition: '010',
          description: 'Daily',
        },
      },
      end_repeat_shift_option: {
        label: `${t('JobScheduler.options.on')}`,
        value: {
          id: 1,
          description: 'on',
        },
      },
      workingDays: [
        {
          id: 1,
          name: 'Sun',
          isActive: false,
        },
        {
          id: 2,
          name: 'Mon',
          isActive: false,
        },
        {
          id: 3,
          name: 'Tue',
          isActive: false,
        },
        {
          id: 4,
          name: 'Wed',
          isActive: false,
        },
        {
          id: 5,
          name: 'Thu',
          isActive: false,
        },
        {
          id: 6,
          name: 'Fri',
          isActive: false,
        },
        {
          id: 7,
          name: 'Sat',
          isActive: false,
        },
      ],
      repeat_number_shifts: null,
      end_repeat_date: moment(new Date()),
    };
  }, [t]);

  const { jobSchedulerGroup, valuesDefinitions, userData } = useSelector((state) => state.app);

  const timeZones = React.useMemo(
    () =>
      valuesDefinitions
        ? valuesDefinitions
            .filter((v) => v.definitionType.definition_type === 'TIME_ZONES')
            .sort((a, b) => a.value_definition - b.value_definition)
        : [],
    [valuesDefinitions],
  );

  const localTz = timeZones.find((tz) => tz.description.includes(getTimeZone()));
  const [item, setItem] = useState({});
  const [initialValues, setInitialValues] = useState(initialValuesC);
  //Repeat options
  let repeatOptions = valuesDefinitions
    ? valuesDefinitions.filter((v) => v.definitionType.definition_type === 'SHIFT_REPETITION')
    : [];

  const endRepeatOptions = useMemo(
    () => [
      { id: 1, description: 'on' },
      { id: 2, description: 'after' },
    ],
    [],
  );

  //To sort
  function compare(a, b) {
    if (a.value_definition > b.value_definition) return 1;
    if (a.value_definition < b.value_definition) return -1;
  }

  //Sort
  repeatOptions = repeatOptions.sort(compare);

  //To handle message
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  const [isloadingShift, setIsLoadingShift] = useState(false);
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [loadingSites, setLoadingSites] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shiftsConflicts, setShiftsConflicts] = useState([]);

  const [frmMessage, setFrmMessage] = useState('');
  const [jobTitleF, setJobTitleF] = useState('');
  const [siteF, setSiteF] = useState('');
  const [startTimeF, setStartTimeF] = useState(moment(new Date().setHours(0, 0, 0, 0)));
  const [endTimeF, setEndTimeF] = useState(moment(new Date().setHours(12, 0, 0, 0)));
  const [diffHours, setDiffHours] = useState('-');
  const [selectedTz, setSelectedTz] = useState(
    timeZones.filter((tz) => tz.value_definition === '060')[0],
  );
  const [canAddNote, setCanAddNote] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const dispatch = useDispatch();
  const [editShiftModal, setEditShiftModal] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState({});
  const [shiftToEditStatus, setShiftToEditStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const SYSTEM_ROLE = 'system';
  const ORG_ROLE = 'organizations';
  const CASALIMPIA_ADMIN_ROLE = 'admin';
  const cognitoRole = useRef('');
  const confirmEdition = useRef(false);
  const editingValues = useRef({});
  //This is to save request declined records that need to be ignore but not deleted
  const requestDeclineRecords = useRef([]);
  //Manage if shift is going to be all day
  const [allDay, setAllDay] = useState(false);
  const [openShiftsConflictsModal, setOpenShiftsConflictsModal] = useState(false);

  const removeDraftShiftModalContent = {
    buttonText: `${t('buttons.btn_delete')}`,
    actionIcon: ico_Archived,
    title: `${t('JobScheduler.modals.delete_shift_draft_title')}`,
    subtitle: `${t('JobScheduler.modals.delete_shift_draft_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  const editShiftModalContent = {
    buttonText: `${t('buttons.btn_confirm')}`,
    actionIcon: ico_update_publish,
    subtitle: `${t('JobScheduler.modals.edit_shift_subtitle')}`,
    bgcolor: PALETTE_COLOR.MAIN_BLUE,
    bordercolor: PALETTE_COLOR.MAIN_BLUE,
    borderhovercolor: PALETTE_COLOR.MAIN_BLUE,
    bghovercolor: PALETTE_COLOR.MAIN_BLUE,
    boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  };

  const removePublishedShiftModalContent = {
    buttonText: `${t('buttons.btn_discard')}`,
    actionIcon: ico_Archived,
    title: `${t('JobScheduler.modals.discard_shift_title')}`,
    subtitle: `${t('JobScheduler.modals.discard_shift_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  const handleRemoveModal = () => {
    setOpenRemoveModal((prev) => {
      return !prev;
    });
  };

  const handleEditShitfModal = () => {
    setEditShiftModal((prev) => {
      return !prev;
    });
    setErrorMessage('');
  };

  const handleClickOpenShiftsConflictsModal = () => {
    setOpenShiftsConflictsModal(true);
  };

  const handleClickCloseShiftsConflictsModal = () => {
    setOpenShiftsConflictsModal(false);
  };

  /***
   * Get shift info when editing
   */
  useEffect(() => {
    if (shiftID) {
      setIsLoadingShift(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'organization' })
        .setJoin({ field: 'status' })
        .setJoin({ field: 'site' })
        .setJoin({ field: 'user_shifts' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.jobtitle' })
        .setJoin({ field: 'timeZone' })
        .setJoin({ field: 'repeat_shift_option' });
      getEntityData(`${SHIFTS_API}${shiftID}`, qb.query())
        .then((res) => {
          setItem(res);
          setJobTitleF(
            res.jobs_titles.length > 0
              ? res.jobs_titles?.map((reg) => ({
                  label: reg.jobtitle.description,
                  value: reg.jobtitle,
                }))
              : res.job_title
              ? [{ label: res.job_title?.description, value: res.job_title }]
              : initialValuesC.job_title,
          );
          setSiteF(
            res.site
              ? { label: res.site?.name, value: res.site, color: res.organization?.color }
              : initialValuesC.site,
          );
          setInitialValues({
            ...res,
            shift_date: res.shift_date ? moment.utc(res.shift_date) : initialValuesC.shift_date,
            start_time: res.start_time
              ? moment.utc(res.start_time).format('HH:mm')
              : initialValuesC.start_time,
            end_time: res.end_time
              ? moment.utc(res.end_time).format('HH:mm')
              : initialValuesC.end_time,
            site: res.site
              ? { label: res.site?.name, value: res.site, color: res.organization?.color }
              : initialValuesC.site,
            job_title:
              res.jobs_titles.length > 0
                ? res.jobs_titles?.map((reg) => ({
                    label: reg.jobtitle.description,
                    value: reg.jobtitle,
                  }))
                : res.job_title
                ? [{ label: res.job_title?.description, value: res.job_title }]
                : initialValuesC.job_title,
            user_shifts: res.user_shifts
              ? res.user_shifts
                  ?.filter(
                    (us) =>
                      us.status.value_definition !== '050' && us.status.value_definition !== '060',
                  )
                  .map((reg) => ({
                    label: reg.user.name,
                    value: reg.user.id,
                    user: reg.user,
                    jobs_titles:
                      reg.user.jobs_titles.length > 0 ? reg.user.jobs_titles : [res.jobs_titles],
                  }))
              : initialValuesC.user_shifts,
            timeZone: res.timeZone
              ? { label: res.timeZone?.description, value: res.timeZone }
              : initialValuesC.timeZone,
            repeat_shift_option: res.repeat_shift_option
              ? { label: res.repeat_shift_option?.description, value: res.repeat_shift_option }
              : initialValuesC.repeat_shift_option,
            end_repeat_shift_option: res.end_repeat_date
              ? { label: endRepeatOptions[0].description, value: endRepeatOptions[0] }
              : res.repeat_number_shifts
              ? { label: endRepeatOptions[1].description, value: endRepeatOptions[1] }
              : initialValuesC.end_repeat_shift_option,
            workingDays: res.workingDays ? JSON.parse(res.workingDays) : initialValuesC.workingDays,
            end_repeat_date: res.end_repeat_date
              ? moment.utc(res.end_repeat_date)
              : initialValuesC.end_repeat_date,
          });
          setSiteF({ label: res.site?.description, value: res.site });
          setStartTimeF(moment.utc(res.start_time));
          setEndTimeF(moment.utc(res.end_time));
          setSelectedTz(res.timeZone);
          //This is to save request declined records that need to be ignore but not deleted, they will be concatenated again
          // on user_shifts records before editing
          requestDeclineRecords.current = res.user_shifts?.filter(
            (us) => us.status.value_definition === '060',
          );
          setIsLoadingShift(false);
        })
        .catch((err) => {
          console.log('GetShiftData err=', err);
          setIsLoadingShift(false);
        });
    } else if (shiftTemplateInfo) {
      setItem(shiftTemplateInfo);
      setInitialValues({
        ...shiftTemplateInfo,
        shift_date: shiftTemplateInfo.shift_date
          ? moment.utc(shiftTemplateInfo.shift_date)
          : initialValuesC.shift_date,
        start_time: shiftTemplateInfo.start_time
          ? shiftTemplateInfo.start_time
          : initialValuesC.start_time,
        end_time: shiftTemplateInfo.end_time ? shiftTemplateInfo.end_time : initialValuesC.end_time,
        site: shiftTemplateInfo.site
          ? {
              label: shiftTemplateInfo.site?.value?.name,
              value: shiftTemplateInfo?.site?.value,
              color: shiftTemplateInfo.organization?.color,
            }
          : initialValuesC.site,
        job_title:
          shiftTemplateInfo.jobs_titles?.length > 0
            ? shiftTemplateInfo.jobs_titles?.map((reg) => ({
                label: reg.jobtitle.description,
                value: reg.jobtitle,
              }))
            : shiftTemplateInfo.job_title
            ? [
                {
                  label: shiftTemplateInfo.job_title?.description,
                  value: shiftTemplateInfo.job_title,
                },
              ]
            : initialValuesC.job_title,
        user_shifts: initialValuesC.user_shifts,
        timeZone: shiftTemplateInfo.timeZone
          ? {
              label: shiftTemplateInfo.timeZone?.value?.description,
              value: shiftTemplateInfo?.timeZone?.value,
            }
          : initialValuesC.timeZone,
        repeat_shift: initialValuesC.repeat_shift,
        repeat_shift_option: initialValuesC.repeat_shift_option,
        end_repeat_shift_option: initialValuesC.end_repeat_shift_option,
        workingDays: initialValuesC.workingDays,
        end_repeat_date: initialValuesC.end_repeat_date,
      });
      setJobTitleF(
        shiftTemplateInfo.jobs_titles?.length > 0
          ? shiftTemplateInfo.jobs_titles?.map((reg) => ({
              label: reg.jobtitle.description,
              value: reg.jobtitle,
            }))
          : shiftTemplateInfo.job_title
          ? [
              {
                label: shiftTemplateInfo.job_title?.description,
                value: shiftTemplateInfo.job_title,
              },
            ]
          : initialValuesC.job_title,
      );
      setSiteF({
        label: shiftTemplateInfo.site?.value?.name,
        value: shiftTemplateInfo?.site?.value,
      });
      setStartTimeF(moment.utc(shiftTemplateInfo.start_time));
      setEndTimeF(moment.utc(shiftTemplateInfo.end_time));

      setIsLoadingShift(false);
    }
  }, [shiftID, endRepeatOptions, shiftTemplateInfo, initialValuesC]);

  /***
   * Get employees by site & job title & group
   * depending on form selections
   */
  useEffect(() => {
    setEmployeeList([]);
    if (siteF && jobTitleF.length > 0) {
      setLoadingEmployees(true);
      const number_jobtitles = jobTitleF.map((option) => option.value.value_definition_id);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setJoin({ field: 'user_groups' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.status' })
        .setJoin({ field: 'organizations_users' })
        .setJoin({ field: 'organizations_users.organization_user_sites' })
        .setJoin({ field: 'organizations_users.organization_user_sites.site' })
        .setJoin({ field: 'status' })
        .setJoin({ field: 'omitOrgSiteSearch' })
        .setFilter({
          field: 'jobs_titles.job_title.value_definition_id',
          operator: CondOperator.IN,
          value: number_jobtitles,
        })
        .setFilter({
          field: 'organizationUserSitesSite.id',
          operator: CondOperator.EQUALS,
          value: siteF.value?.id,
        })
        .setFilter({
          field: 'status.value_definition',
          operator: CondOperator.EQUALS,
          value: '010',
        })
        .setFilter({
          field: 'jobTitleStatus.value_definition',
          operator: CondOperator.EQUALS,
          value: '020', //Active JOB TITLE
        })
        .setFilter({
          field: 'user_groups.group.id',
          operator: CondOperator.EQUALS,
          value: jobSchedulerGroup.id,
        });
      getEntityData(USERS_API, qb.query())
        .then((res) => {
          setEmployeeList(res);
          setLoadingEmployees(false);
        })
        .catch((err) => {
          console.log('GetEmployeesBySiteJobTitle err=', err);
          setLoadingEmployees(false);
        });
    }
  }, [siteF, jobTitleF, jobSchedulerGroup]);

  /***
   * Calc hours difference between two time fields
   */
  useEffect(() => {
    if (startTimeF && endTimeF) {
      try {
        const diff = ShiftFullTimeDifferences({ start_time: startTimeF, end_time: endTimeF });
        isNaN(diff) ? setDiffHours('-') : setDiffHours(`${diff}h`);
        if (action === SHIFT_EDIT) if (diff === 24) setAllDay(true);
      } catch (e) {
        console.log('error: ', e);
        setDiffHours('-');
      }
    } else {
      setDiffHours('-');
    }
  }, [startTimeF, endTimeF, action]);

  /***
   * Load user permissions
   * Load all Job titles
   * Load sites for a group
   */
  useEffect(() => {
    async function fetchUserData() {
      const info = await getCurrentSession();
      cognitoRole.current = info.idToken?.payload['cognito:groups'][0];

      if (cognitoRole.current === SYSTEM_ROLE || cognitoRole.current === CASALIMPIA_ADMIN_ROLE)
        setCanAddNote(true);
      else if (cognitoRole.current === ORG_ROLE) {
        const { role } = userData;
        //Site Manager
        setCanAddNote(role.value_definition === '020');
      }
    }

    const getJobTitles = () => {
      setLoadingJobTitles(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'description', order: 'ASC' })
        .select(['definition_type_id', 'description'])
        .setFilter({
          field: 'definitionType.definition_type',
          operator: CondOperator.EQUALS,
          value: 'JOBS_TYPES',
        });
      getEntityData(VALUES_DEFINITIONS_API, qb.query())
        .then((res) => {
          setJobTitlesList(res);
          setLoadingJobTitles(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingJobTitles(false);
        });
    };

    const getSitesbyGroup = () => {
      if (jobSchedulerGroup) {
        setLoadingSites(true);
        const qb = RequestQueryBuilder.create();
        getEntityData(`${USERS_API}query/userSites/${jobSchedulerGroup.id}`, qb.query())
          .then((res) => {
            setLoadingSites(false);
            const sites = res.map((site) => ({
              label: site.name,
              value: site,
              color: site.organization_id.color,
              address: site.address,
              status: site.status,
            }));
            setSiteList(sites.filter((site) => site.status.value_definition === '010')); //Only active sites
            setLoadingSites(false);
          })
          .catch((err) => {
            console.log('axios err=', err);
            setLoadingSites(false);
          });
      }
    };

    fetchUserData();
    getJobTitles();
    getSitesbyGroup();

    return () => {};
  }, [jobSchedulerGroup, userData]);

  /***
   * Look for user settings
   */
  React.useEffect(() => {
    setIsLoadingShift(true);
    const qb = RequestQueryBuilder.create()
      .setJoin({
        field: 'user',
      })
      .setFilter({
        field: 'user.id',
        operator: CondOperator.EQUALS,
        value: userData.id,
      })
      .setFilter({
        field: 'code',
        operator: CondOperator.EQUALS,
        value: '010',
      });
    //Call API
    getEntityData(USER_SETTINGS_API, qb.query())
      .then((res) => {
        //User with timezone setting already saved
        if (res && res.length > 0) {
          const userTimeZone = timeZones.filter((tz) => tz.value_definition === res[0]?.detail)[0];
          setInitialValues((prev) => {
            return {
              ...prev,
              timeZone: userTimeZone
                ? { label: userTimeZone?.timeZone?.description, value: userTimeZone }
                : initialValuesC.timeZone,
            };
          });
        } else {
          setInitialValues((prev) => {
            return {
              ...prev,
              timeZone: initialValuesC.timeZone,
            };
          });
        }
        setIsLoadingShift(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });
    return () => {
      setIsLoadingShift(false);
      console.log('axios cleanup.');
    };
  }, [userData, timeZones, initialValuesC]);

  /***
   * Change date selector color
   */
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: MAIN_BLUE,
          },
        }}
      />
    );
  };

  const BuildHeader = (props) => {
    return (
      <>
        <TzHeader
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
          }}
        >
          <AppCheckBox
            checked={props.value.value_definition === selectedTz.value_definition}
            onChange={() => {}}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#000000',
              textAlign: 'left',
            }}
          >
            <TzHeaderLabel>{t('my_local_time')}</TzHeaderLabel>
            <TzHeaderValue>{`${localTz.description}`}</TzHeaderValue>
          </div>
        </TzHeader>
      </>
    );
  };

  const getMainButtonText = () => {
    if (
      action === SHIFT_CREATION ||
      (action === SHIFT_EDIT && item?.status?.value_definition === '020') //Draft
    )
      return `${t('buttons.btn_publish')}`;
    else if (
      action === SHIFT_EDIT &&
      item?.status?.value_definition === '010' //Published
    )
      return `${t('buttons.btn_update_publish')}`;
    else return `${t('buttons.btn_save')}`;
  };

  const saveShift = (values, status) => {
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    setFrmMessage('');
    const timeZone = timeZones.filter(
      (tz) => tz.value_definition === values.timeZone.value.value_definition,
    )[0];

    values.shift_date = moment(values.shift_date).isValid() ? values.shift_date : null;
    values.start_time = moment(values.start_time, 'HH:mm', true).isValid()
      ? values.start_time
      : null;
    values.end_time = moment(values.end_time, 'HH:mm', true).isValid() ? values.end_time : null;

    if (isEqual(values, initialValuesC) || isEqual(values, emptyForm)) {
      setFrmMessage(`${t('JobScheduler.at_least_one_field')}`);
    } else {
      //To create new shift
      let formattedShiftDate = null;
      let formattedFStartTime = null;
      let formattedFEndTime = null;
      let userStatus;
      //New logic for new timepicker component
      const timeRangeStart = values.start_time.split(':');
      const timeRangeEnd = values.end_time.split(':');

      if (new moment(values.shift_date).isValid()) {
        //UTC 0
        const shiftDate = moment(values.shift_date)
          .utcOffset(0)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            dayOfYear: values.shift_date.get('dayOfYear'),
            month: values.shift_date.get('month'),
            year: values.shift_date.get('year'),
          });
        formattedShiftDate = shiftDate.format();
        formattedFStartTime = shiftDate
          .set({
            hour: timeRangeStart[0],
            minute: timeRangeStart[1],
            second: 0,
          })
          .format();
        formattedFEndTime = shiftDate.set({
          hour: timeRangeEnd[0],
          minute: timeRangeEnd[1],
          second: 0,
        });
        // All DAY VALIDATION
        formattedFEndTime = allDay
          ? formattedFEndTime.add(1, 'days').format()
          : formattedFEndTime.format();
      } else {
        formattedFStartTime = moment()
          .utcOffset(0)
          .set({
            hour: timeRangeStart[0],
            minute: timeRangeStart[1],
            second: 0,
          })
          .format();
        formattedFEndTime = moment().utcOffset(0).set({
          hour: timeRangeEnd[0],
          minute: timeRangeEnd[1],
          second: 0,
        });
      }

      if (status.value_definition === '010') {
        //Publish
        //publish shift - employees are saved as Invited
        userStatus = valuesDefinitions.filter(
          (v) =>
            v.definitionType.definition_type === 'USER_SHIFT_STATUS' &&
            v.value_definition === '020',
        )[0];
      } else {
        //employees are saved as preInvited
        userStatus = valuesDefinitions.filter(
          (v) =>
            v.definitionType.definition_type === 'USER_SHIFT_STATUS' &&
            v.value_definition === '010',
        )[0];
      }
      const filteredEmployees = values.user_shifts?.map((item) => {
        return { user: item.user, status: userStatus };
      });
      const filteredJobTitles =
        values.job_title === ''
          ? []
          : values.job_title?.map((item) => {
              return { jobtitle: item.value };
            });
      const repeat_shift_option = repeatOptions.filter(
        (options) => options.value_definition === values.repeat_shift_option.value.value_definition,
      )[0];
      const requestCreateShift = {
        ...requestCreateShiftC,
        ...values,
        status,
        shift_date: formattedShiftDate,
        start_time: formattedFStartTime,
        end_time: formattedFEndTime,
        group: jobSchedulerGroup,
        organization: item.organization
          ? item.organization
          : values.site?.value
          ? values.site.value.organization_id
          : null,
        site: values.site?.value ? values.site?.value : null,
        jobtitle: filteredJobTitles,
        jobs_titles: filteredJobTitles,
        user_shifts: filteredEmployees,
        location: values.location,
        timeZone,
        repeat_shift_option: values.repeat_shift ? repeat_shift_option : null,
        repeat_number_shifts: values.repeat_shift
          ? values.end_repeat_shift_option.value.id === 1
            ? null
            : values.repeat_number_shifts
          : null,
        end_repeat_date: values.repeat_shift
          ? values.end_repeat_shift_option.value.id === 2
            ? null
            : values.end_repeat_date
          : null,
        workingDays: values.repeat_shift
          ? values.repeat_shift_option.value.value_definition === '040'
            ? JSON.stringify(values.workingDays)
            : null
          : null,
      };

      switch (action) {
        case SHIFT_CREATION:
          createShift(requestCreateShift, status);
          break;
        case SHIFT_EDIT:
          setShiftToEdit(requestCreateShift);
          setShiftToEditStatus(status);
          handleEditShitfModal(true);
          break;
        default:
          break;
      }
    }
  };

  const createShift = (requestCreateShift, status) => {
    if (requestCreateShift && requestCreateShift.user_shifts?.length > 0) {
      requestCreateShift.user_shifts.forEach((us) => {
        delete us.user.jobs_titles;
        delete us.user.organizations;
        delete us.user.organizations_users;
        delete us.user.sites;
        delete us.user.user_groups;
      });
    }

    setLoading(true);
    saveEntityData(SHIFTS_API, requestCreateShift)
      .then((res) => {
        if (res.conflicts) {
          setShiftsConflicts(res.conflicts);
          handleClickOpenShiftsConflictsModal();
        } else {
          setShowSucessModal({
            show: true,
            type: 'success',
            title:
              status.value_definition === '020' //Draft
                ? `${t('success_messages.shift_draft_created')}`
                : status.value_definition === '010' //Publish
                ? `${t('success_messages.shift_published')}`
                : `${t('success_messages.shift_created')}`,
          });
          handleclose();
          reloadSchedulerCalendar(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.status === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : status.value_definition === '020' //Draft
              ? `${t('error_messages.error_shift_draft_creating')}`
              : `${t('error_messages.error_shift_processing')}`,
          message: '',
        });
        console.log('shifts error = ', err);
      });
  };

  const editShift = () => {
    setLoading(true);
    setErrorMessage('');
    const finalUserShifts = shiftToEdit.user_shifts.concat(requestDeclineRecords.current);
    shiftToEdit.user_shifts = finalUserShifts;
    editEntityData(`${SHIFTS_API}${item.id}`, shiftToEdit)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title:
            shiftToEditStatus.value_definition === '020' //Draft
              ? `${t('success_messages.shift_draft_edited')}`
              : shiftToEditStatus.value_definition === '010' //Publish
              ? `${t('success_messages.shift_edited')}`
              : `${t('success_messages.shift_edited')}`,
        });
        handleclose();

        confirmEdition.current = false;
        editingValues.current = {};
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.shiftToEditStatus === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : shiftToEditStatus.value_definition === '020' //Draft
              ? `${t('error_messages.error_shift_draft_editing')}`
              : `${t('error_messages.error_shift_processing')}`,
          message: '',
        });
        setErrorMessage(err?.response?.data?.message);
        confirmEdition.current = false;
        editingValues.current = {};
        console.log('shifts error = ', err);
        setLoading(false);
      });
  };

  const deleteShift = () => {
    deleteEntityData(`${SHIFTS_API}${item.id}`)
      .then((res) => {
        handleclose();
        handleRemoveModal();
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.shift_draft_deleted')}`,
        });
        reloadSchedulerCalendar(true);
      })
      .catch((err) => {
        setShowErrorMessage({
          show: true,
          type: 'error',
          title:
            err.response?.status === 403
              ? `${t('error_messages.error_shifts_permissions')}`
              : `${t('error_messages.error_shift_deleting')}`,
          message: '',
        });
        console.log('shifts deleting error = ', err);
      });
  };

  const saveAsDraft = (values) => {
    const status = valuesDefinitions.filter(
      (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '020',
    )[0]; //Draft
    saveShift(values, status);
  };

  const saveAsPublished = (values) => {
    if (values.open_spots < values.user_shifts.length) {
      values.open_spots = values.user_shifts.length;
    }

    let status = {};
    status = valuesDefinitions.filter(
      (v) => v.definitionType.definition_type === 'SHIFT_STATUS' && v.value_definition === '010',
    )[0];
    saveShift(values, status);
  };

  const shiftToUpdateAssign = (shiftEdit) => {
    let deleted = [];
    for (let i = 0; i < item.user_shifts.length; i++) {
      if (
        shiftEdit.user_shifts.filter((shift) => shift.user === item.user_shifts[i].user).length ===
        0
      ) {
        deleted.push(item.user_shifts[i]);
      }
    }
    return deleted;
  };

  /***
   * Open discard modal
   */
  const handleOpenDiscardShift = () => {
    setErrorMessage('');
    removePublishedShiftModalContent.title = `${t('JobScheduler.modals.discard_shift_title')} ${
      item.open_spots > 1 ? ' • ' + item.open_spots : ''
    }`;
    //removePublishedShiftModalContent.subtitle = SHIFT_DISCARD;
    //removePublishedShiftModalContent.action = DISCARD_SHIFT;

    setOpenDiscardModal(!openDiscardModal);
  };

  /***
   * Delete a published shift
   */
  const deletePublishedShift = () => {
    setErrorMessage('');
    setLoading(true);
    deleteEntityData(`${SHIFTS_API}${item.id}`)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.shift_deleted')}`,
          message: '',
        });
        handleclose(item);
        handleRemoveModal();
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let mess = '';

        switch (err.response?.data?.statusCode) {
          case 403:
            mess = `${t('error_messages.error_shifts_permissions')}`;
            break;
          case 400.1:
          case 400.2:
            mess = err.response?.data?.message; // TODO: translate this message from backend
            break;
          default:
            mess = `${t('error_messages.error_shift_discarding')}`;
            break;
        }
        setErrorMessage(mess);
        /* showMessage({
            show: true,
            type: 'error',
            title: mess,
            message: '',
          });*/
        console.log('Deleting shift error = ', err);
      });
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={shiftSchema}
        onSubmit={saveAsPublished}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
          <div style={{ width: '590px' }}>
            {isloadingShift ? (
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{ padding: '23px 30px 7px', fontSize: '14px' }}
                novalidate="novalidate"
              >
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.date')}></LabelForm>
                  </Col>
                  <Col xs={6} style={{ fontSize: '14px' }}>
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      locale={i18next.resolvedLanguage}
                      style={{ fontSize: '14px' }}
                    >
                      <DatePicker
                        style={{ fontSize: '14px' }}
                        label
                        value={values.shift_date}
                        inputFormat="LL"
                        onChange={(newValue) => {
                          setFieldValue('shift_date', newValue);
                          setFieldValue('end_repeat_date', null);
                          setFieldValue('end_repeat_date', newValue);
                        }}
                        components={{
                          OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                        }}
                        renderDay={renderWeekPickerDay}
                        renderInput={(params) => (
                          <DatePickerInput
                            InputLabelProps={{ shrink: false }}
                            hiddenLabel
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {touched.shift_date && errors.shift_date && (
                      <ErrorText className="px-2 mb-0">{errors.shift_date}</ErrorText>
                    )}
                  </Col>
                  <Col
                    xs={3}
                    style={{ display: 'flex', alignContent: 'flex-start' }}
                    className="mx-0 px-0"
                  >
                    <AppSwitch
                      name="all_day"
                      value={allDay}
                      onChange={(selected) => {
                        setAllDay(selected.target.checked);
                        if (selected.target.checked) {
                          //Set start at 00:00:00 and end one day more at 00:00:00
                          setStartTimeF(moment(values.shift_date).startOf('day'));
                          setEndTimeF(moment(values.shift_date).add(1, 'days').startOf('day'));
                          setFieldValue(
                            'start_time',
                            '00:00' /*moment(values.shift_date).startOf('day')*/,
                          );
                          setFieldValue(
                            'end_time',
                            '00:00' /*moment(values.shift_date).add(1, 'days').startOf('day')*/,
                          );
                        } else {
                          //Set start at 00:00:00 and end same day at 12:00:00
                          setStartTimeF(moment(values.shift_date).startOf('day'));
                          setEndTimeF(
                            moment(values.shift_date)
                              .startOf('day')
                              .startOf('day')
                              .hour(12)
                              .minute(0),
                          );
                          setFieldValue(
                            'start_time',
                            '00:00' /*moment(values.shift_date).startOf('day')*/,
                          );
                          setFieldValue(
                            'end_time',
                            '12:00',
                            /*moment(values.shift_date)
                              .startOf('day')
                              .startOf('day')
                              .hour(12)
                              .minute(0),*/
                          );
                        }
                      }}
                    />
                    <LabelForm
                      tittle={t('form_labels.all_day')}
                      style={{ backgroundColor: 'pink' }}
                    ></LabelForm>
                  </Col>
                </Row>
                {/* *************************** NEW CLOCK *************************** */}
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.time_range')}></LabelForm>
                  </Col>
                  <Col xs={3}>
                    <TimeRangeInput
                      id="time"
                      label="."
                      type="time"
                      disabled={allDay}
                      value={values.start_time}
                      onChange={(event, newValue) => {
                        //New way: Add Shift date
                        const hours = event.target.value.split(':');
                        const dateTime = moment(values.shift_date).set({
                          hour: hours[0],
                          minute: hours[1],
                          second: 0,
                          millisecond: 0,
                        });
                        setFieldValue('start_time', event.target.value);
                        setStartTimeF(dateTime);
                      }}
                      InputLabelProps={{
                        shrink: true,
                        fontSize: 1,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          fontSize: '14px',
                          color: '#000000',
                          padding: 0,
                        },
                      }}
                      sx={{
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                          filter: allDay
                            ? 'invert(61%) sepia(0%) saturate(0%) hue-rotate(140deg) brightness(98%) contrast(98%);'
                            : 'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                        },
                      }}
                    />
                    {touched.start_time && errors.start_time && (
                      <ErrorText className="px-2 mb-0">{errors.start_time}</ErrorText>
                    )}
                  </Col>

                  <Col
                    xs={1}
                    style={{ textAlign: 'center', padding: '0', width: '6%', marginLeft: 5 }}
                  >
                    <LabelForm tittle="-"></LabelForm>
                  </Col>

                  <Col xs={3}>
                    <TimeRangeInput
                      id="time"
                      label="."
                      type="time"
                      disabled={allDay}
                      value={values.end_time}
                      //minTime={values.start_time ? moment(values.start_time) : moment()}
                      onChange={(event, newValue) => {
                        //New way: Add Shift date
                        const hours = event.target.value.split(':');
                        const dateTime = moment(values.shift_date).set({
                          hour: hours[0],
                          minute: hours[1],
                          second: 0,
                          millisecond: 0,
                        });
                        setFieldValue('end_time', event.target.value);
                        setEndTimeF(dateTime);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          fontSize: '14px',
                          color: '#000000',
                          padding: 0,
                        },
                      }}
                      sx={{
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {
                          filter: allDay
                            ? 'invert(61%) sepia(0%) saturate(0%) hue-rotate(140deg) brightness(98%) contrast(98%);'
                            : 'invert(38%) sepia(91%) saturate(699%) hue-rotate(192deg) brightness(103%) contrast(97%)',
                        },
                      }}
                    />
                    {touched.end_time && errors.end_time && (
                      <ErrorText className="px-2 mb-0">{errors.end_time}</ErrorText>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="px-0"
                    style={{ textAlign: 'left', padding: '0', width: '18%' }}
                  >
                    <HoursDiff>
                      {diffHours} {t('form_labels.total').toLowerCase()}
                    </HoursDiff>
                    <AppDropDownSingleSelect
                      isSearchable={true}
                      allowSelectAll={false}
                      width={400}
                      menuStyle={{ position: 'fixed' }}
                      name="group"
                      BuildHeader={BuildHeader}
                      headerOptions={[
                        {
                          label: ShortTimeZone(localTz),
                          value: localTz,
                        },
                      ]}
                      onChange={(selected) => {
                        setSelectedTz(selected.value);
                        setFieldValue('timeZone', selected);
                      }}
                      value={values.timeZone}
                      options={timeZones.map((option) => ({
                        label: option.description,
                        value: option,
                      }))}
                      targetComponent={<Zone>{ShortTimeZone(values.timeZone?.value)}</Zone>}
                      defaultValue={values.timeZone}
                    />
                  </Col>
                </Row>
                <hr />
                {/* *************************** END NEW CLOCK *************************** */}
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.shift_title')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="title"
                      value={values.title}
                      invalid={touched.title && errors.title}
                      onChange={handleChange}
                    ></InputForm>
                    {touched.title && errors.title && (
                      <ErrorText className="px-2 mb-0">{errors.title}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.site')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownSelect
                      options={siteList}
                      noOptionsMessage={() => t('no_options')}
                      isClearable={true}
                      name="site"
                      placeholder={t('required')}
                      isLoading={loadingSites}
                      customOption={SingleOption}
                      avatar={values.site && values.site.value ? calcDot(values.site.color) : null}
                      icon={EllipseIcon}
                      value={values.site}
                      onChange={(selected) => {
                        setFieldValue('site', selected);
                        setSiteF(selected);
                        selected
                          ? setFieldValue('location', selected?.address)
                          : setFieldValue('location', '');
                      }}
                      invalid={touched.site && errors.site}
                      defaultValue={values.site}
                    />
                    {touched.site && errors.site && (
                      <ErrorText className="px-2 mb-0">{errors.site}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.job_titles')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownMultipleSelect
                      options={jobTitlesList.map((option) => ({
                        label: option.description,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options')}
                      isClearable={true}
                      name="job_title"
                      placeholder={t('required')}
                      isLoading={loadingJobTitles}
                      value={values.job_title}
                      icon={null}
                      height={230}
                      onChange={(selected) => {
                        if (selected.length === 0) {
                          setFieldValue('job_title', '');
                        } else {
                          setJobTitleF(selected);
                          setFieldValue('job_title', selected);
                          let users_shifts_new = [];
                          let band = false;
                          for (let index = 0; index < selected.length; index++) {
                            for (let indey = 0; indey < values.user_shifts.length; indey++) {
                              band = false;
                              for (
                                let indez = 0;
                                indez < values.user_shifts[indey].jobs_titles.length;
                                indez++
                              ) {
                                if (
                                  selected[index].value.value_definition_id ===
                                    values.user_shifts[indey].jobs_titles[indez].job_title
                                      ?.value_definition_id ||
                                  selected[index].value.value_definition_id ===
                                    values.user_shifts[indey].jobs_titles[indez].jobtitle
                                      ?.value_definition_id
                                ) {
                                  band = true;
                                }
                              }
                              if (band === true) {
                                users_shifts_new.push(values.user_shifts[indey]);
                              }
                            }
                          }
                          setFieldValue('user_shifts', users_shifts_new);
                        }
                      }}
                      invalid={touched.job_title && errors.job_title}
                      defaultValue={[]}
                    />
                    {touched.job_title && errors.job_title && (
                      <ErrorText className="px-2 mb-0">{errors.job_title}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.employees')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownMultipleSelect
                      options={employeeList.map((option) => ({
                        label: option.name,
                        value: option.id,
                        user: option,
                        jobs_titles: option.jobs_titles,
                      }))}
                      noOptionsMessage={() => t('no_options')}
                      isClearable={true}
                      name="user_shifts"
                      customOption={MutipleCustomOption}
                      placeholder={t('placeholder_select_site_job')}
                      isLoading={loadingEmployees}
                      value={values.user_shifts}
                      icon={null}
                      height={230}
                      onChange={(selected) => {
                        setFieldValue('user_shifts', selected);
                      }}
                      invalid={touched.user_shifts && errors.user_shifts}
                      defaultValue={values.user_shifts}
                    />
                    {touched.user_shifts && errors.user_shifts && (
                      <ErrorText className="px-2 mb-0">{errors.user_shifts}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.open_spots')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <div style={{ display: 'inline-flex' }}>
                      {values.open_spots > 0 ? (
                        <img
                          style={{ marginRight: 10, width: 20 }}
                          onClick={() => setFieldValue('open_spots', --values.open_spots)}
                          src={MinusCircleBlue}
                          alt="MinusCircleBlue"
                        />
                      ) : (
                        <DarkTooltip title={t('tooltips.number_spots')}>
                          <img
                            style={{ marginRight: 10, width: 20 }}
                            src={MinusCircleGray}
                            alt="MinusCircleGray"
                          />
                        </DarkTooltip>
                      )}
                      <InputForm
                        style={{ marginRight: 10 }}
                        width="60px"
                        className="mb-0"
                        inpfontsize={'14px'}
                        height={'40px'}
                        placeholder={t('placeholder_one')}
                        name="open_spots"
                        value={values.open_spots}
                        invalid={touched.open_spots && errors.open_spots}
                        onChange={handleChange}
                      ></InputForm>
                      <img
                        style={{ marginRight: 10, width: 20 }}
                        onClick={() => setFieldValue('open_spots', ++values.open_spots)}
                        src={PlusCircleBlue}
                        alt="PlusCircleBlue"
                      />
                      <div
                        style={{
                          textAlign: 'left',
                          marginLeft: '10px',
                          display: 'inline-flex',
                        }}
                      >
                        <div style={{ width: 'auto', marginTop: 8, marginRight: 10 }}>
                          {t('JobScheduler.total_for_shift')}
                        </div>
                        <DarkTooltip title={t('tooltips.total_for_shift')}>
                          <img
                            style={{ marginRight: 10, width: 20 }}
                            src={ExclamationCircleBlue}
                            alt="ExclamationCircleBlue"
                          />
                        </DarkTooltip>
                      </div>
                    </div>

                    {touched.open_spots && errors.open_spots && (
                      <ErrorText className="px-2 mb-0">{errors.open_spots}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.location')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      inpfontsize={'14px'}
                      height={'40px'}
                      placeholder={t('required')}
                      name="location"
                      value={values.location}
                      disabled
                      invalid={touched.location && errors.location}
                      onChange={handleChange}
                    ></InputForm>
                    {touched.location && errors.location && (
                      <ErrorText className="px-2 mb-0">{errors.location}</ErrorText>
                    )}
                  </Col>
                </Row>
                {canAddNote && (
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.notes')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        placeholder={t('optional')}
                        style={{ width: '100%', resize: 'none' }}
                        inpfontsize={'14px'}
                        name="note"
                        ctype="textarea"
                        rows={3}
                        value={
                          values.note &&
                          values.note.toLowerCase().replace(/\s/g, '_') === 'not_shift_note'
                            ? `${t('Timesheet.not_shift_note')}`
                            : values.note
                        }
                        invalid={touched.note && errors.note}
                        onChange={handleChange}
                      ></InputForm>
                      {touched.note && errors.note && (
                        <ErrorText className="px-2 mb-0">{errors.note}</ErrorText>
                      )}
                    </Col>
                  </Row>
                )}
                <hr />
                {
                  /** Repeat shift block would be avaiable:
                   * When creating  a new shift
                   * Only editing Drafts
                   */

                  ((action === SHIFT_CREATION && !shiftTemplateInfo) ||
                    (action === SHIFT_EDIT && item.status?.value_definition === '020')) && (
                    <>
                      <Row className="mb-3">
                        <Col xs={3}>
                          <LabelForm tittle={t('form_labels.repeat_shift')}></LabelForm>
                        </Col>
                        <Col xs={2}>
                          <AppSwitch
                            name="repeat_shift"
                            value={values.repeat_shift}
                            invalid={touched.location && errors.location}
                            onChange={(selected) => {
                              setFieldValue('repeat_shift', selected.target.checked);
                              setFieldValue('repeat_number_shifts', 1);
                            }}
                          />
                        </Col>
                        {/* Repeat shift */}
                        {values.repeat_shift && (
                          <Col xs={5}>
                            <AppDropDownSelect
                              options={repeatOptions.map((option) => ({
                                label: `${t(
                                  `JobScheduler.options.${option.description.replace(/\s/g, '_')}`,
                                )}`,
                                value: option,
                              }))}
                              noOptionsMessage={() => t('no_options')}
                              isClearable={false}
                              height="10rem"
                              isSearchable={false}
                              name="repeat_shift_option"
                              isLoading={false}
                              onChange={(selected) => {
                                setFieldValue('repeat_shift_option', selected);
                                if (values.repeat_shift_option.value.value_definition !== '040') {
                                  let tmp = values.workingDays;
                                  tmp = tmp.map((t) => {
                                    t.isActive = false;
                                    return t;
                                  });
                                  setFieldValue('workingDays', tmp);
                                }
                              }}
                              invalid={touched.repeat_shift_option && errors.repeat_shift_option}
                              defaultValue={
                                values.repeat_shift_option && {
                                  label: `${t(
                                    `JobScheduler.options.${values.repeat_shift_option.value.description.replace(
                                      /\s/g,
                                      '_',
                                    )}`,
                                  )}`,
                                  value: values.repeat_shift_option.value,
                                }
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      {/* Workdays repeat */}
                      {values.repeat_shift_option.value.value_definition === '040' &&
                        values.repeat_shift && (
                          <Row className="mb-3">
                            <Col xs={3}>
                              <LabelForm tittle={t('form_labels.repeat_on')}></LabelForm>
                            </Col>
                            <Col xs={9}>
                              <Row>
                                {values.workingDays.map((day, index) => (
                                  <AppDivButton
                                    key={index}
                                    className="ml-auto p-2"
                                    style={{
                                      width: 50,
                                      height: 40,
                                      borderRadius: '16px',
                                      fontSize: '14px',
                                      marginRight: 5,
                                    }}
                                    bgcolor={
                                      day.isActive
                                        ? PALETTE_COLOR.MAIN_BLUE
                                        : PALETTE_COLOR.MAIN_WHITE
                                    }
                                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                                    color={
                                      day.isActive
                                        ? PALETTE_COLOR.MAIN_WHITE
                                        : PALETTE_COLOR.MAIN_BLUE
                                    }
                                    bordercolor={PALETTE_COLOR.MAIN_BLUE}
                                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                                    title={t(`week_days.${day.name}`)}
                                    onClick={(selected) => {
                                      let tmp = values.workingDays;
                                      tmp[index].isActive = !tmp[index].isActive;
                                      setFieldValue('workingDays', tmp);
                                    }}
                                  >
                                    {t(`week_days.${day.name}`)}
                                  </AppDivButton>
                                ))}
                                {touched.workingDays && errors.workingDays && (
                                  <ErrorText className="px-2 mb-0">{errors.workingDays}</ErrorText>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        )}
                      {/* End Repeat */}
                      {values.repeat_shift && (
                        <Row className="mb-3">
                          <Col xs={4}>
                            <LabelForm tittle={t('form_labels.end_repeat')}></LabelForm>
                          </Col>
                          <Col xs={3}>
                            <AppDropDownSelect
                              options={endRepeatOptions.map((option) => ({
                                label: `${t(`JobScheduler.options.${option.description}`)}`,
                                value: option,
                              }))}
                              noOptionsMessage={() => t('no_options')}
                              isClearable={false}
                              height="8rem"
                              isSearchable={false}
                              name="end_repeat_shift_option"
                              isLoading={false}
                              onChange={(selected) => {
                                setFieldValue('end_repeat_shift_option', selected);
                                //Reset to its default value
                                setFieldValue('repeat_number_shifts', 1);
                                setFieldValue('end_repeat_date', values.shift_date);
                              }}
                              invalid={
                                touched.end_repeat_shift_option && errors.end_repeat_shift_option
                              }
                              defaultValue={
                                values.end_repeat_shift_option && {
                                  label: `${t(
                                    `JobScheduler.options.${values.end_repeat_shift_option.value.description}`,
                                  )}`,
                                  value: values.end_repeat_shift_option.value,
                                }
                              }
                            />
                          </Col>
                          {/* Date */}
                          {values.end_repeat_shift_option.value.id === 1 ? (
                            <Col xs={5}>
                              <LocalizationProvider
                                dateAdapter={DateAdapter}
                                locale={i18next.resolvedLanguage}
                              >
                                <DatePicker
                                  label
                                  value={values.end_repeat_date}
                                  minDate={values.shift_date}
                                  inputFormat="LL"
                                  onChange={(newValue) => {
                                    setFieldValue('end_repeat_date', newValue);
                                  }}
                                  components={{
                                    OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                                  }}
                                  renderDay={renderWeekPickerDay}
                                  renderInput={(params) => (
                                    <DatePickerInput
                                      InputLabelProps={{ shrink: false }}
                                      hiddenLabel
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {touched.end_repeat_date && errors.end_repeat_date && (
                                <ErrorText className="px-2 mb-0">
                                  {errors.end_repeat_date}
                                </ErrorText>
                              )}
                            </Col>
                          ) : (
                            // After x shifts
                            <Col xs={5}>
                              <div style={{ display: 'inline-flex', width: '30%' }}>
                                <InputForm
                                  className="mb-0"
                                  inpfontsize={'14px'}
                                  height={'40px'}
                                  placeholder={t('placeholder_one')}
                                  name="repeat_number_shifts"
                                  value={values.repeat_number_shifts}
                                  invalid={
                                    touched.repeat_number_shifts && errors.repeat_number_shifts
                                  }
                                  onChange={handleChange}
                                ></InputForm>
                              </div>
                              <div
                                style={{
                                  textAlign: 'left',
                                  marginLeft: '10px',
                                  display: 'inline-flex',
                                  width: '20%',
                                }}
                              >
                                <LabelForm
                                  tittle={t('form_labels.shifts').toLowerCase()}
                                ></LabelForm>
                              </div>
                              {touched.repeat_number_shifts && errors.repeat_number_shifts && (
                                <ErrorText className="px-2 mb-0">
                                  {errors.repeat_number_shifts}
                                </ErrorText>
                              )}
                            </Col>
                          )}
                        </Row>
                      )}
                      <hr />
                    </>
                  )
                }
                {frmMessage && (
                  <ErrorText className="px-2 mb-0 text-center">{frmMessage}</ErrorText>
                )}
                <StyleButtons>
                  <span>
                    <AppButton
                      loading={loading}
                      style={{
                        width:
                          action === SHIFT_CREATION ||
                          (action === SHIFT_EDIT && item?.status?.value_definition === '020')
                            ? 'auto'
                            : action === SHIFT_EDIT &&
                              item?.status?.value_definition === '010' &&
                              'auto',
                        marginRight: '20px',
                        display: 'unset',
                        flexDirection: 'unset',
                        fontSize: '14px',
                      }}
                      color={PALETTE_COLOR.MAIN_WHITE}
                      bgcolor={PALETTE_COLOR.MAIN_GREEN}
                      bordercolor={PALETTE_COLOR.MAIN_GREEN}
                      bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      size="lg"
                      type="submit"
                      title={getMainButtonText()}
                    >
                      {getMainButtonText()}
                    </AppButton>
                    {!(action === SHIFT_EDIT && item?.status?.value_definition === '010') && (
                      <AppButton
                        loading={loading}
                        size="lg"
                        style={{
                          width: 'auto',
                          display: 'unset',
                          flexDirection: 'unset',
                          fontSize: '14px',
                        }}
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        onClick={() => saveAsDraft(values)}
                        title={
                          action === SHIFT_CREATION
                            ? `${t('buttons.btn_save_as_draft')}`
                            : `${t('buttons.btn_save_draft')}`
                        }
                      >
                        {action === SHIFT_CREATION
                          ? `${t('buttons.btn_save_as_draft')}`
                          : `${t('buttons.btn_save_draft')}`}
                      </AppButton>
                    )}

                    {action === SHIFT_EDIT && item?.status?.value_definition === '010' && (
                      <AppButton
                        loading={loading}
                        size="lg"
                        style={{
                          width: 'auto',
                          display: 'unset',
                          flexDirection: 'unset',
                          fontSize: '14px',
                        }}
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        onClick={handleOpenDiscardShift}
                        title={t('buttons.btn_discard')}
                      >
                        {t('buttons.btn_discard')}
                      </AppButton>
                    )}

                    {draft ? (
                      <AppButton
                        loading={loading}
                        style={{
                          width: 40,
                          display: 'unset',
                          flexDirection: 'unset',
                          paddingLeft: 10,
                          marginLeft: 16,
                        }}
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        onClick={() => handleRemoveModal(SHIFT_REMOVE)}
                        title={t('buttons.btn_discard')}
                        icon={trash}
                      ></AppButton>
                    ) : (
                      ''
                    )}
                  </span>
                  {/* {!(action === SHIFT_EDIT && item?.status?.value_definition === '010') && (
                    <AppButton
                      loading={loading}
                      // className="ml-auto p-2"
                      style={{ width: 'auto', fontSize: '14px' }}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      //onClick={handleRemoveGroupModal}
                      title={t('buttons.btn_save_as_template')}
                    >
                      {t('buttons.btn_save_as_template')}
                    </AppButton>
                  )} */}
                </StyleButtons>
              </Form>
            )}

            {showErrorMessage.show && (
              <CustomizedSnackbar
                show={showErrorMessage.show}
                type={showErrorMessage.type}
                title={showErrorMessage.title}
                message={showErrorMessage.message}
              />
            )}
          </div>
        )}
      </Formik>
      {draft ? (
        <AppModal
          open={openRemoveModal}
          handleclose={handleRemoveModal}
          title={''}
          boxshadow={'none'}
          inside={1}
        >
          <AppModalStatus
            actionIcon={removeDraftShiftModalContent.actionIcon}
            title={removeDraftShiftModalContent.title}
            subtitle={
              <>
                <p style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                  {removeDraftShiftModalContent.subtitle}
                </p>
                {/* <DivDraft>
                  <p className="hours">
                    {new moment(item.start_time).format('h')}
                    {moment(item.start_time).format('a') === 'am' ? 'a' : 'p'} -{' '}
                    {new moment(item.end_time).format('h')}
                    {moment(item.end_time).format('a') === 'am' ? 'a' : 'p'}
                  </p>
                  <p className="title">{item.title}</p>
                  <p className="location">{item.location}</p>
                </DivDraft> */}
              </>
            }
            bgcolor={removeDraftShiftModalContent.bgcolor}
            bordercolor={removeDraftShiftModalContent.bordercolor}
            borderhovercolor={removeDraftShiftModalContent.borderhovercolor}
            bghovercolor={removeDraftShiftModalContent.bghovercolor}
            boxShadow={removeDraftShiftModalContent.boxShadow}
            buttonText={removeDraftShiftModalContent.buttonText}
            buttonAction={deleteShift}
          />
        </AppModal>
      ) : (
        ''
      )}
      {shiftsConflicts.length > 0 ? (
        /* Edit confirmation modal */
        <AppModal
          open={openShiftsConflictsModal}
          handleclose={handleClickCloseShiftsConflictsModal}
          boxshadow={'none'}
          inside={1}
          title={t('JobScheduler.modals.conflicts_found_title')}
          width="600px"
        >
          <DivShiftsConflicts>
            <div className="cont">
              <div className="col1">
                <h4>{t('form_labels.employee')}</h4>
              </div>
              <div className="col2">
                <h4>{t('form_labels.reason')}</h4>
              </div>
            </div>

            {shiftsConflicts.map((c) => (
              <div className="cont">
                <div className="col1">
                  <div style={{ display: 'flex' }}>
                    {calcAvatar(c, 29, 29)}
                    <StyledIconLabel>{c.name}</StyledIconLabel>
                  </div>
                </div>
                <div className="col2">
                  {c.user_shift.map((us) => (
                    <>
                      <div className="reasonLabel">
                        <img src={OverlappingShift} alt="OverlappingShift" />
                        {t('JobScheduler.overlapping_shift')}
                      </div>
                      <div className="reason" style={{ backgroundColor: c.color }}>
                        <div style={{ overflow: 'hidden' }}>
                          <p style={{ float: 'left' }}>
                            {us.shift.jobs_titles?.length > 0 ? (
                              us.shift.jobs_titles?.map((item, index) => (
                                <>
                                  {index > 0 ? ', ' : ''}
                                  {item.jobtitle.description}
                                </>
                              ))
                            ) : (
                              <>{us.shift.job_title?.description}</>
                            )}{' '}
                            - {us.shift.title}
                          </p>
                          <p style={{ float: 'right' }}>
                            {new moment.utc(us.shift.start_time).format('h')}
                            {moment(us.shift.start_time).format('a') === 'am' ? 'a' : 'p'} -{' '}
                            {new moment.utc(us.shift.end_time).format('h')}
                            {moment(us.shift.end_time).format('a') === 'am' ? 'a' : 'p'}
                          </p>
                        </div>
                        <div className="gray" style={{ overflow: 'hidden' }}>
                          <p style={{ float: 'left' }}>{us.shift.site.name}</p>
                          <p style={{ float: 'right' }}>
                            {moment(us.shift.end_time).diff(moment(us.shift.start_time), 'h')}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            ))}
            {/* <AppButton
              size="lg"
              style={{
                marginTop: 30,
                marginBottom: 30,
              }}
              bgcolor={PALETTE_COLOR.MAIN_WHITE}
              bghovercolor={PALETTE_COLOR.MAIN_BLUE}
              color={PALETTE_COLOR.MAIN_BLUE}
              bordercolor={PALETTE_COLOR.TOP_GRAY}
              borderhovercolor={PALETTE_COLOR.TOP_GRAY}
              onClick={() => console.log('test !!!!')}
              title={t('buttons.btn_exclude_conflict_employees')}
            >
              {t('buttons.btn_exclude_conflict_employees')}
            </AppButton> */}
          </DivShiftsConflicts>
        </AppModal>
      ) : (
        ''
      )}
      {editShiftModal ? (
        <AppModal
          open={editShiftModal}
          handleclose={handleEditShitfModal}
          title={''}
          boxshadow={'none'}
          inside={1}
          width="580px"
          styleModal={{ maxWidth: '580px' }}
        >
          <AppModalStatus
            actionIcon={editShiftModalContent.actionIcon}
            title={`${t('JobScheduler.modals.update_and_publish_title')} • ${
              shiftToEdit?.open_spots
            }`}
            subtitle={
              <EditShiftDetail>
                <p>{editShiftModalContent.subtitle}</p>
                <div className="borderTop">
                  <p className="text">{t('JobScheduler.to_update_assign')}</p>
                  <div className="avatars">
                    {shiftToEdit?.user_shifts
                      .filter(
                        (us) =>
                          us.status.value_definition !== '050' &&
                          us.status.value_definition !== '060',
                      )
                      .map((user, index) => (
                        <span style={{ cursor: 'pointer' }}>{calcAvatar(user.user, 30, 30)}</span>
                      ))}
                  </div>
                  <div className="spots">
                    {t('form_labels.open_spots')}:{' '}
                    {shiftToEdit?.open_spots - shiftToEdit?.user_shifts.length}
                  </div>
                </div>
                <div className="borderTop borderBottom">
                  <p className="text">{t('JobScheduler.to_cancel_unassign')}</p>
                  <div className="avatars">
                    {shiftToUpdateAssign(shiftToEdit)
                      .filter(
                        (us) =>
                          us.status.value_definition !== '050' &&
                          us.status.value_definition !== '060',
                      )
                      .map((user, index) => (
                        <span style={{ cursor: 'pointer' }}>{calcAvatar(user.user, 30, 30)}</span>
                      ))}
                  </div>
                  <div className="spots">
                    {t('form_labels.open_spots')}:{' '}
                    {shiftToEdit?.open_spots - shiftToEdit?.user_shifts.length}
                  </div>
                </div>
              </EditShiftDetail>
            }
            bgcolor={editShiftModalContent.bgcolor}
            bordercolor={editShiftModalContent.bordercolor}
            borderhovercolor={editShiftModalContent.borderhovercolor}
            bghovercolor={editShiftModalContent.bghovercolor}
            boxShadow={editShiftModalContent.boxShadow}
            buttonText={editShiftModalContent.buttonText}
            buttonAction={editShift}
            isLoading={loading}
            errorMessage={errorMessage}
          />
        </AppModal>
      ) : (
        ''
      )}

      <AppModal
        open={openDiscardModal}
        handleclose={handleOpenDiscardShift}
        title={''}
        boxshadow={'none'}
        inside={1}
        styleModal={{ maxWidth: '635px' }}
      >
        <AppModalStatus
          actionIcon={removePublishedShiftModalContent.actionIcon}
          title={removePublishedShiftModalContent.title}
          subtitle={
            <>
              <p style={{ color: PALETTE_COLOR.ACCENT_PURPLE, fontSize: 16, fontWeight: 500 }}>
                {item.title}
              </p>
              <p style={{ color: PALETTE_COLOR.BLACK, fontSize: 16 }}>
                {removePublishedShiftModalContent.subtitle}
              </p>
            </>
          }
          bgcolor={removePublishedShiftModalContent.bgcolor}
          bordercolor={removePublishedShiftModalContent.bordercolor}
          borderhovercolor={removePublishedShiftModalContent.borderhovercolor}
          bghovercolor={removePublishedShiftModalContent.bghovercolor}
          boxShadow={removePublishedShiftModalContent.boxShadow}
          buttonText={removePublishedShiftModalContent.buttonText}
          isLoading={loading}
          errorMessage={errorMessage}
          buttonAction={() => deletePublishedShift()}
        />
      </AppModal>
    </>
  );
};
