import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ic_job_scheduler from '@assets/icons/ic_job_scheduler.svg';
import ic_job_scheduler_active from '@assets/icons/ic_job_scheduler_active.svg';
import ic_timesheet from '@assets/icons/ic_timesheet.svg';
import ic_timesheet_active from '@assets/icons/ic_timesheet_active.svg';
import ic_message_center from '@assets/icons/ic_message_center.svg';
import ic_message_center_active from '@assets/icons/ic_message_center_active.svg';
import ic_organizations from '@assets/icons/ic_organizations.svg';
import ic_organizations_active from '@assets/icons/ic_organizations_active.svg';
import ic_users from '@assets/icons/ic_users.svg';
import ic_users_active from '@assets/icons/ic_users_active.svg';
import ic_groups from '@assets/icons/ic_groups.svg';
import ic_groups_active from '@assets/icons/ic_groups_active.svg';
import { IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { showTemplatesAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import {
  LOBBY,
  TIME_SHEET_LOBBY,
  MESSAGE_CENTER,
  USERS,
  GROPUS,
  ORGANIZATIONS_MANAGEMENT,
  DASHBOARD,
} from 'navigation/CONSTANTS';
import packageInfo from '../../package.json';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const DEF_TEXT_COLOR = PALETTE_COLOR.MAIN_WHITE;

const Nav = styled.nav`
  width: 60px;
  padding: 1em;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.02);
  @media (max-width: 700px) {
    padding-top: 64px;
  }
  @media (min-width: 700px) {
    position: fixed;
    width: 60px;
    height: calc(100% - 64px);
  }
`;

const NavList = styled.ul`
  margin: 0;
  margin-left: -10px;
  padding: 0;
  list-style: none;
  line-height: 2;
  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 1em;
    color: #333;
  }
  a:visited {
    color: #333;
  }
  a:hover,
  a:focus {
    color: #0077cc;
  }
`;

const IconWrapper = styled.img`
  cursor: pointer;
`;

const StyledBadge = styled('div')((props) => ({
  height: '20px',
  width: 'auto',
  minWidth: '20px',
  borderRadius: '50px',
  border: `2px solid ${PALETTE_COLOR.MAIN_WHITE}`,
  backgroundColor: `${PALETTE_COLOR.ACCENT_RED}`,
  position: 'absolute',
  right: '0px',
  top: '0px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

const StyledTextBadge = styled('span')((props) => ({
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  color: `${PALETTE_COLOR.MAIN_WHITE}`,
  marginLeft: '5px',
  marginRight: '5px',
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: props.bgcolor,
    color: DEF_TEXT_COLOR,
    fontSize: 13,
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderRadius: 12,
    padding: '8px 16px 8px 16px',
    left: 0,
  },
}));

const Navigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  let history = useHistory();
  const currentLocactionMainPath = location.pathname.split('/')[1];
  const { userData, countChatMessages } = useSelector((state) => state.app);
  const [selectedPage, setSelectedPage] = React.useState(
    `${currentLocactionMainPath === '' ? DASHBOARD : `/${currentLocactionMainPath}`}`,
  );

  const updateShowWeekTemplate = React.useCallback(
    async (payload) => {
      dispatch(showTemplatesAction(payload));
    },
    [dispatch],
  );

  let goTo = (route) => {
    updateShowWeekTemplate({ visible: false });
    setSelectedPage(`/${route.split('/')[1]}`);
    history.push(route);
  };

  return (
    <>
      <Nav>
        <NavList>
          <li>
            <LightTooltip
              title={t('JobScheduler.job_scheduler')}
              placement="right"
              bgcolor={'#8EC044'}
            >
              <IconButton
                aria-label={t('JobScheduler.job_scheduler')}
                onClick={() => goTo(LOBBY.replace(':type', 'calendar'))}
              >
                <IconWrapper
                  src={!LOBBY.includes(selectedPage) ? ic_job_scheduler : ic_job_scheduler_active}
                  onMouseOver={(e) => {
                    e.currentTarget.src = ic_job_scheduler_active;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = !LOBBY.includes(selectedPage)
                      ? ic_job_scheduler
                      : ic_job_scheduler_active || '';
                  }}
                  alt="Cap logo"
                />
              </IconButton>
            </LightTooltip>
          </li>
          <li>
            <LightTooltip
              title={t('Timesheet.time_sheet')}
              placement="right"
              bgcolor={'#8EC044'}
            >
              <IconButton
                aria-label={t('Timesheet.time_sheet')}
                onClick={() => goTo(TIME_SHEET_LOBBY)}
              >
                <IconWrapper
                  src={
                    !TIME_SHEET_LOBBY.includes(selectedPage) ? ic_timesheet : ic_timesheet_active
                  }
                  onMouseOver={(e) => {
                    e.currentTarget.src = ic_timesheet_active;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = !TIME_SHEET_LOBBY.includes(selectedPage)
                      ? ic_timesheet
                      : ic_timesheet_active || '';
                  }}
                  alt="Cap logo"
                />
              </IconButton>
            </LightTooltip>
          </li>
          <li>
            <LightTooltip
              title={t('MessageCenter.title')}
              placement="right"
              bgcolor={'#8EC044'}
            >
              <IconButton
                aria-label={t('MessageCenter.title')}
                onClick={() => goTo(MESSAGE_CENTER.replace(':type', 'chat'))}
              >
                <IconWrapper
                  src={
                    !MESSAGE_CENTER.includes(selectedPage)
                      ? ic_message_center
                      : ic_message_center_active
                  }
                  onMouseOver={(e) => {
                    e.currentTarget.src = ic_message_center_active;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = !MESSAGE_CENTER.includes(selectedPage)
                      ? ic_message_center
                      : ic_message_center_active || '';
                  }}
                  alt="Cap logo"
                />
                {countChatMessages > 0 && (
                  <StyledBadge>
                    <StyledTextBadge>
                      {countChatMessages}
                    </StyledTextBadge>
                  </StyledBadge>
                )}
              </IconButton>
            </LightTooltip>
          </li>
          <li>
            {/*<LightTooltip
              title={t('Conflict Manager')}
              placement="right"
              bgcolor={'rgba(255, 86, 107, 0.9)'}
            >
              <IconButton aria-label="Conflict Manager" onClick={() => goTo(CONFLICS)}>
                <IconWrapper
                  src={!CONFLICS.includes(selectedPage) ? ic_confict : ic_confict_active}
                  alt="Cap logo"
                  onMouseOver={(e) => {
                    e.currentTarget.src = ic_confict_active;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = !CONFLICS.includes(selectedPage)
                      ? ic_confict
                      : ic_confict_active || '';
                  }}
                />
              </IconButton>
                </LightTooltip>*/}
          </li>
        </NavList>

        <NavList style={{ position: 'absolute', bottom: 10 }}>
          <li>
            <LightTooltip
              title={t('Organization.title')}
              placement="right"
              bgcolor={'#1179BF'}
            >
              <IconButton
                aria-label={t('Organization.organization')}
                onClick={() => goTo(ORGANIZATIONS_MANAGEMENT.replace(':type', 'organizations'))}
              >
                <IconWrapper
                  src={
                    !ORGANIZATIONS_MANAGEMENT.includes(selectedPage)
                      ? ic_organizations
                      : ic_organizations_active
                  }
                  onMouseOver={(e) => {
                    e.currentTarget.src = ic_organizations_active;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = !ORGANIZATIONS_MANAGEMENT.includes(selectedPage)
                      ? ic_organizations
                      : ic_organizations_active || '';
                  }}
                  alt="Cap logo"
                />
              </IconButton>
            </LightTooltip>
          </li>
          {!userData.isSiteManager && (
            <div>
              <li>
                <LightTooltip
                  title={t('Users.title')}
                  placement="right"
                  bgcolor={'#1179BF'}
                >
                  <IconButton
                    aria-label={t('Users.users')}
                    onClick={() => goTo(USERS.replace(':type', 'employees'))}
                  >
                    <IconWrapper
                      src={!USERS.includes(selectedPage) ? ic_users : ic_users_active}
                      alt="Cap logo"
                      onMouseOver={(e) => {
                        e.currentTarget.src = ic_users_active;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.src = !USERS.includes(selectedPage)
                          ? ic_users
                          : ic_users_active || '';
                      }}
                    />
                  </IconButton>
                </LightTooltip>
              </li>
              <li>
                <LightTooltip
                  title={t('Groups.title')}
                  placement="right"
                  bgcolor={'#1179BF'}
                >
                  <IconButton
                    aria-label={t('Groups.groups')}
                    onClick={() => goTo(GROPUS.replace(':type', 'group'))}
                  >
                    <IconWrapper
                      src={!GROPUS.includes(selectedPage) ? ic_groups : ic_groups_active}
                      alt="Cap logo"
                      onMouseOver={(e) => {
                        e.currentTarget.src = ic_groups_active;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.src = !GROPUS.includes(selectedPage)
                          ? ic_groups
                          : ic_groups_active || '';
                      }}
                    />
                  </IconButton>
                </LightTooltip>
              </li>
            </div>
          )}
          <li>
            <label
              style={{ display: 'flex', justifyContent: 'center', fontSize: '6px' }}
            >{`v. ${packageInfo.version}`}</label>
          </li>
        </NavList>
      </Nav>
    </>
  );
};
export default Navigation;
