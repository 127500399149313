import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  AppTable,
  AppButton,
  AppDivButton,
  AppDropDownCheckBoxSelect,
  IcArrow,
  InputForm,
  AppDropDownSingleSelect,
  AppAvatar,
  AppMassPublishShiftButton,
  AppModal,
  AppModalStatus,
  SiteNestedCell,
} from '@components';
import { AditionalActions } from '../AditionalActions';
import ic_remove from '@assets/icons/ic_remove.svg';
import styled from 'styled-components';
import Badge from '@mui/material/Badge';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { IconButton, Tooltip } from '@mui/material';
import { Menu, MenuItem } from '@szhsin/react-menu';
import moment from 'moment';
import ic_avatar_empty from '@assets/icons/ic_avatar_empty.svg';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import { ORGANIZATIONS_USERS_API, SHIFTS_API } from 'services/CONSTANTS';
import { getEntityData } from '@services';
import { ShiftView } from '../ShiftView';
import { useDispatch } from 'react-redux';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import {
  setReloadSchedulerAction,
  showMessageAction,
  showTemplatesAction,
  setJobSchedulerQueriesAction,
} from '@redux/actions/appActions';
import ic_advance_filter_active from '@assets/icons/ic_advance_filter_active.svg';
import ic_advance_filter from '@assets/icons/ic_advance_filter.svg';
import Collapse from '@mui/material/Collapse';
import { ShiftTimeDifferences, ShiftStartHour, ShiftEndHour } from 'utils/appUtils';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '@config/CONSTANTS';
import LoadingOverlay from 'react-loading-overlay';
import ico_upload from '@assets/icons/ico_upload.svg';
import { saveEntityData } from '@services';
import { SHIFTS_WEEEK_TEMPLATES } from '@services/CONSTANTS';
import chain from '@assets/icons/chain.svg';
import Avatar from '@assets/icons/ic_no_avatar.svg';

import { useTranslation } from 'react-i18next';
import ic_search from '@assets/icons/ic_search.svg';
import LZString from 'lz-string';

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
const MAIN_WHITE = PALETTE_COLOR.MAIN_WHITE;

const hours = [
  { key: 'at12am', name: '12am', hour: 0 },
  { key: 'at1am', name: '1am', hour: 1 },
  { key: 'at2am', name: '2am', hour: 2 },
  { key: 'at3am', name: '3am', hour: 3 },
  { key: 'at4am', name: '4am', hour: 4 },
  { key: 'at5am', name: '5am', hour: 5 },
  { key: 'at6am', name: '6am', hour: 6 },
  { key: 'at7am', name: '7am', hour: 7 },
  { key: 'at8am', name: '8am', hour: 8 },
  { key: 'at9am', name: '9am', hour: 9 },
  { key: 'at10am', name: '10am', hour: 10 },
  { key: 'at11am', name: '11am', hour: 11 },
  { key: 'at12pm', name: '12pm', hour: 12 },
  { key: 'at1pm', name: '1pm', hour: 13 },
  { key: 'at2pm', name: '2pm', hour: 14 },
  { key: 'at3pm', name: '3pm', hour: 15 },
  { key: 'at4pm', name: '4pm', hour: 16 },
  { key: 'at5pm', name: '5pm', hour: 17 },
  { key: 'at6pm', name: '6pm', hour: 18 },
  { key: 'at7pm', name: '7pm', hour: 19 },
  { key: 'at8pm', name: '8pm', hour: 20 },
  { key: 'at9pm', name: '9pm', hour: 21 },
  { key: 'at10pm', name: '10pm', hour: 22 },
  { key: 'at11pm', name: '11pm', hour: 23 },
];

const FilterWrapper = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  background: #eff4fb;
  border-radius: 20px;
  color: ${MAIN_BLUE};
  height: 40px;
  padding: 10px 10px 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
`;

const IconButtonWrapper = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

const AdvanceFilterWrapper = styled(Collapse)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-top: 1px solid #edf1f8;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 6,
    border: `2px solid ${MAIN_WHITE}`,
    padding: '0 4px',
    background: 'red',
  },
}));

const IconWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
  color: #1179bf;
  height: 40px;
`;

const CalendarTypeSelectorWrapper = styled.label`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  top: -8px;
  position: relative;
  cursor: pointer;
`;

const DateLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #000000;
  text-align: center;
`;

const UserWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const UserLabelWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

const AvatarWrapper = styled.img`
  height: 40px;
  width: 40px;
`;

const StyledLoader = styled(LoadingOverlay)`
  .MyLoader_overlay {
    background: rgba(137, 137, 255, 0.24);
    color: #000;
  }
`;

const Icon = styled.img``;

export function Calendar({
  sites,
  allSitesSelected,
  group,
  handleClickOpenCreateSnglShiftModal,
  isUserPermissionJS,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /****************************************************************************************** */
  const [calendarConfig, setCalendarConfig] = useState({
    viewType: 'View by Employees',
    calendarType: 'Week',
    currentWeek: { start: moment.utc().startOf('week'), end: moment.utc().endOf('week') },
    day: moment(),
  });
  const [calendarColumns, setCalendarColumns] = useState([]);
  const { userData, reloadScheduler, jobSchedulerGroup } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [currentSites, setCurrentSites] = useState([]);
  const [lastEmployeesCalled, setLastEmployeesCalled] = useState([]);
  const [lastSitesCalled, setLastSitesCalled] = useState([]);
  const [lastIndexSites, setLastIndexSites] = useState(0);
  const [columns, setColumns] = useState([]);
  const [openLoadWeek, setOpenLoadWeek] = useState(false);
  const [weekTemplate, setWeekTemplate] = useState({});
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [laodMoreEnabled, setLaodMoreEnabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermSites, setSearchTermSites] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debouncedSearchTermSites = useDebounce(searchTermSites, 500);
  const [allSites, setAllSites] = useState([]);
  const [allSitesSearch, setAllSitesSearch] = useState([]);

  const reloadDataCalendar = () => {
    setOffset(0);
    setData([]);
  };

  const reloadDataCalendarBySite = useCallback(() => {
    const sitesToCall = allSites.filter((_, index) => index >= 0 && index < limit);
    setLastSitesCalled([...sitesToCall]);
    setCurrentSites([...sitesToCall]);
    setLastEmployeesCalled([]);
  }, [allSites, limit]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.WEEK_TEMPLATE,
    drop: (item) => {
      updateShowWeekTemplate({ visible: false });
      setWeekTemplate(item);
      setOpenLoadWeek(true);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const allShiftsNoDeclined = (data) => {
    const shiftsNoDeclined = [];
    data.forEach((us) => {
      if (
        us.status &&
        us.status.value_definition !== '050' &&
        us.status.value_definition !== '060'
      ) {
        shiftsNoDeclined.push(us);
      }
    });
    return shiftsNoDeclined;
  };

  const setDataAndOperate = (newData) => {
    const dataShiftsEmpy = [];
    [...data, ...newData].forEach((shift, index) => {
      const ides = dataShiftsEmpy.length > 0 ? dataShiftsEmpy.map((d) => d.id) : [];
      if (!ides.includes(shift.id)) {
        let shiftsNoDeclined = allShiftsNoDeclined(shift.user_shifts);
        if (
          (shift.open_spots &&
            shift.user_shifts &&
            shift.open_spots > shiftsNoDeclined.length &&
            shiftsNoDeclined.length > 0) ||
          (shift.user_shifts.length > 0 && shiftsNoDeclined.length === 0)
        ) {
          let shiftEmpy = Object.assign({}, shift);
          shiftEmpy.open_spots = shift.open_spots - shiftsNoDeclined.length;
          shiftEmpy.user_shifts = [];
          dataShiftsEmpy.push(shiftEmpy);
        }
        dataShiftsEmpy.push(shift);
      }
    });
    setData(dataShiftsEmpy);
  };

  const isActive = canDrop && isOver;

  /***************************************************************************************** */
  const showMessage = useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  const handleShowAdvanceFilter = () => {
    setShowAdvanceFilter((prev) => !prev);
  };

  const reloadSchedulerCalendar = useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  /***
   *
   */
  const advanceFilterColumns = () => {
    const columns = [
      {
        dataField: 'status_id.type',
        text: `${t('table_columns.type')}`,
        advanceFilter: [
          {
            label: `${t('status_types.Unpublished')}`,
            value: 'Unpublished',
            filter: 'status.value_definition',
            codOperator: '$ex',
            values: '010',
          },
          {
            label: `${t('status_types.Published')}`,
            value: 'Published',
            filter: 'status.value_definition',
            codOperator: '$eq',
            values: '010',
          },
          {
            label: `${t('status_types.No_Employees')}`,
            isDisabled: true,
            value: 'No Employees',
            filter: 'status.value_definition',
            values: '010',
          },
          {
            label: `${t('status_types.No_Site')}`,
            value: `${t('status_types.No_Site')}`,
            isDisabled: true,
            filter: 'status.value_definition',
            codOperator: '$isnull',
            values: '00',
          },
        ],
      },
    ];
    return columns.filter((c) => c.advanceFilter !== undefined);
  };

  const onFilterChange = (newSelectedOptions, event, selectedValues) => {
    if (event.action === 'select-option') {
      if (calendarConfig.viewType === 'View by Employees') {
        reloadDataCalendar();
      } else {
        reloadDataCalendarBySite();
      }
      if (event.option.value.includes('*')) {
        if (event.option.targetSelection && event.option.targetSelection.length > 0) {
          setSelectedFilters([
            {
              ...advanceFilterColumns()
                .filter((c) => c.dataField === event.option.filter)[0]
                .advanceFilter.filter((i) => event.option.targetSelection.includes(i.value))[0],
              filter: event.option.filter,
            },
            event.option,
          ]);
        } else {
          setSelectedFilters(selectedValues);
        }
      } else {
        setSelectedFilters(selectedValues);
      }
    }
    if (event.action === 'deselect-option') {
      if (calendarConfig.viewType === 'View by Employees') {
        reloadDataCalendar();
      } else {
        reloadDataCalendarBySite();
      }
      if (event.option.value.includes('*')) {
        setSelectedFilters([]);
      } else {
        setSelectedFilters(
          selectedFilters.filter(
            (f) => f.filter === event.option.filter && f.value !== event.option.value,
          ),
        );
      }
    }
  };

  const lastEmployees = useMemo(() => {
    return [...lastEmployeesCalled];
  }, [lastEmployeesCalled]);

  const lastSites = useMemo(() => {
    return [...lastSitesCalled];
  }, [lastSitesCalled]);

  useEffect(() => {
    if (!allSitesSelected) {
      setLaodMoreEnabled(false);
    }
  }, [allSitesSelected]);

  useEffect(() => {
    if (allSitesSearch.length > 0) {
      const sitesToCall = allSitesSearch.filter((_, index) => index >= 0 && index < limit);
      setCurrentSites([...sitesToCall]);
      if (allSitesSearch.length <= limit) {
        setLaodMoreEnabled(false);
      } else {
        setLaodMoreEnabled(true);
      }
    }
  }, [allSitesSearch, limit]);

  useEffect(() => {
    if (allSites.length > 0) {
      reloadDataCalendarBySite();
    }
  }, [allSites, reloadDataCalendarBySite]);

  useEffect(() => {
    if (calendarConfig.viewType === 'View by Sites') {
      reloadDataCalendar();
      if (allSites.length <= limit) {
        setLaodMoreEnabled(false);
      } else {
        setLaodMoreEnabled(true);
      }
    }
  }, [jobSchedulerGroup, calendarConfig.viewType, allSites.length, limit]);

  useEffect(() => {
    // if (calendarConfig.viewType === 'View by Employees') {
    reloadDataCalendar();
    // }
    if (calendarConfig.viewType === 'View by Sites') {
      setAllSites([...sites.map(el => el.id)]);
    }
  }, [reloadScheduler, jobSchedulerGroup, sites, calendarConfig.viewType]);

  // SET selectedFilters
  useEffect(() => {
    // console.log('setSelectedFilters');
    const advanceFilter = [
      {
        label: `${t('status_types.Unpublished')}`,
        value: 'Unpublished',
        filter: 'status.value_definition',
        codOperator: '$ex',
        values: '010',
      },
      {
        label: `${t('status_types.Published')}`,
        value: 'Published',
        filter: 'status.value_definition',
        codOperator: '$eq',
        values: '010',
      },
      {
        label: `${t('status_types.No_Employees')}`,
        isDisabled: true,
        value: 'No Employees',
        filter: 'status.value_definition',
        values: '010',
      },
      {
        label: `${t('status_types.No_Site')}`,
        value: `${t('status_types.No_Site')}`,
        isDisabled: true,
        filter: 'status.value_definition',
        codOperator: '$isnull',
        values: '00',
      },
    ];
    setSelectedFilters(advanceFilter);
  }, [t]);

  //SET COLUMNS
  useEffect(() => {
    // console.log('SET COLUMNS');
    setColumns([
      {
        dataField: 'user',
        text: 'Date', // Do not translate this text label
        sort: false,
        search: false,
        headerFormatter: (column, colIndex) => {
          return (
            <div style={{ justifyContent: 'center', width: '50%', marginLeft: '25%' }}>
              <AppDropDownSingleSelect
                isSearchable={false}
                allowSelectAll={false}
                name="group"
                width={228}
                onChange={(selected) => {
                  if (selected.value === 'View by Employees') {
                    reloadDataCalendar();
                    setLastSitesCalled([]);
                  }
                  setCalendarConfig({ ...calendarConfig, viewType: selected.value });
                }}
                value={{
                  label: `${t(
                    `JobScheduler.menu.${calendarConfig.viewType
                      .toLowerCase()
                      .replace(/\s/g, '_')}`,
                  )}`,
                  value: calendarConfig.viewType,
                }}
                options={[
                  {
                    label: `${t('JobScheduler.menu.view_by_employees')}`,
                    value: 'View by Employees',
                  },
                  { label: `${t('JobScheduler.menu.view_by_sites')}`, value: 'View by Sites' },
                ]}
                targetComponent={
                  <CalendarTypeSelectorWrapper>
                    {t(
                      `JobScheduler.menu.${calendarConfig.viewType
                        .toLowerCase()
                        .replace(/\s/g, '_')}`,
                    )}
                    <IcArrow
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      status={'enabled'}
                      direction={'down'}
                    />
                  </CalendarTypeSelectorWrapper>
                }
                defaultValue={{}}
              />
            </div>
          );
        },
        headerStyle: { width: calendarConfig.calendarType === 'Day' ? '370px' : '23%' },
        formatter: (cellContent, row) => {
          switch (calendarConfig.viewType) {
            case 'View by Employees':
              const avatarConfig = cellContent.id
                ? cellContent.avatar_config
                  ? JSON.parse(cellContent.avatar_config)
                  : null
                : null;
              return (
                <UserWrapper>
                  {cellContent.id === null ? (
                    <AvatarWrapper src={ic_avatar_empty} />
                  ) : avatarConfig ? (
                    <AppAvatar
                      avatarId={avatarConfig.avatarId}
                      bgColor={avatarConfig.bgColor}
                      gender={avatarConfig.gender}
                      skinColor={avatarConfig.skinColor}
                      width={40}
                      height={40}
                    />
                  ) : cellContent?.photo_url ? (
                    <Icon
                      src={cellContent.photo_url}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 60,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    <Icon
                      src={Avatar}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 60,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  )}
                  <div
                    style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 12 }}
                  >
                    <UserLabelWrapper>
                      {cellContent.id === null
                        ? `${t('JobScheduler.shifts_without_employees')}`
                        : cellContent.name}
                    </UserLabelWrapper>
                  </div>
                </UserWrapper>
              );
            case 'View by Sites':
              //Get all sites which belog to the group
              return (
                <SiteNestedCell
                  row={row}
                  data={data}
                  calendarConfig={calendarConfig}
                  userWithPermissions={isUserPermissionJS}
                />
              );
            default:
              break;
          }
        },
      },
      ...calendarColumns,
    ]);
  }, [calendarConfig, calendarColumns, data, isUserPermissionJS, t]);

  //LOAD EMPLOYEES
  useEffect(() => {
    console.log('LOAD EMPLOYEES');
    if (sites.length > 0 && calendarConfig.viewType === 'View by Employees') {
      let search = '';
      setLoading(true);

      const startDate = calendarConfig.calendarType === 'Day'
        ? `${calendarConfig.day.format('YYYY-MM-DD')} 00:00`
        : `${calendarConfig.currentWeek.start.format('YYYY-MM-DD')} 00:00`;
      const endDate = calendarConfig.calendarType === 'Day'
        ? `${calendarConfig.day.format('YYYY-MM-DD')} 23:59`
        : `${calendarConfig.currentWeek.end.format('YYYY-MM-DD')} 23:59`;

      if (debouncedSearchTerm) {
        search = `&search=${debouncedSearchTerm.replace(/\s/g, '%').toLowerCase().trim()}`;
      }

      getEntityData(
        `${ORGANIZATIONS_USERS_API}users-by-sites-or-group-page`,
        `${allSitesSelected ? `groupId=${group.id}` : 'siteIds=' + sites.map((s) => s.id)}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}${search}`,
      )
        .then((organizationsUsers) => {
          console.log('📌 - file: index.js:550 - .then - organizationsUsers:', organizationsUsers);
          let users = organizationsUsers;
          const uniqueUsers = [...new Map(users.map((item) => [item['id'], item])).values()];
          if (uniqueUsers.length === 0 || uniqueUsers.length < limit) {
            setLaodMoreEnabled(false);
          } else {
            setLaodMoreEnabled(true);
          }
          console.log('🚀 ~ .then ~ uniqueUsers:', uniqueUsers);
          setLastEmployeesCalled([...uniqueUsers.map(el => el.id)]);
          if (offset === 0) {
            setEmployees((prev) => [...uniqueUsers]);
          } else {
            setEmployees((prev) => [...prev, ...uniqueUsers]);
          }
        })
        .catch((err) => {
          console.log('🚀 ~ useEffect ~ err:', err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [
    sites,
    group,
    allSitesSelected,
    offset,
    limit,
    calendarConfig.calendarType,
    calendarConfig.day,
    calendarConfig.currentWeek.start,
    calendarConfig.currentWeek.end,
    calendarConfig.viewType,
    debouncedSearchTerm
  ]);

  ///LOAD SHIFTS
  useEffect(() => {
    // if (employees.length === 0) return;
    console.log('LOAD SHIFTS');
    console.log('lastEmployees', lastEmployees);
    console.log('lastSites', lastSites);
    // setData([]);
    const fieldsFiltering = [];
    const fieldSearch = {};
    let filtersCalendar = {};
    filtersCalendar = { unpublished: false, published: false };
    selectedFilters.forEach((column) => {
      if (column.label === `${t('status_types.Unpublished')}`) {
        filtersCalendar.unpublished = true;
      } else if (column.label === `${t('status_types.Published')}`) {
        filtersCalendar.published = true;
      }
    });
    if (filtersCalendar.published === true && filtersCalendar.unpublished === true) {
      fieldSearch['status.value_definition'] = { [CondOperator.NOT_EQUALS]: '020' };
      fieldsFiltering.push(fieldSearch);
      const fieldSearchOr = {};
      fieldSearchOr['status.value_definition'] = { [CondOperator.NOT_EQUALS]: '040' };
      fieldsFiltering.push(fieldSearchOr);
    }
    if (filtersCalendar.published === false && filtersCalendar.unpublished === true) {
      fieldSearch['status.value_definition'] = { [CondOperator.NOT_EQUALS]: '010' };
      fieldsFiltering.push(fieldSearch);
      const fieldSearchOr = {};
      fieldSearchOr['status.value_definition'] = { [CondOperator.NOT_EQUALS]: '020' };
      fieldsFiltering.push(fieldSearchOr);
      const fieldSearchOrs = {};
      fieldSearchOrs['status.value_definition'] = { [CondOperator.NOT_EQUALS]: '040' };
      fieldsFiltering.push(fieldSearchOrs);
    }
    if (filtersCalendar.published === true && filtersCalendar.unpublished === false) {
      fieldSearch['status.value_definition'] = { [CondOperator.EQUALS]: '010' };
      fieldsFiltering.push(fieldSearch);
    }
    console.log('📌 - file: index.js:595 - useEffect - fieldsFiltering:', fieldsFiltering);
    console.log('sites.length > 0', sites.length > 0);
    console.log('reloadScheduler', reloadScheduler);
    if ((sites.length > 0 || reloadScheduler) && (lastEmployees.length > 0 || lastSites.length > 0)) {
      //USER DRAFT SHIFTs
      setLoading(true);

      const fieldsSearching = [];
      if (debouncedSearchTermSites.length > 0) {
        fieldsSearching.push({
          'shift_site.name': {
            $contL: debouncedSearchTermSites.toLowerCase(),
          }
        });
        fieldsSearching.push({
          'title': {
            $contL: debouncedSearchTermSites.toLowerCase(),
          }
        });
      }

      let dqb = RequestQueryBuilder.create()
        .setJoin([
          { field: 'organization' },
          { field: 'site' },
          { field: 'status' },
          { field: 'jobs_titles' },
          { field: 'jobs_titles.jobtitle' },
          { field: 'user_shifts' },
          { field: 'user_shifts.user' },
          { field: 'user_shifts.status' },
          { field: 'timeZone' },
          { field: 'repeat_shift_option' },
          { field: 'group' },
          { field: 'user_shifts.userShiftEvents' },
          { field: 'user_shifts.userShiftEvents.type' },
          { field: 'user_shifts.user_shifts_requests' },
          { field: 'user_shifts.user_shifts_requests.status' },
        ])
        .sortBy({ field: 'start_time', order: 'ASC' })
        .search({
          $and: [
            {
              shift_date: {
                $between: [
                  calendarConfig.calendarType === 'Day'
                    ? `${calendarConfig.day.format('YYYY-MM-DD')} 00:00`
                    : `${calendarConfig.currentWeek.start.format('YYYY-MM-DD')} 00:00`,
                  calendarConfig.calendarType === 'Day'
                    ? `${calendarConfig.day.format('YYYY-MM-DD')} 23:59`
                    : `${calendarConfig.currentWeek.end.format('YYYY-MM-DD')} 23:59`,
                ],
              },
              creator_user_id: {
                $eq: userData.sub_cognito_id,
              },
              'status.value_definition': {
                $eq: '020',
              },
              'group.id': {
                $eq: group.id,
              },
              [`${lastEmployees.length > 0 ? 'user_shifts.user_id' : 'shift_site.id'}`]: {
                $in: lastEmployees.length > 0 ? [...lastEmployees] : [...lastSites],
              }
            },
            { $or: fieldsSearching }
          ],
        });

      // let compressedIdes = '';
      // if (!allSitesSelected) {
      //   const stringIdes = sites.map((s) => s.id).toString().replace('[', '').replace(']', '');
      //   compressedIdes = LZString.compressToEncodedURIComponent(stringIdes);
      //   // const decompressedString = LZString.decompress(compressedIdes);
      //   // console.log("stringIdes is: " + decompressedString);
      // }
      
      console.log('LOAD USER DRAFT SHIFTs');
      console.log(`${SHIFTS_API}`);
      console.log(`${dqb.query()}`);
      getEntityData(SHIFTS_API, dqb.query())
        .then((res) => {
          console.log('📌 - file: index.js:666 - .then - res:', res);
          //USER allow to see SHIFTS
          if (fieldsFiltering.length > 0) {
            console.log('LOAD USER DRAFT SHIFTs WITH FILTERS');
            const joins = [
              { field: 'organization' },
              { field: 'status' },
              { field: 'site' },
              { field: 'user_shifts' },
              { field: 'user_shifts.user' },
              { field: 'user_shifts.status' },
              { field: 'jobs_titles' },
              { field: 'jobs_titles.jobtitle' },
              { field: 'timeZone' },
              { field: 'group' },
              { field: 'repeat_shift_option' },
              { field: 'user_shifts.userShiftEvents' },
              { field: 'user_shifts.userShiftEvents.type' },
              { field: 'user_shifts.user_shifts_requests' },
              { field: 'user_shifts.user_shifts_requests.status' },
            ];

            let filterSites = {};
            if (lastSites.length === 0) {
              filterSites = {
                'shift_site.id': {
                  $in: sites.map((s) => s.id),
                }
              };
            }

            if (allSitesSelected) {
              filterSites = {
                'group.id': {
                  $eq: group.id,
                }
              };
              joins.push({ field: 'all_sites' });
            }
            // else {
            //   filterSites = {
            //     'shift_site.id': {
            //       $in: [compressedIdes]
            //     }
            //   };
            //   joins.push({ field: 'compressed' });
            // }

            let qb = RequestQueryBuilder.create()
              .setJoin(joins)
              .sortBy({ field: 'start_time', order: 'ASC' })
              .search({
                $and: [
                  {
                    ...filterSites,
                    shift_date: {
                      $between: [
                        calendarConfig.calendarType === 'Day'
                          ? `${calendarConfig.day.format('YYYY-MM-DD')} 00:00`
                          : `${calendarConfig.currentWeek.start.format('YYYY-MM-DD')} 00:00`,
                        calendarConfig.calendarType === 'Day'
                          ? `${calendarConfig.day.format('YYYY-MM-DD')} 23:59`
                          : `${calendarConfig.currentWeek.end.format('YYYY-MM-DD')} 23:59`,
                      ],
                    },
                    [`${lastEmployees.length > 0 ? 'user_shifts.user_id' : 'shift_site.id'}`]: {
                      $in: lastEmployees.length > 0 ? [...lastEmployees] : [...lastSites],
                    },
                  },
                  ...fieldsFiltering,
                  { $or: fieldsSearching }
                ],
              });
            dispatch(setJobSchedulerQueriesAction({ draft: dqb, publish: qb }));
            
            console.log('USER allow to see SHIFTS');
            console.log(`${SHIFTS_API}`);
            console.log(`${qb.query()}`);
            getEntityData(SHIFTS_API, qb.query())
              .then((resShifts) => {
                console.log('📌 - file: index.js:894 - .then - res:', res);
                console.log('📌 - file: index.js:895 - .then - resShifts:', resShifts);
                if (filtersCalendar.published === false && filtersCalendar.unpublished === false) {
                  res = res.filter(
                    (c) => !c.user_shifts || c.user_shifts.length === 0 || c.site_id === 'null',
                  );
                }

                if (calendarConfig.viewType === 'View by Sites') {
                  if (debouncedSearchTermSites.length > 0) {
                    const ides = [...res, ...resShifts].map(el => el.site.id);
                    setAllSitesSearch([...new Set(ides)]);
                  }
                  else {
                    if (allSitesSearch.length > 0) {
                      setAllSitesSearch([...sites.map(el => el.id)]);
                    }
                  }
                }
                //setData([...res, ...resShifts]);
                setDataAndOperate([...res, ...resShifts]);
                setLoading(false);
                reloadSchedulerCalendar(false);
              })
              .catch((err) => {
                setLoading(false);
                reloadSchedulerCalendar(false);
                console.log('axios err=', err);
              });
          } else {
            //setData([...res]);
            console.log('LOAD USER DRAFT SHIFTs NO FILTERS');
            setDataAndOperate([...res]);
            setLoading(false);
            reloadSchedulerCalendar(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          reloadSchedulerCalendar(false);
          console.log('axios err=', err);
        });
    } else {
      setLoading(false);
      reloadSchedulerCalendar(false);
    }
  }, [employees, calendarConfig, userData, reloadScheduler, selectedFilters, dispatch, lastSites, lastEmployees, debouncedSearchTermSites]); // eslint-disable-line react-hooks/exhaustive-deps

  //CALC COLUMNS
  useEffect(() => {
    // console.log('CALC COLUMNS');
    const calColumns = [];

    /*const compareArrays = (user_shifts1, user_shifts2) => {
      for (let i = 0; i < user_shifts1.length; i++) {
        if (
          calendarConfig.viewType === 'View by Sites' &&
          user_shifts1.length > 0 &&
          user_shifts2.length > 0
        ) {
          //console.log('test23213 $$$$', row);
          // if (user_shifts1[0].user.id === user_shifts2[0].user.id) return true;
        } else {
          for (let j = 0; j < user_shifts2.length; j++) {
            if (user_shifts1[i].user.id === user_shifts2[j].user.id) return true;
          }
        }
      }
      return false;
    };*/

    /*const compareArraysJobTitles = (jobs_titles1, jobs_titles2) => {
      for (let i = 0; i < jobs_titles1.length; i++) {
        for (let j = 0; j < jobs_titles2.length; j++) {
          if (
            jobs_titles1[i]?.jobtitle?.value_definition_id ===
            jobs_titles2[j]?.jobtitle?.value_definition_id
          )
            return true;
        }
      }
      return false;
    };*/

    const calculateConflicts = (data, row) => {
      let show = true;
      //let push = false;
      let shiftConflicts = [];
      let dataSites = [];

      if (data && data.length > 0) {
        if (calendarConfig.viewType === 'View by Sites') {
          for (let i = 0; i < data.length; i++) {
            if (data[i].user_shifts && data[i].user_shifts.length > 0) {
              for (let j = 0; j < data[i].user_shifts.length; j++) {
                const dataTemp = Object.assign({}, data[i]);
                const users = [...data[i].user_shifts];
                const userTemp = data[i].user_shifts[0];
                users[0] = data[i].user_shifts[j];
                users[j] = userTemp;
                dataTemp.user_shifts = users;
                dataSites.push(dataTemp);
              }
            } else {
              dataSites.push(data[i]);
            }
          }
          data = dataSites;
        }
        /*for (let i = 0; i < data.length; i++) {
          for (let j = 1; j < data.length; j++) {
            if (
              data[i].id !== data[j].id &&
              data[i].site &&
              data[i].site?.id === data[j].site?.id &&
              compareArraysJobTitles(data[i].jobs_titles, data[j].jobs_titles) &&
              compareArrays(data[i].user_shifts, data[j].user_shifts)
            ) {
              const starTime = moment(data[i].start_time).utc().format('YYYY-MM-DD HH:mm:ss');
              let endTime = moment(data[i].end_time).utc().format('YYYY-MM-DD HH:mm:ss');
              const starTimeShift = moment(data[j].start_time).utc().format('YYYY-MM-DD HH:mm:ss');
              let endTimeShift = moment(data[j].end_time)
                .add(60, 'minutes')
                .utc()
                .format('YYYY-MM-DD HH:mm:ss');

              if (endTime <= starTime) {
                endTime = moment(data[i].end_time)
                  .add(1, 'days')
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss');
                console.log('&&&& SUMMA DIA 1 !!!!!', data[i].title);
              }
              if (endTimeShift <= starTimeShift) {
                endTimeShift = moment(data[j].end_time)
                  .add(1, 'days')
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss');
                console.log(
                  '&&&& SUMMA DIA F !!!!!',
                  data[i].title,
                  data[j].end_time,
                  endTimeShift,
                );
              }

              if (
                (endTimeShift >= starTime && starTimeShift <= starTime) ||
                (endTimeShift >= endTime && starTimeShift >= endTime && starTimeShift <= endTime) ||
                (endTimeShift <= endTime && starTimeShift >= starTime) ||
                (endTimeShift >= endTime && starTimeShift <= starTime)
              ) {
                if (!push) {
                  push = true;
                  shiftConflicts.push(data[i]);
                  shiftConflicts.push(data[j]);
                }

                if (shiftConflicts.filter((s) => s.id === data[j].id).length === 0) {
                  shiftConflicts.push(data[j]);
                }
              }
            }
          }
        }*/
        let resultado = data.map((s, i) =>
          shiftConflicts.length > 0 && shiftConflicts.filter((sh) => sh.id === s.id).length > 0 ? (
            show ? (
              <div
                style={{
                  borderStyle: 'solid',
                  borderColor: '#1179BF',
                  borderRadius: 10,
                  marginBottom: 8,
                }}
              >
                {shiftConflicts.map((shift, index) => (
                  <>
                    <ShiftView
                      key={index}
                      shift={shift}
                      user={row.user}
                      handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
                      viewType={calendarConfig.viewType}
                    />
                    {shiftConflicts.length !== index + 1 ? (
                      <Icon
                        src={chain}
                        style={{
                          width: 16,
                          height: 16,
                          display: 'flex',
                          marginTop: -15,
                          marginRight: 'auto',
                          marginBottom: -5,
                          marginLeft: 'auto',
                          zIndex: 1,
                          position: 'relative',
                        }}
                      />
                    ) : (
                      <div style={{ marginBottom: -10 }}></div>
                    )}
                  </>
                ))}
                {(show = false)}
              </div>
            ) : (
              ''
            )
          ) : (
            //By employee - week, By site - Week
            <ShiftView
              key={i}
              shift={s}
              user={row.user}
              handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
              viewType={calendarConfig.viewType}
            />
          ),
        );
        if (resultado) {
          return resultado;
        }
      }
      return [];
    };

    switch (calendarConfig.calendarType) {
      case 'Week':
        for (let index = 0; index < 7; index++) {
          const day = moment.utc(calendarConfig.currentWeek.start).add(index, 'days');
          calColumns.push({
            dataField: day.format('DDMMYYYY'),
            text: 'Date',
            sort: false,
            search: false,
            headerFormatter: (column, colIndex) => {
              return (
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                  <DateLabel>
                    {`${day.format('ddd')} ${day.format('MM')}.${day.format('DD')}`}
                  </DateLabel>
                </div>
              );
            },
            headerStyle: { width: '11%' },
            formatter: (cellContent, row) => {
              return calculateConflicts(cellContent, row);
            },
          });
        }
        break;
      case 'Day':
        hours.forEach((element, i) => {
          calColumns.push({
            dataField: element.name,
            text: 'Date',
            sort: false,
            search: false,
            attrs: (cell, row, rowIndex, colIndex) => {
              if (colIndex === 1) {
                return { colSpan: 24 };
              }
            },
            headerFormatter: (column, colIndex) => {
              return <DateLabel>{element.name}</DateLabel>;
            },
            style: (cell, row, rowIndex, colIndex) => {
              return { border: 'none' };
            },
            headerStyle: { width: '88px' },
            formatter: (cellContent, row) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {cellContent &&
                    cellContent.map((cell) => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {cell.map((shift, i) => {
                          //By employee / Site - Day
                          return (
                            <ShiftView
                              key={i}
                              width={ShiftTimeDifferences(shift) * 88}
                              marginLeft={
                                i === 0
                                  ? ShiftStartHour(shift) * 88
                                  : (ShiftStartHour(shift) - ShiftEndHour(cell[i - 1])) * 88
                              }
                              shift={shift}
                              user={row.user}
                              handleClickOpenCreateSnglShiftModal={
                                handleClickOpenCreateSnglShiftModal
                              }
                              viewType={calendarConfig.viewType}
                            />
                          );
                        })}
                      </div>
                    ))}
                </div>
              );
            },
          });
        });
        break;
      default:
    }
    setCalendarColumns(calColumns);
  }, [
    calendarConfig.currentWeek,
    handleClickOpenCreateSnglShiftModal,
    calendarConfig.calendarType,
    calendarConfig.viewType,
  ]);

  const calcData = () => {
    const currentEmployeeWithShift = data
      .flatMap((s) => s.user_shifts)
      .map((shift) => shift.user.id);
    let users = [
      { id: null },
      ...employees.filter((em) => currentEmployeeWithShift.includes(em.id)),
    ];

    let finalData = [];
    switch (calendarConfig.viewType) {
      case 'View by Employees':
        switch (calendarConfig.calendarType) {
          case 'Day':
            users.forEach((user) => {
              let baseObj = { user: user };
              baseObj[hours[0].name] = shiftForHourAndUser(user);
              finalData.push(baseObj);
            });
            break;

          default:
            users.forEach((user) => {
              let baseObj = { user: user };
              for (let index = 0; index < 7; index++) {
                const day = moment.utc(calendarConfig.currentWeek.start).add(index, 'days');
                baseObj[day.format('DDMMYYYY')] = shiftForDateAndUser(day, user);
              }
              finalData.push(baseObj);
            });
            break;
        }
        break;
      case 'View by Sites':
        let customSites = [{ id: null }, ...sites.filter(el => currentSites.includes(el.id))];
        switch (calendarConfig.calendarType) {
          case 'Day':
            //GET EMPLOYESS FROM DATA
            customSites.forEach((site) => {
              let baseObj = { site: site };
              baseObj[hours[0].name] = shiftForHourAndSite(site);
              finalData.push(baseObj);
            });
            break;

          default:
            //GET EMPLOYESS FROM DATA
            customSites.forEach((site) => {
              let baseObj = { site: site };
              for (let index = 0; index < 7; index++) {
                const day = moment.utc(calendarConfig.currentWeek.start).add(index, 'days');
                baseObj[day.format('DDMMYYYY')] = shiftForDateAndSite(day, site);
              }
              finalData.push(baseObj);
            });
            break;
        }

        break;
      default:
        break;
    }
    return finalData;
  };

  const shiftForDateAndUser = (date, user) => {
    data.forEach((element) => {});
    if (!user.id) {
      //withous users
      return data.filter(
        (d) =>
          moment.utc(d.shift_date).isSame(date, 'date') &&
          (d.user_shifts === null || d.user_shifts.length === 0),
      );
    } else {
      return data.filter(
        (d) =>
          moment.utc(d.shift_date).isSame(date, 'date') &&
          d.user_shifts !== null &&
          d.user_shifts.length > 0 &&
          d.user_shifts.filter((us) => us.user.id === user.id).length > 0,
      );
    }
  };

  const shiftForDateAndSite = (date, site) => {
    if (!site.id) {
      //withous users
      return data.filter(
        (d) =>
          moment.utc(d.shift_date).isSame(date, 'date') &&
          (d.site === null || d.site === undefined),
      );
    } else {
      return data.filter(
        (d) => moment.utc(d.shift_date).isSame(date, 'date') && d.site && d.site.id === site.id,
      );
    }
  };

  const shiftForHourAndUser = (user) => {
    if (!user.id) {
      //withous users
      return groupShiftsForDayFilter(
        data.filter((d) => d.user_shifts === null || d.user_shifts.length === 0),
      );
    } else {
      return groupShiftsForDayFilter(
        data.filter(
          (d) =>
            d.user_shifts !== null &&
            d.user_shifts.length > 0 &&
            d.user_shifts.filter((us) => us.user.id === user.id).length > 0,
        ),
      );
    }
  };

  const shiftForHourAndSite = (site) => {
    if (!site.id) {
      //withous users
      return groupShiftsForDayFilter(data.filter((d) => d.site === null || d.site === undefined));
    } else {
      return groupShiftsForDayFilter(data.filter((d) => d.site && d.site.id === site.id));
    }
  };

  const groupShiftsForDayFilter = (shiftsList) => {
    let grouped = [];
    if (calendarConfig.viewType === 'View by Employees') {
      //Group
      shiftsList.forEach((swu) => {
        let shiftStartHour = parseInt(moment.utc(swu.start_time).format('HH'));
        let shiftEndHour = parseInt(moment.utc(swu.end_time).format('HH'));
        //12a - 12a
        shiftEndHour = shiftEndHour === 0 ? 24 : shiftEndHour;
        //to the next days 11p - 1a
        shiftEndHour = shiftEndHour < shiftStartHour ? 24 : shiftEndHour;
        if (grouped.length === 0) {
          grouped.push([swu]);
        } else {
          grouped.forEach((groupedRow, index) => {
            let startHours = groupedRow.map((gr) =>
              parseInt(moment.utc(gr.start_time).format('HH')),
            );
            let endHours = groupedRow.map((gr) => parseInt(moment.utc(gr.end_time).format('HH')));
            if (
              (shiftStartHour >= Math.max(...endHours) ||
                shiftEndHour <= Math.min(...startHours)) &&
              Math.max(...endHours) !== 0
            ) {
              grouped[index] = [...groupedRow, ...[swu]];
            } else {
              grouped.push([swu]);
            }
          });
        }
      });
      //Delete repeated
      let processed = [];
      grouped.forEach((groupedRow) => {
        groupedRow.forEach((element, index) => {
          if (processed.includes(element.id)) {
            groupedRow.splice(index, 1);
          } else {
            processed.push(element.id);
          }
        });
      });
      //Delete repeated just to be sure XD (bug)
      processed = [];
      grouped.forEach((groupedRow) => {
        groupedRow.forEach((element, index) => {
          if (processed.includes(element.id)) {
            groupedRow.splice(index, 1);
          } else {
            processed.push(element.id);
          }
        });
      });
    } else {
      let groupedNoUsers = [];
      let shiftsListModified = [];

      shiftsList.forEach((swu) => {
        if (swu.user_shifts && swu.user_shifts.length > 0) {
          for (let i = 0; i < swu.user_shifts.length; i++) {
            const dataTemp = Object.assign({}, swu);
            const users = [...swu.user_shifts];
            const userTemp = swu.user_shifts[0];
            users[0] = swu.user_shifts[i];
            users[i] = userTemp;
            dataTemp.user_shifts = users;
            shiftsListModified.push(Object.assign({}, dataTemp));
          }
        } else {
          groupedNoUsers.push(swu);
        }
      });

      grouped.push([...groupedNoUsers]);
      //Group
      shiftsListModified.forEach((swu) => {
        let found = false;
        grouped.forEach((g) => {
          if (
            g[0] &&
            g[0].user_shifts &&
            g[0].user_shifts.length > 0 &&
            g[0].user_shifts[0].user.id === swu.user_shifts[0].user.id
          ) {
            found = true;
          }
        });

        if (!found) {
          let test = shiftsListModified.filter(
            (shift) => shift.user_shifts[0].user.id === swu.user_shifts[0].user.id,
          );
          grouped.push([...test]);
        }
      });
    }
    //clean emptys
    grouped.forEach((element, index) => {
      if (element.length === 0) {
        grouped.splice(index, 1);
      }
    });
    //console.log('🚀 ~ file: index.js ~ line 907 ~ grouped.forEach ~ grouped', grouped);
    return grouped;
  };

  const updateShowWeekTemplate = useCallback(
    async (payload) => {
      dispatch(showTemplatesAction(payload));
    },
    [dispatch],
  );

  const uploadWeekTemplate = () => {
    let values = {};
    values.startAt = moment
      .utc(calendarConfig.currentWeek.start)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    values.endAt = moment
      .utc(calendarConfig.currentWeek.end)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    delete weekTemplate.shifts;
    setLoading(true);
    saveEntityData(`${SHIFTS_WEEEK_TEMPLATES}schedule/upload/week`, { weekTemplate, ...values })
      .then((res) => {
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.weekly_shift_template_created')}`,
          message: '',
        });
        //Close form modal
        setOpenLoadWeek(false);
        reloadSchedulerCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_weekly_shift_template_creating')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  const confirmUploadWeekTemplate = {
    buttonText: `${t('buttons.btn_load')}`,
    actionIcon: ico_upload,
    title: `${t('JobScheduler.modals.load_week_template_title')}`,
    subtitle: ``,
    bgcolor: PALETTE_COLOR.MAIN_BLUE,
    bordercolor: PALETTE_COLOR.MAIN_BLUE,
    borderhovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    bghovercolor: PALETTE_COLOR.MAIN_BLUE_LIGHT,
    boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
  };

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          reloadDataCalendar();
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  /***************************************************************************************** */
  return (
    <div ref={drop}>
      <ConditionalWrapper
        condition={isActive}
        wrapper={(children) => (
          <StyledLoader active={true} classNamePrefix="MyLoader_">
            {children}
          </StyledLoader>
        )}
      >
        {columns.length > 0 && (
          <>
            <AppTable
              key="calendar-view"
              isSearchable={false}
              allowOverFlowX={calendarConfig.calendarType === 'Day'}
              columns={columns}
              filtersAdditional={[
                {
                  filter: (
                    <Tooltip title={t('AppTable.advanced_filter')} placement="bottom">
                      <IconButtonWrapper
                        style={{ width: 40, height: 40 }}
                        aria-label={t('form_labels.organizations')}
                        onClick={handleShowAdvanceFilter}
                      >
                        <StyledBadge badgeContent={selectedFilters.length} color="success">
                          <IconWrapper
                            style={
                              showAdvanceFilter || selectedFilters.length > 0
                                ? { width: 30, height: 30 }
                                : {}
                            }
                            src={
                              showAdvanceFilter || selectedFilters.length > 0
                                ? ic_advance_filter_active
                                : ic_advance_filter
                            }
                            alt="Cap logo"
                          />
                        </StyledBadge>
                      </IconButtonWrapper>
                    </Tooltip>
                  ),
                },
                {
                  filter: (
                    <Menu
                      style={{ height: '42px' }}
                      menuButton={
                        <AdvanceFilterButtonWrapper>
                          {t(`JobScheduler.menu.${calendarConfig.calendarType.toLowerCase()}`)}
                          <IcArrow
                            style={{ marginLeft: 5, cursor: 'pointer' }}
                            status={'enabled'}
                            direction={'down'}
                          />
                        </AdvanceFilterButtonWrapper>
                      }
                    >
                      <MenuItem
                        onClick={(e) => {
                          reloadDataCalendar();
                          setCalendarConfig({ ...calendarConfig, calendarType: 'Day' });
                        }}
                      >
                        {t('JobScheduler.menu.day')}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          reloadDataCalendar();
                          setCalendarConfig({ ...calendarConfig, calendarType: 'Week' });
                        }}
                      >
                        {t('JobScheduler.menu.week')}
                      </MenuItem>
                    </Menu>
                  ),
                },
                {
                  filter: (
                    <PaginationTopButton>
                      <IconButton
                        aria-label={t('form_labels.job_scheduler')}
                        style={{ marginLeft: 2, marginRight: 2 }}
                        onClick={(e) => {
                          reloadDataCalendar();
                          if (calendarConfig.calendarType === 'Day') {
                            setCalendarConfig({
                              ...calendarConfig,
                              day: calendarConfig.day.add(-1, 'day'),
                            });
                          } else {
                            const prevStart = moment
                              .utc(calendarConfig.currentWeek.start)
                              .add(-7, 'day');
                            const prevEnd = moment
                              .utc(calendarConfig.currentWeek.start)
                              .add(-1, 'day');
                            setCalendarConfig({
                              ...calendarConfig,
                              currentWeek: { start: prevStart, end: prevEnd },
                            });
                          }
                        }}
                      >
                        <IcArrow
                          style={{ height: 10, width: 5 }}
                          direction="left"
                          status={'enabled'}
                        />
                      </IconButton>
                      <div style={{ color: '#1179BF', marginRight: 5 }}>
                        {calendarConfig.calendarType === 'Day'
                          ? `${calendarConfig.day.format('MMM')} ${calendarConfig.day.format('DD')}`
                          : `${calendarConfig.currentWeek.start.format(
                              'MMM',
                            )} ${calendarConfig.currentWeek.start.format(
                              'DD',
                            )} - ${calendarConfig.currentWeek.end.format('DD')}`}
                      </div>
                      <IconButton
                        aria-label={t('form_labels.job_scheduler')}
                        onClick={(e) => {
                          reloadDataCalendar();
                          if (calendarConfig.calendarType === 'Day') {
                            setCalendarConfig({
                              ...calendarConfig,
                              day: calendarConfig.day.add(1, 'day'),
                            });
                          } else {
                            const nextStart = moment
                              .utc(calendarConfig.currentWeek.end)
                              .add(1, 'day');
                            const nextEnd = moment.utc(calendarConfig.currentWeek.end).add(7, 'day');
                            setCalendarConfig({
                              ...calendarConfig,
                              currentWeek: { start: nextStart, end: nextEnd },
                            });
                          }
                        }}
                      >
                        <IcArrow
                          style={{ height: 10, width: 5 }}
                          direction={'right'}
                          status={'enabled'}
                        />
                      </IconButton>
                    </PaginationTopButton>
                  ),
                },
                {
                  filter: (
                    <AppButton
                      size="lg"
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      style={{ marginRight: 16 }}
                      onClick={(e) => {
                        if (calendarConfig.viewType === 'View by Employees') {
                          reloadDataCalendar();
                        } else {
                          reloadDataCalendarBySite();
                        }
                        setCalendarConfig({
                          ...calendarConfig,
                          currentWeek: {
                            start: moment.utc().startOf('week'),
                            end: moment.utc().endOf('week'),
                          },
                          day: moment(),
                        });
                      }}
                    >
                      {t('buttons.btn_today')}
                    </AppButton>
                  ),
                },
              ]}
              disablePagination={true}
              disableTopPagination={true}
              advanceFilterAlter={
                <AdvanceFilterWrapper in={showAdvanceFilter}>
                  <div style={{ padding: 20, display: 'flex' }}>
                    {advanceFilterColumns().map((c, index) => (
                      <>
                        <AppDropDownCheckBoxSelect
                          key={index}
                          showSelection={false}
                          options={c.advanceFilter.map((d) => ({ ...d, filter: d.filter }))}
                          isSearchable={false}
                          allowSelectAll={true}
                          height={300}
                          onChange={onFilterChange}
                          selectAllControlOption={{
                            label: `${t('lbl_all')}`,
                            value: '*',
                            filter: c.dataField,
                          }}
                          defaultValue={selectedFilters}
                          targetComponent={
                            <AdvanceFilterButtonWrapper style={{ marginRight: 5 }}>
                              {c.text}
                              <ArrowWrapper
                                src={ic_arrow_down}
                                style={{ marginLeft: 5 }}
                                alt="IcArrowWrapper"
                              ></ArrowWrapper>
                            </AdvanceFilterButtonWrapper>
                          }
                        />
                        {selectedFilters
                          .filter((f) => !f.value.includes('*'))
                          .map((filter, i) => (
                            <FilterWrapper key={i}>
                              {filter.label}
                              {filter.isDisabled ? (
                                <></>
                              ) : (
                                <IconButton
                                  aria-label={t('AppTable.remove_filter')}
                                  onClick={() => {
                                    // if (calendarConfig.viewType === 'View by Employees') {
                                    reloadDataCalendar();
                                    // }
                                    selectedFilters.splice(selectedFilters.indexOf(filter), 1);
                                    setSelectedFilters([...selectedFilters]);
                                  }}
                                >
                                  <IconWrapper
                                    style={{ width: 8, height: 8 }}
                                    src={ic_remove}
                                    alt="remove"
                                  />
                                </IconButton>
                              )}
                            </FilterWrapper>
                          ))}
                      </>
                    ))}
                    {selectedFilters.length > 0 ? (
                      <AdvanceFilterButtonWrapper
                        style={{ cursor: 'pointer', color: MAIN_BLUE }}
                        onClick={(e) => {
                          // if (calendarConfig.viewType === 'View by Employees') {
                          reloadDataCalendar();
                          // }
                          setSelectedFilters([]);
                        }}
                      >
                        {t('AppTable.reset_all')}
                      </AdvanceFilterButtonWrapper>
                    ) : null}
                  </div>
                </AdvanceFilterWrapper>
              }
              loading={loading}
              data={calcData()}
              trailingBtn={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 40,
                    position: 'absolute',
                    right: 0,
                    paddingRight: 20,
                  }}
                >
                  <AditionalActions
                    currentdata={data}
                    currentWeek={calendarConfig.currentWeek}
                    type={calendarConfig.calendarType}
                    group={group}
                    allowEditActions={isUserPermissionJS}
                  />
                  {/*<AppIconButton
                    icon={ic_danger_triangle}
                    title={'Conflicts'}
                    iconWrapperStyle={{ width: 20, height: 20 }}
                    badgeContent={2}
              />*/}
                  {isUserPermissionJS ? (
                    <AppMassPublishShiftButton shiftsList={data} loading={loading} />
                  ) : (
                    <></>
                  )}
                </div>
              }
              search_alter={
                <InputForm
                  style={{ marginLeft: 15, marginRight: 15 }}
                  height={'40px'}
                  widthspan={'50%'}
                  widthinput={'100%'}
                  placeholder={t('placeholder_search')}
                  icon={ic_search}
                  onChange={(e) => {
                    if (calendarConfig.viewType === 'View by Employees') {
                      setSearchTerm(e.target.value);
                    } else {
                      setSearchTermSites(e.target.value);
                    }
                  }}
                />
              }
            />
            {laodMoreEnabled && data.length > 0 && (
              <div style={{ width: '100%', paddingTop: '10px', paddingBottom: '10px', alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
                <AppDivButton
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  hovercolor={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.MAIN_BLUE}
                  style={{
                    fontSize: '14px',
                    width: '500px',
                  }}
                  loading={loading}
                  onClick={() => {
                    if (calendarConfig.viewType === 'View by Employees') {
                      setOffset((prev) => prev + limit);
                    } else {
                      setLoading(true);
                      const lastIndex = lastIndexSites + limit;
                      console.log('📌 - file: index.js:1770 - lastIndex:', lastIndex);
                      console.log('📌 - file: index.js:1771 - allSites:', allSites);
                      const sitesToCall = allSites.filter((_, index) => index >= lastIndex && index < lastIndex + limit);
                      console.log('📌 - file: index.js:1774 - sitesToCall:', sitesToCall);
                      setLastSitesCalled([...sitesToCall]);
                      setCurrentSites((prev) => [...prev, ...sitesToCall]);
                      setLastIndexSites(lastIndex);
                      if (lastIndex + limit >= allSites.length) {
                        setLaodMoreEnabled(false);
                      } else {
                        setLaodMoreEnabled(true);
                      }
                      setLoading(false);
                    }
                  }}
                >
                  {t('buttons.btn_load_more')}
                </AppDivButton>
              </div>
            )}
          </>
        )}

        <AppModal
          open={openLoadWeek}
          handleclose={() => setOpenLoadWeek(false)}
          title={''}
          boxshadow={'none'}
          inside={1}
        >
          <AppModalStatus
            actionIcon={confirmUploadWeekTemplate.actionIcon}
            title={confirmUploadWeekTemplate.title}
            subtitle={
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ color: '#000000', fontSize: 16, fontWeight: 400 }}>
                  {t('JobScheduler.modals.load_week_template_subtitle')}
                </div>
                <div
                  style={{
                    background: '#E7F5FF',
                    borderRadius: 10,
                    height: 114,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                  }}
                >
                  <div style={{ fontWeight: 500, fontSize: 13 }}> {weekTemplate.name}</div>
                  <div style={{ fontWeight: 300, fontSize: 13 }}>
                    {t('JobScheduler.modals.subtitle_copy_info')}
                  </div>
                  <div style={{ fontWeight: 400, fontSize: 32 }}>{weekTemplate.total_shifts}</div>
                </div>
              </div>
            }
            bgcolor={confirmUploadWeekTemplate.bgcolor}
            bordercolor={confirmUploadWeekTemplate.bordercolor}
            borderhovercolor={confirmUploadWeekTemplate.borderhovercolor}
            bghovercolor={confirmUploadWeekTemplate.bghovercolor}
            boxShadow={confirmUploadWeekTemplate.boxShadow}
            buttonText={confirmUploadWeekTemplate.buttonText}
            subject={''}
            isLoading={loading}
            buttonAction={() => uploadWeekTemplate()}
          />
        </AppModal>
      </ConditionalWrapper>
    </div>
  );
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
