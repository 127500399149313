import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import AppPanel from '@components/AppPanel';
import { AppModal, StateTag, HeaderBtn, AppOrgIcon } from '@components';
//import { AppTable, AppButton} from '@components';
import EncView from '@components/EncView';
import logo_edit from '@assets/icons/edit.svg';
//import Cell from '@assets/icons/Cell.svg';
//import Location from '@assets/icons/Location.svg';
import status_active from '@assets/icons/status_active.svg';
import status_archived from '@assets/icons/status_archived.svg';
import Status_block from '@assets/icons/Status_block.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
//import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import restore from '@assets/icons/ic_restore.svg';
//import group from '@assets/icons/group.svg';
import { EditOrganizations } from '@pages/Organizations/EditOrganizations';
import { AddSite } from '@pages/Organizations/AddSite';
//import { EditSite } from '@pages/Organizations/EditSite';
import { /*editEntityData,*/ getEntityData } from 'services';
import { ORGANIZATIONS_API } from '@services/CONSTANTS';
//import {  SITES_API, ORGANIZATIONS_USERS_API } from '@services/CONSTANTS';
import { size } from 'lodash';
import { Tooltip } from '@mui/material';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
//import {  CondOperator } from '@nestjsx/crud-request';
//import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import IconButton from '@mui/material/IconButton';
//import { AppModalStatus } from 'components/AppModalStatus';
//import ico_Archived from '@assets/icons/ico_Archived.svg';
//import ico_Block from '@assets/icons/ico_Block.svg';
//import ico_Restore from '@assets/icons/ico_Restore.svg';
//import { RestoreSite } from '../RestoreSite';
import { AddEmployee } from '@pages/Organizations/AddEmployee';
import { EditEmployee } from '@pages/Organizations/EditEmployee';
import { FilterRepresentatives, FilterSiteRepresentatives } from './FilterRepresentatives';
import ic_eye from '@assets/icons/ic_eye.svg';
import { VIEW_SITE, DASHBOARD } from '@navigation/CONSTANTS';

import { useTranslation } from 'react-i18next';

const StyledColor = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const Icon = styled.img``;

const StyleDivTitle = styled.div`
  bottom: 80.54%;
  border-radius: 52px;
  margin-right: 9px;
  margin-left: -15px;
  font-size: 20px;
  font-family: 'Poppins';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const StyleRow = styled.div`
  padding: 14px 44px 24px;
`;

const StyleTittle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #878e97;
`;

const StyleFields = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
`;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledIconLabel = styled.div`
  margin-left: 10px;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

/*const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;*/

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const OrgName = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

const OrgP = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const ViewOrganization = () => {
  const { t } = useTranslation();
  const { valuesDefinitions, userPermissions, userData } = useSelector((state) => state.app);
  let { organizationId } = useParams();
  let history = useHistory();
  //If site Manager only get sites where he is SM
  /*const filtersSites = React.useMemo(() => {
    let sitesToFilterBy = [];
    let filter = [
      { field: 'organization_id.id', operator: CondOperator.EQUALS, value: organizationId },
    ];
    if (userData && userData.isSiteManager && userData.sites && userData.sites.length > 0) {
      sitesToFilterBy = userData.sites.map((site) => site.id);
      filter = [
        ...filter,
        { field: 'id', operator: CondOperator.IN, value: sitesToFilterBy.map((site) => site) },
      ];
    }
    return filter;
  }, [organizationId, userData]);*/

  /* const joinsSites = React.useMemo(
     () => [
       { field: 'organizations_users_sites' },
       { field: 'organizations_users_sites.organization_user' },
       { field: 'organizations_users_sites.organization_user.user_id' },
       { field: 'organizations_users_sites.organization_user.user_id.role' },
     ],
     [],
   );*/

  /*const filteredEmployees = [
    { field: 'organization_id.id', operator: CondOperator.EQUALS, value: organizationId },
    { field: 'user_id.type.value_definition', operator: CondOperator.EQUALS, value: '010' },
  ];*/

  const [organizationData, setOrganizationData] = useState(null);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [showArchivedModal, setShowArchivedModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //Know if reload table
  const [loadParent, setLoadParent] = React.useState(true);
  const [openForm, setOpenForm] = React.useState(false);
  const [openSite, setOpenSite] = React.useState(false);
  const [openAssignedEmployess, setOpenAssignedEmployess] = React.useState(false);
  //const [openEditSite, setOpenEditSite] = React.useState(false);
  const [openEditEmployee, setOpenEditEmployee] = React.useState(false);
  //const [selectedSite, setSelectedSite] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState({});
  //const [openRestoreSite, setOpenRestoreSite] = React.useState(false);
  //const [openChangeStatusSite, setOpenChangeStatusSite] = React.useState(false);
  //const [siteItem, setSiteItem] = useState();
  //const [newSiteStatus, setNewSiteStatus] = useState();
  /*const [siteStatusModalContent, setSiteStatusModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });*/

  /***
   *
   */
  const siteStatusList = useMemo(
    () =>
      valuesDefinitions &&
      valuesDefinitions.filter((v) => v.definitionType.definition_type === 'SITE_STATUS'),
    [valuesDefinitions],
  );
  console.log(loadParent)
  /**
   * Avoid white page when enter to this page
   */
  React.useEffect(() => {
    siteStatusList?.sort(function (a, b) {
      if (a.value_definition_id > b.value_definition_id) {
        return 1;
      }
      if (a.value_definition_id < b.value_definition_id) {
        return -1;
      }
      return 0;
    });
  }, [siteStatusList]);

  //To know which action is: Edit or restore
  const [actionType, setActionType] = useState('');

  //Enable buttons
  const [enableButton, setEnableButton] = useState(true);

  /***
   * Depend on which groups system user has and its permissions, and which groups org has,
   * show or hide buttons
   */
  const showButtonsToSystemUser = React.useCallback(
    (orgInfo) => {
      let showButtons = false;
      //Get org sites
      const groupsSites = orgInfo?.organizations_sites.map(
        (orSite) => orSite?.groupsSite[0]?.group?.id,
      );
      if (groupsSites.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (groupsSites.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );

  //Get Data Organization by id
  useEffect(() => {
    const getOrgData = () => {
      setLoadParent(true);
      const qb = RequestQueryBuilder.create();
      // TODO LADY
      /*.setJoin({ field: 'organizations_sites' })
      .setJoin({ field: 'organizations_sites.status' })
      .setJoin({ field: 'organizations_sites.groupsSite' })
      .setJoin({ field: 'organizations_sites.groupsSite.group' });*/
      getEntityData(`${ORGANIZATIONS_API}${organizationId}`, qb.query())
        .then((res) => {
          setLoadParent(false);
          setOrganizationData(res);
          //If it is a system user, determine if he can edit org or add sites/employees
          if (userPermissions?.type === 'system') {
            setEnableButton(showButtonsToSystemUser(res));
          } else if (userData?.isSiteManager) {
            setEnableButton(false);
          }
        })
        .catch((err) => {
          console.log('axios err=', err);
        });

      return () => {
        console.log('axios cleanup.');
      };
    };
    if (userData) {
      if (userData?.isSiteManager) {
        if (userData?.organizations && userData?.organizations.length > 0) {
          if (userData?.organizations[0].id !== parseInt(organizationId)) {
            history.push(DASHBOARD);
          } else {
            getOrgData();
          }
        } else {
          //Site manager without Org
          history.push(DASHBOARD);
        }
      } else {
        getOrgData();
      }
    }
  }, [
    organizationId,
    showSucessModal,
    showArchivedModal,
    userPermissions,
    showButtonsToSystemUser,
    userData?.isSiteManager,
    userData?.organizations,
    history,
    userData,
  ]);

  // ************ Methods to handle modals
  const handleCloseModal = () => {
    setOpenForm(false);
  };

  const handleCloseSite = () => {
    setOpenSite(false);
  };

  /*const handleCloseEditSite = () => {
    setOpenEditSite(false);
  };*/

  /*const handleCloseRestoreSite = () => {
    setOpenRestoreSite(false);
  };*/

  /*const handleCloseChangeStatusSite = () => {
    setOpenChangeStatusSite(false);
  };*/

  const handleCloseAssignedEmployess = () => {
    setOpenAssignedEmployess(false);
  };

  /*const handleClickOpenRestoreSiteModal = () => {
    setOpenRestoreSite(true);
  };*/

  const handleClickOpenEditEmployeeModal = () => {
    setOpenEditEmployee(true);
  };

  const handleCloseEditEmployeeModal = () => {
    setOpenEditEmployee(false);
  };

  const handleClickOpenFormModal = (value) => {
    setOpenForm(true);
    setActionType(value);
  };

  /*const handleClickOpenSiteModal = () => {
    setOpenSite(true);
  };*/

  /*const handleClickOpenEditSiteModal = () => {
    setOpenEditSite(true);
  };*/

  /*const handleClickOpenAssignedEmployessModal = () => {
    setOpenAssignedEmployess(true);
  };*/

  // ************ End methods to handle modals

  /***
   * Handle modal change site status
   */
  /*const handleClickOpenChangeStatusSite = (item, newStatus) => {
    setOpenChangeStatusSite(true);
    setSiteItem(item);
    setNewSiteStatus(newStatus);

    switch (newStatus.value_definition) {
      case '030':
        setSiteStatusModalContent({
          buttonText: 'Archive',
          actionIcon: ico_Archived,
          title: 'Archive Site',
          subtitle: 'All relations with assigned users and shifts will be lost',
          bgcolor: PALETTE_COLOR.ACCENT_RED,
          bordercolor: PALETTE_COLOR.ACCENT_RED,
          borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        });
        break;
      case '020':
        setSiteStatusModalContent({
          buttonText: 'Block',
          actionIcon: ico_Block,
          title: 'Block Site',
          subtitle: 'All relations with assigned users and shifts will be suspended',
          bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
          bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
          borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
        });
        break;
      case '010':
        setSiteStatusModalContent({
          buttonText: 'Unblock',
          actionIcon: ico_Restore,
          title: 'Unblock Site',
          subtitle: 'All relations with assigned users and shifts will be restored',
          bgcolor: PALETTE_COLOR.ACCENT_GREEN,
          bordercolor: PALETTE_COLOR.ACCENT_GREEN,
          borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          boxShadow: '0px 4px 10px rgba(134, 217, 113, 0.3)',
        });
        break;
      default:
        break;
    }
  };*/

  /***
   * Handle change site status
   */
  /*const changeSiteStatus = () => {
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    console.log('requestChangeSiteStatus ', { status: newSiteStatus });
    editEntityData(`${SITES_API}${siteItem.id}`, { status: newSiteStatus })
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: 'Site status changed successfully',
          message: '',
        });
        handleCloseChangeStatusSite();
      })
      .catch((err) => {
        handleCloseChangeStatusSite();
        setShowSucessModal({
          show: true,
          type: 'error',
          title: 'Error changing Site status',
          message: '',
        });
      });
  };*/

 /* const getColumns = useMemo(() => {
    return [
      {
        dataField: 'name',
        text: `${t('table_columns.name')}`,
        sort: true,
        search: true,
        headerStyle: { width: '25%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          return (
            <Tooltip title={row.name}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.name}
              </StyledLabel>
            </Tooltip>
          );
        },
      },
      {
        dataField: 'site',
        text: `${t('table_columns.site_managers')}`,
        sort: false,
        headerStyle: { width: '20%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          return <FilterSiteRepresentatives key={row.id} site={row} />;
        },
      },
      {
        dataField: 'phone',
        text: `${t('table_columns.phone')}`,
        sort: false,
        search: true,
        headerStyle: { width: '14%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          return <StyledLabel>{row.phone}</StyledLabel>;
        },
      },
      {
        dataField: 'address',
        text: `${t('table_columns.address')}`,
        isDummyField: true,
        search: true,
        headerStyle: { width: '25%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          return (
            <Tooltip title={row.address}>
              <StyledLabel
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {row.address}
              </StyledLabel>
            </Tooltip>
          );
        },
      },
      {
        dataField: 'role.groupsSite',
        text: `${t('table_columns.group')}`,
        sort: false,
        headerStyle: { width: '12%' },
        style: { height: '54px', overflow: 'hidden' },
        formatter: (cellContent, row) => {
          if (size(row.groupsSite) > 0) {
            const groupsSites = row.groupsSite.filter((v) => v.created_at !== 'null');
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {groupsSites.map((org, index) => (
                  <StyledColor>{org.group.name}</StyledColor>
                ))}
              </div>
            );
          }
        },
      },
      {
        dataField: 'status.description',
        text: `${t('table_columns.status')}`,
        sort: false,
        search: true,
        headerStyle: { width: '10%' },
        style: { height: '54px' },
        formatter: (cellContent, row) => {
          if (typeof row.status !== 'undefined') {
            if (row.status !== 'null' && row.status !== null) {
              if (row.status.value_definition === '010') {
                return (
                  <StyledLabel>
                    <Status src={status_active} alt="logo"></Status>
                    <StyledIconLabel>
                      {t(`Organization.status.${row.status.description}`)}
                    </StyledIconLabel>
                  </StyledLabel>
                );
              } else if (row.status.value_definition === '020') {
                return (
                  <StyledLabel>
                    <Status src={Status_block} alt="logo"></Status>
                    <StyledIconLabel>
                      {t(`Organization.status.${row.status.description}`)}
                    </StyledIconLabel>
                  </StyledLabel>
                );
              } else if (row.status.value_definition === '030') {
                return (
                  <StyledLabel>
                    <Status src={status_archived} alt="logo"></Status>
                    <StyledIconLabel>
                      {t(`Organization.status.${row.status.description}`)}
                    </StyledIconLabel>
                  </StyledLabel>
                );
              }
            }
          }
        },
      },
      {
        dataField: 'edit',
        text: `${t('table_columns.view')}`,
        isDummyField: true,
        headerStyle: { width: '6%' },
        style: { height: '54px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
        formatter: (cellContent, row) => {
          return (
            <StyledLabel style={{ marginLeft: 0 }}>
              <Edit src={ic_eye} alt="logo"></Edit>
            </StyledLabel>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            history.push(VIEW_SITE.replace(':siteId', `${row.id}?b=true`));
          },
        },*/
        // formatter: (cellContent, row) => {
        //   //Decides if show or not site menu. Depends on system user permissions
        //   let showSiteMenu = false;
        //   if (userPermissions?.type === 'system') {
        //     if (size(row.groupsSite) > 0) {
        //       //Only get valid groups
        //       const groupsSites = row.groupsSite.filter((v) => v.created_at !== 'null');
        //       //Only get the first item on array
        //       const groupSite = groupsSites.length > 0 ? groupsSites[0] : null;
        //       //Ensure system user group is same that site group and syste user group has full access
        //       if (
        //         userPermissions?.systemUserGroups.filter(
        //           (systemG) => systemG?.id === groupSite?.group.id && systemG?.permission === '020',
        //         ).length > 0
        //       ) {
        //         showSiteMenu = true;
        //       }
        //     } else {
        //       //Site without group
        //       showSiteMenu = false;
        //     }
        //   } else {
        //     //Is not a system user. Can see menu
        //     showSiteMenu = true;
        //   }

        //   if (showSiteMenu) {
        //     return (
        //       <Menu
        //         align="end"
        //         direction="bottom"
        //         menuButton={
        //           <DropDownWrapper>
        //             <IconButton aria-label="edit" sx={{ padding: '17px 10px' }}>
        //               <Icon src={Cell} />
        //             </IconButton>
        //           </DropDownWrapper>
        //         }
        //         transition
        //       >
        //         {row?.status?.value_definition === '030' ? (
        //           <>
        //             <MenuItem onClick={() => handleClickOpenRestoreSiteModal()}>
        //               {'Restore Site'}
        //             </MenuItem>
        //             {/* Restore site */}
        //             <AppModal
        //               open={openRestoreSite}
        //               handleclose={handleCloseRestoreSite}
        //               title={'Restore Site'}
        //               inside={1}
        //             >
        //               <RestoreSite
        //                 handleclose={handleCloseRestoreSite}
        //                 setShowSucessModal={setShowSucessModal}
        //                 data={row}
        //                 siteData={row}
        //               />
        //             </AppModal>
        //           </>
        //         ) : (
        //           <>
        //             <MenuItem
        //               onClick={() => {
        //                 handleClickOpenEditSiteModal();
        //                 setSelectedSite(row);
        //               }}
        //             >
        //               {'Edit Site'}
        //             </MenuItem>
        //             {siteStatusList?.map(
        //               (s) =>
        //                 row?.status?.value_definition !== s.value_definition && (
        //                   <MenuItem onClick={() => handleClickOpenChangeStatusSite(row, s)}>
        //                     {s.value_definition === '010'
        //                       ? 'Unblock'
        //                       : s.value_definition === '020'
        //                       ? 'Block'
        //                       : s.value_definition === '030' && 'Archive'}{' '}
        //                     Site
        //                   </MenuItem>
        //                 ),
        //             )}
        //           </>
        //         )}
        //       </Menu>
        //     );
        //   } else {
        //     return <></>;
        //   }
        // },
        // events: {
        //   onClick: (e, column, columnIndex, row, rowIndex) => {},
        // },
   /*   },
    ];
  }, [/*openRestoreSite, siteStatusList, userPermissions, history, t]);*/

/*  const getColumnsEmployess = [
    {
      dataField: 'user_id.name',
      text: `${t('table_columns.name')}`,
      sort: true,
      search: true,
      headerStyle: { width: '12%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user_id.name}</StyledLabel>;
      },
    },
    {
      dataField: 'user_id.email',
      text: `${t('table_columns.email')}`,
      sort: false,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title={row.user_id.email}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {row.user_id.email}
            </StyledLabel>
          </Tooltip>
        );
      },
    },
    {
      dataField: 'user_id.phone',
      text: `${t('table_columns.phone')}`,
      sort: false,
      search: true,
      headerStyle: { width: '18%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.user_id.phone}</StyledLabel>;
      },
    },
    {
      dataField: 'job',
      text: `${t('table_columns.job_title')}`,
      isDummyField: true,
      search: true,
      headerStyle: { width: '16%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (size(row.user_id.jobs_titles) > 0) {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {row.user_id.jobs_titles
                .filter(
                  (v) => v.created_at !== 'null' && v.status && v.status.value_definition === '020',
                )
                .map((org, index) => (
                  <StyledColor>{org.job_title.description}</StyledColor>
                ))}
            </div>
          );
        }
      },
    },
    {
      dataField: 'organization_user_sites',
      text: `${t('table_columns.sites_assigned')}`,
      sort: false,
      headerStyle: { width: '16%' },
      style: { height: '54px', overflow: 'hidden' },
      formatter: (cellContent, row) => {
        if (size(row.organization_user_sites) > 0) {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {row.organization_user_sites.map((org, index) => (
                <StyledColor>{org.site.name}</StyledColor>
              ))}
            </div>
          );
        }
      },
    },
    {
      dataField: 'status_id.description',
      text: `${t('table_columns.status')}`,
      sort: true,
      search: true,
      headerStyle: { width: '18%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (typeof row.status_id !== 'undefined') {
          if (row.status_id.value_definition === '010') {
            return (
              <StyledLabel>
                <Status src={status_active} alt="logo"></Status>
                <StyledIconLabel>
                  {t(`Users.status.${row.status_id.description}`)}
                </StyledIconLabel>
              </StyledLabel>
            );
          } else if (row.status_id.value_definition === '050') {
            return (
              <StyledLabel>
                <Status src={Status_block} alt="logo"></Status>
                <StyledIconLabel>
                  {t(`Users.status.${row.status_id.description}`)}
                </StyledIconLabel>
              </StyledLabel>
            );
          } else if (row.status_id.value_definition === '060') {
            return (
              <StyledLabel>
                <Status src={status_archived} alt="logo"></Status>
                <StyledIconLabel>
                  {t(`Users.status.${row.status_id.description}`)}
                </StyledIconLabel>
              </StyledLabel>
            );
          }
        }
      },
    },
    {
      dataField: 'edit',
      isDummyField: true,
      text: '',
      headerStyle: { width: '4%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (enableButton) {
          return (
            <StyledLabel
              style={{ marginLeft: '0px', justifyContent: 'center', alignitems: 'center' }}
            >
              <IconButton
                aria-label="edit"
                sx={{ padding: '7px 7px' }}
                onClick={() => {
                  handleClickOpenEditEmployeeModal();
                  setSelectedEmployee(row);
                }}
              >
                <Icon src={logo_edit} />
              </IconButton>
            </StyledLabel>
          );
        } else {
          return <></>;
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => { },
      },
    },
  ];*/

  return (
    <Container fluid>
      <EncView>
        <StyleDivTitle>
          <AppOrgIcon
            color={organizationData?.color}
            style={{ width: 50, height: 50, fontSize: 20, padding: 5 }}
          >
            {organizationData?.name.substring(0, 2).toUpperCase()}
          </AppOrgIcon>
        </StyleDivTitle>
        <SpaceEncName>
          <div>
            <OrgName style={{ margin: '0px' }}>{organizationData?.name}</OrgName>
            <OrgP style={{ color: PALETTE_COLOR.LIGHT_GREY }}>{t('Organization.organization.view.header_info')}</OrgP>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {organizationData?.status_id?.value_definition === '010' && (
            <>
              <StateTag
                width="auto"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Organization.organizations_status.${organizationData?.status_id.description}_organization`)}
              </StateTag>
            </>
          )}
          {organizationData?.status_id?.value_definition === '030' && (
            <>
              <StateTag
                width="auto"
                icon={status_archived}
                background={PALETTE_COLOR.ARCHIVED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_RED}
              >
                {t(`Organization.status.${organizationData?.status_id.description}`)}
              </StateTag>
              {!userData.isSiteManager && (
                <HeaderBtn
                  width="auto"
                  icon={restore}
                  action={() => handleClickOpenFormModal('restore')}
                >
                  {t('buttons.btn_restore_organization')}
                </HeaderBtn>
              )}
            </>
          )}
        </SpaceEnc>
      </EncView>

      <AppPanel
        notitlepadding={`true`}
        padding_card="15px"
        title={t('Organization.view.organization_information_title')}
        icon={organizationData?.status_id?.value_definition === '010' && enableButton && logo_edit}
        width={"100%"}
        call={() => handleClickOpenFormModal('edit')}
      >
        <StyleRow>
          <StyleTittle>
            <Row>
              <Col>{t('form_labels.name')}</Col>
              <Col>{t('form_labels.address')}</Col>
              <Col>{t('form_labels.phone')}</Col>
              <Col>{t('form_labels.email')}</Col>
            </Row>
          </StyleTittle>
          <StyleFields>
            <Row>
              <Col>{organizationData?.name}</Col>
              <Col>{organizationData?.address}</Col>
              <Col>{organizationData?.phone}</Col>
              <Col>{organizationData?.email}</Col>
            </Row>
          </StyleFields>
        </StyleRow>
        <StyleRow>
          <StyleTittle>
            <Row>
              <Col>{t('form_labels.representatives')}</Col>
              <Col>{t('form_labels.organization_id')}</Col>
              <Col>{t('form_labels.work_week')}</Col>
              <Col>{t('form_labels.invoicing_period')}</Col>
            </Row>{' '}
          </StyleTittle>
          <StyleFields>
            <Row>
              <Col>
                <FilterRepresentatives org={organizationData} />
              </Col>
              <Col>{organizationData?.id}</Col>
              {organizationData?.work_week_start_id ? (
                <Col>
                  {t(`week_days.${organizationData?.work_week_start_id?.description.replace(/\s/g, '_')}`)} -{' '}
                  {t(`week_days.${organizationData?.work_week_end_id?.description.replace(/\s/g, '_')}`)}
                </Col>
              ) : (
                <Col></Col>
              )}
              <Col>{t(`invoicing_periods.${organizationData?.invoicing_period_id?.description.replace(/\s/g, '_')}`)}</Col>
            </Row>
          </StyleFields>
        </StyleRow>
      </AppPanel>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {showArchivedModal.show && (
        <CustomizedSnackbar
          show={showArchivedModal.show}
          type={showArchivedModal.type}
          title={showArchivedModal.title}
          message={showArchivedModal.message}
        />
      )}
      {/* Edit/restore organization */}
      <AppModal
        open={openForm}
        handleclose={handleCloseModal}
        title={actionType === 'edit' ? `${t('Organization.organization.modal_edit.title')}` : `${t('Organization.organization.modal_edit.restore_title')}`}
        inside={1}
      >
        <EditOrganizations
          handleclose={handleCloseModal}
          setShowSucessModal={setShowSucessModal}
          setShowArchivedModal={setShowArchivedModal}
          data={{
            id: organizationData?.id,
            name: organizationData?.name,
            phone: organizationData?.phone,
            address: organizationData?.address,
            email: organizationData?.email,
            color: organizationData?.color,
            representatives: organizationData?.representatives,
            work_week_start_id: organizationData?.work_week_start_id,
            work_week_end_id: organizationData?.work_week_end_id,
            invoicing_period_id: organizationData?.invoicing_period_id,
          }}
          editAction={actionType}
        />
      </AppModal>

      {/* Sites table */}
      {/*<AppTable
        endpointPath={SITES_API}
        columns={getColumns}
        isSearchable={false}
        joins={joinsSites}
        reload={loadParent}
        filters={filtersSites}
        disablePagination={false}
        disableTopPagination={true}
        noBorders={true}
        headerIcon={Location}
        headerText={t('table_headers.sites_header')}
        trailingBtn={
          organizationData?.status_id?.value_definition === '010' &&
          enableButton && (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <AppButton
                tooltiptitle={t('tooltips.add_sites')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleClickOpenSiteModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            </div>
          )
        }
      />*/}

      {/* Add site */}
      <AppModal open={openSite} handleclose={handleCloseSite} title={t('Organization.modals.add_site_title')} inside={1}>
        <AddSite
          handleclose={handleCloseSite}
          setShowSucessModal={setShowSucessModal}
          data={organizationData}
          setLoadParent={setLoadParent}
        />
      </AppModal>

      {/* Change site status */}
      {/* <AppModal
        open={openChangeStatusSite}
        handleclose={handleCloseChangeStatusSite}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={siteStatusModalContent.actionIcon}
          title={siteStatusModalContent.title}
          subtitle={siteStatusModalContent.subtitle}
          bgcolor={siteStatusModalContent.bgcolor}
          bordercolor={siteStatusModalContent.bordercolor}
          borderhovercolor={siteStatusModalContent.borderhovercolor}
          bghovercolor={siteStatusModalContent.bghovercolor}
          boxShadow={siteStatusModalContent.boxShadow}
          buttonText={siteStatusModalContent.buttonText}
          subject={siteItem?.name}
          buttonAction={changeSiteStatus}
        />
      </AppModal> */}

      {/* Assigned employees table - All users except SM can see this table */}
      {/*false && (
        <AppPanel
          title={t('Organization.view.assigned_employees_title')}
          width="100%"
          icon_label={Location}
        >
          <AppTable
            endpointPath={ORGANIZATIONS_USERS_API}
            columns={getColumnsEmployess}
            reload={!showSucessModal.show}
            filters={filteredEmployees}
            disablePagination={true}
            disableTopPagination={true}
            joins={[
              { field: 'organization_user_sites' },
              { field: 'organization_user_sites.site' },
            ]}
            trailingBtn={
              organizationData?.status_id?.value_definition === '010' &&
              enableButton && (
                <AppButton
                  tooltiptitle={t('tooltips.add_assigned_employees')}
                  bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                  bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                  bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                  borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                  icon={ic_add_circle}
                  onClick={() => handleClickOpenAssignedEmployessModal()}
                >
                  {t('buttons.btn_add')}
                </AppButton>
              )
            }
          />
        </AppPanel>
      )*/}

      {/* Add employee */}
      <AppModal
        open={openAssignedEmployess}
        handleclose={handleCloseAssignedEmployess}
        title={t('Organization.modals.add_employee_title')}
        inside={1}
      >
        <AddEmployee
          handleclose={handleCloseAssignedEmployess}
          setShowSucessModal={setShowSucessModal}
          data={{ id_organization: organizationId }}
        />
      </AppModal>

      {/* Edit employee */}
      <AppModal
        open={openEditEmployee}
        handleclose={handleCloseEditEmployeeModal}
        title={t('Organization.modals.edit_employee_assignment_title')}
        inside={1}
      >
        <EditEmployee
          handleclose={handleCloseEditEmployeeModal}
          setShowSucessModal={setShowSucessModal}
          data={{ employee: selectedEmployee }}
        />
      </AppModal>

      {/* Edit site */}
      {/* <AppModal
        open={openEditSite}
        handleclose={handleCloseEditSite}
        title={'Edit a Site'}
        inside={1}
      >
        <EditSite
          handleclose={handleCloseEditSite}
          setShowSucessModal={setShowSucessModal}
          siteData={selectedSite}
        />
      </AppModal> */}
    </Container>
  );
};
