import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '@assets/images/logo.svg';
import Avatar from '@assets/icons/ic_no_avatar.svg';
import ic_notification from '@assets/icons/ic_notification.svg';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import back from '@assets/icons/back.svg';
import styled from 'styled-components';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useAuth } from '@auth/ProvideAuth';
import { useDispatch } from 'react-redux';
import { setAppLoadingAction } from '@redux/actions/appActions';
import { PROFILE, SETTINGS } from '@navigation/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useSelector } from 'react-redux';
import { AppAvatar, AppNotificationList } from '@components';
import { IconButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import close_square from '@assets/icons/close_square.svg';
import { NOTIFICATIONS_API } from '@services/CONSTANTS';
import { getEntityData } from 'services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import Badge from '@mui/material/Badge';
import { setReloadNotifications } from 'redux/actions/appActions';

import { useTranslation } from 'react-i18next';

const BACKGROUND_BlUE = PALETTE_COLOR.BACKGROUND_BlUE;
const MAIN_WHITE = PALETTE_COLOR.MAIN_WHITE;

const HeaderBar = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 60px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  padding: 10px 10px 10px 18px;
  z-index: 1;
  > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const ImageWrapper = styled.img``;

const IconWrapper = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 12px;
`;

const DropDownDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;

const ArrowDown = styled.img`
  height: 27px;
  cursor: pointer;
  margin-top: 5px;
`;

const NameWrapper = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;

const RoleWrapper = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Middle Gray */

  color: #6d737b;
  text-transform: capitalize;
`;

const MenuItemWrapper = styled(MenuItem)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 30px 8px 30px;
  cursor: ${(props) => (props.disableSelection ? 'default' : 'pointer')};
  &:hover {
    background-color: ${(props) => (props.disableSelection ? '#ffffff' : BACKGROUND_BlUE)};
  }
`;

const FirstMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-style: normal;
  > * {
    &:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
    &:last-child {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #6d737b;
    }
  }
`;

const CustomModalWrapper = styled.div`
  background: #ffffff;
`;

const CustomModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
`;
const StyledPaper = styled.div`
  background: #ffffff;
  width: 400px;
  border-radius: 0px !important;
  overflow: hidden !important;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 2,
    border: `2px solid ${MAIN_WHITE}`,
    padding: '0 4px',
    background: 'red',
  },
}));

const Icon = styled.img`
  border-radius: 60px;
  margin-left: 12px;
`;

// It's advisable to put styles object outside React component scope whenever possible.
const menuStyles = {
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
  borderRadius: 16,
  marginTop: 15,
  marginLeft: -10,
  paddingTop: 24,
};

const Header = () => {
  const { t } = useTranslation();
  const { userData, reloadNotifications } = useSelector((state) => state.app);
  const [notificationIsOpen, setNotificationIsOpen] = React.useState(false);
  const [notificationsCount, setNotificationsCount] = React.useState([]);
  const avatarConfig = userData.id
    ? userData.avatar_config
      ? JSON.parse(userData.avatar_config)
      : null
    : null;
  let auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  //To get query param value
  const location = useLocation();
  const [params, setParams] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const singleValue = queryParams.get('b');
    setParams(singleValue);
  }, [params, location]);

  const reloadNotification = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadNotifications(isLoading));
    },
    [dispatch],
  );

  useEffect(() => {
    if (userData && userData.sub_cognito_id) {
      const qb = RequestQueryBuilder.create();
      getEntityData(`${NOTIFICATIONS_API}count/${userData.sub_cognito_id}/010`, qb.query())
        .then((res) => {
          console.log('🚀 ~ .then ~ res:', res);
          reloadNotification(false);
          //console.log('🚀 ~ file: Header.js ~ line 195 ~ .then ~ res', res);
          setNotificationsCount(res.count);
        })
        .catch((err) => {
          //console.log('🚀 ~ file: Header.js ~ line 202 ~ useEffect ~ err', err);
        });
    }
  }, [userData, notificationIsOpen, reloadNotifications, reloadNotification]);

  const loading = React.useCallback(
    async (isLoading) => {
      dispatch(setAppLoadingAction(isLoading));
    },
    [dispatch],
  );

  let goTo = (route) => {
    history.push(route);
  };

  let goBack = () => {
    history.goBack();
  };

  let logout = (values) => {
    loading(true);
    auth
      .signout()
      .then((res) => {
        loading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        loading(false);
      });
  };

  return (
    <HeaderBar>
      <div style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
        <ImageWrapper src={logo} alt="Xcelvations Logo" />
      </div>
      {params && (
        <div style={{ cursor: 'pointer' }} onClick={goBack}>
          <IconWrapper src={back} alt="back" style={{ marginRight: '4px', marginLeft: '60px' }} />{' '}
          {t('back')}
        </div>
      )}
      <RightHeaderContainer>
        <React.Fragment>
          <StyledBadge badgeContent={notificationsCount} color="success">
            <IconWrapper
              src={ic_notification}
              onClick={() => setNotificationIsOpen(true)}
              alt="Cap logo"
            />
          </StyledBadge>
          <Drawer
            anchor={'right'}
            open={notificationIsOpen}
            onClose={() => setNotificationIsOpen(false)}
            PaperProps={{ component: StyledPaper }}
          >
            <CustomModalWrapper>
              <CustomModalTitle>
                <div style={{ fontWeight: 400, fontSize: 24, margin: '18px 18px 20px 30px' }}>
                  {t('notifications')}
                </div>
                <IconButton
                  aria-label="close"
                  onClick={() => setNotificationIsOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 10,
                  }}
                >
                  <img src={close_square} alt="close_square" />
                </IconButton>
              </CustomModalTitle>
              <AppNotificationList handleClickCloseNotificationsModal={setNotificationIsOpen} />
            </CustomModalWrapper>
          </Drawer>
        </React.Fragment>

        <Menu
          menuStyles={menuStyles}
          menuButton={
            <div style={{ display: 'flex', cursor: 'pointer' }}>
              {userData.avatar_config ? (
                <AppAvatar
                  avatarId={avatarConfig?.avatarId}
                  bgColor={avatarConfig?.bgColor}
                  gender={avatarConfig?.gender}
                  skinColor={avatarConfig?.skinColor}
                  width={40}
                  height={40}
                />
              ) : userData?.photo_url ? (
                <Icon
                  src={userData?.photo_url}
                  style={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                  }}
                />
              ) : (
                <UserAvatar src={Avatar} alt="avatar" />
              )}
              <DropDownDetailsWrapper>
                <NameWrapper title={userData?.name}>{userData?.name}</NameWrapper>
                <RoleWrapper>
                  {userData.role
                    ? `${t(`roles.${userData.role.description.replace(/\s/g, '_')}`)}`
                    : userData?.cognitoUser?.idToken?.payload['cognito:groups']
                        .map((el) => `${t(`roles.cognito_group.${el.toLowerCase()}`)}`)
                        .join(',')}
                </RoleWrapper>
              </DropDownDetailsWrapper>
              <ArrowDown src={ic_arrow_down} alt="IcArrowDown"></ArrowDown>
            </div>
          }
          transition
        >
          <MenuItemWrapper disableSelection={1}>
            <FirstMenuItemWrapper>
              <div> {t('Profile.my_account')}</div>
              <div> {userData?.email}</div>
            </FirstMenuItemWrapper>
          </MenuItemWrapper>
          <MenuItemWrapper onClick={() => goTo(PROFILE)}>{t('Profile.profile')}</MenuItemWrapper>
          <MenuItemWrapper onClick={() => goTo(SETTINGS)}>{t('Profile.settings')}</MenuItemWrapper>
          <MenuItemWrapper onClick={() => logout()}>{t('Profile.logout')}</MenuItemWrapper>
        </Menu>
      </RightHeaderContainer>
    </HeaderBar>
  );
};
export default Header;
