import { AppAsyncLocationDropdown, AppButton, AppDropDownMultipleSelect, AppDropDownSelect, CustomizedSnackbar, IconColourPicker, InputForm, LabelForm } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { setGlobalOrganizationAction } from '@redux/actions/appActions';
import { getEntityData, saveEntityData } from '@services';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { object } from 'yup';
import * as yup from 'yup';

import { ORGANIZATIONS_API, USERS_API } from 'services/CONSTANTS';
import { CustomOption } from 'utils/avatar';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 5px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const StyleCheckBox = styled.div`
  display: flex;
  padding-left: 5%;
`;

const StyleCheckLabel = styled(Form.Check.Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  margin-left: 10px;
  font-weight: 300;
  color: ${PALETTE_COLOR.INACTIVE_GRAY};
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const AddOrganization = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [organizationsAdminUsers, setOrganizationsUsers] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  //Get values definitions - Week days and invoicing period
  const { valuesDefinitions } = useSelector((state) => state.app);
  let weekDays = valuesDefinitions.filter((v) => v.definitionType.definition_type === 'WEEK_DAYS');

  let invoicingPeriod = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'INVOICING_PERIOD',
  );

  //To sort
  function compare(a, b) {
    if (a.value_definition > b.value_definition) return 1;
    if (a.value_definition < b.value_definition) return -1;
  }

  //Sort
  weekDays = weekDays.sort(compare);
  invoicingPeriod = invoicingPeriod.sort(compare);

  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  useEffect(() => {
    setLoadingUsers(true);
    const qb = RequestQueryBuilder.create()
      .sortBy({ field: 'name', order: 'ASC' })
      .setFilter({
        field: 'type.description',
        operator: CondOperator.EQUALS,
        value: 'Organizations',
      })
      .setFilter({
        field: 'role.description',
        operator: CondOperator.EQUALS,
        value: 'Organization Representative',
      });
    getEntityData(USERS_API, qb.query())
      .then((res) => {
        setOrganizationsUsers(res);
        setLoadingUsers(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoadingUsers(false);
      });
    return () => {
      console.log('axios cleanup.');
    };
  }, []);

  useEffect(() => {
    let values = {
      name: '',
      phone: '',
      email: '',
      address: '',
      color: '',
      use_data: '',
      representativesDropdown: [],
      representative_phone: '',
      representative_email: '',
      work_week_start_id: '',
      work_week_end_id: '',
      invoicing_period_id: '',
    };
    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrganization = (values) => {
    setLoading(true);
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    const filteresRep = values.representativesDropdown.map((item) => item.value);
    nameWithoutSpaces = nameWithoutSpaces.trim();
    values.name = nameWithoutSpaces;
    values.representatives = filteresRep;
    //Add new fields
    values.work_week_start_id = values.work_week_start_id.value;
    values.work_week_end_id = values.work_week_end_id.value;
    values.invoicing_period_id = values.invoicing_period_id.value;
    saveEntityData(ORGANIZATIONS_API, values)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.organization_created')}`,
          message: '',
        });
        handleclose();
        setLoading(false);
        getEntityData(`${ORGANIZATIONS_API}organization/initial`, '').then((resMinOrg) => {
          if (Object.keys(resMinOrg).length > 0) {
            dispatch(setGlobalOrganizationAction(resMinOrg));
          }
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.organization_exists')}`,
            message: '',
          });
        } else {
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_organization_creating')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      {initialValues && (
        <Formik
          validationSchema={object().shape({
            name: yup.string().required(`${t('validation.name_required')}`),
            address: yup.string().required(`${t('validation.address_required')}`),
            email: yup
              .string()
              .email(`${t('validation.email_invalid')}`)
              .required(`${t('validation.email_required')}`),
            phone: yup.string().required(`${t('validation.phone_required')}`),
            representativesDropdown: yup.array().nullable(),
            color: yup.string().required(`${t('validation.color_required')}`),
            work_week_start_id: yup
              .object()
              .nullable()
              .required(`${t('validation.start_day_required')}`),
            work_week_end_id: yup
              .object()
              .nullable()
              .required(`${t('validation.end_day_required')}`),
            invoicing_period_id: yup
              .object()
              .nullable()
              .required(`${t('validation.invoicing_period_required')}`),
          })}
          onSubmit={addOrganization}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
            <div style={{ width: '600px', fontSize: 14 }}>
              <Form
                onSubmit={handleSubmit}
                noValidate
                className="baseForm"
                style={{ padding: '23px' }}
              >
                <div>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.name')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        className="mb-0"
                        height={'40px'}
                        placeholder={t('required')}
                        name="name"
                        inpfontsize={'14px'}
                        value={values.name}
                        invalid={touched.name && errors.name}
                        onChange={handleChange}
                        testId="name"
                      />
                      {touched.name && errors.name && (
                        <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.color')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <IconColourPicker
                        id="color"
                        testId="color"
                        onClick={(selectColour) => {
                          if (selectColour !== '') {
                            setFieldValue('color', selectColour);
                          }
                        }}
                        invalid={touched.color && errors.color}
                      />
                      {touched.color && errors.color && (
                        <ErrorText className="px-2 mb-0">{errors.color}</ErrorText>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.address')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <AppAsyncLocationDropdown
                        loadOptions={(values) => loadLocationOptions(values)}
                        testId="address"
                        id="address"
                        setLeftPadding
                        height="200px"
                        placeholder={t('required')}
                        isClearable={true}
                        name="address"
                        onChange={(selected) => {
                          setFieldValue('address', selected?.value);
                        }}
                      />
                      {touched.address && errors.address && (
                        <ErrorText className="px-2 mb-0">{errors.address}</ErrorText>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.representatives')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <AppDropDownMultipleSelect
                        options={organizationsAdminUsers.map((option) => ({
                          label: option.name,
                          value: option,
                        }))}
                        testId="representativesDropdown"
                        noOptionsMessage={() => t('no_options')}
                        isClearable={true}
                        name="representativesDropdown"
                        customOption={CustomOption}
                        placeholder={t('optional')}
                        isLoading={loadingUsers}
                        value={values.representativesDropdown}
                        icon={null}
                        height={230}
                        onChange={(selected) => {
                          setFieldValue('representativesDropdown', selected);
                        }}
                        invalid={touched.representativesDropdown && errors.representativesDropdown}
                        defaultValue={[]}
                      />
                      {touched.representativesDropdown && errors.representativesDropdown && (
                        <ErrorText className="px-2 mb-0">
                          {errors.representativesDropdown}
                        </ErrorText>
                      )}
                      <StyleCheckBox className="pl-2">
                        <Form.Check
                          className="pt-2"
                          type="checkbox"
                          style={{
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            style={{ width: 22, height: 22, marginTop: 0, borderRadius: 8 }}
                            data-testid="use_first_representative"
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                if (
                                  values.representativesDropdown &&
                                  values.representativesDropdown.length > 0
                                ) {
                                  setFieldValue(
                                    `email`,
                                    values.representativesDropdown[0].value.email,
                                  );
                                  setFieldValue(
                                    `phone`,
                                    values.representativesDropdown[0].value.phone,
                                  );
                                }
                              } else {
                                setFieldValue(`email`, '');
                                setFieldValue(`phone`, '');
                              }
                            }}
                          />
                          <StyleCheckLabel style={{ fontSize: '14px' }}>
                            {t('Organization.use_first_representative')}
                          </StyleCheckLabel>
                        </Form.Check>
                      </StyleCheckBox>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.phone')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        className="mb-0"
                        inpfontsize={'14px'}
                        height={'40px'}
                        placeholder={t('required')}
                        name="phone"
                        ctype={'phone'}
                        inputProps={{
                          name: 'phone',
                          "data-testid": 'phone',
                        }}
                        country={'us'}
                        value={values.phone}
                        invalid={touched.phone && errors.phone}
                        onChange={(value, data, event, formattedValue) =>
                          setFieldValue('phone', formattedValue)
                        }
                        maxLength="10"
                        testId="phone"
                      />
                      {touched.phone && errors.phone && (
                        <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.email')}></LabelForm>
                    </Col>
                    <Col xs={9}>
                      <InputForm
                        className="mb-0"
                        height={'40px'}
                        inpfontsize={'14px'}
                        placeholder={t('required')}
                        name="email"
                        value={values.email}
                        invalid={touched.email && errors.email}
                        onChange={handleChange}
                        testId="email"
                      />
                      {touched.email && errors.email && (
                        <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.work_week')}></LabelForm>
                    </Col>
                    <Col xs={4}>
                      <AppDropDownSelect
                        options={weekDays.map((option) => ({
                          label: `${t(`week_days.${option.description.replace(/\s/g, '_')}`)}`,
                          value: option,
                        }))}
                        noOptionsMessage={() => t('no_options')}
                        isClearable={true}
                        height="8rem"
                        isSearchable={false}
                        name="work_week_start_id"
                        placeholder={t('placeholder_starts')}
                        isLoading={false}
                        onChange={(selected) => {
                          setFieldValue('work_week_start_id', selected);
                        }}
                        invalid={touched.work_week_start_id && errors.work_week_start_id}
                        defaultValue={values.work_week_start_id}
                        testId="work_week_start_id"
                      />
                      {touched.work_week_start_id && errors.work_week_start_id && (
                        <ErrorText className="px-2 mb-0">{errors.work_week_start_id}</ErrorText>
                      )}
                    </Col>
                    <Col xs={1} style={{ textAlign: 'center' }}>
                      <LabelForm tittle="-"></LabelForm>
                    </Col>
                    <Col xs={4}>
                      <AppDropDownSelect
                        options={weekDays.map((option) => ({
                          label: `${t(`week_days.${option.description.replace(/\s/g, '_')}`)}`,
                          value: option,
                        }))}
                        noOptionsMessage={() => t('no_options')}
                        isClearable={true}
                        height="8rem"
                        isSearchable={false}
                        name="work_week_end_id"
                        placeholder={t('placeholder_ends')}
                        isLoading={false}
                        onChange={(selected) => {
                          setFieldValue('work_week_end_id', selected);
                        }}
                        invalid={touched.work_week_end_id && errors.work_week_end_id}
                        defaultValue={values.work_week_end_id}
                        testId="work_week_end_id"
                      />
                      {touched.work_week_end_id && errors.work_week_end_id && (
                        <ErrorText className="px-2 mb-0">{errors.work_week_end_id}</ErrorText>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={3}>
                      <LabelForm tittle={t('form_labels.invoicing_period')}></LabelForm>
                    </Col>
                    <Col xs={6}>
                      <AppDropDownSelect
                        options={invoicingPeriod.map((option) => ({
                          label: `${t(
                            `invoicing_periods.${option.description.replace(/\s/g, '_')}`,
                          )}`,
                          value: option,
                        }))}
                        noOptionsMessage={() => t('no_options')}
                        isClearable={true}
                        height="8rem"
                        isSearchable={false}
                        name="invoicing_period_id"
                        placeholder={t('required')}
                        isLoading={false}
                        onChange={(selected) => {
                          setFieldValue('invoicing_period_id', selected);
                        }}
                        invalid={touched.invoicing_period_id && errors.invoicing_period_id}
                        defaultValue={values.invoicing_period_id}
                        testId="invoicing_period"
                      />
                      {touched.invoicing_period_id && errors.invoicing_period_id && (
                        <ErrorText className="px-2 mb-0">{errors.invoicing_period_id}</ErrorText>
                      )}
                    </Col>
                  </Row>
                </div>
                <StyleButtons>
                  <AppButton
                    loading={loading}
                    style={{
                      width: 'auto',
                      fontSize: '14px',
                    }}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bgcolor={PALETTE_COLOR.MAIN_GREEN}
                    bordercolor={PALETTE_COLOR.MAIN_GREEN}
                    bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    size="lg"
                    type="submit"
                    title={t('buttons.btn_confirm')}
                    testId="add_organization_confirm"
                  >
                    {t('buttons.btn_confirm')}
                  </AppButton>
                  <div style={{ margin: '0.0rem 20px 0px' }}>
                    <AppButton
                      loading={loading}
                      style={{
                        width: 'auto',
                        fontSize: '14px',
                      }}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      onClick={handleclose}
                      testId="add_organization_cancel"
                    >
                      {t('buttons.btn_cancel')}
                    </AppButton>
                  </div>
                  {showMessage.show && (
                    <CustomizedSnackbar
                      show={showMessage.show}
                      type={showMessage.type}
                      title={showMessage.title}
                      message={showMessage.message}
                    />
                  )}
                </StyleButtons>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
