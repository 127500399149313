import React, { useState, useEffect, useRef } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppModal, InputForm, AppButton } from '@components';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import styled from 'styled-components';
import { showMessageAction } from '@redux/actions/appActions';
import { SHIFTS_WEEEK_TEMPLATES } from '@services/CONSTANTS';
import template from '@assets/icons/template.svg';
import { object } from 'yup';
import { Formik } from 'formik';
import { saveEntityData } from '@services';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

const ModalContentWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  background-color: ${(props) => (props.bg ? props.bg : `${PALETTE_COLOR.MAIN_WHITE}`)};
  padding: 5px 30px 34px 30px;
`;

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const InputIcon = styled.img``;

const ModalText = styled.div`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '24px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '36px')};
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
`;

const DivBlueBox = styled.div`
  background: #e7f5ff;
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK} p {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-size: 32px;
    margin-top: 2px;
  }
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const SaveSiteWeekTemplate = ({
  currentdata,
  currentWeek,
  type,
  row,
  handleSaveSiteWeekAsTemplateModal,
  openSaveShiftsWeekTemplate,
}) => {
  const { t } = useTranslation();
  //Redux
  const dispatch = useDispatch();
  const [initialValuesTemplate, setInitialValuesTemplate] = useState(null);
  //Store shifts from a specific site
  const siteWeekShifts = useRef([]);
  const [loading, setLoading] = useState(false);

  /***
   *
   */
  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   *
   */
  useEffect(() => {
    let values = {
      name: '',
      total_shifts: 0,
      shifts: [],
    };
    setInitialValuesTemplate(values);
  }, []);

  /***
   * Filter shifts from selected site
   */
  useEffect(() => {
    siteWeekShifts.current = currentdata.filter((shift) => shift?.site?.id === row?.site?.id);
  }, [currentdata, row]);

  /***
   *
   */
  const saveWeekTemplate = (values) => {
    //Only if array has data
    if (siteWeekShifts.current && siteWeekShifts.current.length > 0) {
      //Add properties to value obj
      values.total_shifts = siteWeekShifts.current.length;
      values.type = type;
      //Add on an array each shift
      siteWeekShifts.current.forEach((data, i) => {
        delete data.rows;
        values.shifts[i] = { id: data.id };
      });
      //Add week dates
      values.startAt = moment
        .utc(currentWeek.start)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format();
      values.endAt = moment
        .utc(currentWeek.end)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format();
      setLoading(true);
      saveEntityData(`${SHIFTS_WEEEK_TEMPLATES}`, values)
        .then((res) => {
          showMessage({
            show: true,
            type: 'success',
            title: `${t('success_messages.weekly_shift_template_created')}`,
            message: '',
          });
          //Close form modal
          handleSaveSiteWeekAsTemplateModal();
          setLoading(false);
        })
        .catch((err) => {
          showMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_weekly_shift_template_creating')}`,
            message: '',
          });
          setLoading(false);
        });
    }
  };

  return (
    <AppModal
      open={openSaveShiftsWeekTemplate}
      handleclose={handleSaveSiteWeekAsTemplateModal}
      inside={1}
    >
      <ModalContentWrapper width={'600px'}>
        <StyleSpaceIcon style={{ marginTop: 30, marginBottom: 18 }}>
          <InputIcon src={template} />
        </StyleSpaceIcon>
        <ModalText
          fontWeight={'400'}
          fontSize={'16px'}
          lineHeight={'24px'}
          style={{ marginBottom: 2 }}
        >{`${row.site.name}`}</ModalText>
        <ModalText style={{ marginBottom: 16 }}>
          {t('form_labels.title_site_week_template')}
        </ModalText>
        <Formik
          validationSchema={object().shape({
            name: Yup.string()
              .max(200, `${t('JobScheduler.validation.site_week_template.must_be_title')}`)
              .required(`${t('validation.title_required')}`),
          })}
          onSubmit={saveWeekTemplate}
          initialValues={initialValuesTemplate}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <div style={{ width: '600px' }}>
              <Form onSubmit={handleSubmit} noValidate className="baseForm">
                <InputForm
                  className="mb-0"
                  fontSize="14px"
                  height={'40px'}
                  width={'530px'}
                  placeholder={t('placeholder_title')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
                <DivBlueBox width={'530px'}>
                  <p>{t('JobScheduler.modals.subtitle_save_info')}</p>
                  <span>{siteWeekShifts.current.length}</span>
                </DivBlueBox>
                <StyleSpaceButtons width={'530px'}>
                  <AppButton
                    style={{
                      width: 'auto',
                      height: 64,
                      boxShadow: '0px 4px 10px rgba(131, 180, 49, 0.3)',
                    }}
                    type="submit"
                    loading={loading}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    bgcolor={PALETTE_COLOR.MAIN_BLUE}
                    bordercolor={PALETTE_COLOR.MAIN_BLUE_LIGHT}
                    borderhovercolor={PALETTE_COLOR.MAIN_BLUE_LIGHT}
                  >
                    {t('buttons.btn_save')}
                  </AppButton>
                </StyleSpaceButtons>
              </Form>
            </div>
          )}
        </Formik>
      </ModalContentWrapper>
    </AppModal>
  );
};
export default SaveSiteWeekTemplate;
