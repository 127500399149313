import Archived from '@assets/icons/Archived.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import ic_eye from '@assets/icons/ic_eye.svg';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/status_blocked.svg';
import { AppButton, AppDarkTooltip, AppModal, AppOrgIcon, AppTable } from '@components';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { VIEW_ORGANIZATIONS } from '@navigation/CONSTANTS';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CondOperator } from '@nestjsx/crud-request';
import { setGlobalOrganizationAction } from '@redux/actions/appActions';
import { GROUPS_API, ORGANIZATIONS_API } from '@services/CONSTANTS';
import { formatFullDate } from '@utils/dateFormatHelper';
//import { size } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { getEntityData } from 'services';

import { AddOrganization } from './AddOrganization';
import { FilterRepresentatives } from './ViewOrganization/FilterRepresentatives';

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;
const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

/*const StyledGroups = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;*/

export const OrganizationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { valuesDefinitions, userPermissions, globalOrganizationData } = useSelector(
    (state) => state.app,
  );
  const [groups, setGroups] = React.useState([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });

  //Get org statuses
  const statuses =
    valuesDefinitions &&
    valuesDefinitions.filter((v) => v.definitionType.definition_type === 'ORGANIZATION_STATUS');

  //Store custom filters
  const [filters, setFilters] = React.useState(null);
  //Store if user can create a org
  const [canCreateOrg, setCanCreateOrg] = React.useState(false);
  //Joins
  const joins = React.useMemo(() => {
    /* return [
      { field: 'organizations_sites' },
      { field: 'organizations_sites.status' },
      { field: 'organizations_sites.groupsSite' },
      { field: 'organizations_sites.groupsSite.group' },
    ];*/
    return [];
  }, []);

  /**
   * Opens and closes modal
   */
  const handleAddOrgModal = () => {
    setOpenForm(!openForm);
  };

  let history = useHistory();

  /***
   *
   */
  let goTo = (route) => {
    history.push(route);
  };

  /***
   * Get groups to filter
   */
  console.log(groups);
  useEffect(() => {
    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroups(userPermissions?.systemUserGroups);
        } else {
          setGroups([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroups(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions]);

  /***
   * Only get org which belong to user groups
   */
  useEffect(() => {
    if (userPermissions && userPermissions?.type) {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setFilters([
            {
              field: 'organizationsGroupsSites.id',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
        } else {
          setFilters({
            field: 'organizationsGroupsSites.id',
            operator: CondOperator.IN,
            value: -1,
          });
        }
      } else {
        setFilters([]);
      }
    } else {
      setFilters(null);
    }
  }, [userPermissions]);

  /***
   *
   */
  // useEffect(() => {
  //   if (userPermissions) {
  //     if (userPermissions?.type === 'system') {
  //       setCanCreateOrg(userPermissions?.systemUserPermission);
  //     } else {
  //       setCanCreateOrg(true);
  //     }
  //   }
  // }, [userPermissions]);

  useEffect(() => {
    (async () => {
      try {
        if (Object.keys(globalOrganizationData).length === 0) {
          getEntityData(`${ORGANIZATIONS_API}organization/initial`, '').then((resMinOrg) => {
            if (Object.keys(resMinOrg).length > 0) {
              dispatch(setGlobalOrganizationAction(resMinOrg));
              const enableCreateOrg =
                Number(
                  resMinOrg.organizations_parameters.find(
                    (el) => el.name === 'enable_create_organizations',
                  ).value,
                ) > 0;
              if (enableCreateOrg) {
                if (userPermissions) {
                  if (userPermissions?.type === 'system') {
                    setCanCreateOrg(userPermissions?.systemUserPermission);
                  } else {
                    setCanCreateOrg(enableCreateOrg);
                  }
                }
              } else {
                setCanCreateOrg(enableCreateOrg);
              }
            } else {
              // When no organizations created yet
              if (userPermissions) {
                if (userPermissions?.type === 'system') {
                  setCanCreateOrg(userPermissions?.systemUserPermission);
                } else {
                  setCanCreateOrg(true);
                }
              }
            }
          });
        } else {
          const enableCreateOrg =
            Number(
              globalOrganizationData.organizations_parameters.find(
                (el) => el.name === 'enable_create_organizations',
              ).value,
            ) > 0;
          setCanCreateOrg(enableCreateOrg);
        }
      } catch (error) {
        console.log('📌 - file: OrganizationList.js:189 - error:', error);
      }
    })();
  }, [dispatch, globalOrganizationData, userPermissions]);

  const columns = [
    {
      dataField: 'name',
      text: `${t('table_columns.name')}`,
      sort: true,
      search: true,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.name}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              <AppOrgIcon
                color={row.color}
                style={{ width: 30, height: 30, fontSize: 12, padding: 5, display: 'inline-flex' }}
              >
                {row.name.substring(0, 2).toUpperCase()}
              </AppOrgIcon>
              <span style={{ marginLeft: 10 }}>{row.name}</span>
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'representatives',
      text: `${t('table_columns.representatives')}`,
      search: false,
      headerStyle: { width: '14%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        const representatives = row?.representatives
          ? row.representatives.filter((or) => or.user_id.role?.value_definition === '010')
          : [];
        return representatives.map((user) => user.user_id.name).join(',');
      },

      formatter: (cellContent, row) => {
        return <FilterRepresentatives key={row.id} org={row} />;
      },
    },
    {
      dataField: 'phone',
      text: `${t('table_columns.phone')}`,
      search: true,
      headerStyle: { width: '12%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.phone}</StyledLabel>;
      },
    },
    {
      dataField: 'email',
      text: `${t('table_columns.email')}`,
      sort: false,
      search: true,
      headerStyle: { width: '15%' },
      style: { height: '54px', overflow: 'hidden' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${cellContent}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {row.email}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    /*  {
      dataField: 'organizationsGroupsSites.name',
      text: `${t('table_columns.groups')}`,
      search: false,
      headerStyle: { width: '9%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        const filteredList = [
          ...new Set(row.organizations_sites?.map((item) => item.groupsSite[0]?.group?.name)),
        ];
        //ACTIVE ONLY
        return filteredList.join(',');
      },
      advanceFilter: groups && [...groups.map((s) => ({ label: s.name, value: s.name }))],
      formatter: (cellContent, row) => {
        const filteredList = [
          ...new Set(row.organizations_sites?.map((item) => item.groupsSite[0]?.group?.name)),
        ];
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filteredList
              .filter((el) => el !== undefined)
              .map((group, index) => (
                <StyledGroups key={index}>{group}</StyledGroups>
              ))}
          </div>
        );
      },
    },
    {
      dataField: 'organizations_sites.length',
      text: `${t('table_columns.sites')}`,
      search: false,
      headerStyle: { width: '5%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{size(row.organizations_sites)}</StyledLabel>;
      },
    },*/
    {
      dataField: 'status_id.description',
      text: `${t('table_columns.status')}`,
      sort: true,
      headerStyle: { width: '8%' },
      search: true,
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (row.status_id.value_definition === '010') {
          return (
            <StyledLabel>
              <Status src={status_active} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status_id.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        } else if (row.status_id.value_definition === '020') {
          return (
            <StyledLabel>
              <Status src={status_blocked} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status_id.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        } else {
          return (
            <StyledLabel>
              <Status src={Archived} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status_id.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
      },
      advanceFilter: statuses && [
        ...statuses
          .filter((s) => s.description !== 'Blocked')
          .map((s) => ({
            label: `${t(`Organization.status.${s.description}`)}`,
            value: s.description,
          })),
        {
          label: `${t('Users.archived_only')}`,
          value: 'archived*',
          isFooter: true,
          targetSelection: [`${t('Organization.status.Archived')}`],
        },
      ],
    },
    {
      dataField: 'updated_at',
      text: `${t('table_columns.last_modified')}`,
      sort: false,
      search: false,
      headerStyle: { width: '8%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        return row.updated_at && moment(row.updated_at).format('MM/DD/yyyy hh:mm A');
      },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {row.updated_at !== null ? `${formatFullDate(row.updated_at)}` : ''}
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'edit',
      text: `${t('table_columns.view')}`,
      isDummyField: true,
      headerStyle: { width: '5%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            <Edit src={ic_eye} alt="logo"></Edit>
          </StyledLabel>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          goTo(VIEW_ORGANIZATIONS.replace(':organizationId', `${row.id}?b=true`));
        },
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      {filters !== null && (
        <AppTable
          testId={'organizationList'}
          endpointPath={ORGANIZATIONS_API}
          joins={joins}
          filters={[]}
          //filters={filters}
          columns={columns}
          withAdvanceFilter={true}
          withColumnToggler={true}
          reload={showSucessModal.show}
          csvName={t('csv_names.export_organization_management_csv_name')}
          withExport={true}
          trailingBtn={
            canCreateOrg ? (
              <AppButton
                tooltiptitle={t('tooltips.add_organization')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleAddOrgModal()}
                testId="addOrganization"
              >
                {t('buttons.btn_add')}
              </AppButton>
            ) : (
              <></>
            )
          }
        />
      )}
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      <AppModal
        open={openForm}
        handleclose={handleAddOrgModal}
        title={t('Organization.organization.modal_add.title')}
        inside={1}
        testId="organizationFormModal"
      >
        <AddOrganization handleclose={handleAddOrgModal} setShowSucessModal={setShowSucessModal} />
      </AppModal>
    </div>
  );
};
