import React, { useState } from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { PALETTE_COLOR } from '@config/CONSTANTS';

import EditIcon from '@assets/icons/edit.svg';
const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
const BLACK = PALETTE_COLOR.BLACK;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${BLACK};
  :hover {
    cursor: pointer;
    color: ${MAIN_BLUE};
  }
`;

export const ShiftNestedCell = (props) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <StyledNestedCell
      style={{ display: 'flex', margin: '3px', paddingRight: '3px' }}
      className="pl-2"
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      {...props}
    >
      <Col className="label-container" xs={showEdit ? 10 : 12}>
        <span>{props.text}</span>
      </Col>
      {showEdit && (
        <Col
          xs={2}
          className="py-2 text-center justify-content-center"
          style={{
            backgroundColor: 'rgba(233, 239, 255, 0.9)',
            borderRadius: '8px',
            padding: '10px',
            display: 'flex',
          }}
        >
          {props.showIcon ? (
            <></>
          ) : (
            <img src={EditIcon} alt="edit" style={{ width: '13px', justifyContent: 'center' }} />
          )}
        </Col>
      )}
    </StyledNestedCell>
  );
};
