import React from 'react';
import chroma from 'chroma-js';

const AppFemaleAvatar = (props) => {
  switch (props.avatarId) {
    case 1:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_18328)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_18328)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <g clipPath="url(#clip2_4782_18328)">
            <path
              d="M-0.00111699 9.99802C7.99612 2.00187 13.0008 12.9993 13.0008 12.9993L10.9994 20.9987C10.9994 20.9987 8.99904 23.4984 10.9994 30.9984C12.9997 38.4984 8.49902 42.4984 8.49902 42.4984C8.49902 42.4984 4.99985 45 1.99985 43C-1.00015 41 -1.39775 39.0784 -1.00015 35.5C-0.500147 31 0.998999 32.4984 0.998999 27.9984C0.998999 23.4984 -7.99835 17.9942 -0.00111699 9.99802Z"
              fill="#1B0B47"
            />
            <path
              d="M13.0304 13.1813L18.5376 15.5626L14.9657 23.8235L9.45846 21.4422C9.45846 21.4422 8.49958 19.5 9.99785 16.5009C11.4961 13.5018 13.0304 13.1813 13.0304 13.1813Z"
              fill="#FFA0BD"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 41L16.6766 32.177C14.5574 32.7572 13 34.6967 13 37C13 39.7614 15.2386 42 18 42V50.3C16.4507 48.9346 15.0744 47.3086 13.9174 45.4696C12.2165 42.7662 11.0292 39.6655 10.4354 36.3768C9.84164 33.0881 9.85532 29.6881 10.4755 26.4061C11.0957 23.1242 12.308 20.0368 14.0305 17.3524C15.753 14.668 17.9456 12.4491 20.4603 10.8456C22.975 9.24202 25.7533 8.2911 28.6075 8.05703C29.2305 8.00594 29.854 7.98926 30.476 8.00666C30.6505 8.00222 30.8252 8 31 8C33.4951 8 35.9658 8.45265 38.271 9.33211C40.5762 10.2116 42.6707 11.5006 44.435 13.1256C46.1993 14.7507 47.5989 16.6798 48.5537 18.803C49.3002 20.4629 49.7634 22.2159 49.9301 24H50V25.5V28.2857C50 28.2857 49.6712 28.4383 49.0015 28.6525V37.1404C49.0015 37.1404 49.0015 38.5439 47.5015 39.5965C46.9824 39.9608 46.2837 40.241 45.5918 40.4517C45.7881 39 45.8888 37.5359 45.8888 36.0992C45.8888 35.0649 45.924 33.96 45.9604 32.8148V32.8148C45.9959 31.6968 46.0327 30.5404 46.0392 29.3736C44.1879 29.7129 41.6726 30 38.4615 30C30.6154 30 26 28.2857 26 28.2857V27.2872C25.3194 28.3507 24.5839 29.6375 24 31C23.25 32.75 23.25 34.5439 23.25 36.0515C23.25 37.5592 23.25 38.7807 22.5 39.386C21 40.5965 18 41 18 41Z"
              fill="#1B0B47"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <defs>
            <clipPath id="clip0_4782_18328">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_18328">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4782_18328">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 2:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_18348)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_18348)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <g clipPath="url(#clip2_4782_18348)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48 25C48 22.6362 47.5344 20.2956 46.6298 18.1117C45.7253 15.9278 44.3994 13.9435 42.7279 12.2721C41.0565 10.6006 39.0722 9.27475 36.8883 8.37017C34.7044 7.46558 32.3638 7 30 7C27.6362 7 25.2956 7.46558 23.1117 8.37017C20.9278 9.27475 18.9435 10.6006 17.2721 12.2721C15.6006 13.9435 14.2748 15.9278 13.3702 18.1117C12.5295 20.1412 12.068 22.306 12.007 24.4991L12 24.5V25V65H23V34.5975C25.4847 34.1193 29.7793 33.0675 35.2083 30.8407C40.6717 28.5996 44.0812 26.4823 45.8758 25.2039C46.1179 27.7278 46.0341 30.3637 45.9562 32.8148C45.9198 33.96 45.8846 35.0649 45.8846 36.0992C45.8846 36.5069 45.8765 36.9167 45.8604 37.3279C46.0914 37.255 46.3074 37.1751 46.4988 37.0877C47.9988 36.4035 47.9988 35.4912 47.9988 35.4912V25H48ZM15 33C14.8111 33.1416 14.6321 33.2968 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.6321 40.7032 14.8111 40.8584 15 41V33Z"
              fill="#AD3A20"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4782_18348">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_18348">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4782_18348">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 3:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32.0001" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_18408)">
            <path
              d="M23 37.0001C23 39.7615 20.7614 42.0001 18 42.0001C15.2386 42.0001 13 39.7615 13 37.0001C13 34.2386 15.2386 32.0001 18 32.0001C20.7614 32.0001 23 34.2386 23 37.0001Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41.0001H35V65.8705C35 65.8705 31.3571 68.0001 26.5 68.0001C21.6429 68.0001 18 64.5227 18 64.5227V41.0001Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9865C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0217 41.998 55.0001 35.498 56.5001C25.998 55.5001 17.428 45.0217 17.428 36.0992C14.9996 25.5001 15.4996 13.0001 31.9996 13.0001C48.4996 13.0001 45.8854 27.1768 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42.0001C36 41.4478 36.4477 41.0001 37 41.0001H38C38.5523 41.0001 39 41.4478 39 42.0001C39 42.5523 38.5523 43.0001 38 43.0001H37C36.4477 43.0001 36 42.5523 36 42.0001Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_18408)">
            <g clipPath="url(#clip2_4782_18408)">
              <path
                d="M7 69.0001C7 64.0295 11.0294 60.0001 16 60.0001H38C42.9706 60.0001 47 64.0295 47 69.0001V74.0001C47 78.9706 42.9706 83.0001 38 83.0001H16C11.0294 83.0001 7 78.9706 7 74.0001V69.0001Z"
                fill="#E05A33"
              />
            </g>
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2012C34.5759 28.0641 42.3536 30.6463 50.4999 22.5C54.4999 18.5 36.4999 1.50004 21.9999 11C10.6666 12.679 11.3143 19.4699 11.8142 24.7107C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0329 12.0695 28.4317 12.2002 28.7183C12.0394 29.9514 11.9999 31.1853 11.9999 32C11.9999 34.2092 13.7907 36 15.9999 36C18.209 36 19.9999 34.2092 19.9999 32C22.209 32 23.9999 30.2092 23.9999 28C23.9999 27.654 23.9559 27.3183 23.8734 26.9981C23.9154 26.9994 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5848C27.6572 25.7671 28.2956 25.979 28.9648 26.2012Z"
            fill="#9C6A5A"
          />
          <path
            d="M11.8425 22.5412C18.7053 15.5674 23 25.1588 23 25.1588L21.2825 32.1355C21.2825 32.1355 19.5659 34.3156 21.2825 40.8568C22.9991 47.3979 19.1368 50.8865 19.1368 50.8865C19.1368 50.8865 16.134 53.0683 13.5596 51.324C10.9852 49.5797 10.644 47.9037 10.9852 44.7828C11.4142 40.8581 12.7007 42.165 12.7007 38.2403C12.7007 34.3156 4.9797 29.5151 11.8425 22.5412Z"
            fill="#9C6A5A"
          />
          <path
            d="M49.9697 18.4618C45.0677 11.7059 42 20.9976 42 20.9976L43.2268 27.7563C43.2268 27.7563 44.4529 29.8683 43.2268 36.205C42.0007 42.5417 44.7594 45.9213 44.7594 45.9213C44.7594 45.9213 46.9043 48.0349 48.7431 46.3451C50.582 44.6553 50.8257 43.0318 50.582 40.0084C50.2755 36.2063 49.3566 37.4724 49.3566 33.6703C49.3566 29.8683 54.8716 25.2178 49.9697 18.4618Z"
            fill="#9C6A5A"
          />
          <g clipPath="url(#clip3_4782_18408)">
            <path
              d="M27.9287 46.0001C27.9287 45.4478 28.3764 45.0001 28.9287 45.0001H38.0716C38.6239 45.0001 39.0716 45.4478 39.0716 46.0001C39.0716 48.7615 36.833 51.0001 34.0716 51.0001H32.9287C30.1673 51.0001 27.9287 48.7615 27.9287 46.0001Z"
              fill="#66253C"
            />
            <path
              d="M35.7548 50.7097C35.2288 50.8977 34.6622 51.0001 34.0716 51.0001H32.9287C30.2342 51.0001 28.0375 48.8687 27.9326 46.2C28.6966 45.9141 29.8307 45.947 30.9501 45.9795C31.3057 45.9898 31.6598 46.0001 32.0001 46.0001C34.2093 46.0001 36.0001 47.5671 36.0001 49.5001C36.0001 49.9252 35.9135 50.3327 35.7548 50.7097Z"
              fill="#B03E67"
            />
            <path
              d="M29 45.0001H39V46.0001C39 46.5523 38.5523 47.0001 38 47.0001H30C29.4477 47.0001 29 46.5523 29 46.0001V45.0001Z"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip4_4782_18408)">
            <g clipPath="url(#clip5_4782_18408)">
              <path
                d="M24 40.5001C24 39.6716 24.6716 39.0001 25.5 39.0001H27.5C28.3284 39.0001 29 39.6716 29 40.5001C29 41.3285 28.3284 42.0001 27.5 42.0001H25.5C24.6716 42.0001 24 41.3285 24 40.5001Z"
                fill="#FF859B"
              />
              <path
                d="M43 40.5001C43 39.6716 43.6716 39.0001 44.5 39.0001H45.5C46.3284 39.0001 47 39.6716 47 40.5001C47 41.3285 46.3284 42.0001 45.5 42.0001H44.5C43.6716 42.0001 43 41.3285 43 40.5001Z"
                fill="#FF859B"
              />
              <path
                d="M43 37.5001C43 38.3285 42.3284 39.0001 41.5 39.0001C40.6716 39.0001 40 38.3285 40 37.5001V36.2728C40 36.1222 40.1221 36.0001 40.2727 36.0001L42.7273 36.0001C42.8779 36.0001 43 36.1222 43 36.2728V37.5001Z"
                fill="#1B0B47"
              />
              <path
                d="M33 37.5001C33 38.3285 32.3284 39.0001 31.5 39.0001C30.6716 39.0001 30 38.3285 30 37.5001L30 36.2728C30 36.1222 30.1221 36.0001 30.2727 36.0001L32.7273 36.0001C32.8779 36.0001 33 36.1222 33 36.2728V37.5001Z"
                fill="#1B0B47"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 36.5001C29 36.2239 29.2239 36.0001 29.5 36.0001H33.5C33.7761 36.0001 34 36.2239 34 36.5001C34 36.7762 33.7761 37.0001 33.5 37.0001H29.5C29.2239 37.0001 29 36.7762 29 36.5001Z"
                fill="#1B0B47"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39 36.5001C39 36.2239 39.2239 36.0001 39.5 36.0001H43.5C43.7761 36.0001 44 36.2239 44 36.5001C44 36.7762 43.7761 37.0001 43.5 37.0001H39.5C39.2239 37.0001 39 36.7762 39 36.5001Z"
                fill="#1B0B47"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_4782_18408">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_18408">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4782_18408">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip3_4782_18408">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip4_4782_18408">
              <path
                d="M0 32.0001C0 14.3269 14.3269 6.10352e-05 32 6.10352e-05C49.6731 6.10352e-05 64 14.3269 64 32.0001C64 49.6732 49.6731 64.0001 32 64.0001C14.3269 64.0001 0 49.6732 0 32.0001Z"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip5_4782_18408">
              <path
                d="M0 32.0001C0 14.3269 14.3269 6.10352e-05 32 6.10352e-05C49.6731 6.10352e-05 64 14.3269 64 32.0001C64 49.6732 49.6731 64.0001 32 64.0001C14.3269 64.0001 0 49.6732 0 32.0001Z"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 4:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_18388)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_18388)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <g clipPath="url(#clip2_4782_18388)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.6298 18.1117C47.5344 20.2956 48 22.6362 48 25H47.9988V35.4912C47.9988 35.4912 47.9988 36.4035 46.4988 37.0877C46.3074 37.1751 46.0914 37.255 45.8604 37.3279C45.8765 36.9167 45.8846 36.5069 45.8846 36.0992C45.8846 35.0649 45.9198 33.96 45.9562 32.8148C46.0362 30.2976 46.1224 27.5855 45.8555 25L23 25V65H12V25V24.5L12.007 24.4991C12.068 22.306 12.5295 20.1412 13.3702 18.1117C14.2748 15.9278 15.6006 13.9435 17.2721 12.2721C18.9435 10.6006 20.9278 9.27475 23.1117 8.37017C25.2956 7.46558 27.6362 7 30 7C32.3638 7 34.7044 7.46558 36.8883 8.37017C39.0722 9.27475 41.0565 10.6006 42.7279 12.2721C44.3994 13.9435 45.7253 15.9278 46.6298 18.1117ZM16.0866 41.6194C16.6932 41.8707 17.3434 42 18 42L18 32C17.3434 32 16.6932 32.1293 16.0866 32.3806C15.48 32.6319 14.9288 33.0002 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.9288 40.9998 15.48 41.3681 16.0866 41.6194Z"
              fill="#FE7B21"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <defs>
            <clipPath id="clip0_4782_18388">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_18388">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4782_18388">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 5:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18052)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18052)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#702C99"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9674 32.4833C47.1807 31.7963 47.9996 30.4938 47.9996 29C47.9996 28.5071 47.9104 28.035 47.7473 27.599C49.0807 26.9503 49.9996 25.5824 49.9996 24C49.9996 21.7909 48.2087 20 45.9996 20C45.5751 20 45.1661 20.0661 44.7822 20.1886C46.1998 23.8625 46.096 28.4069 45.9674 32.4833Z"
            fill="#702C99"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
            fill="#702C99"
          />
          <path
            d="M11.9989 15.998C19.9961 8.00187 25.0008 18.9993 25.0008 18.9993L22.9994 26.9987C22.9994 26.9987 20.999 29.4984 22.9994 36.9984C24.9997 44.4984 20.499 48.4984 20.499 48.4984C20.499 48.4984 16.9999 51 13.9999 49C10.9999 47 10.6023 45.0784 10.9999 41.5C11.4999 37 12.999 38.4984 12.999 33.9984C12.999 29.4984 4.00165 23.9942 11.9989 15.998Z"
            fill="#702C99"
          />
          <circle cx="14" cy="31" r="7" fill="#702C99" />
          <circle cx="51" cy="28" r="3" fill="#702C99" />
          <circle cx="51.5" cy="32.5" r="3.5" fill="#702C99" />
          <circle cx="50" cy="37" r="3" fill="#702C99" />
          <path
            d="M50.9697 16.4618C46.0677 9.70582 43 18.9975 43 18.9975L44.2268 25.7562C44.2268 25.7562 45.4529 27.8682 44.2268 34.2049C43.0007 40.5417 45.7594 43.9213 45.7594 43.9213C45.7594 43.9213 47.9043 46.0348 49.7431 44.345C51.582 42.6552 51.8257 41.0317 51.582 38.0083C51.2755 34.2063 50.3566 35.4723 50.3566 31.6702C50.3566 27.8682 55.8716 23.2177 50.9697 16.4618Z"
            fill="#702C99"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4887_18052">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18052">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 6:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32.0001" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_18368)">
            <path
              d="M23 37.0001C23 39.7615 20.7614 42.0001 18 42.0001C15.2386 42.0001 13 39.7615 13 37.0001C13 34.2386 15.2386 32.0001 18 32.0001C20.7614 32.0001 23 34.2386 23 37.0001Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41.0001H35V65.8705C35 65.8705 31.3571 68.0001 26.5 68.0001C21.6429 68.0001 18 64.5227 18 64.5227V41.0001Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9865C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0217 41.998 55.0001 35.498 56.5001C25.998 55.5001 17.428 45.0217 17.428 36.0992C14.9996 25.5001 15.4996 13.0001 31.9996 13.0001C48.4996 13.0001 45.8854 27.1768 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42.0001C36 41.4478 36.4477 41.0001 37 41.0001H38C38.5523 41.0001 39 41.4478 39 42.0001C39 42.5523 38.5523 43.0001 38 43.0001H37C36.4477 43.0001 36 42.5523 36 42.0001Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_18368)">
            <g clipPath="url(#clip2_4782_18368)">
              <path
                d="M7 69.0001C7 64.0295 11.0294 60.0001 16 60.0001H38C42.9706 60.0001 47 64.0295 47 69.0001V74.0001C47 78.9706 42.9706 83.0001 38 83.0001H16C11.0294 83.0001 7 78.9706 7 74.0001V69.0001Z"
                fill="#FF4DD8"
              />
            </g>
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 41.0001L16.6766 32.1771C14.5574 32.7573 13 34.6968 13 37.0001C13 39.7615 15.2386 42.0001 18 42.0001V50.3001C16.4507 48.9347 15.0744 47.3087 13.9174 45.4697C12.2165 42.7663 11.0292 39.6656 10.4354 36.3769C9.84164 33.0882 9.85532 29.6881 10.4755 26.4062C11.0957 23.1242 12.308 20.0368 14.0305 17.3525C15.753 14.6681 17.9456 12.4492 20.4603 10.8456C22.975 9.24208 25.7533 8.29116 28.6075 8.05709C29.2305 8.006 29.854 7.98932 30.476 8.00672C30.6505 8.00228 30.8252 8.00006 31 8.00006C33.4951 8.00006 35.9658 8.45271 38.271 9.33217C40.5762 10.2116 42.6707 11.5007 44.435 13.1257C46.1993 14.7507 47.5989 16.6799 48.5537 18.8031C49.3002 20.463 49.7634 22.216 49.9301 24.0001H50V25.5001V28.2858C50 28.2858 49.6712 28.4384 49.0015 28.6526V37.1404C49.0015 37.1404 49.0015 38.5439 47.5015 39.5966C46.9824 39.9608 46.2837 40.2411 45.5918 40.4518C45.7881 39.0001 45.8888 37.536 45.8888 36.0992C45.8888 35.065 45.924 33.9601 45.9604 32.8149V32.8148C45.9959 31.6968 46.0327 30.5404 46.0392 29.3737C44.1879 29.7129 41.6726 30.0001 38.4615 30.0001C30.6154 30.0001 26 28.2858 26 28.2858V27.2873C25.3194 28.3508 24.5839 29.6375 24 31.0001C23.25 32.7501 23.25 34.5439 23.25 36.0516C23.25 37.5593 23.25 38.7808 22.5 39.386C21 40.5966 18 41.0001 18 41.0001Z"
            fill="#FF4DD8"
          />
          <g clipPath="url(#clip3_4782_18368)">
            <path
              d="M27.9287 46.0001C27.9287 45.4478 28.3764 45.0001 28.9287 45.0001H38.0716C38.6239 45.0001 39.0716 45.4478 39.0716 46.0001C39.0716 48.7615 36.833 51.0001 34.0716 51.0001H32.9287C30.1673 51.0001 27.9287 48.7615 27.9287 46.0001Z"
              fill="#66253C"
            />
            <path
              d="M35.7548 50.7097C35.2288 50.8977 34.6622 51.0001 34.0716 51.0001H32.9287C30.2342 51.0001 28.0375 48.8687 27.9326 46.2C28.6966 45.9141 29.8307 45.947 30.9501 45.9795C31.3057 45.9898 31.6598 46.0001 32.0001 46.0001C34.2093 46.0001 36.0001 47.5671 36.0001 49.5001C36.0001 49.9252 35.9135 50.3327 35.7548 50.7097Z"
              fill="#B03E67"
            />
            <path
              d="M29 45.0001H39V46.0001C39 46.5523 38.5523 47.0001 38 47.0001H30C29.4477 47.0001 29 46.5523 29 46.0001V45.0001Z"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip4_4782_18368)">
            <g clipPath="url(#clip5_4782_18368)">
              <path
                d="M24 40.5001C24 39.6716 24.6716 39.0001 25.5 39.0001H27.5C28.3284 39.0001 29 39.6716 29 40.5001C29 41.3285 28.3284 42.0001 27.5 42.0001H25.5C24.6716 42.0001 24 41.3285 24 40.5001Z"
                fill="#FF859B"
              />
              <path
                d="M43 40.5001C43 39.6716 43.6716 39.0001 44.5 39.0001H45.5C46.3284 39.0001 47 39.6716 47 40.5001C47 41.3285 46.3284 42.0001 45.5 42.0001H44.5C43.6716 42.0001 43 41.3285 43 40.5001Z"
                fill="#FF859B"
              />
              <path
                d="M30 37.5001C30 36.6716 30.6716 36.0001 31.5 36.0001C32.3284 36.0001 33 36.6716 33 37.5001V38.7273C33 38.878 32.8779 39.0001 32.7273 39.0001H30.2727C30.1221 39.0001 30 38.878 30 38.7273V37.5001Z"
                fill="#1B0B47"
              />
              <path
                d="M40 37.5001C40 36.6716 40.6716 36.0001 41.5 36.0001C42.3284 36.0001 43 36.6716 43 37.5001V38.7273C43 38.878 42.8779 39.0001 42.7273 39.0001H40.2727C40.1221 39.0001 40 38.878 40 38.7273V37.5001Z"
                fill="#1B0B47"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_4782_18368">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_18368">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4782_18368">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip3_4782_18368">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip4_4782_18368">
              <path
                d="M0 32.0001C0 14.3269 14.3269 6.10352e-05 32 6.10352e-05C49.6731 6.10352e-05 64 14.3269 64 32.0001C64 49.6732 49.6731 64.0001 32 64.0001C14.3269 64.0001 0 49.6732 0 32.0001Z"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip5_4782_18368">
              <path
                d="M0 32.0001C0 14.3269 14.3269 6.10352e-05 32 6.10352e-05C49.6731 6.10352e-05 64 14.3269 64 32.0001C64 49.6732 49.6731 64.0001 32 64.0001C14.3269 64.0001 0 49.6732 0 32.0001Z"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 7:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18369)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18369)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <g clipPath="url(#clip2_4887_18369)">
            <path
              d="M-0.00111699 9.99802C7.99612 2.00187 13.0008 12.9993 13.0008 12.9993L10.9994 20.9987C10.9994 20.9987 8.99904 23.4984 10.9994 30.9984C12.9997 38.4984 8.49902 42.4984 8.49902 42.4984C8.49902 42.4984 4.99985 45 1.99985 43C-1.00015 41 -1.39775 39.0784 -1.00015 35.5C-0.500147 31 0.998999 32.4984 0.998999 27.9984C0.998999 23.4984 -7.99835 17.9942 -0.00111699 9.99802Z"
              fill="#9C6A5A"
            />
            <path
              d="M13.0304 13.1813L18.5376 15.5626L14.9657 23.8235L9.45846 21.4422C9.45846 21.4422 8.49958 19.5 9.99785 16.5009C11.4961 13.5018 13.0304 13.1813 13.0304 13.1813Z"
              fill="#FFA0BD"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 41L16.6766 32.177C14.5574 32.7572 13 34.6967 13 37C13 39.7614 15.2386 42 18 42V50.3C16.4507 48.9346 15.0744 47.3086 13.9174 45.4696C12.2165 42.7662 11.0292 39.6655 10.4354 36.3768C9.84164 33.0881 9.85532 29.6881 10.4755 26.4061C11.0957 23.1242 12.308 20.0368 14.0305 17.3524C15.753 14.668 17.9456 12.4491 20.4603 10.8456C22.975 9.24202 25.7533 8.2911 28.6075 8.05703C29.2305 8.00594 29.854 7.98926 30.476 8.00666C30.6505 8.00222 30.8252 8 31 8C33.4951 8 35.9658 8.45265 38.271 9.33211C40.5762 10.2116 42.6707 11.5006 44.435 13.1256C46.1993 14.7507 47.5989 16.6798 48.5537 18.803C49.3002 20.4629 49.7634 22.2159 49.9301 24H50V25.5V28.2857C50 28.2857 49.6712 28.4383 49.0015 28.6525V37.1404C49.0015 37.1404 49.0015 38.5439 47.5015 39.5965C46.9824 39.9608 46.2837 40.241 45.5918 40.4517C45.7881 39 45.8888 37.5359 45.8888 36.0992C45.8888 35.0649 45.924 33.96 45.9604 32.8148V32.8148C45.9959 31.6968 46.0327 30.5404 46.0392 29.3736C44.1879 29.7129 41.6726 30 38.4615 30C30.6154 30 26 28.2857 26 28.2857V27.2872C25.3194 28.3507 24.5839 29.6375 24 31C23.25 32.75 23.25 34.5439 23.25 36.0515C23.25 37.5592 23.25 38.7807 22.5 39.386C21 40.5965 18 41 18 41Z"
              fill="#9C6A5A"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_18369">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18369">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4887_18369">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 8:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18527)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18527)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <g clipPath="url(#clip2_4887_18527)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48 25C48 22.6362 47.5344 20.2956 46.6298 18.1117C45.7253 15.9278 44.3994 13.9435 42.7279 12.2721C41.0565 10.6006 39.0722 9.27475 36.8883 8.37017C34.7044 7.46558 32.3638 7 30 7C27.6362 7 25.2956 7.46558 23.1117 8.37017C20.9278 9.27475 18.9435 10.6006 17.2721 12.2721C15.6006 13.9435 14.2748 15.9278 13.3702 18.1117C12.5295 20.1412 12.068 22.306 12.007 24.4991L12 24.5V25V65H23V34.5975C25.4847 34.1193 29.7793 33.0675 35.2083 30.8407C40.6717 28.5996 44.0812 26.4823 45.8758 25.2039C46.1179 27.7278 46.0341 30.3637 45.9562 32.8148C45.9198 33.96 45.8846 35.0649 45.8846 36.0992C45.8846 36.5069 45.8765 36.9167 45.8604 37.3279C46.0914 37.255 46.3074 37.1751 46.4988 37.0877C47.9988 36.4035 47.9988 35.4912 47.9988 35.4912V25H48ZM15 33C14.8111 33.1416 14.6321 33.2968 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.6321 40.7032 14.8111 40.8584 15 41V33Z"
              fill="#702C99"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="white"
          />
          <defs>
            <clipPath id="clip0_4887_18527">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18527">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4887_18527">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 9:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18589)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18589)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#FE7B21"
          />
          <path
            d="M11.8425 22.5412C18.7053 15.5673 23 25.1588 23 25.1588L21.2825 32.1355C21.2825 32.1355 19.5659 34.3156 21.2825 40.8567C22.9991 47.3979 19.1368 50.8865 19.1368 50.8865C19.1368 50.8865 16.134 53.0682 13.5596 51.3239C10.9852 49.5796 10.644 47.9037 10.9852 44.7828C11.4142 40.8581 12.7007 42.1649 12.7007 38.2403C12.7007 34.3156 4.9797 29.515 11.8425 22.5412Z"
            fill="#FE7B21"
          />
          <path
            d="M49.9697 18.4618C45.0677 11.7058 42 20.9975 42 20.9975L43.2268 27.7562C43.2268 27.7562 44.4529 29.8682 43.2268 36.2049C42.0007 42.5417 44.7594 45.9213 44.7594 45.9213C44.7594 45.9213 46.9043 48.0348 48.7431 46.345C50.582 44.6552 50.8257 43.0317 50.582 40.0083C50.2755 36.2063 49.3566 37.4723 49.3566 33.6703C49.3566 29.8682 54.8716 25.2177 49.9697 18.4618Z"
            fill="#FE7B21"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 37.5C43 38.3284 42.3284 39 41.5 39C40.6716 39 40 38.3284 40 37.5V36.2727C40 36.1221 40.1221 36 40.2727 36L42.7273 36C42.8779 36 43 36.1221 43 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M33 37.5C33 38.3284 32.3284 39 31.5 39C30.6716 39 30 38.3284 30 37.5L30 36.2727C30 36.1221 30.1221 36 30.2727 36L32.7273 36C32.8779 36 33 36.1221 33 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 36.5C29 36.2239 29.2239 36 29.5 36H33.5C33.7761 36 34 36.2239 34 36.5C34 36.7761 33.7761 37 33.5 37H29.5C29.2239 37 29 36.7761 29 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 36.5C39 36.2239 39.2239 36 39.5 36H43.5C43.7761 36 44 36.2239 44 36.5C44 36.7761 43.7761 37 43.5 37H39.5C39.2239 37 39 36.7761 39 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_18589">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18589">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 10:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18722)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18722)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#3633E0" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#362ABC" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#3633E0"
            />
          </g>
          <g clipPath="url(#clip2_4887_18722)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.6298 18.1117C47.5344 20.2956 48 22.6362 48 25H47.9988V35.4912C47.9988 35.4912 47.9988 36.4035 46.4988 37.0877C46.3074 37.1751 46.0914 37.255 45.8604 37.3279C45.8765 36.9167 45.8846 36.5069 45.8846 36.0992C45.8846 35.0649 45.9198 33.96 45.9562 32.8148C46.0362 30.2976 46.1224 27.5855 45.8555 25L23 25V65H12V25V24.5L12.007 24.4991C12.068 22.306 12.5295 20.1412 13.3702 18.1117C14.2748 15.9278 15.6006 13.9435 17.2721 12.2721C18.9435 10.6006 20.9278 9.27475 23.1117 8.37017C25.2956 7.46558 27.6362 7 30 7C32.3638 7 34.7044 7.46558 36.8883 8.37017C39.0722 9.27475 41.0565 10.6006 42.7279 12.2721C44.3994 13.9435 45.7253 15.9278 46.6298 18.1117ZM16.0866 41.6194C16.6932 41.8707 17.3434 42 18 42L18 32C17.3434 32 16.6932 32.1293 16.0866 32.3806C15.48 32.6319 14.9288 33.0002 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.9288 40.9998 15.48 41.3681 16.0866 41.6194Z"
              fill="#FF4CD8"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_18722">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18722">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4887_18722">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 11:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18772)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18772)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9674 32.4833C47.1807 31.7963 47.9996 30.4938 47.9996 29C47.9996 28.5071 47.9104 28.035 47.7473 27.599C49.0807 26.9503 49.9996 25.5824 49.9996 24C49.9996 21.7909 48.2087 20 45.9996 20C45.5751 20 45.1661 20.0661 44.7822 20.1886C46.1998 23.8625 46.096 28.4069 45.9674 32.4833Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
            fill="#1B0B47"
          />
          <path
            d="M11.9989 15.998C19.9961 8.00187 25.0008 18.9993 25.0008 18.9993L22.9994 26.9987C22.9994 26.9987 20.999 29.4984 22.9994 36.9984C24.9997 44.4984 20.499 48.4984 20.499 48.4984C20.499 48.4984 16.9999 51 13.9999 49C10.9999 47 10.6023 45.0784 10.9999 41.5C11.4999 37 12.999 38.4984 12.999 33.9984C12.999 29.4984 4.00165 23.9942 11.9989 15.998Z"
            fill="#1B0B47"
          />
          <circle cx="14" cy="31" r="7" fill="#1B0B47" />
          <circle cx="51" cy="28" r="3" fill="#1B0B47" />
          <circle cx="51.5" cy="32.5" r="3.5" fill="#1B0B47" />
          <circle cx="50" cy="37" r="3" fill="#1B0B47" />
          <path
            d="M50.9697 16.4618C46.0677 9.70582 43 18.9975 43 18.9975L44.2268 25.7562C44.2268 25.7562 45.4529 27.8682 44.2268 34.2049C43.0007 40.5417 45.7594 43.9213 45.7594 43.9213C45.7594 43.9213 47.9043 46.0348 49.7431 44.345C51.582 42.6552 51.8257 41.0317 51.582 38.0083C51.2755 34.2063 50.3566 35.4723 50.3566 31.6702C50.3566 27.8682 55.8716 23.2177 50.9697 16.4618Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="white"
          />
          <defs>
            <clipPath id="clip0_4887_18772">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18772">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 12:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18840)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18840)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
            <path
              d="M17 59.6485C17 58.7381 17.7381 58 18.6485 58H34.3515C35.2619 58 36 58.7381 36 59.6485C36 60.4344 35.446 61.109 34.6707 61.2373C32.8837 61.533 29.5751 62 26.5 62C23.4249 62 20.1163 61.533 18.3293 61.2373C17.554 61.109 17 60.4344 17 59.6485Z"
              fill="#FF4DD8"
            />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#D2499B" />
            <path
              d="M19.6013 59.4968C17.7906 59.2547 16.6732 57 18.5 57H34.5C36.3268 57 35.2094 59.2547 33.3987 59.4968C31.5211 59.7479 29.0106 60 26.5 60C23.9894 60 21.4789 59.7479 19.6013 59.4968Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 41L16.6766 32.177C14.5574 32.7572 13 34.6967 13 37C13 39.7614 15.2386 42 18 42V50.3C16.4507 48.9346 15.0744 47.3086 13.9174 45.4696C12.2165 42.7662 11.0292 39.6655 10.4354 36.3768C9.84164 33.0881 9.85532 29.6881 10.4755 26.4061C11.0957 23.1242 12.308 20.0368 14.0305 17.3524C15.753 14.668 17.9456 12.4491 20.4603 10.8456C22.975 9.24202 25.7533 8.2911 28.6075 8.05703C29.2305 8.00594 29.854 7.98926 30.476 8.00666C30.6505 8.00222 30.8252 8 31 8C33.4951 8 35.9658 8.45265 38.271 9.33211C40.5762 10.2116 42.6707 11.5006 44.435 13.1256C46.1993 14.7507 47.5989 16.6798 48.5537 18.803C49.3002 20.4629 49.7634 22.2159 49.9301 24H50V25.5V28.2857C50 28.2857 49.6712 28.4383 49.0015 28.6525V37.1404C49.0015 37.1404 49.0015 38.5439 47.5015 39.5965C46.9824 39.9608 46.2837 40.241 45.5918 40.4517C45.7881 39 45.8888 37.5359 45.8888 36.0992C45.8888 35.0649 45.924 33.96 45.9604 32.8148V32.8148C45.9959 31.6968 46.0327 30.5404 46.0392 29.3736C44.1879 29.7129 41.6726 30 38.4615 30C30.6154 30 26 28.2857 26 28.2857V27.2872C25.3194 28.3507 24.5839 29.6375 24 31C23.25 32.75 23.25 34.5439 23.25 36.0515C23.25 37.5592 23.25 38.7807 22.5 39.386C21 40.5965 18 41 18 41Z"
            fill="#FE7B21"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_18840">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18840">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 13:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_19230)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_19230)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <g clipPath="url(#clip2_4887_19230)">
            <path
              d="M-0.00111699 9.99802C7.99612 2.00187 13.0008 12.9993 13.0008 12.9993L10.9994 20.9987C10.9994 20.9987 8.99904 23.4984 10.9994 30.9984C12.9997 38.4984 8.49902 42.4984 8.49902 42.4984C8.49902 42.4984 4.99985 45 1.99985 43C-1.00015 41 -1.39775 39.0784 -1.00015 35.5C-0.500147 31 0.998999 32.4984 0.998999 27.9984C0.998999 23.4984 -7.99835 17.9942 -0.00111699 9.99802Z"
              fill="#FF4CD8"
            />
            <path
              d="M13.0304 13.1813L18.5376 15.5626L14.9657 23.8235L9.45846 21.4422C9.45846 21.4422 8.49958 19.5 9.99785 16.5009C11.4961 13.5018 13.0304 13.1813 13.0304 13.1813Z"
              fill="#FFA0BD"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 41L16.6766 32.177C14.5574 32.7572 13 34.6967 13 37C13 39.7614 15.2386 42 18 42V50.3C16.4507 48.9346 15.0744 47.3086 13.9174 45.4696C12.2165 42.7662 11.0292 39.6655 10.4354 36.3768C9.84164 33.0881 9.85532 29.6881 10.4755 26.4061C11.0957 23.1242 12.308 20.0368 14.0305 17.3524C15.753 14.668 17.9456 12.4491 20.4603 10.8456C22.975 9.24202 25.7533 8.2911 28.6075 8.05703C29.2305 8.00594 29.854 7.98926 30.476 8.00666C30.6505 8.00222 30.8252 8 31 8C33.4951 8 35.9658 8.45265 38.271 9.33211C40.5762 10.2116 42.6707 11.5006 44.435 13.1256C46.1993 14.7507 47.5989 16.6798 48.5537 18.803C49.3002 20.4629 49.7634 22.2159 49.9301 24H50V25.5V28.2857C50 28.2857 49.6712 28.4383 49.0015 28.6525V37.1404C49.0015 37.1404 49.0015 38.5439 47.5015 39.5965C46.9824 39.9608 46.2837 40.241 45.5918 40.4517C45.7881 39 45.8888 37.5359 45.8888 36.0992C45.8888 35.0649 45.924 33.96 45.9604 32.8148V32.8148C45.9959 31.6968 46.0327 30.5404 46.0392 29.3736C44.1879 29.7129 41.6726 30 38.4615 30C30.6154 30 26 28.2857 26 28.2857V27.2872C25.3194 28.3507 24.5839 29.6375 24 31C23.25 32.75 23.25 34.5439 23.25 36.0515C23.25 37.5592 23.25 38.7807 22.5 39.386C21 40.5965 18 41 18 41Z"
              fill="#FF4CD8"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <defs>
            <clipPath id="clip0_4887_19230">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_19230">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4887_19230">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 14:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_19291)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_19291)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#3633E0" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#362ABC" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#3633E0"
            />
          </g>
          <g clipPath="url(#clip2_4887_19291)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48 25C48 22.6362 47.5344 20.2956 46.6298 18.1117C45.7253 15.9278 44.3994 13.9435 42.7279 12.2721C41.0565 10.6006 39.0722 9.27475 36.8883 8.37017C34.7044 7.46558 32.3638 7 30 7C27.6362 7 25.2956 7.46558 23.1117 8.37017C20.9278 9.27475 18.9435 10.6006 17.2721 12.2721C15.6006 13.9435 14.2748 15.9278 13.3702 18.1117C12.5295 20.1412 12.068 22.306 12.007 24.4991L12 24.5V25V65H23V34.5975C25.4847 34.1193 29.7793 33.0675 35.2083 30.8407C40.6717 28.5996 44.0812 26.4823 45.8758 25.2039C46.1179 27.7278 46.0341 30.3637 45.9562 32.8148C45.9198 33.96 45.8846 35.0649 45.8846 36.0992C45.8846 36.5069 45.8765 36.9167 45.8604 37.3279C46.0914 37.255 46.3074 37.1751 46.4988 37.0877C47.9988 36.4035 47.9988 35.4912 47.9988 35.4912V25H48ZM15 33C14.8111 33.1416 14.6321 33.2968 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.6321 40.7032 14.8111 40.8584 15 41V33Z"
              fill="#9C6A5A"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4887_19291">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_19291">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4887_19291">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 15:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_19344)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_19344)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#702C99"
          />
          <path
            d="M11.8425 22.5412C18.7053 15.5673 23 25.1588 23 25.1588L21.2825 32.1355C21.2825 32.1355 19.5659 34.3156 21.2825 40.8567C22.9991 47.3979 19.1368 50.8865 19.1368 50.8865C19.1368 50.8865 16.134 53.0682 13.5596 51.3239C10.9852 49.5796 10.644 47.9037 10.9852 44.7828C11.4142 40.8581 12.7007 42.1649 12.7007 38.2403C12.7007 34.3156 4.9797 29.515 11.8425 22.5412Z"
            fill="#702C99"
          />
          <path
            d="M49.9697 18.4618C45.0677 11.7058 42 20.9975 42 20.9975L43.2268 27.7562C43.2268 27.7562 44.4529 29.8682 43.2268 36.2049C42.0007 42.5417 44.7594 45.9213 44.7594 45.9213C44.7594 45.9213 46.9043 48.0348 48.7431 46.345C50.582 44.6552 50.8257 43.0317 50.582 40.0083C50.2755 36.2063 49.3566 37.4723 49.3566 33.6702C49.3566 29.8682 54.8716 25.2177 49.9697 18.4618Z"
            fill="#702C99"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <defs>
            <clipPath id="clip0_4887_19344">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_19344">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 16:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4889_18259)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4889_18259)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <g clipPath="url(#clip2_4889_18259)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.6298 18.1117C47.5344 20.2956 48 22.6362 48 25H47.9988V35.4912C47.9988 35.4912 47.9988 36.4035 46.4988 37.0877C46.3074 37.1751 46.0914 37.255 45.8604 37.3279C45.8765 36.9167 45.8846 36.5069 45.8846 36.0992C45.8846 35.0649 45.9198 33.96 45.9562 32.8148C46.0362 30.2976 46.1224 27.5855 45.8555 25L23 25V65H12V25V24.5L12.007 24.4991C12.068 22.306 12.5295 20.1412 13.3702 18.1117C14.2748 15.9278 15.6006 13.9435 17.2721 12.2721C18.9435 10.6006 20.9278 9.27475 23.1117 8.37017C25.2956 7.46558 27.6362 7 30 7C32.3638 7 34.7044 7.46558 36.8883 8.37017C39.0722 9.27475 41.0565 10.6006 42.7279 12.2721C44.3994 13.9435 45.7253 15.9278 46.6298 18.1117ZM16.0866 41.6194C16.6932 41.8707 17.3434 42 18 42L18 32C17.3434 32 16.6932 32.1293 16.0866 32.3806C15.48 32.6319 14.9288 33.0002 14.4645 33.4645C14.0002 33.9288 13.6319 34.48 13.3806 35.0866C13.1293 35.6932 13 36.3434 13 37C13 37.6566 13.1293 38.3068 13.3806 38.9134C13.6319 39.52 14.0002 40.0712 14.4645 40.5355C14.9288 40.9998 15.48 41.3681 16.0866 41.6194Z"
              fill="#1B0B47"
            />
          </g>
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <defs>
            <clipPath id="clip0_4889_18259">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4889_18259">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip2_4889_18259">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4721_18007)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4721_18007)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

const AppMaleAvatar = (props) => {
  switch (props.avatarId) {
    case 1:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4721_18007)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4721_18007)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 2:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17298)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17298)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <path
            d="M22.0002 28C21.371 31 23.0002 34.9811 23.0002 35.7433C23.0002 36.5054 19.069 38.7684 18.0002 39.5C16.9314 40.2316 16.5002 32.5 16.5002 32.5C13.5002 32.5 13.0002 38 13.0002 38C13.0002 38 10.7556 37.2599 10.0002 33.5C9.48994 30.9601 10.2986 25.4111 10.5002 24C11.0002 20.5 11.5002 12.5 18.0002 8.50001C24.5002 4.50001 41.0002 4.50001 45.0002 5.50002C54.8938 7.97341 56.2182 21.4865 53.0002 26C48.0002 31.5 34.0002 25 29.5002 25C25.0002 25 22.6295 25 22.0002 28Z"
            fill="#AD3A20"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M43 37.5C43 38.3284 42.3284 39 41.5 39C40.6716 39 40 38.3284 40 37.5V36.2727C40 36.1221 40.1221 36 40.2727 36L42.7273 36C42.8779 36 43 36.1221 43 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M33 37.5C33 38.3284 32.3284 39 31.5 39C30.6716 39 30 38.3284 30 37.5L30 36.2727C30 36.1221 30.1221 36 30.2727 36L32.7273 36C32.8779 36 33 36.1221 33 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 36.5C29 36.2239 29.2239 36 29.5 36H33.5C33.7761 36 34 36.2239 34 36.5C34 36.7761 33.7761 37 33.5 37H29.5C29.2239 37 29 36.7761 29 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 36.5C39 36.2239 39.2239 36 39.5 36H43.5C43.7761 36 44 36.2239 44 36.5C44 36.7761 43.7761 37 43.5 37H39.5C39.2239 37 39 36.7761 39 36.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4781_17298">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17298">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 3:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17337)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17337)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1177 27.4638C35.9142 28.606 49.5988 30.6109 51 18C52 9 38.5 -1.50001 24 7.99999C14.8908 9.3495 12.611 16.2779 12.0917 22.2538C11.4149 22.9702 11 23.9367 11 25C11 25.5304 11.1033 26.0368 11.2908 26.5C11.1033 26.9632 11 27.4695 11 28C11 29.1056 11.4486 30.1065 12.1737 30.8305C12.0608 31.2004 12 31.5931 12 32C12 34.2091 13.7909 36 16 36C18.2091 36 20 34.2091 20 32C22.2091 32 24 30.2091 24 28C24 27.6552 23.9564 27.3206 23.8744 27.0015C23.9163 27.0005 23.9582 27 24 27C24.9517 27 26.3901 27.2107 28.1177 27.4638Z"
            fill="#47280B"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4781_17337">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17337">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 4:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17376)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17376)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#3633E0" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#362ABC" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#3633E0"
            />
          </g>
          <path
            d="M24 25C24 25.5305 23.8967 26.0368 23.7092 26.5C23.8967 26.9632 24 27.4695 24 28C24 30.2091 22.2091 32 20 32C20 34.2091 18.2091 36 16 36C13.7909 36 12 34.2091 12 32C12 30.9541 12.0652 29.2176 12.3683 27.6787C12.1319 27.1682 12 26.5995 12 26C12 23.7909 13.7909 22 16 22C16.4069 22 16.7996 22.0607 17.1695 22.1737C17.8935 21.4486 18.8944 21 20 21C22.2091 21 24 22.7909 24 25Z"
            fill="#1B0B47"
          />
          <path
            d="M45.8487 33.8927C47.6122 33.4756 48.9245 31.891 48.9245 30C48.9245 29.4695 48.8213 28.9632 48.6338 28.5C48.8213 28.0368 48.9245 27.5304 48.9245 27C48.9245 24.9863 47.4365 23.3201 45.5 23.0411C46.0928 26.1816 45.9824 29.652 45.8819 32.8147C45.8703 33.1785 45.8589 33.5381 45.8487 33.8927Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4781_17376">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17376">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 5:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17415)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17415)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9674 32.4833C47.1807 31.7963 47.9996 30.4938 47.9996 29C47.9996 28.5071 47.9104 28.035 47.7473 27.599C49.0807 26.9503 49.9996 25.5824 49.9996 24C49.9996 21.7909 48.2087 20 45.9996 20C45.5751 20 45.1661 20.0661 44.7822 20.1886C46.1998 23.8625 46.096 28.4069 45.9674 32.4833Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4781_17415">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17415">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 6:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17454)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17454)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
            <path
              d="M17 59.6485C17 58.7381 17.7381 58 18.6485 58H34.3515C35.2619 58 36 58.7381 36 59.6485C36 60.4344 35.446 61.109 34.6707 61.2373C32.8837 61.533 29.5751 62 26.5 62C23.4249 62 20.1163 61.533 18.3293 61.2373C17.554 61.109 17 60.4344 17 59.6485Z"
              fill="#FF4DD8"
            />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#D2499B" />
            <path
              d="M19.6013 59.4968C17.7906 59.2547 16.6732 57 18.5 57H34.5C36.3268 57 35.2094 59.2547 33.3987 59.4968C31.5211 59.7479 29.0106 60 26.5 60C23.9894 60 21.4789 59.7479 19.6013 59.4968Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            d="M20.6733 28.2208V34.2388C20.6733 35.0009 20 36 19.5 36.5C19 37 17.5 38 16.6509 38C15.8018 38 13.1111 37.5487 11.824 35.7433C10.5368 33.9378 10.9315 24.4208 11.824 22.2028C12.7164 19.9848 16.0066 15.3153 20.6733 11.7508C24.0531 9.16914 28.3394 7.03367 33.5451 6.40558C58.4841 3.39656 49.2179 20.9865 46 25.5C40.5 23.5 36.5 23 29.2828 23.9824C22.0657 24.9647 21.2668 25.964 20.6733 28.2208Z"
            fill="#47280B"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M43 37.5C43 38.3284 42.3284 39 41.5 39C40.6716 39 40 38.3284 40 37.5V36.2727C40 36.1221 40.1221 36 40.2727 36L42.7273 36C42.8779 36 43 36.1221 43 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M33 37.5C33 38.3284 32.3284 39 31.5 39C30.6716 39 30 38.3284 30 37.5L30 36.2727C30 36.1221 30.1221 36 30.2727 36L32.7273 36C32.8779 36 33 36.1221 33 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 36.5C29 36.2239 29.2239 36 29.5 36H33.5C33.7761 36 34 36.2239 34 36.5C34 36.7761 33.7761 37 33.5 37H29.5C29.2239 37 29 36.7761 29 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 36.5C39 36.2239 39.2239 36 39.5 36H43.5C43.7761 36 44 36.2239 44 36.5C44 36.7761 43.7761 37 43.5 37H39.5C39.2239 37 39 36.7761 39 36.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4781_17454">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17454">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 7:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4781_17493)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4781_17493)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            d="M14 27C14 26.4477 14.4477 26 15 26H22C22.5523 26 23 26.4477 23 27C23 27.5523 22.5523 28 22 28H15C14.4477 28 14 27.5523 14 27Z"
            fill="#1B0B47"
          />
          <path
            d="M14 34C14 33.4477 14.4477 33 15 33H20C20.5523 33 21 33.4477 21 34C21 34.5523 20.5523 35 20 35H15C14.4477 35 14 34.5523 14 34Z"
            fill="#1B0B47"
          />
          <path
            d="M13.5 29C12.6716 29 12 29.6716 12 30.5C12 31.3284 12.6716 32 13.5 32H23.5C24.3284 32 25 31.3284 25 30.5C25 29.6716 24.3284 29 23.5 29H13.5Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4781_17493">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4781_17493">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 8:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17801)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17801)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#AD3A20"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <path
            d="M28 46C28 44.3431 29.3431 43 31 43H40C41.6569 43 43 44.3431 43 46H28Z"
            fill="#AD3A20"
          />
          <defs>
            <clipPath id="clip0_4782_17801">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17801">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 9:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17811)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35 53.8678C35 59.5217 34.1177 58.9864 28.3831 58.9864C22.6484 58.9864 20.0016 54.403 20.0016 48.7491C20.0016 43.0953 19.7839 38 24.4129 38C37.5 38.5 35 48.2139 35 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 49.5712 55.9932 33.4996 55.9932C17.428 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17811)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <path
            d="M22.0002 28C21.371 31 23.0002 34.9811 23.0002 35.7433C23.0002 36.5054 19.069 38.7684 18.0002 39.5C16.9314 40.2316 16.5002 32.5 16.5002 32.5C13.5002 32.5 13.0002 38 13.0002 38C13.0002 38 10.7556 37.2599 10.0002 33.5C9.48994 30.9601 10.2986 25.4111 10.5002 24C11.0002 20.5 11.5002 12.5 18.0002 8.50001C24.5002 4.50001 41.0002 4.50001 45.0002 5.50002C54.8938 7.97341 56.2182 21.4865 53.0002 26C48.0002 31.5 34.0002 25 29.5002 25C25.0002 25 22.6295 25 22.0002 28Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M43 37.5C43 38.3284 42.3284 39 41.5 39C40.6716 39 40 38.3284 40 37.5V36.2727C40 36.1221 40.1221 36 40.2727 36L42.7273 36C42.8779 36 43 36.1221 43 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M33 37.5C33 38.3284 32.3284 39 31.5 39C30.6716 39 30 38.3284 30 37.5L30 36.2727C30 36.1221 30.1221 36 30.2727 36L32.7273 36C32.8779 36 33 36.1221 33 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 36.5C29 36.2239 29.2239 36 29.5 36H33.5C33.7761 36 34 36.2239 34 36.5C34 36.7761 33.7761 37 33.5 37H29.5C29.2239 37 29 36.7761 29 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 36.5C39 36.2239 39.2239 36 39.5 36H43.5C43.7761 36 44 36.2239 44 36.5C44 36.7761 43.7761 37 43.5 37H39.5C39.2239 37 39 36.7761 39 36.5Z"
            fill="#1B0B47"
          />
          <path
            d="M27 46C27 44.3431 28.3431 43 30 43H41C42.6569 43 44 44.3431 44 46V57C41.7909 57 40 55.2091 40 53V46H31V53C31 55.2091 29.2091 57 27 57V46Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4782_17811">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17811">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 10:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17821)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17821)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1177 27.4638C35.9142 28.606 49.5988 30.6109 51 18C52 9 38.5 -1.50001 24 7.99999C14.8908 9.3495 12.611 16.2779 12.0917 22.2538C11.4149 22.9702 11 23.9367 11 25C11 25.5304 11.1033 26.0368 11.2908 26.5C11.1033 26.9632 11 27.4695 11 28C11 29.1056 11.4486 30.1065 12.1737 30.8305C12.0608 31.2004 12 31.5931 12 32C12 34.2091 13.7909 36 16 36C18.2091 36 20 34.2091 20 32C22.2091 32 24 30.2091 24 28C24 27.6552 23.9564 27.3206 23.8744 27.0015C23.9163 27.0005 23.9582 27 24 27C24.9517 27 26.3901 27.2107 28.1177 27.4638Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M26.5 45C26.5 45 31 44.5 37.5 43V45C36 45.6667 32 46.6 30 47C27.5 47.5 26.5 45 26.5 45Z"
            fill="#47280B"
          />
          <path
            d="M48 44.7558C48 44.7558 44 44 38 43V45C39.3511 45.5535 42.2569 46.0876 44.1794 46.441L44.5 46.5C46.881 46.939 48 44.7558 48 44.7558Z"
            fill="#47280B"
          />
          <path
            d="M34.2808 54C33.6302 54 33.1528 54.6114 33.3106 55.2425L33.5606 56.2425C33.6719 56.6877 34.0719 57 34.5308 57H37.4692C37.9281 57 38.3281 56.6877 38.4394 56.2425L38.6894 55.2425C38.8472 54.6114 38.3698 54 37.7192 54H34.2808Z"
            fill="#47280B"
          />
          <defs>
            <clipPath id="clip0_4782_17821">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17821">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 11:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17831)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17831)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#3633E0" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#362ABC" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#3633E0"
            />
          </g>
          <path
            d="M24 25C24 25.5305 23.8967 26.0368 23.7092 26.5C23.8967 26.9632 24 27.4695 24 28C24 30.2091 22.2091 32 20 32C20 34.2091 18.2091 36 16 36C13.7909 36 12 34.2091 12 32C12 30.9541 12.0652 29.2176 12.3683 27.6787C12.1319 27.1682 12 26.5995 12 26C12 23.7909 13.7909 22 16 22C16.4069 22 16.7996 22.0607 17.1695 22.1737C17.8935 21.4486 18.8944 21 20 21C22.2091 21 24 22.7909 24 25Z"
            fill="#AD3A20"
          />
          <path
            d="M45.8487 33.8927C47.6122 33.4756 48.9245 31.891 48.9245 30C48.9245 29.4695 48.8213 28.9632 48.6338 28.5C48.8213 28.0368 48.9245 27.5304 48.9245 27C48.9245 24.9863 47.4365 23.3201 45.5 23.0411C46.0928 26.1816 45.9824 29.652 45.8819 32.8147C45.8703 33.1785 45.8589 33.5381 45.8487 33.8927Z"
            fill="#AD3A20"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M37.5 43C31 44.5 26.5 45 26.5 45C26.5 45 27.5 47.5 30 47C32 46.6 36 45.6667 37.5 45V43Z"
            fill="#AD3A20"
          />
          <path
            d="M38 43C44 44 48 44.7558 48 44.7558C48 44.7558 46.881 46.939 44.5 46.5C44.3967 46.481 44.2897 46.4613 44.1794 46.441C42.2569 46.0876 39.3511 45.5535 38 45V43Z"
            fill="#AD3A20"
          />
          <defs>
            <clipPath id="clip0_4782_17831">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17831">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 12:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17841)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17841)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9674 32.4833C47.1807 31.7963 47.9996 30.4938 47.9996 29C47.9996 28.5071 47.9104 28.035 47.7473 27.599C49.0807 26.9503 49.9996 25.5824 49.9996 24C49.9996 21.7909 48.2087 20 45.9996 20C45.5751 20 45.1661 20.0661 44.7822 20.1886C46.1998 23.8625 46.096 28.4069 45.9674 32.4833Z"
            fill="#47280B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
            fill="#47280B"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M24 40.5C24 39.6716 24.6716 39 25.5 39H27.5C28.3284 39 29 39.6716 29 40.5C29 41.3284 28.3284 42 27.5 42H25.5C24.6716 42 24 41.3284 24 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M43 40.5C43 39.6716 43.6716 39 44.5 39H45.5C46.3284 39 47 39.6716 47 40.5C47 41.3284 46.3284 42 45.5 42H44.5C43.6716 42 43 41.3284 43 40.5Z"
            fill="#FF859B"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M28 46C28 44.3431 29.3431 43 31 43H40C41.6569 43 43 44.3431 43 46H28Z"
            fill="#47280B"
          />
          <defs>
            <clipPath id="clip0_4782_17841">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17841">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 13:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17851)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17851)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#FF4DD8"
            />
            <path
              d="M17 59.6485C17 58.7381 17.7381 58 18.6485 58H34.3515C35.2619 58 36 58.7381 36 59.6485C36 60.4344 35.446 61.109 34.6707 61.2373C32.8837 61.533 29.5751 62 26.5 62C23.4249 62 20.1163 61.533 18.3293 61.2373C17.554 61.109 17 60.4344 17 59.6485Z"
              fill="#FF4DD8"
            />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#D2499B" />
            <path
              d="M19.6013 59.4968C17.7906 59.2547 16.6732 57 18.5 57H34.5C36.3268 57 35.2094 59.2547 33.3987 59.4968C31.5211 59.7479 29.0106 60 26.5 60C23.9894 60 21.4789 59.7479 19.6013 59.4968Z"
              fill="#FF4DD8"
            />
          </g>
          <path
            d="M20.6733 28.2208V34.2388C20.6733 35.0009 20 36 19.5 36.5C19 37 17.5 38 16.6509 38C15.8018 38 13.1111 37.5487 11.824 35.7433C10.5368 33.9378 10.9315 24.4208 11.824 22.2028C12.7164 19.9848 16.0066 15.3153 20.6733 11.7508C24.0531 9.16914 28.3394 7.03367 33.5451 6.40558C58.4841 3.39656 49.2179 20.9865 46 25.5C40.5 23.5 36.5 23 29.2828 23.9824C22.0657 24.9647 21.2668 25.964 20.6733 28.2208Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M43 37.5C43 38.3284 42.3284 39 41.5 39C40.6716 39 40 38.3284 40 37.5V36.2727C40 36.1221 40.1221 36 40.2727 36L42.7273 36C42.8779 36 43 36.1221 43 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M33 37.5C33 38.3284 32.3284 39 31.5 39C30.6716 39 30 38.3284 30 37.5L30 36.2727C30 36.1221 30.1221 36 30.2727 36L32.7273 36C32.8779 36 33 36.1221 33 36.2727V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29 36.5C29 36.2239 29.2239 36 29.5 36H33.5C33.7761 36 34 36.2239 34 36.5C34 36.7761 33.7761 37 33.5 37H29.5C29.2239 37 29 36.7761 29 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 36.5C39 36.2239 39.2239 36 39.5 36H43.5C43.7761 36 44 36.2239 44 36.5C44 36.7761 43.7761 37 43.5 37H39.5C39.2239 37 39 36.7761 39 36.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <path
            d="M37.5 43C31 44.5 26.5 45 26.5 45C26.5 45 27.5 47.5 30 47C32 46.6 36 45.6667 37.5 45V43Z"
            fill="#1B0B47"
          />
          <path
            d="M38 43C44 44 48 44.7558 48 44.7558C48 44.7558 46.881 46.939 44.5 46.5C44.3967 46.481 44.2897 46.4613 44.1794 46.441C42.2569 46.0876 39.3511 45.5535 38 45V43Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4782_17851">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17851">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 14:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4782_17861)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4782_17861)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            d="M14 27C14 26.4477 14.4477 26 15 26H22C22.5523 26 23 26.4477 23 27C23 27.5523 22.5523 28 22 28H15C14.4477 28 14 27.5523 14 27Z"
            fill="#1B0B47"
          />
          <path
            d="M14 34C14 33.4477 14.4477 33 15 33H20C20.5523 33 21 33.4477 21 34C21 34.5523 20.5523 35 20 35H15C14.4477 35 14 34.5523 14 34Z"
            fill="#1B0B47"
          />
          <path
            d="M13.5 29C12.6716 29 12 29.6716 12 30.5C12 31.3284 12.6716 32 13.5 32H23.5C24.3284 32 25 31.3284 25 30.5C25 29.6716 24.3284 29 23.5 29H13.5Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <path
            d="M27 46C27 44.3431 28.3431 43 30 43H41C42.6569 43 44 44.3431 44 46V57C41.7909 57 40 55.2091 40 53V46H31V53C31 55.2091 29.2091 57 27 57V46Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4782_17861">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4782_17861">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 15:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_18943)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_18943)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#E05A33"
            />
            <path d="M17 58H36V61C36 61 31 62 26.5 62C22 62 17 61 17 61V58Z" fill="#E05A33" />
            <path d="M17 58H36V60C36 60 33 61.5 26.5 61.5C20 61.5 17 60 17 60V58Z" fill="#C53926" />
            <path
              d="M16.5 59C16.5 57.8954 17.3954 57 18.5 57H34.5C35.6046 57 36.5 57.8954 36.5 59C36.5 59 31.5 60 26.5 60C21.5 60 16.5 59 16.5 59Z"
              fill="#E05A33"
            />
          </g>
          <path
            d="M22.0002 28C21.371 31 23.0002 34.9811 23.0002 35.7433C23.0002 36.5054 19.069 38.7684 18.0002 39.5C16.9314 40.2316 16.5002 32.5 16.5002 32.5C13.5002 32.5 13.0002 38 13.0002 38C13.0002 38 10.7556 37.2599 10.0002 33.5C9.48994 30.9601 10.2986 25.4111 10.5002 24C11.0002 20.5 11.5002 12.5 18.0002 8.50001C24.5002 4.50001 41.0002 4.50001 45.0002 5.50002C54.8938 7.97341 56.2182 21.4865 53.0002 26C48.0002 31.5 34.0002 25 29.5002 25C25.0002 25 22.6295 25 22.0002 28Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_18943">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_18943">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 16:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4887_19055)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M35.0002 53.8678C35.0002 59.5217 31.5 59.9996 28.3832 58.9864C25.2665 57.9733 19 49.1535 19 43.4996C19 37.8457 19.7841 38 24.4131 38C37.5002 38.5 35.0002 48.2139 35.0002 53.8678Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 41.998 55 35.498 56.5C25.998 55.5 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4887_19055)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9674 32.4833C47.1807 31.7963 47.9996 30.4938 47.9996 29C47.9996 28.5071 47.9104 28.035 47.7473 27.599C49.0807 26.9503 49.9996 25.5824 49.9996 24C49.9996 21.7909 48.2087 20 45.9996 20C45.5751 20 45.1661 20.0661 44.7822 20.1886C46.1998 23.8625 46.096 28.4069 45.9674 32.4833Z"
            fill="#47280B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.2967 22.7594C35.4166 23.5317 34.263 24 33 24C31.8485 24 30.788 23.6108 29.9428 22.9567C28.6766 24.2194 26.9294 25 25 25C24.2884 25 23.6016 24.8938 22.9546 24.6964C22.6949 24.9808 22.3947 25.2275 22.0632 25.4275C22.6478 26.123 23 27.0204 23 28C23 29.5965 22.0647 30.9746 20.7121 31.6161C20.8931 31.864 21 32.1695 21 32.5C21 33.3284 20.3284 34 19.5 34C19.4883 34 19.4767 33.9999 19.4651 33.9996C18.7735 35.1954 17.4807 36 16 36C13.7909 36 12 34.2091 12 32C12 31.5931 12.0608 31.2004 12.1737 30.8305C11.4486 30.1065 11 29.1056 11 28C11 26.8944 11.4486 25.8935 12.1737 25.1695C12.0608 24.7996 12 24.4069 12 24C12 23.0343 12.3422 22.1485 12.912 21.4573C12.3315 20.4374 12 19.2574 12 18C12 14.8253 14.1134 12.1442 17.0101 11.2869C17.0034 11.1921 17 11.0965 17 11C17 8.79086 18.7909 7 21 7C21.7027 7 22.363 7.18118 22.9368 7.49936C24.1473 5.40741 26.4093 4 29 4C30.3733 4 31.6543 4.39549 32.7352 5.07878C33.9222 3.80011 35.6177 3 37.5 3C40.2166 3 42.5439 4.66648 43.5155 7.03291C45.4811 7.2858 47 8.96552 47 11C47 11.2076 46.9842 11.4114 46.9537 11.6104C48.8091 12.9839 50 15.1116 50 17.5C50 21.6421 46.4183 25 42 25C39.7671 25 37.7479 24.1424 36.2967 22.7594Z"
            fill="#47280B"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.7273C33 38.8779 32.8779 39 32.7273 39H30.2727C30.1221 39 30 38.8779 30 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.7273C43 38.8779 42.8779 39 42.7273 39H40.2727C40.1221 39 40 38.8779 40 38.7273V37.5Z"
            fill="#1B0B47"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.2689 39.5378C32.0743 39.5378 33.5378 38.0743 33.5378 36.2689C33.5378 34.4635 32.0743 33 30.2689 33C28.4635 33 27 34.4635 27 36.2689C27 38.0743 28.4635 39.5378 30.2689 39.5378ZM30.2689 41.5378C33.1788 41.5378 35.5378 39.1788 35.5378 36.2689C35.5378 33.359 33.1788 31 30.2689 31C27.359 31 25 33.359 25 36.2689C25 39.1788 27.359 41.5378 30.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2689 39.5378C46.0743 39.5378 47.5378 38.0743 47.5378 36.2689C47.5378 34.4635 46.0743 33 44.2689 33C42.4635 33 41 34.4635 41 36.2689C41 38.0743 42.4635 39.5378 44.2689 39.5378ZM44.2689 41.5378C47.1788 41.5378 49.5378 39.1788 49.5378 36.2689C49.5378 33.359 47.1788 31 44.2689 31C41.359 31 39 33.359 39 36.2689C39 39.1788 41.359 41.5378 44.2689 41.5378Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0003 36L34.0003 36.1036L33.9707 34.1038L40.9707 34.0002L41.0003 36Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_4887_19055">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4887_19055">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return (
        <svg
          width={props.width}
          height={props.height}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="32" fill={props.bgColor} />
          <g clipPath="url(#clip0_4721_18007)">
            <path
              d="M23 37C23 39.7614 20.7614 42 18 42C15.2386 42 13 39.7614 13 37C13 34.2386 15.2386 32 18 32C20.7614 32 23 34.2386 23 37Z"
              fill={props.skinColor}
            />
            <path
              d="M18 41H35V65.8705C35 65.8705 31.3571 68 26.5 68C21.6429 68 18 64.5226 18 64.5226V41Z"
              fill={props.skinColor}
            />
            <path
              d="M34.8114 53.1122C34.8114 58.4968 33.9402 57.9871 28.2776 57.9871C22.6151 57.9871 20.0016 53.6219 20.0016 48.2373C20.0016 42.8526 19.7867 38 24.3574 38C37.2799 38.4762 34.8114 47.7275 34.8114 53.1122Z"
              fill={chroma(props.skinColor).darken().hex()}
            />
            <path
              d="M45.8854 36.0992C45.8854 45.0216 44.4996 55.9932 33.4996 55.9932C22.4996 55.9932 17.428 45.0216 17.428 36.0992C14.9996 25.5 15.4996 13 31.9996 13C48.4996 13 45.8854 27.1767 45.8854 36.0992Z"
              fill={chroma(props.skinColor).brighten(0.2).hex()}
            />
            <path
              d="M36 42C36 41.4477 36.4477 41 37 41H38C38.5523 41 39 41.4477 39 42C39 42.5523 38.5523 43 38 43H37C36.4477 43 36 42.5523 36 42Z"
              fill={props.skinColor}
            />
          </g>
          <g clipPath="url(#clip1_4721_18007)">
            <path
              d="M7 69C7 64.0294 11.0294 60 16 60H38C42.9706 60 47 64.0294 47 69V74C47 78.9706 42.9706 83 38 83H16C11.0294 83 7 78.9706 7 74V69Z"
              fill="#3633E0"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.9648 26.2011C34.5759 28.064 42.3536 30.6462 50.4999 22.5C54.4999 18.5 36.4999 1.49998 21.9999 11C10.6666 12.679 11.3143 19.4698 11.8142 24.7106C11.9097 25.7126 11.9999 26.6579 11.9999 27.5C11.9999 28.0328 12.0695 28.4317 12.2002 28.7182C12.0394 29.9513 11.9999 31.1852 11.9999 32C11.9999 34.2091 13.7907 36 15.9999 36C18.209 36 19.9999 34.2091 19.9999 32C22.209 32 23.9999 30.2091 23.9999 28C23.9999 27.654 23.9559 27.3182 23.8734 26.998C23.9154 26.9993 23.9575 27 23.9999 27C25.2236 27 26.319 26.4505 27.0527 25.5847C27.6572 25.767 28.2956 25.9789 28.9648 26.2011Z"
            fill="#1B0B47"
          />
          <path
            d="M27.9287 46C27.9287 45.4477 28.3764 45 28.9287 45H38.0716C38.6239 45 39.0716 45.4477 39.0716 46C39.0716 48.7614 36.833 51 34.0716 51H32.9287C30.1673 51 27.9287 48.7614 27.9287 46Z"
            fill="#66253C"
          />
          <path
            d="M35.7548 50.7096C35.2288 50.8976 34.6622 51 34.0716 51H32.9287C30.2342 51 28.0375 48.8687 27.9326 46.1999C28.6966 45.914 29.8307 45.9469 30.9501 45.9794C31.3057 45.9897 31.6598 46 32.0001 46C34.2093 46 36.0001 47.567 36.0001 49.5C36.0001 49.9252 35.9135 50.3326 35.7548 50.7096Z"
            fill="#B03E67"
          />
          <path
            d="M29 45H39V46C39 46.5523 38.5523 47 38 47H30C29.4477 47 29 46.5523 29 46V45Z"
            fill="white"
          />
          <path
            d="M30 37.5C30 36.6716 30.6716 36 31.5 36C32.3284 36 33 36.6716 33 37.5V38.5C33 39.3284 32.3284 40 31.5 40C30.6716 40 30 39.3284 30 38.5V37.5Z"
            fill="#1B0B47"
          />
          <path
            d="M40 37.5C40 36.6716 40.6716 36 41.5 36C42.3284 36 43 36.6716 43 37.5V38.5C43 39.3284 42.3284 40 41.5 40C40.6716 40 40 39.3284 40 38.5V37.5Z"
            fill="#1B0B47"
          />
          <defs>
            <clipPath id="clip0_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
            <clipPath id="clip1_4721_18007">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export const AppAvatar = (props) => {
  const { gender, ...other } = props;
  switch (gender) {
    case 'male':
      return <AppMaleAvatar {...other} />;
    case 'female':
      return <AppFemaleAvatar {...other} />;
    default:
      return <AppMaleAvatar {...other} />;
  }
};
