import * as React from 'react';
import Lottie from 'lottie-react';
import bars from '@assets/animations/98770-assistagro-loading-bars.json';
import { styled } from '@mui/material/styles';

const LottieWrapper = styled(Lottie)`
  width: ${(props) => (props.width ? props.width : '100px')};
  height: ${(props) => (props.height ? props.height : '40px')};
`;
// Inspired by blueprintjs
export function AppComponentLoading(props) {
  return <LottieWrapper animationData={bars} autoPlay={true} loop={true} {...props} />;
}
