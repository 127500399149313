import React, { useState } from 'react';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { AppButton } from 'components';
import { ORGANIZATIONS_USERS_API } from 'services/CONSTANTS';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { deleteEntityData } from '@services';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';

import { useTranslation } from 'react-i18next';

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleSpaceLabel = styled.div`
  display: flex;
  justify-content: center;
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding: 10px 10px 10px 10px;
`;

const StyleSpaceName = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ff5555;
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalText = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  padding: 5px 5px 5px 5px;
  text-align: center;
`;

const InputIcon = styled.img``;

const ModalContentWrapper = styled.div`
  padding: 5px 35px 34px 35px;
`;

export const DeleteEmployeeOrganization = ({
  handleCloseDeleteEmployeeModal,
  setShowSucessModal,
  data,
}) => {
  const { t } = useTranslation();
  const { employee } = data;
  const [showMessage, setShowMessage] = useState({ show: false, type: '', title: '', message: '' });
  const [loading, setLoading] = useState(false);

  const deleteEmployee = () => {
    setLoading(true);
    setShowMessage((prev) => {
      return { ...prev, show: false };
    });
    deleteEntityData(`${ORGANIZATIONS_USERS_API}${employee.id}`)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.employee_deleted')}`,
          message: '',
        });
        handleCloseDeleteEmployeeModal();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 400
        ) {
          //Conflict
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_employee_partner')}`,
            message: '',
          });
        } else {
          setShowMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_employee_deleting')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <ModalContentWrapper>
      <StyleSpaceIcon>
        <InputIcon src={ico_Archived} />
      </StyleSpaceIcon>
      <StyleSpaceLabel>{t('Organization.modals.delete_employee_title')}</StyleSpaceLabel>
      <StyleSpaceName>{employee?.user_id.name}</StyleSpaceName>
      <ModalText className="mb-0">{t('Organization.modals.delete_employee_subtitle')}</ModalText>
      <StyleSpaceButtons>
        <Formik
          onSubmit={deleteEmployee}
          initialValues={{
            email: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched, isValid }) => (
            <Form noValidate onSubmit={handleSubmit} className="baseForm">
              <AppButton
                style={{ width: 'auto', height: 64, marginTop: '6%' }}
                type="submit"
                loading={loading}
                bgcolor={PALETTE_COLOR.ACCENT_RED}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
                bghovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
              >
                {t('buttons.btn_remove')}
              </AppButton>
              {showMessage.show && (
                <CustomizedSnackbar
                  show={showMessage.show}
                  type={showMessage.type}
                  title={showMessage.title}
                  message={showMessage.message}
                />
              )}
            </Form>
          )}
        </Formik>
      </StyleSpaceButtons>
    </ModalContentWrapper>
  );
};
