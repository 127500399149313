import { useState } from 'react';
import { getCurrentSession, logOut } from '@services';
import { getEntityData, logUserSessionIn, getUserDetails } from 'services';
import { VALUES_DEFINITIONS_API } from '@services/CONSTANTS';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { setAppLoadValuesDefinitionsAction, setAppLoadUserAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const qb = RequestQueryBuilder.create().sortBy({ field: 'value_definition_id', order: 'DESC' });

  // signin method: It can either return a promise or execute a callback function.
  // You can prefer to keep this in userServices.js
  const signin = (isChangeStatus) => {
    return new Promise(async (resolve, reject) => {
      try {
        const info = await getCurrentSession();
        resolve(info);
        logUserSessionIn();
        //GET ALL VALUES DEFINITIONS CONFIG
        getEntityData(VALUES_DEFINITIONS_API, qb.query())
          .then((vdRes) => {
            let valuesDefinitions = vdRes.data;
            dispatch(setAppLoadValuesDefinitionsAction(valuesDefinitions));
          })
          .catch((err) => {
            console.log('App >> LOAD_VALUES_DEFINITIONS >> axios err=', err);
          });
        console.log('App >> LOAD_LOGGED_USER');
        //load current user info
        getUserDetails(info.idToken?.payload?.sub)
          .then((res) => {
            dispatch(setAppLoadUserAction({ ...res, cognitoUser: info }));
          })
          .catch((err) => {
            console.log('App >> Getting logged user >> axios err=', err);
          });
      } catch (error) {
        console.error('signin error!==', error);
        reject('signin error!');
      }
    });
  };

  const signout = () => {
    return new Promise((resolve, reject) => {
      try {
        // do API endpoint axios call here and return the promise.
        setUser(null);
        logOut();
        resolve(true);
      } catch (error) {
        console.error('signout error!==', error);
        reject('signout error!');
      }
    });
  };

  const getUserData = async () => {
    const user = await getCurrentSession();
    setUser(user);
    return new Promise((resolve, reject) => {
      try {
        resolve(user);
      } catch (error) {
        console.error('signin error!==', error);
        reject('signin error!');
      }
    });
  };

  return {
    user,
    getUserData,
    signin,
    signout,
  };
}
