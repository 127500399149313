import { PALETTE_COLOR } from '@config/CONSTANTS';
import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StyleFilters = styled.div`
  display: flex;
  padding: 0px 20px;
  font-size: 20px;
  justify-content: space-around;
`;

const FieldFilters = styled.div`
  padding: 0px 20px;
  width: 100%;
`;

const StyledCard = styled.div`
  width: 100%;
  height: auto;
  left: 0px;
  top: 0px;
  color: #000000;
  display: flex;
  justify-content: space-between;
`;

const StyledCardLabel = styled.div`
  justify-content: start !important;
  -webkit-flex-direction: row !important;
  margin-bottom: 20px;
  padding: var(--bs-gutter-x, 0.75rem);
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  justify-content: space-between;
`;

const StyledCardIcon = styled(InputGroup.Text)`
  background-color: white !important;
  padding: 10px 10px;
  border-radius: 16px;
  margin-right: 20px;
  border: unset;
  background: unset;
`;
const CardIcon = styled.img``;

const StyledGroupContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 5px 10px 5px 10px;
  margin-right: 14px;
  background-color: #e4ebf6;
  border-radius: 16px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  width: auto;
  padding: 9px 21px 9px 21px;
  margin-right: 2px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  background-color: #e4ebf6;
  border-color: #e4ebf6 !important;
  :active {
    border: 1px solid rgba(109, 115, 123, 0.2);
    box-sizing: border-box;
    color: white;
    background-color: ${PALETTE_COLOR.MAIN_BLUE} !important;
    box-shadow: 0px 4px 10px rgba(131, 180, 49, 0.3) !important;
    border: unset;
  }
  :focus,
  :hover {
    color: white;
    background-color: ${PALETTE_COLOR.MAIN_BLUE} !important;
    box-shadow: unset !important;
  }
`;

const StyledBadge = styled('div')((props) => ({
  height: '20px',
  width: 'auto',
  minWidth: '20px',
  borderRadius: '50px',
  border: `2px solid ${PALETTE_COLOR.MAIN_BLUE}`,
  backgroundColor: `${PALETTE_COLOR.MAIN_WHITE}`,
  position: 'absolute',
  right: '25px',
  top: '5px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

const StyledTextBadge = styled('span')((props) => ({
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '12px',
  color: `${PALETTE_COLOR.MAIN_BLUE}`,
  marginLeft: '5px',
  marginRight: '5px',
}));

export function Encabezado(props) {
  const { title, children, icon, options, indexActive, filters, testId } = props;
  const { countChatMessages } = useSelector((state) => state.app);

  const [selectedBtn, setSelectedBtn] = React.useState(indexActive);
  return (
    <StyledCard data-testId={testId}>
      <StyledCardLabel>
        {icon && (
          <StyledCardIcon>
            <CardIcon src={icon}></CardIcon>
          </StyledCardIcon>
        )}
        <div data-testId={`${testId}_title`}>{title}</div>

        {filters && (
          <StyleFilters>
            {filters.map((item, index) => {
              if (index > 0) {
                return (
                  <FieldFilters key={index} style={{ padding: '5px', cursor: 'pointer' }}>
                    {' '}
                    {item.filter}
                  </FieldFilters>
                );
              } else {
                return <FieldFilters style={{ cursor: 'pointer' }}> {item.filter}</FieldFilters>;
              }
            })}
          </StyleFilters>
        )}
      </StyledCardLabel>

      {options && (
        <StyledGroupContainer data-testId={`${testId}_options`}>
          {options.map((item, index) => {
            return (
              <StyledButton
                key={index}
                style={{
                  backgroundColor: selectedBtn === index && PALETTE_COLOR.MAIN_BLUE,
                  color: selectedBtn === index && 'white',
                }}
                onClick={() => {
                  setSelectedBtn(index);
                  item.onClick();
                }}
              >
                {item.label}
                {countChatMessages > 0 && item.label.toLowerCase().includes('chat') && (
                  <StyledBadge>
                    <StyledTextBadge>{countChatMessages}</StyledTextBadge>
                  </StyledBadge>
                )}
              </StyledButton>
            );
          })}
        </StyledGroupContainer>
      )}
      {children}
    </StyledCard>
  );
}
export default Encabezado;
