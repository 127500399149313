// You can use CONSTANTS.js file for below definitions of constants and import here.
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const LOADING = 'LOADING';
export const LOAD_VALUES_DEFINITIONS = 'LOAD_VALUES_DEFINITIONS';
export const LOAD_LOGGED_USER = 'LOAD_LOGGED_USER';
export const SET_JOB_SCHEDULER_GROUP = 'SET_JOB_SCHEDULER_GROUP';
export const SET_JOB_SCHEDULER_SITES = 'SET_JOB_SCHEDULER_SITES';
export const SET_RELOAD_SCHEDULER = 'SET_RELOAD_SCHEDULER';
export const SET_RELOAD_TIMESHEET = 'SET_RELOAD_TIMESHEET';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_TEMPLATES = 'SHOW_TEMPLATES';
export const SET_JOB_SCHEDULER_QUERIES = 'SET_JOB_SCHEDULER_QUERIES';
export const SET_SITE_WEEK_SCHEDULER_MODAL_OPEN = 'SET_SITE_WEEK_SCHEDULER_MODAL_OPEN';
export const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
export const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE';
export const LOAD_NEW_CHAT_MESSAGE_FOCUS = 'LOAD_NEW_CHAT_MESSAGE_FOCUS';
export const SET_DESTINATION_CHAT_USER_ID = 'SET_DESTINATION_CHAT_USER_ID';
export const LOADING_CHAT = 'LOADING_CHAT';
export const SET_PROFILE_IMAGE_TO_SAVE = 'SET_PROFILE_IMAGE_TO_SAVE';
export const SET_RELOAD_NOTIFICATIONS = 'SET_RELOAD_NOTIFICATIONS';
export const SET_TIME_SHEET_DATES = 'SET_TIME_SHEET_DATES';
export const SET_COUNT_CHAT_MESSAGES = 'SET_COUNT_CHAT_MESSAGES';
export const SET_USERS_CHAT_MESSAGES = 'SET_USERS_CHAT_MESSAGES';
export const SET_UPDATED_CHAT_MESSAGES = 'SET_UPDATED_CHAT_MESSAGES';
export const FOCUS_STATUS = 'FOCUS_STATUS';
export const GLOBAL_ORGANIZATION = 'GLOBAL_ORGANIZATION';
// Without THUNK MIDDLEWARE only actions can be dispatched.
export const incrementAction = (payload) => ({
  type: INCREMENT,
  payload,
});

// THUNK MIDDLEWARE enables dispatch within action function's return method.
export const increment = (step) => {
  return (dispatch) => {
    dispatch(incrementAction(step));
  };
};

export const decrementAction = () => ({
  type: DECREMENT,
});

export const setAppLoadingAction = (payload) => ({
  type: LOADING,
  payload,
});

export const setAppLoading = (isLoading) => {
  return (dispatch) => {
    dispatch(setAppLoadingAction(isLoading));
  };
};

export const setReloadNotifications = (payload) => ({
  type: SET_RELOAD_NOTIFICATIONS,
  payload,
});

export const setAppLoadValuesDefinitionsAction = (payload) => ({
  type: LOAD_VALUES_DEFINITIONS,
  payload,
});

export const setAppLoadValuesDefinitions = (valuesDefinitions) => {
  return (dispatch) => {
    dispatch(setAppLoadValuesDefinitionsAction(valuesDefinitions));
  };
};

export const setAppLoadUserAction = (payload) => ({
  type: LOAD_LOGGED_USER,
  payload,
});

export const setAppLoadUser = (user) => {
  return (dispatch) => {
    dispatch(setAppLoadUserAction(user));
  };
};

export const setJobSchedulerGroupAction = (payload) => ({
  type: SET_JOB_SCHEDULER_GROUP,
  payload,
});

export const setJobSchedulerGroup = (group) => {
  return (dispatch) => {
    dispatch(setJobSchedulerGroupAction(group));
  };
};

export const setJobSchedulerSitesAction = (payload) => ({
  type: SET_JOB_SCHEDULER_SITES,
  payload,
});

export const setJobSchedulerSites = (sites) => {
  return (dispatch) => {
    dispatch(setJobSchedulerSitesAction(sites));
  };
};

export const setReloadSchedulerAction = (payload) => ({
  type: SET_RELOAD_SCHEDULER,
  payload,
});

export const setReloadTimeSheetAction = (payload) => ({
  type: SET_RELOAD_TIMESHEET,
  payload,
});

export const setReloadTimeSheet = (sites) => {
  return (dispatch) => {
    dispatch(setReloadTimeSheetAction(sites));
  };
};

export const setReloadScheduler = (sites) => {
  return (dispatch) => {
    dispatch(setReloadSchedulerAction(sites));
  };
};

export const showMessageAction = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const showMessage = (sites) => {
  return (dispatch) => {
    dispatch(showMessageAction(sites));
  };
};

export const showTemplatesAction = (payload) => ({
  type: SHOW_TEMPLATES,
  payload,
});

export const showTemplates = (sites) => {
  return (dispatch) => {
    dispatch(showTemplatesAction(sites));
  };
};

export const setJobSchedulerQueriesAction = (payload) => ({
  type: SET_JOB_SCHEDULER_QUERIES,
  payload,
});

export const setJobSchedulerQueries = (sites) => {
  return (dispatch) => {
    dispatch(setJobSchedulerQueriesAction(sites));
  };
};

export const setSiteWeekSchedulerModalOpenAction = (payload) => ({
  type: SET_SITE_WEEK_SCHEDULER_MODAL_OPEN,
  payload,
});

export const setSiteWeekSchedulerModalOpen = (isOpen) => {
  return (dispatch) => {
    dispatch(setSiteWeekSchedulerModalOpenAction(isOpen));
  };
};

export const setUserPermissionsAction = (payload) => ({
  type: LOAD_USER_PERMISSIONS,
  payload,
});

export const setUserPermissions = (payload) => {
  return (dispatch) => {
    dispatch(setUserPermissionsAction(payload));
  };
};

export const setNewChatMessageAction = (payload) => ({
  type: NEW_CHAT_MESSAGE,
  payload,
});

export const setNewChatMessage = (payload) => {
  return (dispatch) => {
    dispatch(setNewChatMessageAction(payload));
  };
};

export const setLoadNewChatMessageFocusAction = (payload) => ({
  type: LOAD_NEW_CHAT_MESSAGE_FOCUS,
  payload,
});

export const setLoadNewChatMessageFocus = (payload) => {
  return (dispatch) => {
    dispatch(setLoadNewChatMessageFocusAction(payload));
  };
};

export const setDestinationChatUserIdAction = (payload) => ({
  type: SET_DESTINATION_CHAT_USER_ID,
  payload,
});

export const setDestinationChatUserId = (payload) => {
  return (dispatch) => {
    dispatch(setDestinationChatUserIdAction(payload));
  };
};

export const setLoadingChatAction = (payload) => ({
  type: LOADING_CHAT,
  payload,
});

export const setLoadingChat = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingChatAction(payload));
  };
};

export const setProfilePhotoToSaveAction = (payload) => ({
  type: SET_PROFILE_IMAGE_TO_SAVE,
  payload,
});

export const setProfilePhotoToSave = (payload) => {
  return (dispatch) => {
    dispatch(setProfilePhotoToSaveAction(payload));
  };
};

export const setTimeSheetDatesAction = (payload) => ({
  type: SET_TIME_SHEET_DATES,
  payload,
});

export const setTimeSheetDates = (payload) => {
  return (dispatch) => {
    dispatch(setTimeSheetDatesAction(payload));
  };
};

export const setCountChatMessagesAction = (payload) => ({
  type: SET_COUNT_CHAT_MESSAGES,
  payload,
});

export const setCountChatMessages = (payload) => {
  return (dispatch) => {
    dispatch(setCountChatMessagesAction(payload));
  };
};

export const setUsersChatMessagesAction = (payload) => ({
  type: SET_USERS_CHAT_MESSAGES,
  payload,
});

export const setUsersChatMessages = (payload) => {
  return (dispatch) => {
    dispatch(setUsersChatMessagesAction(payload));
  };
};

export const setUpdatedChatMessagesAction = (payload) => ({
  type: SET_UPDATED_CHAT_MESSAGES,
  payload,
});

export const setUpdatedChatMessages = (payload) => {
  return (dispatch) => {
    dispatch(setUpdatedChatMessagesAction(payload));
  };
};

export const setFocusStatusAction = (payload) => ({
  type: FOCUS_STATUS,
  payload,
});

export const setFocusStatus = (payload) => {
  return (dispatch) => {
    dispatch(setFocusStatusAction(payload));
  };
};

export const setGlobalOrganizationAction = (payload) => ({
  type: GLOBAL_ORGANIZATION,
  payload,
});

export const setGlobalOrganization = (payload) => {
  return (dispatch) => {
    dispatch(setGlobalOrganizationAction(payload));
  };
};