import React from 'react';
import Header from './Header';
import Navigation from './Navigation';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomizedSnackbar } from '@components';
import { useSelector } from 'react-redux';
import {
  showMessageAction,
  showTemplatesAction,
  setNewChatMessageAction,
  setCountChatMessagesAction,
  setUsersChatMessagesAction,
  setUpdatedChatMessagesAction,
  setFocusStatus,
  /*setLoadNewChatMessageFocusAction,*/
} from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { WeekTeemplatesLoader } from '@pages/JobScheduler/AditionalActions/WeekTeemplatesLoader';
import IconButton from '@mui/material/IconButton';
import close_square from '@assets/icons/close_square.svg';
import { fetchToken, onMessageListener } from '../firebase';
import { Toast, ToastContainer } from 'react-bootstrap';
import logowe from '@assets/images/logo_we.svg';
//import { setReloadNotifications } from 'redux/actions/appActions';

import { useTranslation } from 'react-i18next';
import { getEntityData } from 'services';
import { USERS_API } from 'services/CONSTANTS';

const LogoWe = styled.img`
  width: 24px;
  height: 24px;
`;

const Wrapper = styled.div`
  @media (min-width: 700px) {
    display: flex;
    top: 61px;
    position: relative;
    height: calc(100% - 64px);
    width: 100%;
    flex: auto;
    flex-direction: column;
  }
`;

const Main = styled.main`
  position: fixed;
  height: calc(100% - 185px);
  width: 100%;
  padding: 1em;
  background: #f5f9ff;
  @media (min-width: 700px) {
    flex: 1;
    margin-left: 60px;
    height: calc(100% - 64px);
    width: calc(100% - 60px);
  }
`;

const CustomModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 400px;
  height: 580px;
  position: absolute;
  right: 50px;
  z-index: 1000;
  bottom: 20px;
`;

const CustomModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
`;

export const Layout = ({ children }) => {
  const { t } = useTranslation();
  const { messageData } = useSelector((state) => state.app);
  const { showTemplates } = useSelector((state) => state.app);
  const [show, setShow] = React.useState(false);
  const [notification, setNotification] = React.useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = React.useState(false);
  const [chatFocus, setChatFocus] = React.useState(true);
  const [callUnreaded, setCallUnreaded] = React.useState(false);
  const dispatch = useDispatch();
  fetchToken(setTokenFound);

  React.useEffect(() => {
    const handleActivityFalse = () => {
      setChatFocus(false);
      setCallUnreaded(false);
    };

    const handleActivityTrue = () => {
      setChatFocus(true);
      setCallUnreaded(true);
    };

    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);

    return () => {
      window.removeEventListener('focus', handleActivityTrue);
      window.removeEventListener('blur', handleActivityFalse);
    };
  }, [chatFocus]);

  React.useEffect(() => {
    if (callUnreaded) {
      try {
        getEntityData(`${USERS_API}chat/unreaded`, '')
          .then((res) => {
            dispatch(setUsersChatMessagesAction(res));
            let totalMessageCount = res.reduce(
              (acc, message) => acc + Number(message.unreadMessages),
              0,
            );
            dispatch(setCountChatMessagesAction(totalMessageCount));
            dispatch(setUpdatedChatMessagesAction(true));
          })
          .catch((err) => {
            console.log('📌 - file: Layout.js:128 - .then - err:', err);
          });
      } catch (error) {
        console.log('📌 - file: Layout.js:131 - .then - error:', error);
      }
    }
    dispatch(setFocusStatus(callUnreaded));
  }, [callUnreaded, dispatch]);

  onMessageListener()
    .then((payload) => {
      //Si es de tipo chat message
      if (payload.data && payload.data.type === 'newMessageChat') {
        //Almacenar en redux obj -> {type: 'newMessageChat', source: chatMessageDto.source.id, destination: chatMessageDto.destination.id }
        dispatch(setNewChatMessageAction(payload.data));
        try {
          getEntityData(`${USERS_API}chat/unreaded`, '')
            .then((res) => {
              dispatch(setUsersChatMessagesAction(res));
              let totalMessageCount = res.reduce(
                (acc, message) => acc + Number(message.unreadMessages),
                0,
              );
              dispatch(setCountChatMessagesAction(totalMessageCount));
              dispatch(setUpdatedChatMessagesAction(true));
              setShow(true);
              setNotification({ title: payload.notification.title, body: payload.notification.body });
            })
            .catch((err) => {
              console.log('📌 - file: Layout.js:154 - .then - err:', err);
            });
        } catch (error) {
          console.log('📌 - file: Layout.js:157 - .then - error:', error);
        }
      } else {
        setShow(true);
        setNotification({ title: payload.notification.title, body: payload.notification.body });
      }
    })
    .catch((err) => console.log('failed: ', err));

  const updateMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /*const reloadNotification = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadNotifications(isLoading));
    },
    [dispatch],
  );

  function reloadNoti() {
    reloadNotification(true);
  }
  setInterval(reloadNoti, 20000);*/

  const updateShowWeekTemplate = React.useCallback(
    async (payload) => {
      dispatch(showTemplatesAction(payload));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (messageData && messageData.show) {
      setTimeout(() => {
        updateMessage({ ...messageData, show: false });
      }, 5000);
    }
  }, [messageData, updateMessage]);

  ////////////////////////////////Focus////////////////////////////////////////

  /* React.useEffect(() => {
    const handleFocus = () => {
      console.log('Tab has focus');
      dispatch(setLoadNewChatMessageFocusAction(true));
    };

    const handleBlur = () => {
      console.log('Tab lost focus');
      dispatch(setLoadNewChatMessageFocusAction(false));
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [dispatch]);*/

  ////////////////////////////////Focus////////////////////////////////////////

  React.useEffect(() => {
    try {
      getEntityData(`${USERS_API}chat/unreaded`, '')
        .then((res) => {
          let totalMessageCount = res.reduce(
            (acc, message) => acc + Number(message.unreadMessages),
            0,
          );
          dispatch(setCountChatMessagesAction(totalMessageCount));
        })
        .catch((err) => {
          console.log('📌 - file: Layout.js:184 - React.useEffect - err:', err);
        });
    } catch (error) {
      console.log('📌 - file: Layout.js:187 - React.useEffect - error:', error);
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <Navigation />

        <Main>
          <Scrollbars>{children}</Scrollbars>
          {messageData && messageData.show && (
            <CustomizedSnackbar
              show={messageData.show}
              type={messageData.type}
              title={messageData.title}
              message={messageData.message}
            />
          )}
          {/**TEMPLATES*/}
          {showTemplates.visible ? (
            <CustomModalWrapper>
              <CustomModalTitle>
                <div
                  style={{ fontWeight: 400, fontSize: 24, margin: '18px 18px 20px 30px' }}
                >{`${showTemplates.title}`}</div>
                <IconButton
                  aria-label="close"
                  onClick={() => updateShowWeekTemplate({ visible: false })}
                  sx={{
                    position: 'absolute',
                    right: 16,
                    top: 10,
                  }}
                >
                  <img src={close_square} alt="close_square" />
                </IconButton>
              </CustomModalTitle>
              <WeekTeemplatesLoader type={showTemplates.type} />
            </CustomModalWrapper>
          ) : null}
          <ToastContainer className="p-3" position={'bottom-end'}>
            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation>
              <Toast.Header>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: 16,
                  }}
                >
                  <div>
                    <LogoWe src={logowe} className="rounded mr-2" alt="logo" />
                    <strong className="mr-auto">{notification.title}</strong>
                  </div>
                  <small>{t('just_now')}</small>
                </div>
              </Toast.Header>
              <Toast.Body>{notification.body}</Toast.Body>
            </Toast>
          </ToastContainer>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};
