import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { AppAvatar, AppModal } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useDispatch } from 'react-redux';
import { setProfilePhotoToSaveAction } from '@redux/actions/appActions';
import { useSelector } from 'react-redux';
import { InputGroup } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import arrow_left from '@assets/icons/ic_arrow_left_linear.svg';
import arrow_right from '@assets/icons/ic_arrow_right_linear.svg';
import { ChangeAvatarForm } from '@pages/Profile/ChangeAvatar/ChangeAvatarForm';

import { useTranslation } from 'react-i18next';

const ContainerWrapper = styled(Container)`
  margin: 0px;
  padding: 0px;
  width: ${(props) => (props.container === 1 ? '800px' : 'unset')};
`;

const StyledCardLabel = styled.div`
  font-size: 24px;
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  display: flex;
  padding: 10px ${(props) => (props.notitlepadding ? 0 : 30)}px !important;
  font-weight: normal !important;
  justify-content: flex-start;
`;

const Icon = styled.img``;

const StyledCardIcon = styled(InputGroup.Text)`
  border: none;
  padding: 0.375rem 0rem;
  background: unset;
`;

const ActionButton = styled(Button)`
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  :hover,
  :focus {
    border: none;
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

export const CreateCustomAvatar = (props) => {
  const { t } = useTranslation();
  const { setShowSucessModal, icon, call, onAvatarChange = null, container } = props;
  //Gt data from redux
  const { userData, profileImageToSave } = useSelector((state) => state.app);
  //User config
  const avatarConfig = userData.id ? JSON.parse(userData.avatar_config) : null;
  //Open modal to select a custom avatar
  const [openChangeAvatarForm, setOpenChangeAvatarForm] = React.useState(false);
  //Variables to set random avatars
  const [contAvatars, setContAvatars] = React.useState(0);
  const [fillAvatars, setFillAvatars] = React.useState(false);
  const [searches] = React.useState([]);
  //Const to set random avatars
  const genderOptions = ['female', 'male'];
  const avatarIdOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const skinColorOptions = ['#F5D0C5', '#FFCB7E', '#836055'];
  const bgColorOptions = [
    PALETTE_COLOR.AVATAR_LIGHT_BLUE,
    PALETTE_COLOR.AVATAR_PURPLE,
    PALETTE_COLOR.AVATAR_GREEN,
    PALETTE_COLOR.AVATAR_ORANGE,
    PALETTE_COLOR.AVATAR_YELLOW,
    PALETTE_COLOR.AVATAR_PINK,
    PALETTE_COLOR.AVATAR_BLUE,
  ];
  //Redux
  const dispatch = useDispatch();
  //Initial change avatar
  const [changeAvatar, setChangeAvatar] = React.useState({
    data: false,
    avatarId: '',
    gender: '',
    skinColor: '',
    bgColor: '',
  });

  /***
   * Fill random avatar array
   */
  React.useEffect(() => {
    const handleRandemAvatar = () => {
      for (var i = 0; i < 16; i++) {
        let randomAvatar = {
          avatarId: avatarIdOptions[Math.floor(Math.random() * avatarIdOptions.length)],
          gender: genderOptions[Math.floor(Math.random() * genderOptions.length)],
          skinColor: skinColorOptions[Math.floor(Math.random() * skinColorOptions.length)],
          bgColor: bgColorOptions[Math.floor(Math.random() * bgColorOptions.length)],
        };
        searches.push(randomAvatar);
        setFillAvatars(true);
      }
    };
    if (fillAvatars === false) {
      handleRandemAvatar();
    }
  });

  /***
   *
   */
  React.useEffect(() => {
    const avatarConfig = userData.id ? JSON.parse(userData.avatar_config) : null;
    setChangeAvatar({ ...avatarConfig, data: false });
    if (onAvatarChange) {
      onAvatarChange(avatarConfig);
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  /***
   * Store selected avatar when set on in <ChangeAvatarForm/>
   */
  React.useEffect(() => {
    if (profileImageToSave.isAvatar === true && profileImageToSave.config) {
      const avatarConfig = profileImageToSave.config ? profileImageToSave.config : null;
      setChangeAvatar({ ...avatarConfig, data: true });
      if (onAvatarChange) {
        onAvatarChange(avatarConfig);
      }
    }
  }, [profileImageToSave]); // eslint-disable-line react-hooks/exhaustive-deps

  /***
   *
   */
  const handleClickOpenChangeAvatarFormModal = () => {
    setOpenChangeAvatarForm(true);
  };

  /***
   *
   */
  const handleCloseChandeAvatarFormModal = () => {
    setOpenChangeAvatarForm(false);
  };

  /***
   *
   */
  const handleChangeNextAvatar = () => {
    let new_contAvatars = contAvatars;
    if (contAvatars === 15) {
      new_contAvatars = 0;
    } else {
      new_contAvatars = contAvatars + 1;
    }
    setContAvatars(new_contAvatars);
    let dataAvatar = {
      avatarId: searches[new_contAvatars].avatarId,
      gender: searches[new_contAvatars].gender,
      skinColor: searches[new_contAvatars].skinColor,
      bgColor: searches[new_contAvatars].bgColor,
    };
    setChangeAvatar({ ...dataAvatar, data: true });
    //Store in redux to be sent to WS on "Save" button
    dispatch(setProfilePhotoToSaveAction({ isAvatar: true, config: dataAvatar }));
    if (onAvatarChange) {
      onAvatarChange(dataAvatar);
    }
  };

  /***
   *
   */
  const handleChangeOldAvatar = () => {
    let new_contAvatars = contAvatars;
    if (contAvatars === 0) {
      new_contAvatars = 15;
    } else {
      new_contAvatars = contAvatars - 1;
    }
    setContAvatars(new_contAvatars);
    let dataAvatar = {
      avatarId: searches[new_contAvatars].avatarId,
      gender: searches[new_contAvatars].gender,
      skinColor: searches[new_contAvatars].skinColor,
      bgColor: searches[new_contAvatars].bgColor,
    };
    setChangeAvatar({ ...dataAvatar, data: true });
    //Store in redux to be sent to WS on "Save" button
    dispatch(setProfilePhotoToSaveAction({ isAvatar: true, config: dataAvatar }));
    if (onAvatarChange) {
      onAvatarChange(dataAvatar);
    }
  };

  return (
    <ContainerWrapper container={container}>
      {/* Avatar */}
      <Row>
        <Col
          md={{ span: 10, offset: 1 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledCardLabel style={{ paddingBottom: '20px !important' }} {...props}>
            <div>
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton onClick={handleChangeOldAvatar}>
                    <Icon
                      src={arrow_left}
                      style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  </IconButton>
                </Col>
                <Col>
                  {changeAvatar.data === false && userData.avatar_config ? (
                    <AppAvatar
                      avatarId={avatarConfig?.avatarId}
                      bgColor={avatarConfig?.bgColor}
                      gender={avatarConfig?.gender}
                      skinColor={avatarConfig?.skinColor}
                      width={128}
                      height={128}
                    />
                  ) : changeAvatar.data === true ? (
                    <AppAvatar
                      avatarId={changeAvatar?.avatarId}
                      bgColor={changeAvatar?.bgColor}
                      gender={changeAvatar?.gender}
                      skinColor={changeAvatar?.skinColor}
                      width={128}
                      height={128}
                    />
                  ) : (
                    //User has a photo, so shows a default avatar
                    <AppAvatar
                      avatarId={1}
                      bgColor={'#E4ECF7'}
                      gender={'male'}
                      skinColor={'#F5D0C5'}
                      width={128}
                      height={128}
                    />
                  )}
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton onClick={handleChangeNextAvatar}>
                    <Icon
                      src={arrow_right}
                      style={{
                        width: 24,
                        height: 24,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  </IconButton>
                </Col>
              </Row>
              <Row>
                <ActionButton
                  variant="link"
                  className="my-1"
                  onClick={handleClickOpenChangeAvatarFormModal}
                >
                  {t('Login.choose_avatar.create_avatar')}
                </ActionButton>
              </Row>
            </div>
          </StyledCardLabel>
        </Col>
        <Col md={1} style={{ textAlign: 'end' }}>
          <StyledCardIcon>
            {icon && (
              <IconButton
                aria-label="edit"
                onClick={call}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 10,
                }}
              >
                <Icon src={icon} />
              </IconButton>
            )}
          </StyledCardIcon>
        </Col>
      </Row>
      {/*Change Avatar modal*/}
      <AppModal
        PaperProps={{ sx: { maxWidth: 800 } }}
        open={openChangeAvatarForm}
        handleclose={handleCloseChandeAvatarFormModal}
        title={t('Login.choose_avatar.modal_create_avatar_title')}
        inside={0}
      >
        <ChangeAvatarForm
          handleclose={handleCloseChandeAvatarFormModal}
          setShowSucessModal={setShowSucessModal}
          userdata={userData}
          onAvatarChanged={(e) => {}}
        />
      </AppModal>
    </ContainerWrapper>
  );
};
