import React, { useState, useEffect } from 'react';
import { AppTable, AppModal } from '@components';

import { VALUES_DEFINITIONS_API, USERS_API } from '@services/CONSTANTS';
import styled from 'styled-components';
import logo_edit from '@assets/icons/edit.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { useParams } from 'react-router-dom';
import { obtainEntityData } from 'services';
import EncView from '@components/EncView';
import IconButton from '@mui/material/IconButton';
import { EditJobTitle } from './EditJobTitle';
import { CondOperator } from '@nestjsx/crud-request';
import { size } from 'lodash';
import { calcAvatar } from 'utils/avatar';
import group from '@assets/icons/group.svg';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const HeaderTitle = styled.h3`
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
`;

const StyledLabel = styled.div`
  margin-left: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
  p {
    margin: 8px auto auto 10px;
  }
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${PALETTE_COLOR.MAIN_WHITE} !important;
  border: 1px solid ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
  padding: 12px !important;
  :hover {
    background-color: ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
  }
`;

const StyledGroups = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

const Icon = styled.img``;

export const ListDetailJobTitle = () => {
  const { t } = useTranslation();
  let { jobTitleId } = useParams();
  const [openForm, setOpenForm] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });
  const [jobTitleData, setJobTitleData] = useState();
  //Redux
  const { userData } = useSelector((state) => state.app);
  //Joins and filters
  const jobTitleJoins = React.useMemo(() => {
    return [{ field: 'jobs_titles.job_title' }, { field: 'user_groups.group' }];
  }, []);

  const jobTitleFilters = React.useMemo(() => {
    return [
      {
        field: 'job_title.value_definition_id',
        operator: CondOperator.EQUALS,
        value: jobTitleId,
      },
    ];
  }, [jobTitleId]);

  const jobTitleColumns = [
    {
      dataField: 'id',
      text: `${t('table_columns.ID')}`,
      sort: true,
      search: true,
      headerStyle: { width: '8%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.id}</StyledLabel>;
      },
    },
    {
      dataField: 'name',
      text: `${t('table_columns.name')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {calcAvatar(row, 34, 34)}
            <p>{row.name}</p>
          </StyledLabel>
        );
      },
    },
    {
      dataField: 'group',
      text: `${t('table_columns.group')}`,
      sort: false,
      search: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (size(row.user_groups) > 0) {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {row.user_groups.map((gp, index) => (
                <StyledGroups key={index}>{gp.group.name}</StyledGroups>
              ))}
            </div>
          );
        }
      },
    },
    {
      dataField: 'email',
      text: `${t('table_columns.email')}`,
      sort: false,
      search: false,
      headerStyle: { width: '25%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.email}</StyledLabel>;
      },
    },
    {
      dataField: 'phone',
      text: `${t('table_columns.phone')}`,
      sort: false,
      search: false,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.phone}</StyledLabel>;
      },
    },
  ];

  //Get jobtitle data by id
  useEffect(() => {
    obtainEntityData(`${VALUES_DEFINITIONS_API}${jobTitleId}`)
      .then((res) => {
        setJobTitleData(res);
      })
      .catch((err) => {
        console.log('axios err=', err);
      });

    return () => {
      console.log('axios cleanup.');
    };
  }, [jobTitleId, showSucessModal]);

  /***
   * Shows/hides modal
   */
  const handleFormModal = () => {
    setOpenForm(!openForm);
  };

  return (
    <div>
      <EncView>
        <SpaceEnc>
          <div>
            <HeaderTitle>{jobTitleData?.description}</HeaderTitle>
            <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>{t('Groups.view.job_titles.header_info')}</p>
          </div>
          <HeaderButton>
            {userData?.cognitoUser?.idToken?.payload['cognito:groups'][0] === 'system' ? (
              <></>
            ) : (
              <StyledIconButton
                title={t('buttons.titles.edit')}
                aria-label="edit icon"
                onClick={() => handleFormModal()}
              >
                <Icon src={logo_edit} alt="edit icon" />
              </StyledIconButton>
            )}
          </HeaderButton>
        </SpaceEnc>
      </EncView>

      <AppTable
        endpointPath={USERS_API}
        columns={jobTitleColumns}
        withAdvanceFilter={true}
        reload={showSucessModal.show}
        joins={jobTitleJoins}
        filters={jobTitleFilters}
        headerIcon={group}
        headerText={t('table_headers.assigned_employees_header')}
        //TODO Add a custom reload prop to each AppTable to load data only when a row is added, edited or removed.
        //To more info see GroupList.js
      />

      {/* succ error message */}
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {/* Edit form modal */}
      <AppModal open={openForm} handleclose={handleFormModal} title={t('Groups.modals.edit_job_title')} inside={1}>
        <EditJobTitle
          handleclose={handleFormModal}
          setShowSucessModal={setShowSucessModal}
          data={{ id: jobTitleData?.value_definition_id, name: jobTitleData?.description }}
        />
      </AppModal>
    </div>
  );
};
