import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { CustomizedSnackbar, AppButton, AppDropDownSelect } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { editEntityData, getEntityData } from '@services';
import { GROUPS_API, USERS_API } from 'services/CONSTANTS';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const EditGroupForm = ({
  handleclose,
  setShowSucessModal,
  data: { id, user_groups },
  selectedGroup,
}) => {
  const { t } = useTranslation();
  const addGroupToUserSchema = Yup.object().shape({
    permission: Yup.object().nullable().required(`${t('validation.permission_required')}`),
    group: Yup.object().nullable().required(`${t('validation.group_required')}`),
    // action: Yup.string(),
  });
  const { valuesDefinitions } = useSelector((state) => state.app);
  const [permissionList, setPermissionList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  //Get group & permission lists
  useEffect(() => {
    const gePermissionList = () => {
      const list = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USERS_GROUPS_PERMISSION',
      );
      console.log('gePermissionList ', list);
      setPermissionList(list);
    };

    const getGroupsList = () => {
      setIsLoadingGroups(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .select(['id', 'name'])
        .setLimit(1000);
      getEntityData(GROUPS_API, qb.query())
        .then((res) => {
          const userGroups = user_groups.map((item) => {
            return item.group;
          });
          const difference = getDifference(res, userGroups);
          console.log('difference ', difference);
          setGroupsList(difference);
          setIsLoadingGroups(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setIsLoadingGroups(false);
        });
    };
    gePermissionList();
    getGroupsList();
    return () => {
      console.log('axios cleanup.');
    };
  }, [user_groups, valuesDefinitions]);

  const getDifference = (array1, array2) => {
    return array1.filter((item1) => {
      return !array2.some((item2) => {
        return item1.id === item2.id;
      });
    });
  };

  const editUserGroup = (values) => {
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });

    let assingGroupRequest = {};
    const newGroupItem = {
      group: values.group.value.id,
      group_permission: values.permission.value.value_definition_id,
    };

    if (user_groups.length > 0) {
      let items = user_groups
        .filter((item) => selectedGroup.id !== item.id)
        .map((item) => ({
          group: item.group.id,
          group_permission: item.group_permission.value_definition_id,
        }));
      items = [...items, newGroupItem];
      assingGroupRequest = {
        request_user_groups: items,
      };
      setLoading(true);
      editEntityData(`${USERS_API}${id}`, assingGroupRequest)
        .then((res) => {
          setShowSucessModal({
            show: true,
            type: 'success',
            title: `${t('success_messages.group_edited')}`,
            message: '',
          });
          handleclose();
          setLoading(false);
        })
        .catch((err) => {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_group_editing')}`,
            message: '',
          });
          setLoading(false);
        });
    }
  };

  return (
    <Formik
      validationSchema={addGroupToUserSchema}
      onSubmit={editUserGroup}
      initialValues={{
        group: selectedGroup.group
          ? { label: selectedGroup.group.name, value: selectedGroup.group }
          : null,
        permission: selectedGroup.group_permission
          ? {
              label: selectedGroup.group_permission.description,
              value: selectedGroup.group_permission,
            }
          : null,
      }}
    >
      {({ handleSubmit, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.group')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={groupsList.map((option) => ({ label: option.name, value: option }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height="9rem"
                  isSearchable={true}
                  name="group"
                  placeholder={t('required')}
                  isLoading={isLoadingGroups}
                  onChange={(selected) => {
                    setFieldValue('group', selected);
                  }}
                  invalid={touched.group && errors.group}
                  defaultValue={values.group}
                />
                {touched.group && errors.group && (
                  <ErrorText className="px-2 mb-0">{errors.group}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.permission')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={permissionList.map((option) => ({
                    label: `${t(`Users.permissions.${option.description.replace(/\s{2,}/g, ' ').replace(/\s/g, '_')}`)}`,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height="8rem"
                  name="permission"
                  placeholder={t('required')}
                  onChange={(selected) => {
                    console.log('selected');
                    console.log(selected);
                    setFieldValue('permission', selected);
                  }}
                  invalid={touched.permission && errors.permission}
                  defaultValue={values.permission && {
                    label: `${t(`Users.permissions.${values.permission.value.description.replace(/\s{2,}/g, ' ').replace(/\s/g, '_')}`)}`, 
                    value: values.permission.value
                  }}
                />
                {touched.permission && errors.permission && (
                  <ErrorText className="px-2 mb-0">{errors.permission}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_update')}
                >
                  {t('buttons.btn_update')}
                </AppButton>
                <AppButton
                  loading={loading}
                  size="lg"
                  style={{ width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              <AppButton
                loading={loading}
                // className="ml-auto p-2"
                style={{ width: 'auto' }}
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.ACCENT_RED}
                color={PALETTE_COLOR.ACCENT_RED}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                //onClick={handleRemoveGroupModal}
                title={t('buttons.btn_remove')}
              >
                {t('buttons.btn_remove')}
              </AppButton>
            </StyleButtons>
            {showErrorMessage.show && (
              <CustomizedSnackbar
                show={showErrorMessage.show}
                type={showErrorMessage.type}
                title={showErrorMessage.title}
                message={showErrorMessage.message}
              />
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};
