import moment from 'moment';
import i18next from 'i18n'
i18next.changeLanguage(i18next.resolvedLanguage);

// Jun 9, 2022 at 5:25 pm
export const formatFullDate = (preDate) => {
  return moment(preDate).format(`ll [${i18next.t('at')}] h:mm a`);
};

// June 9, 2022
export const formatDate = (preDate) => {
  return moment(preDate).format('LL');
};

export const formatEspecial = (preDate, format) => {
  return moment.utc(preDate).format(format);
};
