import React, { useState } from 'react';
import styled from 'styled-components';
import { SettingsOptions } from './SettingsOptions';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { General } from './General';
import { Breaks } from './Breaks';

import { useTranslation } from 'react-i18next';

const MainWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-radius: 16px;
  width: 98%;
  height: 88%;
  padding: 0px 10px 10px 0px;
  margin: 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
`;

const Row = styled.div`
  flex-direction: row;
  height: 45px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
  }
`;

const SettingsTypeColumn = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  border-width: 1px;
  border-right-style: solid;
  border-right-color: #edf1f8;
  border-bottom: none;
  padding-top: 15px;
`;

const SettingsDataColumn = styled.div`
  flex-direction: column;
  flex: 4;
  display: flex;
  height: 100%;
`;

const SettingName = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const SettingsDataRow = styled.div`
  flex-direction: row;
`;

export const Settings = () => {
  const { t } = useTranslation();
  const [settingSelected, setSettingSelected] = useState({
    id: 1,
    value: `${t('SettingsCenter.general')}`,
    selected: true,
  });

  return (
    <MainWrapper>
      <SettingsTypeColumn>
        <Row style={{ flex: '1' }}>
          <SettingsOptions setSettingSelected={setSettingSelected} />
        </Row>
      </SettingsTypeColumn>
      <SettingsDataColumn>
        <Row
          style={{
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#edf1f8',
          }}
        >
          <SettingName>{`${settingSelected?.value ? settingSelected?.value : ''}`}</SettingName>
        </Row>
        <SettingsDataRow>{settingSelected.id === 1 ? <General /> : <Breaks />}</SettingsDataRow>
      </SettingsDataColumn>
    </MainWrapper>
  );
};
