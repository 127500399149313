const common = {
  firebase: {
    apiKey: 'AIzaSyDvfVGxVu7YNi2wYf583FZ7c_Mh7eeiGRY', // API key
    authDomain: 'horizen-gestion-de-personal.firebaseapp.com', // Constructed with project ID
    projectId: 'horizen-gestion-de-personal', // Project ID
    storageBucket: 'horizen-gestion-de-personal.appspot.com', // Storage Bucket
    messagingSenderId: '1024913989583', // Project number
    appId: '1:1024913989583:android:04739339c6b4fb93277cba', // Mobile SDK App ID
    measurementId: '', // Optional, used for Google Analytics
    databaseURL: '', // Realtime Database URL
  },
};

const local = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'http://localhost:3000/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_wIRJcZTBA',
    APP_CLIENT_ID: '230gqjgkpn0au9f61opl5m4g0s',
    IDENTITY_POOL_ID: 'us-east-1:fb6a499e-b510-46bc-92de-c61beb3e5ea8',
  },
  firebase: common.firebase,
  google: {
    REACT_GOOGLE_MAP_KEY: 'AIzaSyD9yVyRO8NGUrDGEOOzIWEpVFfIZb2bdew',
  },
};

const dev = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://api.dev.horizen.bananascript.io/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_wIRJcZTBA',
    APP_CLIENT_ID: '230gqjgkpn0au9f61opl5m4g0s',
    IDENTITY_POOL_ID: 'us-east-1:fb6a499e-b510-46bc-92de-c61beb3e5ea8',
  },
  firebase: common.firebase,
  google: {
    REACT_GOOGLE_MAP_KEY: 'AIzaSyD9yVyRO8NGUrDGEOOzIWEpVFfIZb2bdew',
  },
};

const stage = {
  s3: {
    BUCKET: 'YOUR_DEV_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://api.horizen.bananascript.io/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_LHO3JgsZE',
    APP_CLIENT_ID: '6fa07kvtctfmd3ehtcmb57tdc4',
    IDENTITY_POOL_ID: 'YOUR_DEV_IDENTITY_POOL_ID',
  },
  firebase: common.firebase,
  google: {
    REACT_GOOGLE_MAP_KEY: 'AIzaSyD9yVyRO8NGUrDGEOOzIWEpVFfIZb2bdew',
  },
};

const prod = {
  s3: {
    BUCKET: 'YOUR_PROD_S3_UPLOADS_BUCKET_NAME',
  },
  api: {
    ENDPOINT: 'https://api.horizen.bananascript.io/',
    // ENDPOINT: 'http://localhost:3000/',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_JwQKxfcrd',
    APP_CLIENT_ID: 'p3nsr3udpu09oajqebdsmhtlu',
    IDENTITY_POOL_ID: 'us-east-1:bd5cd50b-516f-4d29-be66-3d76c6b48107',
  },
  firebase: common.firebase,
  google: {
    REACT_GOOGLE_MAP_KEY: 'AIzaSyD9yVyRO8NGUrDGEOOzIWEpVFfIZb2bdew',
  },
};

const config =
  process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : process.env.REACT_APP_STAGE === 'stage'
    ? stage
    : process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : local;

const sharedObj = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  CONTACT_EMAIL: 'contacto@casalimpia.com',
  ...config,
};
export default sharedObj;
