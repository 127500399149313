import { components } from 'react-select';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppOrgIcon } from '@components';

const StyledCircle = styled.div`
  width: 30px;
  height: 30px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: ${(props) => (props.color ? props.color : PALETTE_COLOR.INACTIVE_GREY_BLUE)};
  margin: 0;
  padding: 0;
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
`;

const OptionLabelWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const calcDot = (color) => {
  return <StyledCircle color={color} />;
};

export const calcOrgCircle = (props) => {
  return (
    <AppOrgIcon
      color={props.color}
      title={props.label}
      width={'30px'}
      height={'30px'}
      padding={'4px'}
      fontSize={'12px'}
    >
      {props.label.substring(0, 2).toUpperCase()}
    </AppOrgIcon>
  );
};

export const SingleOption = (props) => {
  return (
    <components.Option {...props}>
      <Row>
        <Col xs={1}>
          {!props?.data?.innerText ? (
            <StyledCircle color={props.data.color} />
          ) : (
            <AppOrgIcon
              color={props.data.color}
              title={props.label}
              width={'30px'}
              height={'30px'}
              padding={'4px'}
              fontSize={'12px'}
            >
              {props.label.substring(0, 2).toUpperCase()}
            </AppOrgIcon>
          )}
        </Col>
        <Col>
          <OptionLabelWrapper>{props.label}</OptionLabelWrapper>
        </Col>
      </Row>
    </components.Option>
  );
};
