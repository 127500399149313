import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Form, Col } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { calcAvatar } from 'utils/avatar';
import { formatEspecial } from '@utils/dateFormatHelper';
import moment from 'moment';
import { size } from 'lodash';

import { useTranslation } from 'react-i18next';

export const ViewDetailsActivity = ({ handleclose, setShowSucessModal, data }) => {
  const { t } = useTranslation();
  const addGroupSchema = Yup.object().shape({
    name: Yup.string()
      .max(20, `${t('JobScheduler.activities.validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
  });

  //To handle message
  /***
   * Calling to WS to add a group
   */
  let cont_employee = 0;
  let cont_jobtitle = 0;
  let employees = [];
  return (
    <Formik
      validationSchema={addGroupSchema}
      initialValues={{
        performer_id: data.perfomer ? data.perfomer : null,
        performer_name: data.perfomer?.name ? data.perfomer?.name : '',
        shift_users: data.shift_users,
        type: data.type_activity?.description ? data.type_activity?.description : '',
        type_code: data.type_activity?.value_definition,
        activity_shifts: data.activity_shifts,
        activity_date: data.activity_date,
        activity_time: data.activity_time,
        group_name: data.group?.name ? data.group?.name : '',
        activity_details: data.activity_details,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <div style={{ width: '780px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px', width: '780px' }}>
            <div style={{ width: '780px', display: 'flex' }}>
              <div style={{ borderRight: '0.2px solid #8080803d', width: '240px' }}>
                <Row className="mb-3">
                  <LabelForm tittle={t('form_labels.performer')}></LabelForm>
                </Row>
                <Row className="mb-3">
                  <div style={{ display: 'flex' }}>
                    {' '}
                    <div style={{ marginRight: '7px' }}>{calcAvatar(values.performer_id)}</div>{' '}
                    {values.performer_name}
                  </div>
                </Row>
                <Row className="mb-3">
                  <LabelForm tittle={t('form_labels.related_to')}></LabelForm>
                </Row>
                {values.shift_users.map((user, i) => {
                  if (!employees.includes(user.user_id.id)) {
                    employees.push(user.user_id.id);
                    return (
                      <Row className="mb-3">
                        <div style={{ display: 'flex' }}>
                          {' '}
                          <div style={{ marginRight: '7px' }}>{calcAvatar(user.user_id)}</div>{' '}
                          {user.user_id.name}
                        </div>
                      </Row>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
              <div style={{ padding: '0px 32px 17px 35px', width: '520px' }}>
                <Row className="mb-3">
                  <LabelForm tittle={t('form_labels.description')}></LabelForm>
                </Row>
                <Row className="mb-3">
                  <div>
                    <b> {values.performer_name}</b>{' '}
                    <b
                      style={{
                        color:
                          values.type_code === '010'
                            ? '#8989FF'
                            : values.type_code === '020'
                            ? '#FF5555'
                            : values.type_code === '040'
                            ? '#52CC34'
                            : values.type_code === '050'
                            ? '#52CC34'
                            : values.type_code === '030' && '#FFB800',
                      }}
                    >
                      {values.type.toLowerCase() === 'delete'
                        ? `${t(`JobScheduler.activities.types.${values.type.toLowerCase() + 'd'}`)}`
                        : values.type_code !== '050' 
                        ? `${t(`JobScheduler.activities.types.${values.type.toLowerCase() + 'ed'}`)}`
                        : `${t(`JobScheduler.activities.types.${values.type.toLowerCase().replace(/\s/g, '_')}`)}`}
                    </b>
                    {values.type_code !== '040' &&
                      values.shift_users.map((user, i) => {
                        return (
                          <>
                            {i === 0 ? (
                              user?.user_id?.name ? (
                                <>
                                  {t('JobScheduler.activities.user_shift_prefix')}
                                  <b>{user.user_id.name}</b>
                                  {t('JobScheduler.activities.user_shift_sufix')}
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    {values.activity_shifts.map((shift, i) => {
                      if (values.type_code === '040') {
                        return (
                          <>
                            {i === 0 ? (
                              <>
                                <b> {size(values.activity_shifts)}</b>
                                {` ${t('JobScheduler.activities.shifts_on')} `}
                                <b>
                                  {
                                    formatEspecial(
                                      moment(shift.shift_id.shift_date)
                                        .startOf('week')
                                        .format('YYYY-MM-DD'),
                                      'MMM D',
                                    ) +
                                    ' - ' +
                                    formatEspecial(
                                      moment(shift.shift_id.shift_date)
                                        .endOf('week')
                                        .format('YYYY-MM-DD'),
                                      'D',
                                    )
                                  }
                                </b>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {i === 0 ? (
                              <>
                                <b>
                                  {' '}
                                  {shift.shift_id?.jobs_titles
                                    ?.map((jobtitle) => jobtitle.jobtitle.description)
                                    .join(', ') + ' > '}{' '}
                                  {shift.shift_id.title && (
                                    <>"{shift.shift_id.title}"</>
                                  )}
                                </b>
                                {` ${t('JobScheduler.activities.on')} `}
                                <b>
                                  {formatEspecial(shift.shift_id.shift_date, 'ddd') +
                                    ' ' +
                                    shift.shift_id.shift_date.substring(8, 10) +
                                    '.' +
                                    formatEspecial(shift.shift_id.shift_date, 'MM.YYYY')}
                                </b>
                                {` ${t('JobScheduler.activities.from')} `}
                                <b>{`${moment.utc(shift.shift_id.start_time).format('h')}${
                                  moment.utc(shift.shift_id.start_time).format('a') === 'am'
                                    ? 'a'
                                    : 'p'
                                }`}</b>
                                {` ${t('JobScheduler.activities.to')} `}
                                <b>{`${moment.utc(shift.shift_id.end_time).format('h')}${
                                  moment.utc(shift.shift_id.end_time).format('a') === 'am'
                                    ? 'a'
                                    : 'p'
                                }`}</b>
                              </>
                            ) : (
                              <></>
                            )}
                            {shift.shift_id?.site?.name && (
                              <>
                                {` ${t('form_labels.site').toLowerCase()} `}<b>{shift.shift_id?.site?.name}</b>
                              </>
                            )}
                          </>
                        );
                      }
                    })}
                    {` ${t('JobScheduler.activities.in_the')} `}
                    <b>{values.group_name}</b>
                    {` ${t('JobScheduler.activities.group')} `}
                  </div>
                </Row>
                {values.type_code === '030' ? (
                  <>
                    <Row className="mb-3">
                      <LabelForm tittle={t('form_labels.changes')}></LabelForm>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={20}>
                        {values.activity_details.map((detail, i) => {
                          if (detail.change_after.trim() !== detail.change_before.trim()) {
                            if (detail.change_after !== '' && detail.change_before !== '') {
                              return (
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <div style={{ width: '130px', padding: '4px 8px' }}>
                                    {detail.field}
                                  </div>
                                  <div
                                    style={{
                                      width: 'max-content',
                                      padding: '4px 8px',
                                      background: '#E6FFEE',
                                      marginRight: '10px',
                                      display: 'flex',
                                    }}
                                  >
                                    {detail.field === 'Date'
                                      ? formatEspecial(detail.change_after, 'ddd') +
                                        ' ' +
                                        detail.change_after.substring(8, 10) +
                                        '.' +
                                        formatEspecial(detail.change_after, 'MM')
                                      : detail.change_after}
                                  </div>
                                  <div
                                    style={{
                                      width: 'inherit',
                                      padding: '4px 8px',
                                      background: '#EDF1F8',
                                    }}
                                  >
                                    <del>
                                      {' '}
                                      {detail.field === 'Date'
                                        ? formatEspecial(detail.change_before, 'ddd') +
                                          ' ' +
                                          detail.change_before.substring(8, 10) +
                                          '.' +
                                          formatEspecial(detail.change_before, 'MM')
                                        : detail.change_before}
                                    </del>
                                  </div>
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          } else {
                            return <></>;
                          }
                        })}
                        {values.activity_details.map((detail, i) => {
                          if (detail.field === 'Employee') {
                            cont_employee++;
                            return (
                              <>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <div style={{ width: '130px', padding: '4px 8px' }}>
                                    {cont_employee === 1 ? `${t(`form_labels.${detail.field.toLowerCase().replace(/\s/g, '_')}`)}` : ''}
                                  </div>
                                  {detail.change_after !== '' ? (
                                    <div
                                      style={{
                                        width: 'max-content',
                                        padding: '4px 8px',
                                        background: '#E6FFEE',
                                        marginRight: '10px',
                                        display: 'flex',
                                      }}
                                    >
                                      {detail.change_after}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {detail.change_before !== '' ? (
                                    <div
                                      style={{
                                        width: 'inherit',
                                        padding: '4px 8px',
                                        background: '#EDF1F8',
                                      }}
                                    >
                                      <del> {detail.change_before}</del>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                        {values.activity_details.map((detail, i) => {
                          cont_jobtitle++;
                          if (detail.field === 'Jobs Titles') {
                            return (
                              <>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <div style={{ width: '130px', padding: '4px 8px' }}>
                                    {cont_jobtitle === 1 ? `${t(`form_labels.${detail.field.toLowerCase().replace(/\s/g, '_')}`)}` : ''}
                                  </div>
                                  {detail.change_after !== '' ? (
                                    <div
                                      style={{
                                        width: 'max-content',
                                        padding: '4px 8px',
                                        background: '#E6FFEE',
                                        marginRight: '10px',
                                        display: 'flex',
                                      }}
                                    >
                                      {detail.change_after}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {detail.change_before !== '' ? (
                                    <div
                                      style={{
                                        width: 'inherit',
                                        padding: '4px 8px',
                                        background: '#EDF1F8',
                                      }}
                                    >
                                      <del> {detail.change_before}</del>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {values.type.toLowerCase() === 'delete' && (
                  <>
                    {' '}
                    <Row className="mb-3">
                      <LabelForm tittle={t('form_labels.reason')}></LabelForm>
                    </Row>
                    <Row className="mb-3">
                      <div>
                        <p>{t('form_labels.facility_cancel')} </p>
                      </div>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <AppButton
                    size="lg"
                    style={{
                      width: 'auto',
                      display: 'unset',
                      flexDirection: 'unset',
                      fontSize: '14px',
                    }}
                    bgcolor={PALETTE_COLOR.MAIN_BLUE}
                    bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                    color={PALETTE_COLOR.MAIN_WHITE}
                    bordercolor={PALETTE_COLOR.TOP_GRAY}
                    borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                    onClick={() => console.log('ok')}
                    title={t('buttons.btn_view_scheduler')}
                  >
                    {t('buttons.btn_view_scheduler')}
                  </AppButton>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
