import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { AppComponentLoading } from '@components';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { CondOperator } from '@nestjsx/crud-request';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { getEntityData } from '@services';
import { SHIFTS_WEEEK_TEMPLATES } from 'services/CONSTANTS';
import { InputForm } from '@components';
import ic_search from '@assets/icons/ic_search.svg';
import Inner from '@assets/icons/Inner.svg';
import WeekTemplateView from './WeekTemplateView';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const NoDataPanelText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const Icon = styled.img``;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 30px;
  height: 450px;
  overflow-y: scroll;
`;

//const Icon = styled.img``;

export const WeekTeemplatesLoader = (props) => {
  const { t } = useTranslation();
  const assignOrgEmpSchema = Yup.object().shape({
    organization: Yup.object().nullable().required(`${t('validation.organization_required')}`),
    permittedSites: Yup.array().min(1, `${t('validation.permitted_sites_required')}`),
  });
  const { type } = props;
  const { jobSchedulerGroup } = useSelector((state) => state.app);
  const [searchTerm, setSearchTerm] = useState('');
  const [templatesList, setTemplatesList] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [reloadData, setReloadData] = useState(true);

  useEffect(() => {
    setReloadData(true);
  }, [jobSchedulerGroup]);

  useEffect(() => {
    const getTemplateList = () => {
      let keys = ['name'];
      const fieldsFiltering = [];
      if (debouncedSearchTerm) {
        keys.forEach((key) => {
          const fieldSearch = {};
          fieldSearch[key] = { [CondOperator.CONTAINS]: debouncedSearchTerm };
          fieldsFiltering.push(fieldSearch);
        });
      }

      setLoadingTemplates(true);
      const qb = RequestQueryBuilder.create()
        .setJoin([{ field: 'shifts' }, { field: 'shifts.group' }])
        .search({
          $and: [
            {
              'shifts.group.id': {
                $eq: jobSchedulerGroup.id,
              },
            },
            {
              type: {
                $eq: type,
              },
            },
            { $or: fieldsFiltering },
          ],
        })
        .sortBy({ field: 'id', order: 'DESC' });
      getEntityData(SHIFTS_WEEEK_TEMPLATES, qb.query())
        .then((res) => {
          //console.log('🚀 ~ file: WeekTeemplatesLoader.js ~ line 137 ~ .then ~ res', res);
          setTemplatesList(res);
          setLoadingTemplates(false);
          setReloadData(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setReloadData(false);
          setLoadingTemplates(false);
        });
    };
    if (reloadData) getTemplateList();
  }, [debouncedSearchTerm, reloadData, jobSchedulerGroup, type]);

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
          setReloadData(true);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={assignOrgEmpSchema}
      initialValues={{
        organization: '',
        permittedSites: [],
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <div style={{ width: '400px' }}>
          <InputForm
            style={{ width: 'inherit' }}
            height={'40px'}
            placeholder={t('placeholder_search')}
            icon={ic_search}
            background={'#FFFFFF'}
            borderRadius={'0px'}
            boxShadow={'0px 1px 0px rgba(0, 0, 0, 0.08)'}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loadingTemplates ? (
            <div style={{ height: '250px' }}>
              {' '}
              <AppComponentLoading style={{ width: 'auto' }} />{' '}
            </div>
          ) : (
            <>
              {templatesList.length === 0 ? (
                <div style={{ padding: '30px', maxHeight: '400px' }}>
                  <Icon
                    src={Inner}
                    style={{ width: '47px', height: '47px', display: 'flex', margin: 'auto' }}
                  />
                  <NoDataPanelTitle style={{ marginTop: '20px' }}>
                    {t('JobScheduler.nothing_found')}
                  </NoDataPanelTitle>
                  <NoDataPanelText>
                    {t('JobScheduler.try_another')}
                  </NoDataPanelText>
                </div>
              ) : null}
              <TemplateWrapper>
                {templatesList.map((template, index) => (
                  <WeekTemplateView
                    key={index}
                    data={template}
                    reloadData={() => setReloadData(true)}
                  />
                ))}
              </TemplateWrapper>
            </>
          )}
        </div>
      )}
    </Formik>
  );
};
