import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppModalStatus,
  AppModal,
  AppButton,
  AppDropDownSelect,
  AppCheckBoxList,
} from '@components';
import { editEntityData } from '@services';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { ORGANIZATIONS_USERS_API } from 'services/CONSTANTS';
import { deleteEntityData } from '@services';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@redux/actions/appActions';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 0px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  justify-content: space-between;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleItalicLabel = styled.div`
  padding-top: 10px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Poppins';
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
  margin-bottom: 30px;
`;

const requestEditAssignOrganization = {
  user_id: {},
  organization_id: {},
  sites: [],
};

export const EditAssignOrganizationForm = ({
  handleclose,
  setShowSucessModal,
  data,
  org,
  setReload,
}) => {
  const { t } = useTranslation();
  const assignOrgEmpSchema = Yup.object().shape({
    organization: Yup.object().nullable().required(`${t('validation.organization_required')}`),
    permittedSites: Yup.array().min(1, `${t('validation.permitted_sites_required')}`),
  });
  const usersGroups = data?.user_groups.map((userGroup) => userGroup.group.id);
  const dispatch = useDispatch();

  //To handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  //Info to send to component which shows confirmation to delete
  const removeOrgModalContent = {
    buttonText: `${t('buttons.btn_remove')}`,
    actionIcon: ico_Archived,
    title: `${t('Users.employees.view.modals.remove_organization_title')}`,
    subtitle: `${t('Users.employees.view.modals.remove_organization_subtitle')}`,
    bgcolor: PALETTE_COLOR.ACCENT_RED,
    bordercolor: PALETTE_COLOR.ACCENT_RED,
    borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
  };

  /***
   * Shows remove modal
   */
  const handleRemoveModal = () => {
    setOpenRemoveModal((prev) => {
      return !prev;
    });
  };

  /***
   * Call api to remove org from employee
   */
  const removeOrg = () => {
    //Call api
    setLoading(true);
    deleteEntityData(`${ORGANIZATIONS_USERS_API}${org.id}`, {})
      .then((res) => {
        //Close modal
        handleclose();
        setLoading(false);
        setReload(true);
        //Show success message
        showMessage({
          show: true,
          type: 'success',
          title: `${t('success_messages.organization_removed')}`,
          message: '',
        });
      })
      .catch((err) => {
        setLoading(false);
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_organization_removing')}`,
          message: '',
        });
      });
  };

  /***
   * Calc sites using the users groups
   */
  const calcSites = (organizationSites) => {
    let sites = [];
    organizationSites &&
      organizationSites.forEach((element) => {
        if (element.groupsSite.filter((gs) => usersGroups.includes(gs.group.id)).length > 0) {
          sites.push(element);
        }
      });
    return sites;
  };

  /***
   * Call api to edit assign an organization to an employee
   */
  const editAssignOrganizationToEmployee = (values) => {
    //Hide message
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    showMessage((prev) => {
      return { ...prev, show: false };
    });
    //Fill the obj
    console.log('values: ', values);
    requestEditAssignOrganization.user_id = data;
    requestEditAssignOrganization.organization_id = values.organization.value;
    requestEditAssignOrganization.sites = values.permittedSites.map((site) => site.value);
    setLoading(true);
    //Call api
    editEntityData(`${ORGANIZATIONS_USERS_API}${org.id}`, requestEditAssignOrganization)
      .then((res) => {
        //Show success message
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.organization_assigned')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        showMessage({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_organization_assign')}`,
          message: '',
        });
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={assignOrgEmpSchema}
      onSubmit={editAssignOrganizationToEmployee}
      initialValues={{
        organization: org.organization_id
          ? { label: org.organization_id.name, value: org.organization_id }
          : null,
        permittedSites: org.organization_user_sites
          ? org.organization_user_sites.map((orgUser) => {
              return {
                label: orgUser.site.name,
                subtitle: orgUser.site.address,
                id: orgUser.site.id,
                value: orgUser.site.id,
              };
            })
          : null,
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <div style={{ width: '590px', maxHeight: values.organization ? '550px' : 'auto', overflow: 'hidden' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '30px 30px' }}>
            <Row className="mb-3">
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.organization')}></LabelForm>
              </Col>
              <Col xs={8}>
                <AppDropDownSelect
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height="8rem"
                  isSearchable={true}
                  name="organization"
                  placeholder={t('required')}
                  onChange={(selected) => {
                    values.permittedSites = [];
                    setFieldValue('organization', selected);
                  }}
                  invalid={touched.organization && errors.organization}
                  defaultValue={values.organization}
                  isDisabled={true}
                />
                {touched.organization && errors.organization && (
                  <ErrorText className="px-2 mb-0">{errors.organization}</ErrorText>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <LabelForm tittle={t('form_labels.permitted_sites')}></LabelForm>
              </Col>
              <Col xs={8} style={{ overflowY: 'auto',  maxHeight: values.organization ? '370px' : 'auto', paddingBottom: '10px' }}>
                {values.organization ? (
                  <AppCheckBoxList
                    options={calcSites(values?.organization?.value.organizations_sites).map(
                      (option) => ({
                        label: option.name,
                        subtitle: option.address,
                        id: option.id,
                        value: option.id,
                      }),
                    )}
                    name="permittedSites"
                    onClick={(selectedValues) => {
                      console.log('selectedValues: ', selectedValues);
                      setFieldValue('permittedSites', selectedValues);
                    }}
                    invalid={touched.permittedSites && errors.permittedSites}
                    defaultValue={values.permittedSites.map((option) => ({
                      label: option.name,
                      subtitle: option.address,
                      id: option.id,
                      value: option.id,
                    }))}
                  />
                ) : (
                  <StyleItalicLabel>
                    {t('select_organization')}
                  </StyleItalicLabel>
                )}
                {touched.permittedSites && errors.permittedSites && (
                  <ErrorText className="px-2 mb-0">{errors.permittedSites}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    width: 'auto',
                    marginRight: '20px',
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_update')}
                >
                  {t('buttons.btn_update')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={loading}
                  style={{ width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              <AppButton
                size="lg"
                // className="ml-auto p-2"
                style={{ width: 'auto' }}
                loading={loading}
                bgcolor={PALETTE_COLOR.MAIN_WHITE}
                bghovercolor={PALETTE_COLOR.ACCENT_RED}
                color={PALETTE_COLOR.ACCENT_RED}
                bordercolor={PALETTE_COLOR.TOP_GRAY}
                borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                onClick={handleRemoveModal}
                title={t('buttons.btn_remove')}
              >
                {t('buttons.btn_remove')}
              </AppButton>
            </StyleButtons>

            {/* Remove modal */}
            <AppModal
              open={openRemoveModal}
              handleclose={handleRemoveModal}
              title={''}
              boxshadow={'none'}
              inside={1}
            >
              <AppModalStatus
                actionIcon={removeOrgModalContent.actionIcon}
                title={removeOrgModalContent.title}
                subtitle={removeOrgModalContent.subtitle}
                bgcolor={removeOrgModalContent.bgcolor}
                bordercolor={removeOrgModalContent.bordercolor}
                borderhovercolor={removeOrgModalContent.borderhovercolor}
                bghovercolor={removeOrgModalContent.bghovercolor}
                boxShadow={removeOrgModalContent.boxShadow}
                buttonText={removeOrgModalContent.buttonText}
                subject={''}
                isLoading={loading}
                buttonAction={() => removeOrg()}
              />
            </AppModal>
          </Form>
          {showMessage.show && (
            <CustomizedSnackbar
              show={showMessage.show}
              type={showMessage.type}
              title={showMessage.title}
              message={showMessage.message}
            />
          )}
        </div>
      )}
    </Formik>
  );
};
