import React, { useEffect, useState } from 'react';
import {
  AppTable,
  AppButton,
  AppOrgIcon,
  AppModal,
  CustomizedSnackbar,
  AppDarkTooltip,
} from '@components';
import { SITES_API, ORGANIZATIONS_API, GROUPS_API } from '@services/CONSTANTS';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { getEntityData } from 'services';
import styled from 'styled-components';
import ic_eye from '@assets/icons/ic_eye.svg';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Blocked.svg';
import Archived from '@assets/icons/Archived.svg';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { FilterSiteManagers } from './FilterSiteManagers';
import { CondOperator } from '@nestjsx/crud-request';
import { AddSite } from '@pages/Organizations/AddSite';
import { VIEW_SITE } from '@navigation/CONSTANTS';

import { useTranslation } from 'react-i18next';

const Edit = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledFilledTag = styled.div`
  width: auto;
  margin: 3px;
  padding: 5px 10px;
  background: ${(props) => props.color || PALETTE_COLOR.MAIN_GRAY};
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

export const SitesList = () => {
  const { t } = useTranslation();
  const { userPermissions, valuesDefinitions } = useSelector((state) => state.app);
  let history = useHistory();
  //const [openForm, setOpenForm] = React.useState(false);
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  //Store custom filters
  const [filters, setFilters] = React.useState(null);
  const [openSite, setOpenSite] = React.useState(false);
  const [canCreateAUser, setCanCreateAUser] = React.useState(false);
  //Know if reload table
  const [loadParent, setLoadParent] = React.useState(true);

  //To sort
  function compare(a, b) {
    if (a.value_definition > b.value_definition) return 1;
    if (a.value_definition < b.value_definition) return -1;
  }

  //Joins
  const joins = React.useMemo(() => {
   /* return [
      { field: 'organizations_users_sites' },
      { field: 'organizations_users_sites.organization_user' },
      { field: 'organizations_users_sites.organization_user.user_id' },
      { field: 'organizations_users_sites.organization_user.user_id.role' },
    ];*/
    return [];
  }, []);

  /***
   *
   */
  let goTo = (route) => {
    history.push(route);
  };

  useEffect(() => {
    setStatuses(
      valuesDefinitions
        ? valuesDefinitions
            .filter((v) => v.definitionType.definition_type === 'SITE_STATUS')
            .sort(compare)
        : [],
    );

    //Sort
    //setStatuses(statuses.sort(compare));
  }, [valuesDefinitions]);

  /***
   * Get groups to filter
   */
  useEffect(() => {
    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroups(userPermissions?.systemUserGroups);
        } else {
          setGroups([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroups(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions]);

  /***
   * Get organizations to filter
   */
  useEffect(() => {
    const getOrganizations = () => {
      let qb;
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name'])
          .setJoin([
            { field: 'organizations_sites' },
            { field: 'organizations_sites.groupsSite' },
            { field: 'organizations_sites.groupsSite.group' },
          ])
          .setFilter({
            field: 'organizationsGroupsSites.id',
            operator: CondOperator.IN,
            value:
              userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0
                ? userPermissions?.systemUserGroups?.map((g) => g.id)
                : -1,
          });
      } else {
        qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
      }
      //Call API
      getEntityData(ORGANIZATIONS_API, qb.query())
        .then((res) => {
          setOrganizationsList(res);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
    };
    getOrganizations();
  }, [userPermissions]);

  /***
   * Only get sites which belong to user groups
   */
  useEffect(() => {
    if (userPermissions && userPermissions?.type) {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setFilters([
            {
              field: 'groupsSite.group.id',
              operator: CondOperator.IN,
              value: userPermissions?.systemUserGroups?.map((g) => g.id),
            },
          ]);
        } else {
          //System user without groups
          setFilters({
            field: 'groupsSite.group.id',
            operator: CondOperator.IN,
            value: -1,
          });
        }
      } else {
        setFilters([]);
      }
    } else {
      setFilters(null);
    }
  }, [userPermissions]);

  /***
   *
   */
  useEffect(() => {
    if (userPermissions) {
      if (userPermissions?.type === 'system') {
        setCanCreateAUser(userPermissions?.systemUserPermission);
      } else {
        setCanCreateAUser(true);
      }
    }
  }, [userPermissions]);

  const handleCreateSiteModal = () => {
    setOpenSite(!openSite);
  };

  const columns = [
    {
      dataField: 'name',
      text: `${t('table_columns.site')}`,
      disableColumnToggle: true,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledFilledTag color={PALETTE_COLOR.MAIN_GRAY}>{row.name}</StyledFilledTag>
          </div>
        );
      },
    },
    {
      dataField: 'organization_id.name',
      text: `${t('table_columns.organization')}`,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.organization_id.name}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              <AppOrgIcon
                color={row.organization_id.color}
                style={{ width: 30, height: 30, fontSize: 12, padding: 5, display: 'inline-flex' }}
              >
                {row.name.substring(0, 2).toUpperCase()}
              </AppOrgIcon>
              <span style={{ marginLeft: 10 }}>{row.organization_id.name}</span>
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
      advanceFilter: organizationsList && [
        ...organizationsList.map((s) => ({ label: s.name, value: s.name })),
      ],
    },
   /* {
      dataField: 'siteManager',
      text: `${t('table_columns.site_manager')}`,
      search: false,
      headerStyle: { width: '16%' },
      style: { height: '54px' },
      exportFormatter: (row) => {
        let siteManagers = [];
        siteManagers = row?.organizations_users_sites?.filter(
          (e) => e.organization_user.user_id.role?.value_definition === '020',
        );
        if (siteManagers.length === 0) return `${t('not_assigned')}`;
        return siteManagers.map((user) => user.organization_user.user_id.name).join(',');
      },
      formatter: (cellContent, row) => {
        return <FilterSiteManagers site={row} />;
      },
    },*/
    {
      dataField: 'address',
      text: `${t('table_columns.address')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { height: '54px', overflow: 'hidden' },
      formatter: (cellContent, row) => {
        return <StyledLabel>{row.address}</StyledLabel>;
      },
    },
    {
      dataField: 'groupsSite.group.name',
      text: `${t('table_columns.groups')}`,
      search: true,
      exportFormatter: (row) => {
        const groupsSites = row.groupsSite.filter((v) => v.created_at !== 'null');
        return groupsSites.map((group) => group.group.name).join(',');
      },
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (size(row.groupsSite) > 0) {
          const groupsSites = row.groupsSite.filter((v) => v.created_at !== 'null');
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {groupsSites.map((org, index) => (
                <StyledFilledTag color={PALETTE_COLOR.MAIN_GRAY}>{org.group.name}</StyledFilledTag>
              ))}
            </div>
          );
        }
      },
      advanceFilter: groups && [...groups.map((s) => ({ label: s.name, value: s.name }))],
    },
    {
      dataField: 'status.description',
      text: `${t('table_columns.status')}`,
      sort: false,
      headerStyle: { width: '11%' },
      search: true,
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        if (row.status?.value_definition === '010') {
          return (
            <StyledLabel>
              <Status src={status_active} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        } else if (row.status?.value_definition === '020') {
          return (
            <StyledLabel>
              <Status src={status_blocked} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        } else {
          return (
            <StyledLabel>
              <Status src={Archived} alt="logo"></Status>
              <StyledIconLabel>
                {t(`Organization.status.${row.status.description}`)}
              </StyledIconLabel>
            </StyledLabel>
          );
        }
      },
      advanceFilter: statuses && [
        ...statuses.map((s) => ({ 
          label: `${t(`Organization.status.${s.description}`)}`, 
          value: s.description
        })),
      ],
    },
    /*  {
      dataField: 'updated_at',
      text: `${t('table_columns.last_modified')}`,
      sort: false,
      search: false,
      headerStyle: { width: '12%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            {row.updated_at !== null ? `${formatFullDate(row.updated_at)}` : ''}
          </StyledLabel>
        );
      },
    },*/
    {
      dataField: 'edit',
      text: `${t('table_columns.view')}`,
      isDummyField: true,
      headerStyle: { width: '5%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <StyledLabel>
            <Edit src={ic_eye} alt="logo"></Edit>
          </StyledLabel>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          goTo(VIEW_SITE.replace(':siteId', `${row.id}?b=true`));
        },
      },
    },
  ];

  return (
    <div>
      {filters !== null && (
        <AppTable
          endpointPath={SITES_API}
          joins={joins}
          filters={filters}
          columns={columns}
          csvName={t('csv_names.export_sites_management_csv_name')}
          reload={loadParent}
          withAdvanceFilter={true}
          withColumnToggler={true}
          withExport={true}
          trailingBtn={
            canCreateAUser && (
              <AppButton
                tooltiptitle={t('tooltips.add_site')}
                bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                icon={ic_add_circle}
                onClick={() => handleCreateSiteModal()}
              >
                {t('buttons.btn_add')}
              </AppButton>
            )
          }
        />
      )}
      {/* Add site */}
      <AppModal open={openSite} handleclose={handleCreateSiteModal} title={`${t('Organization.site.modal_add.title')}`} inside={1}>
        <AddSite
          handleclose={handleCreateSiteModal}
          setShowSucessModal={setShowSucessModal}
          data={null}
          setLoadParent={setLoadParent}
        />
      </AppModal>
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </div>
  );
};
