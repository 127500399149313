import React, { useState, useEffect } from 'react';
import { AppButton, AppModal, AppDropDownSelect } from '@components';
import decline from '@assets/icons/ico_decline.svg';
import styled from 'styled-components';
import { Form, Col, Row } from 'react-bootstrap';
import { object } from 'yup';
import * as yup from 'yup';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { Formik } from 'formik';
import moment from 'moment';
import { SHIFTS_API } from '@services/CONSTANTS';
import { saveEntityData } from '@services';
import { showMessageAction, setReloadSchedulerAction } from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

const ModalContentWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  background-color: ${(props) => (props.bg ? props.bg : `${PALETTE_COLOR.MAIN_WHITE}`)};
  padding: 5px 30px 34px 30px;
`;

const StyleSpaceIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const InputIcon = styled.img``;

const ModalText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
`;

const ModalSubtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
`;

const DivBlueBox = styled.div`
  background: #e7f5ff;
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: 10px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK} p {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-size: 32px;
  }
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

const StyleSpaceButtons = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const UnpublishWeek = ({ currentdata, currentWeek, handleUnpublishWeekModal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [unclaimedList, setUnclaimedList] = useState([]);
  const [claimedunclaimedList, setClaimedUnclaimedList] = useState([]);
  const dispatch = useDispatch();
  const items = [
    {
      label: `${t('JobScheduler.options.unpublish_all')}`,
      value: '1',
    },
    {
      label: `${t('JobScheduler.options.unpublish_unclaimed')}`,
      value: '2',
    },
  ];
  const [unpublishOption, setUnpublishedOption] = useState(items[0]);

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  useEffect(() => {
    /***
     * Get published and unclaimed shifts
     */
    async function getUnclaimedShifts() {
      setUnclaimedList(
        currentdata.length > 0 &&
          currentdata.filter(
            (shift) =>
              shift.status?.value_definition === '010' &&
              (shift.user_shifts
                ? shift.user_shifts.filter((us) => us.status?.value_definition === '040').length ===
                  0
                : true) &&
              moment(shift.start_time).diff(moment.utc(), 'hours') >= 2,
          ),
      );
      // console.log('🍓 getUnclaimedShifts ', unclaimedList);
    }

    async function getClaimedUnclaimedShifts() {
      setClaimedUnclaimedList(
        currentdata.length > 0 &&
          currentdata.filter(
            (shift) =>
              shift.status.value_definition === '010' &&
              moment(shift.start_time).diff(moment.utc(), 'hours') >= 2,
          ),
      );
      //console.log('🍓 getClaimedUnclaimedShifts ', claimedunclaimedList);
    }

    switch (unpublishOption?.value) {
      case '1':
        getUnclaimedShifts();
        break;
      case '2':
        getClaimedUnclaimedShifts();
        break;
      default:
        getUnclaimedShifts();
        break;
    }
  }, [currentdata, unpublishOption]);

  const unpublishWeekAction = (values) => {
    let request = {};
    request.startAt = moment
      .utc(currentWeek.start)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .format();
    request.endAt = moment
      .utc(currentWeek.end)
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .format();

    let shiftsList = [];
    switch (values.unpublishOption.value) {
      case '1':
        shiftsList = unclaimedList;
        break;
      case '2':
        shiftsList = claimedunclaimedList;
        break;
      default:
        shiftsList = [];
        break;
    }
    request.unpublishOption = values.unpublishOption.value;
    request.shifts = shiftsList;

    if (shiftsList.length > 0) {
      setLoading(true);
      saveEntityData(`${SHIFTS_API}schedule/unpublish/week`, { ...request })
        .then((res) => {
          showMessage({
            show: true,
            type: 'success',
            title: `${t('success_messages.shifts_unpublished')}`,
            message: '',
          });

          reloadSchedulerCalendar(true);
          setLoading(false);
          handleUnpublishWeekModal();
        })
        .catch((err) => {
          console.log('unpublishWeekAction error', err);
          let mess = '';
          switch (err.response?.data?.statusCode) {
            case 403:
              mess = `${t('error_messages.error_shifts_permissions')}`;
              break;
            default:
              mess = `${t('error_messages.error_shifts_unpublishing')}`;
              break;
          }

          showMessage({
            show: true,
            type: 'error',
            title: mess,
            message: '',
          });

          handleUnpublishWeekModal();
          setLoading(false);
        });
    }
  };

  return (
    <AppModal open={true} handleclose={handleUnpublishWeekModal} inside={1} width={600}>
      <ModalContentWrapper width="600px">
        <StyleSpaceIcon style={{ marginTop: 30, marginBottom: 24 }}>
          <InputIcon src={decline} />
        </StyleSpaceIcon>
        <ModalText>{t('JobScheduler.modals.unpublish_week_title')}</ModalText>
        <ModalSubtitle style={{ marginBottom: 16 }}>
          {t('JobScheduler.modals.unpublish_week_subtitle')}
        </ModalSubtitle>
        <Formik
          validationSchema={object().shape({
            unpublishOption: yup.object().nullable().required(`${t('validation.unpublish_required')}`),
          })}
          initialValues={{
            unpublishOption: items[0],
          }}
          onSubmit={unpublishWeekAction}
        >
          {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <div>
              <Form onSubmit={handleSubmit} noValidate className="baseForm">
                <Row>
                  <Col xs={12}>
                    <AppDropDownSelect
                      options={items.map((option) => ({
                        label: option.label,
                        value: option.value,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      setLeftPadding
                      height="200px"
                      name="unpublishOption"
                      placeholder={t('required')}
                      value={values.unpublishOption}
                      onChange={(selected) => {
                        setFieldValue('unpublishOption', selected);
                        setUnpublishedOption(selected);
                      }}
                      invalid={touched.unpublishOption && errors.unpublishOption}
                      defaultValue={values.unpublishOption}
                    />
                    {touched.unpublishOption && errors.unpublishOption && (
                      <ErrorText className="px-2 mb-0">{errors.unpublishOption}</ErrorText>
                    )}
                  </Col>
                  <Col xs={12}>
                    <DivBlueBox>
                      <p style={{ fontSize: 13, fontWeight: 500 }} class="m-0">
                        {`${moment(currentWeek.start).format('MMM DD')} 
                            - 
                            ${moment(currentWeek.end).format('ll')}`}
                      </p>
                      <p style={{ fontSize: 13, fontWeight: 300 }} class="mb-1">
                        {t('JobScheduler.modals.subtitle_unpublish_info')}
                      </p>
                      <span>
                        {unpublishOption?.value === '1'
                          ? unclaimedList.length
                            ? unclaimedList.length
                            : 0
                          : claimedunclaimedList.length
                          ? claimedunclaimedList.length
                          : 0}
                      </span>
                    </DivBlueBox>
                  </Col>
                </Row>
                <StyleSpaceButtons>
                  <AppButton
                    style={{
                      width: 'auto',
                      height: 64,
                      boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
                    }}
                    type="submit"
                    loading={loading}
                    bghovercolor={PALETTE_COLOR.ACCENT_ORANGE}
                    bgcolor={PALETTE_COLOR.ACCENT_ORANGE}
                    bordercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                    borderhovercolor={PALETTE_COLOR.ACCENT_ORANGE_LIGHT}
                  >
                    {t('buttons.btn_unpublish')}
                  </AppButton>
                </StyleSpaceButtons>
              </Form>
            </div>
          )}
        </Formik>
      </ModalContentWrapper>
    </AppModal>
  );
};
export default UnpublishWeek;
