import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { editEntityData, getEntityData, saveEntityData } from '@services';
import { ORGANIZATIONS_API, USERS_API, ORGANIZATIONS_USERS_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { ArchiveOrganization } from './ArchiveOrganization';
import { AppModal } from '@components/AppModal';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import {
  AppDropDownSelect,
  InputForm,
  AppButton,
  IconColourPicker,
  AppAsyncLocationDropdown,
  AppDropDownMultipleSelect,
  CustomizedSnackbar,
} from '@components';
import { useSelector } from 'react-redux';
import { CustomOption } from 'utils/avatar';

import { useTranslation } from 'react-i18next';

const StyleSpaceButtonsRigth = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyleButtons = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 0.2px solid #8080803d;
  display: flex;
  width: inherit;
`;

const StyleCheckBox = styled.div`
  display: flex;
  padding-left: 5%;
`;

const StyleCheckLabel = styled(Form.Check.Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  margin-left: 10px;
  font-weight: 300;
  color: ${PALETTE_COLOR.INACTIVE_GRAY};
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const EditOrganizations = ({
  handleclose,
  setShowSucessModal,
  data,
  setShowArchivedModal,
  editAction,
}) => {
  const { t } = useTranslation();
  const representative_phone = data.phone;
  const representative_email = data.email;
  const {
    id,
    name,
    phone,
    address,
    email,
    color,
    work_week_start_id,
    work_week_end_id,
    invoicing_period_id,
  } = data;
  const [representatives, setRepresentatives] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [loadingUsers, setLoadingUsers] = useState(false);
  //Get values definitions - Week days and invoicing period
  const { valuesDefinitions } = useSelector((state) => state.app);

  //Avoid white page
  const weekDays = React.useMemo(() => {
    let result = [];
    if (valuesDefinitions) {
      result = valuesDefinitions.filter((v) => v.definitionType.definition_type === 'WEEK_DAYS');
      //Sort
      result = result.sort(compare);
    }
    return result;
  }, [valuesDefinitions]);

  //Avoid white page
  const invoicingPeriod = React.useMemo(() => {
    let result = [];
    if (valuesDefinitions) {
      result = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'INVOICING_PERIOD',
      );
      //Sort
      result = result.sort(compare);
    }
    return result;
  }, [valuesDefinitions]);

  //To sort
  function compare(a, b) {
    if (a.value_definition > b.value_definition) return 1;
    if (a.value_definition < b.value_definition) return -1;
  }

  useEffect(() => {
    const loadOrgUsersList = () => {
      setLoadingUsers(true);
      const qb = RequestQueryBuilder.create()
        .sortBy({ field: 'name', order: 'ASC' })
        .setFilter({
          field: 'type.description',
          operator: CondOperator.EQUALS,
          value: 'Organizations',
        })
        .setFilter({
          field: 'role.description',
          operator: CondOperator.EQUALS,
          value: 'Organization Representative',
        });
      getEntityData(USERS_API, qb.query())
        .then((res) => {
          console.log('AppOrganizations > getEntityData > res=', res);
          setOrganizationsUsers(res);
          setLoadingUsers(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingUsers(false);
        });
      return () => {
        console.log('axios cleanup.');
      };
    };

    const getOrgRepresentatives = () => {
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'user_id.role' })
        .setFilter({
          field: 'organization_id.id',
          operator: CondOperator.EQUALS,
          value: id,
        })
        .setFilter({
          field: 'user_id.role.value_definition',
          operator: CondOperator.EQUALS,
          value: '010',
        });

      getEntityData(ORGANIZATIONS_USERS_API, qb.query())
        .then((res) => {
          console.log('🍋  Getting org users=', res);
          setRepresentatives(res);
        })
        .catch((err) => {
          console.log('GetEmployeesBySiteJobTitle err=', err);
        });
    };

    loadOrgUsersList();
    getOrgRepresentatives();
  }, [id]);

  const [openArchive, setOpenArchive] = useState(false);
  const [organizationsAdminUsers, setOrganizationsUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClickOpenArchiveModal = () => {
    setOpenArchive(true);
  };

  const handleCloseOpenArchiveModal = () => {
    setOpenArchive(false);
  };

  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  let requestEditOrganization = {
    id: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    color: '',
    use_data: '',
    representatives: [],
    representative_phone: '',
    representative_email: '',
    work_week_start_id: '',
    work_week_end_id: '',
    invoicing_period_id: '',
  };

  const editOrganization = (values) => {
    setLoading(true);
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });

    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });

    //Get type of action: Edit/restore
    const { action } = values;
    if (action) {
      const status = valuesDefinitions.filter(
        (v) =>
          v.definitionType.definition_type === 'ORGANIZATION_STATUS' &&
          v.value_definition === action,
      )[0];
      requestEditOrganization.status_id = status;
    }

    const filteresRep = values.representativesDropdown.map((item) => item.value);
    let nameWithoutSpaces = values.name.replace(/ +(?= )/g, '');
    nameWithoutSpaces = nameWithoutSpaces.trim();

    values.name = nameWithoutSpaces;
    requestEditOrganization.name = values.name;
    requestEditOrganization.id = id;
    requestEditOrganization.phone = values.phone;
    requestEditOrganization.email = values.email;
    requestEditOrganization.color = values.color;
    requestEditOrganization.representatives = filteresRep;
    requestEditOrganization.address = values.address.value;

    //Add new fields
    requestEditOrganization.work_week_start_id = values.work_week_start_id.value;
    requestEditOrganization.work_week_end_id = values.work_week_end_id.value;
    requestEditOrganization.invoicing_period_id = values.invoicing_period_id.value;

    editEntityData(`${ORGANIZATIONS_API}${id}`, requestEditOrganization)
      .then((res) => {
        if (work_week_start_id && work_week_end_id && invoicing_period_id) {
          setShowSucessModal({
            show: true,
            type: 'success',
            title:
              editAction === 'restore'
                ? `${t('success_messages.organization_restored')}`
                : `${t('success_messages.organization_edited')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'success',
            title:
              editAction === 'restore' ? `${t('success_messages.organization_restored')}` : `${t('success_messages.work_week_added')}`,
            message: '',
          });
        }
        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.organization_exists')}`,
            message: '',
          });
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title:
              editAction === 'restore'
                ? 'Error restoring organization'
                : `${t('error_messages.error_organization_editing')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          name: yup.string().required(`${t('validation.name_required')}`),
          address: yup.object().nullable().required(`${t('validation.address_required')}`),
          email: yup
            .string()
            .email(`${t('validation.email_invalid')}`)
            .required(`${t('validation.email_required')}`),
          phone: yup
            .string()

            .required(`${t('validation.phone_required')}`),
          representatives: yup.array().nullable(),
          color: yup.string().required(`${t('validation.color_required')}`),
          work_week_start_id: yup.object().nullable().required(`${t('validation.start_day_required')}`),
          work_week_end_id: yup.object().nullable().required(`${t('validation.end_day_required')}`),
          invoicing_period_id: yup.object().nullable().required(`${t('validation.invoicing_period_required')}`),
        })}
        onSubmit={editOrganization}
        initialValues={{
          name: name,
          phone: phone,
          email: email,
          address: { label: address, value: address },
          color: color,
          representative_email: representative_email,
          representative_phone: representative_phone,
          representativesDropdown: representatives
            ? representatives?.map((reg) => ({ label: reg.user_id.name, value: reg.user_id }))
            : [],
          work_week_start_id: work_week_start_id
            ? { label: work_week_start_id?.description, value: work_week_start_id }
            : '',
          work_week_end_id: work_week_start_id
            ? { label: work_week_end_id?.description, value: work_week_end_id }
            : '',
          invoicing_period_id: invoicing_period_id
            ? { label: invoicing_period_id?.description, value: invoicing_period_id }
            : '',
          action: '',
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
          <div style={{ width: '600px', fontSize: 14 }}>
            <Form
              onSubmit={handleSubmit}
              noValidate
              className="baseForm"
              style={{ padding: '23px 23px 0px 23px' }}
            >
              <div>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.name')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('required')}
                      name="name"
                      value={values.name}
                      invalid={touched.name && errors.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.color')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <IconColourPicker
                      id="color"
                      onClick={(selectColour) => {
                        if (selectColour !== '') {
                          setFieldValue('color', selectColour);
                        }
                      }}
                      defaultValue={values.color}
                    />
                    {touched.color && errors.color && (
                      <ErrorText className="px-2 mb-0">{errors.color}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.address')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppAsyncLocationDropdown
                      loadOptions={(values) => loadLocationOptions(values)}
                      //onInputChange={(value) => setQuery(value)}
                      setLeftPadding
                      height="200px"
                      placeholder={t('required')}
                      isClearable={true}
                      name="address"
                      defaultValue={values.address}
                      onChange={(selected) => {
                        setFieldValue('address', selected?.value);
                      }}
                    />
                    {touched.address && errors.address && (
                      <ErrorText className="px-2 mb-0">{errors.address}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.representatives')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <AppDropDownMultipleSelect
                      options={organizationsAdminUsers.map((option) => ({
                        label: option.name,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      name="representativesDropdown"
                      customOption={CustomOption}
                      placeholder={t('optional')}
                      isLoading={loadingUsers}
                      value={values.representativesDropdown}
                      icon={null}
                      height={230}
                      onChange={(selected) => {
                        setFieldValue('representativesDropdown', selected);
                      }}
                      invalid={touched.representativesDropdown && errors.representativesDropdown}
                      defaultValue={[]}
                    />
                    {touched.representativesDropdown && errors.representativesDropdown && (
                      <ErrorText className="px-2 mb-0">{errors.representativesDropdown}</ErrorText>
                    )}
                    <StyleCheckBox className="ml-2">
                      <Form.Check
                        className="pt-2"
                        type="checkbox"
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                        }}
                      >
                        <Form.Check.Input
                          type="checkbox"
                          style={{ width: 22, height: 22, marginTop: 0, borderRadius: 8 }}
                          onChange={(e) => {
                            handleChange(e);
                            console.log('check value ', e.target.checked);
                            if (e.target.checked) {
                              if (
                                values.representativesDropdown &&
                                values.representativesDropdown.length > 0
                              ) {
                                console.log('Setting values');
                                setFieldValue(
                                  `email`,
                                  values.representativesDropdown[0].value.email,
                                );
                                setFieldValue(
                                  `phone`,
                                  values.representativesDropdown[0].value.phone,
                                );
                              }
                            } else {
                              setFieldValue(`email`, '');
                              setFieldValue(`phone`, '');
                            }
                          }}
                        />
                        <StyleCheckLabel style={{ fontSize: '14px' }}>
                          {t('Organization.use_first_representative')}
                        </StyleCheckLabel>
                      </Form.Check>
                    </StyleCheckBox>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.phone')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      placeholder={t('required')}
                      name="phone"
                      ctype={'phone'}
                      inputProps={{
                        name: 'phone',
                      }}
                      country={'us'}
                      value={values.phone}
                      invalid={touched.phone && errors.phone}
                      onChange={(value, data, event, formattedValue) =>
                        setFieldValue('phone', formattedValue)
                      }
                      maxLength="10"
                    />
                    {touched.phone && errors.phone && (
                      <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.email')}></LabelForm>
                  </Col>
                  <Col xs={9}>
                    <InputForm
                      className="mb-0"
                      height={'40px'}
                      inpfontsize={'14px'}
                      placeholder={t('required')}
                      name="email"
                      value={values.email}
                      invalid={touched.email && errors.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.work_week')}></LabelForm>
                  </Col>
                  <Col xs={4}>
                    <AppDropDownSelect
                      options={weekDays.map((option) => ({
                        label: `${t(`week_days.${option.description.replace(/\s/g, '_')}`)}`,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      height="8rem"
                      isSearchable={false}
                      name="work_week_start_id"
                      value={values.work_week_start_id && {
                        label: `${t(`week_days.${values.work_week_start_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.work_week_start_id.value,
                      }}
                      placeholder={t('placeholder_starts')}
                      isLoading={false}
                      onChange={(selected) => {
                        setFieldValue('work_week_start_id', selected);
                      }}
                      invalid={touched.work_week_start_id && errors.work_week_start_id}
                      defaultValue={values.work_week_start_id && {
                        label: `${t(`week_days.${values.work_week_start_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.work_week_start_id.value,
                      }}
                    />
                    {touched.work_week_start_id && errors.work_week_start_id && (
                      <ErrorText className="px-2 mb-0">{errors.work_week_start_id}</ErrorText>
                    )}
                  </Col>
                  <Col xs={1} style={{ textAlign: 'center' }}>
                    <LabelForm tittle="-"></LabelForm>
                  </Col>
                  <Col xs={4}>
                    <AppDropDownSelect
                      options={weekDays.map((option) => ({
                        label: `${t(`week_days.${option.description.replace(/\s/g, '_')}`)}`,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      height="8rem"
                      isSearchable={false}
                      name="work_week_end_id"
                      value={values.work_week_end_id && {
                        label: `${t(`week_days.${values.work_week_end_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.work_week_end_id.value,
                      }}
                      placeholder={t('placeholder_ends')}
                      isLoading={false}
                      onChange={(selected) => {
                        setFieldValue('work_week_end_id', selected);
                      }}
                      invalid={touched.work_week_end_id && errors.work_week_end_id}
                      defaultValue={values.work_week_end_id && {
                        label: `${t(`week_days.${values.work_week_end_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.work_week_end_id.value,
                      }}
                    />
                    {touched.work_week_end_id && errors.work_week_end_id && (
                      <ErrorText className="px-2 mb-0">{errors.work_week_end_id}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xs={3}>
                    <LabelForm tittle={t('form_labels.invoicing_period')}></LabelForm>
                  </Col>
                  <Col xs={5}>
                    <AppDropDownSelect
                      options={invoicingPeriod.map((option) => ({
                        label: `${t(`invoicing_periods.${option.description.replace(/\s/g, '_')}`)}`,
                        value: option,
                      }))}
                      noOptionsMessage={() => t('no_options') }
                      isClearable={true}
                      height="8rem"
                      isSearchable={false}
                      name="invoicing_period_id"
                      value={values.invoicing_period_id && {
                        label: `${t(`invoicing_periods.${values.invoicing_period_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.invoicing_period_id.value,
                      }}
                      placeholder={t('required')}
                      isLoading={false}
                      onChange={(selected) => {
                        setFieldValue('invoicing_period_id', selected);
                      }}
                      invalid={touched.invoicing_period_id && errors.invoicing_period_id}
                      defaultValue={values.invoicing_period_id && {
                        label: `${t(`invoicing_periods.${values.invoicing_period_id.value.description.replace(/\s/g, '_')}`)}`,
                        value: values.invoicing_period_id.value,
                      }}
                    />
                    {touched.invoicing_period_id && errors.invoicing_period_id && (
                      <ErrorText className="px-2 mb-0">{errors.invoicing_period_id}</ErrorText>
                    )}
                  </Col>
                </Row>
              </div>
              <StyleButtons>
                <AppButton
                  loading={loading}
                  style={{
                    width: 'auto',
                    fontSize: '14px',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  onClick={() => {
                    setFieldValue('action', editAction === 'restore' && '010');
                  }}
                >
                  {editAction === 'edit' ? `${t('buttons.btn_update')}` : `${t('buttons.btn_restore')}`}
                </AppButton>
                <AppButton
                  loading={loading}
                  style={{ width: 'auto', margin: '0.0rem 20px 0px', fontSize: '14px' }}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
                {editAction === 'edit' && (
                  <StyleSpaceButtonsRigth>
                    <AppButton
                      loading={loading}
                      style={{ width: 'auto', margin: '0.0rem 0px 0px', fontSize: '14px' }}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      color={PALETTE_COLOR.ACCENT_RED}
                      bordercolor={PALETTE_COLOR.ACCENT_RED}
                      bghovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
                      borderhovercolor={PALETTE_COLOR.ACCENT_RED_LIGHT}
                      onClick={handleClickOpenArchiveModal}
                    >
                      {t('buttons.btn_archive')}
                    </AppButton>
                  </StyleSpaceButtonsRigth>
                )}
              </StyleButtons>
            </Form>
            {showErrorMessage.show && (
              <CustomizedSnackbar
                show={showErrorMessage.show}
                type={showErrorMessage.type}
                title={showErrorMessage.title}
                message={showErrorMessage.message}
              />
            )}
          </div>
        )}
      </Formik>

      <AppModal
        open={openArchive}
        handleclose={handleCloseOpenArchiveModal}
        title={' '}
        boxshadow={'none'}
      >
        <ArchiveOrganization
          handleCloseOpenArchiveModal={handleCloseOpenArchiveModal}
          setShowArchivedModal={setShowArchivedModal}
          finish={handleclose}
          data={data}
        />
      </AppModal>
    </div>
  );
};
