import React from 'react';
import styled from 'styled-components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import send_invitation from '@assets/icons/ic_send.svg';

const Icon = styled.img``;

const DivIcon = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 8px;
  width: 140px;
  height: 40px;
  background: rgba(134, 217, 113, 0.2);
  background-color: ${PALETTE_COLOR.ACCENT_PURPLE};
  box-shadow: 0px 4px 10px rgba(137, 137, 255, 0.3);
  border-radius: 20px;
  margin-left: 15px;
  cursor: pointer;
`;

const Tittle = styled.div`
  padding: 7px 7px 7px 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: white;
`;

export const SendInviteBtn = ({ width, children, action }) => {
  return (
    <Container style={{ width }} onClick={action}>
      <DivIcon>
        <Icon src={send_invitation}></Icon>
      </DivIcon>
      <Tittle>{children}</Tittle>
    </Container>
  );
};
