import React from 'react';
import styled from 'styled-components';
import { AppCheckBox } from '@components';
import { Row, Col } from 'react-bootstrap';

const OptionLabelWrapper = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-top: 10px;
  margin-left: 5px;
`;

const SecondOptionLabelWrapper = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: gray;
  margin-left: 5px;
`;

export const AppCheckBoxList = ({ options, defaultValue, onClick }) => {
  //Store and show the options
  const [initialOptions, setInitialOptions] = React.useState(options);

  //Array to be returned to parent
  const selectedRef = React.useRef(defaultValue);

  /***
   * In charge of add new attribute which determines if checkbox is selected or not
   */
  React.useEffect(() => {
    selectedRef.current = defaultValue;
  }, [defaultValue]);

  /***
   * In charge of add new attribute which determines if checkbox is selected or not
   */
  React.useEffect(() => {
    setInitialOptions(
      options.map((ini) => {
        let found = false;
        defaultValue.map((def) => {
          if (ini.id === def.id) {
            found = true;
          }
          return def;
        });
        ini.selected = found;
        return ini;
      }),
    );
  }, [defaultValue, options]);

  /***
   * Set the array which will be sent to parent
   */
  const relayOnClick = (value) => {
    //Iterate the array shown and set selected true or false
    const newOptions = initialOptions.map((elem) => {
      if (elem.id === value.id) {
        if (elem.selected) {
          elem.selected = !elem.selected;
        } else {
          elem.selected = true;
        }
      }
      return elem;
    });

    setInitialOptions(newOptions);

    //Add or remove items from array retured to parent
    if (selectedRef.current.length === 0) {
      selectedRef.current.push(value);
    } else {
      const found = selectedRef.current.filter((item) => item.id === value.id);
      if (found.length === 0) {
        //Not found
        selectedRef.current.push(value);
      } else {
        //Found
        const newArray = selectedRef.current.filter((item) => item.id !== value.id);
        selectedRef.current = newArray;
      }
    }

    onClick(selectedRef.current);
  };

  return (
    <>
      {initialOptions.map((value, index) => (
        <Row>
          <Col xs={1}>
            <AppCheckBox
              key={index}
              checked={value.selected ? value.selected : false}
              onClick={() => {
                relayOnClick(value);
              }}
            />
          </Col>
          <Col xs={11}>
            <OptionLabelWrapper>{value.label}</OptionLabelWrapper>
            <br />
            {value.subtitle && (
              <SecondOptionLabelWrapper>{value.subtitle}</SecondOptionLabelWrapper>
            )}
          </Col>
        </Row>
      ))}
    </>
  );
};
