import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import { AppButton, AppDropDownSelect } from '@components';
import { saveEntityData } from '@services';
import { InputForm } from 'components/InputForm';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { USERS_API } from 'services/CONSTANTS';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

let requestCreateSysUser = {
  name: '',
  phone: '',
  email: '',
  user_system_rol: '',
  status: {},
};

export const AddSystemUserForm = ({ handleclose, setShowSucessModal }) => {
  const { t } = useTranslation();
  const createSystemUserSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
    email: Yup.string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    phone: Yup.string()
    .required(`${t('validation.phone_required')}`),
    user_system_rol: Yup.string().nullable(),
    role: Yup.object().nullable().required(`${t('validation.type_required')}`),
    action: Yup.string(),
  });
  const { valuesDefinitions } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  //To handle message
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const requestStatusCreate = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '030',
  )[0]; //Invited
  const requestStatusDraft = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '020',
  )[0]; //Draft
  const typeSystem = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_TYPES' && v.value_definition === '030',
  )[0]; //System
  const systemUserTypes = valuesDefinitions.filter(
    (v) =>
      v.definitionType.definition_type === 'ORGANIZATION_USER_ROLE' &&
      v.validation_type === 'SYSTEM_USER_TYPE',
  );

  requestCreateSysUser.type = typeSystem;
  const createSysUser = (values) => {
    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });

    const status =
      values.action === 'create'
        ? { ...requestStatusCreate }
        : values.action === 'draft' && { ...requestStatusDraft };
    requestCreateSysUser = { ...requestCreateSysUser, ...values, status };
    requestCreateSysUser.role = values.role.vdef;
    setLoading(true);
    saveEntityData(USERS_API, requestCreateSysUser)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_created')}`,
          message: '',
        });

        handleclose();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.user_exists')}`,
            message: '',
          });
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_user_creating')}`,
            message: '',
          });
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={createSystemUserSchema}
      onSubmit={createSysUser}
      initialValues={{
        name: '',
        phone: '',
        email: '',
        user_system_rol: '',
        role: null,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px', fontSize: '14px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  onChange={handleChange}
                ></InputForm>
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                ></InputForm>
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.type')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={systemUserTypes.map((option) => ({
                    label: `${t(`roles.${option.description.replace(/\s/g, '_')}`)}`,
                    value: option.value_definition_id,
                    vdef: option,
                  }))}
                  noOptionsMessage={() => t('no_options') }
                  isClearable={true}
                  height="8rem"
                  name="role"
                  placeholder={t('required')}
                  onChange={(selected) => {
                    setFieldValue('role', selected);
                    if (selected && selected.vdef.value_definition === '040') {
                      setFieldValue('user_system_rol', null);
                    }
                  }}
                  invalid={touched.role && errors.role}
                  defaultValue={values.role}
                />
                {touched.role && errors.role && (
                  <ErrorText className="px-2 mb-0">{errors.role}</ErrorText>
                )}
              </Col>
            </Row>
            {values.role == null || values.role.vdef.value_definition !== '040' ? (
              <Row className="mb-3">
                <Col xs={3}>
                  <LabelForm tittle={t('form_labels.role')}></LabelForm>
                </Col>
                <Col xs={9}>
                  <InputForm
                    className="mb-0"
                    inpfontsize={'14px'}
                    height={'40px'}
                    placeholder={t('optional')}
                    name="user_system_rol"
                    value={values.user_system_rol}
                    invalid={touched.user_system_rol && errors.user_system_rol}
                    onChange={handleChange}
                  ></InputForm>
                  {touched.user_system_rol && errors.user_system_rol && (
                    <ErrorText className="px-2 mb-0">{errors.user_system_rol}</ErrorText>
                  )}
                </Col>
              </Row>
            ) : null}

            <StyleButtons>
              <span>
                <AppButton
                  loading={loading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  onClick={() => {
                    setFieldValue('action', 'create');
                  }}
                  title={t('buttons.btn_create_invite')}
                >
                  {t('buttons.btn_create_invite')}
                </AppButton>
                <AppButton
                  loading={loading}
                  size="lg"
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={() => {
                    setFieldValue('action', 'draft');
                  }}
                  type="submit"
                  title={t('buttons.btn_save_as_draft')}
                >
                  {t('buttons.btn_save_as_draft')}
                </AppButton>
                <AppButton
                  size="lg"
                  loading={loading}
                  style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>

              {showErrorMessage.show && (
                <CustomizedSnackbar
                  show={showErrorMessage.show}
                  type={showErrorMessage.type}
                  title={showErrorMessage.title}
                  message={showErrorMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
        </div>
      )}
    </Formik>
  );
};
